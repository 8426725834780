import { CheckOutlined } from '@ant-design/icons';
import { Form, Input, Modal, Select, notification, Button, Row, Col } from 'antd';
import React from 'react';

const { Option } = Select;
const methods = [
  {
    code: 1,
    name: 'GET',
  },
  {
    code: 2,
    name: 'PUT',
  },
  {
    code: 3,
    name: 'DELETE',
  },
  {
    code: 4,
    name: 'POST',
  },
];
const layout = {
  labelCol: { span: 24 },
};

class APIForm extends React.Component {
  state = {};
  formRef = React.createRef();

  onFinish = (values) => {
    const { description, path, name, method } = values;
    this.props.onCreateApi({ description, path, name, method });
  };

  componentDidMount = () => {};

  onEditApi = (values) => {
    const { description, path, method, name } = values;
    this.props.onEditApi(this.props.editApi.code, { description, path, method, name });
  };

  onFinishFailed = () => {
    notification.warning({ message: 'Không được để trống !!!' });
  };

  afterClose = () => {
    this.formRef.current.resetFields();
  };

  render() {
    const { visibleApiForm, onToggleModalAPI, editApi } = this.props;
    // const formItemLayout = {
    //   labelCol: {
    //     xs: { span: 24 },
    //     sm: { span: 8 },
    //   },
    //   wrapperCol: {
    //     xs: { span: 24 },
    //     sm: { span: 12 },
    //   },
    // };
    return (
      <Modal
        title={editApi && editApi.code ? 'Cập nhập API' : 'Thêm API mới'}
        visible={visibleApiForm}
        onOk={editApi && editApi.code ? this.onEditApi : this.onFinish}
        onCancel={onToggleModalAPI}
        destroyOnClose
        footer={<></>}>
        <Row>
          <Col span={22} offset={1}>
            <Form
              {...layout}
              ref={this.formRef}
              name="control-ref"
              onFinish={editApi && editApi.code ? this.onEditApi : this.onFinish}
              onFinishFailed={this.onFinishFailed}
              // initialValues = { editApi && editApi.code ? editApi.payload.path : ''}
              initialValues={{
                path: editApi && editApi.code ? editApi.path : '',
                name: editApi && editApi.code ? editApi.name : '',
                method: editApi && editApi.code ? editApi.method : '',
                description: editApi && editApi.code ? editApi.description : '',
              }}>
              <Form.Item
                name="path"
                label="Đường dẫn"
                rules={[{ required: true, message: 'Nhập đường dẫn' }]}>
                <Input placeholder="Nhập đường dẫn" />
              </Form.Item>

              <Form.Item name="name" label="Tên" rules={[{ required: true, message: 'Nhập tên' }]}>
                {/* initialValue: editApi && editApi.code ? editApi.payload.path : '', */}
                <Input placeholder="Nhập tên" />
              </Form.Item>

              <Form.Item
                name="method"
                label="Phương thức"
                rules={[
                  {
                    required: true,
                    message: 'Nhập phương thức',
                  },
                ]}>
                {/* initialValue: editApi && editApi.code ? editApi.payload.method : '', */}
                <Select placeholder="Chọn phương thức" style={{ width: '100%' }}>
                  {methods &&
                    methods.map((m) => (
                      <Option value={m.name} key={m.code}>
                        {m.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Mô tả"
                name="description"
                rules={[
                  {
                    required: true,
                    message: 'Nhập mô tả',
                  },
                ]}>
                {/* initialValue: editApi && editApi.id ? editApi.payload.description : '', */}
                <Input placeholder="Nhập mô tả" />
              </Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                shape="round"
                style={{
                  width: '20%',
                  marginLeft: '180px',
                  marginTop: '10px',
                }}>
                <CheckOutlined />
                Lưu
              </Button>
            </Form>
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default APIForm;
