import { FileExcelOutlined, DeleteOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Form,
  Modal,
  Select,
  Table,
  Button,
  Row,
  Input,
  // Tooltip,
  Space,
  Popover,
  Popconfirm,
  notification,
} from 'antd';
import React from 'react';
import Highlighter from 'react-highlight-words';
import * as XLSX from 'xlsx';

// import UploadForm from '../../../components/UploadForm/index';
import { isBelowBreakpoint } from '../../../../utils/window';

const { Option } = Select;

class ListAPI extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  state = {
    selectedRowKeys: [],
    data: [],
    idRole: '',
    listApiOfRoleLength: 0,
    selectedItems: [],
    roles: [],
    searchText: '',
    searchedColumn: '',
  };

  static getDerivedStateFromProps(props, state) {
    const { roleId, listApiOfRole } = props;
    const { idRole, listApiOfRoleLength } = state;
    if (
      (roleId && roleId.payload.id && roleId.payload.id !== idRole) ||
      listApiOfRole?.length !== listApiOfRoleLength
    ) {
      const id = roleId?.payload?.id || '';
      const result = listApiOfRole?.map((item) => ({
        name: item.name,
        key: item.id,
        path: item.path,
        method: item.method,
        status: item.status,
        description: item.description,
      }));

      return { data: result, idRole: id, listApiOfRoleLength: listApiOfRole?.length };
    }
    return state;
  }

  onSelectChange = (selectedRowKey) => {
    this.setState({ selectedRowKeys: selectedRowKey });
  };

  handleChange = (selectedItems) => {
    this.setState({ selectedItems });
  };

  afterClose = () => {
    this.props.form.resetFields();
  };

  addAPI = () => {
    this.props.uploadApiFromExcel(this.state.roles);
  };

  onImportExcel = (event) => {
    const { files } = event.target;
    if (files.length === 1) {
      // Process a file if we have exactly one
      this.importExcel(files[0]);
    }
  };

  importExcel = (file) => {
    const fileReader = new FileReader();

    fileReader.onload = (event) => {
      try {
        /** convert sheet to json */
        let startAt = 0;
        const arrayTotal = [];
        const { result } = event.target;
        const workbook = XLSX.read(result, { type: 'binary' });
        const Sheet = workbook.Sheets[workbook.SheetNames[0]];
        let data = XLSX.utils.sheet_to_json(Sheet);
        for (let i = 1; i <= data.length; i++) {
          const flagCell = Sheet[`A${i}`];
          if (flagCell !== undefined) {
            startAt = i;
            // 1
          }
        }

        if (startAt === 0) {
          notification.error({ message: 'Thêm mới file excel thất bại!', duration: 1 });
        } else {
          // const arrayTotal = [];
          data = XLSX.utils.sheet_to_json(Sheet, { raw: true });
          notification.success({ message: 'Tải File thành công', duration: 1 });

          for (let i = 0; i < data.length; i++) {
            // create a role
            if (data[i].role && data[i].path && data[i].method && data[i].name) {
              const roleApis = arrayTotal.find((item) => item.code === data[i].role);
              if (roleApis) {
                roleApis.apis.push({
                  description: data[i].description ? data[i].description : '',
                  method: data[i].method,
                  name: data[i].name,
                  path: data[i].path,
                });
              } else {
                arrayTotal.push({
                  code: data[i].role,
                  apis: [
                    {
                      description: data[i].description ? data[i].description : '',
                      method: data[i].method,
                      name: data[i].name,
                      path: data[i].path,
                    },
                  ],
                });
              }
            }
          }
          this.setState({ roles: arrayTotal });
        }
      } catch (e) {
        notification.error({ message: e, duration: 1 });
      }
    };
    fileReader.readAsBinaryString(file);
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}>
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}>
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
    const {
      visibleListApi,
      onToggleModalList,
      allApis,
      onDeleteApiOfRole,
      onDeleteListApiOfRole,
      getRoles,
      // uploadApiFromExcel,
    } = this.props;
    const { selectedRowKeys, idRole, selectedItems } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const filteredOptions = allApis?.filter((item) => !selectedItems.includes(item));

    const content = (
      <Form ref={this.formRef}>
        <Form.Item>
          <Select
            allowClear
            mode="multiple"
            placeholder="Chọn API"
            setFieldsValue={selectedItems}
            style={{ width: '350px', marginRight: '5px' }}
            onChange={this.handleChange}
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            showSearch>
            {filteredOptions?.map((item) => (
              <Option key={item.id} value={item.code}>
                {item.name}
              </Option>
            ))}
          </Select>
          <Popconfirm
            title="Bạn muốn thêm mới ?"
            placement="bottom"
            onConfirm={() => {
              // eslint-disable-next-line no-sequences
              this.props.onCreateApiOfRole(this.state.idRole, this.state.selectedItems);
              this.formRef.current.resetFields();
            }}
            okText="Có"
            cancelText="Không">
            <Button style={{ borderRadius: '5px' }}> Thêm </Button>
          </Popconfirm>
        </Form.Item>
      </Form>
    );

    const contentExcel = (
      <>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://docs.google.com/spreadsheets/d/1PWzV27csZ1vCoDjQeEGvv7HWzDC823MtfRyDY34bJ4w/edit?usp=sharing">
          Mẫu file excel
        </a>
        <br />
        <br />
        <input
          id="fileUpload"
          type="file"
          ref={(v) => {
            this.fileUpload = v;
          }}
          accept=".xlsx, .xls"
          onChange={this.onImportExcel}
        />
        <Button onClick={this.addAPI}>Thêm API từ File</Button>
      </>
    );

    const columns = (onDeleteApiOfRole, idRole, getAPIofRole) => [
      {
        title: <b>Tên</b>,
        dataIndex: 'name',
        ...this.getColumnSearchProps('name'),
        // render: (value, record) => (
        //   <Tooltip title={record.description}>
        //     <b>{record.name}</b>
        //   </Tooltip>
        // ),
      },
      {
        title: 'Đường dẫn',
        dataIndex: 'path',
        ...this.getColumnSearchProps('path'),
      },
      {
        title: 'Phương Thức',
        dataIndex: 'method',
      },
      {
        title: 'Xóa',
        dataIndex: 'delete',
        render: (value, record) => (
          <Popconfirm
            title="Bạn muốn xóa ?"
            placement="rightTop"
            onConfirm={() => {
              onDeleteApiOfRole(idRole, [record.key]);
            }}
            okText="Có"
            cancelText="Không">
            <Button icon={<DeleteOutlined />} shape="circle" type="danger" />
          </Popconfirm>
        ),
      },
    ];

    return (
      <div className="container">
        <div className="row">
          <Modal
            title="Danh sách API "
            width="85%"
            bodyStyle={{ overflowX: 'scroll' }}
            visible={visibleListApi}
            onOk={onToggleModalList}
            onCancel={onToggleModalList}
            okText="Đóng"
            // afterClose={this.afterClose}
            closable
            cancelButtonProps={{ style: { display: 'none' } }}>
            <Table
              title={() => (
                <Row>
                  <Popover
                    content={content}
                    title="Chọn API"
                    trigger="click"
                    placeholder="Chọn API"
                    placement="left">
                    <Button placeholder="Chọn API" icon={<PlusOutlined />}>
                      Thêm API
                    </Button>
                  </Popover>
                  <Popover content={contentExcel} trigger="click" placement="right">
                    <Button
                      icon={<FileExcelOutlined />}
                      style={{
                        marginLeft: isBelowBreakpoint() ? 0 : '10px',
                      }}>
                      Thêm API từ Excel
                    </Button>
                  </Popover>
                  <Popconfirm
                    title="Bạn muốn xóa ?"
                    placement="rightTop"
                    onConfirm={
                      selectedRowKeys.length === 0
                        ? ''
                        : () => onDeleteListApiOfRole(idRole, this.state.selectedRowKeys)
                    }
                    okText="Có"
                    cancelText="Không">
                    <Button
                      icon={<DeleteOutlined />}
                      style={{
                        marginLeft: isBelowBreakpoint() ? 0 : '10px',
                      }}
                      disabled={selectedRowKeys.length === 0}>
                      Xóa nhiều
                    </Button>
                  </Popconfirm>
                </Row>
              )}
              size="small"
              rowClassName={(r, idx) => (idx % 2 === 0 ? 'even' : '')}
              scroll={{ x: 'max-content' }}
              rowSelection={rowSelection}
              columns={columns(onDeleteApiOfRole, idRole, getRoles)}
              dataSource={this.state.data}
            />
          </Modal>
        </div>
      </div>
    );
  }
}

export default ListAPI;
