import { Layout, Breadcrumb, Alert, Space, Button } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Link } from 'react-router-dom';

import Account from './components/account';
import Api from './components/api';
import Company from './components/company';
import Configuration from './components/configuration';
import Customer from './components/customer';
import Dashboard from './components/dashboard';
import Driver from './components/driver';
import Feature from './components/feature';
import Inventory from './components/inventory';
import Order from './components/order';
import Routing from './components/routing';
import SampleRoute from './components/sampleRoute';
import Vehicle from './components/vehicle';
import VehicleParts from './components/vehiclePart';
import Footer from '../../components/Footer';
import Sidebar from '../../components/SideBar';
import UserWidget from '../../components/UserWidget';
import BREADCUMB_NAME from '../../config/breadcumbs';
import ROUTER from '../../config/router';
import WithAuthentication from '../../hoc/WithAuthentication';
import { actions as alertActions } from '../../redux/AlertRedux';
import ChangePasswordPage from '../Auth/ChangePasswordPage/index';

// import 'flag-icon-css/css/flag-icon.min.css';

const { Header, Content } = Layout;

class AppLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChangePassword = () => {
    const { history } = this.props;
    history.push(ROUTER.AUTH.CHANGE_PASSWORD);
  };

  breadcrumbRender = () => {
    const { isAdmin } = this.props;
    const { location } = this.props;
    const arr = location.pathname.split('/');
    return isAdmin
      ? arr.map((item, index) => {
          if (item !== '') {
            if (index === arr.length - 1) {
              return (
                <Breadcrumb.Item key="home">
                  {BREADCUMB_NAME[item] ? BREADCUMB_NAME[item] : 'Detail'}
                </Breadcrumb.Item>
              );
            }

            return (
              <Breadcrumb.Item key="home">
                <Link to={`${arr.slice(0, index + 1).join('/')}`}>
                  {BREADCUMB_NAME[item] ? BREADCUMB_NAME[item] : 'Detail'}
                </Link>
              </Breadcrumb.Item>
            );
          }
          return null;
        })
      : null;
  };

  onRedirect = () => {
    const { history, deleteAlert, alertAction } = this.props;
    deleteAlert();
    history.push(alertAction.link);
  };

  AlertIfNecessary = () => {
    const { alerts, alertAction } = this.props;
    if (alerts.length > 0) {
      return (
        <Alert
          message={alerts[0].message}
          description={alertAction.text}
          type={alerts[0].type}
          action={
            <Space direction="vertical" style={{ width: '150px' }}>
              <Button size="medium" type="primary" onClick={this.onRedirect}>
                Thiết Lập
              </Button>
              {/* <Button size="medium" danger type="ghost" onClick={this.props.deleteAlert}>
                No
              </Button> */}
            </Space>
          }
          afterClose={this.props.deleteAlert}
          closable
          closeIcon={
            <Button size="medium" danger type="ghost" onClick={this.props.deleteAlert}>
              Lúc Khác
            </Button>
          }
        />
      );
    } else return <></>;
  };

  render() {
    const { user, alertAction } = this.props;
    if (location.pathname === alertAction.link) this.props.deleteAlert();
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Sidebar />
        <Layout>
          <Header
            style={{
              background: '#ffffff',
              padding: '0 25px',
            }}>
            <div
              style={{
                float: 'right',
              }}>
              <UserWidget user={user} onChangePassword={this.handleChangePassword} />
            </div>
          </Header>
          <this.AlertIfNecessary />
          <Breadcrumb separator=">" style={{ margin: '16px 25px' }}>
            {/* {this.breadcrumbRender()} */}
          </Breadcrumb>
          <Content
            style={{
              background: '#ffffff',
              margin: '0px 25px',
              padding: '10px 25px',
            }}>
            <Switch>
              <Route exact path={ROUTER.AUTH.CHANGE_PASSWORD} component={ChangePasswordPage} />
            </Switch>
            <Configuration />
            <Dashboard />
            <Feature />
            <Company />
            <Vehicle />
            <VehicleParts />
            <Account />
            <Driver />
            <Customer />
            <Api />
            <Order />
            <Inventory />
            <Routing />
            <SampleRoute />
          </Content>
          <Footer text="Anfast ©2023" />
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = ({ authReducer, appReducer, configurationReducer, alertReducer }) => ({
  user: authReducer.authUser,
  role: authReducer.role,
  currentPage: appReducer.currentPage,
  isAdmin: authReducer.isAdmin,
  item: configurationReducer.item,
  alerts: alertReducer.alerts,
  alertAction: alertReducer.action,
});

const mapDispatchToProps = (dispatch) => ({
  deleteAlert: () => dispatch(alertActions.deleteAlert()),
});

export default WithAuthentication(true)(connect(mapStateToProps, mapDispatchToProps)(AppLayout));
