import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ROUTER from '../../../config/router';
import AddCustomer from '../../Customer/add';
import EditCustomer from '../../Customer/edit';
import CustomerPage from '../../Customer/index';

const Customer = () => (
  <Switch>
    <Route exact path={ROUTER.CUSTOMER.INDEX} component={CustomerPage} />
    <Route exact path={ROUTER.CUSTOMER.ADD} component={AddCustomer} />
    <Route exact path={ROUTER.CUSTOMER.EDIT} component={EditCustomer} />
  </Switch>
);
export default Customer;
