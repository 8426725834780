import lodash from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import SelectSearch from './Select';
import { actions as productActions } from '../../redux/productRedux';

const { getProducts } = productActions;

class ProductSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prevSelectedValue: [],
      defaultValue: [],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.allCategory && nextProps.allCategory.length) {
      if (!lodash.isEqual(nextProps.selectedValue, prevState.prevSelectedValue)) {
        const nextState = { prevSelectedValue: nextProps.selectedValue };
        if (nextProps.selectedValue && nextProps.selectedValue.length) {
          const { selectedValue } = nextProps;
          if (typeof selectedValue === 'string') {
            nextState.defaultValue = ProductSearch.getProductByCode([selectedValue], nextProps);
          } else if (typeof selectedValue === 'object') {
            if (Array.isArray(selectedValue)) {
              if (typeof selectedValue[0] === 'string') {
                nextState.defaultValue = ProductSearch.getProductByCode(selectedValue, nextProps);
              } else if (typeof selectedValue[0] === 'object') {
                nextState.defaultValue = selectedValue;
              }
            } else {
              nextState.defaultValue = selectedValue;
            }
          }
        } else {
          nextState.defaultValue = [];
        }
        return nextState;
      }
    }
    return null;
  }

  componentDidMount() {
    this.props.getProducts({
      page: -1,
      page_size: -1,
    });
  }

  searchProductByName = async (name) => {
    const params = {
      search: name,
      page: 1,
      page_size: 50,
    };
    const api = API_URLS.PRODUCT.getFilteredProducts(params);
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      if (response.data.data && response.data.data.length) {
        return response.data.data.map((product) => ({
          key: product.code,
          label: `${product.name}`,
          // defaultValue: response.data.data,
        }));
      }
    }

    return [];
  };

  static getProductByCode = (codes, props) => {
    if (codes && codes.length) {
      const { allProducts } = props;
      if (!allProducts.length) {
        return [];
      }
      const defaultValue = [];
      codes.forEach((code) => {
        const product_code = code.trim();
        if (!product_code) return;
        const foundProduct = allProducts.find((p) => p.code === product_code);
        const product = foundProduct
          ? {
              key: product_code,
              label: `${foundProduct.name}`,
            }
          : { key: product_code, label: `[Not found] ${product_code}` };
        defaultValue.push(product);
      });
      return defaultValue;
    }
    return [];
  };

  render() {
    const { allProducts, onChange } = this.props;
    const dataSource = allProducts;
    return (
      <SelectSearch
        {...this.props}
        onChange={onChange}
        selectedValue={this.state.defaultValue}
        dataSource={dataSource}
        placeholder={this.props.placeholder || 'Chọn sản phẩm'}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  allProducts: state?.productReducer?.data,
});

const mapDispatchToProps = (dispatch) => ({
  getProducts: (params) => dispatch(getProducts(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductSearch);
