import * as tt from '@tomtom-international/web-sdk-maps';
import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import '@tomtom-international/web-sdk-maps/dist/maps.css';
import './Tomtom.css';
import { connect } from 'react-redux';

import { actions as tomtomActions } from '../../../redux/TomtomRedux';
import TomtomSearchInput from './TomtomSearchInput';

function TomtomSearch(props) {
  const mapElement = useRef();
  const [map, setMap] = useState({});
  const [longitude, setLongitude] = useState(props.curLng || 105.78371);
  const [latitude, setLatitude] = useState(props.curLat || 21.04578);
  const [prevMarkers] = useState([]);

  const addAddressMarker = (value) => {
    setLongitude(value.lng);
    setLatitude(value.lat);
    if (props.onSelectAddress) {
      props.onSelectAddress(value);
    }
  };

  useEffect(() => {
    const initMap = tt.map({
      key: process.env.REACT_APP_TOM_TOM_API_KEY,
      container: mapElement.current,
      center: [longitude, latitude],
      zoom: 14,
      language: 'vn',
    });
    setMap(initMap);
    return () => initMap.remove();
  }, []);

  useEffect(() => {
    if (!map || !map.options) return;

    const removeAllPrevMarkers = () => {
      prevMarkers.map((item) => item.remove());
    };
    removeAllPrevMarkers();

    const addMarker = () => {
      map.jumpTo({ center: [longitude, latitude] });

      const marker = new tt.Marker({
        draggable: true,
      })
        .setLngLat([longitude, latitude])
        .addTo(map);
      prevMarkers.push(marker);
      marker.on('dragend', () => {
        const lngLat = marker.getLngLat();
        props.setTomtomSearchLatitude(lngLat.lat);
        props.setTomtomSearchLongitude(lngLat.lng);
        map.jumpTo({ center: [lngLat.lng, lngLat.lat] });
      });
    };
    addMarker();

    const setDefaultLatlng = () => {
      if (props.curLat && props.curLng) {
        setLongitude(props.curLng);
        setLatitude(props.curLat);
      }
    };
    setDefaultLatlng();
  }, [map, longitude, latitude, props.curLat, props.curLng]);

  return (
    <>
      {map && (
        <div className="tomtom-search-app">
          <TomtomSearchInput
            selectingAddress={props?.selectingAddress}
            onChange={(value) => addAddressMarker(value)}
            isEditMode={props.isEditMode}
            smallInput={props.smallInput}
          />
          <div ref={mapElement} className="tomtom-search-map" />
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  selectedAddress: state.tomtomReducer.address,
  curLat: state.tomtomReducer.latitude,
  curLng: state.tomtomReducer.longitude,
});

const mapDispatchToProps = (dispatch) => ({
  setTomtomSearchAddress: (payload) => dispatch(tomtomActions.setMapAddress(payload)),
  setTomtomSearchLatitude: (payload) => dispatch(tomtomActions.setMapLatitude(payload)),
  setTomtomSearchLongitude: (payload) => dispatch(tomtomActions.setMapLongitude(payload)),
  clearTomtomStore: () => dispatch(tomtomActions.clearStore()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TomtomSearch);
