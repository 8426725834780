import { Card, Col, Row, Typography } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { connect } from 'react-redux';

import Filter from './filter';
import common from '../../../config/common';
import { actions as dashboardActions } from '../../../redux/DashboardRedux';
import { ISODateParam } from '../../../utils/dateTime';
const { Text } = Typography;
const { getStatisticNodes } = dashboardActions;

const StackBar = {
  chart: {
    type: 'bar',
    height: 350,
    stacked: true,
  },
  plotOptions: {
    bar: {
      horizontal: true,
      dataLabels: {
        total: {
          enabled: true,
          offsetX: 0,
          style: {
            fontSize: '13px',
            fontWeight: 900,
          },
        },
      },
    },
  },
  stroke: {
    width: 1,
    colors: ['#fff'],
  },
  //   title: {
  //     text: 'Fiction Books Sales',
  //   },
  xaxis: {
    categories: [],
    labels: {
      formatter(val) {
        return `${val}K`;
      },
    },
  },
  yaxis: {
    title: {
      text: undefined,
    },
  },
  tooltip: {
    y: {
      formatter(val) {
        return `${val}K`;
      },
    },
  },
  fill: {
    opacity: 1,
  },
  legend: {
    position: 'top',
    horizontalAlign: 'left',
    offsetX: 40,
  },
};

const NodesDashboard = ({ isFetching, getStatisticNodes, dataNodes }) => {
  const [dateFrom, setDateFrom] = useState(moment().subtract(1, 'M'));
  const [dateTo, setDateTo] = useState(moment());
  const [driverCodes, setDriverCodes] = useState();

  useEffect(() => {
    const momentFrom = moment.isMoment(dateFrom) ? dateFrom : moment(dateFrom);
    const momentTo = moment.isMoment(dateTo) ? dateTo : moment(dateTo);
    getStatisticNodes({
      page: -1,
      page_size: -1,
      time_start: ISODateParam(momentFrom),
      time_end: ISODateParam(momentTo),
      driver_codes: driverCodes,
    });
  }, [dateFrom, dateTo, driverCodes]);

  const dataOfNodes = [];
  const dataName = [];
  const ScheduledData = {
    name: 'Scheduled',
    data: [],
  };

  const OnRouteData = {
    name: 'OnRoute',
    data: [],
  };

  const CompletedData = {
    name: 'Completed',
    data: [],
  };

  const statusArr = {
    OnRoute: 0,
    Scheduled: 1,
    Completed: 2,
  };

  dataNodes?.forEach((element) => {
    dataName.push(element.driver_name);
    const dataOfNode = {
      name: element.driver_name,
      data: [0, 0, 0],
    };
    if (element?.number_of_nodes_by_status.length) {
      element?.number_of_nodes_by_status.forEach((ele) => {
        if (statusArr[ele.status] !== undefined) {
          dataOfNode.data[statusArr[ele.status]] = ele.number_of_nodes;
        }
      });
    }
    dataOfNodes.push(dataOfNode);
  });

  dataOfNodes.forEach((element) => {
    OnRouteData.data.push(element.data[0]);
    ScheduledData.data.push(element.data[1]);
    CompletedData.data.push(element.data[2]);
  });

  const handleSearchData = (value) => {
    setDateFrom(value.fromDate);
    setDateTo(value.toDate);
    setDriverCodes(value?.driverCode?.join());
  };

  return (
    <Card
      title={<Text>Tổng hợp số lượng điểm giao từng lái xe</Text>}
      bordered
      loading={isFetching}
      extra={<Filter onSearch={handleSearchData} />}>
      <Row gutter={16}>
        <Col span={24} lg={24}>
          {dataNodes ? (
            <Chart
              options={{
                ...StackBar,
                xaxis: {
                  categories: dataName,
                },
                // title: { text: this.props.title },
                legend: {
                  position: 'top',
                  formatter: (value) =>
                    common.orderStatus[value] ? common.orderStatus[value] : value,
                },
              }}
              series={[OnRouteData, ScheduledData, CompletedData]}
              type="bar"
              height="auto"
              width="100%"
            />
          ) : (
            <div>Không có dữ liệu</div>
          )}
        </Col>
      </Row>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  isFetching: state.dashboardReducer?.nodes?.sale?.isFetching,
  dataNodes: state.dashboardReducer?.nodes?.sale?.data,
});

const mapDispatchToProps = (dispatch) => ({
  getStatisticNodes: (query) => {
    dispatch(getStatisticNodes(query));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NodesDashboard);
