import { CheckOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Modal, Row, Select, Table, notification } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import VehiclePartSearch from '../../../../../components/FormItems/VehiclePartSearch';
import { actions as vehicleExpiryActions } from '../../../../../redux/VehicleExpiryRedux';

const { Option } = Select;

const columns = (allPart) => [
  {
    title: <b>Tên linh kiện</b>,
    dataIndex: 'part_code',
    render: (value, record) => {
      const filterList = allPart.filter((data) => data.code === record.part_code);

      return <div>{filterList[0]?.name}</div>;
    },
  },
  {
    title: <b>Ngày nhập hàng</b>,
    dataIndex: 'import_date',
    render: (value) => <>{moment(value).format('DD/MM/YYYY')}</>,
  },
  {
    title: <b>Quãng đường đã đi được</b>,
    dataIndex: 'travel_distance',
  },
  {
    title: <b>Kiểu</b>,
    dataIndex: 'type',
    render: (value) => {
      if (value === 'Fix') return <div>Sửa chữa</div>;
      if (value === 'Replace') return <div>Thay thế</div>;
    },
  },
  {
    title: <b>Ngày tạo</b>,
    dataIndex: 'created_at',
    render: (value) => <>{moment(value).format('DD/MM/YYYY')}</>,
  },
];

const VehicleHistory = ({
  vehicleId,
  getVehicleExpiryById,
  currentExpoiry,
  isFetching,
  allPart,
  insertVehicleExpiryMaintanin,
}) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [part_code, setPartCode] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [listPart, setListPart] = useState([]);

  useEffect(() => {
    getVehicleExpiryById(vehicleId);
  }, []);

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const renderListProduct = () => {
    const listInput = [];
    for (let i = 0; i < listPart.length; i += 1) {
      listInput.push(
        <Row gutter={15} style={{ marginTop: '10px' }}>
          <Col span={12}>
            <VehiclePartSearch
              selectedValue={part_code}
              onChange={(value) => {
                updateListProduct(i + 1, { part_code: value });
              }}
            />
          </Col>
          <Col span={12}>
            <Select
              style={{ width: '100%' }}
              onChange={(value) => {
                updateListProduct(i + 1, { maintain_type: value });
              }}
              placeholder="Chọn trạng thái">
              <Option value="Fix">Fix</Option>
              <Option value="Replace">Replace</Option>
            </Select>
          </Col>
        </Row>,
      );
    }

    return listInput;
  };

  const updateListProduct = (index, value) => {
    const temp = [...listPart];
    temp[index] = { ...temp[index], ...value };
    setListPart(temp);
  };

  const onValidateModal = () => {
    const uniqueCode = new Set(listPart.map((value) => value.part_code));
    const partCodeFilter = listPart.map((e) => e.part_code).filter((value) => value);
    const importMaintainType = listPart.map((e) => e.maintain_type).filter((value) => value);
    if (listPart && listPart.length > 0) {
      if (uniqueCode.size < listPart.length) {
        notification.open({
          message: 'Trùng tên linh kiện',
          type: 'error',
        });
      } else if (partCodeFilter.length < importMaintainType.length) {
        notification.open({
          message: 'Thiếu tên linh kiện',
          type: 'error',
        });
        return false;
      } else if (partCodeFilter.length > importMaintainType.length) {
        notification.open({
          message: 'Thiếu trạng thái  sửa chữa',
          type: 'error',
        });
        return false;
      } else return true;
    }
    return false;
  };

  const handleInsertMaintain = () => {
    if (onValidateModal()) {
      insertVehicleExpiryMaintanin(vehicleId, listPart, {
        onSuccess: () => {
          notification.success({
            message: 'Thêm thành công',
          });
          getVehicleExpiryById(vehicleId);
        },
        onError: () =>
          notification.error({
            message: `${'Thêm thất bại'}`,
          }),
      });
      handleCloseModal();
      setListPart([]);
    }
  };

  return (
    <>
      <Row gutter={15}>
        <Col span={24}>
          <Button
            style={{ float: 'right', marginBottom: '15px' }}
            shape="round"
            type="primary"
            onClick={handleOpenModal}
            icon={<PlusOutlined />}>
            Thêm lịch sử sửa chữa
          </Button>
        </Col>
        <Col span={24}>
          <Table
            loading={isFetching}
            dataSource={currentExpoiry?.maintain_histories || []}
            columns={columns(allPart)}
            pagination={{
              current: page,
              page_size: pageSize,
              showSizeChanger: true,
              // onShowSizeChange: { handleChangePage },
              showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total}`,
            }}
            size="small"
            scroll={{ x: 'max-content' }}
          />
        </Col>
        <Col span={24}>
          <Modal
            title="Linh kiện cần sửa chữa"
            onOk={() => {
              handleOpenModal();
            }}
            onCancel={handleCloseModal}
            visible={openModal}
            footer={<></>}
            destroyOnClose>
            <Form onFinish={handleInsertMaintain}>
              <Row gutter={15}>
                <Col span={12}>
                  <Form.Item required name="part_code" label="Tên linh kiện">
                    <VehiclePartSearch
                      selectedValue={part_code}
                      onChange={(value) => {
                        updateListProduct(0, { part_code: value });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item required name="maintain_type" label="Loại trạng thái sửa chữa">
                    <Select
                      style={{ width: '100%' }}
                      onChange={(value) => {
                        updateListProduct(0, { maintain_type: value });
                      }}
                      placeholder="Chọn trạng thái">
                      <Option value="Fix">Sửa chữa</Option>
                      <Option value="Replace">Thay thế</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              {renderListProduct()}

              <Button
                type="primary"
                htmlType="submit"
                shape="round"
                style={{
                  width: '20%',
                  marginLeft: '180px',
                  marginTop: '10px',
                }}>
                <CheckOutlined />
                Lưu
              </Button>
            </Form>
          </Modal>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  allExpiry: state.vehicleExpiryReducer.allItems,
  isFetching: state.vehicleExpiryReducer.isFetching,
  allPart: state.vehiclePartReducer.allItems,
  currentExpoiry: state?.vehicleExpiryReducer?.currentVehicleExpiry || {},
});

const mapDispatchToProps = (dispatch) => ({
  getAllVehicleExpiry: () => dispatch(vehicleExpiryActions.getAllVehicleExpiry()),
  getAllVehiclePart: () => dispatch(vehiclePartActions.getAllVehiclePart()),
  getVehicleExpiryById: (id, meta) => dispatch(vehicleExpiryActions.getVehicleExpiryById(id, meta)),
  insertVehicleExpiryMaintanin: (id, payload, meta) =>
    dispatch(vehicleExpiryActions.insertVehicleExpiryMaintanin(id, payload, meta)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VehicleHistory);
