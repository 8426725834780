import { Button, Modal, Select, notification } from 'antd';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { API_URLS } from '../../../config/api';
import { actions as orderActions } from '../../../redux/OrderRedux';
import { apiCall } from '../../../utils/api';

const ChangeCompanyModal = (props) => {
  const { isOpen, onCancel, dataSelect } = props;
  const [data, setData] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const order_codes = dataSelect;
  const order = order_codes.length;

  useEffect(() => {
    getApi();
  }, [selectedValue]);

  const getApi = async () => {
    const api = API_URLS.COMPANY.getCompany();
    const result = await apiCall({ ...api });
    setData(result.response.data.data);
  };
  const handleChange = (value) => {
    setSelectedValue(value);
  };
  const changeCompany = async () => {
    const body = {
      order_codes,
      to_company_code: selectedValue,
    };
    const change = API_URLS.ORDER.changeCompany(body);

    const { response, error } = await apiCall({ ...change });
    if (!error && response.status === 200) {
      props.invalidateOrders();
      onCancel();
      notification.open({
        message: 'Chuyển đơn hàng thành công',
        type: 'success',
      });
    } else {
      notification.open({
        message: 'Có lỗi khi chuyển đơn hàng. Hãy thử lại!',
        type: 'error',
      });
    }
  };

  return (
    <Modal
      style={{ minWidth: '35%' }}
      title={<span>Chuyển đơn hàng sang công ty khác</span>}
      open={isOpen}
      onCancel={onCancel}
      footer={
        <>
          <Button type="primary" shape="round" onClick={changeCompany} disabled={!order}>
            Chuyển
          </Button>
          <Button type="ghost" shape="round" onClick={onCancel}>
            Huỷ
          </Button>
        </>
      }>
      <h3>Số đơn đã chọn: {order}</h3>

      <Select
        style={{ width: '100%' }}
        onChange={handleChange}
        defaultValue="Chọn công ty muốn chuyển đơn">
        {data.map((item) => (
          <Select.Option key={item.code} value={item.code}>
            {item.name}
          </Select.Option>
        ))}
      </Select>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  insertManyOrder: (payload, meta) => dispatch(orderActions.insertManyOrder(payload, meta)),
  invalidateOrders: () => dispatch(orderActions.invalidateOrders()),
});

export default connect(null, mapDispatchToProps)(ChangeCompanyModal);
