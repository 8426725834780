import { PlusOutlined } from '@ant-design/icons';
import { Divider, Button, notification } from 'antd';
// import queryString from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Filter from './components/Filter';
import VehiclePartForm from './components/Form';
import VehiclePartList from './components/List';
import WithConfig from '../../hoc/WithConfig';
import WithLoading from '../../hoc/WithLoading';
import { actions as vehiclePartActions } from '../../redux/VehiclePartRedux';

class VehiclePart extends Component {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props) {
    super(props);
  }
  state = {
    dataFilter: {
      page: 1,
      page_size: 10,
      search: '',
    },
    visibleForm: false,
  };

  static getDerivedStateFromProps(props, state) {
    const { search, page, page_size } = state.dataFilter;
    props.getVehiclePartIfNeed({
      page,
      page_size,
      search,
    });
    return null;
  }

  onPageChange = (page, page_size) => {
    const { dataFilter } = this.state;
    const { invalidateVehiclePart } = this.props;
    dataFilter.page = page;
    dataFilter.page_size = page_size;
    this.setState({
      dataFilter,
    });
    invalidateVehiclePart();
  };

  handleOnAdd = () => {
    this.setState({
      curEditPart: {},
      visibleForm: true,
    });
  };

  setEditPart = (code, payload) => {
    this.setState({
      curEditPart: { code, payload },
      visibleForm: true,
    });
  };

  onCreateVehiclePart = (values) => {
    this.props.insertVehiclePart(values, {
      onSuccess: () => {
        notification.success({ message: 'Tạo vai trò mới thành công' });
        this.onCloseForm();
        this.props.invalidateVehiclePart();
      },
      onError: (mes) => {
        notification.error({ message: `Tạo vai trò thất bại. ${mes}` });
      },
    });
  };

  onEditPart = (vehiclePartCode, values) => {
    this.props.updateVehiclePart(vehiclePartCode, values, {
      onSuccess: () => {
        notification.success({ message: 'Đã cập nhật linh kiện' });
        this.onCloseForm();
        this.props.invalidateVehiclePart();
      },
      onError: (error) => notification.error({ message: `${error} - Cập nhật thất bại` }),
    });
  };

  onCloseForm = () => {
    this.setState({
      visibleForm: false,
    });
  };

  onChangeFilter = (data) => {
    const { dataFilter } = this.state;
    this.setState({ dataFilter: { ...dataFilter, ...data } });
  };

  render() {
    const { visibleForm, curEditPart } = this.state;
    const { isFetching, meta, vehicles } = this.props;
    return (
      <>
        <div>
          <h2 style={{ float: 'left' }}>Danh sách linh kiện</h2>
          <div style={{ float: 'right' }}>
            <Button shape="round" type="primary" onClick={this.handleOnAdd} icon={<PlusOutlined />}>
              Thêm
            </Button>
          </div>
        </div>
        <Divider style={{ marginTop: 0 }} />

        <div style={{ marginBottom: '15px' }}>
          <Filter onSearch={this.onChangeFilter} />
        </div>
        <VehiclePartList
          isFetching={isFetching}
          dataSource={vehicles}
          meta={meta}
          onSetEditPart={this.setEditPart}
          onPageChange={this.onPageChange}
        />
        <VehiclePartForm
          editPart={curEditPart}
          visibleForm={visibleForm}
          onCloseForm={this.onCloseForm}
          onCreateVehiclePart={this.onCreateVehiclePart}
          onEditPart={this.onEditPart}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { vehiclePartReducer } = state;
  return {
    vehicles: vehiclePartReducer.items,
    meta: vehiclePartReducer.meta,
    total: vehiclePartReducer.meta.total,
    isFetching: vehiclePartReducer.isFetching,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getVehiclePartIfNeed: (filterOption) =>
    dispatch(vehiclePartActions.getVehiclePartIfNeed(filterOption)),
  changePage: (page, page_size, total) =>
    dispatch(vehiclePartActions.changePage(page, page_size, total)),
  invalidateVehiclePart: () => dispatch(vehiclePartActions.invalidateVehiclePart()),
  insertVehiclePart: (payload, meta) =>
    dispatch(vehiclePartActions.insertVehiclePart(payload, meta)),
  updateVehiclePart: (vehiclePartCode, payload, meta) =>
    dispatch(vehiclePartActions.updateVehiclePart(vehiclePartCode, payload, meta)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WithConfig('vehiclePart')(WithLoading(VehiclePart)));
