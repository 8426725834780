/* eslint-disable import/no-unresolved */
import { Button, Table } from 'antd';
import React, { Component } from 'react';

import '../../../assets/css/style.css';
import './style.css';

const rowSelection = (onSelectChange, onSelectRow, onSelectAllRow) => ({
  onChange: (selectedRowKeys, record) => onSelectChange(selectedRowKeys, record),
  onSelect: (record, selected) => onSelectRow(record, selected),
  getCheckboxProps: (record) => ({
    name: record.name,
  }),
  onSelectAll: (selected, selectedRows, changeRows) =>
    onSelectAllRow(selected, selectedRows, changeRows),
  columnWidth: 10,
});

const columns = (collapseTable, isImportFromFile, onSelectRoute) => [
  // {
  //   title: <b>Mã lịch mẫu</b>,
  //   dataIndex: 'code',
  // },
  {
    title: <b>Lịch mẫu</b>,
    dataIndex: 'name',
    key: 'name',
    render: isImportFromFile
      ? (value) => value
      : (value, record) => (
          <Button
            type="link"
            onClick={onSelectRoute ? () => onSelectRoute(record) : null}
            key={`${record.code}-sample-route-name`}>
            {value}
          </Button>
        ),
  },
];

const expandcolumns = [
  {
    title: <b>Lái xe</b>,
    dataIndex: 'driver_code',
    key: 'driver_code',
    width: '150px',
    render: (value, record) => (record?.driver?.code ? record?.driver.name : value),
  },
  {
    title: <b>Khách hàng</b>,
    dataIndex: 'customer_codes',
    key: 'customer_codes',
    // width: '450px',
    render: (values, record) => {
      let str = '';
      if (record?.customers?.length)
        record.customers.forEach((customer) =>
          str.length ? (str += `; ${customer?.name}`) : (str += `${customer?.name}`),
        );
      else str = values.join('; ');

      return str;
    },
  },
];

class SampleRouteList extends Component {
  constructor(props) {
    super(props);

    this.state = { pageSize: 1 };
  }

  render() {
    const {
      dataSource,
      meta,
      onPageChange,
      isImportFromFile,
      onSelectChange,
      showRowSelection,
      onSelectRow,
      collapseTable,
      onSelectAllRow,
      onSelectRoute,
      mode,
    } = this.props;
    const sampleRouteColumns = columns(collapseTable, isImportFromFile, onSelectRoute);

    const expandedRowRender = (record, mode) => (
      <div className="expand-detail-route">
        <Table
          size="small"
          dataSource={record.sample_routes}
          columns={expandcolumns}
          pagination={false}
          rowKey="driver_code"
          scroll={{ y: mode === 'routing' && 410 }}
          style={{ marginLeft: '20px', maxWidth: '100%' }}
        />
      </div>
    );

    return dataSource?.length ? (
      <Table
        size="small"
        dataSource={dataSource}
        columns={sampleRouteColumns}
        rowKey={(record) =>
          isImportFromFile ? `${record?.name + record?.location?.address}` : record?.code
        }
        expandedRowRender={(record) => expandedRowRender(record, mode)}
        pagination={
          mode === 'routing'
            ? false
            : {
                current: meta?.page,
                pageSize: meta?.pageSize || 5,
                total: meta?.total,
                onChange: onPageChange,
                showSizeChanger: !showRowSelection,
                locale: { items_per_page: '/ trang' },
                showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total} lịch mẫu`,
              }
        }
        rowSelection={
          showRowSelection ? rowSelection(onSelectChange, onSelectRow, onSelectAllRow) : ''
        }
        // scroll={{ x: 'max-content' }}
      />
    ) : null;
  }
}

export default SampleRouteList;
