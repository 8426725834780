import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { DropTarget } from 'react-dnd';

import ItemTypes from '../constants';

const dropTarget = {
  drop(props, monitor) {
    const targetNodeIndex = props.nodeIndex;
    const targetRouteIndex = props.routeIndex;

    const dragNode = monitor.getItem();
    const sourceNodeIndex = dragNode.nodeIndex;
    const sourceRouteIndex = dragNode.routeIndex;

    if (
      targetRouteIndex === sourceRouteIndex &&
      (targetNodeIndex === sourceNodeIndex || targetNodeIndex === sourceNodeIndex - 1)
    )
      return;
    props.onChangeNodePosition(
      dragNode.node,
      { routeIndex: sourceRouteIndex, nodeIndex: sourceNodeIndex },
      { routeIndex: targetRouteIndex, nodeIndex: targetNodeIndex },
    );
  },
};

function collectDrop(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  };
}

class NodeConnector extends Component {
  calcTimeLineWidth = (time) => {
    if (time) {
      const timeInHr = time / 3600;
      const timeLineWidth = (timeInHr * 100) / 3 - 3;
      if (timeLineWidth < 0) return '1%';
      return `${timeLineWidth}%`;
    }
    return 50;
  };
  render() {
    const { connectDropTarget, isOver, mainColor, time } = this.props;
    return connectDropTarget(
      <div
        style={{
          height: 30,
          width: this.calcTimeLineWidth(time),
          display: 'inline-block',
        }}>
        <div
          style={{
            width: '100%',
            height: '100%',
            // borderBottom: `5px solid ${isOver ? isOverColor : color}`,
            borderBottom: `5px solid ${mainColor}`,
            opacity: isOver ? 0.2 : 1,
          }}
        />
      </div>,
    );
  }
}

NodeConnector.propTypes = {
  connectDropTarget: PropTypes.func.isRequired,
  isOver: PropTypes.bool.isRequired,
};

export default DropTarget(ItemTypes.NODE, dropTarget, collectDrop)(NodeConnector);
