import PropTypes from 'prop-types';
import React, { Component } from 'react';

function withRef(WrappedComponent) {
  class WithRef extends Component {
    render() {
      const { forwardedRef, ...rest } = this.props;

      // Assign the custom prop "forwardedRef" as a ref
      return <WrappedComponent ref={forwardedRef} {...rest} />;
    }
  }

  WithRef.propTypes = {
    forwardedRef: PropTypes.any.isRequired,
  };

  // eslint-disable-next-line react/no-multi-comp
  return React.forwardRef((props, ref) => <WithRef {...props} forwardedRef={ref} />);
}

export default withRef;
