/* eslint-disable no-lone-blocks */
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Layout, Menu, notification } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import ROUTER from '../../config/router';
import SIDEBAR_ITEMS from '../../config/sidebar';
import { actions as companyActions } from '../../redux/CompanyRedux';
import { actions as configActions } from '../../redux/ConfigurationRedux';

import '../../assets/css/style.css';
const { Sider } = Layout;
class SideBar extends Component {
  constructor() {
    super();
    this.state = {
      collapsed: false,
      openKey: '',
      initCollapsed: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { location } = props;
    if (
      (location.pathname === '/routing' || location.pathname === '/schedule') &&
      !state.initCollapsed
    ) {
      return { collapsed: true, initCollapsed: true };
    } else if (location.pathname !== '/routing' && state.initCollapsed) {
      return { initCollapsed: false };
    }

    return null;
  }

  componentDidMount() {
    const { location, history, role, companyCode } = this.props;
    if (!SIDEBAR_ITEMS()[role]) {
      notification.error({
        message: 'Tài khoản chưa được kích hoạt - Hãy thử đăng nhập tài khoản khác',
        duration: 0,
      });

      return history.push(ROUTER.NOT_FOUND);
    } else if (!location.pathname.startsWith(SIDEBAR_ITEMS()[role][0].to)) {
      return history.push(SIDEBAR_ITEMS()[role][0].to);
    }

    this.props.getConfiguration();
    if (companyCode) {
      this.props.getCurrentCompany(companyCode);
    }
    const path = location.pathname.slice(1);
    const link = path.split('/', 1);
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ oldPath: link[0] });
    this.findCurrentOpenKey();

    // if (this.props.location.pathname === '/routing') {
    //   this.onCollapse();
    // }
  }

  findCurrentOpenKey = () => {
    const { role } = this.props;

    const link = this.props.location.pathname.slice(1);

    const keypath = link.split('/', 1);
    const path = keypath[0];
    let foundKey = '';
    if (!this.state.collapsed) {
      // eslint-disable-next-line no-unused-expressions
      SIDEBAR_ITEMS()[role]?.forEach((item) => {
        // 0. Nếu đã tìm thấy ( foundKey != '' ) thì return luôn
        if (foundKey !== '') {
          return foundKey;
        }
        if (item.key === path) {
          foundKey = item.key;
        }
        if (item.children) {
          item.children.forEach((children) => {
            if (children.key === path) {
              foundKey = item.key;
            }
          });
        }
      });
    }
    this.setState({ openKey: foundKey });
    return foundKey;
  };

  onCollapse = () => {
    this.setState((prevState) => ({
      collapsed: !prevState.collapsed,
    }));
  };

  IconName(name) {
    const TagName = name;
    return name ? <TagName /> : null;
  }

  menuItemSelected = (e) => {
    const path = e.key;
    const coll = this.state.collapsed;
    if (coll === true) {
      this.setState({ openKey: '' });
    }
    this.setState({ oldPath: path });
    // this.props.getTitle(path);
  };

  subMenuSelected = (e) => {
    const path = e.key;
    this.setState({ openKey: path });
  };

  renderMenuItem = ({ key, to, icon, content }) => (
    <Menu.Item key={key} onClick={this.menuItemSelected}>
      <Link to={to}>
        {this.IconName(icon)}
        <span style={{ marginRight: '10px' }}>{content}</span>
      </Link>
    </Menu.Item>
  );
  renderSubMenu = ({ key, icon, content, children }) => (
    <Menu.SubMenu
      key={key}
      onTitleClick={this.subMenuSelected}
      title={
        <span>
          {this.IconName(icon)}
          <span>{content}</span>
        </span>
      }>
      {children.map((item) => this.renderMenuItem(item))}
    </Menu.SubMenu>
  );

  render() {
    const { role, companyCode, currentCompany, hasInventory } = this.props;
    const { collapsed } = this.state;
    const menuList = SIDEBAR_ITEMS()[role];
    if (role === 'admin' && !hasInventory) {
      // Only display "Inventory" when enabled
      delete menuList[8];
    }
    if (role === 'admin' && (!companyCode || currentCompany?.type !== 'multi')) {
      // Only display "Chi nhánh" for Tổng công ty/Trụ sở
      delete menuList[1];
    }

    return (
      <Sider
        style={{ background: '#001529' }}
        collapsible
        onCollapse={this.onCollapse}
        collapsed={collapsed}
        theme="dark">
        <div
          style={{
            margin: 24,
            textAlign: 'center',
          }}>
          <Link to="/">
            <img
              src={collapsed ? '/logoAnFast.png' : '/logoAnFast.png'}
              style={{ width: '100%' }}
              alt=""
            />
            {companyCode && currentCompany?.name ? (
              <h2 style={{ marginTop: 15, color: 'white', textAlign: 'center' }}>
                {currentCompany.name}
              </h2>
            ) : null}
          </Link>
        </div>

        <Menu
          style={{ background: '#001529', border: 'none' }}
          theme="dark"
          mode="inline"
          // onClick={this.handleClick}
          // inlineCollapsed
          selectedKeys={[this.state.oldPath]}>
          {menuList?.map((item) =>
            item.children ? this.renderSubMenu(item) : this.renderMenuItem(item),
          )}
          <hr />
          <Menu.Item>
            <a href="https://hdsd.anfast.vn" target="_blank">
              <QuestionCircleOutlined />
              <span style={{ marginRight: '10px' }}>HDSD</span>
            </a>
          </Menu.Item>
        </Menu>
      </Sider>
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mapStateToProps = ({ appReducer, authReducer, configurationReducer, companyReducer }) => ({
  role: authReducer.authUser.role_code,
  currentPage: appReducer.currentPage,
  authUser: authReducer.authUser,
  item: configurationReducer.item,
  isAuthenticated: authReducer.isAuthenticated,
  companyCode: authReducer.authUser.company_code,
  currentCompany: companyReducer.currentCompany,
  hasInventory: companyReducer.hasInventory,
  // allCompanies: companyReducer.allItems,
});

const mapDispatchToProps = (dispatch) => ({
  getConfiguration: () => dispatch(configActions.getConfiguration()),
  insertConfiguration: () => dispatch(configActions.insertConfiguration()),
  // getAllCompanies: () => dispatch(companyActions.getAllCompanies()),
  getCurrentCompany: (code) => dispatch(companyActions.getCompanyById(code)),
});

// export default WithLocale(connect(mapStateToProps)(SideBar));
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SideBar));
