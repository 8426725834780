import produce from 'immer';
import moment from 'moment';

import { isCallingApi, isSuccessfulApiCall, isFailedApiCall } from './actionDedicate';
import { PREFIX, typesWithPrefix } from './config';
import { API_URLS } from '../config/api';
import { apiCall } from '../utils/api';
const _types = typesWithPrefix(PREFIX.COMPANY);
const TYPE = {
  INVALIDATE_COMPANIES: _types('INVALIDATE_COMPANIES'),
  GET_COMPANIES: _types('GET_COMPANIES'),
  GET_ALL_COMPANIES: _types('GET_ALL_COMPANIES'),
  UPDATE_COMPANY: _types('UPDATE_COMPANY'),
  DELETE_COMPANY: _types('DELETE_COMPANY'),
  GET_COMPANY_BY_ID: _types('GET_COMPANY_BY_ID'),
  GET_COMPANIES_CHANGE_PAGE: _types('GET_COMPANIES_CHANGE_PAGE'),
};
export const actions = {
  //get list company
  getCompaniesIfNeed: (filterOption) => (dispatch, getState) => {
    const state = getState();
    const isFetching = state.companyReducer.isFetching;
    const didInvalidate = state.companyReducer.didInvalidate;
    const query = state.companyReducer.query;
    const meta = state.companyReducer.meta;
    const isQueryChanged =
      query.search !== filterOption.search ||
      query.type !== filterOption.type ||
      meta.page !== filterOption.page ||
      meta.page_size !== filterOption.page_size;
    if ((!isFetching && didInvalidate) || (!isFetching && isQueryChanged)) {
      dispatch(actions.getCompanies(filterOption));
    }
  },

  getCompanies: (query) => async (dispatch) => {
    const params = {
      page: query.page,
      page_size: query.page_size,
      search: query.search,
      type: query.type,
    };
    const api = API_URLS.COMPANY.getCompanies(params);
    dispatch(actions.gettingCompanies());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(
        actions.getCompaniesSuccess({
          items: response.data.data,
          meta: {
            page: response.data.page,
            page_size: response.data.page_size,
            total: response.data.total,
          },
          query: {
            search: params.search,
            type: params.type,
          },
        }),
      );
    } else {
      dispatch(actions.getCompaniesFailure(params));
    }
  },
  getCompany: (query) => async (dispatch) => {
    const params = {
      page: query.page,
      page_size: query.page_size,
      search: query.search,
      type: query.type,
    };
    const api = API_URLS.COMPANY.getCompany(params);
    dispatch(actions.gettingCompanies());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(
        actions.getCompaniesSuccess({
          items: response.data.data,
          meta: {
            page: response.data.page,
            page_size: response.data.page_size,
            total: response.data.total,
          },
          query: {
            search: params.search,
            type: params.type,
          },
        }),
      );
    } else {
      dispatch(actions.getCompaniesFailure(params));
    }
  },
  gettingCompanies: () => ({
    type: TYPE.GET_COMPANIES,
    meta: { prefix: [PREFIX.COMPANY, PREFIX.API_CALLING] },
  }),

  getCompaniesSuccess: (payload) => ({
    type: TYPE.GET_COMPANIES,
    payload,
    meta: {
      prefix: [PREFIX.COMPANY, PREFIX.API_CALLED_SUCCESS],
    },
  }),

  getCompaniesFailure: (query) => ({
    type: TYPE.GET_COMPANIES,
    query,
    meta: {
      prefix: [PREFIX.COMPANY, PREFIX.API_CALLED_FAILURE],
    },
  }),

  invalidateCompanies: () => ({
    type: TYPE.INVALIDATE_COMPANIES,
    meta: {
      prefix: [PREFIX.COMPANY, PREFIX.API_CALLED_SUCCESS],
    },
  }),

  // get company by id
  gettingCompanyById: () => ({
    type: TYPE.GET_COMPANY_BY_ID,
    meta: { prefix: [PREFIX.COMPANY, PREFIX.API_CALLING] },
  }),

  getCompanyByIdSuccess: (payload, meta) => ({
    type: TYPE.GET_COMPANY_BY_ID,
    payload,
    meta: {
      prefix: [PREFIX.COMPANY, PREFIX.API_CALLED_SUCCESS],
      ...meta,
    },
  }),

  getCompanyByIdFailure: () => ({
    type: TYPE.GET_COMPANY_BY_ID,
    meta: { prefix: [PREFIX.COMPANY, PREFIX.API_CALLED_FAILURE] },
  }),

  getCompanyById: (companyId) => async (dispatch) => {
    const api = API_URLS.COMPANY.getCompanyById(companyId);
    dispatch(actions.gettingCompanyById());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      const payload = response.data.data;

      dispatch(actions.getCompanyByIdSuccess(payload));
    } else {
      dispatch(actions.getCompanyByIdFailure(error));
    }
  },

  //GET ALL COMPANIES
  getAllCompanies: () => async (dispatch) => {
    const params = {
      page: -1,
      page_size: -1,
    };
    const api = API_URLS.COMPANY.getCompanies(params);
    dispatch(actions.gettingAllCompanies());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(
        actions.getAllCompaniesSuccess({
          items: response.data.data,
        }),
      );
    } else {
      dispatch(actions.getAllCompaniesFailure(params));
    }
  },
  gettingAllCompanies: () => ({
    type: TYPE.GET_ALL_COMPANIES,
    meta: { prefix: [PREFIX.COMPANY, PREFIX.API_CALLING] },
  }),

  getAllCompaniesSuccess: (payload) => ({
    type: TYPE.GET_ALL_COMPANIES,
    payload,
    meta: {
      prefix: [PREFIX.COMPANY, PREFIX.API_CALLED_SUCCESS],
    },
  }),

  getAllCompaniesFailure: (query) => ({
    type: TYPE.GET_ALL_COMPANIES,
    query,
    meta: {
      prefix: [PREFIX.COMPANY, PREFIX.API_CALLED_FAILURE],
    },
  }),

  // update company
  updatingCompany: () => ({
    type: TYPE.UPDATE_COMPANY,
    meta: { prefix: [PREFIX.COMPANY, PREFIX.API_CALLING] },
  }),
  updateCompanySuccess: () => ({
    type: TYPE.UPDATE_COMPANY,
    meta: {
      prefix: [PREFIX.COMPANY, PREFIX.API_CALLED_SUCCESS],
    },
  }),
  updateCompanyFailure: () => ({
    type: TYPE.UPDATE_COMPANY,
    meta: {
      prefix: [PREFIX.COMPANY, PREFIX.API_CALLED_FAILURE],
    },
  }),
  updateCompany: (companyId, payload, meta) => async (dispatch) => {
    const api = API_URLS.COMPANY.updateCompany(companyId, payload);
    dispatch(actions.updatingCompany());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(actions.updateCompanySuccess(response.data));
      dispatch(actions.getCompanies({ page: 1, page_size: 10 }));
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
    } else {
      dispatch(actions.updateCompanyFailure(error));
      if (meta && meta.onError) {
        meta.onError(error);
      }
    }
  },
  updateCompanyFeatures: (companyId, payload, meta) => async (dispatch) => {
    const api = API_URLS.COMPANY.updateCompanyFeatures(companyId, payload);
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(actions.getCompanyById(companyId));
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
    } else {
      if (meta && meta.onError) {
        meta.onError(error);
      }
    }
  },

  // Delete company
  deletingCompany: () => ({
    type: TYPE.DELETE_COMPANY,
    meta: { prefix: [PREFIX.COMPANY, PREFIX.API_CALLING] },
  }),

  deleteCompanySuccess: () => ({
    type: TYPE.DELETE_COMPANY,
    meta: {
      prefix: [PREFIX.COMPANY, PREFIX.API_CALLED_SUCCESS],
    },
  }),

  deleteCompanyFailure: () => ({
    type: TYPE.DELETE_COMPANY,
    meta: {
      prefix: [PREFIX.COMPANY, PREFIX.API_CALLED_FAILURE],
    },
  }),

  deleteCompany: (companyId, meta) => async (dispatch) => {
    const api = API_URLS.COMPANY.deleteCompany(companyId);
    dispatch(actions.deletingCompany());
    const { response, error } = await apiCall({ ...api });
    if (
      !error &&
      response.status === 200 &&
      response.data.success === true &&
      response.data.success === true
    ) {
      dispatch(actions.deleteCompanySuccess(response.data));
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
    } else {
      dispatch(actions.deleteCompanyFailure(error));
      if (meta && meta.onError) {
        meta.onError(error);
      }
    }
  },

  // insert company
  insertCompany: (payload, meta) => async (dispatch) => {
    const api = API_URLS.COMPANY.insertCompany(payload);
    const params = {
      page: 1,
      page_size: 10,
      search: '',
    };
    const { response, error } = await apiCall(api);
    if (
      !error &&
      response.data.success === true &&
      (response.status === 200 || response.status === 201)
    ) {
      if (meta && meta.onSuccess) {
        meta.onSuccess();
        dispatch(actions.getCompanies(params));
      }
    } else if (meta && meta.onError) {
      meta.onError(error);
    }
    return { response, error };
  },

  changePage: (page, page_size, total) => ({
    type: TYPE.GET_COMPANIES_CHANGE_PAGE,
    payload: {
      page,
      page_size,
      total,
    },
    meta: {
      prefix: [PREFIX.COMPANY],
    },
  }),
};

const initialState = {
  isFetching: false,
  didInvalidate: true,
  lastUpdated: moment().unix(),
  currentCompany: {},
  hasTransportation: true,
  hasInventory: false,
  allItems: [],
  items: [],
  query: {},
  meta: {
    total: 0,
    page: 1,
    page_size: 10,
  },
};

export const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case TYPE.GET_COMPANIES:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          const { items, meta, query } = action.payload;
          draft.isFetching = false;
          draft.didInvalidate = false;
          draft.lastUpdated = moment.unix();
          draft.items = items;
          draft.meta = meta;
          draft.query = query;
        } else if (isFailedApiCall(action)) {
          draft.isFetching = false;
          draft.didInvalidate = false;
        }
        break;
      case TYPE.GET_COMPANY_BY_ID:
        if (isSuccessfulApiCall(action)) {
          draft.currentCompany = action.payload;
          if (action.payload?.features?.length) {
            draft.hasTransportation = action.payload.features.includes('Transportation');
            draft.hasInventory = action.payload.features.includes('Inventory');
          } else {
            draft.hasTransportation = false;
            draft.hasInventory = false;
          }
        }
        break;
      case TYPE.INVALIDATE_COMPANIES:
        draft.didInvalidate = true;
        break;
      case TYPE.GET_COMPANIES_CHANGE_PAGE:
        draft.meta = {
          page: action.payload.page,
          page_size: action.payload.page_size,
          total: action.payload.total,
        };
        break;
      case TYPE.GET_ALL_COMPANIES:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          const { items } = action.payload;
          draft.isFetching = false;
          draft.didInvalidate = false;
          draft.allItems = items;
        } else if (isFailedApiCall(action)) {
          draft.isFetching = false;
          draft.didInvalidate = false;
        }
        break;
      case TYPE.UPDATE_COMPANY:
      case TYPE.DELETE_COMPANY:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        }
        if (isSuccessfulApiCall(action)) {
          draft.isFetching = false;
          draft.didInvalidate = true;
          draft.lastUpdated = moment.unix();
        }
        if (isFailedApiCall(action)) {
          draft.isFetching = false;
          draft.didInvalidate = false;
        }
        break;
      default:
        return draft;
    }
  });
