import lodash from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { actions as featureActions } from '../../redux/FeatureRedux';
import SelectSearch from './Select';

const { getAllFeatures } = featureActions;

class FeatureSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prevSelectedValue: [],
      defaultValue: [],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.allFeatures && nextProps.allFeatures.length) {
      if (!lodash.isEqual(nextProps.selectedValue, prevState.prevSelectedValue)) {
        const nextState = { prevSelectedValue: nextProps.selectedValue };
        if (nextProps.selectedValue && nextProps.selectedValue.length) {
          const { selectedValue } = nextProps;
          if (typeof selectedValue === 'string') {
            nextState.defaultValue = FeatureSearch.getFeatureByCode([selectedValue], nextProps);
          } else if (typeof selectedValue === 'object') {
            if (Array.isArray(selectedValue)) {
              if (typeof selectedValue[0] === 'string') {
                nextState.defaultValue = FeatureSearch.getFeatureByCode(selectedValue, nextProps);
              } else if (typeof selectedValue[0] === 'object') {
                nextState.defaultValue = selectedValue;
              }
            } else {
              nextState.defaultValue = selectedValue;
            }
          }
        } else {
          nextState.defaultValue = [];
        }
        return nextState;
      }
    }
    return null;
  }

  componentDidMount() {
    this.props.getAllFeatures();
  }

  // searchProductByName = async (name) => {
  //   const params = {
  //     search: name,
  //     page: 1,
  //     page_size: 50,
  //   };
  // const api = API_URLS.PRODUCT.getFilteredProducts(params);
  //   const { response, error } = await apiCall(api);
  //   if (!error && response.status === 200) {
  //     if (response.data.data && response.data.data.length) {
  //       return response.data.data.map((product) => ({
  //         key: product.code,
  //         label: `${product.name}`,
  //         // defaultValue: response.data.data,
  //       }));
  //     }
  //   }

  //   return [];
  // };

  static getFeatureByCode = (codes, props) => {
    if (codes && codes.length) {
      const { allFeatures } = props;
      if (!allFeatures.length) {
        return [];
      }
      const defaultValue = [];
      codes.forEach((code) => {
        const featureCode = code.trim();
        if (!featureCode) return;
        const foundFeature = allFeatures.find((p) => p.code === featureCode);
        const feature = foundFeature
          ? {
              key: featureCode,
              name: `${foundFeature.name}`,
            }
          : { key: featureCode, name: `[Not found] ${featureCode}` };
        defaultValue.push(feature);
      });
      return defaultValue;
    }
    return [];
  };

  render() {
    const { allFeatures, onChange } = this.props;
    const dataSource = allFeatures;
    return (
      <SelectSearch
        {...this.props}
        onChange={onChange}
        selectedValue={this.state.defaultValue}
        dataSource={dataSource}
        placeholder={this.props.placeholder || 'Chọn đặc tính'}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  allFeatures: state.featureReducer.allItems,
});

const mapDispatchToProps = (dispatch) => ({
  getAllFeatures: () => dispatch(getAllFeatures()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeatureSearch);
