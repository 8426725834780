import { PlusOutlined } from '@ant-design/icons';
import { Button, Divider } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import ModalCancelVoucher from './EditForm/Modal';
import Filter from './components/Filter';
import ListCancelVoucher from './components/List';
import ROUTER from '../../config/router';
import { actions as cancelVoucherActions } from '../../redux/CancelVoucherRedux';
import { actions as wareHouseActions } from '../../redux/WareHousesRedux';

const CancelVoucher = ({
  getCancelVoucher,
  history,
  cancelVoucherData,
  meta,
  isFetching,
  changeState,
  wareHouseData,
  getInvetory,
}) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [state, setState] = useState();
  const [productCodes, setProductCodes] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [cuCancelVoucher, setcuCancelVoucher] = useState({});

  useEffect(() => {
    getCancelVoucher({
      page,
      page_size: pageSize,
      state,
      product_codes: productCodes,
    });
  }, [page, pageSize, state, productCodes]);

  useEffect(() => {
    getInvetory({
      page: -1,
      page_size: -1,
    });
  }, []);

  const handleAdd = () => {
    history.push(ROUTER.INVENTORY.ADD_CANCELLATION_VOUCHER);
  };

  const onPageChange = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
  };

  const handleChangeState = (value) => {
    setState(value);
  };

  const handleChangeProductCodes = (value) => {
    setProductCodes(value);
  };

  const handleOpenModal = () => {
    setOpenModal(!openModal);
  };

  const getCancelVoucherByCode = (cancelCode, payload) => {
    handleOpenModal();
    setcuCancelVoucher(payload);
  };

  return (
    <>
      <div>
        <h2 style={{ float: 'left' }}>Danh sách phiếu hủy</h2>
        <div style={{ float: 'right' }}>
          <Button shape="round" type="primary" onClick={handleAdd} icon={<PlusOutlined />}>
            Thêm
          </Button>
        </div>
      </div>
      <Divider style={{ marginTop: 0 }} />

      <div style={{ marginBottom: '15px' }}>
        <Filter
          onChangeStatus={handleChangeState}
          onChangeProductCodes={handleChangeProductCodes}
        />
      </div>
      <ListCancelVoucher
        data={cancelVoucherData}
        meta={meta}
        isFetching={isFetching}
        onPageChange={onPageChange}
        changeState={changeState}
        getCancelVoucher={getCancelVoucher}
        getCancelVoucherByCode={getCancelVoucherByCode}
      />

      <ModalCancelVoucher
        item={cuCancelVoucher}
        toggleModalVoucher={handleOpenModal}
        wareHouseData={wareHouseData}
        visibleModal={openModal}
        getCancelVoucher={getCancelVoucher}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  cancelVoucherData: state?.cancelVoucherReducer?.data,
  meta: state?.cancelVoucherreducer?.meta,
  isFetching: state?.cancelVoucherreducer?.isFetching,
  wareHouseData: state?.wareHousesReducer?.data,
});

const mapDispatchToProps = (dispatch) => ({
  getCancelVoucher: (params) => dispatch(cancelVoucherActions.getCancelVoucher(params)),
  getInvetory: (params) => dispatch(wareHouseActions.getInvetory(params)),
  changeState: (payload, meta) => dispatch(cancelVoucherActions.changeState({ payload, meta })),
});

export default connect(mapStateToProps, mapDispatchToProps)(CancelVoucher);
