/* eslint-disable no-useless-escape */
import { CheckOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  Button,
  Select, // Icon, Divider, Tag, Row, Col,
} from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import CompanySearch from '../../../components/FormItems/CompanySearch';
import { actions as authActions } from '../../../redux/AccountRedux';

const { Item } = Form;
const { Option } = Select;
const layout = {
  labelCol: {
    span: 24,
  },
};
// const { getAllStations } = stationActions;
class UserForm extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      formRef: this.formRef,
      role_code: '',
      station: '',
      roles: [],
    };
  }
  onReset = () => {
    this.formRef.current.resetFields();
  };

  changeRole = (value) => {
    this.setState({ role_code: value });
  };

  onFinish = (values) => {
    if (!this.state.role_code) {
      this.props.emitData({
        ...values,
        // role_code: this.props.roledefault.toLowerCase(),
      });
      this.formRef.current.resetFields();
    } else {
      this.props.emitData({
        ...values,
      });
      this.formRef.current.resetFields();
    }
  };

  validateUsername = (rule, value, callback) => {
    const { duplicateUsernameValue } = this.props;
    const re = /^[a-zA-Z0-9]+([_\.][a-zA-Z0-9]+)*$/;
    if (value && !re.test(value)) {
      callback('Tên tài khoản không hợp lệ');
    } else if (value && value === duplicateUsernameValue) {
      callback('Trùng lặp tài khoản');
    } else {
      callback();
    }
  };

  validateEmail = (rule, value, callback) => {
    const re = /^[a-z][a-z0-9]*([_\.][a-z0-9]+)*@[a-z0-9]+(\.[a-z0-9]+)+$/;
    if (value && !re.test(value)) {
      callback('Email không hợp lệ');
    } else {
      callback();
    }
  };

  validatePhoneNumber = (rule, value, callback) => {
    const re = /^(0)[1-9]([0-9]{8}[0-9]?)\b/;
    if (value && !re.test(value)) {
      callback('Số điện thoại không hợp lệ');
    } else {
      callback();
    }
  };

  validateLengthPassword = (rule, value, callback) => {
    if (value && value.length < 6) {
      callback('Mật khẩu không thể ít hơn 6 kí tự');
    } else {
      callback();
    }
  };

  compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== this.formRef.current.getFieldValue('password')) {
      callback('Hãy điền đúng mật khẩu đã tạo ở trên');
    } else {
      callback();
    }
  };

  render() {
    let { roledefault } = this.props;
    if (roledefault === 'Admin') {
      roledefault = '';
    }

    const { allowedRoles } = this.props;

    return (
      <Form onFinish={this.onFinish} ref={this.formRef} {...layout} autoComplete="off">
        <Item
          label="Tên"
          name="full_name"
          rules={[
            {
              required: true,
              message: `Hãy nhập tên tài khoản`,
            },
          ]}>
          <Input placeholder="Nhập tên" />
        </Item>
        <Item
          label="Số điện thoại"
          name="phone_number"
          rules={[
            {
              // required: true,
              message: `Hãy nhập số điện thoại`,
            },
            {
              validator: this.validatePhoneNumber,
            },
          ]}>
          <Input placeholder="Nhập số điện thoại" />
        </Item>
        <Item
          label="Email"
          name="email"
          rules={[
            {
              // required: true,
              message: `Hãy nhập email`,
            },
            {
              validator: this.validateEmail,
            },
          ]}>
          <Input placeholder="Nhập Email" />
        </Item>
        <Item
          label="Tài khoản"
          name="username"
          rules={[
            {
              required: true,
              message: `Hãy nhập tài khoản người dùng`,
            },
            {
              validator: this.validateUsername,
            },
          ]}>
          <Input placeholder="Nhập tài khoản người dùng" />
        </Item>
        <Item
          label="Mật khẩu"
          name="password"
          rules={[
            {
              required: true,
              message: `Hãy nhập mật khẩu`,
            },
            {
              validator: this.validateLengthPassword,
            },
          ]}>
          <Input type="password" placeholder="Nhập mật khẩu" />
        </Item>
        <Item
          label="Xác nhận mật khẩu"
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: `Hãy nhập xác nhận mật khẩu`,
            },
            {
              validator: this.compareToFirstPassword,
            },
          ]}>
          <Input type="password" placeholder="Nhập xác nhận mật khẩu" />
        </Item>
        <Item
          // style={{ display: this.state.role === 'Admin' ? 'none' : 'flex' }}
          label="Quyền người dùng"
          name="role_code"
          rules={[
            {
              required: true,
              message: 'Hãy chọn quyền người dùng',
            },
          ]}>
          <Select
            placeholder="Chọn quyền người dùng"
            onChange={this.changeRole}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }>
            {allowedRoles.map((item) => (
              <Option value={item.code} key={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Item>
        {!this.props.company_code ? (
          <Item
            label="Công ty"
            name="company_code"
            rules={[
              {
                required: this.formRef.current?.getFieldValue('role_code') !== 'super-admin',
                message: 'Hãy chọn công ty',
              },
            ]}>
            <CompanySearch
              allowClear={false}
              disabled={this.formRef.current?.getFieldValue('role_code') === 'super-admin'}
            />
          </Item>
        ) : (
          <Item
            style={{ display: 'none' }}
            label="Công ty"
            name="company_code"
            initialValue={this.props.company_code}
            rules={[{ message: 'Hãy chọn công ty' }]}>
            <Input defaultValue={this.props.company_code} />
          </Item>
        )}

        <Item style={{ float: 'right', marginTop: '10px' }}>
          <Button type="primary" htmlType="submit" icon={<CheckOutlined />} shape="round">
            Lưu
          </Button>
          &nbsp;
        </Item>
        <br />
      </Form>
    );
  }
}
const mapStateToProps = (state) => {
  const { authReducer, companyReducer } = state;
  return {
    authUser1: authReducer.authUser,
    companies: companyReducer.items,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllStations: ({ params }) => dispatch(getAllStations(params)),
  login: (username, password, remember) =>
    dispatch(authActions.login(username, password, remember)),
});
export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
