import produce from 'immer';
import moment from 'moment';

import { API_URLS } from '../config/api';
import { apiCall } from '../utils/api';
import { isCallingApi, isSuccessfulApiCall, isFailedApiCall } from './actionDedicate';
import { PREFIX, typesWithPrefix } from './config';
const _types = typesWithPrefix(PREFIX.VEHICLE);
const TYPE = {
  INVALIDATE_VEHICLES: _types('INVALIDATE_VEHICLES'),
  GET_VEHICLES: _types('GET_VEHICLES'),
  GET_ALL_VEHICLES: _types('GET_ALL_VEHICLES'),
  UPDATE_VEHICLE: _types('UPDATE_VEHICLE'),
  DELETE_VEHICLE: _types('DELETE_VEHICLE'),
  GET_VEHICLE_BY_ID: _types('GET_VEHICLE_BY_ID'),
  GET_VEHICLES_CHANGE_PAGE: _types('GET_VEHICLES_CHANGE_PAGE'),
};
export const actions = {
  //get list vehicle
  getVehiclesIfNeed: (filterOption) => (dispatch, getState) => {
    const state = getState();
    const isFetching = state.vehicleReducer.isFetching;
    const didInvalidate = state.vehicleReducer.didInvalidate;
    const query = state.vehicleReducer.query;
    const isQueryChanged =
      query.vehicle_type !== filterOption.vehicle_type || query.search !== filterOption.search;
    if ((!isFetching && didInvalidate) || (!isFetching && isQueryChanged)) {
      dispatch(actions.getVehicles(filterOption));
    }
  },

  getVehicles: (query) => async (dispatch) => {
    const params = {
      page: query.page,
      page_size: query.page_size,
      search: query.search,
      vehicle_type: query.vehicle_type,
    };
    const api = API_URLS.VEHICLE.getVehicles(params);
    dispatch(actions.gettingVehicles());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(
        actions.getVehiclesSuccess({
          items: response.data.data,
          meta: {
            page: response.data.page,
            page_size: response.data.page_size,
            total: response.data.total,
          },
          query: {
            search: params.search,
            vehicle_type: params.vehicle_type,
          },
        }),
      );
    } else {
      dispatch(actions.getVehiclesFailure(params));
    }
  },
  gettingVehicles: () => ({
    type: TYPE.GET_VEHICLES,
    meta: { prefix: [PREFIX.VEHICLE, PREFIX.API_CALLING] },
  }),

  getVehiclesSuccess: (payload) => ({
    type: TYPE.GET_VEHICLES,
    payload,
    meta: {
      prefix: [PREFIX.VEHICLE, PREFIX.API_CALLED_SUCCESS],
    },
  }),

  getVehiclesFailure: (query) => ({
    type: TYPE.GET_VEHICLES,
    query,
    meta: {
      prefix: [PREFIX.VEHICLE, PREFIX.API_CALLED_FAILURE],
    },
  }),

  invalidateVehicles: () => ({
    type: TYPE.INVALIDATE_VEHICLES,
    meta: {
      prefix: [PREFIX.VEHICLE, PREFIX.API_CALLED_SUCCESS],
    },
  }),

  // get vehicle by id
  getVehicleById: (vehicleId, meta) => async (dispatch) => {
    dispatch(actions.gettingVehicleDetail());
    const api = API_URLS.VEHICLE.getVehicleById(vehicleId);
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      // const data = response.data.data;
      dispatch(actions.getVehicleDetailSuccess(response.data.data));
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
    } else {
      dispatch(actions.getVehicleDetailFailure());
      if (meta && meta.onError) {
        meta.onError(error);
      }
    }
  },
  gettingVehicleDetail: () => ({
    type: TYPE.GET_VEHICLE_BY_ID,
    meta: { prefix: [PREFIX.VEHICLE, PREFIX.API_CALLING] },
  }),
  getVehicleDetailSuccess: (payload) => ({
    type: TYPE.GET_VEHICLE_BY_ID,
    meta: { prefix: [PREFIX.VEHICLE, PREFIX.API_CALLED_SUCCESS] },
    payload,
  }),
  getVehicleDetailFailure: () => ({
    type: TYPE.GET_VEHICLE_BY_ID,
    meta: { prefix: [PREFIX.VEHICLE, PREFIX.API_CALLED_FAILURE] },
  }),

  //GET ALL VEHICLES
  getAllVehicles: () => async (dispatch) => {
    const params = {
      page: -1,
      page_size: -1,
    };
    const api = API_URLS.VEHICLE.getVehicles(params);
    dispatch(actions.gettingAllVehicles());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(
        actions.getAllVehiclesSuccess({
          items: response.data.data,
        }),
      );
    } else {
      dispatch(actions.getAllVehiclesFailure(params));
    }
  },
  gettingAllVehicles: () => ({
    type: TYPE.GET_ALL_VEHICLES,
    meta: { prefix: [PREFIX.VEHICLE, PREFIX.API_CALLING] },
  }),

  getAllVehiclesSuccess: (payload) => ({
    type: TYPE.GET_ALL_VEHICLES,
    payload,
    meta: {
      prefix: [PREFIX.VEHICLE, PREFIX.API_CALLED_SUCCESS],
    },
  }),

  getAllVehiclesFailure: (query) => ({
    type: TYPE.GET_ALL_VEHICLES,
    query,
    meta: {
      prefix: [PREFIX.VEHICLE, PREFIX.API_CALLED_FAILURE],
    },
  }),

  // update vehicle
  updatingVehicle: () => ({
    type: TYPE.UPDATE_VEHICLE,
    meta: { prefix: [PREFIX.VEHICLE, PREFIX.API_CALLING] },
  }),
  updateVehicleSuccess: () => ({
    type: TYPE.UPDATE_VEHICLE,
    meta: {
      prefix: [PREFIX.VEHICLE, PREFIX.API_CALLED_SUCCESS],
    },
  }),
  updateVehicleFailure: () => ({
    type: TYPE.UPDATE_VEHICLE,
    meta: {
      prefix: [PREFIX.VEHICLE, PREFIX.API_CALLED_FAILURE],
    },
  }),
  updateVehicle: (vehicleId, payload, meta) => async (dispatch) => {
    const api = API_URLS.VEHICLE.updateVehicle(vehicleId, payload);
    dispatch(actions.updatingVehicle());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(actions.updateVehicleSuccess(response.data));
      dispatch(actions.getVehicles({ page: 1, page_size: 10 }));
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
    } else {
      dispatch(actions.updateVehicleFailure(error));
      if (meta && meta.onError) {
        meta.onError(error);
      }
    }
  },

  // Delete vehicle
  deletingVehicle: () => ({
    type: TYPE.DELETE_VEHICLE,
    meta: { prefix: [PREFIX.VEHICLE, PREFIX.API_CALLING] },
  }),

  deleteVehicleSuccess: () => ({
    type: TYPE.DELETE_VEHICLE,
    meta: {
      prefix: [PREFIX.VEHICLE, PREFIX.API_CALLED_SUCCESS],
    },
  }),

  deleteVehicleFailure: () => ({
    type: TYPE.DELETE_VEHICLE,
    meta: {
      prefix: [PREFIX.VEHICLE, PREFIX.API_CALLED_FAILURE],
    },
  }),

  deleteVehicle: (vehicleId, meta) => async (dispatch) => {
    const api = API_URLS.VEHICLE.deleteVehicle(vehicleId);
    dispatch(actions.deletingVehicle());
    const { response, error } = await apiCall({ ...api });
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(actions.deleteVehicleSuccess(response.data));
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
    } else {
      dispatch(actions.deleteVehicleFailure(error));
      if (meta && meta.onError) {
        meta.onError(error);
      }
    }
  },

  // insert vehicle
  insertVehicle: (payload, meta) => async (dispatch) => {
    const api = API_URLS.VEHICLE.insertVehicle(payload);
    const params = {
      page: 1,
      page_size: 10,
      search: '',
    };
    const { response, error } = await apiCall(api);
    if (!error && (response.status === 200 || response.status === 201)) {
      if (meta && meta.onSuccess) {
        meta.onSuccess();
        dispatch(actions.getVehicles(params));
      }
    } else if (meta && meta.onError) {
      meta.onError(error);
    }
    return { response, error };
  },

  changePage: (page, page_size, total) => ({
    type: TYPE.GET_VEHICLES_CHANGE_PAGE,
    payload: {
      page,
      page_size,
      total,
    },
    meta: {
      prefix: [PREFIX.VEHICLE],
    },
  }),
};

const initialState = {
  isFetching: false,
  didInvalidate: true,
  lastUpdated: moment().unix(),
  currentVehicle: {},
  allItems: [],
  items: [],
  query: {},
  meta: {
    total: 0,
    page: 1,
    page_size: 10,
  },
};

export const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case TYPE.GET_VEHICLES:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          const { items, meta, query } = action.payload;
          draft.isFetching = false;
          draft.didInvalidate = false;
          draft.lastUpdated = moment.unix();
          draft.items = items;
          draft.meta = meta;
          draft.query = query;
        } else if (isFailedApiCall(action)) {
          draft.isFetching = false;
          draft.didInvalidate = false;
        }
        break;
      case TYPE.GET_VEHICLE_BY_ID:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          draft.currentVehicle = action.payload;
          draft.isFetching = false;
        } else if (isFailedApiCall(action)) {
          draft.isFetching = false;
          draft.didInvalidate = false;
        }
        break;
      case TYPE.INVALIDATE_VEHICLES:
        draft.didInvalidate = true;
        break;
      case TYPE.GET_VEHICLES_CHANGE_PAGE:
        draft.meta = {
          page: action.payload.page,
          page_size: action.payload.page_size,
          total: action.payload.total,
        };
        break;
      case TYPE.GET_ALL_VEHICLES:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          const { items } = action.payload;
          draft.isFetching = false;
          draft.didInvalidate = false;
          draft.allItems = items;
        } else if (isFailedApiCall(action)) {
          draft.isFetching = false;
          draft.didInvalidate = false;
        }
        break;
      case TYPE.UPDATE_VEHICLE:
      case TYPE.DELETE_VEHICLE:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        }
        if (isSuccessfulApiCall(action)) {
          draft.isFetching = false;
          draft.didInvalidate = true;
          draft.lastUpdated = moment.unix();
        }
        if (isFailedApiCall(action)) {
          draft.isFetching = false;
          draft.didInvalidate = false;
        }
        break;
      default:
        return draft;
    }
  });
