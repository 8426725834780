import { UnorderedListOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Table, Switch } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import Filter from './Filter';
import WithLoadingHOC from '../../../../hoc/WithLoading/index';

const columns = (onSetEditRole, onActiveDeactiveRole, onGetIdListAPI) => [
  {
    title: <b>Mã</b>,
    dataIndex: 'code',
    render: (value, record) => {
      const edit = () => {
        onSetEditRole(record.id, {
          name: record.name,
          code: record.code,
          description: record.description,
        });
      };

      return (
        <Button className="btnCode" onClick={edit}>
          {record.code}
        </Button>
      );
    },
  },
  {
    title: <b>Tên</b>,
    dataIndex: 'name',
  },
  {
    title: <b>Ghi chú</b>,
    dataIndex: 'description',
  },
  {
    title: <b>Trạng thái</b>,
    dataIndex: 'status',
    align: 'center',
    render: (value, record) => (
      <Switch defaultChecked={value} onChange={() => onActiveDeactiveRole(record.id)} />
    ),
  },
  {
    title: <b>Danh sách API </b>,
    dataIndex: 'edit',
    align: 'center',
    render: (value, record) => (
      <Button
        icon={<UnorderedListOutlined />}
        shape="circle"
        type="primary"
        onClick={() => onGetIdListAPI({ id: record.id })}
      />
    ),
  },
];

const RoleTab = ({
  dataSource,
  meta: { page, page_size, total },
  onShowSizeChange,
  onChange,
  onSetAddRole,
  onSetEditRole,
  onActiveDeactiveRole,
  onGetIdListAPI,
  onChangeFilter,
}) => (
  <>
    <Button
      shape="round"
      type="primary"
      onClick={onSetAddRole}
      style={{ float: 'right', marginBottom: 20 }}
      icon={<PlusOutlined />}>
      Thêm vai trò mới
    </Button>
    <Filter onSearch={onChangeFilter} />
    <Table
      className="tableCode"
      columns={columns(onSetEditRole, onActiveDeactiveRole, onGetIdListAPI)}
      dataSource={dataSource}
      rowKey="id"
      size="small"
      scroll={{ x: 'max-content' }}
      rowClassName={(r, idx) => (idx % 2 === 0 ? 'even' : '')}
      pagination={{
        current: page,
        page_size,
        total,
        showSizeChanger: true,
        onShowSizeChange,
        onChange,
        page_sizeOptions: ['10', '20', '40'],
        showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total}`,
      }}
    />
  </>
);

RoleTab.propTypes = {
  dataSource: PropTypes.array,
  meta: PropTypes.object,
  onSetAddRole: PropTypes.func,
  onSetEditRole: PropTypes.func,
  onActiveDeactiveRole: PropTypes.func,
  onGetIdListAPI: PropTypes.func,
};

RoleTab.defaultProps = {
  dataSource: [],
  meta: {},
  onSetAddRole: () => {},
  onSetEditRole: () => {},
  onActiveDeactiveRole: () => {},
  onGetIdListAPI: () => {},
};

export default WithLoadingHOC(RoleTab);
