import { Col, Row, Select } from 'antd';
import React from 'react';

import ProductSearch from '../../../components/FormItems/ProductSearch';

const Filter = ({ onChangeStatus, onChangeProductCodes }) => (
  <>
    <Row gutter={15}>
      <Col span={12}>
        <ProductSearch mode="multiple" allowClear onChange={onChangeProductCodes} />
      </Col>
      <Col span={12}>
        <Select
          style={{ width: '100%' }}
          placeholder="Trạng thái"
          onChange={onChangeStatus}
          allowClear>
          <Select.Option value="processing">Đang xử lý</Select.Option>
          <Select.Option value="completed">Đã hoàn thành</Select.Option>
          <Select.Option value="cancelled">Đã hủy</Select.Option>
        </Select>
      </Col>
      {/* */}
    </Row>
  </>
);

export default Filter;
