import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import moment from 'moment';

import { PREFIX, typesWithPrefix } from './config';
import { API_URLS } from '../config/api';
import { apiCallPromise } from '../utils/api';

const _types = typesWithPrefix(PREFIX.CUSTOMER);
const types = {
  INVALIDATE_CUSTOMERS: _types('INVALIDATE_CUSTOMERS'),
  GET_CUSTOMERS: _types('GET_CUSTOMERS'),
  GET_ALL_CUSTOMERS: _types('GET_ALL_CUSTOMERS'),
  INSERT_CUSTOMER: _types('INSERT_CUSTOMER'),
  INSERT_MANY_CUSTOMERS: _types('INSERT_MANY_CUSTOMERS'),
  UPDATE_CUSTOMER: _types('UPDATE_CUSTOMER'),
  DELETE_CUSTOMER: _types('DELETE_CUSTOMER'),
  GET_CUSTOMER_BY_ID: _types('GET_CUSTOMER_BY_ID'),
  GET_CUSTOMERS_CHANGE_PAGE: _types('GET_CUSTOMERS_CHANGE_PAGE'),
  CHANGE_STATE: _types('CHANGE_STATE'),
};

// export const customerActions = {
//   //get list customer
//   getCustomersIfNeed: (filterOption) => (dispatch, getState) => {
//     const state = getState();
//     const isFetching = state.customerReducer.isFetching;
//     const didInvalidate = state.customerReducer.didInvalidate;
//     const query = state.customerReducer.query;
//     const isQueryChanged =
//       query.search !== filterOption.search ||
//       query.gender !== filterOption.gender ||
//       query.status !== filterOption.status;
//     if ((!isFetching && didInvalidate) || (!isFetching && isQueryChanged)) {
//       dispatch(customerActions.getCustomers(filterOption));
//     }
//   },

//   getCustomers: (query) => async (dispatch) => {
//     const params = {
//       page: query.page,
//       pageSize: query.pageSize,
//       search: query.search,
//       gender: query.gender,
//       status: query.status,
//     };
//     const api = API_URLS.CUSTOMER.getCustomers(params);
//     dispatch(customerActions.gettingCustomers());
//     const { response, error } = await apiCall(api);
//     if (!error && response.status === 200 && response.data.success === true) {
//       dispatch(
//         customerActions.getCustomersSuccess({
//           items: response.data.data,
//           meta: {
//             page: response.data.page,
//             pageSize: response.data.page_size,
//             total: response.data.total,
//           },
//           query: {
//             search: params.search,
//             gender: params.gender,
//             status: params.status,
//           },
//         }),
//       );
//     } else {
//       dispatch(customerActions.getCustomersFailure(params));
//     }
//   },
//   gettingCustomers: () => ({
//     type: types.GET_CUSTOMERS,
//     meta: { prefix: [PREFIX.CUSTOMER, PREFIX.API_CALLING] },
//   }),
//   getCustomersSuccess: (payload) => ({
//     type: types.GET_CUSTOMERS,
//     payload,
//     meta: {
//       prefix: [PREFIX.CUSTOMER, PREFIX.API_CALLED_SUCCESS],
//     },
//   }),
//   getCustomersFailure: (query) => ({
//     type: types.GET_CUSTOMERS,
//     query,
//     meta: {
//       prefix: [PREFIX.CUSTOMER, PREFIX.API_CALLED_FAILURE],
//     },
//   }),

//   invalidateCustomers: () => ({
//     type: types.INVALIDATE_CUSTOMERS,
//     meta: {
//       prefix: [PREFIX.CUSTOMER, PREFIX.API_CALLED_SUCCESS],
//     },
//   }),

//   // get customer by id
//   getCustomerById: (customerId, meta) => async (dispatch) => {
//     dispatch(customerActions.gettingCustomerDetail());
//     const api = API_URLS.CUSTOMER.getCustomerById(customerId);
//     const { response, error } = await apiCall(api);
//     if (!error && response.status === 200 && response.data.success === true) {
//       // const data = response.data.data;
//       dispatch(customerActions.getCustomerDetailSuccess(response.data.data));
//       if (meta && meta.onSuccess) {
//         meta.onSuccess();
//       }
//     } else {
//       dispatch(customerActions.getCustomerDetailFailure());
//       if (meta && meta.onError) {
//         meta.onError(error);
//       }
//     }
//   },
//   gettingCustomerDetail: () => ({
//     type: types.GET_CUSTOMER_BY_ID,
//     meta: { prefix: [PREFIX.CUSTOMER, PREFIX.API_CALLING] },
//   }),
//   getCustomerDetailSuccess: (payload) => ({
//     type: types.GET_CUSTOMER_BY_ID,
//     meta: { prefix: [PREFIX.CUSTOMER, PREFIX.API_CALLED_SUCCESS] },
//     payload,
//   }),
//   getCustomerDetailFailure: () => ({
//     type: types.GET_CUSTOMER_BY_ID,
//     meta: { prefix: [PREFIX.CUSTOMER, PREFIX.API_CALLED_FAILURE] },
//   }),

//   //GET ALL CUSTOMERS
//   getAllCustomers: () => async (dispatch) => {
//     const params = {
//       page: -1,
//       pageSize: -1,
//     };
//     const api = API_URLS.CUSTOMER.getCustomers(params);
//     dispatch(customerActions.gettingAllCustomers());
//     const { response, error } = await apiCall(api);
//     if (!error && response.status === 200 && response.data.success === true) {
//       dispatch(
//         customerActions.getAllCustomersSuccess({
//           items: response.data.data,
//         }),
//       );
//     } else {
//       dispatch(customerActions.getAllCustomersFailure(params));
//     }
//   },
//   gettingAllCustomers: () => ({
//     type: types.GET_ALL_CUSTOMERS,
//     meta: { prefix: [PREFIX.CUSTOMER, PREFIX.API_CALLING] },
//   }),
//   getAllCustomersSuccess: (payload) => ({
//     type: types.GET_ALL_CUSTOMERS,
//     payload,
//     meta: {
//       prefix: [PREFIX.CUSTOMER, PREFIX.API_CALLED_SUCCESS],
//     },
//   }),
//   getAllCustomersFailure: (query) => ({
//     type: types.GET_ALL_CUSTOMERS,
//     query,
//     meta: {
//       prefix: [PREFIX.CUSTOMER, PREFIX.API_CALLED_FAILURE],
//     },
//   }),

//   // update customer
//   updatingCustomer: () => ({
//     type: types.UPDATE_CUSTOMER,
//     meta: { prefix: [PREFIX.CUSTOMER, PREFIX.API_CALLING] },
//   }),
//   updateCustomerSuccess: () => ({
//     type: types.UPDATE_CUSTOMER,
//     meta: {
//       prefix: [PREFIX.CUSTOMER, PREFIX.API_CALLED_SUCCESS],
//     },
//   }),
//   updateCustomerFailure: () => ({
//     type: types.UPDATE_CUSTOMER,
//     meta: {
//       prefix: [PREFIX.CUSTOMER, PREFIX.API_CALLED_FAILURE],
//     },
//   }),
//   updateCustomer: (customerId, payload, meta) => async (dispatch) => {
//     const api = API_URLS.CUSTOMER.updateCustomer(customerId, payload);
//     dispatch(customerActions.updatingCustomer());
//     const { response, error } = await apiCall(api);
//     if (!error && response.status === 200 && response.data.success === true) {
//       dispatch(customerActions.updateCustomerSuccess(response.data));
//       dispatch(customerActions.getCustomers({ page: 1, page_size: 10 }));
//       if (meta && meta.onSuccess) {
//         meta.onSuccess();
//       }
//     } else {
//       dispatch(customerActions.updateCustomerFailure(error));
//       if (meta && meta.onError) {
//         meta.onError(error);
//       }
//     }
//   },

//   // Delete customer
//   deletingCustomer: () => ({
//     type: types.DELETE_CUSTOMER,
//     meta: { prefix: [PREFIX.CUSTOMER, PREFIX.API_CALLING] },
//   }),
//   deleteCustomerSuccess: () => ({
//     type: types.DELETE_CUSTOMER,
//     meta: {
//       prefix: [PREFIX.CUSTOMER, PREFIX.API_CALLED_SUCCESS],
//     },
//   }),
//   deleteCustomerFailure: () => ({
//     type: types.DELETE_CUSTOMER,
//     meta: {
//       prefix: [PREFIX.CUSTOMER, PREFIX.API_CALLED_FAILURE],
//     },
//   }),
//   deleteCustomer: (customerId, meta) => async (dispatch) => {
//     const api = API_URLS.CUSTOMER.deleteCustomer(customerId);
//     dispatch(customerActions.deletingCustomer());
//     const { response, error } = await apiCall({ ...api });
//     if (!error && response.status === 200 && response.data.success === true) {
//       dispatch(customerActions.deleteCustomerSuccess(response.data));
//       if (meta && meta.onSuccess) {
//         meta.onSuccess();
//       }
//     } else {
//       dispatch(customerActions.deleteCustomerFailure(error));
//       if (meta && meta.onError) {
//         meta.onError(error);
//       }
//     }
//   },

//   // insert customer
//   insertCustomer: (payload, meta) => async (dispatch) => {
//     const api = API_URLS.CUSTOMER.insertCustomer(payload);
//     const params = {
//       page: 1,
//       pageSize: 10,
//       search: '',
//     };
//     const { response, error } = await apiCall(api);
//     if (!error && (response.status === 200 || response.status === 201)) {
//       if (meta && meta.onSuccess) {
//         meta.onSuccess();
//         dispatch(customerActions.getCustomers(params));
//       }
//     } else if (meta && meta.onError) {
//       meta.onError(error);
//     }
//     return { response, error };
//   },

//   insertManyCustomer: (payload, meta) => async (dispatch) => {
//     const api = API_URLS.CUSTOMER.insertManyCustomer(payload);
//     const params = {
//       page: 1,
//       pageSize: 10,
//       search: '',
//     };
//     const { response, error } = await apiCall(api);
//     if (!error && (response.status === 200 || response.status === 201)) {
//       if (meta && meta.onSuccess) {
//         meta.onSuccess(response);
//         dispatch(customerActions.getCustomers(params));
//       }
//     } else if (meta && meta.onError) {
//       meta.onError(error);
//     }
//     return { response, error };
//   },

//   changePage: (page, pageSize, total) => ({
//     type: types.GET_CUSTOMERS_CHANGE_PAGE,
//     payload: {
//       page,
//       pageSize,
//       total,
//     },
//     meta: {
//       prefix: [PREFIX.CUSTOMER],
//     },
//   }),
// };

const thunkActions = {
  getCustomers: createAsyncThunk(types.GET_CUSTOMERS, async ({ ...query }, { rejectWithValue }) => {
    const params = {
      page: query.page,
      page_size: query.pageSize,
      search: query.search,
      type: query.type,
      // gender: query.gender,
      active: query.active,
    };
    const api = API_URLS.CUSTOMER.getCustomers(params);
    const { response, error } = await apiCallPromise(api);
    if (error) rejectWithValue({ params });

    return {
      items: response.data.data,
      meta: {
        page: response.data.page,
        pageSize: response.data.page_size,
        total: response.data.total,
      },
      query: {
        search: params.search,
        type: params.type,
        // gender: params.gender,
        active: params.active,
      },
    };
  }),
  getCustomersIfNeed: (filterOption) => (dispatch, getState) => {
    const state = getState();
    const isFetching = state.customerReducer.isFetching;
    const didInvalidate = state.customerReducer.didInvalidate;
    const query = state.customerReducer.query;
    const isQueryChanged =
      query.search !== filterOption.search ||
      query.type !== filterOption.type ||
      // query.gender !== filterOption.gender ||
      query.active !== filterOption.active;
    if ((!isFetching && didInvalidate) || (!isFetching && isQueryChanged))
      dispatch(thunkActions.getCustomers(filterOption));
  },

  getAllCustomers: createAsyncThunk(types.GET_ALL_CUSTOMERS, async (_, { rejectWithValue }) => {
    const params = { page: -1, page_size: -1 };
    const api = API_URLS.CUSTOMER.getCustomers(params);
    const { response, error } = await apiCallPromise(api);
    if (error) rejectWithValue({ params });

    return { items: response.data.data };
  }),

  invalidateCustomers: () => ({ type: types.INVALIDATE_CUSTOMERS }),

  getCustomerById: createAsyncThunk(
    types.GET_CUSTOMER_BY_ID,
    async ({ customerId, meta }, { rejectWithValue }) => {
      const api = API_URLS.CUSTOMER.getCustomerById(customerId);
      const { response, error } = await apiCallPromise(api);
      if (!error && response.status === 200 && response.data.success === true) {
        if (meta && meta.onSuccess) meta.onSuccess();
        return { data: response.data.data };
      } else {
        if (meta && meta.onError) meta.onError(error);
        rejectWithValue();
      }
    },
  ),

  insertCustomer: createAsyncThunk(types.INSERT_CUSTOMER, async ({ payload, meta }) => {
    const api = API_URLS.CUSTOMER.insertCustomer(payload);
    const params = { page: 1, pageSize: 10, search: '' };
    const { response, error } = await apiCallPromise(api).catch((result) => result);

    if (!error && (response.status === 200 || response.status === 201)) {
      if (meta && meta.onSuccess) {
        meta.onSuccess();
        fulfillWithValue({ response });

        dispatch(thunkActions.getCustomers({ params }));
      }
    } else {
      if (meta && meta.onError) meta.onError(error);
      rejectWithValue({ error });
    }
  }),

  insertManyCustomers: createAsyncThunk(
    types.INSERT_MANY_CUSTOMERS,
    async ({ payload, meta }, { dispatch }) => {
      const api = API_URLS.CUSTOMER.insertManyCustomers(payload);
      const params = { page: 1, pageSize: 10, search: '' };
      const { response, error } = await apiCallPromise(api);
      if (!error && (response.status === 200 || response.status === 201)) {
        if (meta && meta.onSuccess) meta.onSuccess(response);
        // fulfillWithValue();
        dispatch(thunkActions.getCustomers({ params }));
      } else {
        if (meta && meta.onError) meta.onError(error);
        // rejectWithValue();
      }
    },
  ),

  updateCustomer: createAsyncThunk(
    types.UPDATE_CUSTOMER,
    async ({ customerId, payload, meta }, { dispatch, fulfillWithValue, rejectWithValue }) => {
      const api = API_URLS.CUSTOMER.updateCustomer(customerId, payload);
      const { response, error } = await apiCallPromise(api);
      if (!error && response.status === 200 && response.data.success === true) {
        if (meta && meta.onSuccess) meta.onSuccess();
        fulfillWithValue({ data: response.data });
      } else {
        if (meta && meta.onError) meta.onError(error);
        rejectWithValue({ error });
      }
    },
  ),

  deleteCustomer: createAsyncThunk(
    types.DELETE_CUSTOMER,
    async ({ customerId }, { rejectWithValue }) => {
      const api = API_URLS.CUSTOMER.deleteCustomer(customerId);
      const { response, error } = await apiCallPromise({ ...api });
      if (error) {
        rejectWithValue({ error });
        if (meta && meta.onError) meta.onError(error);
      }

      if (meta && meta.onSuccess) meta.onSuccess();
      return { data: response.data };
    },
  ),

  changeState: createAsyncThunk(types.CHANGE_STATE, async ({ payload, meta }, { dispatch }) => {
    const api = API_URLS.CUSTOMER.changeStateCustomer(payload);

    const { response, error } = await apiCallPromise(api);

    if (!error && response.status === 200) {
      if (meta && meta.onSuccess) meta.onSuccess();
      // dispatch(thunkActions.getCustomers({ page: payload.page, page_size: payload.page_size }));
    } else {
      if (meta && meta.onError) meta.onError(error);
    }

    return response.data;
  }),

  changePage: (page, pageSize, total) => ({
    type: types.GET_CUSTOMERS_CHANGE_PAGE,
    payload: { page, pageSize, total },
  }),
};

const initialState = {
  isFetching: false,
  didInvalidate: true,
  lastUpdated: moment().unix(),
  currentCustomer: {},
  allItems: [],
  items: [],
  query: {
    search: '',
    type: '',
    // gender: '',
    active: '',
  },
  meta: {
    total: 0,
    page: 1,
    pageSize: 10,
  },
};

const customerSlice = createSlice({
  name: 'customerReducer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(types.INVALIDATE_CUSTOMERS, (state) => {
        state.didInvalidate = true;
      })
      .addCase(types.GET_CUSTOMERS_CHANGE_PAGE, (state, { payload }) => {
        state.meta = { page: payload.page, pageSize: payload.pageSize, total: payload.total };
      })
      .addCase(thunkActions.getCustomerById.fulfilled, (state, { payload }) => {
        state.currentCustomer = payload.data;
        state.isFetching = false;
      })
      .addCase(thunkActions.getCustomers.fulfilled, (state, { payload }) => {
        const { items, meta, query } = payload;
        state.isFetching = false;
        state.didInvalidate = false;
        state.lastUpdated = moment.unix();
        state.items = items;
        state.meta = meta;
        state.query = {
          search: query.search,
          type: query.type,
          // gender: query.gender,
          active: query.active,
        };
      })
      .addCase(thunkActions.getAllCustomers.fulfilled, (state, { payload }) => {
        const { items } = payload;
        state.isFetching = false;
        state.didInvalidate = false;
        state.allItems = items;
      })
      .addMatcher(
        isAnyOf(
          thunkActions.updateCustomer.fulfilled,
          thunkActions.deleteCustomer.fulfilled,
          thunkActions.changeState.fulfilled,
        ),
        (state) => {
          state.isFetching = false;
          state.didInvalidate = true;
          state.lastUpdated = moment.unix();
        },
      )
      .addMatcher(
        isAnyOf(
          thunkActions.getCustomers.pending,
          thunkActions.getCustomerById.pending,
          thunkActions.getAllCustomers.pending,
          thunkActions.updateCustomer.pending,
          thunkActions.deleteCustomer.pending,
          thunkActions.changeState.pending,
        ),
        (state) => {
          state.isFetching = true;
        },
      )
      .addMatcher(
        isAnyOf(
          thunkActions.getCustomers.rejected,
          thunkActions.getCustomerById.rejected,
          thunkActions.getAllCustomers.rejected,
          thunkActions.updateCustomer.rejected,
          thunkActions.deleteCustomer.rejected,
          thunkActions.changeState.rejected,
        ),
        (state) => {
          state.isFetching = false;
          state.didInvalidate = false;
        },
      );
  },
});

export const actions = { ...customerSlice.actions, ...thunkActions };
export const { reducer } = customerSlice;

// export const reducer = (state = initialState, action) =>
//   produce(state, (draft) => {
//     switch (action.type) {
//       case types.GET_CUSTOMERS:
//         if (isCallingApi(action)) {
//           draft.isFetching = true;
//         } else if (isSuccessfulApiCall(action)) {
//           const { items, meta, query } = action.payload;
//           draft.isFetching = false;
//           draft.didInvalidate = false;
//           draft.lastUpdated = moment.unix();
//           draft.items = items;
//           draft.meta = meta;
//           draft.query = query;
//         } else if (isFailedApiCall(action)) {
//           draft.isFetching = false;
//           draft.didInvalidate = false;
//         }
//         break;
//       case types.GET_CUSTOMER_BY_ID:
//         if (isCallingApi(action)) {
//           draft.isFetching = true;
//         } else if (isSuccessfulApiCall(action)) {
//           draft.currentCustomer = action.payload;
//           draft.isFetching = false;
//         } else if (isFailedApiCall(action)) {
//           draft.isFetching = false;
//           draft.didInvalidate = false;
//         }
//         break;
//       case types.INVALIDATE_CUSTOMERS:
//         draft.didInvalidate = true;
//         break;
//       case types.GET_CUSTOMERS_CHANGE_PAGE:
//         draft.meta = {
//           page: action.payload.page,
//           pageSize: action.payload.pageSize,
//           total: action.payload.total,
//         };
//         break;
//       case types.GET_ALL_CUSTOMERS:
//         if (isCallingApi(action)) {
//           draft.isFetching = true;
//         } else if (isSuccessfulApiCall(action)) {
//           const { items } = action.payload;
//           draft.isFetching = false;
//           draft.didInvalidate = false;
//           draft.allItems = items;
//         } else if (isFailedApiCall(action)) {
//           draft.isFetching = false;
//           draft.didInvalidate = false;
//         }
//         break;
//       case types.UPDATE_CUSTOMER:
//       case types.DELETE_CUSTOMER:
//         if (isCallingApi(action)) {
//           draft.isFetching = true;
//         }
//         if (isSuccessfulApiCall(action)) {
//           draft.isFetching = false;
//           draft.didInvalidate = true;
//           draft.lastUpdated = moment.unix();
//         }
//         if (isFailedApiCall(action)) {
//           draft.isFetching = false;
//           draft.didInvalidate = false;
//         }
//         break;
//       default:
//         return draft;
//     }
//   });
