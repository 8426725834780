import { CheckOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';

const FormItem = Form.Item;
const { Item } = Form;
const { Option } = Select;

const FormCategory = ({
  openModalForm,
  openModal,
  onSubmit,
  closeModalForm,
  dataCurCategory,
  title,
  dataCategories,
}) => {
  const onFinish = (value) => {
    onSubmit(value);
  };

  return (
    <Modal
      title={title}
      visible={openModal}
      onOK={openModalForm}
      onCancel={closeModalForm}
      footer={<></>}
      destroyOnClose>
      <Form
        onFinish={onFinish}
        layout="vertical"
        initialValues={{
          code: dataCurCategory?.code ? dataCurCategory?.code : '',
          parent_code: dataCurCategory?.parent_code ? dataCurCategory?.parent_code : '',
          name: dataCurCategory?.name ? dataCurCategory?.name : '',
          description: dataCurCategory?.description ? dataCurCategory?.description : '',
        }}>
        <Row gutter={10}>
          <Col span={24}>
            <FormItem label="Mã nhóm hàng" name="code">
              <Input placeholder="Nhập mã nhóm hàng..." />
            </FormItem>
            <FormItem
              label="Tên nhóm hàng"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Nhập tên nhóm hàng!',
                },
              ]}>
              <Input placeholder="Nhập tên nhóm hàng..." />
            </FormItem>
            <FormItem label="Nhóm hàng cha" name="parent_code">
              <Select showSearch optionFilterProp="children">
                {dataCategories?.map((category, index) => (
                  <Option key={index} value={category.code}>
                    {`${category.name}${category.code ? ` (${category.code})` : ''}`}
                  </Option>
                ))}
              </Select>
            </FormItem>
            <FormItem label="Mô tả" name="description">
              <TextArea rows={4} placeholder="Mô tả..." />
            </FormItem>
          </Col>

          <Item style={{ float: 'right', marginTop: '10px' }}>
            <Button type="primary" htmlType="submit" icon={<CheckOutlined />} shape="round">
              Lưu
            </Button>
            &nbsp;
          </Item>
        </Row>
      </Form>
    </Modal>
  );
};

export default FormCategory;
