export const HEADERS = {
  DEFAULT_HEADER: {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
  },
  header: () => ({
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    Authorization: localStorage.getItem('jwt'),
  }),
  jsonHeader: () => ({
    'Content-Type': 'application/json; charset=UTF-8',
    Authorization: localStorage.getItem('jwt'),
  }),
  file_header: () => ({
    'Content-Type': 'multipart/form-data',
    Authorization: localStorage.getItem('jwt'),
  }),
};

export const API_URLS = {
  //ACCOUNT
  ACCOUNT: {
    login: (payload) => ({
      endPoint: '/api/v1/account/user/login',
      method: 'POST',
      headers: HEADERS.DEFAULT_HEADER,
      payload,
    }),
    loginWithToken: () => ({
      endPoint: '/api/v1/account/user/information',
      method: 'GET',
      headers: HEADERS.header(),
    }),
    changePassword: () => ({
      endPoint: '/api/v1/account/user/password',
      method: 'PUT',
      headers: HEADERS.header(),
    }),
  },

  //COMPANY
  COMPANY: {
    getCompanies: (params) => ({
      endPoint: '/api/v1/account/company',
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_COMPANIES',
      params,
    }),
    getCompany: (params) => ({
      endPoint: '/api/v1/backend/companies/organization',
      method: 'GET',
      headers: HEADERS.jsonHeader(),
      name: 'GET_COMPANIES',
      params,
    }),
    getCompanyById: (companyId) => ({
      endPoint: `/api/v1/account/company/${companyId}`,
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_COMPANY',
    }),
    insertCompany: (payload) => ({
      endPoint: '/api/v1/account/company',
      method: 'POST',
      headers: HEADERS.header(),
      name: 'ADD_COMPANY',
      payload,
    }),
    updateCompany: (company_code, payload) => ({
      endPoint: `/api/v1/account/company/${company_code}`,
      method: 'PUT',
      headers: HEADERS.header(),
      name: 'EDIT_COMPANY',
      payload,
    }),
    updateCompanyFeatures: (company_code, payload) => ({
      endPoint: `/api/v1/account/user/features/company/${company_code}`,
      method: 'PUT',
      headers: HEADERS.header(),
      name: 'EDIT_COMPANY_FEATURES',
      payload,
    }),
    deleteCompany: (company_code) => ({
      endPoint: `/api/v1/account/company/${company_code}`,
      method: 'DELETE',
      headers: HEADERS.header(),
      name: 'DELETE_COMPANY',
    }),
    registerCompany: (params) => ({
      endPoint: '/api/v1/account/company/account',
      method: 'POST',
      headers: HEADERS.header(),
      name: 'REGISTER_COMPANY',
      payload: params,
    }),
  },
  //DASHBOARD
  DASHBOARD: {
    drivingTime: (params) => ({
      endPoint: '/api/v1/backend/report/statistic-driving-time',
      method: 'GET',
      headers: HEADERS.header(),
      name: 'STATISTIC_DRIVING_TIME',
      params,
    }),
    getStatisticNodes: (params) => ({
      endPoint: '/api/v1/backend/report/statistic-nodes',
      method: 'GET',
      headers: HEADERS.header(),
      name: 'STATISTIC_NODES',
      params,
    }),
    getStatisticOrders: (params) => ({
      endPoint: '/api/v1/backend/report/statistic-orders',
      method: 'GET',
      headers: HEADERS.header(),
      name: 'STATISTIC_ORDERS',
      params,
    }),
    getStatisticRealDistance: (params) => ({
      endPoint: '/api/v1/backend/report/statistic-real-distance',
      method: 'GET',
      headers: HEADERS.header(),
      name: 'STATISTIC_REAL_DISTANCE',
      params,
    }),
    compareEstimateAndReal: (params) => ({
      endPoint: '/api/v1/backend/report/compare-estimate-and-real',
      method: 'GET',
      headers: HEADERS.header(),
      name: 'CONPARE_ESTIMATE_AND_REAL',
      params,
    }),
  },
  MEDIA: {
    imageUpload: ({ width, height }) => ({
      endPoint: `/api/upload-media/v3/images/${width}/${height}`,
      method: 'POST',
      headers: HEADERS.file_header(),
    }),
    // imageUploadWithSlug: ({ width, height }) => ({
    //   endPoint: `/api/upload-media/v3/images/slug/${width}/${height}`,
    //   method: 'POST',
    //   headers: HEADERS.file_header(),
    // }),
    imageUploadNoResize: () => ({
      endPoint: `/api/upload-media/v3/images/noresize`,
      method: 'POST',
      headers: HEADERS.file_header(),
    }),
    // imageUploadBase64: () => '/api/upload-media/v1/images/base64',
  },

  //VEHICLE
  VEHICLE: {
    getVehicles: (params) => ({
      endPoint: '/api/v1/backend/vehicle',
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_VEHICLES',
      params,
    }),
    getVehicleById: (vehicleId) => ({
      endPoint: `/api/v1/backend/vehicle/${vehicleId}`,
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_VEHICLE',
    }),
    insertVehicle: (payload) => ({
      endPoint: '/api/v1/backend/vehicle',
      method: 'POST',
      headers: HEADERS.header(),
      name: 'ADD_VEHICLE',
      payload,
    }),
    updateVehicle: (vehicleCode, payload) => ({
      endPoint: `/api/v1/backend/vehicle/${vehicleCode}`,
      method: 'PUT',
      headers: HEADERS.header(),
      name: 'EDIT_VEHICLE',
      payload,
    }),
    deleteVehicle: (vehicleCode) => ({
      endPoint: `/api/v1/account/company/${vehicleCode}`,
      method: 'DELETE',
      headers: HEADERS.header(),
      name: 'DELETE_VEHICLE',
    }),
  },

  //VEHICLE PART
  VEHICLE_PART: {
    getVehicleParts: (params) => ({
      endPoint: '/api/v1/backend/vehicle/part',
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_VEHICLE_PARTS',
      params,
    }),
    getVehiclePartById: (vehiclePartId) => ({
      endPoint: `/api/v1/backend/vehicle/part/${vehiclePartId}`,
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_VEHICLE_PART',
    }),
    insertVehiclePart: (payload) => ({
      endPoint: '/api/v1/backend/vehicle/part',
      method: 'POST',
      headers: HEADERS.header(),
      name: 'ADD_VEHICLE_PART',
      payload,
    }),
    updateVehiclePart: (vehicleCode, payload) => ({
      endPoint: `/api/v1/backend/vehicle/part/${vehicleCode}`,
      method: 'PUT',
      headers: HEADERS.header(),
      name: 'EDIT_VEHICLE_PART',
      payload,
    }),
    deleteVehiclePart: (vehicleCode) => ({
      endPoint: `/api/v1/account/company/part/${vehicleCode}`,
      method: 'DELETE',
      headers: HEADERS.header(),
      name: 'DELETE_VEHICLE_PART',
    }),
  },

  //VEHICLE EXPIRY
  VEHICLE_EXPIRY: {
    getVehicleExpirys: (params) => ({
      endPoint: '/api/v1/backend/vehicle/expiry',
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_VEHICLE_EXPIRYS',
      params,
    }),
    getVehicleExpiryById: (vehicleCode, expiryPartCode) => ({
      endPoint: `/api/v1/backend/vehicle/expiry/${vehicleCode}?partCode=${expiryPartCode}`,
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_VEHICLE_EXPIRY',
    }),
    insertVehicleExpiry: (payload) => ({
      endPoint: '/api/v1/backend/vehicle/expiry',
      method: 'POST',
      headers: HEADERS.header(),
      name: 'ADD_VEHICLE_EXPIRY',
      payload,
    }),
    updateVehicleExpiry: (vehicleCode, payload) => ({
      endPoint: `/api/v1/backend/vehicle/expiry/${vehicleCode}`,
      method: 'PUT',
      headers: HEADERS.header(),
      name: 'EDIT_VEHICLE_EXPIRY',
      payload,
    }),
    deleteVehicleExpiry: (vehicleCode) => ({
      endPoint: `/api/v1/account/company/expiry/${vehicleCode}`,
      method: 'DELETE',
      headers: HEADERS.header(),
      name: 'DELETE_VEHICLE_EXPIRY',
    }),
    insertVehicleExpiryMaintain: (vehicleCode, payload) => ({
      endPoint: `/api/v1/backend/vehicle/expiry/maintain/${vehicleCode}`,
      method: 'POST',
      headers: HEADERS.header(),
      name: 'ADD_VEHICLE_EXPIRY',
      payload,
    }),
  },

  VEHICLE_BILLING: {
    getVehicleBilling: (params) => ({
      endPoint: '/api/v1/backend/vehicle/billing',
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_VEHICLE_BILLING',
      params,
    }),
    getVehicleBillingById: (vehicleCode) => ({
      endPoint: `/api/v1/backend/vehicle/billing/${vehicleCode}`,
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_VEHICLE_BiLLING_BY_CODE',
    }),
    insertVehicleBilling: (payload) => ({
      endPoint: '/api/v1/backend/vehicle/billing',
      method: 'POST',
      headers: HEADERS.header(),
      name: 'ADD_VEHICLE_BILLING',
      payload,
    }),
    updateVehicleBilling: (vehicleCode, payload) => ({
      endPoint: `/api/v1/backend/vehicle/billing/${vehicleCode}`,
      method: 'PUT',
      headers: HEADERS.header(),
      name: 'EDIT_VEHICLE_BILLING',
      payload,
    }),
    deleteVehicleBilling: (vehicleCode) => ({
      endPoint: `/api/v1/account/company/billing/${vehicleCode}`,
      method: 'DELETE',
      headers: HEADERS.header(),
      name: 'DELETE_VEHICLE_EBILLING',
    }),
  },

  //DRIVER
  DRIVER: {
    getDrivers: (params) => ({
      endPoint: '/api/v1/backend/driver',
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_DRIVERS',
      params,
    }),
    getDriverById: (driverId) => ({
      endPoint: `/api/v1/backend/driver/${driverId}`,
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_DRIVER',
    }),
    insertDriver: (payload) => ({
      endPoint: '/api/v1/backend/driver',
      method: 'POST',
      headers: HEADERS.header(),
      name: 'ADD_DRIVER',
      payload,
    }),
    insertManyDriver: (payload) => ({
      endPoint: '/api/v1/backend/driver/many',
      method: 'POST',
      headers: HEADERS.header(),
      name: 'ADD_MANY_DRIVER',
      payload,
    }),
    updateDriver: (driverCode, payload) => ({
      endPoint: `/api/v1/backend/driver/${driverCode}`,
      method: 'PUT',
      headers: HEADERS.header(),
      name: 'EDIT_DRIVER',
      payload,
    }),
    deleteDriver: (driverCode) => ({
      endPoint: `/api/v1/account/driver/${driverCode}`,
      method: 'DELETE',
      headers: HEADERS.header(),
      name: 'DELETE_DRIVER',
    }),
    getDriversMobileApp: (params) => ({
      endPoint: '/api/v1/backend/driver/mobile-app',
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_DRIVERS_MOBILE_APP',
      params,
    }),
    activeDriverMobileApp: (driverCode) => ({
      endPoint: `/api/v1/backend/driver/mobile-app/active/${driverCode}`,
      method: 'PUT',
      headers: HEADERS.header(),
      name: 'ACTIVE_DRIVER_CODE',
    }),
    deactiveDriverMobileApp: (driverCode) => ({
      endPoint: `/api/v1/backend/driver/mobile-app/deactive/${driverCode}`,
      method: 'PUT',
      headers: HEADERS.header(),
      name: 'DEACTIVE_DRIVER_CODE',
    }),
    changeStateDriver: (payload) => ({
      endPoint: '/api/v1/backend/driver/active-state',
      method: 'PUT',
      headers: HEADERS.header(),
      name: 'CHANGE_ACTIVE_DRIVER',
      payload,
    }),
  },

  CUSTOMER: {
    getCustomers: (params) => ({
      endPoint: '/api/v1/backend/customer',
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_CUSTOMERS',
      params,
    }),
    getCustomerById: (customerId) => ({
      endPoint: `/api/v1/backend/customer/${customerId}`,
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_CUSTOMER',
    }),
    insertCustomer: (payload) => ({
      endPoint: '/api/v1/backend/customer',
      method: 'POST',
      headers: HEADERS.header(),
      name: 'ADD_CUSTOMER',
      payload,
    }),
    insertManyCustomers: (payload) => ({
      endPoint: '/api/v1/backend/customer/many',
      method: 'POST',
      headers: HEADERS.header(),
      name: 'ADD_MANY_CUSTOMERS',
      payload,
    }),
    updateCustomer: (customerCode, payload) => ({
      endPoint: `/api/v1/backend/customer/${customerCode}`,
      method: 'PUT',
      headers: HEADERS.header(),
      name: 'EDIT_CUSTOMER',
      payload,
    }),
    deleteCustomer: (customerCode) => ({
      endPoint: `/api/v1/account/customer/${customerCode}`,
      method: 'DELETE',
      headers: HEADERS.header(),
      name: 'DELETE_CUSTOMER',
    }),
    changeStateCustomer: (payload) => ({
      endPoint: '/api/v1/backend/customer/state',
      method: 'PUT',
      headers: HEADERS.header(),
      name: 'CHANGE_STATE',
      payload,
    }),
  },

  //USER
  USER: {
    getUsers: (params) => ({
      endPoint: `/api/v1/account/user`,
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_USERS',
      params,
    }),
    insertUser: (payload) => ({
      endPoint: '/api/v1/account/user',
      method: 'POST',
      headers: HEADERS.header(),
      name: 'ADD_USER',
      payload,
    }),
    updateUser: (code, payload) => ({
      endPoint: `/api/v1/account/user/${code}`,
      method: 'PUT',
      headers: HEADERS.header(),
      name: 'EDIT_USER',
      payload,
    }),
    deleteUser: (code) => ({
      endPoint: `/api/v1/account/user/${code}`,
      method: 'DELETE',
      headers: HEADERS.header(),
      name: 'DELETE_USER',
    }),
    activeDeactiveUser: (code) => ({
      endPoint: `/api/v1/account/user/${code}/status`,
      method: 'PUT',
      headers: HEADERS.jsonHeader(),
      name: 'ACTIVATE_USER',
    }),
    resetPassword: (accountCode) => ({
      endPoint: `/api/v1/account/user/password/reset/${accountCode}`,
      method: 'PUT',
      headers: HEADERS.jsonHeader(),
    }),
  },
  STORAGE: {
    getStorage: (params) => ({
      endPoint: '/api/v1/inventory/storages',
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_STORAGES',
      params,
    }),
    insertStorage: (payload) => ({
      endPoint: 'api/v1/inventory/storages',
      method: 'POST',
      headers: HEADERS.header(),
      name: 'ADD_STORAGE',
      payload,
    }),
    updateStorage: (code, payload) => ({
      endPoint: `/api/v1/inventory/storages/${code}`,
      method: 'PUT',
      headers: HEADERS.header(),
      name: 'EDIT_STORAGE',
      payload,
    }),
    changeStatusStorage: (code, payload) => ({
      endPoint: `api/v1/inventory/storages/status/${code}`,
      method: 'PUT',
      headers: HEADERS.jsonHeader(),
      name: 'UPDATE_STORAGE_STATUS',
      payload,
    }),
    getStorageById: (storageId) => ({
      endPoint: `/api/v1/inventory/storages/${storageId}`,
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_STORAGE_BY_ID',
    }),
  },
  CATEGORIES: {
    getCategories: (params) => ({
      endPoint: '/api/v1/inventory/categories',
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_CATEGORIES',
      params,
    }),
    insertCategory: (payload) => ({
      endPoint: 'api/v1/inventory/categories',
      method: 'POST',
      headers: HEADERS.header(),
      name: 'ADD_CATEGORY',
      payload,
    }),
    updateCategory: (code, payload) => ({
      endPoint: `/api/v1/inventory/categories/${code}`,
      method: 'PUT',
      headers: HEADERS.header(),
      name: 'EDIT_CATEGORY',
      payload,
    }),
    changeStatusCategory: (code, payload) => ({
      endPoint: `api/v1/inventory/categories/status/${code}`,
      method: 'PUT',
      headers: HEADERS.jsonHeader(),
      name: 'UPDATE_CATEGORY_STATUS',
      payload,
    }),
    getCategoryById: (code) => ({
      endPoint: `/api/v1/inventory/categories/${code}`,
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_CATEGORY_BY_ID',
    }),
    getCategoriesByLevel: (level) => ({
      endPoint: `/api/v1/inventory/categories?level=${level}&page=-1&page_size=-1`,
      method: 'GET',
      headers: HEADERS.jsonHeader(),
      name: 'GET_CATEGORIES',
    }),
  },

  PRODUCTS: {
    getProducts: (params) => ({
      endPoint: '/api/v1/inventory/products',
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_PRODUCTS',
      params,
    }),
    insertProduct: (payload) => ({
      endPoint: '/api/v1/inventory/products',
      method: 'POST',
      headers: HEADERS.header(),
      name: 'ADD_PRODUCT',
      payload,
    }),
    updateProduct: (code, payload) => ({
      endPoint: `/api/v1/inventory/products/${code}`,
      method: 'PUT',
      headers: HEADERS.header(),
      name: 'EDIT_PRODUCT',
      payload,
    }),
    changeStatusProduct: (code, payload) => ({
      endPoint: `/api/v1/inventory/products/status/${code}`,
      method: 'PUT',
      headers: HEADERS.jsonHeader(),
      name: 'UPDATE_PRODUCT_STATUS',
      payload,
    }),
    getProductById: (code) => ({
      endPoint: `/api/v1/inventory/products/${code}`,
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_PRODUCT_BY_ID',
    }),
  },
  CANCELVOUCHER: {
    getCancelVoucher: (params) => ({
      endPoint: '/api/v1/inventory/vouchers/cancellation',
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_CANCEL_VOUCHER',
      params,
    }),
    insertCancelVoucher: (payload) => ({
      endPoint: '/api/v1/inventory/vouchers/cancellation',
      method: 'POST',
      headers: HEADERS.header(),
      name: 'ADD_CANCEL_VOUCHER',
      payload,
    }),
    updateCancelVoucher: (code, payload) => ({
      endPoint: `/api/v1/inventory/vouchers/cancellation/${code}`,
      method: 'PUT',
      headers: HEADERS.header(),
      name: 'EDIT_CANCEL_VOUCHER',
      payload,
    }),
    changeStateCancelVoucher: (code, payload) => ({
      endPoint: `/api/v1/inventory/vouchers/cancellation/state/${code}`,
      method: 'PUT',
      headers: HEADERS.jsonHeader(),
      name: 'UPDATE_CANCEL_VOUCHER_STATE',
      payload,
    }),
    getCancelVoucherById: (code) => ({
      endPoint: `/api/v1/inventory/vouchers/cancellation/${code}`,
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_CANCEL_VOUCHER_BY_ID',
    }),
  },

  DELIVERY_VOUCHER: {
    getDeliveryVoucher: (params) => ({
      endPoint: '/api/v1/inventory/vouchers/delivery',
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_DELIVERY_VOUCHER',
      params,
    }),
    insertDeliveryVoucher: (payload) => ({
      endPoint: '/api/v1/inventory/vouchers/delivery',
      method: 'POST',
      headers: HEADERS.header(),
      name: 'ADD_DELIVERY_VOUCHER',
      payload,
    }),
    updateDeliveryVoucher: (code, payload) => ({
      endPoint: `/api/v1/inventory/vouchers/delivery/${code}`,
      method: 'PUT',
      headers: HEADERS.header(),
      name: 'EDIT_DELIVERY_VOUCHER',
      payload,
    }),
    changeStateDeliveryVoucher: (code, payload) => ({
      endPoint: `/api/v1/inventory/vouchers/delivery/state/${code}`,
      method: 'PUT',
      headers: HEADERS.jsonHeader(),
      name: 'UPDATE_DELIVERY_VOUCHE_STATE',
      payload,
    }),
    getDeliveryVoucherById: (code) => ({
      endPoint: `/api/v1/inventory/vouchers/delivery/${code}`,
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_DELIVERY_VOUCHE_VOUCHER_BY_ID',
    }),
    getDeliveryVoucherQRCode: (code) => ({
      endPoint: `/api/v1/inventory/vouchers/delivery/${code}/get-qrcode`,
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_DELIVERY_VOUCHE_VOUCHER_QR_CODE',
    }),
    getDeliveryVoucherItemQRCode: (code, product_code, lot_number) => ({
      endPoint: `/api/v1/inventory/vouchers/delivery/${code}/${product_code}/${lot_number}/get-qrcode`,
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_DELIVERY_VOUCHE_VOUCHER_QR_CODE',
    }),
  },

  RECEIVING_VOUCHER: {
    getReceivingVoucher: (params) => ({
      endPoint: '/api/v1/inventory/vouchers/receiving',
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_RECEIVING_VOUCHER',
      params,
    }),
    insertReceivingVoucher: (payload) => ({
      endPoint: '/api/v1/inventory/vouchers/receiving',
      method: 'POST',
      headers: HEADERS.header(),
      name: 'ADD_RECEIVING_VOUCHER',
      payload,
    }),
    updateReceivingVoucher: (code, payload) => ({
      endPoint: `/api/v1/inventory/vouchers/receiving/${code}`,
      method: 'PUT',
      headers: HEADERS.header(),
      name: 'EDIT_RECEIVING_VOUCHER',
      payload,
    }),
    changeStateReceivingVoucher: (code, payload) => ({
      endPoint: `/api/v1/inventory/vouchers/receiving/state/${code}`,
      method: 'PUT',
      headers: HEADERS.jsonHeader(),
      name: 'UPDATE_RECEIVING_VOUCHE_STATE',
      payload,
    }),
    getReceivingVoucherById: (code) => ({
      endPoint: `/api/v1/inventory/vouchers/receiving/${code}`,
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_RECEIVING_VOUCHE_VOUCHER_BY_ID',
    }),
  },

  CANCEL_VOUCHER: {
    getCancelVoucher: (params) => ({
      endPoint: '/api/v1/inventory/vouchers/cancellation',
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_CANCEL_VOUCHER',
      params,
    }),
    insertCancelVoucher: (payload) => ({
      endPoint: '/api/v1/inventory/vouchers/cancellation',
      method: 'POST',
      headers: HEADERS.header(),
      name: 'ADD_CANCEL_VOUCHER',
      payload,
    }),
    updateCancelVoucher: (code, payload) => ({
      endPoint: `/api/v1/inventory/vouchers/cancellation/${code}`,
      method: 'PUT',
      headers: HEADERS.header(),
      name: 'EDIT_CANCEL_VOUCHER',
      payload,
    }),
    changeStateCancelVoucher: (code, payload) => ({
      endPoint: `/api/v1/inventory/vouchers/cancellation/state/${code}`,
      method: 'PUT',
      headers: HEADERS.jsonHeader(),
      name: 'UPDATE_CANCEL_VOUCHE_STATE',
      payload,
    }),
    getCancelVoucherById: (code) => ({
      endPoint: `/api/v1/inventory/vouchers/cancellation/${code}`,
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_CANCEL_VOUCHE_VOUCHER_BY_ID',
    }),
  },

  INVENTORY_RECORD_VOUCHER: {
    getInventoryRecordVoucher: (params) => ({
      endPoint: '/api/v1/inventory/vouchers/inventory-record',
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_INVENTORY_RECORD_VOUCHER',
      params,
    }),
    insertInventoryRecordVoucher: (payload) => ({
      endPoint: '/api/v1/inventory/vouchers/inventory-record',
      method: 'POST',
      headers: HEADERS.header(),
      name: 'ADD_INVENTORY_RECORD_VOUCHER',
      payload,
    }),
    updateInventoryRecordVoucher: (code, payload) => ({
      endPoint: `/api/v1/inventory/vouchers/inventory-record/${code}`,
      method: 'PUT',
      headers: HEADERS.header(),
      name: 'EDIT_INVENTORY_RECORD_VOUCHER',
      payload,
    }),
    changeStateInventoryRecordVoucher: (code, payload) => ({
      endPoint: `/api/v1/inventory/vouchers/inventory-record/state/${code}`,
      method: 'PUT',
      headers: HEADERS.jsonHeader(),
      name: 'UPDATE_INVENTORY_RECORD_VOUCHE_STATE',
      payload,
    }),
    getInventoryRecordVoucherById: (code) => ({
      endPoint: `/api/v1/inventory/vouchers/inventory-record/${code}`,
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_INVENTORY_RECORD_VOUCHE_VOUCHER_BY_ID',
    }),
  },

  INVENTORY: {
    getInventory: (params) => ({
      endPoint: '/api/v1/inventory/warehouses',
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_INVENTORY',
      params,
    }),
  },
  UNITS: {
    getUnits: (params) => ({
      endPoint: '/api/v1/inventory/units',
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_UNITS',
      params,
    }),
    insertUnit: (payload) => ({
      endPoint: 'api/v1/inventory/units',
      method: 'POST',
      headers: HEADERS.header(),
      name: 'ADD_UNIT',
      payload,
    }),
    updateUnit: (code, payload) => ({
      endPoint: `/api/v1/inventory/units/${code}`,
      method: 'PUT',
      headers: HEADERS.header(),
      name: 'EDIT_UNIT',
      payload,
    }),

    getUnitById: (code) => ({
      endPoint: `/api/v1/inventory/units/${code}`,
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_UNIT_BY_ID',
    }),
  },

  // SECRET KEY
  SECRET_KEY: {
    getSecretKeyByCompany: (code, params) => ({
      endPoint: `/api/v1/backend/secret-key/${code}`,
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_SECRET_KEY_BY_COMPANY',
      params,
    }),
    createSecretKey: (payload) => ({
      endPoint: '/api/v1/backend/secret-key',
      method: 'POST',
      headers: HEADERS.header(),
      name: 'CREATE_SECRET_KEY',
      payload,
    }),
  },

  //ROLE
  ROLE: {
    getRoles: (params) => ({
      endPoint: '/api/v1/account/role',
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_ROLES',
      params,
    }),
    insertRole: () => ({
      endPoint: '/api/v1/account/role',
      method: 'POST',
      headers: HEADERS.header(),
      name: 'INSERT_ROLE',
    }),
    editRole: (id) => ({
      endPoint: `/api/v1/account/role/${id}`,
      method: 'PUT',
      headers: HEADERS.header(),
      name: 'EDIT_ROLE',
    }),
    deleteRole: (id) => ({
      endPoint: `/api/v1/account/role/${id}`,
      method: 'DELETE',
      headers: HEADERS.header(),
      name: 'DELETE_ROLE',
    }),
    getRole: (id) => ({
      endPoint: `/api/v1/account/role/${id}`,
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_ROLE',
    }),
    activeDeactiveRole: (id) => ({
      endPoint: `/api/v1/account/role/${id}/status`,
      method: 'PUT',
      headers: HEADERS.header(),
      name: 'ACTIVE_DEACTIVE_ROLE',
    }),
    getApiOfRole: (id) => ({
      endPoint: `/api/v1/account/role/${id}`,
      method: 'GET',
      headers: HEADERS.jsonHeader(),
      name: 'GET_API_OF_ROLE',
    }),
    insertApiOfRole: (id, payload) => ({
      endPoint: `/api/v1/account/role/${id}/apis/add`,
      method: 'PUT',
      headers: HEADERS.jsonHeader(),
      name: 'ADD_API_ROLE',
      payload,
    }),
    deleteApiOfRole: (id, payload) => ({
      endPoint: `/api/v1/account/role/${id}/apis/remove`,
      method: 'PUT',
      headers: HEADERS.jsonHeader(),
      name: 'DELETE_API_OF_ROLE',
      payload,
    }),
    insertApiOfRoleFromExcel: () => ({
      endPoint: `/api/v1/account/role`,
      method: 'PUT',
      headers: HEADERS.jsonHeader(),
      name: 'ADD_API_OF_ROLE_FROM_EXCEL',
    }),
  },

  //API
  API: {
    getApis: (params) => ({
      endPoint: '/api/v1/account/api',
      method: 'GET',
      headers: HEADERS.jsonHeader(),
      name: 'GET_APIS',
      params,
    }),
    insertApi: (payload) => ({
      endPoint: '/api/v1/account/api',
      method: 'POST',
      headers: HEADERS.jsonHeader(),
      name: 'INSERT_API',
      payload,
    }),
    updateApi: (code, payload) => ({
      endPoint: `/api/v1/account/api/${code}`,
      method: 'PUT',
      headers: HEADERS.jsonHeader(),
      name: 'UPDATE_API',
      payload,
    }),
    deleteApi: (code) => ({
      endPoint: `/api/v1/account/api/${code}`,
      method: 'DELETE',
      headers: HEADERS.jsonHeader(),
      name: 'DELETE_API',
    }),
  },

  //CONFIGURATION
  CONFIGURATION: {
    getConfiguration: () => ({
      endPoint: '/api/v1/backend/configuration',
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_CONFIGURATIONS',
    }),
    insertConfiguration: (payload) => ({
      endPoint: '/api/v1/backend/configuration',
      method: 'POST',
      headers: HEADERS.header(),
      name: 'INSERT_CONFIGURATION',
      payload,
    }),
    updateConfiguration: (configCode, payload) => ({
      endPoint: `/api/v1/backend/configuration/${configCode}`,
      method: 'PUT',
      headers: HEADERS.header(),
      name: 'EDIT_CONFIGURATION',
      payload,
    }),
  },

  //ORDER
  ORDER: {
    getOrders: (params) => ({
      endPoint: '/api/v1/backend/order',
      method: 'GET',
      headers: HEADERS.jsonHeader(),
      name: 'GET_ORDERS',
      params,
    }),

    getOrderByCode: (code) => ({
      endPoint: `/api/v1/backend/order/${code}`,
      method: 'GET',
      headers: HEADERS.jsonHeader(),
      name: 'GET_ORDER_BY_CODE',
    }),
    insertOrder: (payload) => ({
      endPoint: '/api/v1/backend/order',
      method: 'POST',
      headers: HEADERS.jsonHeader(),
      name: 'INSERT_ORDER',
      payload,
    }),
    changeCompany: (payload) => ({
      endPoint: '/api/v1/backend/order/change-company',
      method: 'POST',
      headers: HEADERS.jsonHeader(),
      name: 'INSERT_ORDER',
      payload,
    }),
    insertManyOrder: (payload) => ({
      endPoint: '/api/v1/backend/order/many',
      method: 'POST',
      headers: HEADERS.jsonHeader(),
      name: 'ADD_MANY_ORDER',
      payload,
    }),
    updateOrder: (code, payload) => ({
      endPoint: `/api/v1/backend/order/${code}`,
      method: 'PUT',
      headers: HEADERS.header(),
      name: 'UPDATE_ORDER',
      payload,
    }),
  },

  //FEATURE
  FEATURE: {
    getFeatures: (params) => ({
      endPoint: '/api/v1/backend/feature',
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_FEATURES',
      params,
    }),
    getFeatureById: (featureId) => ({
      endPoint: `/api/v1/backend/feature/${featureId}`,
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_FEATURE',
    }),
    insertFeature: (payload) => ({
      endPoint: '/api/v1/backend/feature',
      method: 'POST',
      headers: HEADERS.header(),
      name: 'ADD_FEATURE',
      payload,
    }),
    updateFeature: (featureCode, payload) => ({
      endPoint: `/api/v1/backend/feature/${featureCode}`,
      method: 'PUT',
      headers: HEADERS.header(),
      name: 'EDIT_FEATURE',
      payload,
    }),
    deleteFeature: (featureCode) => ({
      endPoint: `/api/v1/account/feature/${featureCode}`,
      method: 'DELETE',
      headers: HEADERS.header(),
      name: 'DELETE_FEATURE',
    }),
  },

  //ROUTING
  ROUTING: {
    getCVRPSolution: (payload) => ({
      endPoint: '/api/v1/solver/cvrp',
      method: 'POST',
      headers: HEADERS.header(),
      name: 'GET_CVRP_SOLUTION',
      payload,
    }),
    calc: (payload) => ({
      endPoint: '/api/v1/solver/calc',
      method: 'POST',
      headers: HEADERS.header(),
      name: 'RECALCULATE_CVRP_SOLUTION',
      payload,
    }),
    insertRoute: (payload) => ({
      endPoint: '/api/v1/backend/route',
      method: 'POST',
      headers: HEADERS.header(),
      name: 'INSERT_ROUTE',
      payload,
    }),
    insertRoutes: (payload) => ({
      endPoint: '/api/v1/backend/route/many',
      method: 'POST',
      headers: HEADERS.header(),
      name: 'INSERT_ROUTES',
      payload,
    }),
    insertRouteFromSampleRoute: (payload) => ({
      endPoint: '/api/v1/backend/route/from-sample-route',
      method: 'POST',
      headers: HEADERS.header(),
      name: 'INSERT_ROUTE_FROM_SAMPLE_ROUTE',
      payload,
    }),
    sendRouteToMobileApp: (payload) => ({
      endPoint: '/api/v1/backend/route/send-to-mobile-app',
      method: 'POST',
      headers: HEADERS.header(),
      name: 'SEND_TO_MOBILE_APP',
      payload,
    }),
    getRoutes: (params) => ({
      endPoint: '/api/v1/backend/route',
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_ROUTES',
      params,
    }),
    updateRoutes: (payload) => ({
      endPoint: '/api/v1/backend/route',
      method: 'PUT',
      headers: HEADERS.header(),
      name: 'UPDATE_ROUTES',
      payload,
    }),
    updateRouteByCode: (code, payload) => ({
      endPoint: `/api/v1/backend/route/${code}`,
      method: 'PUT',
      headers: HEADERS.header(),
      name: 'UPDATE_ROUTE_BY_CODE',
      payload,
    }),
    updateDriverRoute: (code, payload) => ({
      endPoint: `/api/v1/backend/route/${code}/driver`,
      method: 'PUT',
      headers: HEADERS.header(),
      name: 'UPDATE_DRIVER_ROUTE',
      payload,
    }),
    deleteRoute: (payload) => ({
      endPoint: '/api/v1/backend/route/delete',
      method: 'DELETE',
      headers: HEADERS.header(),
      name: 'DELETE_ROUTE',
      payload, // cái nào có body phải tcos paylaod
    }),
  },

  //SAMPLE_ROUTE
  SAMPLE_ROUTE: {
    getSampleRoutes: (params) => ({
      endPoint: '/api/v1/backend/sample-route',
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_SAMPLE_ROUTES',
      params,
    }),
    getSampleRouteByCode: (code) => ({
      endPoint: `/api/v1/backend/sample-route/${code}`,
      method: 'GET',
      headers: HEADERS.header(),
      name: 'GET_SAMPLE_ROUTE_BY_CODE',
    }),
    insertSampleRoute: (payload) => ({
      endPoint: '/api/v1/backend/sample-route',
      method: 'POST',
      headers: HEADERS.header(),
      name: 'INSERT_SAMPLE_ROUTE',
      payload,
    }),
    editSampleRoute: (code, payload) => ({
      endPoint: `/api/v1/backend/sample-route/${code}`,
      method: 'PUT',
      headers: HEADERS.header(),
      name: 'EDIT_SAMPLE_ROUTE',
      payload,
    }),
    deleteSampleRoute: (code) => ({
      endPoint: `/api/v1/backend/sample-route/${code}`,
      method: 'DELETE',
      headers: HEADERS.header(),
      name: 'DELETE_SAMPLE_ROUTE',
      payload,
    }),
  },
};
