import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import moment from 'moment';

import { PREFIX, typesWithPrefix } from './config';
import { API_URLS } from '../config/api';
import { apiCallPromise } from '../utils/api';

const _types = typesWithPrefix(PREFIX.CONFIGURATION);
const types = {
  GET_CONFIGURATION: _types('GET_CONFIGURATION'),
  INSERT_CONFIGURATION: _types('INSERT_CONFIGURATION'),
  SET_CONFIG: _types('SET_CONFIG'),
  UPDATE_CONFIGURATION: _types('UPDATE_CONFIGURATION'),
  CLEAR_STORE: _types('CLEAR_STORE'),
  INVALIDATE_CONFIGURATIONS: _types('INVALIDATE_CONFIGURATIONS'),
};

const thunkActions = {
  getConfiguration: createAsyncThunk(types.GET_CONFIGURATION, async (_, { rejectWithValue }) => {
    const api = API_URLS.CONFIGURATION.getConfiguration();
    const { response, error } = await apiCallPromise(api);
    if (error) rejectWithValue({ error });
    return { items: response.data.data || [] };
  }),
  insertConfiguration: createAsyncThunk(
    types.INSERT_CONFIGURATION,
    async ({ payload }, { dispatch, rejectWithValue }) => {
      const api = API_URLS.CONFIGURATION.insertConfiguration(payload);
      const { response, error } = await apiCallPromise(api);
      if (error) {
        rejectWithValue({ error });

        return;
      }

      dispatch(thunkActions.getConfiguration());
      return { items: response.data.data };
    },
  ),
  updateConfiguration: createAsyncThunk(
    types.UPDATE_CONFIGURATION,
    async ({ configurationId, payload, meta }, { dispatch, fulfillWithValue, rejectWithValue }) => {
      if (configurationId) {
        const api = API_URLS.CONFIGURATION.updateConfiguration(configurationId, payload);
        const { response, error } = await apiCallPromise(api);
        if (!error && response.status === 200 && response.data.success === true) {
          fulfillWithValue({ ...response.data });
          dispatch(thunkActions.getConfiguration());
          if (meta && meta.onSuccess) meta.onSuccess();
        } else {
          rejectWithValue({ error });
          if (meta && meta.onError) meta.onError(error);
        }
      } else {
        dispatch(thunkActions.insertConfiguration({ payload, meta }));
      }
    },
  ),
  setConfig: (payload) => (dispatch) => dispatch({ type: types.SET_CONFIG, payload }),
  invalidateConfigurations: () => (dispatch) => dispatch({ type: types.INVALIDATE_CONFIGURATIONS }),
  // clearStore: () => (dispatch) =>
  //   dispatch({ type: types.CLEAR_STORE, meta: { prefix: [PREFIX.CONFIGURATION] } }),
};

const initialState = {
  isFetching: false,
  didInvalidate: true,
  lastUpdated: moment().unix(),
  item: {},
};

const configurationSlice = createSlice({
  name: 'configurationReducer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(thunkActions.getConfiguration.fulfilled, (state, { payload }) => {
        const { items } = payload;
        state.isFetching = false;
        state.didInvalidate = false;
        state.lastUpdated = moment.unix();
        if (items?.length > 0) {
          state.item = items[0];
          state.item = {
            ...state.item,
            timeWindow: [
              moment(state.item.time_window.from_time, 'HH:mm').isValid()
                ? moment(state.item.time_window.from_time, 'HH:mm')
                : undefined,
              moment(state.item.time_window.to_time, 'HH:mm').isValid()
                ? moment(state.item.time_window.to_time, 'HH:mm')
                : undefined,
            ],
            workTime: [
              moment(state.item.work_time_from, 'HH:mm').isValid()
                ? moment(state.item.work_time_from, 'HH:mm')
                : undefined,
              moment(state.item.work_time_to, 'HH:mm').isValid()
                ? moment(state.item.work_time_to, 'HH:mm')
                : undefined,
            ],
          };
        } else state.item = {};
        // else {
        //   return <Redirect to={ROUTER.CONFIGURATION} />;
        // }
      })
      .addCase(thunkActions.updateConfiguration.fulfilled, (state) => {
        state.isFetching = false;
        state.didInvalidate = true;
        state.lastUpdated = moment.unix();
      })
      .addCase(types.SET_CONFIG, (state, { payload }) => {
        state.item = payload;
      })
      .addMatcher(
        isAnyOf(thunkActions.getConfiguration.pending, thunkActions.updateConfiguration.pending),
        (state) => {
          state.isFetching = true;
        },
      )
      .addMatcher(
        isAnyOf(thunkActions.getConfiguration.rejected, thunkActions.updateConfiguration.rejected),
        (state) => {
          state.isFetching = false;
          state.didInvalidate = false;
        },
      );
  },
});

export const actions = { ...configurationSlice.actions, ...thunkActions };
export const { reducer } = configurationSlice;
