import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import LOCAL_STORAGE from './config/localStorage';
import LocaleContext from './context/locale';
import Locale from './locales';
import store from './redux';
import './index.css';
import 'antd/dist/antd.css';

// function noConsole() {}

// if (process.env.NODE_ENV === 'production') window.console.log = noConsole;

const locale = new Locale(
  localStorage.getItem(LOCAL_STORAGE.language)
    ? localStorage.getItem(LOCAL_STORAGE.language)
    : 'vn',
);

ReactDOM.render(
  <Provider store={store}>
    <LocaleContext.Provider value={locale}>
      <Router>
        <App />
      </Router>
    </LocaleContext.Provider>
  </Provider>,
  document.getElementById('root'),
);
