import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { PREFIX, typesWithPrefix } from './config';
import { API_URLS } from '../config/api';
import { apiCallPromise } from '../utils/api';

const _types = typesWithPrefix(PREFIX.RECEIVING_VOUCHER);

const types = {
  GET_RECEIVING_VOUCHER: _types('GET_RECEIVING_VOUCHER'),
  ADD_RECEIVING_VOUCHER: _types('GET_RECEIVING_VOUCHER'),
  UPDATE_RECEIVING_VOUCHER: _types('GET_RECEIVING_VOUCHER'),
  GET_RECEIVING_VOUCHER_BY_CODE: _types('GET_RECEIVING_VOUCHER_BY_CODE'),
  CHANGE_STATE: _types('CHANGE_STATE'),
};

const initialState = {
  data: null,
  isFetching: false,
  didInvalidate: true,
  currentReceivingVoucher: {},
  meta: {
    pageSize: 10,
    page: 1,
    total: 0,
  },
};

const thunkActions = {
  getReceivingVoucher: createAsyncThunk(types.GET_RECEIVING_VOUCHER, async (query) => {
    const params = {
      page: query.page,
      page_size: query.page_size,
      state: query.state,
      product_codes: query.product_codes?.length ? query.product_codes.join(',') : undefined,
      storage_code: query.storage_code,
    };

    const api = API_URLS.RECEIVING_VOUCHER.getReceivingVoucher(params);

    const { response, error } = await apiCallPromise(api);

    if (error) rejectWithValue(params);
    return response?.data;
  }),

  getReceivingVoucherById: createAsyncThunk(types.GET_RECEIVING_VOUCHER_BY_CODE, async (code) => {
    const api = API_URLS.RECEIVING_VOUCHER.getReceivingVoucherById(code);
    const { response, error } = await apiCallPromise(api);

    if (!error && response.status === 200 && response.data.success === true) {
      if (meta && meta.onSuccess) meta.onSuccess();
      return { data: response.data.data };
    } else {
      if (meta && meta.onError) meta.onError(error);
      rejectWithValue();
    }
  }),

  addReceivingVoucher: createAsyncThunk(types.ADD_RECEIVING_VOUCHER, async ({ payload, meta }) => {
    const api = API_URLS.RECEIVING_VOUCHER.insertReceivingVoucher(payload);
    const { response, error } = await apiCallPromise(api);

    if (!error && (response.status === 200 || response.status === 201)) {
      if (meta && meta.onSuccess) {
        meta.onSuccess();
        fulfillWithValue({ response });
      }
    } else {
      if (meta && meta.onError) meta.onError(error);
      rejectWithValue({ error });
    }
  }),

  updateReceivingVoucher: createAsyncThunk(
    types.UPDATE_RECEIVING_VOUCHER,
    async ({ payload, meta }) => {
      const api = API_URLS.RECEIVING_VOUCHER.updateReceivingVoucher(payload.code, payload.payload);

      const { response, error } = await apiCallPromise(api);

      if (meta) {
        if (!error && response.status === 200) {
          meta.onSuccess();
        } else {
          meta.onError();
        }
      }

      return response.data;
    },
  ),

  changeState: createAsyncThunk(types.CHANGE_STATE, async ({ payload, meta }) => {
    const api = API_URLS.RECEIVING_VOUCHER.changeStateReceivingVoucher(
      payload.code,
      payload.payload,
    );

    const { response, error } = await apiCallPromise(api);
    if (meta) {
      if (!error && response.status === 200) {
        meta.onSuccess();
      } else {
        meta.onError();
      }
    }

    return response.data;
  }),
};

const receivingVoucherSlice = createSlice({
  name: 'receivingVoucherSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(thunkActions.getReceivingVoucher.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(thunkActions.getReceivingVoucher.fulfilled, (state, { payload }) => {
        const { data, page, page_size, total } = payload;
        state.data = data;
        state.didInvalidate = true;
        state.isFetching = false;
        state.meta.page = page;
        state.meta.pageSize = page_size;
        state.meta.total = total;
      })
      .addCase(thunkActions.getReceivingVoucher.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(thunkActions.getReceivingVoucherById.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(thunkActions.getReceivingVoucherById.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.currentReceivingVoucher = payload;
      })
      .addCase(thunkActions.getReceivingVoucherById.rejected, (state) => {
        state.isFetching = false;
      })
      .addMatcher(
        isAnyOf(
          thunkActions.addReceivingVoucher.fulfilled,
          thunkActions.updateReceivingVoucher.fulfilled,
          thunkActions.changeState.fulfilled,
        ),
        (state) => {
          state.isFetching = false;
          state.didInvalidate = true;
        },
      )
      .addMatcher(
        isAnyOf(
          thunkActions.addReceivingVoucher.pending,
          thunkActions.updateReceivingVoucher.pending,
          thunkActions.changeState.pending,
        ),
        (state) => {
          state.isFetching = true;
        },
      )
      .addMatcher(
        isAnyOf(
          thunkActions.addReceivingVoucher.rejected,
          thunkActions.updateReceivingVoucher.rejected,
          thunkActions.changeState.rejected,
        ),
        (state) => {
          state.isFetching = false;
          state.didInvalidate = true;
        },
      );
  },
});

export const actions = { ...receivingVoucherSlice.actions, ...thunkActions };
export const { reducer } = receivingVoucherSlice;
