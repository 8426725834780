import { PlusOutlined } from '@ant-design/icons';
import { Button, Divider } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import ModalInventoryVoucher from './EditForm/Modal';
import Filter from './components/Filter';
import ListInventoryRecordVoucher from './components/List';
import ROUTER from '../../config/router';
import { actions as inventoryRecordVoucherSlice } from '../../redux/InventoryRecordVoucherRedux';
import { actions as wareHouseActions } from '../../redux/WareHousesRedux';

const InventoryRecordVoucher = ({
  getInventoryRecordVoucher,
  history,
  inventoryRecordVoucherData,
  isFetching,
  changeState,
  wareHouseData,
  getInvetory,
}) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [state, setState] = useState();
  const [productCodes, setProductCodes] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [curInventoryVoucher, setCurInventoryVoucher] = useState({});

  useEffect(() => {
    getInventoryRecordVoucher({
      page,
      page_size: pageSize,
      state,
      product_codes: productCodes,
    });
  }, [page, pageSize, state, productCodes]);

  useEffect(() => {
    getInvetory({
      page: -1,
      page_size: -1,
    });
  }, []);

  const handleChangeState = (value) => {
    setState(value);
  };

  const handleChangeProductCodes = (value) => {
    setProductCodes(value);
  };

  const onPageChange = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
  };

  const handleOpenModal = () => {
    setOpenModal(!openModal);
  };

  const handleAdd = () => {
    history.push(ROUTER.INVENTORY.ADD_INVENTORY_RECORD);
  };

  const getInventoryVoucherByCode = (inventoryCode, payload) => {
    handleOpenModal();
    setCurInventoryVoucher(payload);
  };

  return (
    <>
      <div>
        <h2 style={{ float: 'left' }}>Danh sách phiếu kiểm kê</h2>
        <div style={{ float: 'right' }}>
          <Button
            shape="round"
            type="primary"
            onClick={handleAdd}
            style={{ float: 'right' }}
            icon={<PlusOutlined />}>
            Thêm
          </Button>
        </div>
      </div>
      <Divider style={{ marginTop: 0 }} />
      <div style={{ marginBottom: '15px' }}>
        <Filter onChangeState={handleChangeState} onChangeProductCodes={handleChangeProductCodes} />
      </div>
      <ListInventoryRecordVoucher
        data={inventoryRecordVoucherData}
        isFetching={isFetching}
        onPageChange={onPageChange}
        getInventoryVoucherByCode={getInventoryVoucherByCode}
        changeState={changeState}
        getInventoryRecordVoucher={getInventoryRecordVoucher}
      />
      <ModalInventoryVoucher
        item={curInventoryVoucher}
        toggleModalVoucher={handleOpenModal}
        wareHouseData={wareHouseData}
        visibleModal={openModal}
        getInventoryRecordVoucher={getInventoryRecordVoucher}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  wareHouseData: state?.wareHousesReducer?.data,
  inventoryRecordVoucherData: state?.InventoryRecordVoucherReducer?.data,
  isFetching: state?.InventoryRecordVoucherReducer?.isFetching,
  meta: state?.InventoryRecordVoucherReducer?.meta,
});

const mapDispatchToProps = (dispatch) => ({
  getInventoryRecordVoucher: (params) =>
    dispatch(inventoryRecordVoucherSlice.getInventoryRecordVoucher(params)),
  getInvetory: (params) => dispatch(wareHouseActions.getInvetory(params)),
  changeState: (payload, meta) =>
    dispatch(inventoryRecordVoucherSlice.changeState({ payload, meta })),
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryRecordVoucher);
