/* eslint-disable react/no-access-state-in-setstate */
import { CheckOutlined, ReloadOutlined } from '@ant-design/icons';
import {
  Form,
  Row,
  Col,
  Input,
  Button,
  Typography,
  Select,
  TimePicker,
  DatePicker,
  InputNumber,
  Switch,
  Divider,
} from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import CustomerSearch from '../../../components/FormItems/CustomerSearch';
import FeatureSearch from '../../../components/FormItems/FeatureSearch';
import TomtomSearch from '../../../components/FormItems/TomtomSearch';
import { actions as customerActions } from '../../../redux/CustomerRedux';
import { actions as tomtomActions } from '../../../redux/TomtomRedux';

const { TextArea } = Input;
const FormItem = Form.Item;
const { Title } = Typography;
const { Option } = Select;
const layout = {
  labelCol: {
    span: 24,
  },
};

function disabledDate(current) {
  // Can not select days before today an
  return current < moment().startOf('day');
}

class OrderForm extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      formRef: this.formRef,
      currentOrderCode: props?.defaultValue?.code || '',
      listContacts: [],
      isTransientGuest: false,
      didInitListContacts: false,
      currentTimeWindow: [],
      handleChangeTimeWindow: this.handleChangeTimeWindow,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const {
      defaultValue,
      allCustomers,
      setTomtomSearchAddress,
      setTomtomSearchLatitude,
      setTomtomSearchLongitude,
    } = props;
    const { currentOrderCode, currentTimeWindow, didInitListContacts, handleChangeTimeWindow } =
      state;
    if (defaultValue?.code && defaultValue?.code !== currentOrderCode) {
      state.formRef.current.setFieldsValue(defaultValue);
      setTomtomSearchAddress(defaultValue.address);
      setTomtomSearchLatitude(defaultValue.latitude);
      setTomtomSearchLongitude(defaultValue.longitude);

      return { currentOrderCode: defaultValue?.code };
    }

    if (!didInitListContacts && allCustomers.length && defaultValue?.customer_code) {
      const customerContacts = allCustomers.find(
        (customer) => customer.code === defaultValue?.customer_code,
      )?.contacts;

      return { listContacts: customerContacts, didInitListContacts: true };
    }

    if (
      defaultValue?.timeWindow?.length &&
      typeof defaultValue?.timeWindow[0] === 'object' &&
      !currentTimeWindow.length
    ) {
      handleChangeTimeWindow(defaultValue?.timeWindow);

      return;
    }

    return null;
  }

  componentDidMount() {
    const {
      defaultValue,
      setTomtomSearchAddress,
      setTomtomSearchLatitude,
      setTomtomSearchLongitude,
      getAllCustomers,
    } = this.props;
    if (defaultValue) {
      setTomtomSearchAddress(defaultValue.address);
      setTomtomSearchLatitude(defaultValue.latitude);
      setTomtomSearchLongitude(defaultValue.longitude);
    }
    getAllCustomers();
  }

  componentWillUnmount() {
    this.props.clearTomtomStore();
  }

  onReset = () => this.formRef.current.resetFields();

  validatePhoneNumber = (rule, value, callback) => {
    const re = /^(0)[1-9]([0-9]{8}[0-9]?)\b/;
    if (value && !re.test(value)) callback('Số điện thoại không hợp lệ!');
    else callback();
  };

  onSelectAddress = (value) => this.formRef.current.setFieldsValue({ address: value });

  handleChangeTypeOfCustomer = () => {
    this.formRef.current.setFieldsValue({ customer_code: undefined, customer_contact: undefined });
    this.setState({ isTransientGuest: !this.state.isTransientGuest });
  };

  onSelectCustomer = (value) => {
    this.formRef.current.setFieldsValue({ customer_code: value });
    if (value && this.props.allCustomers.length) {
      const customerContacts = this.props.allCustomers.find(
        (customer) => customer.code === value,
      )?.contacts;

      this.formRef.current.setFieldsValue({
        customer_contact: customerContacts?.length
          ? `${customerContacts[0].name},${customerContacts[0].phone_number}`
          : undefined,
      });
      this.setState({ listContacts: customerContacts?.length ? customerContacts : [] });
    } else {
      this.formRef.current.setFieldsValue({
        customer_contact: undefined,
      });
    }
  };

  onSelectFeature = (value) => this.formRef.current.setFieldsValue({ feature_codes: value });

  handleChangeTimeWindow = (value) => {
    let fromHour = value[0].hour().toString();
    if (value[0].hour() < 10) fromHour = `0${fromHour}`;
    let fromMinute = value[0].minute().toString();
    if (value[0].minute() < 10) fromMinute = `0${fromMinute}`;
    let endHour = value[1].hour().toString();
    if (value[1].hour() < 10) endHour = `0${endHour}`;
    let endMinute = value[1].minute().toString();
    if (value[1].minute() < 10) endMinute = `0${endMinute}`;
    this.setState({
      currentTimeWindow: [
        { from_time: `${fromHour}:${fromMinute}`, to_time: `${endHour}:${endMinute}` },
      ],
    });
  };

  onFinish = (value) => {
    const { currentTimeWindow, isTransientGuest } = this.state;
    const { address, latitude, longitude, allCustomers } = this.props;
    const payload = {
      ...value,
      customer: {
        code: isTransientGuest ? '' : value?.customer_code,
        company_code:
          !isTransientGuest && value.customer_code
            ? allCustomers.find((customer) => customer.code === value.customer_code)?.company_code
            : '',
        contacts: [
          {
            name:
              !isTransientGuest && value?.customer_contact
                ? value?.customer_contact.slice(0, value?.customer_contact.indexOf(','))
                : isTransientGuest
                ? value?.customer_code
                : '',
            phone_number:
              !isTransientGuest && value?.customer_contact
                ? value?.customer_contact.slice(
                    value?.customer_contact.indexOf(',') + 1,
                    value?.customer_contact?.length,
                  )
                : isTransientGuest
                ? value?.customer_contact
                : '',
          },
        ],
        location: { address, latitude, longitude },
        name:
          !isTransientGuest && value?.customer_code
            ? allCustomers.find((customer) => customer.code === value.customer_code)?.name
            : isTransientGuest
            ? value?.customer_code
            : '',
        note: value?.note,
        time_windows:
          !isTransientGuest && value?.customer_code
            ? allCustomers.find((customer) => customer.code === value.customer_code)?.time_windows
            : currentTimeWindow,
        updated_at: '0001-01-01T00:00:00Z', // Temporary fixed
      },
    };
    this.props.onSubmit(payload);
  };

  render() {
    const { isEditMode, defaultValue } = this.props;
    const { listContacts, isTransientGuest } = this.state;

    return (
      <Form ref={this.formRef} initialValues={defaultValue} onFinish={this.onFinish} {...layout}>
        <Row gutter={15}>
          <Col span={4} style={{ textAlign: 'center' }}>
            <FormItem label="Khách mới" name="isTransientGuest">
              <Switch onChange={this.handleChangeTypeOfCustomer} />
            </FormItem>
          </Col>
          <Col span={10}>
            <FormItem
              label="Khách hàng"
              name="customer_code"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập tên khách hàng!',
                },
              ]}>
              {isTransientGuest ? (
                <Input placeholder="Nhập tên khách hàng..." />
              ) : (
                <CustomerSearch
                  selectedValue={defaultValue?.customer_code}
                  onChange={this.onSelectCustomer}
                />
              )}
            </FormItem>
          </Col>
          <Col span={10}>
            <FormItem
              label={isTransientGuest ? 'Số điện thoại' : 'Người nhận - SĐT'}
              name="customer_contact"
              rules={[
                {
                  required: true,
                  message: isTransientGuest
                    ? 'Vui lòng nhập số điện thoại!'
                    : 'Vui lòng chọn người nhận - SĐT!',
                },
                {
                  validator: isTransientGuest ? this.validatePhoneNumber : null,
                },
              ]}>
              {isTransientGuest ? (
                <Input placeholder="Nhập số điện thoại" />
              ) : (
                <Select placeholder="Chọn người nhận - SĐT" disabled={!listContacts?.length}>
                  {listContacts.map((contact) => (
                    <Select.Option
                      key={contact?.phone_number}
                      value={`${contact?.name},${contact?.phone_number}`}>
                      {contact?.name} - {contact?.phone_number}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </Col>
          {isTransientGuest && (
            <Col span={12}>
              <FormItem
                label="Thời gian nhận hàng trong ngày"
                name="timeWindow"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng chọn thời gian giao hàng!',
                  },
                ]}>
                <TimePicker.RangePicker
                  format="HH:mm"
                  value={defaultValue?.timeWindow[0]}
                  onChange={this.handleChangeTimeWindow}
                  style={{ width: '100%' }}
                  placeholder={['Thời gian bắt đầu', 'Thời gian kết thúc']}
                />
              </FormItem>
            </Col>
          )}
        </Row>
        <Row gutter={15}>
          <Col span={12}>
            <FormItem
              label="Ngày giao hàng"
              name="delivery_date"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn ngày giao hàng!',
                },
              ]}>
              <DatePicker
                format="DD/MM/YYYY"
                disabledDate={disabledDate}
                placeholder="Chọn ngày giao hàng..."
                style={{ width: '100%' }}
              />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              label="Loại xe"
              name="vehicle_type"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn loại xe giao hàng!',
                },
              ]}>
              <Select placeholder="Chọn loại phương tiện...">
                <Option value="truck">Xe tải</Option>
                <Option value="motor">Xe gắn máy</Option>
              </Select>
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem
              label="Địa chỉ"
              name="address"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn địa chỉ giao hàng!',
                },
              ]}>
              <TomtomSearch onSelectAddress={this.onSelectAddress} isEditMode={isEditMode} />
            </FormItem>
          </Col>
          {/* <Col span={12}>
              <FormItem
                label="Lái xe"
                name="driver_code"
                rules={[
                  {
                    required: false,
                    message: 'Vui lòng chọn địa chỉ giao hàng!',
                  },
                ]}>
                <Input />
              </FormItem>
            </Col> */}
          <Col span={8}>
            <FormItem
              label="Đặc tính"
              name="feature_codes"
              rules={[
                {
                  required: false,
                  message: 'Vui lòng chọn địa chỉ giao hàng!',
                },
              ]}>
              {/* <Input /> */}
              <FeatureSearch
                onChange={this.onSelectFeature}
                selectedValue={defaultValue?.feature_codes}
                mode="multiple"
              />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem
              label="Thể tích (m³)"
              name="occupied_space"
              rules={[
                {
                  required: false,
                  message: 'Vui lòng điền thể tích hàng!',
                },
              ]}>
              <InputNumber placeholder="Thể tích..." min={0} style={{ width: '100%' }} />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem
              label="Khối lượng (kg)"
              name="demand"
              rules={[
                {
                  required: false,
                  message: 'Vui lòng điền khối lượng hàng!',
                },
              ]}>
              <InputNumber placeholder="Khối lượng..." min={0} style={{ width: '100%' }} />
            </FormItem>
          </Col>
          <Col span={24} lg={24}>
            <FormItem label="Ghi chú" name="note">
              <TextArea placeholder="Ghi chú..." />
            </FormItem>
          </Col>
        </Row>
        <Divider />
        <FormItem style={{ float: 'right', marginTop: '10px' }}>
          <Button
            type="primary"
            htmlType="submit"
            shape="round"
            icon={<CheckOutlined />}
            style={{ marginRight: 8 }}>
            Lưu
          </Button>
          <Button htmlType="reset" onClick={this.onReset} shape="round" icon={<ReloadOutlined />}>
            Nhập lại
          </Button>
        </FormItem>
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
  allCustomers: state.customerReducer.allItems,
  address: state.tomtomReducer.address,
  latitude: state.tomtomReducer.latitude,
  longitude: state.tomtomReducer.longitude,
});

const mapDispatchToProps = (dispatch) => ({
  setTomtomSearchAddress: (payload) => dispatch(tomtomActions.setMapAddress(payload)),
  setTomtomSearchLatitude: (payload) => dispatch(tomtomActions.setMapLatitude(payload)),
  setTomtomSearchLongitude: (payload) => dispatch(tomtomActions.setMapLongitude(payload)),
  clearTomtomStore: () => dispatch(tomtomActions.clearStore()),
  getAllCustomers: () => dispatch(customerActions.getAllCustomers()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderForm);
