import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { PREFIX, typesWithPrefix } from './config';
import { API_URLS } from '../config/api';
import { apiCallPromise } from '../utils/api';

const _types = typesWithPrefix(PREFIX.UNIT);
const types = {
  GET_UNITS: _types('GET_UNITS'),
  ADD_UNIT: _types('ADD_UNIT'),
  UPDATE_UNIT: _types('UPDATE_UNIT'),
  GET_UNIT_BY_CODE: _types('GET_UNIT_BY_CODE'),
  UPDATE_UNIT_STATUS: _types('UPDATE_UNIT_STATUS'),
};

const initialState = {
  data: null,
  isFetching: false,
  didInvalidate: true,
  currentData: {},
  meta: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
};

const thunkActions = {
  getUnits: createAsyncThunk(types.GET_UNITS, async (query) => {
    const params = {
      page: query.page,
      page_size: query.page_size,
    };

    const api = API_URLS.UNITS.getUnits(params);

    const { response } = await apiCallPromise(api);

    return response.data;
  }),

  addUnit: createAsyncThunk(types.ADD_UNIT, async ({ payload, meta }) => {
    const api = API_URLS.UNITS.insertUnit(payload);

    const { response, error } = await apiCallPromise(api);

    if (!error && (response.status === 200 || response.status === 201)) {
      if (meta && meta.onSuccess) {
        meta.onSuccess();
        fulfillWithValue({ response });
      }
    } else {
      if (meta && meta.onError) meta.onError(error);
      rejectWithValue({ error });
    }
  }),

  getUnitById: createAsyncThunk(types.GET_UNIT_BY_CODE, async (code) => {
    if (!code) {
      return;
    }

    const api = API_URLS.UNITS.getUnitById(code);
    const { response } = await apiCallPromise(api);

    return response.data.data;
  }),

  updateUnit: createAsyncThunk(types.UPDATE_UNIT, async ({ payload, meta }) => {
    const api = API_URLS.UNITS.updateUnit(payload.code, payload.payload);

    const { response, error } = await apiCallPromise(api);

    if (!error && response.status === 200 && response.data.success === true) {
      if (meta && meta.onSuccess) meta.onSuccess();
      return { data: response.data.data };
    } else {
      if (meta && meta.onError) meta.onError(error);
      rejectWithValue();
    }

    return response.data;
  }),
};

const unitSlice = createSlice({
  name: 'unitSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(thunkActions.getUnits.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(thunkActions.getUnits.fulfilled, (state, { payload }) => {
        const { data, page, page_size, total } = payload;
        state.data = data;
        state.didInvalidate = true;
        state.isFetching = false;
        state.meta.page = page;
        state.meta.pageSize = page_size;
        state.meta.total = total;
      })
      .addCase(thunkActions.getUnits.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(thunkActions.getUnitById.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(thunkActions.getUnitById.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.currentData = payload;
      })
      .addCase(thunkActions.getUnitById.rejected, (state) => {
        state.isFetching = false;
      })
      .addMatcher(
        isAnyOf(thunkActions.addUnit.fulfilled, thunkActions.updateUnit.fulfilled),
        (state) => {
          state.isFetching = false;
          state.didInvalidate = true;
        },
      )
      .addMatcher(
        isAnyOf(thunkActions.addUnit.pending, thunkActions.updateUnit.pending),
        (state) => {
          state.isFetching = true;
        },
      )
      .addMatcher(
        isAnyOf(thunkActions.addUnit.rejected, thunkActions.updateUnit.rejected),
        (state) => {
          state.isFetching = false;
          state.didInvalidate = true;
        },
      );
  },
});

export const actions = { ...unitSlice.actions, ...thunkActions };
export const { reducer } = unitSlice;
