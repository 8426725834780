import { Input } from 'antd';
import React, { Component } from 'react';

const { Search } = Input;

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataFilter: {
        search: '',
      },
    };
  }

  search = (field) => {
    const { dataFilter } = this.state;
    dataFilter.search = field;
    this.setState({ dataFilter });
    this.props.onSearch(dataFilter);
  };

  render() {
    return (
      <div>
        <Search
          onSearch={this.search}
          style={{ width: '100%' }}
          placeholder="Tìm theo tên"
          enterButton
          allowClear
        />
      </div>
    );
  }
}

export default Filter;
