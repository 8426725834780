import lodash from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { actions as vehiclePartActions } from '../../redux/VehiclePartRedux';
import SelectSearch from './Select';

const { getAllVehiclePart } = vehiclePartActions;

class VehiclePartSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prevSelectedValue: [],
      defaultValue: [],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.allParts && nextProps.allParts.length) {
      if (!lodash.isEqual(nextProps.selectedValue, prevState.prevSelectedValue)) {
        const nextState = { prevSelectedValue: nextProps.selectedValue };
        if (nextProps.selectedValue && nextProps.selectedValue.length) {
          const { selectedValue } = nextProps;
          if (typeof selectedValue === 'string') {
            nextState.defaultValue = VehiclePartSearch.getVehiclePartByCode(
              [selectedValue],
              nextProps,
            );
          } else if (typeof selectedValue === 'object') {
            if (Array.isArray(selectedValue)) {
              if (typeof selectedValue[0] === 'string') {
                nextState.defaultValue = VehiclePartSearch.getVehiclePartByCode(
                  selectedValue,
                  nextProps,
                );
              } else if (typeof selectedValue[0] === 'object') {
                nextState.defaultValue = selectedValue;
              }
            } else {
              nextState.defaultValue = selectedValue;
            }
          }
        } else {
          nextState.defaultValue = [];
        }
        return nextState;
      }
    }
    return null;
  }

  componentDidMount() {
    this.props.getAllVehiclePart();
  }

  static getVehiclePartByCode = (codes, props) => {
    if (codes && codes.length) {
      const { allParts } = props;
      if (!allParts.length) {
        return [];
      }
      const defaultValue = [];
      codes.forEach((code) => {
        const vehiclePartCode = code.trim();
        if (!vehiclePartCode) return;
        const foundVehiclePart = allParts.find((p) => p.code === vehiclePartCode);
        const vehiclePart = foundVehiclePart
          ? {
              key: vehiclePartCode,
              name: `${foundVehiclePart.name}`,
            }
          : { key: vehiclePartCode, name: `[Not found] ${vehiclePartCode}` };
        defaultValue.push(vehiclePart);
      });
      return defaultValue;
    }
    return [];
  };

  render() {
    const { allParts, onChange } = this.props;
    const dataSource = allParts;
    return (
      <SelectSearch
        {...this.props}
        onChange={onChange}
        selectedValue={this.state.defaultValue}
        dataSource={dataSource}
        placeholder={this.props.placeholder || 'Chọn linh kiện'}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  allParts: state.vehiclePartReducer.allItems,
});

const mapDispatchToProps = (dispatch) => ({
  getAllVehiclePart: () => dispatch(getAllVehiclePart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(VehiclePartSearch);
