import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Divider, Row, Button, Modal, message, notification } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as XLSX from 'xlsx';

import Filter from './components/Filter';
import CustomerList from './components/List';
import ROUTER from '../../config/router';
import WithConfig from '../../hoc/WithConfig';
import WithLoading from '../../hoc/WithLoading';
import { actions as customerActions } from '../../redux/CustomerRedux';

const { getCustomersIfNeed, changePage, invalidateCustomers, insertManyCustomers, changeState } =
  customerActions;

const SheetJSFT = [
  'xlsx',
  'xlsb',
  'xlsm',
  'xls',
  'xml',
  'csv',
  'txt',
  'ods',
  newFunction(),
  'uos',
  'sylk',
  'dif',
  'dbf',
  'prn',
  'qpw',
  '123',
  'wb*',
  'wq*',
  'html',
  'htm',
]
  .map(function (x) {
    return `.${x}`;
  })
  .join(',');
class CustomerPage extends Component {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props) {
    super(props);

    this.state = {
      dataFilter: {
        page: 1,
        pageSize: 10,
        search: '',
        active: undefined,
      },
      dataFromFile: [],
      loading: false,
      visible: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { search, type, active, page, pageSize } = state.dataFilter;
    props.getCustomersIfNeed({
      page,
      pageSize,
      search,
      type,
      active,
    });

    return null;
  }

  onPageChange = (page, pageSize) => {
    const { invalidateCustomers } = this.props;
    const { dataFilter } = this.state;

    dataFilter.page = page;
    dataFilter.pageSize = pageSize;
    this.setState({
      dataFilter,
    });
    invalidateCustomers();
  };

  showModal = () => this.setState({ visible: true });

  handleOk = () => {
    this.props.insertManyCustomers(
      { customers: this.state.dataFromFile },
      {
        onSuccess: (res) => {
          if (res?.data?.success === true) {
            notification.open({
              message: `Thêm thành công ${res?.data?.data?.length} khách hàng!`,
              type: 'success',
            });
          } else if (
            res?.data?.success === false &&
            res?.data?.message === 'must provide at least one element in input slice'
          ) {
            notification.open({
              message: `Thêm khách hàng từ file thất bại! Xem lại thông tin khách hàng trong file đã tồn tại hay chưa?`,
              type: 'error',
            });
          }
        },
        onError: (err) => {
          const message = JSON.parse(err.response).message;
          notification.open({
            message: `Thêm khách hàng từ file thất bại!`,
            description: `${message}`,
            type: 'error',
          });
        },
      },
    );

    this.setState({ loading: true });

    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 2000);
  };

  handleCancel = () => this.setState({ dataFromFile: [], visible: false });

  handleOnAdd = () => {
    const { history } = this.props;
    history.push(ROUTER.CUSTOMER.ADD);
  };

  onChangeFilter = (data) => {
    const { dataFilter } = this.state;
    this.setState({ dataFilter: { ...dataFilter, ...data } });
  };

  readExcelFile(file) {
    const promise = new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);

      reader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: 'buffer' });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      reader.onerror = (err) => reject(err);
    });

    promise
      .then((data) => {
        const recordFromFile = [];
        if (data.length) {
          data.map((record, i) => {
            // Nếu record là thông tin 1 khách hàng
            if (record['Tên KH']) {
              const newCustomer = {
                name: record['Tên KH'],
                type: record['Phân loại'] || 'retail',
                note: record['Ghi chú'],
                location: {
                  address: record['Địa chỉ'],
                  latitude: 0,
                  longitude: 0,
                },
                time_windows: [
                  {
                    from_time: record['Thời gian nhận hàng'],
                    to_time: record['__EMPTY_1'],
                  },
                ],
                contacts: [
                  {
                    name: record['Liên hệ'],
                    phone_number: record['__EMPTY'],
                  },
                ],
              };

              // Nếu bản ghi sau record là liên hệ của record
              for (let j = i + 1; j < data.length; j++) {
                if (data[j]['Tên KH']) break;
                else if (!data[j]['Tên KH'] && data[j]['Liên hệ']) {
                  const contact = {
                    name: data[j]['Liên hệ'],
                    phone_number: data[j]['__EMPTY'],
                  };
                  newCustomer.contacts.push(contact);
                }
              }

              recordFromFile.push(newCustomer);
            }
          });
        }
        if (this.state.dataFromFile.length > 0) this.setState({ dataFromFile: [] });
        this.setState({ dataFromFile: [...recordFromFile] });
        message.success('Tải file thành công!');
      })
      .catch((error) => {
        this.setState({ dataFromFile: [] });
        // eslint-disable-next-line no-console
        console.log(error);
        message.error('Tải file thất bại!');
      });
  }

  render() {
    const { dataFromFile, visible, loading } = this.state;
    const { isFetching, meta, customers, allVehicles, changeState } = this.props;
    return (
      <>
        <div>
          <h2 style={{ float: 'left' }}>Danh sách khách hàng</h2>
          <div style={{ float: 'right', width: '240px' }}>
            <Button
              style={{ marginRight: 15 }}
              type="default"
              shape="round"
              icon={<UploadOutlined />}
              size="medium"
              onClick={this.showModal}>
              Upload File
            </Button>
            <Button shape="round" type="primary" onClick={this.handleOnAdd} icon={<PlusOutlined />}>
              Thêm
            </Button>
          </div>
        </div>
        <Divider style={{ marginTop: 0 }} />

        <div style={{ marginBottom: '15px' }}>
          <Filter onSearch={this.onChangeFilter} />
        </div>

        <CustomerList
          allVehicles={allVehicles}
          isFetching={isFetching}
          dataSource={customers}
          meta={meta}
          onPageChange={this.onPageChange}
          changeStateCustomer={changeState}
        />

        <Modal
          visible={visible}
          title="Nhập File Excel"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width={1500}
          footer={[
            <Button
              key="submit"
              shape="round"
              type="primary"
              loading={loading}
              onClick={this.handleOk}>
              Lưu
            </Button>,
            <Button key="back" shape="round" onClick={this.handleCancel}>
              Huỷ Bỏ
            </Button>,
          ]}>
          <input
            type="file"
            accept={SheetJSFT}
            label="Tải lên File"
            onChange={(e) => {
              const file = e.target.files[0];
              this.readExcelFile(file);
            }}
          />
          <a
            href="https://docs.google.com/spreadsheets/d/10QGaahWlitoBc7Lz-cjlU6HFP0y-fca1/edit#gid=2053929411"
            target="_blank">
            Mẫu file Excel
          </a>
          <CustomerList
            isFetching={isFetching}
            dataSource={dataFromFile}
            meta={meta}
            onChange={this.onPageChange}
            onPageChange={this.onPageChange}
            isImportFromFile
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { customerReducer } = state;
  return {
    customers: customerReducer.items,
    meta: customerReducer.meta,
    total: customerReducer.meta.total,
    isFetching: customerReducer.isFetching,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCustomersIfNeed: (filterOption) => dispatch(getCustomersIfNeed(filterOption)),
  changePage: (page, pageSize, total) => dispatch(changePage(page, pageSize, total)),
  invalidateCustomers: () => dispatch(invalidateCustomers()),
  insertManyCustomers: (payload, meta) => dispatch(insertManyCustomers({ payload, meta })),
  changeState: (payload, meta) => dispatch(changeState({ payload, meta })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WithConfig('customer')(WithLoading(CustomerPage)));

function newFunction() {
  return 'fods';
}
