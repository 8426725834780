import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { API_URLS } from '../config/api';
import { apiCallPromise } from '../utils/api';
import { PREFIX, typesWithPrefix } from './config';
const _types = typesWithPrefix(PREFIX.INVENTORY);

const types = {
  GET_INVENTORY: _types('GET_INVENTORY'),
};

const initialState = {
  data: null,
  isFetching: false,
  didInvalidate: true,
  meta: {
    pageSize: 10,
    page: 1,
    total: 0,
  },
};

const thunkActions = {
  getInvetory: createAsyncThunk(types.GET_INVENTORY, async (query) => {
    const params = {
      page: query.page,
      page_size: query.page_size,
      state: query.state,
      storage_codes: query.storage_codes,
      product_codes: query.product_codes,
      category_codes: query.category_codes,
      expiration_from: query.expiration_from,
      expirtaion_to: query.expirtaion_to,
      quantity_from: query.quantity_from,
      quantity_to: query.quantity_to,
      search: query.search,
    };

    const api = API_URLS.INVENTORY.getInventory(params);

    const { response, error } = await apiCallPromise(api);

    if (error) rejectWithValue(params);

    return response?.data;
  }),
};

const inventorySlice = createSlice({
  name: 'inventorySlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(thunkActions.getInvetory.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(thunkActions.getInvetory.fulfilled, (state, { payload }) => {
        const { data, page, page_size, total } = payload;
        state.data = data;
        state.didInvalidate = true;
        state.isFetching = false;
        state.meta.page = page;
        state.meta.pageSize = page_size;
        state.meta.total = total;
      })
      .addCase(thunkActions.getInvetory.rejected, (state) => {
        state.isFetching = false;
      });
  },
});

export const actions = { ...inventorySlice.actions, ...thunkActions };
export const { reducer } = inventorySlice;
