import { Col, Row, notification } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Card from '../../components/Card';
import ROUTER from '../../config/router';
import { actions as customerActions } from '../../redux/CustomerRedux';
import { actions as driverActions } from '../../redux/DriverRedux';
import { isBelowBreakpoint } from '../../utils/window';
import SampleRouteForm from './components/Form';

class EditSampleRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount = () => {
    const {
      getCustomerById,
      match: {
        params: { code },
      },
    } = this.props;

    getCustomerById(code);
  };

  onSubmit = (values) => {
    const {
      updateCustomer,
      getCustomerById,
      history,
      currentAddress,
      currentLatitude,
      currentLongitude,
      match: {
        params: { code },
      },
    } = this.props;

    const customerInfoPayload = {
      ...values,
      address: undefined,
      code,
      location: { address: currentAddress, latitude: currentLatitude, longitude: currentLongitude },
      time_windows: values.time_windows.map((time) => ({
        from_time: `${time[0].hour() > 9 ? time[0].hour() : `0${time[0].hour()}`}:${
          time[0].minute() > 9 ? time[0].minute() : `0${time[0].minute()}`
        }`,
        to_time: `${time[1].hour() > 9 ? time[1].hour() : `0${time[1].hour()}`}:${
          time[1].minute() > 9 ? time[1].minute() : `0${time[1].minute()}`
        }`,
      })),
    };

    updateCustomer(code, customerInfoPayload, {
      onSuccess: () => {
        notification.open({
          message: 'Sửa thông tin khách hàng thành công.',
          type: 'success',
        });
        getCustomerById(code);
        history.push(ROUTER.CUSTOMER.INDEX);
      },
      onError: () =>
        notification.open({
          message: 'Sửa thông tin khách hàng thất bại!',
          type: 'error',
        }),
    });
  };

  render() {
    const { code, contacts, location, name, note, time_windows } = this.props.currentCustomerData;
    const defaultValue = {
      code,
      contacts,
      address: location?.address,
      longitude: location?.longitude,
      latitude: location?.latitude,
      name,
      note,
      time_windows,
    };

    return (
      <div>
        <Row type="flex" justify="center">
          <Col span={24} lg={24}>
            <Card hoverable={false} bordered={!isBelowBreakpoint()}>
              <SampleRouteForm onSubmit={this.onSubmit} defaultValue={defaultValue} isEditMode />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ customerReducer, tomtomReducer }) => ({
  currentCustomerData: customerReducer.currentCustomer,
  currentAddress: tomtomReducer.address,
  currentLatitude: tomtomReducer.latitude,
  currentLongitude: tomtomReducer.longitude,
});

const mapDispatchToProps = (dispatch) => ({
  getDriverById: (driverId) => dispatch(driverActions.getDriverById(driverId)),
  updateDriver: (driverId, payload, meta) =>
    dispatch(driverActions.updateDriver(driverId, payload, meta)),
  getCustomerById: (customerId) => dispatch(customerActions.getCustomerById({ customerId })),
  updateCustomer: (customerId, payload, meta) =>
    dispatch(customerActions.updateCustomer({ customerId, payload, meta })),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditSampleRoute));
