export const barChartOption = {
  chart: {
    type: 'bar',
    height: 350,
  },
  plotOptions: {
    bar: {
      borderRadius: 4,
      horizontal: true,
    },
  },
  dataLabels: {
    enabled: false,
  },
  tooltip: {
    y: {
      formatter(val) {
        const totalMinutes = Math.floor(val / 60);
        const seconds = val % 60;
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return `${hours}h ${minutes}m ${seconds}s`;
      },
    },
  },
  xaxis: {
    categories: [],
  },
};
