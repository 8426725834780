// import InventoryRecordVoucher from 'containers/InventoryRecordVoucher';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ROUTER from '../../../config/router';
import CancelVoucher from '../../CancelVoucher';
import AddCancelVoucher from '../../CancelVoucher/add';
import Categories from '../../Categories';
import DeliveryVoucher from '../../DeliveryVoucher';
import AddDeliveryVoucher from '../../DeliveryVoucher/add';
import InventoryRecordVoucher from '../../InventoryRecordVoucher';
import AddInventoryVoucher from '../../InventoryRecordVoucher/add';
import ListWareHouses from '../../ListWareHouses';
import ProductPage from '../../Product';
import ReceivingVoucher from '../../ReceivingVoucher';
import AddReceivingVoucher from '../../ReceivingVoucher/add';
import Unit from '../../Unit';
import WareHouse from '../../WareHouse';

const Inventory = () => (
  <Switch>
    <Route exact path={ROUTER.INVENTORY.STORAGE} component={ListWareHouses} />

    <Route exact path={ROUTER.INVENTORY.CATEGORIES} component={Categories} />

    <Route exact path={ROUTER.INVENTORY.UNITS} component={Unit} />

    <Route exact path={ROUTER.INVENTORY.PRODUCTS} component={ProductPage} />

    <Route exact path={ROUTER.INVENTORY.DELIVERY_VOUCHER} component={DeliveryVoucher} />
    <Route exact path={ROUTER.INVENTORY.ADD_DELIVERY_VOUCHER} component={AddDeliveryVoucher} />

    <Route exact path={ROUTER.INVENTORY.RECEIVING_VOUCHER} component={ReceivingVoucher} />
    <Route exact path={ROUTER.INVENTORY.ADD_RECEIVING_VOUCHER} component={AddReceivingVoucher} />

    <Route exact path={ROUTER.INVENTORY.WAREHOUSES} component={WareHouse} />

    <Route exact path={ROUTER.INVENTORY.CANCELLATION_VOUCHER} component={CancelVoucher} />
    <Route exact path={ROUTER.INVENTORY.ADD_CANCELLATION_VOUCHER} component={AddCancelVoucher} />

    <Route exact path={ROUTER.INVENTORY.INVENTORY_RECORD} component={InventoryRecordVoucher} />
    <Route exact path={ROUTER.INVENTORY.ADD_INVENTORY_RECORD} component={AddInventoryVoucher} />
  </Switch>
);

export default Inventory;
