import { PlusOutlined } from '@ant-design/icons';
import { Divider, Button } from 'antd';
// import queryString from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Filter from './components/Filter';
import VehicleList from './components/List';
import ROUTER from '../../config/router';
import WithConfig from '../../hoc/WithConfig';
import WithLoading from '../../hoc/WithLoading';
import { actions as vehicleActions } from '../../redux/VehicleRedux';
const { getVehiclesIfNeed, changePage, invalidateVehicles } = vehicleActions;

class VehiclePage extends Component {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props) {
    super(props);
  }
  state = {
    visible: false,
    dataFilter: {
      page: 1,
      page_size: 10,
      search: '',
      vehicle_type: '',
    },
  };

  static getDerivedStateFromProps(props, state) {
    const { search, vehicle_type, page, page_size } = state.dataFilter;
    props.getVehiclesIfNeed({
      page,
      page_size,
      search,
      vehicle_type,
    });
    return null;
  }

  onPageChange = (page, page_size) => {
    const { dataFilter } = this.state;
    const { invalidateVehicles } = this.props;
    dataFilter.page = page;
    dataFilter.page_size = page_size;
    this.setState({
      dataFilter,
    });
    invalidateVehicles();
  };

  handleOnAdd = () => {
    const { history } = this.props;
    history.push(ROUTER.VEHICLE.ADD_VEHICLE);
  };

  onChangeFilter = (data) => {
    const { dataFilter } = this.state;
    this.setState({ dataFilter: { ...dataFilter, ...data } });
  };

  render() {
    const { isFetching, meta, vehicles } = this.props;
    return (
      <>
        <div>
          <h2 style={{ float: 'left' }}>Danh sách phương tiện</h2>
          <div style={{ float: 'right' }}>
            <Button shape="round" type="primary" onClick={this.handleOnAdd} icon={<PlusOutlined />}>
              Thêm
            </Button>
          </div>
        </div>
        <Divider style={{ marginTop: 0 }} />

        <div style={{ width: 600, maxWidth: '100%', marginBottom: '15px' }}>
          <Filter onSearch={this.onChangeFilter} />
        </div>
        <VehicleList
          isFetching={isFetching}
          dataSource={vehicles}
          meta={meta}
          onPageChange={this.onPageChange}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { vehicleReducer } = state;
  return {
    vehicles: vehicleReducer.items,
    meta: vehicleReducer.meta,
    total: vehicleReducer.meta.total,
    isFetching: vehicleReducer.isFetching,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getVehiclesIfNeed: (filterOption) => dispatch(getVehiclesIfNeed(filterOption)),
  changePage: (page, page_size, total) => dispatch(changePage(page, page_size, total)),
  invalidateVehicles: () => dispatch(invalidateVehicles()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WithConfig('vehicle')(WithLoading(VehiclePage)));
