/* eslint-disable react/prop-types */
import { Row, Col, notification } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Card from '../../components/Card';
import ROUTER from '../../config/router';
import WithConfig from '../../hoc/WithConfig';
import UserForm from './components/UserForm';

class AddUserPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      duplicateUsernameValue: '',
    };
  }

  handleSubmit = (payload) => {
    const { history } = this.props;
    this.props.insertUser(payload, {
      onSuccess: () => {
        notification.success({ message: 'Thêm tài khoản thành công.' });
        history.push(ROUTER.ACCOUNT.INDEX);
      },
      onError: (errorCode) =>
        notification.error({ message: `Thêm tài khoản thất bại! ${errorCode}` }),
    });
  };

  render() {
    const { userCompanyCode } = this.state;
    return (
      <Row>
        <Col span={12} offset={6}>
          <Card hoverable={false} hasShadow>
            <UserForm emitData={this.handleSubmit} company_code={userCompanyCode} />
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  userCompanyCode: state.authReducer.authUser.company_code,
});

const mapDispatchToProps = (dispatch) =>
  // const { pushNotification } = actionContainer;
  ({
    // pushNotification: (payload) => dispatch(pushNotification(payload)),
    // insertUser: (payload, meta) => dispatch(insertUser(payload, meta)),
  });
export default connect(mapStateToProps, mapDispatchToProps)(WithConfig('user')(AddUserPage));
