import { Switch, Table, notification } from 'antd';
import React from 'react';

const column = (editStorage, chageStatusStorage) => [
  {
    title: <b>Mã kho</b>,
    dataIndex: 'code',
    render: (value, record) => {
      const edit = () => {
        editStorage(record.code, {
          code: record.code,
          name: record.name,
          company_code: record.company_code,
          level: record.level,
          parent_code: record.parent_code,
        });
      };
      return <a onClick={edit}>{value}</a>;
    },
  },
  {
    title: <b>Tên kho</b>,
    dataIndex: 'name',
  },
  {
    title: <b>Trạng thái</b>,
    dataIndex: 'status',
    render: (value, record) => (
      <Switch
        defaultChecked={value}
        onChange={(value) => {
          chageStatusStorage(
            {
              code: record.code,
              payload: {
                status: value,
              },
            },
            {
              onSuccess: () => {
                notification.open({
                  message: 'Thay đổi hoạt động thành công',
                  type: 'success',
                });
              },
              onError: (errorCode) =>
                notification.open({
                  message: 'Thay đổi hoạt động không thành công',
                  type: 'error',
                }),
            },
          );
        }}
      />
    ),
  },
];

const List = ({ dataStorage, onPageChange, meta, editStorage, chageStatusStorage }) => (
  <>
    <Table
      size="small"
      columns={column(editStorage, chageStatusStorage)}
      dataSource={dataStorage}
      pagination={{
        current: meta?.page,
        pageSize: meta?.pageSize || 10,
        total: meta?.total,
        onChange: onPageChange,
        showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total}`,
      }}
    />
  </>
);

export default List;
