import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Card from '../../../components/Card';
import WithLoading from '../../../hoc/WithLoading';
import Form from '../components/LoginForm';

class LoginWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
    };
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      error: nextProps.error,
    };
  }

  removeError = () => {
    this.setState(() => ({
      error: false,
    }));
  };

  render() {
    const { doLogin } = this.props;
    const { error } = this.state;
    return (
      <Card hoverable={false} clickable={false} hasShadow>
        <h1 style={{ textAlign: 'center' }}>Đăng nhập</h1>
        <Form
          error={error}
          onSubmit={(values) => doLogin(values)}
          removeError={() => this.removeError()}
        />
        <div>
          <span style={{ float: 'left' }} />
          <Link to="/" style={{ float: 'right' }}>
            {`${'Quên mật khẩu'}?`}
          </Link>
          <Link to="/dang-ky" style={{ float: 'left' }}>
            {`${'Đăng ký'}`}
          </Link>
        </div>
      </Card>
    );
  }
}

export default WithLoading(LoginWrapper);
