import { Switch, Table, notification } from 'antd';
import React from 'react';

const column = (editCategory, categories, changeStatusCategory) => [
  {
    title: <b>Mã nhóm hàng</b>,
    dataIndex: 'code',
    render: (value, record) => {
      const edit = () => {
        editCategory({
          code: record.code,
          name: record.name,
          level: record.level,
          parent_code: record.parent_code,
          description: record.description,
        });
      };
      return <a onClick={edit}>{value}</a>;
    },
  },
  {
    title: <b>Tên nhóm hàng</b>,
    dataIndex: 'name',
  },
  // {
  //   title: <b>Nhóm cha</b>,
  //   dataIndex: 'parent_code',
  //   render: (value) => {
  //     const category = categories.find((element) => element.code === value);
  //     return category?.name;
  //   },
  // },
  {
    title: <b>Mô tả</b>,
    dataIndex: 'description',
  },
  {
    title: <b>Trạng thái</b>,
    dataIndex: 'status',
    render: (value, record) => (
      <Switch
        defaultChecked={value}
        onChange={(value) => {
          changeStatusCategory(
            {
              code: record.code,
              payload: {
                status: value,
              },
            },
            {
              onSuccess: () => {
                notification.open({
                  message: 'Thay đổi hoạt động thành công',
                  type: 'success',
                });
              },
              onError: (errorCode) =>
                notification.open({
                  message: 'Thay đổi hoạt động không thành công',
                  type: 'error',
                }),
            },
          );
        }}
      />
    ),
  },
];

const ListCategories = ({
  dataCategories,
  editCategory,
  changeStatusCategory,
  // onPageChange,
  // meta,
  // allCompanies,
}) => (
  <>
    <Table
      size="small"
      columns={column(editCategory, dataCategories, changeStatusCategory)}
      dataSource={dataCategories}
      rowKey="code"
      pagination={false}
    />
  </>
);

export default ListCategories;
