import { EyeInvisibleOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Checkbox, Popconfirm, Popover, notification } from 'antd';
import React from 'react';
import { connect } from 'react-redux';

import Route from './route';
import { COLORS } from '../../../../../config/colors';
import { actions as routingActions } from '../../../../../redux/RoutingRedux';

function RouteList({
  routes,
  onChangeNodePosition,
  onSwapNodes,
  toggleRoute,
  hiddenRoutesIndex,
  invalidateSolution,
  handleChangeRouteSendToMobileApp,
  selectedRoutes,
  onDelete,
}) {
  return (
    <>
      {routes.map((route, routeIndex) => (
        <div
          key={`${routeIndex}`}
          style={{
            height: 70,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingRight: 10,
            fontSize: 17,
          }}>
          {selectedRoutes && handleChangeRouteSendToMobileApp ? (
            <div style={{ marginRight: '15px' }}>
              <Checkbox
                checked={selectedRoutes?.find((code) => code === route.code)}
                onChange={() => handleChangeRouteSendToMobileApp(route?.code)}
              />
            </div>
          ) : null}
          <div
            style={{
              whiteSpace: 'nowrap',
              width: '90%',
              height: 55,
              overflow: 'auto',
              // marginTop: 10,
            }}>
            <Route
              mainColor={COLORS[routeIndex]}
              routeIndex={routeIndex}
              route={route}
              onChangeNodePosition={onChangeNodePosition}
              onSwapNodes={onSwapNodes}
              // onShowNodeInfo={(node) => onShowNodeInfo(node, routeIndex)}
            />
          </div>

          {(route?.status === 'Scheduled' ||
            route?.status === 'DriverConfirming' ||
            route?.status === 'DriverRejected') && (
            <Popconfirm
              title="Bạn có chắc chắn muốn xóa? "
              onConfirm={() => onDelete(route.code)}
              cancelText="Hủy"
              okText="Đồng ý">
              <Popover>
                <Button
                  style={{ borderColor: 'red' }}
                  icon={<DeleteOutlined style={{ color: 'red' }} />}
                  shape="circle"
                />
              </Popover>
            </Popconfirm>
          )}

          <div style={{ padding: 5 }}>
            <Button
              type={hiddenRoutesIndex.includes(routeIndex) ? 'danger' : 'default'}
              disabled={!route?.nodes?.length}
              icon={
                hiddenRoutesIndex.includes(routeIndex) ? <EyeOutlined /> : <EyeInvisibleOutlined />
              }
              shape="circle"
              onClick={() => {
                toggleRoute(routeIndex);

                invalidateSolution();
              }}
            />
          </div>
        </div>
      ))}
    </>
  );
}

RouteList.defaultProps = { routes: [] };

const mapStateToProps = (state) => ({
  hiddenRoutesIndex: state.routingReducer.hiddenRoutesIndex,
});

const mapDispatchToProps = (dispatch) => ({
  toggleRoute: (payload) => dispatch(routingActions.toggleRoute(payload)),
  invalidateSolution: () => dispatch(routingActions.invalidateSolution()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RouteList);
