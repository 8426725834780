/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import { DropTarget } from 'react-dnd';

import Node from './RoutingTable/RouteList/node';
import ItemTypes from './RoutingTable/constants';

const dropTarget = {
  drop(props, monitor) {
    props.onDropNode(monitor.getItem(), null);
  },
};

function collectDrop(connect, monitor) {
  return { connectDropTarget: connect.dropTarget(), isOver: monitor.isOver() };
}

const UnscheduledOrders = (props) => {
  const { isOver, connectDropTarget, orders } = props;

  return connectDropTarget(
    <div
      style={{
        backgroundColor: isOver ? '#fffbc8' : '#fffee9',
        paddingTop: 10,
        marginBottom: 10,
        zIndex: 2,
        position: 'sticky',
        textAlign: 'center',
        top: 0,
      }}>
      <h4>Đơn chưa lập lịch</h4>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          maxHeight: 350,
          width: '100%',
          overflowX: 'scroll',
          marginTop: 10,
          paddingBottom: 15,
        }}>
        {orders.map((record, index) => (
          <div
            key={record?.code || record?.order_code}
            style={{ marginLeft: 6, display: 'inline' }}>
            <Node mainColor="#294560" node={{ ...record, index: 0 }} />
            {/* <Node
              mainColor="#294560"
              node={{
                index: 0,
                customer: record?.customer,
                distance: 0,
                // duration: 0,
                demand: record?.demand,
                location: record?.customer?.location,
                order_code: record?.code || record?.order_code,
                shipping_code: record?.shipping_code,
                data: {
                  index,
                  comment: '',
                  completed_image: '',
                  customer: record?.customer,
                  demand: record?.demand,
                  distance: 0,
                  estimated_arrival_time: null,
                  estimated_departure_time: null,
                  occupied_space: record?.occupied_space,
                  order_code: record?.code,
                  rate: 0,
                  real_arrived_time: null,
                  real_departure_time: null,
                  status: record?.status,
                },
              }}
            /> */}
          </div>
        ))}
      </div>
    </div>,
  );
};

export default DropTarget(ItemTypes.NODE, dropTarget, collectDrop)(UnscheduledOrders);
