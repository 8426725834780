import { DeleteOutlined } from '@ant-design/icons';
import { Card, Button } from 'antd';
import React from 'react';
import './index.css';

const CustomCard = ({
  hoverable,
  hasShadow,
  clickable,
  style,
  children,
  hasDelete,
  onDelete,
  title,
  extra,
}) => (
  <Card
    extra={extra}
    title={title}
    style={{
      maxWidth: '100%',
      margin: 10,
      boxShadow: hasShadow
        ? '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
        : '',
      cursor: clickable ? 'pointer' : '',
      ...style,
    }}
    className={hoverable ? 'custom-card-hoverable' : ''}
    actions={
      hasDelete
        ? [
            <Button onClick={onDelete} key="delete">
              <DeleteOutlined />
            </Button>,
          ]
        : []
    }>
    {children}
  </Card>
);

export default CustomCard;
