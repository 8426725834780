export default {
  add: 'Add',
  edit: 'Edit Info',
  supplier: 'Supplier',
  product: 'Product',
  detail: 'Product Detail',
  categories: 'Categories',
  order: 'Order',
  service: 'Service',
  guests: 'Guests',
};
