import { SaveOutlined } from '@ant-design/icons';
import { Divider, Row, Col, Button, Typography } from 'antd';
import React, { Component } from 'react';
import { withRouter } from 'react-router';

import ROUTER from '../../../config/router';

const { Title } = Typography;

class DetailList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleOnEdit = () => {
    const { history } = this.props;
    history.push(ROUTER.VEHICLE.EDIT_VEHICLE.replace(':vehicleInfoId', this.props.defaultValue.id));
  };

  render() {
    const { defaultValue } = this.props;
    const FormTitle = 'Thông tin phương tiện';
    return (
      <>
        <Title level={3} description="Thông báo">
          {FormTitle}
        </Title>
        <Divider />

        <Row gutter={15}>
          <Col span={12} lg={4}>
            <Title level={4}>Tên xe: {defaultValue.label}</Title>
          </Col>
          <Col span={12} lg={6}>
            <Title level={4}>
              Loại phương tiện: {defaultValue.vehicle_type === 'truck' ? 'Xe tải' : 'Xe gắn máy'}
            </Title>
          </Col>
          <Col span={12} lg={6}>
            <Title level={4}>Biển số xe: {defaultValue.license_plate_number}</Title>
          </Col>
          <Col span={12} lg={5}>
            <Title level={4}>Mức tiêu thụ nhiên liệu (lít/km): {defaultValue.fuel_per_km}</Title>
          </Col>
          <Col span={24} lg={3}>
            <Button
              shape="round"
              type="primary"
              onClick={this.handleOnEdit}
              style={{ float: 'right' }}
              icon={<SaveOutlined />}>
              Sửa
            </Button>
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(DetailList);
