import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { PREFIX, typesWithPrefix } from './config';
import { API_URLS } from '../config/api';
import { apiCallPromise } from '../utils/api';

const _types = typesWithPrefix(PREFIX.INVENTORY_RECORD_VOUCHER);

const types = {
  GET_INVENTORY_RECORD_VOUCHER: _types('GET_INVENTORY_RECORD_VOUCHER'),
  ADD_INVENTORY_RECORD_VOUCHER: _types('GET_INVENTORY_RECORD_VOUCHER'),
  UPDATE_INVENTORY_RECORD_VOUCHER: _types('GET_INVENTORY_RECORD_VOUCHER'),
  GET_INVENTORY_RECORD_VOUCHER_BY_CODE: _types('GET_INVENTORY_RECORD_VOUCHER_BY_CODE'),
  CHANGE_STATE: _types('CHANGE_STATE'),
};

const initialState = {
  data: null,
  isFetching: false,
  didInvalidate: true,
  currentInventoryRecordVoucher: {},
  meta: {
    pageSize: 10,
    page: 1,
    total: 0,
  },
};

const thunkActions = {
  getInventoryRecordVoucher: createAsyncThunk(types.GET_INVENTORY_RECORD_VOUCHER, async (query) => {
    const params = {
      page: query.page,
      page_size: query.page_size,
      state: query.state,
      product_codes: query.product_codes?.length ? query.product_codes.join(',') : undefined,
    };

    const api = API_URLS.INVENTORY_RECORD_VOUCHER.getInventoryRecordVoucher(params);

    const { response, error } = await apiCallPromise(api);

    if (error) rejectWithValue(params);
    return response?.data;
  }),

  getInventoryRecordVoucherById: createAsyncThunk(
    types.GET_INVENTORY_RECORD_VOUCHER_BY_CODE,
    async (code) => {
      const api = API_URLS.INVENTORY_RECORD_VOUCHER.getInventoryRecordVoucherById(code);
      const { response, error } = await apiCallPromise(api);

      if (!error && response.status === 200 && response.data.success === true) {
        if (meta && meta.onSuccess) meta.onSuccess();
        return { data: response.data.data };
      } else {
        if (meta && meta.onError) meta.onError(error);
        rejectWithValue();
      }
    },
  ),

  addInventoryRecordVoucher: createAsyncThunk(
    types.ADD_INVENTORY_RECORD_VOUCHER,
    async ({ payload, meta }) => {
      const api = API_URLS.INVENTORY_RECORD_VOUCHER.insertInventoryRecordVoucher(payload);
      const { response, error } = await apiCallPromise(api);

      if (!error && (response.status === 200 || response.status === 201)) {
        if (meta && meta.onSuccess) {
          meta.onSuccess();
          fulfillWithValue({ response });
        }
      } else {
        if (meta && meta.onError) meta.onError(error);
        rejectWithValue({ error });
      }
    },
  ),

  updateInventoryRecordVoucher: createAsyncThunk(
    types.UPDATE_INVENTORY_RECORD_VOUCHER,
    async ({ payload, meta }) => {
      const api = API_URLS.INVENTORY_RECORD_VOUCHER.updateInventoryRecordVoucher(
        payload.code,
        payload.payload,
      );

      const { response, error } = await apiCallPromise(api);

      if (meta) {
        if (!error && response.status === 200) {
          meta.onSuccess();
        } else {
          meta.onError();
        }
      }

      return response.data;
    },
  ),

  changeState: createAsyncThunk(types.CHANGE_STATE, async ({ payload, meta }) => {
    const api = API_URLS.INVENTORY_RECORD_VOUCHER.changeStateInventoryRecordVoucher(
      payload.code,
      payload.payload,
    );

    const { response, error } = await apiCallPromise(api);
    if (meta) {
      if (!error && response.status === 200) {
        meta.onSuccess();
      } else {
        meta.onError();
      }
    }

    return response.data;
  }),
};

const inventoryRecordVoucherSlice = createSlice({
  name: 'inventoryRecordVoucherSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(thunkActions.getInventoryRecordVoucher.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(thunkActions.getInventoryRecordVoucher.fulfilled, (state, { payload }) => {
        const { data, page, page_size, total } = payload;
        state.data = data;
        state.didInvalidate = true;
        state.isFetching = false;
        state.meta.page = page;
        state.meta.pageSize = page_size;
        state.meta.total = total;
      })
      .addCase(thunkActions.getInventoryRecordVoucher.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(thunkActions.getInventoryRecordVoucherById.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(thunkActions.getInventoryRecordVoucherById.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.currentInventoryRecordVoucher = payload;
      })
      .addCase(thunkActions.getInventoryRecordVoucherById.rejected, (state) => {
        state.isFetching = false;
      })
      .addMatcher(
        isAnyOf(
          thunkActions.addInventoryRecordVoucher.fulfilled,
          thunkActions.updateInventoryRecordVoucher.fulfilled,
          thunkActions.changeState.fulfilled,
        ),
        (state) => {
          state.isFetching = false;
          state.didInvalidate = true;
        },
      )
      .addMatcher(
        isAnyOf(
          thunkActions.addInventoryRecordVoucher.pending,
          thunkActions.updateInventoryRecordVoucher.pending,
          thunkActions.changeState.pending,
        ),
        (state) => {
          state.isFetching = true;
        },
      )
      .addMatcher(
        isAnyOf(
          thunkActions.addInventoryRecordVoucher.rejected,
          thunkActions.updateInventoryRecordVoucher.rejected,
          thunkActions.changeState.rejected,
        ),
        (state) => {
          state.isFetching = false;
          state.didInvalidate = true;
        },
      );
  },
});

export const actions = { ...inventoryRecordVoucherSlice.actions, ...thunkActions };
export const { reducer } = inventoryRecordVoucherSlice;
