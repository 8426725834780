import { Card, Col, DatePicker, Modal, Row, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { connect } from 'react-redux';

import { columnChartOptions } from './columnChartOptions';
import DriverSearch from '../../../components/FormItems/DriverSearch';
import { actions as CompareActions } from '../../../redux/DashboardRedux';
import { actions as driverActions } from '../../../redux/DriverRedux';

const { getCompareEstimateAndReal } = CompareActions;
const { Text } = Typography;

const column = [
  {
    title: <b>Lái xe</b>,
    dataIndex: 'driver_name',
  },
  {
    title: <b>Tuyến đường</b>,
    dataIndex: 'route_code',
  },
  {
    title: <b>Quãng đường dự kiến</b>,
    dataIndex: 'estimate_distance',
    render: (value) => <div>{value ? `${(value / 1000).toFixed(1)} km` : '0'}</div>,
  },
  {
    title: <b>Quãng đường thực tế</b>,
    dataIndex: 'real_distance',
    render: (value, record) => {
      const ratioTime = record?.estimate_distance
        ? (((value || 0) * 100) / record.estimate_distance).toFixed(1)
        : 100;
      return <div>{value ? `${(value / 1000).toFixed(1)} km - ${ratioTime}%` : ''}</div>;
    },
  },
  {
    title: <b>Thời gian dự kiến</b>,
    dataIndex: 'estimate_time',
    render: (value) => {
      const totalMinutes = value ? Math.floor(value / 60) : 0;
      const seconds = value ? (value % 60).toFixed(1) : 0;
      const hours = value ? Math.floor(totalMinutes / 60) : 0;
      const minutes = value ? totalMinutes % 60 : 0;
      return <div>{value ? `${hours}h ${minutes}m ${seconds}s` : '-'}</div>;
    },
  },
  {
    title: <b>Thời gian thực tế</b>,
    dataIndex: 'real_time',
    render: (value, record) => {
      const totalMinutes = value ? Math.floor(value / 60) : 0;
      const seconds = value ? value % 60 : 0;
      const hours = value ? Math.floor(totalMinutes / 60) : 0;
      const minutes = value ? totalMinutes % 60 : 0;
      const ratioTime = record?.estimate_time
        ? ((value * 100) / record.estimate_time).toFixed(1)
        : 100;
      return <div>{value ? `${hours}h ${minutes}m ${seconds}s - ${ratioTime}%` : '-'}</div>;
    },
  },
];

const CompareEstimateAndReal = ({ getCompareEstimateAndReal, getAllDrivers, data }) => {
  const [driverCode, setDriverCode] = useState();
  const [openModal, setopenModal] = useState(false);
  const [timeCompare, settimeCompare] = useState();

  useEffect(() => {
    getAllDrivers();
  }, []);

  useEffect(() => {
    getCompareEstimateAndReal({
      page: -1,
      page_size: -1,
      driver_codes: driverCode,
    });
  }, [driverCode]);

  const handleChangeDriverCode = (value) => {
    setDriverCode(value);
  };

  const timeEstimate = [];
  const timeReal = [];
  const distanceEstimate = [];
  const distanceReal = [];
  const routeCodes = [];
  const detailComparedData = [];

  if (data?.length && data[0]?.routes?.length) {
    data[0].routes.forEach((element) => {
      timeEstimate.push(element.estimate_time);
      timeReal.push(element.real_time);
      distanceEstimate.push(element.estimate_distance);
      distanceReal.push(element.real_distance);
      routeCodes.push(element.route_code);
      detailComparedData.push({ ...element, driver_name: data[0].driver_name });
    });
  }

  const handleOpenModal = () => {
    setopenModal(!openModal);
  };

  const handleChangeTimeCompare = (value) => {
    settimeCompare(value);
  };

  // const detailTimeData = [];
  // data[0]?.routes[0]?.estimate_nodes?.forEach((estimateElemet) => {
  //   const k = data[0]?.routes[0]?.real_nodes?.find(
  //     (realElement) => realElement.order_code === estimateElemet.order_code,
  //   );
  //   detailTimeData.push({
  //     order_code: estimateElemet.order_code,
  //     estimateDepartueTime: estimateElemet.departure_time,
  //     estimateArrivedTime: estimateElemet.arrived_time,
  //     realDepartueTime: k.departure_time,
  //     realArrivedTime: k.arrived_time,
  //     driverName: k.driver_name,
  //   });
  // });

  return (
    <>
      <Card
        title={<Text>So sánh thời gian và quãng đường giữa dự kiến và thực tế</Text>}
        bordered
        // loading={isFetching}
      >
        <Row gutter={15} style={{ marginBottom: '20px' }}>
          <Col span={12}>
            <div>Chọn lái xe</div>
            <DriverSearch onChange={handleChangeDriverCode} selectedValue={driverCode} allowClear />
          </Col>
          <Col span={12}>
            <div>Chọn thời gian</div>
            <DatePicker
              style={{ width: '100%' }}
              onChange={handleChangeTimeCompare}
              placeholder="Chọn ngày"
            />
          </Col>
        </Row>
        <Chart
          options={{
            ...columnChartOptions.options,
            stacked: false,
            xaxis: {
              ...columnChartOptions.xaxis,
              categories: routeCodes,
              title: { text: 'Tuyến đường' },
            },
            dataLabels: {
              formatter: (val) => `${(val / 1000).toFixed(1)} km`,
            },
            yaxis: {
              labels: {
                formatter: (val) => `${Math.round(val / 1000)} km`,
              },
            },
            tooltip: {
              y: {
                formatter: (val) => `${(val / 1000).toFixed(1)} km`,
              },
            },
            legend: {
              position: 'top',
            },
            title: { text: 'Quãng đường' },
          }}
          series={
            [
              {
                name: 'Quãng đường dự kiến',
                data: distanceEstimate,
              },
              {
                name: 'Quãng đường thực',
                data: distanceReal,
              },
            ]
            // columnChartOptions.series
          }
          type="bar"
          height="400px"
          width="90%"
          onClick={() => {
            handleOpenModal();
          }}
        />
        <Chart
          options={{
            ...columnChartOptions.options,
            stacked: false,
            xaxis: {
              ...columnChartOptions.xaxis,
              categories: routeCodes,
            },
            dataLabels: {
              formatter: (val) => `${(val / 3600).toFixed(1)} giờ`,
            },
            yaxis: {
              labels: {
                formatter: (val) => `${Math.round(val / 3600)} giờ`,
              },
            },
            tooltip: {
              y: {
                formatter(val) {
                  const totalMinutes = Math.floor(val / 60);
                  const seconds = val % 60;
                  const hours = Math.floor(totalMinutes / 60);
                  const minutes = totalMinutes % 60;
                  return `${hours}h ${minutes}m ${seconds}s`;
                },
              },
            },
            legend: {
              position: 'top',
            },
            title: { text: 'Thời gian' },
          }}
          series={
            [
              {
                name: 'Thời gian dự kiến',
                data: timeEstimate,
              },
              {
                name: 'Thời gian thực',
                data: timeReal,
              },
            ]
            // columnChartOptions.series
          }
          type="bar"
          height="400px"
          width="90%"
          onClick={() => {
            handleOpenModal();
          }}
        />
      </Card>
      <Modal
        title="Bảng so sánh giữa dự kiến và thực tế"
        open={openModal}
        onOk={handleOpenModal}
        onCancel={handleOpenModal}
        footer={<></>}
        width={1000}>
        <>
          <Row>
            <Col span={6}>
              {timeCompare ? (
                <div>
                  <b>Thời gian: </b>
                  {timeCompare.format('DD/MM/YYYY')}
                </div>
              ) : null}
            </Col>
          </Row>
          <Table dataSource={detailComparedData} columns={column} />
        </>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state?.dashboardReducer?.Compare?.sale?.data || [],
});
const mapDispatchToProps = (dispatch) => ({
  getCompareEstimateAndReal: (params) => dispatch(getCompareEstimateAndReal(params)),
  getAllDrivers: () => dispatch(driverActions.getAllDrivers()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompareEstimateAndReal);
