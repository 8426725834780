import produce from 'immer';
import moment from 'moment';

import { API_URLS } from '../config/api';
import { apiCall } from '../utils/api';
import { isCallingApi, isSuccessfulApiCall, isFailedApiCall } from './actionDedicate';
import { PREFIX, typesWithPrefix } from './config';
const _types = typesWithPrefix(PREFIX.VEHICLE_EXPIRY);
const TYPE = {
  INVALIDATE_VEHICLE_EXPIRYS: _types('INVALIDATE_VEHICLE_EXPIRYS'),
  GET_VEHICLE_EXPIRYS: _types('GET_VEHICLE_EXPIRYS'),
  GET_ALL_VEHICLE_EXPIRYS: _types('GET_ALL_VEHICLE_EXPIRYS'),
  UPDATE_VEHICLE_EXPIRY: _types('UPDATE_VEHICLE_EXPIRY'),
  DELETE_VEHICLE_EXPIRY: _types('DELETE_VEHICLE_EXPIRY'),
  GET_VEHICLE_EXPIRY_BY_ID: _types('GET_VEHICLE_EXPIRY_BY_ID'),
  GET_VEHICLE_EXPIRYS_CHANGE_PAGE: _types('GET_VEHICLE_EXPIRYS_CHANGE_PAGE'),
};
export const actions = {
  //get list vehicle expiry
  getVehicleExpiryIfNeed: (filterOption) => (dispatch, getState) => {
    const state = getState();
    const isFetching = state.vehicleExpiryReducer.isFetching;
    const didInvalidate = state.vehicleExpiryReducer.didInvalidate;
    const query = state.vehicleExpiryReducer.query;
    const isQueryChanged = query.search !== filterOption.search;
    if ((!isFetching && didInvalidate) || (!isFetching && isQueryChanged)) {
      dispatch(actions.getVehicleExpiry(filterOption));
    }
  },

  getVehicleExpiry: (query) => async (dispatch) => {
    const params = {
      page: query.page,
      page_size: query.page_size,
      search: query.search,
    };
    const api = API_URLS.VEHICLE_EXPIRY.getVehicleExpirys(params);
    dispatch(actions.gettingVehicleExpiry());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(
        actions.getVehicleExpirySuccess({
          items: response.data.data,
          meta: {
            page: response.data.page,
            page_size: response.data.page_size,
            total: response.data.total,
          },
          query: {
            search: params.search,
          },
        }),
      );
    } else {
      dispatch(actions.getVehicleExpiryFailure(params));
    }
  },
  gettingVehicleExpiry: () => ({
    type: TYPE.GET_VEHICLE_EXPIRYS,
    meta: { prefix: [PREFIX.VEHICLE_EXPIRY, PREFIX.API_CALLING] },
  }),

  getVehicleExpirySuccess: (payload) => ({
    type: TYPE.GET_VEHICLE_EXPIRYS,
    payload,
    meta: {
      prefix: [PREFIX.VEHICLE_EXPIRY, PREFIX.API_CALLED_SUCCESS],
    },
  }),

  getVehicleExpiryFailure: (query) => ({
    type: TYPE.GET_VEHICLE_EXPIRYS,
    query,
    meta: {
      prefix: [PREFIX.VEHICLE_EXPIRY, PREFIX.API_CALLED_FAILURE],
    },
  }),

  invalidateVehicleExpiry: () => ({
    type: TYPE.INVALIDATE_VEHICLE_EXPIRYS,
    meta: {
      prefix: [PREFIX.VEHICLE_EXPIRY, PREFIX.API_CALLED_SUCCESS],
    },
  }),

  // get vehicle expiry by id
  getVehicleExpiryById: (vehicleExpiryId, meta) => async (dispatch) => {
    dispatch(actions.gettingVehicleExpiryDetail());
    const api = API_URLS.VEHICLE_EXPIRY.getVehicleExpiryById(vehicleExpiryId);
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(actions.getVehicleExpiryDetailSuccess(response.data.data));
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
    } else {
      dispatch(actions.getVehicleExpiryDetailFailure());
      if (meta && meta.onError) {
        meta.onError(error);
      }
    }
  },
  gettingVehicleExpiryDetail: () => ({
    type: TYPE.GET_VEHICLE_EXPIRY_BY_ID,
    meta: { prefix: [PREFIX.VEHICLE_EXPIRY, PREFIX.API_CALLING] },
  }),
  getVehicleExpiryDetailSuccess: (payload) => ({
    type: TYPE.GET_VEHICLE_EXPIRY_BY_ID,
    meta: { prefix: [PREFIX.VEHICLE_EXPIRY, PREFIX.API_CALLED_SUCCESS] },
    payload,
  }),
  getVehicleExpiryDetailFailure: () => ({
    type: TYPE.GET_VEHICLE_EXPIRY_BY_ID,
    meta: { prefix: [PREFIX.VEHICLE_EXPIRY, PREFIX.API_CALLED_FAILURE] },
  }),

  //GET ALL VEHICLE EXPIRYS
  getAllVehicleExpiry: () => async (dispatch) => {
    const params = {
      page: -1,
      page_size: -1,
    };
    const api = API_URLS.VEHICLE_EXPIRY.getVehicleExpirys(params);
    dispatch(actions.gettingAllVehicleExpiry());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(
        actions.getAllVehicleExpirySuccess({
          items: response.data.data,
        }),
      );
    } else {
      dispatch(actions.getAllVehicleExpiryFailure(params));
    }
  },
  gettingAllVehicleExpiry: () => ({
    type: TYPE.GET_ALL_VEHICLE_EXPIRYS,
    meta: { prefix: [PREFIX.VEHICLE_EXPIRY, PREFIX.API_CALLING] },
  }),

  getAllVehicleExpirySuccess: (payload) => ({
    type: TYPE.GET_ALL_VEHICLE_EXPIRYS,
    payload,
    meta: {
      prefix: [PREFIX.VEHICLE_EXPIRY, PREFIX.API_CALLED_SUCCESS],
    },
  }),

  getAllVehicleExpiryFailure: (query) => ({
    type: TYPE.GET_ALL_VEHICLE_EXPIRYS,
    query,
    meta: {
      prefix: [PREFIX.VEHICLE_EXPIRY, PREFIX.API_CALLED_FAILURE],
    },
  }),

  // update vehicle expiry
  updatingVehicleExpiry: () => ({
    type: TYPE.UPDATE_VEHICLE_EXPIRY,
    meta: { prefix: [PREFIX.VEHICLE_EXPIRY, PREFIX.API_CALLING] },
  }),
  updateVehicleExpirySuccess: () => ({
    type: TYPE.UPDATE_VEHICLE_EXPIRY,
    meta: {
      prefix: [PREFIX.VEHICLE_EXPIRY, PREFIX.API_CALLED_SUCCESS],
    },
  }),
  updateVehicleExpiryFailure: () => ({
    type: TYPE.UPDATE_VEHICLE_EXPIRY,
    meta: {
      prefix: [PREFIX.VEHICLE_EXPIRY, PREFIX.API_CALLED_FAILURE],
    },
  }),
  updateVehicleExpiry: (vehicleExpiryId, payload, meta) => async (dispatch) => {
    const api = API_URLS.VEHICLE_EXPIRY.updateVehicleExpiry(vehicleExpiryId, payload);
    dispatch(actions.updatingVehicleExpiry());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(actions.updateVehicleExpirySuccess(response.data));
      dispatch(actions.getAllVehicleExpiry());
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
    } else {
      dispatch(actions.updateVehicleExpiryFailure(error));
      if (meta && meta.onError) {
        meta.onError(error);
      }
    }
  },

  // Delete vehicle expiry
  deletingVehicleExpiry: () => ({
    type: TYPE.DELETE_VEHICLE_EXPIRY,
    meta: { prefix: [PREFIX.VEHICLE_EXPIRY, PREFIX.API_CALLING] },
  }),

  deleteVehicleExpirySuccess: () => ({
    type: TYPE.DELETE_VEHICLE_EXPIRY,
    meta: {
      prefix: [PREFIX.VEHICLE_EXPIRY, PREFIX.API_CALLED_SUCCESS],
    },
  }),

  deleteVehicleExpiryFailure: () => ({
    type: TYPE.DELETE_VEHICLE_EXPIRY,
    meta: {
      prefix: [PREFIX.VEHICLE_EXPIRY, PREFIX.API_CALLED_FAILURE],
    },
  }),

  deleteVehicleExpiry: (vehicleExpiryId, meta) => async (dispatch) => {
    const api = API_URLS.VEHICLE_EXPIRY.deleteVehicleExpiry(vehicleExpiryId);
    dispatch(actions.deletingVehicleExpiry());
    const { response, error } = await apiCall({ ...api });
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(actions.deleteVehicleExpirySuccess(response.data));
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
    } else {
      dispatch(actions.deleteVehicleExpiryFailure(error));
      if (meta && meta.onError) {
        meta.onError(error);
      }
    }
  },

  // insert vehicle expiry
  insertVehicleExpiry: (payload, meta) => async (dispatch) => {
    const api = API_URLS.VEHICLE_EXPIRY.insertVehicleExpiry(payload);
    const { response, error } = await apiCall(api);
    if (!error && (response.status === 200 || response.status === 201)) {
      if (meta && meta.onSuccess) {
        meta.onSuccess();
        dispatch(actions.getAllVehicleExpiry());
      }
    } else if (meta && meta.onError) {
      meta.onError(error);
    }
    return { response, error };
  },

  insertVehicleExpiryMaintanin: (vehicleExpiryId, payload, meta) => async (dispatch) => {
    const api = API_URLS.VEHICLE_EXPIRY.insertVehicleExpiryMaintain(vehicleExpiryId, payload);
    const { response, error } = await apiCall(api);
    if (!error && (response.status === 200 || response.status === 201)) {
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
    } else if (meta && meta.onError) {
      meta.onError(error);
    }
    return { response, error };
  },

  changePage: (page, page_size, total) => ({
    type: TYPE.GET_VEHICLE_EXPIRYS_CHANGE_PAGE,
    payload: {
      page,
      page_size,
      total,
    },
    meta: {
      prefix: [PREFIX.VEHICLE_EXPIRY],
    },
  }),
};

const initialState = {
  isFetching: false,
  didInvalidate: true,
  lastUpdated: moment().unix(),
  currentVehicleExpiry: {},
  allItems: [],
  items: [],
  query: {},
  meta: {
    total: 0,
    page: 1,
    page_size: 10,
  },
};

export const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case TYPE.GET_VEHICLE_EXPIRYS:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          const { items, meta, query } = action.payload;
          draft.isFetching = false;
          draft.didInvalidate = false;
          draft.lastUpdated = moment.unix();
          draft.items = items;
          draft.meta = meta;
          draft.query = query;
        } else if (isFailedApiCall(action)) {
          draft.isFetching = false;
          draft.didInvalidate = false;
        }
        break;
      case TYPE.GET_VEHICLE_EXPIRY_BY_ID:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          draft.currentVehicleExpiry = action.payload;
          draft.isFetching = false;
        } else if (isFailedApiCall(action)) {
          draft.currentVehicleExpiry = {};
          draft.isFetching = false;
          draft.didInvalidate = false;
        }
        break;
      case TYPE.INVALIDATE_VEHICLE_EXPIRYS:
        draft.didInvalidate = true;
        break;
      case TYPE.GET_VEHICLE_EXPIRYS_CHANGE_PAGE:
        draft.meta = {
          page: action.payload.page,
          page_size: action.payload.page_size,
          total: action.payload.total,
        };
        break;
      case TYPE.GET_ALL_VEHICLE_EXPIRYS:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          const { items } = action.payload;
          draft.isFetching = false;
          draft.didInvalidate = false;
          draft.allItems = items;
        } else if (isFailedApiCall(action)) {
          draft.isFetching = false;
          draft.didInvalidate = false;
        }
        break;
      case TYPE.UPDATE_VEHICLE_EXPIRY:
      case TYPE.DELETE_VEHICLE_EXPIRY:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        }
        if (isSuccessfulApiCall(action)) {
          draft.isFetching = false;
          draft.didInvalidate = true;
          draft.lastUpdated = moment.unix();
        }
        if (isFailedApiCall(action)) {
          draft.isFetching = false;
          draft.didInvalidate = false;
        }
        break;
      default:
        return draft;
    }
  });
