import { CheckOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';

import CategorySearch from '../../../components/FormItems/CategorySearch';
import FeatureSearch from '../../../components/FormItems/FeatureSearch';
// import UnitSearch from '../../../components/FormItems/UnitSearch';
import ImageUploadFormItem from '../../../components/ImageUploadFormItem';

const FormItem = Form.Item;
const { Item } = Form;

const FormProduct = ({
  openModal,
  openModalForm,
  closeModalForm,
  isEdit,
  handleSubmit,
  handleChangeCategoryCodeForm,
  curProduct,
  // handleChangeUnit,
  // curUnit,
}) => (
  <Modal
    title={isEdit ? 'Sửa sản phẩm' : 'Thêm sản phẩm'}
    visible={openModal}
    onOK={openModalForm}
    onCancel={closeModalForm}
    width={1200}
    footer={<></>}
    destroyOnClose>
    <Form
      layout="vertical"
      onFinish={handleSubmit}
      initialValues={{
        code: curProduct?.code ? curProduct?.code : '',
        name: curProduct?.name ? curProduct?.name : '',
        description: curProduct?.description ? curProduct?.description : '',
        feature: curProduct?.feature ? curProduct?.feature : '',
        secondary_code: curProduct?.secondary_code ? curProduct?.secondary_code : '',
        category_code: curProduct?.category_code ? curProduct?.category_code : '',
        img_url: curProduct?.img_url ? curProduct?.img_url : '',
        secondary_unit: curProduct?.unit?.secondary_unit || '',
        // factor: curProduct?.unit?.factor ? curProduct?.unit?.factor : '',
        // primary_unit: curUnit?.code || curProduct?.unit?.primary_unit || '',
      }}>
      <Row gutter={15}>
        <Col span={12}>
          <Row gutter={15}>
            <Col span={24}>
              <FormItem label="Mã sản phẩm" name="code">
                <Input placeholder="Để trống để sinh tự động" disabled={isEdit} />
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem
                label="Tên sản phẩm"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Nhập tên sản phẩm!',
                  },
                ]}>
                <Input placeholder="Nhập tên sản phẩm" />
              </FormItem>
            </Col>
            {/* <Col span={24}>
              <FormItem label="Mã phụ sản phẩm" name="secondary_code">
                <Input placeholder="Nhập mã phụ của sản phẩm" />
              </FormItem>
            </Col> */}
            <Col span={24}>
              <FormItem
                label="Nhóm hàng"
                name="category_code"
                rules={[
                  {
                    required: true,
                    message: 'Nhập tên nhóm hàng!',
                  },
                ]}>
                <CategorySearch
                  allowClear
                  onChange={handleChangeCategoryCodeForm}
                  selectedValue={curProduct?.category_code}
                />
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem label="Đơn vị" name="secondary_unit">
                <Input />
              </FormItem>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Form.Item name="img_url" label="Ảnh sản phẩm" labelAlign="center">
            <ImageUploadFormItem
              width="240px"
              height="240px"
              outputWidth={480}
              outputHeight={480}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <FormItem label="Đặc tính sản phẩm" name="feature">
            {/* <Input placeholder="Đặc tính sản phẩm..." /> */}
            <FeatureSearch mode="multiple" allowClear selectedValue={curProduct?.feature} />
          </FormItem>
        </Col>
        {/* <Col span={24}>
          <Row gutter={15}>
            <Col span={8} lg={6}>
              <FormItem label="Quy Cách" name="secondary_unit">
                <Input />
              </FormItem>
            </Col>
            <Col
              span={8}
              lg={2}
              style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
              <h2 style={{ position: 'absolute', top: '50%' }}>=</h2>
            </Col>
            <Col span={8} lg={6}>
              <FormItem label="Hệ số chuyển đổi" name="factor">
                <InputNumber min={0} step={0.01} style={{ width: '100%' }} />
              </FormItem>
            </Col>
            <Col
              span={8}
              lg={2}
              style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
              <h2 style={{ position: 'absolute', top: '50%' }}>×</h2>
            </Col>
            <Col span={24} lg={8}>
              <FormItem
                label="Đơn vị"
                name="primary_unit"
                valuePropName="selectedValue"
                rules={[
                  {
                    required: true,
                    message: 'Hãy nhập đơn vị chuẩn!',
                  },
                ]}>
                <UnitSearch onChange={handleChangeUnit} allowClear={false} />
              </FormItem>
            </Col>
          </Row>
        </Col> */}
        <Col span={24}>
          <FormItem label="Mô tả" name="description">
            <TextArea rows={4} placeholder="Mô tả..." />
          </FormItem>
        </Col>
        <Col span={24}>
          <Item style={{ float: 'right', marginTop: '10px' }} center>
            <Button type="primary" htmlType="submit" icon={<CheckOutlined />} shape="round">
              Lưu
            </Button>
            &nbsp;
          </Item>
        </Col>
      </Row>
    </Form>
  </Modal>
);
export default FormProduct;
