import { Col, DatePicker, Input, Row, Slider } from 'antd';
import moment from 'moment';
import React from 'react';

import CategorySearch from '../../../components/FormItems/CategorySearch';
import ProductSearch from '../../../components/FormItems/ProductSearch';

const { RangePicker } = DatePicker;
// const { Search } = Input;

const Filter = ({
  changeCategoryCodes,
  changeproductCodes,
  changeStorageCodes,
  handleChangeSearch,
  handleChangeQuantity,
  handleChangeExpiration,
}) => (
  <Row gutter={16} style={{ marginBottom: '15px' }}>
    {/* <Col style={{ marginBottom: '15px' }} span={12} lg={6}>
      <div style={{ marginBottom: '10px' }}>Kho</div>
      <Search
        onSearch={handleChangeSearch}
        style={{ width: '100%' }}
        placeholder="Tìm kiếm theo tên hoặc mã"
        enterButton
        allowClear
      />
      <Col />
    </Col> */}
    <Col style={{ marginBottom: '15px' }} span={12} lg={8}>
      <div style={{ marginBottom: '10px' }}>Nhóm hàng</div>
      <CategorySearch
        onChange={changeCategoryCodes}
        allowClear
        mode="multiple"
        placeholder="Nhóm hàng"
      />
    </Col>
    <Col span={12} lg={8}>
      <div style={{ marginBottom: '10px' }}>Sản phẩm</div>
      <ProductSearch
        onChange={changeproductCodes}
        allowClear
        mode="multiple"
        placeholder="Sản phẩm"
      />
    </Col>
    <Col style={{ marginBottom: '15px' }} span={12} lg={8}>
      <div style={{ marginBottom: '10px' }}>Ngày hết hạn</div>
      <RangePicker
        style={{ width: '100%' }}
        format="DD/MM/YYYY"
        onChange={handleChangeExpiration}
        placeholder={['Từ ngày', 'Đến ngày']}
        ranges={{
          'Hôm nay': [moment(), moment()],
          'Hôm qua': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
          'Tuần này': [moment().startOf('week'), moment().endOf('week')],
          'Tuần trước': [
            moment().subtract(1, 'weeks').startOf('week'),
            moment().subtract(1, 'weeks').endOf('week'),
          ],
          'Tháng này': [moment().startOf('month'), moment().endOf('month')],
          'Tháng trước': [
            moment().subtract(1, 'months').startOf('month'),
            moment().subtract(1, 'months').endOf('month'),
          ],
        }}
      />
    </Col>

    <Col span={12} lg={8}>
      <span>Số lượng tồn kho</span>
      <Slider
        // max={100000}
        step={10}
        range
        defaultValue={[0, 0]}
        onAfterChange={handleChangeQuantity}
      />
    </Col>
  </Row>
);

export default Filter;
