import { PlusOutlined } from '@ant-design/icons';
import { Button, Divider } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import ProductFromModal from './EditForm/Modal';
import Filter from './components/Filter';
import ListRecivingVoucher from './components/List';
import ROUTER from '../../config/router';
import { actions as receivingVoucherActions } from '../../redux/ReceivingVoucherRedux';

const ReceivingVoucher = ({
  getReceivingVoucher,
  history,
  receivingVoucherData,
  meta,
  isFetching,
  changeState,
}) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [openModal, setOpenModal] = useState(false);
  const [receivingCode, setReceivingCode] = useState();
  const [curReceivingVoucher, setcurReceivingVoucher] = useState({});
  const [state, setState] = useState();
  const [productCodes, setProductCodes] = useState();

  useEffect(() => {
    getReceivingVoucher({
      page,
      page_size: pageSize,
      state,
      product_codes: productCodes,
    });
  }, [page, pageSize, state, productCodes]);

  const handleAdd = () => {
    history.push(ROUTER.INVENTORY.ADD_RECEIVING_VOUCHER);
  };

  const handleChangeStatus = (value) => {
    setState(value);
  };

  const handleChangeProductCodes = (value) => {
    setProductCodes(value);
  };

  const onPageChange = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
  };

  const handleOpenModal = () => {
    setOpenModal(!openModal);
  };

  const getReceivingVoucherByCode = (receivingCode, payload) => {
    handleOpenModal();
    setReceivingCode(receivingCode);
    setcurReceivingVoucher(payload);
  };

  return (
    <>
      <div>
        <h2 style={{ float: 'left' }}>Danh sách phiếu nhập</h2>
        <div style={{ float: 'right' }}>
          <Button
            shape="round"
            type="primary"
            onClick={handleAdd}
            style={{ float: 'right' }}
            icon={<PlusOutlined />}>
            Thêm
          </Button>
        </div>
      </div>
      <Divider style={{ marginTop: 0 }} />
      <div style={{ marginBottom: '15px' }}>
        <Filter
          onChangeStatus={handleChangeStatus}
          onChangeProductCodes={handleChangeProductCodes}
        />
      </div>
      <ListRecivingVoucher
        data={receivingVoucherData}
        meta={meta}
        isFetching={isFetching}
        onPageChange={onPageChange}
        getReceivingVoucherByCode={getReceivingVoucherByCode}
        changeState={changeState}
        getReceivingVoucher={getReceivingVoucher}
      />

      <ProductFromModal
        item={curReceivingVoucher}
        receivingCode={receivingCode}
        toggleModalVoucher={handleOpenModal}
        getReceivingVoucher={getReceivingVoucher}
        visibleModal={openModal}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  receivingVoucherData: state.receivingVoucherReducer?.data,
  meta: state?.receivingVoucherReducer?.meta,
  isFetching: state?.receivingVoucherReducer?.isFetching,
});

const mapDispatchToProps = (dispatch) => ({
  getReceivingVoucher: (params) => dispatch(receivingVoucherActions.getReceivingVoucher(params)),
  changeState: (payload, meta) => dispatch(receivingVoucherActions.changeState({ payload, meta })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReceivingVoucher);
