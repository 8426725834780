import { Input, Row, Col } from 'antd';
import React, { Component } from 'react';

import WithLocale from '../../../hoc/WithLocale';

const { Search } = Input;

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataFilter: {
        search: '',
        accountType: '',
        company_code: '',
      },
    };
  }

  onSearch = (value) => {
    const { dataFilter } = this.state;
    dataFilter.search = value;
    this.setState({
      dataFilter,
    });
    this.props.onSearch(dataFilter);
  };

  render() {
    return (
      <Row gutter={16} style={{ marginBottom: '15px' }}>
        <Col span={6} lg={6}>
          <div style={{ marginBottom: '10px' }}>Tên hoặc mã:</div>
          <Search
            onSearch={this.onSearch}
            style={{ width: 400 }}
            // defaultValue={defaultValues.name}
            placeholder="Gõ để tìm kiếm"
            enterButton
            allowClear
          />
        </Col>
      </Row>
    );
  }
}

export default WithLocale(Filter);
