/* eslint-disable prefer-const */
/* eslint-disable no-unused-expressions */
import { DeleteOutlined } from '@ant-design/icons';
import { Input, Select, Button, Table } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

// import { voucherActions } from '../../../redux-toolkit/voucherSlice';

// import { isBelowBreakpoint } from '../../../utils/window';
// import { changeAlias } from '../../../../util/formatText';
// import { isBelowBreakpoint } from '../../../../util/windows';
// const { emptyReceivingVoucher } = voucherActions;

// const formatter = new Intl.NumberFormat('vi-VN', {
//   style: 'currency',
//   currency: 'VND',
//   minimumFractionDigits: 0,
// });
const { Option } = Select;

const columns = (
  onChangeQuantity,
  checkQuantity,
  onRemoveItem,
  onAddItem,
  wareHouseProducts,
  focusRowIndex,
  quantity_number,
  onChangeQuantityNumber,
) => [
  {
    title: () => (
      <Select
        placeholder="Chọn mặt hàng"
        showSearch
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onSelect={(values) => {
          onAddItem(values);
        }}
        showAction={['click', 'focus']}>
        {wareHouseProducts !== null &&
          wareHouseProducts?.length !== undefined &&
          wareHouseProducts?.map((item) => (
            <Option
              key={item.product_code}
              value={item.product_code}
              // disabled={listProducts?.map((product) => {
              //   product?.product_code === item.product_code ? 'true' : 'false';
              // })}
            >
              {item.product_name}
            </Option>
          ))}
      </Select>
    ),
    width: '30%',
    key: 'product_name',
    dataIndex: 'product_name',
    // render: (value, record) => {
    //   const productCheck = products.find((val) => val.code === record.product_code);
    //   return (
    //     <div>
    //       {productCheck.secondary_code
    //         ? `${productCheck.secondary_code} - ${productCheck.name}`
    //         : productCheck.name}
    //     </div>
    //   );
    // },
  },
  {
    title: <b>Quy cách</b>,
    key: 'product_code',
    dataIndex: 'product_code',
    width: '10%',
    render: (value, record) => <div>{record?.unit?.secondary_unit}</div>,
  },
  {
    title: <b>Tồn kho</b>,
    dataIndex: 'total_ordered',
    key: 'total_ordered',
    width: '120px',
    render: (value, record) =>
      record.total_quantity - (record.total_shipping || 0) - (record.total_shipped || 0),
  },
  {
    title: <b>Xoá</b>,
    width: '10%',
    align: 'center',
    render: (value, record, index) => (
      <Button
        shape="circle"
        type="danger"
        onClick={() => onRemoveItem(record.code, index)}
        icon={<DeleteOutlined />}
      />
    ),
  },
];

const childrenColumns = (
  focusRowIndex,
  quantity_number,
  onChangeQuantity,
  onChangeQuantityNumber,
) => [
  {
    title: <b>Số lô</b>,
    key: 'lot_number',
    dataIndex: 'lot_number',
  },

  {
    title: <b>Ngày sản xuất</b>,
    key: 'manufacturing_date',
    dataIndex: 'manufacturing_date',
    render: (value) => (value ? moment(value).format('DD-MM-YYYY') : 'N/A'),
  },
  {
    title: <b>Hạn sử dụng</b>,
    key: 'expiry_date',
    dataIndex: 'expiry_date',
    render: (value) => (value ? moment(value).format('DD-MM-YYYY') : 'N/A'),
  },
  {
    title: <b>Số lượng kiểm kê</b>,
    // key: 'summary_lot_numbers',
    render: (value, record, index) => (
      <Input
        defaultValue={null}
        autoFocus={index === focusRowIndex}
        type="number"
        min={0}
        max={1000}
        key="real_quantity"
        value={quantity_number}
        // addonAfter={<div style={{ width: '20px' }}>{record.unit?.primary}</div>}
        onChange={(e) => {
          onChangeQuantity(e.target.value, record.code, record.lot_number, record.product_code);
        }}
      />
    ),
  },
];

class ItemList extends Component {
  constructor(props) {
    super(props);
    this.textSelect = React.createRef();
    this.state = {
      listProducts: [],
      products: [],
      originProducts: [],
      stationCode: '',
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.wareHouseProducts !== prevState.originProducts) {
      const { wareHouseProducts } = nextProps;
      return {
        wareHouseProducts,
        originProducts: wareHouseProducts,
      };
    }
    if (nextProps.searchProduct) {
      const { productItems, wareHouseProducts, products } = nextProps;
      if (!prevState.listProducts.length && productItems && productItems.length) {
        const fullInfoProductItems = [];
        products.forEach((item) => {
          wareHouseProducts.find((itemProduct) => {
            if (item.product_code === itemProduct.product_code) {
              fullInfoProductItems.push({
                product_code: item.product_code,
                product_name: item.product_name,
                expiry_date: item.lot_details.expiry_date,
                lot_number: item.lot_details.lot_number,
                manufacturing_date: item.lot_details.manufacturing_date,
                real_quantity: this.props.quantity_number,
                total_ordered: item.total_ordered,
                unit: item.unit,
              });
            }
          });
        });
        // nextProps.emptyReceivingVoucher([], '');
        nextProps.sendData(fullInfoProductItems);
        return {
          listProducts: fullInfoProductItems,
        };
      }
    } else if (nextProps.storageCode !== prevState.storageCode) {
      return {
        listProducts: [],
      };
    }
    return null;
  }

  setRowSpan = (listProducts, productCode) => {
    const duplicatedProductsIndex = [];
    listProducts.forEach((item, index) => {
      if (item.code === productCode) {
        duplicatedProductsIndex.push(index);
      }
    });
    if (duplicatedProductsIndex.length === 1) {
      listProducts[duplicatedProductsIndex[0]].rowSpan = 1;
      listProducts[duplicatedProductsIndex[0]].plusIcon = true;
    } else if (duplicatedProductsIndex.length > 1) {
      duplicatedProductsIndex.forEach((pIndex) => {
        listProducts[pIndex].rowSpan = 0;
        listProducts[pIndex].plusIcon = false;
      });
      listProducts[duplicatedProductsIndex[0]].rowSpan = duplicatedProductsIndex.length;
      listProducts[duplicatedProductsIndex[duplicatedProductsIndex.length - 1]].plusIcon = true;

      return duplicatedProductsIndex[duplicatedProductsIndex.length - 1];
    }
    return 0;
  };

  onRemoveItem = (code, productIndex) => {
    const { listProducts } = this.state;
    const removeProducts = listProducts.filter(
      (item, index) => item.code !== code || index !== productIndex,
    );
    this.setRowSpan(removeProducts, code);
    this.setState({ listProducts: removeProducts }, () => this.combineData(removeProducts));
  };
  // onRemoveAllItem = () => {
  //   const { listProducts } = this.state;
  //   this.setState({ listProducts: [] }, () => this.combineData(listProducts));
  // };

  checkQuantity = ({ target: { value } }, id) => {
    if (Number(value) >= 0) this.onChangeQuantity(value, id);
    else this.onChangeQuantity(0, id);
  };

  onAddItem = (productCode) => {
    const { listProducts, originProducts } = this.state;
    let isExist = -1;
    if (listProducts.length) {
      const index = listProducts
        .slice() // to preserve the original array
        .reverse()
        .findIndex((item) => item.product_code === productCode);
      // isExist is the last index of item that has the productCode
      isExist = index >= 0 ? listProducts.length - 1 - index : index;
    }
    const product = originProducts?.find((item) => item.product_code === productCode);

    if (product) {
      let newListProducts = listProducts;
      let focusRowIndex = listProducts.length;
      const newProduct = { ...product, quantity: 1 };

      if (isExist < 0) {
        newListProducts = newListProducts.concat(newProduct);
        focusRowIndex = newListProducts.length - 1;
      }
      //  else {
      //   newListProducts.splice(isExist + 1, 0, newProduct);
      //   focusRowIndex = this.setRowSpan(newListProducts, productCode);
      // }
      this.setState({ listProducts: newListProducts, focusRowIndex }, () => this.combineData());
    }
  };

  handleAddAll = () => {
    const { wareHouseProducts } = this.props;
    const newListProducts = [];
    let focusRowIndex = 0;

    const product = wareHouseProducts;
    for (let i = 0; i < product?.length; i++) {
      const newProduct = { ...product[i], quantity: 1 };
      newListProducts.push(newProduct);
      focusRowIndex = i;
    }
    this.setState({ listProducts: newListProducts, focusRowIndex }, () => this.combineData());
  };

  onChangeQuantity = (value, code, lot_number, product_code) => {
    if (value < 0) return;
    const { listProducts } = this.state;

    const currentProduct = listProducts.find((product) => product.product_code === product_code);
    const currentLotNumber = {
      ...currentProduct?.summary_lot_numbers.find((item) => item.lot_number === lot_number),
      real_quantity: Number(value),
    };
    currentProduct.summary_lot_numbers = currentProduct.summary_lot_numbers.map((item) =>
      item.lot_number === currentLotNumber.lot_number ? currentLotNumber : item,
    );

    // const newProducts = listProducts.map((product) => {
    //   if (product.product_code === currentProduct.product_code) product = currentProduct;
    // });
    this.setState({ listProducts }, () => this.combineData());
  };

  combineData = () => this.props.sendData(this.state.listProducts);

  expandedRowRender = (ware) => {
    const dataIndex = [];
    const { quantity_number, onChangeQuantityNumber } = this.props;
    const { focusRowIndex } = this.state;
    const { summary_lot_numbers } = ware;

    summary_lot_numbers?.map((value, key) => {
      dataIndex.push({
        code: key,
        expiry_date: summary_lot_numbers[key].expiry_date,
        lot_number: summary_lot_numbers[key].lot_number,
        manufacturing_date: summary_lot_numbers[key].manufacturing_date,
        product_code: ware.product_code,
        storage_code: ware.storage_code,
        // real_quantity: summary_lot_numbers[key].real_quantity,
      });
    });

    return (
      <>
        <Table
          rowKey="code"
          columns={childrenColumns(
            focusRowIndex,
            quantity_number,
            this.onChangeQuantity,
            onChangeQuantityNumber,
          )}
          dataSource={dataIndex}
        />
      </>
    );
  };

  render() {
    const { wareHouseProducts, quantity_number, onChangeQuantityNumber } = this.props;
    const { listProducts, focusRowIndex } = this.state;

    return (
      <>
        <a onClick={this.handleAddAll}>Chọn tất cả</a>
        <Table
          key={`${listProducts?.length}`}
          size="small"
          scroll={{ x: 'max-content', y: 400 }}
          dataSource={listProducts}
          columns={columns(
            this.onChangeQuantity,
            this.checkQuantity,
            this.onRemoveItem,
            this.onAddItem,
            wareHouseProducts,
            focusRowIndex,
            quantity_number,
            onChangeQuantityNumber,
            listProducts,
          )}
          rowKey={(record, index) => `${record.code}-${index}`}
          rowClassName={(r, idx) => (idx % 2 ? 'whitesmoke' : '')}
          pagination={false}
          expandable={{ expandedRowRender: this.expandedRowRender }}
        />
      </>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  //   emptyReceivingVoucher: (productItem, stationSumary) =>
  //     dispatch(emptyReceivingVoucher(productItem, stationSumary)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ItemList));
