import { CloudUploadOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Tabs,
  Row,
  Col,
  Button,
  Divider,
  notification,
  DatePicker,
  message,
  InputNumber,
  Radio,
} from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import { connect } from 'react-redux';

import OrderImportModal from './components/OrderImportModal';
import DriverRoutingList from './components/RoutingTable/DriverList';
import RouteList from './components/RoutingTable/RouteList';
import Tomtom from './components/Tomtom';
import TomtomCVRP from './components/TomtomCVRP';
import UnscheduledOrders from './components/UnScheduledOrders';
import SampleRouteSearch from '../../components/FormItems/SampleRouteSearch';
import { API_URLS } from '../../config/api';
import ROUTER from '../../config/router';
import WithConfig from '../../hoc/WithConfig';
import WithLoading from '../../hoc/WithLoading';
import { actions as driverActions } from '../../redux/DriverRedux';
import { actions as orderActions } from '../../redux/OrderRedux';
import { actions as routingActions } from '../../redux/RoutingRedux';
import { actions as vehicleActions } from '../../redux/VehicleRedux';
import { apiCall } from '../../utils/api';
import DriverList from '../Driver/components/List';
import OrderList from '../Order/components/List';
import SampleRouteList from '../SampleRoute/components/List';

const { TabPane } = Tabs;

class Routing extends Component {
  constructor(props) {
    super(props);

    const defaultDeliveryDate = moment().add(1, 'days');

    this.state = {
      dataFilter: {
        page: '',
        page_size: '',
        delivery_date: defaultDeliveryDate.toISOString(),
      },
      selectedDriverRows: [],
      selectedDrivers: [],
      selectedOrderRows: [],
      selectedOrders: [],
      maxNodesPerRoute: 6,
      maxRouteDuration: 240,
      maxRunTime: 30,
      vehicle_capacity: 100,
      routingMethod: 'sample',
      selectedSampleRoute: '',
      currentSampleRoute: null,
      selectedStartTime: '',
      visibleOrderModal: false,
      activeTab: 'driver',
      cvrpRoutes: [],
      cvrpUnscheduledOrders: [],
      isCalculatingCVRP: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    let nextState = {};
    if (
      state.selectedSampleRoute &&
      state.selectedSampleRoute !== state.currentSampleRoute?.code &&
      props.allSampleRoutes.length
    ) {
      nextState = {
        currentSampleRoute: props.allSampleRoutes.find(
          (route) => route?.code === state.selectedSampleRoute,
        ),
      };
    }

    if (props.configValues?.time_window && !state.selectedStartTime) {
      const deliveryDate = state.dataFilter?.delivery_date
        ? moment(state.dataFilter.delivery_date)
        : moment().add(1, 'days');
      const deliveryTime = props.configValues.time_window?.from_time
        ? moment(props.configValues.time_window.from_time, 'HH:mm')
        : null;
      const defaultStarttime = deliveryTime
        ? deliveryDate
            .set('hour', deliveryTime.get('hour'))
            .set('minute', deliveryTime.get('minute'))
            .set('second', 0)
            .toISOString()
        : '';
      nextState.selectedStartTime = defaultStarttime;
    }

    return nextState;
  }

  componentDidMount() {
    const { userRole, history, getAllDrivers, getAllVehicles } = this.props;
    if (userRole === 'super-admin') history.push('/');
    else {
      const { delivery_date } = this.state.dataFilter;
      this.props.getAllRoutingOrders(delivery_date);
      getAllDrivers();
      getAllVehicles();
    }
  }

  onPageChange = (page, page_size) => {
    const { changeOrdersPage, invalidateOrders, meta } = this.props;
    changeOrdersPage(page, page_size, meta.total);
    invalidateOrders();
  };

  // Select drivers
  handleSelectChangeDriver = (selectedRowKey, record) => {
    this.setState({
      selectedDriverRows: record,
    });
  };

  onSelectDriverRow = (record, selected) => {
    const driverLatlng = {
      code: record.code,
      lat: record?.start_location?.latitude || 0,
      lng: record?.start_location?.longitude || 0,
    };
    const selectedDrivers = this.state.selectedDrivers;
    if (selected) {
      selectedDrivers.push(driverLatlng);
      this.setState({ selectedDrivers });
    } else {
      const selectedDriver = selectedDrivers.find((item) => item.code === driverLatlng.code);
      const index = selectedDrivers.indexOf(selectedDriver);
      selectedDrivers.splice(index, 1);
      this.setState({ selectedDrivers });
    }
  };

  onSelectAllDrivers = (selected, selectedRows, changeRows) => {
    const driversLatlng = selectedRows.map((item) => ({
      code: item.code,
      lat: item?.start_location?.latitude || 0,
      lng: item?.start_location?.longitude || 0,
    }));
    this.setState({ selectedDrivers: driversLatlng });
  };

  // Select orders
  handleSelectChangeOrder = (selectedRowKey, record) => {
    this.setState({
      selectedOrderRowKey: selectedRowKey,
      selectedOrderRows: record,
    });
  };

  onSelectOrderRow = (record) => {
    const orderLatlng = {
      code: record.code,
      lat: record?.customer?.location?.latitude || 0,
      lng: record?.customer?.location?.longitude || 0,
    };
    const selectedOrders = this.state.selectedOrders;
    const isSelected = !!this.state.selectedOrders.find((item) => item.code === orderLatlng.code);
    if (isSelected) {
      const selectedDriver = this.state.selectedOrders.find(
        (item) => item.code === orderLatlng.code,
      );
      const index = selectedOrders.indexOf(selectedDriver);
      selectedOrders.splice(index, 1);
      this.setState({ selectedOrders });
    } else {
      selectedOrders.push(orderLatlng);
      this.setState({ selectedOrders });
    }
  };

  // Select all orders
  onSelectAllOrders = (selected, selectedRows, changeRows) => {
    const ordersLatlng = selectedRows.map((item) => ({
      code: item.code,
      lat: item?.customer?.location?.latitude || 0,
      lng: item?.customer?.location?.longitude || 0,
    }));
    this.setState({ selectedOrders: ordersLatlng });
  };

  // Filter order by date
  filterOrder = (deliveryDate) => {
    if (deliveryDate) {
      const selectedDate = moment(deliveryDate);
      const { dataFilter, selectedStartTime } = this.state;
      const newSelectedStartTime = moment(deliveryDate)
        .set('hour', moment(selectedStartTime).get('hour'))
        .set('minute', moment(selectedStartTime).get('minute'))
        .set('second', 0)
        .toISOString();
      dataFilter.delivery_date = selectedDate.toISOString();
      this.setState(
        {
          dataFilter,
          selectedStartTime: newSelectedStartTime,
          selectedOrderRows: [],
          selectedOrders: [],
        },
        () => {
          this.props.getAllRoutingOrders(dataFilter.delivery_date);
        },
      );
    }
  };

  // CVRP
  startCVRP = async () => {
    const { configValues } = this.props;
    const {
      maxNodesPerRoute,
      maxRouteDuration,
      maxRunTime,
      vehicle_capacity,
      dataFilter,
      selectedDrivers,
      selectedOrderRows,
      selectedStartTime,
    } = this.state;
    if (!selectedDrivers.length) notification.warning({ message: 'Vui lòng chọn tài xế!' });
    else if (!selectedOrderRows.length)
      notification.warning({ message: 'Vui lòng chọn đơn giao hàng!' });
    else if (!dataFilter.delivery_date)
      notification.warning({ message: 'Vui lòng chọn ngày giao hàng!' });
    else if (!selectedStartTime) notification.warning({ message: 'Vui lòng chọn giờ xuất phát!' });
    else if (!maxNodesPerRoute || !maxRouteDuration || !maxRunTime || !vehicle_capacity)
      notification.warning({
        message: 'Vui lòng điền đủ điều kiện!',
      });
    else {
      this.setState({ isCalculatingCVRP: true });

      const deliveryStartTime = moment(dataFilter.delivery_date).startOf('day').toISOString();
      const deliveryEndTime = moment(dataFilter.delivery_date).endOf('day').toISOString();
      const selectedTime = moment(selectedStartTime);
      const startTime = moment(dataFilter.delivery_date)
        .hour(selectedTime.hour())
        .minute(selectedTime.minute())
        .second(0);
      const ordersPayload = selectedOrderRows.map((item) => ({
        code: item.code,
        delivery_at: {
          start_time: deliveryStartTime,
          end_time: deliveryEndTime,
        },
        location: item.location || item.customer?.location,
        demand: item.demand,
        occupied_space: item.occupied_space,
      }));
      const depotPayload = {
        location: {
          address: configValues.depot?.address,
          latitude: configValues.depot?.latitude || 0,
          longitude: configValues.depot?.longitude || 0,
        },
        nb_vehicles: selectedDrivers.length,
        vehicle_capacity,
      };
      const payload = {
        orders: ordersPayload,
        max_nodes_per_route: maxNodesPerRoute,
        max_route_duration: maxRouteDuration,
        max_runtime: maxRunTime,
        start_time: startTime.toISOString(),
        depot: depotPayload,
      };
      const api = API_URLS.ROUTING.getCVRPSolution(payload);
      const { response, error } = await apiCall(api);
      if (!error && response.status === 200) {
        notification.success({
          message: 'Tạo lịch thành công.',
        });
        const routes = response.data?.routes?.length
          ? response.data.routes
              .filter((route) => route?.nodes?.length)
              .map((route, index) => {
                const nodes = route.nodes.map((node) => {
                  const order = selectedOrderRows.find((item) => item.code === node.order_code);
                  return {
                    customer: order.customer,
                    shipping_code: order.shipping_code,
                    ...node,
                  };
                });
                return {
                  ...route,
                  driver_code: selectedDrivers[index]?.code || '',
                  nodes,
                  total_distance: route.distance,
                  total_time: route.duration,
                  weight_used: route.demand,
                };
              })
          : [];

        // console.log('cvrpRoutes', JSON.stringify(routes));
        this.setState({ isCalculatingCVRP: false, activeTab: 'result', cvrpRoutes: routes });
      } else {
        notification.error({
          message: `${'Tạo lịch thất bại!'}`,
        });
        this.setState({ isCalculatingCVRP: false });
      }
    }
  };

  // Call API create route from sample route
  insertRouteFromSampleRoute = () => {
    const {
      selectedDriverRows,
      selectedOrderRows,
      selectedSampleRoute,
      selectedStartTime,
      dataFilter,
    } = this.state;

    if (!selectedDriverRows.length) notification.warning({ message: 'Vui lòng chọn tài xế!' });
    else if (!selectedOrderRows.length)
      notification.warning({ message: 'Vui lòng chọn đơn giao hàng!' });
    else if (!dataFilter.delivery_date)
      notification.warning({ message: 'Vui lòng chọn ngày giao hàng!' });
    else if (!selectedSampleRoute) notification.warning({ message: 'Vui lòng chọn tuyến mẫu!' });
    else if (!selectedStartTime) notification.warning({ message: 'Vui lòng chọn giờ xuất phát!' });
    else {
      const payload = {
        drivers: selectedDriverRows,
        orders: selectedOrderRows,
        sample_route_code: selectedSampleRoute,
      };
      this.setState({ isCalculatingCVRP: true });
      this.props.insertRouteFromSampleRoute(payload, {
        onSuccess: (data) => {
          notification.success({
            message: 'Tạo lịch từ lịch mẫu thành công.',
          });
          if (data.length) {
            message.loading('Đang tính toán lịch giao hàng...', 3);
            this.calculateAndUpdateSampleRouting(data, selectedDriverRows.length);
          } else {
            this.setState({ isCalculatingCVRP: false });
          }
        },
        onError: () => {
          notification.error({
            message: `${'Tạo lịch từ lịch mẫu thất bại!'}`,
          });
          this.setState({ isCalculatingCVRP: false });
        },
      });
    }
  };

  generateRoute = () => {
    if (this.state.routingMethod === 'sample') {
      this.insertRouteFromSampleRoute();
    } else {
      this.startCVRP();
    }
  };

  // Select drivers
  handleSelectChangeDriverCVRP = (index, oldDriverCode, newDriverCode) => {
    const { cvrpRoutes } = this.state;
    cvrpRoutes[index].driver_code = newDriverCode;
    this.setState({
      cvrpRoutes,
    });
  };

  // Call API calc after created route from sample route successfully
  handleCalc = (data, numberOfDrivers, meta) => {
    const payload = {
      routes: data.map((route) => ({
        depot: {
          location: route?.start_location || {
            address: '',
            latitude: '',
            longitude: '',
          },
          nb_vehicles: numberOfDrivers || 0,
          vehicle_capacity: 0,
        },
        driver_code: route?.driver_code,
        nodes:
          (route?.nodes &&
            route?.nodes.map((node) => ({
              load: 0,
              location: node?.customer?.location || {
                address: '',
                latitude: '',
                longitude: '',
              },
            }))) ||
          [],
        start_time: this.state.selectedStartTime || route?.estimate_start_time || '',
      })),
    };

    this.props.calcSolution(payload, meta);
  };

  calculateAndUpdateSampleRouting = (data, numberOfDrivers) => {
    this.handleCalc(data, numberOfDrivers, {
      onSuccess: (dataCalc) => {
        // message.success({ message: 'Tính toán xong đường đi.' });
        if (dataCalc.length) this.updateRoutesFromSampleRouting(data, dataCalc);
        this.setState({ isCalculatingCVRP: false });
      },
      onError: () => {
        // message.error({ message: 'Lỗi, không thể tính toán đường đi!' });
      },
    });
  };

  // After called calc -> call api update route
  updateRoutesFromSampleRouting = (data, dataCalculated) => {
    const payloadUpdateRoute = {
      new_routes: data.map((route, i) => ({
        delivery_date: route.delivery_date,
        driver_code: route.driver_code,
        estimate_start_time: dataCalculated?.[i]?.start_time || null,
        finish_location: route.finish_location || {
          address: 'string',
          latitude: 0,
          longitude: 0,
        },
        nodes: route.nodes.map((node, nodeIndex) => ({
          comment: node.comment || '',
          completed_image: node.completed_image || '',
          customer: node.customer || {},
          distance: dataCalculated?.[i]?.nodes?.[nodeIndex]?.distance,
          estimated_arrival_time: dataCalculated?.[i]?.nodes?.[nodeIndex]?.estimated_arrival_time,
          estimated_departure_time:
            dataCalculated?.[i]?.nodes?.[nodeIndex]?.estimated_departure_time,
          order_code: node?.order_code,
          rate: node?.rate,
          real_arrived_time: node?.real_arrived_time || null,
          status: node?.status,
          status_updated: node?.status_updated || {},
        })),
        start_location: route.start_location || {
          address: 'string',
          latitude: 0,
          longitude: 0,
        },
        total_distance: dataCalculated?.[i]?.distance || 0,
        total_time: dataCalculated?.[i]?.duration || 0,
      })),
      old_route_codes: data.map((route) => route.code),
    };

    this.props.updateRoutes(payloadUpdateRoute, {
      onSuccess: () =>
        this.props.history.push(
          `${ROUTER.ROUTING.SCHEDULE}?delivery_date=${this.state.dataFilter?.delivery_date}`,
        ),
      onError: () => {},
    });
  };

  reCalculateAndUpdateRoutes = (changingRoutes, updateRouteIndices) => {
    if (!changingRoutes.length) {
      return;
    }

    // console.log('reCalculateAndUpdateRoutes', changingRoutes, updateRouteIndices);
    const { cvrpRoutes, vehicle_capacity } = this.state;
    this.setState({ isCalculatingCVRP: true });
    //* Payload tính lại khoảng cách, thời gian
    const payload = {
      routes: changingRoutes.map((route) => ({
        depot: {
          location: route?.depot?.location || {
            address: '',
            latitude: 0,
            longitude: 0,
          },
          nb_vehicles: 1,
          vehicle_capacity,
        },
        driver_code: route?.driver_code,
        nodes: route?.nodes?.length
          ? route?.nodes.map((node) => ({
              load: 0,
              location: node?.location || {
                address: '',
                latitude: 0,
                longitude: 0,
              },
            }))
          : [],
        start_time: route.start_time,
      })),
    };

    //* Tính lại khoảng cách, thời gian
    this.props.calcSolution(payload, {
      onSuccess: (dataCalc) => {
        this.setState({ isCalculatingCVRP: false });
        if (!dataCalc?.length) {
          notification.error({
            message: 'Có lỗi khi cập nhật tuyến đường, hãy thử lại!',
          });
          return;
        }
        notification.success({ message: 'Đã cập nhật tuyến đường.' });
        //* Update state tuyến đường (routes)
        cvrpRoutes[updateRouteIndices[0]] = {
          ...changingRoutes[0],
          total_distance: dataCalc[0]?.distance,
          total_time: dataCalc[0]?.duration,
          weight_used: dataCalc[0]?.demand,
          nodes: dataCalc[0]?.nodes?.map((n, i) => ({
            ...n,
            order_code: changingRoutes[0].nodes[i].order_code,
            shipping_code: changingRoutes[0].nodes[i].shipping_code,
          })),
        };
        if (updateRouteIndices[1] !== undefined) {
          const tIdx = dataCalc.length - 1;
          cvrpRoutes[updateRouteIndices[1]] = {
            ...changingRoutes[1],
            total_distance: dataCalc[tIdx]?.distance,
            total_time: dataCalc[tIdx]?.duration,
            weight_used: dataCalc[tIdx]?.demand,
            nodes: dataCalc[tIdx]?.nodes?.map((n, i) => ({
              ...n,
              order_code: changingRoutes[1].nodes[i].order_code,
              shipping_code: changingRoutes[1].nodes[i].shipping_code,
            })),
          };
        }
        this.setState({
          cvrpRoutes: [...cvrpRoutes],
        });
      },
      onError: () => {
        this.setState({ isCalculatingCVRP: false });
        notification.error({
          message: 'Có lỗi khi cập nhật tuyến đường, hãy thử lại!',
        });
      },
    });
  };

  handleChangeNodePosition = (node, source, target) => {
    // console.log('handleChangeNodePosition', node, source, target);
    const { cvrpRoutes, cvrpUnscheduledOrders } = this.state;

    const changingRoutes = [];
    const updateRouteIndices = [];
    const sourceRoute =
      source && (source?.routeIndex || source?.routeIndex === 0)
        ? cvrpRoutes[source.routeIndex]
        : null;
    const targetRoute =
      target && (target?.routeIndex || target?.routeIndex === 0)
        ? cvrpRoutes[target.routeIndex]
        : null;

    if (!targetRoute) {
      //* Trường hợp bỏ đơn hàng khỏi route
      //* Sửa lại mảng đơn hàng chưa được lập lịch
      cvrpUnscheduledOrders.unshift(node);
      sourceRoute.nodes.splice(source.nodeIndex, 1);
      changingRoutes.push(sourceRoute);
      updateRouteIndices.push(source.routeIndex);

      this.setState({ cvrpUnscheduledOrders });
    } else {
      //* Trường hợp thay đổi vị trí của node trong cùng 1 route
      if (source.routeIndex === target.routeIndex) {
        const newNodes = [];
        if (target.nodeIndex === -1) {
          newNodes.push(node);
        }
        targetRoute.nodes?.forEach((n, index) => {
          if (index !== source.nodeIndex) {
            if (index === target.nodeIndex && target.nodeIndex < source.routeIndex) {
              newNodes.push(node);
            }
            newNodes.push(n);
            if (index === target.nodeIndex && target.nodeIndex > source.routeIndex) {
              newNodes.push(node);
            }
          }
        });
        targetRoute.nodes = newNodes;
        changingRoutes.push(targetRoute);
        updateRouteIndices.push(target.routeIndex);
      } else {
        //* Trường hợp thay đổi node từ route này sang route khác
        //* Nếu vị trí node ở đầu route
        if (target.nodeIndex === -1) {
          targetRoute.nodes = [{ ...node, index: undefined }, ...targetRoute.nodes];
        } else {
          targetRoute.nodes.splice(target.nodeIndex + 1, 0, { ...node, index: undefined });
        }
        if (typeof source.routeIndex !== 'number') {
          //* Sửa lại mảng đơn hàng chưa được lập lịch
          const newCvrpUnscheduledOrders = cvrpUnscheduledOrders.toSpliced(node.index, 1);
          this.setState({ cvrpUnscheduledOrders: newCvrpUnscheduledOrders });
        } else {
          sourceRoute.nodes.splice(source.nodeIndex, 1);
          changingRoutes.push(sourceRoute);
          updateRouteIndices.push(source.routeIndex);
        }
        changingRoutes.push(targetRoute);
        updateRouteIndices.push(target.routeIndex);
      }
    }

    return this.reCalculateAndUpdateRoutes(changingRoutes, updateRouteIndices);
  };

  onRemoveNode = (source) =>
    this.handleChangeNodePosition(source.node, {
      nodeIndex: source.nodeIndex,
      routeIndex: source.routeIndex,
    });

  //* Đổi vị trí giữa 2 node với nhau
  onSwapNodes = (source, target) => {
    if (target.routeIndex !== -1 && source.routeIndex !== -1) {
      const { cvrpRoutes } = this.state;
      const changingRoutes = [];
      const updateRouteIndices = [];
      const sourceRoute = cvrpRoutes[source.routeIndex];
      const targetRoute = cvrpRoutes[target.routeIndex];

      //* Trường hợp thay đổi vị trí của node trong cùng 1 route
      if (source.routeIndex === target.routeIndex) {
        const newNodes = [];
        targetRoute.nodes?.forEach((n, index) => {
          if (index === source.nodeIndex - 1) {
            newNodes.push(target.node);
          } else if (index === target.nodeIndex - 1) {
            newNodes.push(source.node);
          } else {
            newNodes.push(n);
          }
        });
        targetRoute.nodes = newNodes;
        changingRoutes.push(targetRoute);
        updateRouteIndices.push(target.routeIndex);
      } //* Trường hợp thay đổi node từ route này sang route khác
      else {
        sourceRoute.nodes[source.nodeIndex] = target.node;
        changingRoutes.push(sourceRoute);
        updateRouteIndices.push(source.routeIndex);
        targetRoute.nodes[target.nodeIndex] = source.node;
        changingRoutes.push(targetRoute);
        updateRouteIndices.push(target.routeIndex);
      }

      return this.reCalculateAndUpdateRoutes(changingRoutes, updateRouteIndices);
    }
  };

  saveAutoRouting = () => {
    const { insertRoutes, history } = this.props;
    const { cvrpRoutes, dataFilter } = this.state;

    // const hasNoDriverRoute = cvrpRoutes.some((r) => !r.driver_code);
    // if (hasNoDriverRoute) {
    //   notification.warning({ message: 'Vui lòng chọn đủ tài xế!' });

    //   return;
    // }

    const routesPayload = cvrpRoutes.map((item) => ({
      delivery_date: item.start_time,
      driver_code: item.driver_code,
      estimate_start_time: item.start_time,
      start_location: item.depot.location,
      finish_location: item.depot.location,
      total_distance: item.total_distance,
      total_time: item.total_time,
      nodes: item.nodes.map(({ index, location, duration, ...keepAttrs }) => ({
        ...keepAttrs,
        // comment: '',
        // completed_image: '',
        // demand: 0,
        // occupied_space: 0,
        // rate: 0,
        shipping_code: keepAttrs.shipping_code || keepAttrs.customer?.code || keepAttrs.order_code,
        // real_arrived_time: keepAttrs.estimated_arrival_time,
        // real_departure_time: keepAttrs.estimated_departure_time,
        status: 'Scheduled',
      })),
    }));

    insertRoutes(
      { routes: routesPayload },
      {
        onSuccess: () => {
          notification.success({
            message: 'Lưu lịch thành công.',
          });
          history.push(`${ROUTER.ROUTING.SCHEDULE}?delivery_date=${dataFilter.delivery_date}`);
        },
        onError: () => {
          notification.error({
            message: 'Lưu lịch thất bại!',
          });
        },
      },
    );
  };

  render() {
    const { allDrivers, isFetchingDriver, allVehicles, isFetchingOrder, allOrders, configValues } =
      this.props;
    const {
      selectedDrivers,
      selectedOrders,
      dataFilter,
      currentSampleRoute,
      maxNodesPerRoute,
      maxRouteDuration,
      maxRunTime,
      vehicle_capacity,
      selectedStartTime,
      activeTab,
      routingMethod,
      cvrpRoutes,
      cvrpUnscheduledOrders,
      isCalculatingCVRP,
    } = this.state;

    return (
      <>
        <Row>
          <Col span={24}>
            <Row>
              <div style={{ float: 'left', width: '80%', display: 'flex' }}>
                <h2>Lập lịch giao vận</h2>
              </div>
            </Row>
          </Col>
        </Row>

        <Divider style={{ marginTop: 0 }} />

        <Row>
          <Col span={11} style={{ marginRight: 25 }}>
            <Tabs
              style={{ width: '100%' }}
              activeKey={activeTab}
              onChange={(newTab) => this.setState({ activeTab: newTab })}
              tabBarExtraContent={{
                right: (
                  <span>
                    <b>{selectedDrivers.length}</b> Lái xe - <b>{selectedOrders.length}</b> Đơn giao
                    hàng - Giao ngày <b>{moment(dataFilter.delivery_date).format('DD/MM/YYYY')}</b>
                  </span>
                ),
              }}
              type="card">
              <TabPane tab="Lái xe" key="driver">
                <DriverList
                  allVehicles={allVehicles}
                  isFetching={isFetchingDriver}
                  dataSource={allDrivers}
                  onSelectChange={this.handleSelectChangeDriver}
                  onSelectRow={this.onSelectDriverRow}
                  onSelectAllRow={this.onSelectAllDrivers}
                  showRowSelection
                  collapseTable
                  mode="routing"
                />
              </TabPane>

              <TabPane tab="Đơn giao hàng" key="order">
                <Row gutter={16}>
                  <Col span={12} sm={8} style={{ marginBottom: 5 }}>
                    <span>Ngày giao hàng:</span>
                    <DatePicker
                      style={{ width: 170 }}
                      onChange={this.filterOrder}
                      value={moment(dataFilter.delivery_date)}
                      format="DD/MM/YYYY"
                      allowClear={false}
                    />
                  </Col>
                  <Col span={8} sm={8} style={{ marginTop: 20, marginBottom: 5 }}>
                    <Button
                      style={{ float: 'right' }}
                      onClick={() => this.setState({ visibleOrderModal: true })}
                      icon={<PlusOutlined />}
                      type="primary"
                      shape="round">
                      Nhập từ file
                    </Button>
                  </Col>
                  <Col span={8} sm={8}>
                    <Button
                      shape="round"
                      type="primary"
                      style={{ marginTop: 20, marginBottom: 5 }}
                      onClick={() =>
                        notification.info({
                          message: 'Tính năng thêm đơn hàng lẻ đang phát triển!',
                        })
                      }
                      icon={<PlusOutlined />}>
                      Thêm đơn
                    </Button>
                  </Col>
                </Row>
                <OrderList
                  isFetching={isFetchingOrder}
                  dataSource={allOrders}
                  onSelectChange={this.handleSelectChangeOrder}
                  onSelectRow={this.onSelectOrderRow}
                  onSelectAllRow={this.onSelectAllOrders}
                  onChange={this.onPageChange}
                  onShowSizeChange={this.onPageChange}
                  showRowSelection
                  collapseTable
                  mode="routing"
                />
              </TabPane>

              <TabPane tab="Lập lịch" key="routing">
                <Row gutter={16}>
                  <Col
                    span={16}
                    style={{
                      marginBottom: 5,
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'flex-start',
                    }}>
                    <div style={{ marginRight: 25 }}>
                      <span>Lập lịch theo:</span>
                      <br />
                      <Radio.Group
                        value={routingMethod}
                        onChange={(e) => this.setState({ routingMethod: e.target.value })}
                        optionType="button"
                        buttonStyle="solid">
                        <Radio.Button value="sample">Tuyến mẫu</Radio.Button>
                        <Radio.Button value="auto">Tự động</Radio.Button>
                      </Radio.Group>
                    </div>
                    <div>
                      <span>Giờ xuất phát:</span>
                      <br />
                      <DatePicker.TimePicker
                        value={selectedStartTime ? moment(selectedStartTime) : null}
                        placeholder="Chọn giờ"
                        format="HH:mm"
                        allowClear={false}
                        onChange={(e) =>
                          this.setState({
                            selectedStartTime: moment(dataFilter.delivery_date)
                              .set('hour', moment(e).get('hour'))
                              .set('minute', moment(e).get('minute'))
                              .set('second', 0)
                              .toISOString(),
                          })
                        }
                      />
                    </div>
                  </Col>
                  <Col span={8}>
                    <Button
                      shape="round"
                      type="primary"
                      onClick={this.generateRoute}
                      style={{ marginTop: 15, float: 'right' }}
                      icon={<PlusOutlined />}
                      loading={isCalculatingCVRP}>
                      Tạo lịch
                    </Button>
                  </Col>
                </Row>
                <Divider />
                {this.state.routingMethod === 'sample' ? (
                  <>
                    <Row gutter={16}>
                      <Col span={12}>
                        <span>Chọn lịch mẫu:</span>
                        <SampleRouteSearch
                          onChange={(e) => this.setState({ selectedSampleRoute: e })}
                        />
                      </Col>
                    </Row>
                    <SampleRouteList
                      dataSource={currentSampleRoute ? [currentSampleRoute] : []}
                      mode="routing"
                    />
                  </>
                ) : (
                  <div>
                    <span style={{ justifyContent: 'center' }}>
                      Một lái xe giao tối đa{' '}
                      <InputNumber
                        // addonAfter="điểm"
                        value={maxNodesPerRoute}
                        style={{ width: 70, marginBottom: 10 }}
                        onChange={(value) => {
                          this.setState({ maxNodesPerRoute: value });
                        }}
                      />{' '}
                      điểm, di chuyển tối đa{' '}
                      <InputNumber
                        // addonAfter="phút"
                        value={maxRouteDuration}
                        style={{ width: 70, marginBottom: 10 }}
                        onChange={(value) => {
                          this.setState({ maxRouteDuration: value });
                        }}
                      />{' '}
                      phút.
                    </span>
                    <br />
                    <span>
                      Tải trọng mỗi xe:{' '}
                      <InputNumber
                        // addonAfter="kg"
                        value={vehicle_capacity}
                        style={{ width: 70, marginBottom: 10 }}
                        onChange={(value) => {
                          this.setState({ vehicle_capacity: value });
                        }}
                      />{' '}
                      kg.
                    </span>
                    <br />
                    <span>
                      Thời gian đợi chạy tối ưu:{' '}
                      <InputNumber
                        // addonAfter="giây"
                        value={maxRunTime}
                        style={{ width: 70, marginBottom: 10 }}
                        onChange={(value) => {
                          this.setState({ maxRunTime: value });
                        }}
                      />
                      {' giây'}.
                    </span>
                  </div>
                )}
              </TabPane>
              {this.state.routingMethod === 'auto' ? (
                <TabPane tab="Kết quả" key="result" disabled={!cvrpRoutes.length}>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Button
                        shape="round"
                        type="primary"
                        onClick={this.saveAutoRouting}
                        style={{ marginTop: 15, float: 'right' }}
                        icon={<CloudUploadOutlined />}
                        loading={isCalculatingCVRP}>
                        Lưu lịch
                      </Button>
                    </Col>
                  </Row>
                  <Divider />
                  <DndProvider backend={Backend}>
                    <UnscheduledOrders
                      orders={cvrpUnscheduledOrders}
                      onDropNode={this.onRemoveNode}
                    />
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ width: 230 }}>
                        <DriverRoutingList
                          allDrivers={allDrivers}
                          routes={cvrpRoutes}
                          onChangeDriver={this.handleSelectChangeDriverCVRP}
                          vehicle_capacity={this.state.vehicle_capacity}
                        />
                      </div>
                      <div style={{ marginLeft: 15, width: 'calc(100% - 230px)', height: '100%' }}>
                        <RouteList
                          routes={cvrpRoutes}
                          onChangeNodePosition={this.handleChangeNodePosition}
                          onSwapNodes={this.onSwapNodes}
                          vehicle_capacity={this.state.vehicle_capacity}
                        />
                      </div>
                    </div>
                  </DndProvider>
                </TabPane>
              ) : null}
            </Tabs>
          </Col>

          <Col span={12}>
            {routingMethod === 'auto' && cvrpRoutes?.length ? (
              <TomtomCVRP
                routes={cvrpRoutes}
                // onAddDriverMakers={selectedDrivers}
                // onAddOrderMakers={selectedOrders}
                // depot={configValues.depot}
              />
            ) : (
              <Tomtom
                onAddDriverMakers={selectedDrivers}
                onAddOrderMakers={selectedOrders}
                depot={configValues.depot}
              />
            )}
          </Col>
        </Row>
        <OrderImportModal
          isOpen={this.state.visibleOrderModal}
          onCancel={() => this.setState({ visibleOrderModal: false })}
          onSuccess={this.filterOrder}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userRole: state.authReducer.authUser.role_code,
  allDrivers: state.driverReducer.allItems?.length
    ? state.driverReducer.allItems.filter((driver) => driver.active)
    : [],
  isFetchingDriver: state.driverReducer.isFetching,
  allVehicles: state.vehicleReducer.allItems,
  allOrders: state.orderReducer.allRoutingOrders.items,
  isFetchingOrder: state.orderReducer.isFetching,
  configValues: state.configurationReducer.item,
  isFetching: state.routingReducer.isFetching,
  meta: state.orderReducer.meta,
  allSampleRoutes: state.sampleRouteReducer.allItems,
});

const mapDispatchToProps = (dispatch) => ({
  getAllDrivers: () => dispatch(driverActions.getAllDrivers()),
  getAllVehicles: () => dispatch(vehicleActions.getAllVehicles()),
  getAllRoutingOrders: (filterOption) => dispatch(orderActions.getAllRoutingOrders(filterOption)),
  getCVRPSolution: (payload, meta) => dispatch(routingActions.getCVRPSolution(payload, meta)),
  calcSolution: (payload, meta) => dispatch(routingActions.calcSolution(payload, meta)),
  updateRoutes: (payload, meta) => dispatch(routingActions.updateRoutes(payload, meta)),
  insertRoutes: (payload, meta) => dispatch(routingActions.insertRoutes(payload, meta)),
  insertRouteFromSampleRoute: (payload, meta) =>
    dispatch(routingActions.insertRouteFromSampleRoute(payload, meta)),
  changeOrdersPage: (page, page_size, total) =>
    dispatch(orderActions.changePage(page, page_size, total)),
  invalidateOrders: () => dispatch(orderActions.invalidateOrders()),
  insertManyOrder: (payload, meta) => dispatch(orderActions.insertManyOrder(payload, meta)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WithConfig('routing')(WithLoading(Routing)));
