import { Card, Col, Row, Typography } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { connect } from 'react-redux';

import { barChartOption } from './barChartOptions';
import Filter from './filter';
import { actions as dashboardActions } from '../../../redux/DashboardRedux';
import { ISODateParam } from '../../../utils/dateTime';

const { Text } = Typography;
const { getStatisticRealDistance } = dashboardActions;

const RealDistanceDashboard = ({ getStatisticRealDistance, isFetching, dataTotalDistance }) => {
  const [dateFrom, setDateFrom] = useState(moment().subtract(1, 'M'));
  const [dateTo, setDateTo] = useState(moment());
  const [driverCodes, setDriverCodes] = useState();

  useEffect(() => {
    const momentFrom = moment.isMoment(dateFrom) ? dateFrom : moment(dateFrom);
    const momentTo = moment.isMoment(dateTo) ? dateTo : moment(dateTo);
    getStatisticRealDistance({
      page: -1,
      page_size: -1,
      time_start: ISODateParam(momentFrom),
      time_end: ISODateParam(momentTo),
      driver_codes: driverCodes,
    });
  }, [dateFrom, dateTo, driverCodes]);

  const dataName = [];
  const dataDistance = [];

  dataTotalDistance?.forEach((element) => {
    dataName.push(element.driver_name);
    dataDistance.push(element.total_distance);
  });

  const handleSearchData = (value) => {
    setDateFrom(value.fromDate);
    setDateTo(value.toDate);
    setDriverCodes(value?.driverCode?.join());
  };

  return (
    <Card
      title={<Text>Thống kê quãng đường thực tế từng lái xe</Text>}
      bordered
      loading={isFetching}
      extra={<Filter onSearch={handleSearchData} />}>
      <Row gutter={16}>
        <Col span={24} lg={24}>
          {dataTotalDistance ? (
            <Chart
              options={{
                ...barChartOption,
                stacked: false,
                xaxis: {
                  ...barChartOption.xaxis,
                  // labels: { show: true },
                  categories: dataName,
                  labels: {
                    formatter: (val) => `${Math.round(val / 1000)} km`,
                  },
                  tooltip: {
                    y: {
                      formatter: (val) => `${Math.round(val / 1000)} km`,
                    },
                  },
                },
                // title: { text: this.props.title },
              }}
              series={[{ data: dataDistance, name: 'Quãng đường' }]}
              type="bar"
              height="auto"
              width="100%"
            />
          ) : (
            <div>Không có dữ liệu</div>
          )}
        </Col>
      </Row>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  isFetching: state.dashboardReducer?.realDistance?.sale?.isFetching,
  dataTotalDistance: state.dashboardReducer?.realDistance?.sale?.data,
});

const mapDispatchToProps = (dispatch) => ({
  getStatisticRealDistance: (query) => {
    dispatch(getStatisticRealDistance(query));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RealDistanceDashboard);
