/* eslint-disable prettier/prettier */
import produce from 'immer';

import { isSuccessfulApiCall } from './actionDedicate';
import { PREFIX, typesWithPrefix } from './config';

// import { isCallingApi, isSuccessfulApiCall, isFailedApiCall } from './actionDedicate';

const _types = typesWithPrefix(PREFIX.CONFIGURATION);

const TYPE = {
  GET_CONFIGURATION: _types('GET_CONFIGURATION'),
  DELETE_ALERT: 'DELETE_ALERT',
  // INSERT_CONFIGURATION: _types('INSERT_CONFIGURATION'),
  // SET_CONFIG: _types('SET_CONFIG'),
  // UPDATE_CONFIGURATION: _types('UPDATE_CONFIGURATION'),
};

export const actions = {
  addConfigurationAlert: () => ({
    type: TYPE.GET_CONFIGURATION,
  }),
  deleteAlert: () => ({
    type: TYPE.DELETE_ALERT,
  }),
};

const initialState = {
  alerts: [],
  action: {
    link: ``,
    text: '',
  },
};

export const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case TYPE.GET_CONFIGURATION:
        if (isSuccessfulApiCall(action)) {
          const { items } = action.payload;
          if (items.length === 0) {
            draft.alerts.push({
              message: 'Bạn chưa thiết lập cấu hình',
              type: 'warning',
            });
            draft.action = {
              link: `/configuration`,
              text: 'Thiết lập ngay',
            };
          }
        }
        break;
      case TYPE.DELETE_ALERT:
        draft.alerts.pop();
        draft.action.link = ``;
        draft.action.text = '';
        break;
      default:
        return draft;
    }
  });
