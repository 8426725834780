import { Tabs } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import WithConfig from '../../hoc/WithConfig';
import WithLoading from '../../hoc/WithLoading';
import ApiTab from './components/ApiTab';
import RoleTab from './components/RoleTab';

const { TabPane } = Tabs;

class Api extends Component {
  state = {};

  static getDerivedStateFromProps(props, state) {}

  componentDidMount() {
    if (this.props.userRole !== 'super-admin') {
      this.props.history.push('/');
    }
  }

  render() {
    return (
      <Tabs style={{ width: '100%' }}>
        <TabPane tab="Vai trò" key="role">
          <RoleTab />
        </TabPane>
        <TabPane tab="API" key="api">
          <ApiTab />
        </TabPane>
      </Tabs>
    );
  }
}

const mapStateToProps = (state) => ({
  userRole: state.authReducer.authUser.role_code,
});

export default connect(mapStateToProps, undefined)(WithConfig('api')(WithLoading(Api)));
