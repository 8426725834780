import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';

const LoadingIndicator = ({ style }) => (
  <Spin
    size="large"
    spinning
    tip="Đang tải ..."
    style={{ maxHeight: '100%', textAlign: 'center', ...style }}
    indicator={<LoadingOutlined />}
  />
);

export default LoadingIndicator;
