import { Button, Col, DatePicker, Divider, Modal, Row, notification } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import EditForm from './EditForm';
import { VOUCHER_STATUS } from '../../../constants/enum';
import { actions as receivingVoucherActions } from '../../../redux/ReceivingVoucherRedux';

const { updateReceivingVoucher } = receivingVoucherActions;

const ProductFromModal = ({
  visibleModal,
  toggleModalVoucher,
  item,
  updateReceivingVoucher,
  getReceivingVoucher,
}) => {
  const [note, setNote] = useState(item?.note);
  const [receivingTime, setReceivingTime] = useState(
    item?.receiving_time ? moment(item.receiving_time).toISOString() : '',
  );
  const [submitData, setSubmitData] = useState();

  useEffect(() => {
    setReceivingTime(item?.receiving_time ? moment(item.receiving_time).toISOString() : '');
    setNote(item?.note);
  }, [item]);

  const isCompleted = item?.state === 'completed' || item?.state === 'cancelled';

  const onChangeNote = (note) => {
    setNote(note.target.value);
  };
  const onChangeReceivingTime = (value) => {
    setReceivingTime(moment(value).toISOString());
  };

  const getData = (product) => {
    const curData = [];
    if (product) {
      product?.map((value, index) => {
        curData.push({
          category_code: value?.category_code,
          category_name: value?.category_name,
          expiry_date: value?.expiry_date,
          lot_image_url: '',
          lot_number: value?.lot_number,
          manufacturing_date: value?.manufacturing_date,
          product_code: value?.product_code,
          product_name: value?.product_name,
          product_secondary_code: value?.product_secondary_code,
          quantity: value?.quantity,
          input_price: value?.input_price,
          unit: {
            factor: value?.unit?.factor,
            primary_unit: value?.unit?.primary_unit,
            secondary_unit: value?.unit?.secondary_unit,
          },
        });
      });
    } else {
      item?.product_items?.map((value, index) => {
        curData.push({
          category_code: value?.category?.code,
          category_name: value?.category?.name,
          expiry_date: value?.expiry_date,
          lot_image_url: '',
          lot_number: value?.lot_number,
          manufacturing_date: value?.manufacturing_date,
          product_code: value?.code,
          product_name: value?.name,
          product_secondary_code: value?.secondary_code,
          quantity: value?.quantity,
          input_price: value?.input_price,
          unit: {
            factor: value?.unit?.factor,
            primary_unit: value?.unit?.primary_unit,
            secondary_unit: value?.unit?.secondary_unit,
          },
        });
      });
    }
    setSubmitData(curData);
  };

  const handleSubmit = () => {
    const payload = {
      note,
      product_items: submitData ? submitData : item.product_items,
      receiving_time: receivingTime,
      storage_code: item?.storage_code,
      storage_level: item?.storage_level,
      storage_name: item?.storage_name,
    };
    updateReceivingVoucher(
      {
        code: item.code,
        payload,
      },
      {
        onSuccess: () => {
          notification.success({ message: 'Sửa phiếu nhập thành công' });
          getReceivingVoucher({
            page: 1,
            page_size: 10,
          });
          getProducts({
            page: 1,
            page_size: 10,
          });
        },
        onError: (mes) => {
          notification.error({ message: `Sửa phiếu nhập thất bại. ` });
        },
      },
    );
    toggleModalVoucher();
  };

  return (
    <Modal
      title={
        <Row gutter={15}>
          <Col span={24}>
            <h3 style={{ float: 'left' }}>Thông tin phiếu nhập</h3>
          </Col>
        </Row>
      }
      visible={visibleModal}
      onCancel={toggleModalVoucher}
      destroyOnClose
      width={1200}
      footer={<></>}>
      <Row gutter={15} style={{ marginBottom: '10px' }}>
        <Col span={24} lg={12}>
          <Row>
            <Col span={12} lg={6}>
              <b>Mã: </b>
            </Col>
            <Col span={12} lg={18}>
              <>{item?.code}</>
            </Col>
          </Row>
        </Col>
        <Col span={24} lg={12}>
          <Row>
            <Col span={12} lg={6}>
              <b>Trạng thái: </b>
            </Col>
            <Col span={12} lg={18}>
              <>{VOUCHER_STATUS[item?.state]}</>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={15} style={{ marginBottom: '10px' }}>
        <Col span={24} lg={12}>
          <Row>
            <Col span={12} lg={6}>
              <b>Ngày tạo: </b>
            </Col>
            <Col span={12} lg={18}>
              <>{moment(item?.created_at).format('DD-MM-YYYY HH:mm:ss')}</>
            </Col>
          </Row>
        </Col>
        <Col span={24} lg={12}>
          <Row>
            <Col span={12} lg={6}>
              <b>Ngày nhập: </b>
            </Col>
            <Col span={12} lg={18}>
              {isCompleted ? (
                <p>
                  {item?.receiving_time
                    ? moment(item?.receiving_time).format('DD/MM/YYYY HH:mm')
                    : '-'}
                </p>
              ) : (
                <DatePicker
                  style={{ width: '100%' }}
                  onChange={onChangeReceivingTime}
                  placeholder="Chọn ngày nhập"
                  format="DD-MM-YYYY"
                  allowClear={false}
                  defaultValue={item?.receiving_time ? moment(item.receiving_time) : ''}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider />
      <EditForm
        onChangeNote={onChangeNote}
        onChangeReceivingTime={onChangeReceivingTime}
        receivingTime={item?.receiving_time}
        products={item?.product_items}
        sendData={getData}
        state={item?.state}
        note={item?.note || note}
      />
      <Row>
        <Col span={8} lg={6}>
          <b>Ghi chú: </b>
        </Col>
        <Col span={16} lg={18}>
          {isCompleted ? (
            <p>{note}</p>
          ) : (
            <TextArea rows={2} placeholder="Ghi chú" value={note} onChange={onChangeNote} />
          )}
        </Col>
      </Row>
      <Button
        type="primary"
        shape="round"
        style={{ width: '20%', marginLeft: '40%', marginTop: '15px' }}
        onClick={handleSubmit}
        disabled={isCompleted}>
        Lưu phiếu nhập
      </Button>
    </Modal>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  updateReceivingVoucher: (payload, meta) => dispatch(updateReceivingVoucher({ payload, meta })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductFromModal);
