import { CheckOutlined } from '@ant-design/icons';
import { Form, Input, Modal, Button, Row, Col, InputNumber } from 'antd';
import React from 'react';
import './PartForm.css';
// const layout = {
//   labelCol: { span: 24 },
// };
class VehiclePartForm extends React.Component {
  state = {
    expiryKm: false,
    expiryTime: false,
  };

  onValidate = (values) => {
    if (values?.expiry_km_to && values.expiry_km_from >= values.expiry_km_to) {
      this.setState({
        expiryKm: true,
      });
      return false;
    }
    if (values?.expiry_time_to && values.expiry_time_from >= values.expiry_time_to) {
      this.setState({
        expiryTime: true,
        check: true,
      });
      return false;
    } else {
      return true;
    }
  };

  onFinish = (values) => {
    if (this.onValidate(values)) {
      this.props.onCreateVehiclePart(values);
    }
  };

  onChange = () => {
    this.setState({
      expiryKm: false,
      expiryTime: false,
    });
  };

  onClose = () => {
    this.onChange();
    this.props.onCloseForm();
  };

  onUpdatePart = (values) => {
    if (this.onValidate(values)) {
      this.props.onEditPart(this.props.editPart.code, values);
    }
  };

  render() {
    const { visibleForm, editPart } = this.props;
    const formItemLayout = {
      labelCol: {
        span: 24,
      },
    };
    return (
      <Modal
        title={editPart && editPart.code ? 'Cập nhập thông tin linh kiện' : 'Thêm linh kiện'}
        visible={visibleForm}
        onOk={editPart && editPart.code ? this.onUpdatePart : this.onFinish}
        onCancel={this.onClose}
        destroyOnClose
        footer={<></>}>
        <Row>
          <Col span={22} offset={1}>
            <Form
              {...formItemLayout}
              onFinish={editPart && editPart.code ? this.onUpdatePart : this.onFinish}
              initialValues={{
                name: editPart && editPart.code ? editPart.payload.name : '',
                expiry_km_from: editPart && editPart.code ? editPart.payload.expiry_km_from : 0,
                expiry_km_to: editPart && editPart.code ? editPart.payload.expiry_km_to : 0,
                expiry_time_from: editPart && editPart.code ? editPart.payload.expiry_time_from : 0,
                expiry_time_to: editPart && editPart.code ? editPart.payload.expiry_time_to : 0,
                description: editPart && editPart.code ? editPart.payload.description : '',
              }}>
              <Form.Item
                name="name"
                label="Tên"
                rules={[{ required: true, message: 'Nhập tên linh kiện' }]}>
                <Input placeholder="Nhập tên linh kiện" />
              </Form.Item>

              <Row className="flex-container">
                <Col span={24} className="padding-div">
                  Hạn sử dụng theo quãng đường
                </Col>
                <Col span={6} style={{ height: 40 }}>
                  <Form.Item name="expiry_km_from">
                    <InputNumber
                      min={0}
                      onChange={this.onChange}
                      style={{ borderColor: this.state.expiryKm ? 'red' : '' }}
                    />
                  </Form.Item>
                </Col>
                <Col span={1}>-</Col>
                <Col span={6} style={{ height: 40 }}>
                  <Form.Item name="expiry_km_to">
                    <InputNumber
                      min={0}
                      onChange={this.onChange}
                      style={{ borderColor: this.state.expiryKm ? 'red' : '' }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <div>km</div>
                </Col>
                {this.state.expiryKm ? (
                  <Col span={24} id="warning-text">
                    Hạn sử dụng bắt đầu phải nhỏ hơn hạn sử dụng kết thúc !!!
                  </Col>
                ) : (
                  ''
                )}
              </Row>

              <Row className="flex-container">
                <Col span={24} className="padding-div">
                  Hạn sử dụng theo thời gian
                </Col>
                <Col span={6} style={{ height: 40 }}>
                  <Form.Item name="expiry_time_from">
                    <InputNumber
                      min={0}
                      onChange={this.onChange}
                      style={{ borderColor: this.state.expiryTime ? 'red' : '' }}
                    />
                  </Form.Item>
                </Col>
                <Col span={1}>-</Col>
                <Col span={6} style={{ height: 40 }}>
                  <Form.Item name="expiry_time_to">
                    <InputNumber
                      min={0}
                      onChange={this.onChange}
                      style={{ borderColor: this.state.expiryTime ? 'red' : '' }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <div>tháng</div>
                </Col>
                {this.state.expiryTime ? (
                  <Col span={24} id="warning-text">
                    Hạn sử dụng bắt đầu phải nhỏ hơn hạn sử dụng kết thúc !!!
                  </Col>
                ) : (
                  ''
                )}
              </Row>

              <Form.Item name="description" label="Ghi chú">
                <Input placeholder="Nhập ghi chú" />
              </Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                shape="round"
                style={{
                  width: '20%',
                  marginLeft: '180px',
                  marginTop: '10px',
                }}>
                <CheckOutlined />
                Lưu
              </Button>
            </Form>
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default VehiclePartForm;
