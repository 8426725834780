import { EditOutlined, DeleteOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Table, Popconfirm, Input, Space } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Highlighter from 'react-highlight-words';

import Filter from './Filter';
import WithLoadingHOC from '../../../../hoc/WithLoading/index';

const APITab = ({
  dataSource,
  meta: { page, page_size, total },
  onShowSizeChange,
  onChange,
  onDeleteApi,
  onSetAddApi,
  onSetEditApi,
  onChangeFilter,
}) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  let searchInput;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}>
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}>
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const columns = [
    // {
    //   title: <b>Mô tả</b>,
    //   dataIndex: 'description',
    // },
    {
      title: <b>Tên</b>,
      dataIndex: 'name',
      // ...getColumnSearchProps('name'),
      // render: (value, record) => (
      //   <Tooltip title={record.description}>
      //     <b>{record.name}</b>
      //   </Tooltip>
      // ),
    },
    {
      title: <b>Đường dẫn</b>,
      dataIndex: 'path',
      // ...getColumnSearchProps('path'),
    },
    {
      title: <b>Phương thức</b>,
      dataIndex: 'method',
      align: 'center',
    },
    {
      title: <b>Xóa</b>,
      dataIndex: 'delete',
      align: 'center',
      render: (value, record) => (
        // const dele = () => {
        //   onDeleteApi(record.id);
        // };
        // return <Button shape="circle" icon={<DeleteOutlined />} type="primary" onClick={dele} />;
        <Popconfirm
          placement="top"
          title={`Xóa API ${record.name}`}
          onConfirm={() => onDeleteApi(record.code)}
          okText="Có"
          cancelText="Không">
          <Button key="del" icon={<DeleteOutlined />} shape="circle" type="danger" />
        </Popconfirm>
      ),
    },
    {
      title: <b>Chỉnh sửa</b>,
      dataIndex: 'edit',
      align: 'center',
      render: (value, record) => {
        const edit = () => {
          onSetEditApi({
            code: record.code,
            path: record.path,
            name: record.name,
            method: record.method,
            description: record.description,
          });
        };

        return <Button shape="circle" icon={<EditOutlined />} type="primary" onClick={edit} />;
      },
    },
  ];

  return (
    <>
      <Button
        shape="round"
        type="primary"
        onClick={onSetAddApi}
        style={{ float: 'right', marginBottom: 20 }}
        icon={<PlusOutlined />}>
        Thêm API mới
      </Button>
      <Filter onSearch={onChangeFilter} />
      <Table
        columns={columns}
        dataSource={dataSource}
        rowClassName={(r, idx) => (idx % 2 ? 'whitesmoke' : '')}
        rowKey="code"
        size="small"
        scroll={{ x: 'max-content' }}
        pagination={{
          current: page,
          page_size,
          total,
          showSizeChanger: true,
          onShowSizeChange,
          onChange,
          page_sizeOptions: ['10', '20', '40'],
          showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total}`,
        }}
      />
    </>
  );
};

APITab.propTypes = {
  dataSource: PropTypes.array,
  meta: PropTypes.object,
  onDeleteApi: PropTypes.func,
  onSetAddApi: PropTypes.func,
  onChangeFilter: PropTypes.func,
  onSetEditApi: PropTypes.func,
};

APITab.defaultProps = {
  dataSource: [],
  meta: {},
  onDeleteApi: () => {},
  onSetAddApi: () => {},
  onSetEditApi: () => {},
  onChangeFilter: () => {},
};

export default WithLoadingHOC(APITab);
