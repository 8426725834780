import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import LoadingIndicator from '../../components/LoadingIndicator';
import ROUTER from '../../config/router';
import { actions as authActions } from '../../redux/AuthRedux';

export default function withAuthentication(needAuthenticated) {
  return function withAuthenticationWrappedComponent(WrappedComponent) {
    class Authentication extends Component {
      constructor(props) {
        super(props);
        this.state = {};
      }

      static getDerivedStateFromProps(nextProps) {
        nextProps.loginWithTokenIfNeed();
        return null;
      }

      render() {
        const { isAuthenticated, isFetching, error } = this.props;
        if (!needAuthenticated) {
          return <WrappedComponent {...this.props} />;
        }
        if (isAuthenticated) {
          return <WrappedComponent {...this.props} />;
        }
        if (!isFetching && !isAuthenticated && error) {
          return <Redirect to={ROUTER.AUTH.LOGIN} />;
        }
        if (isFetching || localStorage.getItem('jwt')) {
          return (
            <div
              style={{
                height: '100vh',
                width: '100%',
                display: 'grid',
                placeContent: 'center',
              }}>
              <LoadingIndicator />
            </div>
          );
        }
        return <Redirect to={ROUTER.AUTH.LOGIN} />;
      }
    }

    function mapStateToProps({ authReducer }) {
      return {
        isAuthenticated: authReducer.isAuthenticated,
        isFetching: authReducer.isFetching,
        error: authReducer.error,
      };
    }

    function mapDispatchToProps(dispatch) {
      return {
        loginWithTokenIfNeed() {
          dispatch(authActions.loginWithTokenIfNeed());
        },
      };
    }

    return connect(mapStateToProps, mapDispatchToProps)(Authentication);
  };
}
