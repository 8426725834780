import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ROUTER from '../../../config/router';
import SampleRoutePage from '../../SampleRoute';
import AddSampleRoute from '../../SampleRoute/add';
import EditSampleRoute from '../../SampleRoute/edit';

const SampleRoute = () => (
  <Switch>
    <Route exact path={ROUTER.SAMPLE_ROUTE.INDEX} component={SampleRoutePage} />
    <Route exact path={ROUTER.SAMPLE_ROUTE.ADD} component={AddSampleRoute} />
    <Route exact path={ROUTER.SAMPLE_ROUTE.EDIT} component={EditSampleRoute} />
  </Switch>
);
export default SampleRoute;
