import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  TagOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { Button, Popconfirm, Table, Tooltip, notification } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';

import { VOUCHER_STATUS } from '../../../constants/enum';

const column = (
  getInventoryVoucherByCode,
  handleEdit,
  handleAcceptVoucher,
  handleCancelVoucher,
) => [
  {
    title: <b>Mã phiếu</b>,
    dataIndex: 'code',
    render: (value, record) => {
      if (record.note) {
        return (
          <Tooltip title={record.note}>
            <span>
              {value} <TagOutlined />
            </span>
          </Tooltip>
        );
      }

      return value;
    },
  },
  {
    title: <b>Kho</b>,
    dataIndex: 'storage_name',
  },
  {
    title: <b>Ngày tạo</b>,
    dataIndex: 'created_at',
    render: (value) => moment(value).format('DD/MM/YYYY'),
  },

  {
    title: <b>Ngày kiểm kê</b>,
    dataIndex: 'record_time',
    render: (value) => (value ? moment(value).format('DD/MM/YYYY') : '-'),
  },
  {
    title: <b>Trạng thái</b>,
    dataIndex: 'state',
    render: (value) => VOUCHER_STATUS[value],
  },
  {
    title: <b>Thao tác</b>,
    align: 'center',
    render: (value, record) => (
      <>
        <Popconfirm
          disabled={[
            'accept',
            'rejected',
            'completed',
            'cancelled',
            'draft',
            'voucher_created',
          ].includes(record.state)}
          title="Bạn chắc chắn muốn hoàn thành phiếu này?"
          onConfirm={(e) => handleAcceptVoucher(e, record)}>
          <Tooltip title="Hoàn thành phiếu">
            <Button
              type="primary"
              shape="circle"
              disabled={[
                'accept',
                'completed',
                'rejected',
                'cancelled',
                'draft',
                'voucher_created',
              ].includes(record.state)}>
              <CheckCircleOutlined />
            </Button>
          </Tooltip>
        </Popconfirm>
        <Popconfirm
          disabled={['accept', 'rejected', 'completed', 'cancelled', 'voucher_created'].includes(
            record.state,
          )}
          title="Bạn chắc chắn muốn huỷ phiếu này?"
          onConfirm={(e) => handleCancelVoucher(e, record)}>
          <Tooltip title="Huỷ phiếu">
            <Button
              type="danger"
              shape="circle"
              style={{ marginLeft: 10 }}
              disabled={[
                'accept',
                'rejected',
                'completed',
                'cancelled',
                'voucher_created',
              ].includes(record.state)}>
              <CloseCircleOutlined />
            </Button>
          </Tooltip>
        </Popconfirm>

        <Button
          onClick={() => {
            getInventoryVoucherByCode(record.code, record);
            // handleOpenModal();
          }}
          style={{ marginLeft: 10 }}
          icon={<UnorderedListOutlined />}
          shape="circle"
          type="primary"
        />
      </>
    ),
  },
];

const ListInventoryRecordVoucher = ({
  data,
  isFetching,
  meta,
  onPageChange,
  getInventoryVoucherByCode,
  changeState,
  getInventoryRecordVoucher,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editingVoucher, setEditingVoucher] = useState({
    code: '',
    state: '',
  });

  const handleEdit = (e, record) => {
    e.preventDefault();

    setIsEditing(true);
    setEditingVoucher({
      code: record.code,
      state: record.state,
    });
  };

  const handleAcceptVoucher = (e, record) => {
    e.preventDefault();

    changeState(
      {
        code: record.code,
        payload: {
          state: 'completed',
        },
      },
      {
        onSuccess: () => {
          notification.success({ message: 'Hoàn thành phiếu thành công' });

          getInventoryRecordVoucher({
            page: 1,
            page_size: 10,
          });
        },
        onError: (mes) => {
          notification.error({ message: `Hoàn thành phiếu thất bại. ` });
        },
      },
    );
    setIsEditing(false);
    setEditingVoucher({
      code: '',
      state: '',
    });
  };

  const handleCancelVoucher = (e, record) => {
    e.preventDefault();

    changeState(
      {
        code: record.code,
        payload: {
          state: 'cancelled',
        },
      },
      {
        onSuccess: () => {
          notification.success({ message: 'Hủy phiếu thành công' });
          getInventoryRecordVoucher({
            page: 1,
            page_size: 10,
          });
        },
        onError: (mes) => {
          notification.error({ message: `Hủy phiếu thất bại. ` });
        },
      },
    );
    setIsEditing(false);
    setEditingVoucher({
      code: '',
      state: '',
    });
  };

  return (
    <>
      <Table
        size="small"
        columns={column(
          getInventoryVoucherByCode,
          handleEdit,
          handleAcceptVoucher,
          handleCancelVoucher,
        )}
        dataSource={data}
        loading={isFetching}
        rowKey={(record) => record?.code}
        pagination={{
          current: meta?.page,
          pageSize: meta?.pageSize || 10,
          total: meta?.total,
          onChange: onPageChange,
          showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total}`,
        }}
      />
    </>
  );
};

export default ListInventoryRecordVoucher;
