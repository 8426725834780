import React, { Component } from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import { compose } from 'redux';

import DriverItem from './item';
import ItemTypes from '../constants';

const dragSource = {
  beginDrag(props) {
    return {
      id: props.id,
      index: props.index,
    };
  },
};

const dropTarget = {
  hover(props, monitor, component) {
    if (!component) {
      return null;
    }
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;
    if (dragIndex === hoverIndex) {
      return;
    }
    props.moveItem(dragIndex, hoverIndex);
    monitor.getItem().index = props.index;
  },
};

function collectDrop(connect) {
  return { connectDropTarget: connect.dropTarget() };
}

function collectDrag(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

class DragableDriverItem extends Component {
  render() {
    const {
      index,
      vehicle_capacity,
      isDragging,
      connectDragSource,
      connectDropTarget,
      driver,
      route,
    } = this.props;
    const opacity = isDragging ? 0.5 : 1;

    return (
      connectDragSource &&
      connectDropTarget &&
      connectDragSource(
        connectDropTarget(
          <div style={{ opacity }}>
            <DriverItem
              vehicle_capacity={vehicle_capacity}
              onChangeDriver={this.props.onChangeDriver}
              onSelectStartTime={this.props.onSelectStartTime}
              driver={driver}
              route={route}
              index={index}
            />
          </div>,
        ),
      )
    );
  }
}

const dragDrop = compose(
  DropTarget(ItemTypes.DRIVER, dropTarget, collectDrop),
  DragSource(ItemTypes.DRIVER, dragSource, collectDrag),
);

export default dragDrop(DragableDriverItem);
