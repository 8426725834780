/* eslint-disable import/no-unresolved */
import { Table } from 'antd';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import { Link } from 'react-router-dom';

import ROUTER from '../../../config/router';
import '../../../assets/css/style.css';

const columns = () => [
  {
    title: <b>Tên xe</b>,
    dataIndex: 'label',
    key: 'label',
    render: (value, record) => (
      <>
        <Link
          to={ROUTER.VEHICLE.DETAIL_VEHICLE.replace(':vehicleInfoId', record.code)}
          key={`${record.code}-vehicle-name`}>
          {value}
        </Link>
      </>
    ),
  },
  {
    title: <b>Biển số xe</b>,
    dataIndex: 'license_plate_number',
    key: 'license_plate_number',
  },
  {
    title: <b>Loại xe</b>,
    dataIndex: 'vehicle_type',
    key: 'vehicle_type',
    render: (record) => <div>{record === 'truck' ? 'Xe tải' : 'Xe gắn máy'}</div>,
  },
  {
    title: <b>Tải Trọng (kg)</b>,
    dataIndex: 'vehicle_capacity',
    key: 'vehicle_capacity',
  },
  {
    title: <b>Thể tích (m³)</b>,
    dataIndex: 'vehicle_volume',
    key: 'vehicle_volume',
  },
  {
    title: <b>Mức tiêu thụ (lít/km)</b>,
    dataIndex: 'fuel_per_km',
    key: 'fuel_per_km',
  },
];

class VehicleList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page_size: 1,
    };
  }

  render() {
    const {
      dataSource,
      meta: { page, page_size, total },
      onPageChange,
    } = this.props;
    return (
      <>
        <Table
          size="small"
          dataSource={dataSource}
          columns={columns()}
          rowKey="id"
          pagination={{
            // page,
            current: page,
            page_size,
            total,
            onChange: onPageChange,
            showSizeChanger: true,
            showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total} phương tiện`,
          }}
          scroll={{ x: 'max-content' }}
        />
      </>
    );
  }
}

export default VehicleList;
