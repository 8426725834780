import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Row, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import FormStorage from './components/Form';
import StorageList from './components/List';
import { actions as storageActions } from '../../redux/StorageRedux';

const { getStorage, addStorage, getStorageById, updateStorage, changeState } = storageActions;

const ListWareHouses = ({
  getStorage,
  dataStorage,
  meta,
  addStorage,
  updateStorage,
  changeState,
}) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [status, setStatus] = useState();
  const [level, setLevel] = useState();
  const [companyCode, setCompanyCode] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [dataCurrentStorage, setDataCurrentStorage] = useState({});

  useEffect(() => {
    getStorage({
      page,
      page_size: pageSize,
      level,
      status,
      company_code: companyCode,
    });
  }, [page, pageSize, level, status, companyCode]);

  const onPageChange = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
  };

  const openModalForm = () => {
    setOpenModal(true);
  };
  const closeModalForm = () => {
    setOpenModal(false);
    setDataCurrentStorage({});
    setIsEdit(false);
  };

  const handleEditStorage = (value) => {
    const payload = {
      code: value.code,
      level: value.level,
      name: value.name,
      parent_code: value.parent_code,
    };
    updateStorage(
      {
        code: value.code,
        payload,
      },
      {
        onSuccess: () => {
          notification.success({ message: 'Sửa kho thành công' });
          getStorage({
            page,
            page_size: pageSize,
            level,
            status,
            company_code: companyCode,
          });
        },
        onError: (mes) => {
          notification.error({ message: `Sửa kho thất bại. ` });
        },
      },
    );
    closeModalForm();
  };

  const handleAddStorage = (value) => {
    addStorage(
      { code: value.code, name: value.name, parent_code: value.parent_code, level: value.level },

      {
        onSuccess: () => {
          notification.success({ message: 'Thêm kho thành công' });
          getStorage({
            page,
            page_size: pageSize,
            level,
            status,
            company_code: companyCode,
          });
        },
        onError: (mes) => {
          notification.error({ message: `Thêm kho thất bại. ` });
        },
      },
    );
    closeModalForm();
  };

  const editStorage = (code, payload) => {
    getStorageById(code);
    setIsEdit(true);
    setDataCurrentStorage(payload);
    openModalForm();
  };

  const defaultValues = {
    code: '',
    name: '',
    parent_code: '',
    level: 0,
  };

  return (
    <>
      <div>
        <h2 style={{ float: 'left' }}>Kho</h2>
        {dataStorage === null || dataStorage?.length < 3 ? (
          <div style={{ float: 'right' }}>
            <Button shape="round" type="primary" onClick={openModalForm} icon={<PlusOutlined />}>
              Thêm
            </Button>
          </div>
        ) : null}
      </div>
      <Divider />
      <StorageList
        dataStorage={dataStorage}
        onPageChange={onPageChange}
        meta={meta}
        editStorage={editStorage}
        chageStatusStorage={changeState}
      />
      <FormStorage
        openModalForm={openModalForm}
        closeModalForm={closeModalForm}
        openModal={openModal}
        onSubmit={isEdit ? handleEditStorage : handleAddStorage}
        dataCurStorage={isEdit ? dataCurrentStorage : defaultValues}
        isEdit={isEdit}
        dataStorage={dataStorage}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  dataStorage: state?.storageReducer?.Storage?.data || null,
  meta: state?.storageReducer?.Storage?.meta,
});

const mapDispatchToProps = (dispatch) => ({
  getStorage: (query) => dispatch(getStorage(query)),
  addStorage: (payload, meta) => dispatch(addStorage({ payload, meta })),
  getStorageById: (storageId) => dispatch(getStorageById(storageId)),
  updateStorage: (payload, meta) => dispatch(updateStorage({ payload, meta })),
  changeState: (payload, meta) => dispatch(changeState({ payload, meta })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListWareHouses);
