import { Col, Input, Row, Select } from 'antd';
import React, { Component } from 'react';

const { Search } = Input;
const { Option } = Select;

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataFilter: {
        search: '',
        status: undefined,
        active: '',
        page: 1,
      },
    };
  }

  onSearch = (field) => {
    const { dataFilter } = this.state;
    dataFilter.search = field;
    this.setState({ dataFilter });
    this.props.onSearch(dataFilter);
  };

  onChangeType = (value) => {
    const { dataFilter } = this.state;
    dataFilter.type = value;
    this.setState({ dataFilter });
    this.props.onSearch(dataFilter);
  };

  onChangeActiveStatus = (field) => {
    const { dataFilter } = this.state;
    dataFilter.active = field;
    this.setState({ dataFilter });
    this.props.onSearch(dataFilter);
  };

  render() {
    return (
      <Row gutter={15}>
        <Col span={8}>
          <div style={{ marginBottom: '10px' }}>Tìm kiếm</div>
          <Search
            onSearch={this.onSearch}
            style={{ width: '100%' }}
            placeholder="Tên hoặc mã khách hàng"
            enterButton
            allowClear
          />
        </Col>
        <Col span={8}>
          <div style={{ marginBottom: '10px' }}>Phân loại</div>
          <Select
            style={{ width: '100%' }}
            placeholder="Phân loại"
            onChange={this.onChangeType}
            allowClear>
            <Option value="retail">Khách lẻ</Option>
            <Option value="agency">Đại lý</Option>
          </Select>
        </Col>
        <Col span={8}>
          <div style={{ marginBottom: '10px' }}>Trạng thái</div>
          <Select
            style={{ width: '100%' }}
            placeholder="Trạng thái"
            onChange={this.onChangeActiveStatus}
            allowClear>
            <Option value="true">Đang hoạt động</Option>
            <Option value="false">Không hoạt động</Option>
          </Select>
        </Col>
        {/* <Col span={6}>
          <div style={{ marginBottom: '10px' }}>Trạng thái</div>
          <Select
            style={{ width: '100%' }}
            placeholder="Trạng thái"
            onChange={this.onChangeStatus}
            allowClear>
            <Option value="Ready">Sẵn sàng</Option>
            <Option value="Driving">Đang trên đường</Option>
            <Option value="Off">Nghỉ</Option>
          </Select>
        </Col>
        <Col span={6}>
          <div style={{ marginBottom: '10px' }}>Giới tính</div>
          <Select
            style={{ width: '100%' }}
            placeholder="Giới tính"
            onChange={this.onChangeGender}
            allowClear>
            <Option value="Male">Nam</Option>
            <Option value="Female">Nữ</Option>
          </Select>
        </Col> */}
      </Row>
    );
  }
}

export default Filter;
