import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

import { API_URLS } from '../config/api';
import { apiCall, apiCallPromise } from '../utils/api';
import { splitNameFromImageURL } from '../utils/image';
import { isCallingApi, isSuccessfulApiCall, isFailedApiCall } from './actionDedicate';
import { PREFIX, typesWithPrefix } from './config';

const { CATEGORIES } = PREFIX;
const _types = typesWithPrefix(CATEGORIES);
const types = {
  GET_CATEGORIES: _types('GET_CATEGORIES'),
  GET_CATEGORIES_BY_LEVEL: _types('GET_CATEGORIES_BY_LEVEL'),
  GET_ACTIVE_CATEGORIES_LEVEL_3: _types('GET_ACTIVE_CATEGORIES_LEVEL_3'),
  RELOAD_ALL_CATEGORIES: _types('RELOAD_ALL_CATEGORIES'),
  UPDATE_CATEGORY: _types('UPDATE_CATEGORY'),
  ADD_CATEGORY: _types('ADD_CATEGORY'),
  INVALIDATE_CATEGORIES: _types('INVALIDATE_CATEGORIES'),
  GET_ALL_CATEGORY: _types('GET_ALL_CATEGORY'),
};

export const categoriesActions = {
  invalidateCategories: () => (dispatch) =>
    dispatch(
      actions.invalidateCategories({
        meta: {
          prefix: [PREFIX.CATEGORIES],
        },
      }),
    ),
  getCategories: () => async (dispatch) => {
    const api = API_URLS.CATEGORIES.getCategories({ level: -1 });
    dispatch(
      actions.getCategories({
        meta: {
          prefix: [PREFIX.CATEGORIES, PREFIX.API_CALLING],
        },
      }),
    );
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      const payload = response.data.data || [];
      dispatch(
        actions.getCategories({
          payload,
          meta: {
            prefix: [PREFIX.CATEGORIES, PREFIX.API_CALLED_SUCCESS],
          },
        }),
      );
    } else {
      dispatch(
        actions.getCategories({
          meta: {
            prefix: [PREFIX.CATEGORIES, PREFIX.API_CALLED_FAILURE],
          },
        }),
      );
    }
  },
  getCategoriesIfNeed: () => (dispatch, getState) => {
    const state = getState();
    const isFetchingCategories = state.categoriesReducer.isFetchingCategories;
    const didInvalidate = state.categoriesReducer.didInvalidate;
    if (!isFetchingCategories && didInvalidate) {
      dispatch(categoriesActions.getCategories());
    }
  },

  getCategoriesByLevel: (level) => async (dispatch) => {
    const api = API_URLS.CATEGORIES.getCategoriesByLevel(level);
    dispatch(
      actions.getCategoriesByLevel({
        payload: level,
        meta: {
          prefix: [PREFIX.CATEGORIES, PREFIX.API_CALLING],
        },
      }),
    );
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data && response.data.data) {
      const payload = response.data.data;
      dispatch(
        actions.getCategoriesByLevel({
          payload,
          level,
          meta: {
            prefix: [PREFIX.CATEGORIES, PREFIX.API_CALLED_SUCCESS],
          },
        }),
      );
      if (level === 0) dispatch(categoriesActions.getCategoriesByLevel(1));
      else if (level === 1) dispatch(categoriesActions.getCategoriesByLevel(2));
      else if (level === 2) dispatch(categoriesActions.getCategoriesByLevel(3));
      else if (level === 3) dispatch(categoriesActions.getCategoriesByLevel(4));
    } else {
      dispatch(
        actions.getCategoriesByLevel({
          meta: {
            prefix: [PREFIX.CATEGORIES, PREFIX.API_CALLED_FAILURE],
          },
        }),
      );
    }
  },

  getCategoriesByLevelIfNeed: (level) => (dispatch, getState) => {
    const state = getState();
    const finished = state.categoriesReducer.list.finished;
    const levelLoadedFlag = state.categoriesReducer.list.levelLoadedFlag;
    const isFetching = state.categoriesReducer.list.isFetching;
    // const levelData = select(
    //   state,
    //   ['categoriesReducer', 'list', 'byLevel'],
    //   `${level}`,
    // );
    if (
      !finished &&
      !isFetching &&
      (!levelLoadedFlag || !levelLoadedFlag.get || !levelLoadedFlag.get(`${level}`))
    ) {
      dispatch(categoriesActions.getCategoriesByLevel(level));
    }
  },

  // const gettingActiveCategoriesLevel3 = () => ({
  //   type: types.GET_ACTIVE_CATEGORIES_LEVEL_3,
  //   meta: {
  //     prefix: [PREFIX.CATEGORIES, PREFIX.API_CALLING],
  //   },
  // });

  // const getActiveCategoriesLevel3Success = (payload, level) => ({
  //   type: types.GET_ACTIVE_CATEGORIES_LEVEL_3,
  //   payload,
  //   meta: {
  //     level,
  //     prefix: [PREFIX.CATEGORIES, PREFIX.API_CALLED_SUCCESS],
  //   },
  // });

  // const getActiveCategoriesLevel3Failure = () => ({
  //   type: types.GET_ACTIVE_CATEGORIES_LEVEL_3,
  //   meta: {
  //     prefix: [PREFIX.CATEGORIES, PREFIX.API_CALLED_FAILURE],
  //   },
  // });

  // export const getActiveCategoriesLevel3 = () => async (dispatch) => {
  //   const api = API_URLS.CATEGORIES.getCategoriesByLevel(3, true);
  //   dispatch(gettingActiveCategoriesLevel3());
  //   const { response, error } = await apiCall(api);
  //   if (!error && response.status === 200 && response.data && response.data.Data) {
  //     const payload = response.data.Data;
  //     dispatch(getActiveCategoriesLevel3Success(payload));
  //   } else {
  //     dispatch(getActiveCategoriesLevel3Failure());
  //   }
  // };

  // export const getActiveCategoriesLevel3IfNeed = () => (dispatch, getState) => {
  //   const state = getState();
  //   const isFetchingActiveCategoriesLevel3 =
  //     state.categoriesReducer.list.isFetchingActiveCategoriesLevel3;

  //   const didInvalidateActiveCategoriesLevel3 =
  //     state.categoriesReducer.list.didInvalidateActiveCategoriesLevel3;

  //   if (!isFetchingActiveCategoriesLevel3 && didInvalidateActiveCategoriesLevel3) {
  //     dispatch(getActiveCategoriesLevel3());
  //   }
  // };

  reloadAllCategories: () => async (dispatch) => {
    dispatch(
      actions.reloadAllCategories({
        meta: {
          prefix: [PREFIX.CATEGORIES, PREFIX.API_CALLING],
        },
      }),
    );
    dispatch(categoriesActions.getCategoriesByLevel(0));
  },

  updatingCategory: () => ({
    type: types.UPDATE_CATEGORY,
    meta: {
      prefix: [PREFIX.CATEGORIES, PREFIX.API_CALLING],
    },
  }),
  updateCategorySuccess: () => ({
    type: types.UPDATE_CATEGORY,
    meta: {
      prefix: [PREFIX.CATEGORIES, PREFIX.API_CALLED_SUCCESS],
    },
  }),
  updateCategory: (payload, code, meta) => async (dispatch) => {
    dispatch(categoriesActions.updatingCategory());
    const verifiedCategory = payload;
    // eslint-disable-next-line no-unused-expressions
    ['WebBanner', 'MobiBanner', 'MobiThumbnail', 'MobiIcon'].forEach((field) => {
      if (verifiedCategory[field]) {
        verifiedCategory[field] = splitNameFromImageURL(verifiedCategory[field]);
      }
    });
    verifiedCategory.active = verifiedCategory.active !== false;
    const api = API_URLS.CATEGORIES.updateCategory(verifiedCategory, code);
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      dispatch(categoriesActions.updateCategorySuccess());
      dispatch(categoriesActions.invalidateCategories());
      dispatch(categoriesActions.getCategoriesByLevel(payload.level));
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
    } else if (meta && meta.onError) {
      meta.onError(error);
    }
  },

  updateMultipleCategory: (payload, meta) => async (dispatch) => {
    dispatch(categoriesActions.updatingCategory());
    const allPromises = [];
    // payloadList.forEach((payload) => {
    const verifiedCategory = payload;
    // ['WebBanner', 'MobiBanner', 'MobiThumbnail', 'MobiIcon'].forEach((field) => {
    // if (verifiedCategory[field]) {
    //   verifiedCategory[field] = splitNameFromImageURL(verifiedCategory[field]);
    // }
    // });
    verifiedCategory.active = verifiedCategory.active !== false;
    const api = API_URLS.CATEGORIES.updateCategory(verifiedCategory, verifiedCategory.code);
    allPromises.push(apiCallPromise(api));
    // });

    Promise.all(allPromises)
      .then(() => {
        dispatch(categoriesActions.updateCategorySuccess());
        dispatch(categoriesActions.invalidateCategories());
        if (meta && meta.onSuccess) {
          meta.onSuccess();
        }
      })
      .catch(() => {
        dispatch(categoriesActions.invalidateCategories());
        if (meta && meta.onError) {
          meta.onError();
        }
      });
  },

  addCategory: (payload, meta) => async (dispatch) => {
    dispatch(
      actions.addCategory({
        meta: {
          prefix: [PREFIX.CATEGORIES, PREFIX.API_CALLING],
        },
      }),
    );
    const verifiedPayload = payload;
    // console.log(verifiedPayload);

    const api = API_URLS.CATEGORIES.addCategory(verifiedPayload);
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      dispatch(
        actions.addCategory({
          payload: response,
          meta: {
            prefix: [PREFIX.CATEGORIES, PREFIX.API_CALLED_SUCCESS],
          },
        }),
      );
      dispatch(categoriesActions.invalidateCategories());
      dispatch(categoriesActions.getCategoriesByLevel(0));
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
    } else if (meta && meta.onError) {
      meta.onError(error);
    }
  },

  // Get All category Info
  getAllCategories: () => async (dispatch) => {
    dispatch(
      actions.getAllCategories({
        meta: {
          prefix: [PREFIX.CATEGORIES, PREFIX.API_CALLING],
        },
      }),
    );
    const api = API_URLS.CATEGORIES.getCategories();
    const params = { Page: 1, PageSize: 500 }; // @TODO: update if there's more than 500
    const { response, error } = await apiCall({ ...api, params });
    if (!error && response.status === 200) {
      dispatch(
        actions.getAllCategories({
          payload: response.data,
          meta: {
            prefix: [PREFIX.CATEGORIES, PREFIX.API_CALLED_SUCCESS],
          },
        }),
      );
    } else {
      dispatch(
        actions.getAllCategories({
          meta: {
            prefix: [PREFIX.CATEGORIES, PREFIX.API_CALLED_FAILURE],
          },
        }),
      );
    }
  },

  getAllCategoryIfNeed: () => (dispatch, getState) => {
    const state = getState();
    const isFetching = state.categoriesReducer.listAllCategory.isFetching;
    const didInvalidate = state.categoriesReducer.listAllCategory.didInvalidate;
    if (!isFetching && didInvalidate) {
      dispatch(categoriesActions.getAllCategories());
    }
  },
};

const CategoriesState = {
  isFetching: false,
  isReloading: false,
  levelLoadedFlag: {}, // did invalidate for Levels
  didInvalidate: true, // for Categories
  lastUpdated: moment().unix(),
  byLevel: {},
  treeData: [],
  allData: {},
  categories: [],
  isFetchingCategories: false,
  activeCategoriesLevel3: [],
  isFetchingActiveCategoriesLevel3: false,
  didInvalidateActiveCategoriesLevel3: true,
  finished: false,
  list: {},
  editCategory: {},
  listAllCategory: {},
  // meta: {
  //   pagination: {
  //     current: 1,
  //     pageSize: 10,
  //     total: 0,
  //   },
  //   query: {
  //     name: '',
  //   },
  // },
};

const convertCategoriesToTreeData = (categoriesLevel) => {
  if (!categoriesLevel || !Object.keys(categoriesLevel).length) return [];

  const allData = {};
  const firstLevelItems = [];
  Object.keys(categoriesLevel).forEach((levelText) => {
    const dataSource = categoriesLevel[levelText];
    const level = Number.parseInt(levelText, 10);
    dataSource.forEach((item) => {
      allData[item.code] = Object.assign(item, { children: [] });

      if (level === 0) {
        firstLevelItems.push(allData[item.code]);
      } else {
        const parentCode = item.parent_code;
        if (allData[parentCode]) {
          allData[parentCode].children = allData[parentCode].children
            ? allData[parentCode].children
            : [];
        } else {
          allData[parentCode] = { children: [] };
        }
        allData[parentCode].children.push(item);
      }
    });
  });
  return { treeData: firstLevelItems, allData };
};

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState: CategoriesState,
  reducers: {
    getCategories: (state, action) => {
      action.type = types.GET_CATEGORIES;
      if (isCallingApi(action.payload)) {
        state.isFetchingCategories = true;
      }
      if (isSuccessfulApiCall(action.payload)) {
        state.categories = action.payload.payload;
        state.didInvalidate = false;
        state.isFetchingCategories = false;
      }
      if (isFailedApiCall(action.payload)) {
        state.didInvalidate = false;
        state.isFetchingCategories = false;
      }
    },
    getAllCategories: (state, action) => {
      action.type = types.GET_ALL_CATEGORY;
    },

    getCategoriesByLevel: (state, action) => {
      action.type = types.GET_CATEGORIES_BY_LEVEL;
      if (isCallingApi(action.payload)) {
        state.isFetching = true;
      }
      if (isSuccessfulApiCall(action.payload)) {
        const { payload, level } = action.payload;
        const { levelLoadedFlag } = state;
        levelLoadedFlag[level] = true;
        const byLevel = JSON.parse(JSON.stringify(state.byLevel));
        byLevel[level] = payload.filter((item) => item.level === level);
        const { allData, treeData } = convertCategoriesToTreeData(byLevel);
        // console.log(allData);
        state.isFetching = false;
        state.isReloading = false;
        // state.levelLoadedFlag;
        state.lastUpdated = moment().unix();
        state.byLevel = byLevel;
        state.allData = allData;
        state.treeData = treeData;
      }
      if (isFailedApiCall(action.payload)) {
        const { level } = action.payload;
        state.levelLoadedFlag[`${level}`] = true;
        state.byLevel[`${level}`] = [];

        state.isFetching = false;
        state.isReloading = false;
      }
    },
    getActiveCategoriesLevel3: (state, action) => {
      action.type = types.GET_ACTIVE_CATEGORIES_LEVEL_3;
      if (isCallingApi(action.payload)) {
        state.isFetchingActiveCategoriesLevel3 = true;
      }
      if (isSuccessfulApiCall(action.payload)) {
        state.activeCategoriesLevel3 = action.payload.payload;
        state.didInvalidateActiveCategoriesLevel3 = false;
        state.isFetchingActiveCategoriesLevel3 = false;
      }
      if (isFailedApiCall(action.payload)) {
        state.didInvalidateActiveCategoriesLevel3 = false;
        state.isFetchingActiveCategoriesLevel3 = false;
      }
    },
    reloadAllCategories: (state, action) => {
      action.type = types.RELOAD_ALL_CATEGORIES;
      if (isCallingApi(action.payload)) {
        state.isReloading = true;
      }
    },
    invalidateCategories: (state, action) => {
      action.type = types.INVALIDATE_CATEGORIES;
      state.levelLoadedFlag = {};
      state.didInvalidate = true;
      state.didInvalidateActiveCategoriesLevel3 = true;
      state.finished = false;
    },
    addCategory: (state, action) => {
      action.type = types.ADD_CATEGORY;
    },
  },
});

export const { actions, reducer } = categoriesSlice;

export default reducer;
