import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import moment from 'moment';

import { PREFIX, typesWithPrefix } from './config';
import { API_URLS } from '../config/api';
import { apiCallPromise } from '../utils/api';

const _types = typesWithPrefix(PREFIX.SAMPLE_ROUTE);
const types = {
  INVALIDATE_SAMPLE_ROUTES: _types('INVALIDATE_SAMPLE_ROUTES'),
  GET_SAMPLE_ROUTES: _types('GET_SAMPLE_ROUTES'),
  GET_ALL_SAMPLE_ROUTES: _types('GET_ALL_SAMPLE_ROUTES'),
  INSERT_SAMPLE_ROUTE: _types('INSERT_SAMPLE_ROUTE'),
  INSERT_MANY_SAMPLE_ROUTES: _types('INSERT_MANY_SAMPLE_ROUTES'),
  UPDATE_SAMPLE_ROUTE: _types('UPDATE_SAMPLE_ROUTE'),
  DELETE_SAMPLE_ROUTE: _types('DELETE_SAMPLE_ROUTE'),
  GET_SAMPLE_ROUTE_BY_ID: _types('GET_SAMPLE_ROUTE_BY_ID'),
  GET_SAMPLE_ROUTES_CHANGE_PAGE: _types('GET_SAMPLE_ROUTES_CHANGE_PAGE'),
};

const thunkActions = {
  getSampleRoutes: createAsyncThunk(
    types.GET_SAMPLE_ROUTES,
    async ({ ...query }, { rejectWithValue }) => {
      const params = {
        page: query.page,
        page_size: query.pageSize,
        company_code: query.companyCode,
      };
      const api = API_URLS.SAMPLE_ROUTE.getSampleRoutes(params);
      const { response, error } = await apiCallPromise(api);
      if (error) rejectWithValue({ params });

      return {
        items: response.data.data,
        meta: {
          page: response.data.page,
          pageSize: response.data.page_size,
          total: response.data.total,
        },
        query: {
          company_code: params.company_code,
        },
      };
    },
  ),
  getSampleRoutesIfNeed: (filterOption) => (dispatch, getState) => {
    const state = getState();
    const isFetching = state.sampleRouteReducer.isFetching;
    const didInvalidate = state.sampleRouteReducer.didInvalidate;
    const query = state.sampleRouteReducer.query;
    const isQueryChanged = query.company_code !== filterOption.companyCode;
    if ((!isFetching && didInvalidate) || (!isFetching && isQueryChanged))
      dispatch(thunkActions.getSampleRoutes(filterOption));
  },

  getAllSampleRoutes: createAsyncThunk(
    types.GET_ALL_SAMPLE_ROUTES,
    async (_, { rejectWithValue }) => {
      const params = { page: -1, pageSize: -1 };
      const api = API_URLS.SAMPLE_ROUTE.getSampleRoutes(params);
      const { response, error } = await apiCallPromise(api);
      if (error) rejectWithValue({ params });

      return { items: response.data.data };
    },
  ),

  invalidateSampleRoutes: () => ({ type: types.INVALIDATE_SAMPLE_ROUTES }),

  getSampleRouteById: createAsyncThunk(
    types.GET_SAMPLE_ROUTE_BY_ID,
    async ({ sampleRouteId, meta }, { rejectWithValue }) => {
      const api = API_URLS.SAMPLE_ROUTE.getSampleRouteByCode(sampleRouteId);
      const { response, error } = await apiCallPromise(api);
      if (!error && response.status === 200 && response.data.success === true) {
        if (meta && meta.onSuccess) meta.onSuccess();
        return { data: response.data.data };
      } else {
        if (meta && meta.onError) meta.onError(error);
        rejectWithValue();
      }
    },
  ),

  insertSampleRoute: createAsyncThunk(
    types.INSERT_SAMPLE_ROUTE,
    async ({ payload, meta }, { dispatch }) => {
      const api = API_URLS.SAMPLE_ROUTE.insertSampleRoute(payload);
      const params = { page: 1, pageSize: 10, search: '' };
      const { response, error } = await apiCallPromise(api);
      if (!error && (response.status === 200 || response.status === 201)) {
        if (meta && meta.onSuccess) {
          meta.onSuccess(response);
          // fulfillWithValue({ response });
        }
        dispatch(thunkActions.getSampleRoutes({ params }));
      } else {
        if (meta && meta.onError) meta.onError(error);
        // rejectWithValue({ error });
      }
    },
  ),

  insertManySampleRoutes: createAsyncThunk(
    types.INSERT_MANY_SAMPLE_ROUTES,
    async ({ payload, meta }, { dispatch }) => {
      const api = API_URLS.SAMPLE_ROUTE.insertSampleRoute(payload);
      const params = { page: 1, pageSize: 10, search: '' };
      const { response, error } = await apiCallPromise(api);
      if (!error && (response.status === 200 || response.status === 201)) {
        if (meta && meta.onSuccess) meta.onSuccess(response);
        // fulfillWithValue();
        dispatch(thunkActions.getSampleRoutes({ params }));
      } else {
        if (meta && meta.onError) meta.onError(error);
        // rejectWithValue();
      }
    },
  ),

  updateSampleRoute: createAsyncThunk(
    types.UPDATE_SAMPLE_ROUTE,
    async ({ code, payload, meta }, { dispatch, fulfillWithValue, rejectWithValue }) => {
      const api = API_URLS.SAMPLE_ROUTE.editSampleRoute(code, payload);
      const { response, error } = await apiCallPromise(api);
      if (!error && response.status === 200 && response.data.success === true) {
        if (meta && meta.onSuccess) meta.onSuccess();
        fulfillWithValue({ data: response.data });
        dispatch(thunkActions.getSampleRoutes({ page: 1, page_size: 10 }));
      } else {
        if (meta && meta.onError) meta.onError(error);
        rejectWithValue({ error });
      }
    },
  ),

  deleteSampleRoute: createAsyncThunk(
    types.DELETE_SAMPLE_ROUTE,
    async ({ sampleRouteId }, { rejectWithValue }) => {
      const api = API_URLS.SAMPLE_ROUTE.deleteSampleRoute(sampleRouteId);
      const { response, error } = await apiCallPromise({ ...api });
      if (error) {
        rejectWithValue({ error });
        if (meta && meta.onError) meta.onError(error);
      }

      if (meta && meta.onSuccess) meta.onSuccess();
      return { data: response.data };
    },
  ),

  changePage: (page, pageSize, total) => ({
    type: types.GET_SAMPLE_ROUTES_CHANGE_PAGE,
    payload: { page, pageSize, total },
  }),
};

const initialState = {
  isFetching: false,
  didInvalidate: true,
  lastUpdated: moment().unix(),
  currentSampleRoute: {},
  allItems: [],
  items: [],
  query: {},
  meta: {
    total: 0,
    page: 1,
    pageSize: 10,
  },
};

const sampleRouteSlice = createSlice({
  name: 'sampleRouteReducer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(types.INVALIDATE_SAMPLE_ROUTES, (state) => {
        state.didInvalidate = true;
      })
      .addCase(types.GET_SAMPLE_ROUTES_CHANGE_PAGE, (state, { payload }) => {
        state.meta = { page: payload.page, pageSize: payload.pageSize, total: payload.total };
      })
      .addCase(thunkActions.getSampleRouteById.fulfilled, (state, { payload }) => {
        state.currentSampleRoute = payload.data;
        state.isFetching = false;
      })
      .addCase(thunkActions.getSampleRoutes.fulfilled, (state, { payload }) => {
        const { items, meta, query } = payload;
        state.isFetching = false;
        state.didInvalidate = false;
        state.lastUpdated = moment.unix();
        state.items = items;
        state.meta = meta;
        state.query = query;
      })
      .addCase(thunkActions.getAllSampleRoutes.fulfilled, (state, { payload }) => {
        const { items } = payload;
        state.isFetching = false;
        state.didInvalidate = false;
        state.allItems = items;
      })
      .addMatcher(
        isAnyOf(thunkActions.updateSampleRoute.fulfilled, thunkActions.deleteSampleRoute.fulfilled),
        (state) => {
          state.isFetching = false;
          state.didInvalidate = true;
          state.lastUpdated = moment.unix();
        },
      )
      .addMatcher(
        isAnyOf(
          thunkActions.getSampleRoutes.pending,
          thunkActions.getSampleRouteById.pending,
          thunkActions.getAllSampleRoutes.pending,
          thunkActions.updateSampleRoute.pending,
          thunkActions.deleteSampleRoute.pending,
        ),
        (state) => {
          state.isFetching = true;
        },
      )
      .addMatcher(
        isAnyOf(
          thunkActions.getSampleRoutes.rejected,
          thunkActions.getSampleRouteById.rejected,
          thunkActions.getAllSampleRoutes.rejected,
          thunkActions.updateSampleRoute.rejected,
          thunkActions.deleteSampleRoute.rejected,
        ),
        (state) => {
          state.isFetching = false;
          state.didInvalidate = false;
        },
      );
  },
});

export const actions = { ...sampleRouteSlice.actions, ...thunkActions };
export const { reducer } = sampleRouteSlice;
