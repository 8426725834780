export const numberToVnd = (number) =>
  new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(number);

export const formatPhoneNumber = (phone_numberString) => {
  const cleaned = `${phone_numberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    // const intlCode = match[1] ? '+84 ' : '';
    return [match[1], ' ', match[2], ' ', match[3]].join('');
  }
  return phone_numberString;
};
