export const ORDER_STATUS = {
  cancelled: 'Huỷ',
  processing: 'Đang Xử Lý',
  completed: 'Hoàn Thành',
  new: 'Mới',
  returned: 'Trả Lại',
};

export const SYSTEM_STATUS = {
  category: 'Nhóm hàng',
  product: 'Sản phẩm',
  home: 'Trang chủ',
  promotion: 'Khuyến mại',
};

export const VOUCHER_STATUS = {
  waiting: 'Chờ xác nhận',
  draft: 'Nháp',
  accept: 'Xác nhận',
  rejected: 'Từ chối',
  cancelled: 'Huỷ',
  processing: 'Đang xử lý',
  completed: 'Hoàn thành',
  voucher_created: 'Đã tạo phiếu xuất',
  new: 'Mới',
};
