/* globals atob, Blob, unescape, Uint8Array */

/* http://stackoverflow.com/a/5100158/907388 */
export const dataUrlToFile = (dataUrl, filename) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataUrl.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataUrl.split(',')[1]);
  } else {
    byteString = unescape(dataUrl.split(',')[1]);
  }

  // separate out the mime component
  const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }

  const file = new Blob([ia], { type: mimeString });
  file.name = filename;
  file.lastModifiedDate = new Date();

  return file;
};

// const IMAGE_BASE_URL = 'https://static.dev.ubofood.com';

// export const buildImageUrl = (imagePath) => {
//   if (!imagePath || imagePath.startsWith('http')) {
//     return imagePath;
//   }

//   return `${IMAGE_BASE_URL}${imagePath}`;
// };

export const splitNameFromImageURL = (imageUrl) => {
  const lastIndexOfSlash = imageUrl.lastIndexOf('/');
  if (lastIndexOfSlash >= 0) {
    return imageUrl.substring(lastIndexOfSlash + 1);
  }

  return imageUrl;
};

export const getImageUrl = (url) => {
  if (!url) {
    return '';
  }

  if (url.startsWith('http') || url.startsWith('/')) {
    return url;
  }
  if (window?.location?.host && window.location.host.includes('tramxanhviet.')) {
    return `https://anvita.sgp1.cdn.digitaloceanspaces.com/tramxanh/images/${url}`;
  } else {
    return `https://anvita.sgp1.cdn.digitaloceanspaces.com/dev/${url}`;
  }
};
