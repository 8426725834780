import { Col, Row, notification } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Card from '../../components/Card';
import ROUTER from '../../config/router';
import WithLocale from '../../hoc/WithLocale';
import { actions as driverActions } from '../../redux/DriverRedux';
import { isBelowBreakpoint } from '../../utils/window';
import DriverForm from './components/Form';

class AddDriver extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSubmit = (values) => {
    const { history } = this.props;
    const {
      code,
      cost_per_hour,
      cost_per_hour_overtime,
      cost_per_km,
      date_of_birth,
      email,
      gender,
      identity_card,
      name,
      note,
      phone_number,
      speed,
      status,
      workTime,
      default_vehicle_code,
      start_locationAddress,
      start_latitude,
      start_longitude,
      finish_locationAddress,
      finish_latitude,
      finish_longitude,
    } = values;

    const driverInfoPayload = {
      cost_per_hour,
      cost_per_hour_overtime,
      cost_per_km,
      date_of_birth: date_of_birth
        ? moment(date_of_birth).format('YYYY-MM-DDTHH:00:00[Z]')
        : undefined,

      email,
      finish_location: {
        address: values.isCopyLocation ? start_locationAddress : finish_locationAddress,
        latitude: values.isCopyLocation ? parseFloat(start_latitude) : parseFloat(finish_latitude),
        longitude: values.isCopyLocation
          ? parseFloat(start_longitude)
          : parseFloat(finish_longitude),
      },
      gender,
      identity_card,
      name,
      note,
      phone_number,
      speed,
      start_location: {
        address: start_locationAddress,
        latitude: parseFloat(start_latitude),
        longitude: parseFloat(start_longitude),
      },
      status,
      work_time_from: workTime && workTime[0] ? moment(workTime[0]).format('HH:mm') : '',
      work_time_to: workTime && workTime[1] ? moment(workTime[1]).format('HH:mm') : '',
      default_vehicle_code,
      code,
    };

    this.props.insertDriver(driverInfoPayload, {
      onSuccess: () => {
        notification.open({
          message: 'Thêm thành công',
          type: 'success',
        });
        history.push(ROUTER.DRIVER.INDEX);
      },
      onError: (err) => {
        if (err.status === 465) {
          notification.error({
            message: `${'Trùng mã lái xe!'}`,
          });
        } else if (err.status === 466) {
          notification.error({
            message: `${'Trùng số điện thoại!'}`,
          });
        } else {
          notification.error({
            message: `${'Thêm lái xe thất bại!'}`,
          });
        }
      },
    });
  };

  render() {
    const { speed, depot, work_time_from, work_time_to } = this.props.configValues;
    const defaultValue = {
      speed,
      workTime:
        work_time_from && work_time_to
          ? [moment(work_time_from, 'HH:mm'), moment(work_time_to, 'HH:mm')]
          : ['', ''],
      start_location: depot,
      start_locationAddress: depot?.address,
      start_latitude: depot?.latitude,
      start_longitude: depot?.longitude,

      finish_location: depot,
      finish_locationAddress: depot?.address,
      finish_latitude: depot?.latitude,
      finish_longitude: depot?.longitude,
      date_of_birth: undefined,
    };

    return (
      <div>
        <Row type="flex" justify="center">
          <Col span={24} lg={24}>
            <Card hoverable={false} bordered={!isBelowBreakpoint()}>
              <DriverForm defaultValue={defaultValue} onSubmit={this.onSubmit} isEditMode={false} />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  configValues: state.configurationReducer.item,
});

const mapDispatchToProps = (dispatch) => ({
  insertDriver: (payload, meta) => dispatch(driverActions.insertDriver(payload, meta)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WithLocale(AddDriver)));
