export const getWindowWidth = () => {
  const w = window || {}; // eslint-disable-line no-use-before-define
  return w.innerWidth || 1200;
};

const windowBreakpoint = 992;
export const isBelowBreakpoint = () => getWindowWidth() < windowBreakpoint;
export const backpage = () => {
  window.history.back();
};
