/* eslint-disable react/prop-types */
import { LogoutOutlined } from '@ant-design/icons';
import { Avatar, Divider, Modal } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { actions as authActions } from '../../redux/AuthRedux';
// import BadgeIcon from '../BadgeIcon';
import UserDropdown from '../UserDropdown';

class UserWidget extends Component {
  languageOnChange = (languageKey) => {
    this.props.locale.setLanguage(languageKey);
  };

  logOut = () => {
    Modal.confirm({
      title: 'Bạn có muốn đăng xuất ?',
      okText: 'Có',
      cancelText: 'Không',
      onOk: () => {
        this.props.logOut();
      },
      onCancel() {},
    });
  };

  handleChangePassword = () => {
    this.props.onChangePassword();
  };

  render() {
    const { user } = this.props;
    return (
      <>
        <span style={{ marginRight: 10 }}>
          Xin chào, <b>{user.full_name}</b>!
        </span>
        <UserDropdown onClick={this.handleChangePassword}>
          <Avatar
            style={{ marginTop: '-10px' }}
            src="https://c7.uihere.com/files/340/946/334/avatar-user-computer-icons-software-developer-avatar.jpg"
          />
        </UserDropdown>
        <Divider type="vertical" />
        <LogoutOutlined count={0} name="LogOut" onClick={() => this.logOut()} />
      </>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  logOut: () => dispatch(authActions.logOut()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserWidget);
