import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ROUTER from '../../../config/router';
import CompareEstimateAndReal from '../../Dashboard/components/CompareEstimateAndReal';
import drivingTimeDashboard from '../../Dashboard/components/drivingTimeDashboard';
import nodesDashboard from '../../Dashboard/components/nodesDashboard';
import ordersDashboard from '../../Dashboard/components/ordersDashboard';
import realDistanceDashboard from '../../Dashboard/components/realDistanceDashboard';

const Dashboard = () => (
  <Switch>
    <Route exact path={ROUTER.DASHBOARD.INDEX} component={drivingTimeDashboard} />
    <Route exact path={ROUTER.DASHBOARD.NODES} component={nodesDashboard} />
    <Route exact path={ROUTER.DASHBOARD.ORDERS} component={ordersDashboard} />
    <Route exact path={ROUTER.DASHBOARD.REALDISTANCE} component={realDistanceDashboard} />
    <Route
      exact
      path={ROUTER.DASHBOARD.CONPARE_ESTIMATE_AND_REAL}
      component={CompareEstimateAndReal}
    />
  </Switch>
);
export default Dashboard;
