import { Tooltip } from 'antd';
import React from 'react';

const ProductColumn = ({ products }) => {
  const productStrList = [];
  products.forEach((item) => {
    const existedIdx = productStrList.findIndex((p) => p.product_code === item.product_code);
    if (existedIdx >= 0) {
      productStrList[existedIdx].quantity += item.quantity;
    } else {
      productStrList.push({
        product_code: item.product_code,
        product_name: item.product_name,
        quantity: item.quantity,
      });
    }
  });

  if (!productStrList.length) {
    return '-';
  }

  return productStrList.length > 2 ? (
    <Tooltip
      title={
        <ul style={{ marginBottom: 0, paddingLeft: 15 }}>
          {productStrList.map((p) => (
            <li key={p.product_code} style={{ lineHeight: 1 }}>
              <small>
                {p.product_name} - {p.quantity}
              </small>
            </li>
          ))}
        </ul>
      }>
      <ul style={{ marginBottom: 0, paddingLeft: 15 }}>
        {productStrList.slice(0, 2).map((p, i) => (
          <li key={p.product_code} style={{ lineHeight: 1 }}>
            <small>
              {p.product_name} - {p.quantity} {i === 1 ? '...' : ''}
            </small>
          </li>
        ))}
      </ul>
    </Tooltip>
  ) : (
    <ul style={{ marginBottom: 0, paddingLeft: 15 }}>
      {productStrList.map((p) => (
        <li key={p.product_code} style={{ lineHeight: 1 }}>
          <small>
            {p.product_name} - {p.quantity}
          </small>
        </li>
      ))}
    </ul>
  );
};

export default ProductColumn;
