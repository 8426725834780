import { PlusOutlined } from '@ant-design/icons';
import { Divider, Button, notification } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Filter from './components/Filter';
import FeatureForm from './components/Form';
import FeatureList from './components/List';
import WithConfig from '../../hoc/WithConfig';
import WithLoading from '../../hoc/WithLoading';
import { actions as featureActions } from '../../redux/FeatureRedux';

class FeaturePage extends Component {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      visibleFeature: false,
      dataFilter: {
        page: '',
        page_size: '',
        search: '',
      },
    };
  }

  static getDerivedStateFromProps(props, state) {
    const page = props.meta.page;
    const page_size = props.meta.page_size;
    const { search } = state.dataFilter;
    props.getFeaturesIfNeed({
      page,
      page_size,
      search,
    });
    return null;
  }

  componentDidMount() {
    this.props.getAllFeatures();
  }

  onPageChange = (page, page_size) => {
    const { changePage, invalidateFeatures, total } = this.props;
    changePage(page, page_size, total);
    invalidateFeatures();
  };

  onChangeFilter = (data) => {
    this.setState({
      dataFilter: data,
    });
  };

  handleOnAdd = () => {
    this.setState({ curEditFeature: {} }, () => this.toggleModalFeature());
  };

  toggleModalFeature = () => {
    this.setState((prevState) => ({ visibleFeature: !prevState.visibleFeature }));
  };

  onCreateFeature = (values) => {
    this.props.insertFeature(values, {
      onSuccess: () => {
        notification.success({ message: 'Tạo đặc tính mới thành công' });
        this.toggleModalFeature();
        this.props.invalidateFeatures();
      },
      onError: (mes) => {
        notification.error({ message: `Tạo đặc tính thất bại. ${mes}` });
      },
    });
  };

  onEditFeature = (code, payload) => {
    this.props.updateFeature(code, payload, {
      onSuccess: () => {
        notification.success({ message: 'Đã cập nhật đặc tính' });
        this.toggleModalFeature();
        this.props.invalidateFeatures();
      },
      onError: (error) => notification.error({ message: `${error} - Cập nhật thất bại` }),
    });
  };

  setEditFeature = (feature) => {
    this.setState({ curEditFeature: feature }, () => this.toggleModalFeature());
  };

  render() {
    const { isFetching, meta, features } = this.props;
    const { visibleFeature, curEditFeature } = this.state;
    return (
      <>
        <div>
          <h2 style={{ float: 'left' }}>Danh sách các đặc tính</h2>
          <div style={{ float: 'right' }}>
            <Button shape="round" type="primary" onClick={this.handleOnAdd} icon={<PlusOutlined />}>
              Thêm
            </Button>
          </div>
        </div>
        <Divider style={{ marginTop: 0 }} />
        <div style={{ width: 400, maxWidth: '100%', marginBottom: '15px' }}>
          <Filter onSearch={this.onChangeFilter} />
        </div>
        <FeatureList
          onSetEditRole={this.setEditFeature}
          isFetching={isFetching}
          dataSource={features}
          meta={meta}
          onPageChange={this.onPageChange}
        />

        <FeatureForm
          visibleFeature={visibleFeature}
          editFeature={curEditFeature}
          onToggleModalFeature={this.toggleModalFeature}
          onCreateFeature={this.onCreateFeature}
          onEditFeature={this.onEditFeature}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { featureReducer } = state;
  return {
    features: featureReducer.items,
    meta: featureReducer.meta,
    total: featureReducer.meta.total,
    isFetching: featureReducer.isFetching,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getFeaturesIfNeed: (filterOption) => dispatch(featureActions.getFeaturesIfNeed(filterOption)),
  changePage: (page, page_size, total) =>
    dispatch(featureActions.changePage(page, page_size, total)),
  invalidateFeatures: () => dispatch(featureActions.invalidateFeatures()),
  insertFeature: (payload, meta) => dispatch(featureActions.insertFeature(payload, meta)),
  updateFeature: (featureId, payload, meta) =>
    dispatch(featureActions.updateFeature(featureId, payload, meta)),
  getAllFeatures: (params) => dispatch(featureActions.getAllFeatures(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WithConfig('feature')(WithLoading(FeaturePage)));
