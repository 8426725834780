import { Select, Spin } from 'antd';
import lodash from 'lodash';
import React, { Component } from 'react';
import { FaTruckMoving } from 'react-icons/fa';
import { IoCloudOffline } from 'react-icons/io5';

const { Option } = Select;

class SelectSearch extends Component {
  constructor(props) {
    super(props);
    this.lastFetchId = 0;

    this.state = {
      value: props.selectedValue || [],
      selectedValue: props.selectedValue || [],
      searchData: [],
      fetching: false,
      initialiedData: [],
      searchText: '',
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { selectedValue } = nextProps;
    const { selectedValue: prevSelectedValue } = prevState;
    if (!lodash.isEqual(prevSelectedValue, selectedValue))
      return { value: selectedValue || [], selectedValue };

    return null;
  }

  // componentDidMount() {
  //   const { dontInitialize } = this.props;
  //   if (!dontInitialize) this.initializeData();
  // }

  handleChange = (value) => {
    const { mode } = this.props;
    this.setState({ value, searchData: [], fetching: false });
    const { onChange } = this.props;
    onChange(mode === 'multiple' ? this.convertToValueArray(value) : value?.key);
  };

  convertToValueArray = (value) => (value && value.length ? value.map((item) => item.key) : []);

  fetchData = (text) => {
    this.setState({ searchText: text });
    if (text.length < 2) return;

    const { onSearch } = this.props;
    this.lastFetchId += 1;
    const fetchId = this.lastFetchId;
    this.setState({ searchData: [], fetching: true });
    const keyword = text === 'all' ? '' : text;
    onSearch(keyword).then((searchData) => {
      if (fetchId !== this.lastFetchId) return;

      this.setState({ searchData, fetching: false, searchText: text });
    });
  };

  // initializeData = () => {
  //   const { onSearch } = this.props;
  //   this.setState({ fetching: true });
  //   onSearch('').then((initialiedData) => this.setState({ initialiedData, fetching: false }));
  // };

  render() {
    const { fetching, searchData, value, defaultValue, searchText } = this.state;
    const { dataSource, placeholder, mode, excludedValue, disabled, style, allowClear } =
      this.props;
    const options = searchText || (searchData && searchData.length) ? searchData : dataSource;
    const placeholderText = placeholder || 'Tìm kiếm';
    return (
      <Select
        showSearch
        allowClear={allowClear}
        style={style ? Object.assign({ width: '100%' }, style) : { width: '100%' }}
        disabled={disabled}
        mode={mode}
        labelInValue
        defaultValue={defaultValue}
        value={value}
        placeholder={placeholderText}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        filterOption={
          excludedValue && excludedValue.length
            ? (v, option) => !excludedValue.includes(option.key)
            : false
        }
        optionFilterProp="key"
        onSearch={this.fetchData}
        onChange={this.handleChange}>
        {options.map((d) => (
          <Option key={d.key || d.code}>
            {d.label || d.name}{' '}
            {d.status === 'Driving' ? (
              <FaTruckMoving />
            ) : d.status === 'Off' ? (
              <IoCloudOffline />
            ) : null}
          </Option>
        ))}
      </Select>
    );
  }
}

export default SelectSearch;
