import { CheckOutlined, ReloadOutlined } from '@ant-design/icons';
import { Form, Row, Col, Input, Button, Typography, Select } from 'antd';
import lodash from 'lodash';
import React, { Component } from 'react';

import CompanySearch from '../../../components/FormItems/CompanySearch';

const { TextArea } = Input;
const FormItem = Form.Item;
const { Title } = Typography;
const { Option } = Select;
const layout = {
  labelCol: {
    span: 24,
  },
};
class CompanyForm extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      phoneError: true,
      formRef: this.formRef,
      companyInfo: {},
      companyType: 'single',
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.companyInfo && !lodash.isEqual(nextProps.companyInfo, prevState.companyInfo)) {
      if (prevState.formRef && prevState.formRef.current) {
        const companyInfo = nextProps.companyInfo;
        prevState.formRef.current.setFieldsValue({
          name: companyInfo.name,
          phone: companyInfo.phone,
          email: companyInfo.email,
          type: companyInfo.type || 'single',
          address: companyInfo.address,
          hotline: companyInfo.hotline,
          description: companyInfo.description,
        });
      }
      return {
        companyInfo: nextProps.companyInfo,
        companyType: nextProps.companyInfo.type || 'single',
      };
    }

    return null;
  }

  onEditorChange = (value) => {
    this.formRef.current.setFieldsValue({ intro: value });
  };

  onReset = () => {
    this.setState(() => ({
      optionFields: ['company'],
    }));
    this.formRef.current.resetFields();
  };

  onFinish = (value) => {
    this.props.onSubmit(value);
  };

  validatePhoneNumber = (rule, value, callback) => {
    const re = /^(0)[1-9]([0-9]{8}[0-9]?)\b/;
    if (value && !re.test(value)) {
      callback('Số điện thoại không hợp lệ');
    } else {
      callback();
    }
  };

  render() {
    const { companyCode, isEditMode } = this.props;
    const { companyType } = this.state;
    const FormTitle = isEditMode ? 'Sửa thông tin công ty' : 'Thêm công ty';
    return (
      <div>
        <Title level={3} description="Thông báo">
          {FormTitle}
        </Title>
        <Form ref={this.formRef} onFinish={this.onFinish} {...layout}>
          <Row gutter={15}>
            <Col span={12}>
              <FormItem
                label="Tên công ty"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Nhập tên công ty của bạn!',
                  },
                ]}>
                <Input placeholder="Nhập tên công ty ..." />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Số điện thoại"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: 'Nhập số điện thoại!',
                  },
                  {
                    validator: this.validatePhoneNumber,
                    message: 'Sai định dạng!',
                  },
                ]}>
                <Input placeholder="Nhập số điện thoại công ty ..." />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: 'Sai định dạng!',
                  },
                ]}>
                <Input placeholder="Nhập Email..." />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Đường dây nóng"
                name="hotline"
                rules={[
                  {
                    validator: this.validatePhoneNumber,
                    message: 'Sai định dạng!',
                  },
                ]}>
                <Input placeholder="Nhập đường dây nóng..." />
              </FormItem>
            </Col>
          </Row>
          {!companyCode ? (
            <Row gutter={15}>
              <Col span={12}>
                <FormItem label="Quy mô công ty" name="type" initialValue="single">
                  <Select
                    style={{ width: '100%', maxWidth: 300 }}
                    placeholder="Quy mô"
                    onChange={(value) => {
                      this.setState({ companyType: value });
                    }}>
                    <Option value="single">Một công ty/Chi Nhánh</Option>
                    <Option value="multi">Tổng công ty/Trụ sở</Option>
                  </Select>
                </FormItem>
              </Col>
              {companyType === 'single' ? (
                <Col span={12}>
                  <FormItem label="Công ty cha" name="parent_code" valuePropName="selectedValue">
                    <CompanySearch type="multi" disabled={companyType === 'multi'} />
                  </FormItem>
                </Col>
              ) : null}
            </Row>
          ) : null}
          <Row gutter={15}>
            <Col span={24} lg={24}>
              <FormItem
                label="Địa chỉ"
                name="address"
                rules={[
                  {
                    required: true,
                    message: 'Nhập địa chỉ!',
                  },
                ]}>
                <TextArea placeholder="Nhập địa chỉ..." />
              </FormItem>
              <FormItem label="Mô tả" name="description">
                <TextArea placeholder="Nhập mô tả..." />
              </FormItem>
            </Col>
          </Row>

          <FormItem style={{ float: 'right', marginTop: '10px' }}>
            <Button
              type="primary"
              htmlType="submit"
              shape="round"
              icon={<CheckOutlined />}
              style={{ marginRight: 8 }}>
              Lưu
            </Button>
            <Button htmlType="reset" onClick={this.onReset} shape="round" icon={<ReloadOutlined />}>
              Nhập lại
            </Button>
          </FormItem>
        </Form>
      </div>
    );
  }
}

export default CompanyForm;
