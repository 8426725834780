import { CheckOutlined } from '@ant-design/icons';
import { Form, Modal, notification, Button, Col } from 'antd';
import React from 'react';
import { connect } from 'react-redux';

import TomtomSearch from '../../../components/FormItems/TomtomSearch';
import { actions as tomtomActions } from '../../../redux/TomtomRedux';

const FormItem = Form.Item;

class LocationForm extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      formRef: this.formRef,
      lat: '',
      lng: '',
      address: '',
      init: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { location } = props;
    const { init } = state;
    if (location && !init) {
      return {
        lat: location.latitude,
        lng: location.longitude,
        address: location.address,
        init: true,
      };
    }
  }

  componentDidMount() {}

  onFinishFailed = () => {
    notification.warning({ message: 'Không được để trống !!!' });
  };

  // onSelectAddress = (value) => {
  //   const { address, lat, lng } = value;
  //   if (address) {
  //     this.formRef.current.setFieldsValue({ address });
  //     this.setState({ lat, lng, address });
  //   } else {
  //     this.setState({ lat, lng });
  //   }
  // };

  onSubmit = () => {
    const { selectedAddress, curLat, curLng } = this.props;
    const location = {
      address: selectedAddress,
      lat: curLat,
      lng: curLng,
    };
    this.formRef.current.setFieldsValue({ selectedAddress });
    this.props.onSubmitLocation(location);
    this.props.onToggleModalLocation();
  };

  render() {
    const { visibleLocation, onToggleModalLocation, isEditMode } = this.props;
    return (
      <Modal
        title="Chọn địa điểm"
        visible={visibleLocation}
        onCancel={onToggleModalLocation}
        destroyOnClose
        footer={null}
        bodyStyle={{ padding: 12 }}>
        <Form ref={this.formRef}>
          <Col span={24}>
            <FormItem name="address">
              <TomtomSearch isEditMode={isEditMode} smallInput />
            </FormItem>
          </Col>
          <Col span={24} style={{ textAlign: 'center', marginTop: -10, marginBottom: -15 }}>
            <FormItem>
              <Button onClick={this.onSubmit} type="primary" shape="round" icon={<CheckOutlined />}>
                Lưu
              </Button>
            </FormItem>
          </Col>
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedAddress: state.tomtomReducer.address,
  curLat: state.tomtomReducer.latitude,
  curLng: state.tomtomReducer.longitude,
});

const mapDispatchToProps = (dispatch) => ({
  setTomtomSearchAddress: (payload) => dispatch(tomtomActions.setMapAddress(payload)),
  setTomtomSearchLatitude: (payload) => dispatch(tomtomActions.setMapLatitude(payload)),
  setTomtomSearchLongitude: (payload) => dispatch(tomtomActions.setMapLongitude(payload)),
  clearTomtomStore: () => dispatch(tomtomActions.clearStore()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationForm);
