import { Avatar, Switch, Table, notification } from 'antd';
import React from 'react';

const editProductFn = (record, editProduct) => {
  editProduct(record.code, {
    category: {
      category_hierarchy: record?.category?.category_hierarchy,
      code: record?.category?.code,
      company_code: record?.category?.company_code,
      description: record?.category?.description,
      level: record?.category?.level,
      name: record?.category?.name,
      parent_code: record?.category?.parent_code,
      status: record?.category?.status,
    },
    category_code: record?.category_code,
    code: record?.code,
    description: record?.description,
    feature: record?.feature,
    name: record?.name,
    secondary_code: record?.secondary_code,
    img_url: record?.img_url,
    status: record?.status,
    unit: {
      factor: record?.unit?.factor,
      primary_unit: record?.unit?.primary_unit,
      secondary_unit: record?.unit?.secondary_unit,
    },
  });
};

const column = (editProduct, changeStatusProduct) => [
  {
    title: <b>Ảnh</b>,
    dataIndex: 'img_url',
    key: 'Avatar',
    render: (value, record) => (
      <a onClick={() => editProductFn(record, editProduct)}>
        <Avatar size={48} src={value} shape="square" />
      </a>
    ),
  },
  {
    title: <b>Mã sản phẩm</b>,
    dataIndex: 'code',
    render: (value, record) => (
      <>
        <div>{value}</div>
        {/* <small>{record.secondary_code}</small> */}
      </>
    ),
  },
  {
    title: <b>Tên sản phẩm</b>,
    dataIndex: 'name',
    render: (value, record) => <a onClick={() => editProductFn(record, editProduct)}>{value}</a>,
  },
  {
    title: <b>Nhóm hàng</b>,
    dataIndex: 'name',
    render: (value, record) => <>{record?.category?.name}</>,
  },
  {
    title: <b>Đơn vị</b>,
    dataIndex: 'unit',
    render: (value) => <>{value.secondary_unit}</>,
  },
  {
    title: <b>Trạng thái</b>,
    dataIndex: 'status',
    render: (value, record) => (
      <Switch
        defaultChecked={value}
        onChange={(value) => {
          changeStatusProduct(
            {
              code: record.code,
              payload: {
                status: value,
              },
            },
            {
              onSuccess: () => {
                notification.open({
                  message: 'Thay đổi trạng thái thành công',
                  type: 'success',
                });
              },
              onError: (errorCode) =>
                notification.open({
                  message: 'Thay đổi trạng thái không thành công',
                  type: 'error',
                }),
            },
          );
        }}
      />
    ),
  },
];

const ListProducts = ({
  dataProducts,
  meta,
  onPageChange,
  editProduct,
  changeStatusProduct,
  isLoading,
}) => (
  <>
    <Table
      size="small"
      columns={column(editProduct, changeStatusProduct)}
      dataSource={dataProducts}
      loading={isLoading}
      rowKey={(record) => record?.code}
      pagination={{
        current: meta?.page,
        pageSize: meta?.pageSize || 10,
        total: meta?.total,
        onChange: onPageChange,
        showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total}`,
      }}
    />
  </>
);

export default ListProducts;
