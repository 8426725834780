import { Col, Input, Row, Select } from 'antd';
import React from 'react';

import CategorySearch from '../../../components/FormItems/CategorySearch';

const { Option } = Select;
const { Search } = Input;

const Filter = ({ onChangeStatus, onChangeCategoryCodes, onChangeSearch }) => (
  <>
    <Row gutter={15}>
      <Col span={8}>
        <Search
          placeholder="Tên hoặc mã sản phẩm"
          onSearch={onChangeSearch}
          style={{ width: '100%' }}
          enterButton
          allowClear
        />
      </Col>
      <Col span={8}>
        <CategorySearch allowClear onChange={onChangeCategoryCodes} mode="multiple" />
      </Col>
      <Col span={8}>
        <Select
          style={{ width: '100%' }}
          placeholder="Trạng thái"
          onChange={onChangeStatus}
          allowClear>
          <Option value="True">Hoạt động</Option>
          <Option value="False">Dừng hoạt động</Option>
        </Select>
      </Col>
    </Row>
  </>
);

export default Filter;
