import { Col, Divider, Row, Typography, Tabs, notification } from 'antd';
import moment from 'moment';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import OrderForm from './components/Form';
import ProductForm from './components/ProductForm';
import Card from '../../components/Card';
import { actions as orderActions } from '../../redux/OrderRedux';
import { actions as productActions } from '../../redux/productRedux';
import { isBelowBreakpoint } from '../../utils/window';

const { TabPane } = Tabs;

const EditOrder = (props) => {
  const {
    match: {
      params: { code },
    },
    currentOrder,
    hasInventory,
    products,
    getOrderByCode,
  } = props;

  useEffect(() => {
    if (code) {
      getOrderByCode(code);
      props.getProducts({ page: -1, page_size: -1 });
    }
  }, [code]);

  useEffect(() => {
    if (hasInventory) {
      getOrderByCode(code);
    }
  }, [hasInventory]);

  const onUpdateOrderInfo = (values) => {
    const orderPayload = {
      customer: values.customer,
      delivery_date: values.delivery_date,
      demand: values.demand,
      driver_code: '',
      feature_codes: values.feature_codes || [],
      money: 0,
      note: values.note,
      occupied_space: values.occupied_space,
      vehicle_type: values.vehicle_type || '',
      order_items: currentOrder.order_items,
    };

    props.updateOrder(currentOrder.code, orderPayload, {
      onSuccess: () => {
        notification.open({
          message: 'Chỉnh sửa thông tin đơn giao hàng thành công!',
          type: 'success',
        });
      },
      onError: () =>
        notification.open({
          message: `${'Chỉnh sửa thông tin đơn giao hàng thất bại!'}`,
          type: 'error',
        }),
    });
  };

  const onUpdateOrderItems = (orderItems) => {
    const orderPayload = {
      customer: currentOrder.customer,
      delivery_date: currentOrder.delivery_date,
      demand: currentOrder.demand,
      driver_code: '',
      feature_codes: currentOrder.feature_codes || [],
      money: 0,
      note: currentOrder.note,
      occupied_space: currentOrder.occupied_space,
      vehicle_type: currentOrder.vehicle_type || '',
      order_items: orderItems,
    };

    props.updateOrder(currentOrder.code, orderPayload, {
      onSuccess: () => {
        notification.open({
          message: 'Chỉnh sửa danh sách sản phẩm thành công!',
          type: 'success',
        });
      },
      onError: () =>
        notification.open({
          message: `${'Chỉnh sửa danh sách sản phẩm thất bại!'}`,
          type: 'error',
        }),
    });
  };

  const defaultValue = {
    code: currentOrder.code,
    customer_code: currentOrder?.customer?.code,
    customer_contact: currentOrder?.customer?.contacts?.length
      ? `${currentOrder?.customer?.contacts[0]?.name},${currentOrder?.customer?.contacts[0]?.phone_number}`
      : '',
    address: currentOrder?.customer?.location?.address,
    longitude: currentOrder?.customer?.location?.longitude,
    latitude: currentOrder?.customer?.location?.latitude,
    timeWindow: [
      currentOrder?.customer?.time_windows?.length
        ? moment({
            hour: Number(currentOrder?.customer?.time_windows[0]?.from_time.slice(0, 2)),
            minute: Number(currentOrder?.customer?.time_windows[0]?.from_time.slice(3)),
          })
        : '',
      currentOrder?.customer?.time_windows?.length
        ? moment({
            hour: Number(currentOrder?.customer?.time_windows[0]?.to_time.slice(0, 2)),
            minute: Number(currentOrder?.customer?.time_windows[0]?.to_time.slice(3)),
          })
        : '',
    ],
    delivery_date: moment(currentOrder.delivery_date, 'YYYY-MM-DDTHH:mm:ss[Z]'),
    feature_codes: currentOrder.feature_codes,
    vehicle_type: currentOrder.vehicle_type,
    occupied_space: currentOrder.occupied_space,
    demand: currentOrder.demand,
    note: currentOrder.note,
  };

  const hasOrderItems = currentOrder?.order_items?.length || hasInventory;

  return (
    <div>
      <Row type="flex" justify="center">
        <Col span={24} lg={24}>
          {hasOrderItems ? (
            <Tabs style={{ width: '100%' }} defaultActiveKey="info">
              <TabPane tab="Thông tin đơn giao hàng" key="info">
                <OrderForm defaultValue={defaultValue} onSubmit={onUpdateOrderInfo} isEditMode />
              </TabPane>
              <TabPane tab="Sản phẩm" key="product">
                <ProductForm
                  orderItems={currentOrder?.order_items || []}
                  products={products}
                  onSubmit={onUpdateOrderItems}
                />
              </TabPane>
            </Tabs>
          ) : (
            <Card hoverable={false} bordered={!isBelowBreakpoint()}>
              <Typography.Title level={3}>Thông tin đơn giao hàng</Typography.Title>
              <Divider />
              <OrderForm defaultValue={defaultValue} onSubmit={onUpdateOrderInfo} isEditMode />
            </Card>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentOrder: state.orderReducer.currentOrder,
  hasInventory: state.companyReducer.hasInventory,
  products: state.productReducer?.data,
});

const mapDispatchToProps = (dispatch) => ({
  getOrderByCode: (code) => dispatch(orderActions.getOrderByCode(code)),
  updateOrder: (code, payload, meta) => dispatch(orderActions.updateOrder(code, payload, meta)),
  getProducts: (params) => dispatch(productActions.getProducts(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditOrder));
