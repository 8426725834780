import lodash from 'lodash';
import React, { Component } from 'react';
import { FaTruckMoving } from 'react-icons/fa';
import { IoCloudOffline } from 'react-icons/io5';
import { connect } from 'react-redux';

import SelectSearch from './SelectSearch';

class DriverSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prevSelectedValue: [],
      defaultValue: [],
      getDriverByCode: this.getDriverByCode,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!lodash.isEqual(nextProps.selectedValue, prevState.prevSelectedValue)) {
      const nextState = { prevSelectedValue: nextProps.selectedValue, defaultValue: [] };
      if (nextProps.selectedValue && nextProps.selectedValue.length) {
        const { selectedValue } = nextProps;
        if (typeof selectedValue === 'string') {
          nextState.defaultValue = prevState.getDriverByCode([selectedValue]);
        } else if (typeof selectedValue === 'object') {
          if (Array.isArray(selectedValue)) {
            if (typeof selectedValue[0] === 'string') {
              nextState.defaultValue = prevState.getDriverByCode(selectedValue);
            } else if (typeof selectedValue[0] === 'object') {
              return nextState;
            }
          } else {
            return nextState;
          }
        }
      }

      return nextState;
    }

    return null;
  }

  // searchDriverByName = async (name) => {
  //   const params = {
  //     search: name,
  //     page: 1,
  //     page_size: 20,
  //   };
  //   const api = API_URLS.DRIVER.getDrivers(params);
  //   const { response, error } = await apiCall(api);
  //   const results = [];
  //   if (!error && response.status === 200) {
  //     if (response.data.data && response.data.data.length) {
  //       response.data.data.forEach((driver) =>
  //         results.push({ key: driver.code, label: driver.name, status: driver.status }),
  //       );
  //     }
  //   }

  //   return results;
  // };

  // getDriverByCode = async (code) => {
  //   if (code && code.length) {
  //     const api = API_URLS.DRIVER.getDriverById(code);
  //     const { response, error } = await apiCall(api);
  //     const defaultValue = [];
  //     if (!error && response.status === 200) {
  //       if (response.data.data.code)
  //         defaultValue.push({
  //           key: `${response.data.data.code} - ${response.data.data.name}`,
  //           value: response.data.data.code,
  //           label: (
  //             <span>
  //               {response.data.data.name}{' '}
  //               {response.data.data.status === 'Driving' ? (
  //                 <FaTruckMoving />
  //               ) : response.data.data.status === 'Off' ? (
  //                 <IoCloudOffline />
  //               ) : null}
  //             </span>
  //           ),
  //           status: response.data.data.status,
  //         });
  //     }

  //     this.setState({ defaultValue });
  //   }
  // };

  searchDriverByName = async (name) => {
    const { allDrivers } = this.props;
    if (!allDrivers || !allDrivers.length) return [];

    const key = name.toLowerCase().trim();
    const foundDrivers = key
      ? allDrivers.filter((item) => item.name.toLowerCase().includes(key))
      : allDrivers;
    return foundDrivers && foundDrivers.length
      ? foundDrivers.map((item) => ({
          key: item.code,
          label: item.name,
          status: item.status,
        }))
      : [];
  };

  getDriverByCode = (codes) => {
    if (codes && codes.length) {
      const { allDrivers } = this.props;
      if (!allDrivers.length) {
        return [];
      }
      const defaultValue = [];
      codes.forEach((code) => {
        const driverCode = code.trim();
        if (!driverCode) return;
        const foundDriver = allDrivers.find((p) => p.code === driverCode);
        const company = foundDriver
          ? {
              key: `${foundDriver.code} - ${foundDriver.name}`,
              value: driverCode,
              label: (
                <span>
                  {foundDriver.name}{' '}
                  {foundDriver.status === 'Driving' ? (
                    <FaTruckMoving />
                  ) : foundDriver.status === 'Off' ? (
                    <IoCloudOffline />
                  ) : null}
                </span>
              ),
              status: foundDriver.status,
            }
          : { key: driverCode, label: `[Not found] ${driverCode}` };
        defaultValue.push(company);
      });

      return defaultValue;
    }

    return [];
  };

  render() {
    const { allDrivers } = this.props;
    return (
      <SelectSearch
        {...this.props}
        placeholder={this.props.placeholder || 'Chọn lái xe'}
        dataSource={allDrivers}
        onSearch={this.searchDriverByName}
        selectedValue={this.state.defaultValue}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  allDrivers: state.driverReducer.allItems,
});

// const mapDispatchToProps = (dispatch) => ({
// });

export default connect(mapStateToProps)(DriverSearch);
