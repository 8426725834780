import { EditOutlined, ReloadOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { Button, Modal, Alert } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { API_URLS } from '../../config/api';
import WithRef from '../../hoc/WithRef';
import { apiCall } from '../../utils/api';
import { dataUrlToFile, getImageUrl } from '../../utils/image';
import DropNCrop from '../DropNCrop';

class ImageUploadFormItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      dropNCropValue: null,
      isLoading: false,
    };

    this.showLoading = this.showLoading.bind(this);
    this.hideLoading = this.hideLoading.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.onCropDrop = this.onCropDrop.bind(this);
    this.upload = this.upload.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  onCropDrop(value) {
    this.setState(() => ({
      dropNCropValue: value,
    }));
  }

  showLoading() {
    this.setState(() => ({
      isLoading: true,
    }));
  }

  hideLoading() {
    this.setState(() => ({
      isLoading: false,
    }));
  }

  showModal() {
    this.setState(() => ({
      visible: true,
    }));
  }

  hideModal() {
    const { isLoading } = this.state;
    if (isLoading) {
      return;
    }
    this.setState(() => ({
      visible: false,
    }));
    setTimeout(() => {
      this.refresh();
    }, 0);
  }

  async upload() {
    const { onChange, outputWidth, outputHeight } = this.props;
    // const slug = 'new';
    const { dropNCropValue } = this.state;
    if (dropNCropValue && !dropNCropValue.error) {
      const fileUpload = dataUrlToFile(dropNCropValue.result);
      let api = '';
      if (outputWidth && outputHeight) {
        api = API_URLS.MEDIA.imageUpload({ width: outputWidth, height: outputHeight });
      } else {
        api = API_URLS.MEDIA.imageUploadNoResize();
      }

      const formData = new FormData();
      formData.append('file', fileUpload);
      this.showLoading();
      const { response, error } = await apiCall({
        ...api,
        payload: formData,
      });
      this.hideLoading();
      if (!error && response.status === 200) {
        onChange(response.data?.ImageUrl || '');
        this.hideModal();
      }
    }
  }

  refresh() {
    this.setState(() => ({
      dropNCropValue: null,
    }));
  }

  render() {
    const {
      value,
      isProduct,
      width,
      height,
      multiple,
      outputWidth,
      outputHeight,
      allowedFileTypes,
    } = this.props;
    const { dropNCropValue, isLoading, visible } = this.state;

    const cropperOptions = {
      guides: true,
      viewMode: 2,
      autoCropArea: 1,
      aspectRatio: outputWidth && outputHeight ? outputWidth / outputHeight : undefined,
    };
    const placeholderText =
      outputWidth && outputHeight ? `${outputWidth}x${outputHeight}` : '1000x1000';
    const placeholderImage = `https://via.placeholder.com/${placeholderText}.jpg?${
      !outputWidth || !outputHeight ? 'text=New Image' : ''
    }`;
    const Image = value.startsWith('{') ? JSON.parse(value) : value;
    const imageUrl = typeof Image === 'object' ? Image.ImageUrl : Image || placeholderImage;

    return (
      <div
        style={{
          width,
          maxWidth: '100%',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}>
        <img
          src={getImageUrl(imageUrl)}
          alt=""
          style={{
            width,
            height,
            objectFit: 'cover',
            maxWidth: '100%',
          }}
        />
        <Button
          icon={<EditOutlined />}
          shape="round"
          style={{ width: '100%' }}
          onClick={() => this.showModal()}>
          Sửa
        </Button>
        <Modal
          onCancel={() => this.hideModal()}
          onOk={() => this.upload()}
          bodyStyle={{ textAlign: 'center' }}
          title="Image Upload"
          visible={visible}
          footer={[
            <Button
              shape="round"
              onClick={() => this.refresh()}
              disabled={isLoading}
              key="refresh-button"
              icon={<ReloadOutlined />}>
              Nhập laị
            </Button>,
            <Button
              onClick={() => this.hideModal()}
              disabled={isLoading}
              key="cancel-button"
              shape="round"
              icon={<CloseOutlined />}>
              Huỷ
            </Button>,
            <Button
              shape="round"
              type="primary"
              loading={isLoading}
              key="submit-button"
              icon={<CheckOutlined />}
              onClick={() => this.upload()}>
              Chọn
            </Button>,
          ]}
          maskClosable={false}>
          <DropNCrop
            allowedFileTypes={allowedFileTypes}
            canvasWidth="100%"
            canvasHeight="360px"
            outputWidth={outputWidth}
            outputHeight={outputHeight}
            value={dropNCropValue}
            multiple={multiple}
            onChange={(value) => this.onCropDrop(value)}
            cropperOptions={cropperOptions}
          />
          {dropNCropValue && dropNCropValue.error ? (
            <Alert
              style={{
                width: '100%',
                border: '1px solid #ff0000',
                textAlign: 'left',
              }}
              type="error"
              message={dropNCropValue.error}
              banner
            />
          ) : null}
        </Modal>
      </div>
    );
  }
}

ImageUploadFormItem.propTypes = {
  value: PropTypes.string,
  slug: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  outputWidth: PropTypes.number.isRequired,
  outputHeight: PropTypes.number.isRequired,
  allowedFileTypes: PropTypes.any,
};

ImageUploadFormItem.defaultProps = {
  slug: '',
  value: '',
  multiple: false,
  allowedFileTypes: undefined,
};

export default WithRef(ImageUploadFormItem);
