import { SaveOutlined } from '@ant-design/icons';
import {
  Divider,
  notification,
  Row,
  InputNumber,
  Button,
  Col,
  Form,
  TimePicker,
  Input,
} from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import TomtomSearch from '../../components/FormItems/TomtomSearch';
import WithConfig from '../../hoc/WithConfig';
import WithLoading from '../../hoc/WithLoading';
import { actions as configActions } from '../../redux/ConfigurationRedux';
import { actions as tomtomActions } from '../../redux/TomtomRedux';

const FormItem = Form.Item;
const layout = {
  labelCol: {
    span: 24,
  },
};
class ConfigurationPage extends Component {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      formRef: this.formRef,
      init: props.configValues.id !== undefined,
    };
  }

  componentDidMount() {
    const { configValues } = this.props;
    if (configValues) {
      this.props.setTomtomSearchAddress(configValues?.depot?.address);
      this.props.setTomtomSearchLatitude(configValues?.depot?.latitude);
      this.props.setTomtomSearchLongitude(configValues?.depot?.longitude);
    }
  }

  componentWillUnmount() {
    this.props.clearTomtomStore();
  }

  static getDerivedStateFromProps(props, state) {
    const {
      configValues,
      setTomtomSearchAddress,
      setTomtomSearchLatitude,
      setTomtomSearchLongitude,
    } = props;
    const { init } = state;
    if (configValues?.id && !init) {
      state.formRef.current.setFieldsValue(configValues);
      state.formRef.current.setFieldsValue({
        address: configValues?.depot?.address,
        latitude: configValues?.depot?.latitude,
        longitude: configValues?.depot?.longitude,
      });
      setTomtomSearchAddress(configValues?.depot?.address);
      setTomtomSearchLatitude(configValues?.depot?.latitude);
      setTomtomSearchLongitude(configValues?.depot?.longitude);
      return {
        init: true,
      };
    }
    return null;
  }

  onSelectAddress = (value) => {
    this.formRef.current.setFieldsValue({
      address: value.address,
      longitude: value.lng,
      latitude: value.lat,
    });
  };

  // onSelectTimeWindow = (value) => this.formRef.current.setFieldsValue({ timeWindow: value });

  onFinish = (values) => {
    const { configValues, updateConfiguration } = this.props;
    const {
      speed,
      timeWindow,
      vehicle_volume,
      vehicle_capacity,
      workTime,
      address,
      longitude,
      latitude,
    } = values;
    const configValuePayload = {
      speed,
      time_window: {
        from_time: moment(timeWindow[0]).format('HH:mm'),
        to_time: moment(timeWindow[1]).format('HH:mm'),
      },
      work_time_from: moment(workTime[0]).format('HH:mm'),
      work_time_to: moment(workTime[1]).format('HH:mm'),
      vehicle_volume,
      vehicle_capacity,
      depot: {
        address,
        longitude,
        latitude,
      },
    };
    updateConfiguration(configValues.id, configValuePayload, {
      onSuccess: () => {
        notification.open({
          message: 'Sửa cấu hình thành công.',
          type: 'success',
        });
      },
      onError: () =>
        notification.open({
          message: `${'Sửa cấu hình thất bại!'}`,
          type: 'error',
        }),
    });
  };

  render() {
    const { configValues } = this.props;
    return (
      <>
        <div>
          <h2 style={{ float: 'left' }}>Cấu hình</h2>
        </div>
        <Divider style={{ marginTop: 0 }} />

        <Form ref={this.formRef} initialValues={configValues} onFinish={this.onFinish} {...layout}>
          <Row gutter={15}>
            <Col span={12}>
              <FormItem
                label="Thời gian giao hàng mặc định trong ngày"
                name="timeWindow"
                rules={[
                  {
                    required: true,
                    message: 'Chọn thời gian giao hàng mặc định trong ngày!',
                  },
                ]}>
                <TimePicker.RangePicker
                  format="HH:mm"
                  placeholder={['Bắt đầu', 'Kết thúc']}
                  style={{ width: '100%' }}
                  onChange={this.onSelectTimeWindow}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Thời gian làm việc mặc định của lái xe"
                name="workTime"
                rules={[
                  {
                    required: true,
                    message: 'Chọn thời gian làm việc mặc định của lái xe!',
                  },
                ]}>
                <TimePicker.RangePicker
                  format="HH:mm"
                  placeholder={['Bắt đầu', 'Kết thúc']}
                  style={{ width: '100%' }}
                />
              </FormItem>
            </Col>

            <Col span={8}>
              <FormItem
                label="Tải trọng mặc định (kg)"
                name="vehicle_capacity"
                rules={[
                  {
                    required: true,
                    message: 'Nhập tải trọng mặc định!',
                  },
                ]}>
                <InputNumber placeholder="Tải trọng của xe" min={0} style={{ width: '100%' }} />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="Thể tích mặc định (m³)"
                name="vehicle_volume"
                rules={[
                  {
                    required: true,
                    message: 'Nhập thể tích mặc định!',
                  },
                ]}>
                <InputNumber placeholder="Thể tích" min={0} style={{ width: '100%' }} />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="Tốc độ mặc định"
                name="speed"
                rules={[
                  {
                    required: true,
                    message: 'Nhập tốc độ mặc định!',
                  },
                ]}>
                <InputNumber placeholder="Tốc độ" min={0} style={{ width: '100%' }} />
              </FormItem>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Địa chỉ"
                name="address"
                rules={[
                  {
                    required: true,
                    message: 'Nhập địa chỉ hoặc chọn vị trí trên bản đồ!',
                  },
                ]}
                style={{ width: '100%' }}>
                <Input />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label="Kinh độ"
                name="longitude"
                rules={[
                  {
                    required: true,
                    message: 'Chọn vị trí trên bản đồ',
                  },
                ]}
                style={{ width: '100%' }}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Vĩ độ"
                name="latitude"
                rules={[
                  {
                    required: true,
                    message: 'Chọn vị trí trên bản đồ',
                  },
                ]}
                style={{ width: '100%' }}>
                <Input />
              </Form.Item>
            </Col>

            <Col span={24}>
              <FormItem label="Tìm trên bản đồ" name="depot">
                <TomtomSearch isEditMode onSelectAddress={this.onSelectAddress} />
              </FormItem>
            </Col>
          </Row>

          <FormItem style={{ float: 'right', marginTop: '10px' }}>
            <Button
              type="primary"
              htmlType="submit"
              shape="round"
              icon={<SaveOutlined />}
              style={{ marginRight: 8 }}>
              Lưu
            </Button>
            {/* <Button htmlType="reset" onClick={this.onReset} shape="round" icon={<ReloadOutlined />}>
              Nhập lại
            </Button> */}
          </FormItem>
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  configValues: state.configurationReducer.item,
});

const mapDispatchToProps = (dispatch) => ({
  getConfiguration: () => dispatch(configActions.getConfiguration()),
  updateConfiguration: (configurationId, payload, meta) =>
    dispatch(configActions.updateConfiguration({ configurationId, payload, meta })),
  setTomtomSearchAddress: (payload) => dispatch(tomtomActions.setMapAddress(payload)),
  setTomtomSearchLatitude: (payload) => dispatch(tomtomActions.setMapLatitude(payload)),
  setTomtomSearchLongitude: (payload) => dispatch(tomtomActions.setMapLongitude(payload)),
  clearTomtomStore: () => dispatch(tomtomActions.clearStore()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WithConfig('configuration')(WithLoading(ConfigurationPage)));
