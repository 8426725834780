import { Col, Row, notification } from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Card from '../../components/Card';
import ROUTER from '../../config/router';
import { actions as driverActions } from '../../redux/DriverRedux';
import { isBelowBreakpoint } from '../../utils/window';
import DriverForm from './components/Form';

class EditDriver extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    const {
      getDriverById,
      match: {
        params: { driverInfoId },
      },
    } = this.props;
    getDriverById(driverInfoId);
  };

  onSubmit = (values) => {
    const {
      updateDriver,
      getDriverById,
      history,
      match: {
        params: { driverInfoId },
      },
    } = this.props;
    const {
      cost_per_hour,
      cost_per_hour_overtime,
      cost_per_km,
      date_of_birth,
      email,
      gender,
      identity_card,
      name,
      note,
      phone_number,
      speed,
      status,
      workTime,
      default_vehicle_code,
      start_locationAddress,
      start_latitude,
      start_longitude,
      finish_locationAddress,
      finish_latitude,
      finish_longitude,
    } = values;

    const driverInfoPayload = {
      cost_per_hour,
      cost_per_hour_overtime,
      cost_per_km,
      date_of_birth: date_of_birth
        ? moment(date_of_birth).format('YYYY-MM-DDTHH:00:00[Z]')
        : undefined,
      email,
      finish_location: {
        address: finish_locationAddress,
        latitude: parseFloat(finish_latitude),
        longitude: parseFloat(finish_longitude),
      },
      gender,
      identity_card,
      name,
      note,
      phone_number,
      speed,
      start_location: {
        address: start_locationAddress,
        latitude: parseFloat(start_latitude),
        longitude: parseFloat(start_longitude),
      },
      status,
      work_time_from: workTime && workTime[0] ? moment(workTime[0]).format('HH:mm') : '',
      work_time_to: workTime && workTime[1] ? moment(workTime[1]).format('HH:mm') : '',
      default_vehicle_code,
    };

    updateDriver(driverInfoId, driverInfoPayload, {
      onSuccess: () => {
        notification.open({
          message: 'Sửa thành công',
          type: 'success',
        });
        getDriverById(driverInfoId);
        history.push(ROUTER.DRIVER.INDEX);
      },
      onError: (err) => {
        if (err.status === 466) {
          notification.error({
            message: `${'Trùng số điện thoại!'}`,
          });
        } else {
          notification.error({
            message: `${'Thêm lái xe thất bại!'}`,
          });
        }
      },
    });
  };

  render() {
    const {
      code,
      cost_per_hour,
      cost_per_hour_overtime,
      cost_per_km,
      email,
      gender,
      identity_card,
      name,
      note,
      phone_number,
      speed,
      status,
      start_location,
      finish_location,
      date_of_birth,
      work_time_from,
      work_time_to,
      default_vehicle_code,
    } = this.props.currentDriverData;

    const defaultValue = {
      code,
      cost_per_hour,
      cost_per_hour_overtime,
      cost_per_km,
      email,
      gender,
      identity_card,
      name,
      note,
      phone_number,
      speed,
      status,
      start_location,
      start_locationAddress: start_location?.address,
      start_latitude: start_location?.latitude,
      start_longitude: start_location?.longitude,
      finish_location,
      finish_locationAddress: finish_location?.address,
      finish_latitude: finish_location?.latitude,
      finish_longitude: finish_location?.longitude,
      date_of_birth: date_of_birth ? moment(date_of_birth, 'YYYY-MM-DDTHH:00:00[Z]') : undefined,
      workTime:
        work_time_from && work_time_to
          ? [moment(work_time_from, 'HH:mm'), moment(work_time_to, 'HH:mm')]
          : ['', ''],
      default_vehicle_code,
    };

    return (
      <div>
        <Row type="flex" justify="center">
          <Col span={24} lg={24}>
            <Card hoverable={false} bordered={!isBelowBreakpoint()}>
              <DriverForm onSubmit={this.onSubmit} defaultValue={defaultValue} isEditMode />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ driverReducer }) => ({
  currentDriverData: driverReducer.currentDriver,
});

const mapDispatchToProps = (dispatch) => ({
  getDriverById: (driverId) => dispatch(driverActions.getDriverById(driverId)),
  updateDriver: (driverId, payload, meta) =>
    dispatch(driverActions.updateDriver(driverId, payload, meta)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditDriver));
