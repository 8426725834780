import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { PREFIX, typesWithPrefix } from './config';
import { API_URLS } from '../config/api';
import { apiCallPromise } from '../utils/api';

const _types = typesWithPrefix(PREFIX.CATEGORY);
const types = {
  GET_CATEGORIES: _types('GET_CATEGORIES'),
  ADD_CATEGORY: _types('ADD_CATEGORY'),
  UPDATE_CATEGORY: _types('UPDATE_CATEGORY'),
  GET_CATEGORY_BY_CODE: _types('GET_CATEGORY_BY_CODE'),
  UPDATE_CATEGORY_STATUS: _types('UPDATE_CATEGORY_STATUS'),
  GET_ALL_CATEGORIES: _types('GET_ALL_CATEGORIES'),
};

const initialState = {
  data: null,
  allItems: null,
  allDataObject: null,
  treeData: null,
  isFetching: false,
  didInvalidate: true,
  currentData: {},
  meta: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
};

const thunkActions = {
  getCategories: createAsyncThunk(types.GET_CATEGORIES, async (query) => {
    const params = {
      page: query.page,
      page_size: query.page_size,
      status: query.status,
      level: query.level,
    };

    const api = API_URLS.CATEGORIES.getCategories(params);

    const { response } = await apiCallPromise(api);

    return response.data;
  }),
  getAllCategories: createAsyncThunk(types.GET_ALL_CATEGORIES, async () => {
    const params = {
      page: -1,
      page_size: -1,
    };

    const api = API_URLS.CATEGORIES.getCategories(params);

    const { response } = await apiCallPromise(api);

    return response.data;
  }),

  addCategory: createAsyncThunk(
    types.ADD_CATEGORY,
    async ({ payload, meta }, { fulfillWithValue, rejectWithValue }) => {
      const api = API_URLS.CATEGORIES.insertCategory(payload);

      const { response, error } = await apiCallPromise(api);

      if (!error && (response.status === 200 || response.status === 201)) {
        if (meta && meta.onSuccess) {
          meta.onSuccess();
          fulfillWithValue({ response });
        }
      } else {
        if (meta && meta.onError) meta.onError(error);
        rejectWithValue({ error });
      }
    },
  ),

  getCategoryById: createAsyncThunk(types.GET_CATEGORY_BY_CODE, async (code) => {
    if (!code) {
      return;
    }

    const api = API_URLS.CATEGORIES.getCategoryById(code);
    const { response } = await apiCallPromise(api);
    return response.data.data;
  }),

  updateCategory: createAsyncThunk(types.UPDATE_CATEGORY, async ({ payload, meta }) => {
    const api = API_URLS.CATEGORIES.updateCategory(payload.code, payload.payload);

    const { response, error } = await apiCallPromise(api);

    if (!error && response.status === 200 && response.data.success === true) {
      if (meta && meta.onSuccess) meta.onSuccess();
      return { data: response.data.data };
    } else {
      if (meta && meta.onError) meta.onError(error);
      rejectWithValue();
    }

    return response.data;
  }),

  changeState: createAsyncThunk(types.UPDATE_CATEGORY_STATUS, async ({ payload, meta }) => {
    const api = API_URLS.CATEGORIES.changeStatusCategory(payload.code, payload.payload);

    const { response, error } = await apiCallPromise(api);
    if (meta) {
      if (!error && response.status === 200) {
        meta.onSuccess();
      } else {
        meta.onError();
      }
    }

    return response.data;
  }),
};

const categorySlice = createSlice({
  name: 'categorySlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(thunkActions.getCategories.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(thunkActions.getCategories.fulfilled, (state, { payload }) => {
        const { data, page, page_size, total } = payload;
        state.data = data;
        state.didInvalidate = false;
        state.isFetching = false;
        state.meta.page = page;
        state.meta.pageSize = page_size;
        state.meta.total = total;
      })
      .addCase(thunkActions.getCategories.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(thunkActions.getAllCategories.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(thunkActions.getAllCategories.fulfilled, (state, { payload }) => {
        const { data, total } = payload;
        state.allItems = data;
        const { allDataObject, treeData } = convertCategoriesToTreeData(data);
        state.allDataObject = allDataObject;
        state.treeData = treeData;
        state.didInvalidate = false;
        state.isFetching = false;
        state.meta.total = total;
      })
      .addCase(thunkActions.getAllCategories.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(thunkActions.getCategoryById.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(thunkActions.getCategoryById.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.currentData = payload;
      })
      .addCase(thunkActions.getCategoryById.rejected, (state) => {
        state.isFetching = false;
      })
      .addMatcher(
        isAnyOf(
          thunkActions.addCategory.fulfilled,
          thunkActions.updateCategory.fulfilled,
          thunkActions.changeState.fulfilled,
        ),
        (state) => {
          state.isFetching = false;
          state.didInvalidate = false;
        },
      )
      .addMatcher(
        isAnyOf(
          thunkActions.addCategory.pending,
          thunkActions.updateCategory.pending,
          thunkActions.changeState.pending,
        ),
        (state) => {
          state.isFetching = true;
        },
      )
      .addMatcher(
        isAnyOf(
          thunkActions.addCategory.rejected,
          thunkActions.updateCategory.rejected,
          thunkActions.changeState.rejected,
        ),
        (state) => {
          state.isFetching = false;
          state.didInvalidate = true;
        },
      );
  },
});

export const actions = { ...categorySlice.actions, ...thunkActions };
export const { reducer } = categorySlice;

const convertCategoriesToTreeData = (categories) => {
  if (!categories?.length) return { treeData: [], allDataObject: {} };

  const allDataObject = {};
  const firstLevelItems = [];
  categories.forEach((item) => {
    allDataObject[item.code] = allDataObject[item.code]
      ? Object.assign(allDataObject[item.code], item)
      : Object.assign(item, { children: [] });

    if (item.level === 0 || !item.parent_code) {
      firstLevelItems.push(allDataObject[item.code]);
    } else {
      const parentCode = item.parent_code;
      if (allDataObject[parentCode]) {
        allDataObject[parentCode].children = allDataObject[parentCode].children
          ? allDataObject[parentCode].children
          : [];
      } else {
        allDataObject[parentCode] = { children: [] };
      }
      allDataObject[parentCode].children.push(allDataObject[item.code]);
    }
  });
  return { treeData: firstLevelItems, allDataObject };
};
