import { notification } from 'antd';
import _ from 'lodash';

export const downloadFile = (absoluteUrl) => {
  if (!absoluteUrl) {
    notification.warning({
      message: 'Không có file download',
    });
    return;
  }
  let link = document.createElement('a');
  link.href = absoluteUrl;
  link.download = absoluteUrl;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  link = null;
};

/* http://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript */
export const bytesToSize = (bytes) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  return Math.round(bytes / Math.pow(1024, i), 2) + sizes[i];
};

/* Validation */
export const fileSizeLessThan = (size, message) => (value) => ({
  isValid:
    (value instanceof Array || value instanceof Object) &&
    value[0] instanceof File &&
    value[0].size <= size,
  message: `${message} ${bytesToSize(size)}`,
});

/* Validation */
export const fileType = (types, message) => (value) => ({
  isValid:
    (value instanceof Array || value instanceof Object) &&
    value[0] instanceof File &&
    _.includes(types, value[0].type),
  message,
});

/* Validation */
export const fileExt = (exts, message) => (value) => ({
  isValid:
    (value instanceof Array || value instanceof Object) &&
    value[0] instanceof File &&
    _.includes(exts, value[0].name.substring(value[0].name.indexOf('.') + 1).toLowerCase()),
  message,
});
