import { Select } from 'antd';
import lodash from 'lodash';
import React, { Component } from 'react';

const { Option } = Select;
const children = [];
for (let i = 10; i < 36; i++) {
  children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}

class SelectFormItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.selectedValue || undefined,
      selectedValue: props.selectedValue || undefined,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { selectedValue } = nextProps;
    const { selectedValue: prevSelectedValue } = prevState;
    if (!lodash.isEqual(prevSelectedValue, selectedValue)) {
      return { value: selectedValue || undefined, selectedValue };
    }

    return null;
  }

  handleChange = (value) => {
    const { mode } = this.props;
    this.setState({
      value,
    });
    const { onChange } = this.props;
    onChange(
      mode === 'multiple'
        ? this.convertToValueArray(value)
        : mode === 'object'
        ? value
        : value?.key || undefined,
    );
  };

  convertToValueArray = (value) => (value && value.length ? value.map((item) => item.key) : []);

  render() {
    const { value } = this.state;
    const {
      dataSource,
      placeholder,
      mode,
      // excludedValue,
      disabled,
      style,
      allowClear,
    } = this.props;
    const options = dataSource || [];
    const placeholderText = placeholder || 'Tìm kiếm';
    return (
      <Select
        showSearch
        allowClear={allowClear}
        style={style ? Object.assign({ width: '100%' }, style) : { width: '100%' }}
        disabled={disabled}
        mode={mode}
        labelInValue
        value={value}
        optionFilterProp="children"
        placeholder={placeholderText}
        // filterOption={(input, option) =>
        //   excludedValue &&
        //   !excludedValue.includes(option.key) &&
        //   option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        // }
        onChange={this.handleChange}>
        {options.map((d) => (
          <Option key={d.code} value={d.code}>
            {d.name}
          </Option>
        ))}
      </Select>
    );
  }
}

export default SelectFormItem;
