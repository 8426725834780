import lodash from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { actions as sampleRouteActions } from '../../redux/SampleRouteRedux';
import SelectSearch from './Select';

const { getAllSampleRoutes } = sampleRouteActions;

class SampleRouteSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prevSelectedValue: [],
      defaultValue: [],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.allCompanies && nextProps.allCompanies.length) {
      if (!lodash.isEqual(nextProps.selectedValue, prevState.prevSelectedValue)) {
        const nextState = { prevSelectedValue: nextProps.selectedValue };
        if (nextProps.selectedValue && nextProps.selectedValue.length) {
          const { selectedValue } = nextProps;
          if (typeof selectedValue === 'string') {
            nextState.defaultValue = SampleRouteSearch.getSampleRouteByCode(
              [selectedValue],
              nextProps,
            );
          } else if (typeof selectedValue === 'object') {
            if (Array.isArray(selectedValue)) {
              if (typeof selectedValue[0] === 'string') {
                nextState.defaultValue = SampleRouteSearch.getSampleRouteByCode(
                  selectedValue,
                  nextProps,
                );
              } else if (typeof selectedValue[0] === 'object')
                nextState.defaultValue = selectedValue;
            } else nextState.defaultValue = selectedValue;
          }
        } else nextState.defaultValue = [];

        return nextState;
      }
    }
    return null;
  }

  componentDidMount() {
    this.props.getAllSampleRoutes();
  }

  static getSampleRouteByCode = (codes, props) => {
    if (codes && codes.length) {
      const { allSampleRoutes } = props;
      if (!allSampleRoutes.length) return [];

      const defaultValue = [];
      codes.forEach((code) => {
        const sampleRoute_code = code.trim();
        if (!sampleRoute_code) return;
        const foundSampleRoute = allSampleRoutes.find((p) => p.code === sampleRoute_code);
        const sampleRoute = foundSampleRoute
          ? {
              key: sampleRoute_code,
              label: `${foundSampleRoute.name}`,
            }
          : { key: sampleRoute_code, label: `[Not found] ${sampleRoute_code}` };
        defaultValue.push(sampleRoute);
      });
      return defaultValue;
    }
    return [];
  };

  render() {
    const { allSampleRoutes, onChange } = this.props;
    return (
      <SelectSearch
        {...this.props}
        onChange={onChange}
        selectedValue={this.state.defaultValue}
        dataSource={allSampleRoutes}
        placeholder={this.props.placeholder || 'Chọn lịch mẫu'}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  allSampleRoutes: state.sampleRouteReducer.allItems,
});

const mapDispatchToProps = (dispatch) => ({
  getAllSampleRoutes: () => dispatch(getAllSampleRoutes()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SampleRouteSearch);
