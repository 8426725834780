import produce from 'immer';
import moment from 'moment';

import { API_URLS } from '../config/api';
import { apiCall } from '../utils/api';
import { isCallingApi, isSuccessfulApiCall, isFailedApiCall } from './actionDedicate';
import { PREFIX, typesWithPrefix } from './config';
const _types = typesWithPrefix(PREFIX.VEHICLE_PART);
const TYPE = {
  INVALIDATE_VEHICLE_PARTS: _types('INVALIDATE_VEHICLE_PARTS'),
  GET_VEHICLE_PARTS: _types('GET_VEHICLE_PARTS'),
  GET_ALL_VEHICLE_PARTS: _types('GET_ALL_VEHICLE_PARTS'),
  UPDATE_VEHICLE_PART: _types('UPDATE_VEHICLE_PART'),
  DELETE_VEHICLE_PART: _types('DELETE_VEHICLE_PART'),
  GET_VEHICLE_PART_BY_ID: _types('GET_VEHICLE_PART_BY_ID'),
  GET_VEHICLE_PARTS_CHANGE_PAGE: _types('GET_VEHICLE_PARTS_CHANGE_PAGE'),
};
export const actions = {
  //get list vehicle part
  getVehiclePartIfNeed: (filterOption) => (dispatch, getState) => {
    const state = getState();
    const isFetching = state.vehiclePartReducer.isFetching;
    const didInvalidate = state.vehiclePartReducer.didInvalidate;
    const query = state.vehiclePartReducer.query;
    const isQueryChanged = query.search !== filterOption.search;
    if ((!isFetching && didInvalidate) || (!isFetching && isQueryChanged)) {
      dispatch(actions.getVehiclePart(filterOption));
    }
  },

  getVehiclePart: (query) => async (dispatch) => {
    const params = {
      page: query.page,
      page_size: query.page_size,
      search: query.search,
    };
    const api = API_URLS.VEHICLE_PART.getVehicleParts(params);
    dispatch(actions.gettingVehiclePart());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(
        actions.getVehiclePartSuccess({
          items: response.data.data,
          meta: {
            page: response.data.page,
            page_size: response.data.page_size,
            total: response.data.total,
          },
          query: {
            search: params.search,
          },
        }),
      );
    } else {
      dispatch(actions.getVehiclePartFailure(params));
    }
  },
  gettingVehiclePart: () => ({
    type: TYPE.GET_VEHICLE_PARTS,
    meta: { prefix: [PREFIX.VEHICLE_PART, PREFIX.API_CALLING] },
  }),

  getVehiclePartSuccess: (payload) => ({
    type: TYPE.GET_VEHICLE_PARTS,
    payload,
    meta: {
      prefix: [PREFIX.VEHICLE_PART, PREFIX.API_CALLED_SUCCESS],
    },
  }),

  getVehiclePartFailure: (query) => ({
    type: TYPE.GET_VEHICLE_PARTS,
    query,
    meta: {
      prefix: [PREFIX.VEHICLE_PART, PREFIX.API_CALLED_FAILURE],
    },
  }),

  invalidateVehiclePart: () => ({
    type: TYPE.INVALIDATE_VEHICLE_PARTS,
    meta: {
      prefix: [PREFIX.VEHICLE_PART, PREFIX.API_CALLED_SUCCESS],
    },
  }),

  // get vehicle part by id
  getVehiclePartById: (vehiclePartId, meta) => async (dispatch) => {
    dispatch(actions.gettingVehiclePartDetail());
    const api = API_URLS.VEHICLE_PART.getVehiclePartById(vehiclePartId);
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(actions.getVehiclePartDetailSuccess(response.data.data));
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
    } else {
      dispatch(actions.getVehiclePartDetailFailure());
      if (meta && meta.onError) {
        meta.onError(error);
      }
    }
  },
  gettingVehiclePartDetail: () => ({
    type: TYPE.GET_VEHICLE_PART_BY_ID,
    meta: { prefix: [PREFIX.VEHICLE_PART, PREFIX.API_CALLING] },
  }),
  getVehiclePartDetailSuccess: (payload) => ({
    type: TYPE.GET_VEHICLE_PART_BY_ID,
    meta: { prefix: [PREFIX.VEHICLE_PART, PREFIX.API_CALLED_SUCCESS] },
    payload,
  }),
  getVehiclePartDetailFailure: () => ({
    type: TYPE.GET_VEHICLE_PART_BY_ID,
    meta: { prefix: [PREFIX.VEHICLE_PART, PREFIX.API_CALLED_FAILURE] },
  }),

  //GET ALL VEHICLE PARTS
  getAllVehiclePart: () => async (dispatch) => {
    const params = {
      page: -1,
      page_size: -1,
    };
    const api = API_URLS.VEHICLE_PART.getVehicleParts(params);
    dispatch(actions.gettingAllVehiclePart());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(
        actions.getAllVehiclePartSuccess({
          items: response.data.data,
        }),
      );
    } else {
      dispatch(actions.getAllVehiclePartFailure(params));
    }
  },
  gettingAllVehiclePart: () => ({
    type: TYPE.GET_ALL_VEHICLE_PARTS,
    meta: { prefix: [PREFIX.VEHICLE_PART, PREFIX.API_CALLING] },
  }),

  getAllVehiclePartSuccess: (payload) => ({
    type: TYPE.GET_ALL_VEHICLE_PARTS,
    payload,
    meta: {
      prefix: [PREFIX.VEHICLE_PART, PREFIX.API_CALLED_SUCCESS],
    },
  }),

  getAllVehiclePartFailure: (query) => ({
    type: TYPE.GET_ALL_VEHICLE_PARTS,
    query,
    meta: {
      prefix: [PREFIX.VEHICLE_PART, PREFIX.API_CALLED_FAILURE],
    },
  }),

  // update vehicle part
  updatingVehiclePart: () => ({
    type: TYPE.UPDATE_VEHICLE_PART,
    meta: { prefix: [PREFIX.VEHICLE_PART, PREFIX.API_CALLING] },
  }),
  updateVehiclePartSuccess: () => ({
    type: TYPE.UPDATE_VEHICLE_PART,
    meta: {
      prefix: [PREFIX.VEHICLE_PART, PREFIX.API_CALLED_SUCCESS],
    },
  }),
  updateVehiclePartFailure: () => ({
    type: TYPE.UPDATE_VEHICLE_PART,
    meta: {
      prefix: [PREFIX.VEHICLE_PART, PREFIX.API_CALLED_FAILURE],
    },
  }),
  updateVehiclePart: (vehiclePartId, payload, meta) => async (dispatch) => {
    const api = API_URLS.VEHICLE_PART.updateVehiclePart(vehiclePartId, payload);
    dispatch(actions.updatingVehiclePart());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(actions.updateVehiclePartSuccess(response.data));
      dispatch(actions.getVehiclePart({ page: 1, page_size: 10 }));
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
    } else {
      dispatch(actions.updateVehiclePartFailure(error));
      if (meta && meta.onError) {
        meta.onError(error);
      }
    }
  },

  // Delete vehicle part
  deletingVehiclePart: () => ({
    type: TYPE.DELETE_VEHICLE_PART,
    meta: { prefix: [PREFIX.VEHICLE_PART, PREFIX.API_CALLING] },
  }),

  deleteVehiclePartSuccess: () => ({
    type: TYPE.DELETE_VEHICLE_PART,
    meta: {
      prefix: [PREFIX.VEHICLE_PART, PREFIX.API_CALLED_SUCCESS],
    },
  }),

  deleteVehiclePartFailure: () => ({
    type: TYPE.DELETE_VEHICLE_PART,
    meta: {
      prefix: [PREFIX.VEHICLE_PART, PREFIX.API_CALLED_FAILURE],
    },
  }),

  deleteVehiclePart: (vehiclePartId, meta) => async (dispatch) => {
    const api = API_URLS.VEHICLE_PART.deleteVehiclePart(vehiclePartId);
    dispatch(actions.deletingVehiclePart());
    const { response, error } = await apiCall({ ...api });
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(actions.deleteVehiclePartSuccess(response.data));
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
    } else {
      dispatch(actions.deleteVehiclePartFailure(error));
      if (meta && meta.onError) {
        meta.onError(error);
      }
    }
  },

  // insert vehicle part
  insertVehiclePart: (payload, meta) => async (dispatch) => {
    const api = API_URLS.VEHICLE_PART.insertVehiclePart(payload);
    const params = {
      page: 1,
      page_size: 10,
      search: '',
    };
    const { response, error } = await apiCall(api);
    if (!error && (response.status === 200 || response.status === 201)) {
      if (meta && meta.onSuccess) {
        meta.onSuccess();
        dispatch(actions.getVehiclePart(params));
      }
    } else if (meta && meta.onError) {
      meta.onError(error);
    }
    return { response, error };
  },

  changePage: (page, page_size, total) => ({
    type: TYPE.GET_VEHICLE_PARTS_CHANGE_PAGE,
    payload: {
      page,
      page_size,
      total,
    },
    meta: {
      prefix: [PREFIX.VEHICLE_PART],
    },
  }),
};

const initialState = {
  isFetching: false,
  didInvalidate: true,
  lastUpdated: moment().unix(),
  currentVehiclePart: {},
  allItems: [],
  items: [],
  query: {},
  meta: {
    total: 0,
    page: 1,
    page_size: 10,
  },
};

export const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case TYPE.GET_VEHICLE_PARTS:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          const { items, meta, query } = action.payload;
          draft.isFetching = false;
          draft.didInvalidate = false;
          draft.lastUpdated = moment.unix();
          draft.items = items;
          draft.meta = meta;
          draft.query = query;
        } else if (isFailedApiCall(action)) {
          draft.isFetching = false;
          draft.didInvalidate = false;
        }
        break;
      case TYPE.GET_VEHICLE_PART_BY_ID:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          draft.currentVehiclePart = action.payload;
          draft.isFetching = false;
        } else if (isFailedApiCall(action)) {
          draft.isFetching = false;
          draft.didInvalidate = false;
        }
        break;
      case TYPE.INVALIDATE_VEHICLE_PARTS:
        draft.didInvalidate = true;
        break;
      case TYPE.GET_VEHICLE_PARTS_CHANGE_PAGE:
        draft.meta = {
          page: action.payload.page,
          page_size: action.payload.page_size,
          total: action.payload.total,
        };
        break;
      case TYPE.GET_ALL_VEHICLE_PARTS:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          const { items } = action.payload;
          draft.isFetching = false;
          draft.didInvalidate = false;
          draft.allItems = items;
        } else if (isFailedApiCall(action)) {
          draft.isFetching = false;
          draft.didInvalidate = false;
        }
        break;
      case TYPE.UPDATE_VEHICLE_PART:
      case TYPE.DELETE_VEHICLE_PART:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        }
        if (isSuccessfulApiCall(action)) {
          draft.isFetching = false;
          draft.didInvalidate = true;
          draft.lastUpdated = moment.unix();
        }
        if (isFailedApiCall(action)) {
          draft.isFetching = false;
          draft.didInvalidate = false;
        }
        break;
      default:
        return draft;
    }
  });
