import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { API_URLS } from '../config/api';
import { apiCallPromise } from '../utils/api';
import { PREFIX, typesWithPrefix } from './config';

const _types = typesWithPrefix(PREFIX.STORAGE);
const types = {
  GET_STORAGES: _types('GET_STORAGES'),
  ADD_STORAGE: _types('ADD_STORAGE'),
  UPDATE_STORAGE: _types('UPDATE_STORAGE'),
  GET_STORAGE_BY_CODE: _types('GET_STORAGE_BY_CODE'),
  UPDATE_STORAGE_STATUS: _types('UPDATE_STORAGE_STATUS'),
};

const initialState = {
  Storage: {
    data: null,
    isFetching: false,
    didInvalidate: true,
    currentData: {},
    meta: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
  },
};
const thunkActions = {
  getStorage: createAsyncThunk(types.GET_STORAGES, async (query) => {
    const params = {
      page: query.page,
      page_size: query.page_size,
      status: query.status,
      level: query.level,
      company_code: query.company_code,
    };
    const api = API_URLS.STORAGE.getStorage(params);

    const { response } = await apiCallPromise(api);

    return response.data;
  }),

  addStorage: createAsyncThunk(types.ADD_STORAGE, async ({ payload, meta }) => {
    const api = API_URLS.STORAGE.insertStorage(payload);
    const { response, error } = await apiCallPromise(api);

    if (!error && (response.status === 200 || response.status === 201)) {
      if (meta && meta.onSuccess) {
        meta.onSuccess();
        fulfillWithValue({ response });
      }
    } else {
      if (meta && meta.onError) meta.onError(error);
      rejectWithValue({ error });
    }
  }),

  getStorageById: createAsyncThunk(types.GET_STORAGE_BY_CODE, async (storageId) => {
    const api = API_URLS.STORAGE.getStorageById(storageId);
    const { response } = await apiCallPromise(api);

    return response.data.data;
  }),

  updateStorage: createAsyncThunk(types.UPDATE_STORAGE, async ({ payload, meta }) => {
    const api = API_URLS.STORAGE.updateStorage(payload.code, payload.payload);

    const { response, error } = await apiCallPromise(api);

    if (meta) {
      if (!error && response.status === 200) {
        meta.onSuccess();
      } else {
        meta.onError();
      }
    }

    return response.data;
  }),

  changeState: createAsyncThunk(types.UPDATE_STORAGE_STATUS, async ({ payload, meta }) => {
    const api = API_URLS.STORAGE.changeStatusStorage(payload.code, payload.payload);

    const { response, error } = await apiCallPromise(api);
    if (meta) {
      if (!error && response.status === 200) {
        meta.onSuccess();
      } else {
        meta.onError();
      }
    }

    return response.data;
  }),
};

const storageSlice = createSlice({
  name: 'storageSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(thunkActions.getStorage.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(thunkActions.getStorage.fulfilled, (state, { payload }) => {
        const { data, page, page_size, total } = payload;
        state.Storage.data = data;
        state.Storage.didInvalidate = true;
        state.Storage.isFetching = false;
        state.Storage.meta.page = page;
        state.Storage.meta.pageSize = page_size;
        state.Storage.meta.total = total;
      })
      .addCase(thunkActions.getStorage.rejected, (state) => {
        state.Storage.isFetching = false;
      })
      .addCase(thunkActions.getStorageById.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(thunkActions.getStorageById.fulfilled, (state, { payload }) => {
        state.Storage.isFetching = false;
        state.Storage.currentData = payload;
      })
      .addCase(thunkActions.getStorageById.rejected, (state) => {
        state.Storage.isFetching = false;
      })
      .addMatcher(
        isAnyOf(
          thunkActions.addStorage.fulfilled,
          thunkActions.updateStorage.fulfilled,
          thunkActions.changeState.fulfilled,
        ),
        (state) => {
          state.Storage.isFetching = false;
          state.Storage.didInvalidate = true;
        },
      )
      .addMatcher(
        isAnyOf(
          thunkActions.addStorage.pending,
          thunkActions.updateStorage.pending,
          thunkActions.changeState.pending,
        ),
        (state) => {
          state.Storage.isFetching = true;
        },
      )
      .addMatcher(
        isAnyOf(
          thunkActions.addStorage.rejected,
          thunkActions.updateStorage.rejected,
          thunkActions.changeState.rejected,
        ),
        (state) => {
          state.Storage.isFetching = false;
          state.Storage.didInvalidate = true;
        },
      );
  },
});

export const actions = { ...storageSlice.actions, ...thunkActions };
export const { reducer } = storageSlice;
