import { LoginOutlined } from '@ant-design/icons';
import { Form, Button, Input, Checkbox } from 'antd';
import React from 'react';

import WithLocale from '../../../../hoc/WithLocale';
import './index.css';

// const handleSubmit = (event, form, onSubmit) => {
//   event.preventDefault();
//   form.validateFields((error, values) => {
//     if (!error) {
//       onSubmit(values);
//     }
//   });
// };

const LoginForm = ({ onSubmit, locale, error, removeError }) => {
  const { trans } = locale;
  const [form] = Form.useForm();

  const onFinish = (values) => onSubmit(values);
  React.useEffect(() => {
    form.setFieldsValue({
      username: '',
      password: '',
      remember: '',
    });
  }, []);
  return (
    <Form form={form} onFinish={onFinish} initialValues={{ remember: true }}>
      <Form.Item
        label={trans('Username')}
        validateStatus={error ? 'error' : undefined}
        help={error ? `${trans('These credential do not match our records')}!` : undefined}
        name="username"
        rules={[{ required: true, message: `${trans('Please input your Username')}!` }]}>
        <Input placeholder={`${trans('Enter your username')} ...`} onChange={() => removeError()} />
      </Form.Item>
      <br />
      <Form.Item
        label={trans('Password')}
        validateStatus={error ? 'error' : undefined || ''}
        name="password"
        rules={[{ required: true, message: `${trans('Please input your Password')}!` }]}>
        <Input type="password" placeholder={`${trans('Enter your password')} ...`} />
      </Form.Item>
      {/* <Form.Item name="remember" valuePropName="checked">
        <Checkbox>{trans('Remember me')}</Checkbox>
      </Form.Item> */}
      <br />
      <br />
      <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
        <LoginOutlined />
        {trans('Log In')}
      </Button>
      <br />
      <br />
      <br />
    </Form>
  );
};

export default WithLocale(LoginForm);
