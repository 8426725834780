import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Cropper from 'react-cropper';
import Dropzone from 'react-dropzone';

import bytesToSize from './util/bytesToSize';
import fileSizeLessThan from './util/fileSizeLessThan';
import fileType from './util/fileType';

import './style/index.css';

class DropNCrop extends Component {
  constructor(props) {
    super(props);

    this.onCrop = this.onCrop.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.value && this.cropperRef) {
      setTimeout(() => {
        this.onCrop();
      }, 0);
    }
  }

  onCrop() {
    const { value, onChange, outputWidth, outputHeight } = this.props;
    if (this.cropperRef?.getCroppedCanvas && this.cropperRef.getCroppedCanvas() != null) {
      onChange({
        ...value,
        result: this.cropperRef
          .getCroppedCanvas({
            width: outputWidth,
            height: outputHeight,
          })
          .toDataURL(value.filetype),
      });
    }
  }

  onDrop(files) {
    const { onChange, maxFileSize, allowedFileTypes } = this.props;
    const fileSizeValidation = fileSizeLessThan(maxFileSize, 'File ảnh phải nhỏ hơn')(files);
    const fileTypeValidation = fileType(allowedFileTypes, 'File ảnh không hỗ trợ')(files);

    if (fileSizeValidation.isValid && fileTypeValidation.isValid) {
      const reader = new FileReader();
      reader.onload = () => {
        onChange({
          src: reader.result,
          filename: files[0].name,
          filetype: files[0].type,
          result: reader.result,
          error: null,
        });
      };
      reader.readAsDataURL(files[0]);
    } else {
      onChange({
        // eslint-disable-next-line no-nested-ternary
        error: !fileTypeValidation.isValid
          ? fileTypeValidation.message
          : !fileSizeValidation.isValid
          ? fileSizeValidation.message
          : null, // TODO: Update error state to be an array to handle both messages if necessary
      });
    }
  }

  render() {
    const {
      canvasHeight,
      canvasWidth,
      className,
      cropperOptions,
      instructions,
      allowedFileTypes,
      maxFileSize,
      multiple,
      value,
    } = this.props;

    const dropNCropClasses = {
      'drop-n-crop': true,
      [`${className}`]: className,
    };

    const acceptedFileTypes = allowedFileTypes
      .map((mimeType) => `.${mimeType.split('/')[1]}`)
      .join(', ');

    return (
      <div className={classNames(dropNCropClasses)}>
        {value && value.src ? (
          <Cropper
            ref={(input) => {
              this.cropperRef = input;
            }}
            src={value && value.src}
            style={{
              height: canvasHeight,
              width: canvasWidth,
            }}
            // Only use the cropend method to reduce the callback/setState lag while cropping
            cropend={this.onCrop}
            zoom={this.onCrop}
            {...cropperOptions}
          />
        ) : (
          <Dropzone
            className="dropzone"
            onDrop={this.onDrop}
            multiple={multiple}
            style={{
              height: canvasHeight,
              width: canvasWidth,
            }}>
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div
                {...getRootProps()}
                className={classNames('dropzone', { 'dropzone--isActive': isDragActive })}>
                <input {...getInputProps()} accept={acceptedFileTypes} />
                {!instructions ? (
                  <div className="dropzone-instructions">
                    <div className="dropzone-instructions--main">
                      Nhấn vào đây để chọn hoặc kéo thả ảnh của bạn
                    </div>
                    <hr />
                    <div className="dropzone-instructions--sub">
                      {`${'Định dạng hỗ trợ'}: `}
                      {acceptedFileTypes}
                    </div>
                    <div className="dropzone-instructions--sub">
                      Dung lượng tối đa
                      {': '}
                      {bytesToSize(maxFileSize)}
                    </div>
                  </div>
                ) : (
                  instructions
                )}
              </div>
            )}
          </Dropzone>
        )}
      </div>
    );
  }
}

/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
DropNCrop.propTypes = {
  allowedFileTypes: PropTypes.array,
  canvasWidth: PropTypes.string,
  canvasHeight: PropTypes.string,
  outputWidth: PropTypes.number,
  outputHeight: PropTypes.number,
  className: PropTypes.string,
  cropperOptions: PropTypes.object,
  instructions: PropTypes.node,
  maxFileSize: PropTypes.number,
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  value: PropTypes.shape({
    result: PropTypes.string,
    filename: PropTypes.string,
    filetype: PropTypes.string,
    src: PropTypes.string,
    error: PropTypes.string,
  }),
};

DropNCrop.defaultProps = {
  allowedFileTypes: ['image/jpeg', 'image/jpg', 'image/png'],
  canvasHeight: '360px',
  canvasWidth: '100%',
  multiple: false,
  cropperOptions: {
    guides: false,
    viewMode: 3,
    autoCropArea: 1,
  },
  maxFileSize: 1048576,
};

export default DropNCrop;
