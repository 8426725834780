import { InputNumber } from 'antd';
import React from 'react';

const selectInputOnFocus = (event) => event.target.select();

const VNDInput = (props) => (
  <InputNumber
    placeholder="Nhập số tiền"
    min={0}
    step={1000}
    style={{ width: '100%' }}
    prefix="₫"
    onFocus={selectInputOnFocus}
    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
    parser={(value) => value.replace(/(\.*)/g, '').trim()}
    {...props}
  />
);

export default VNDInput;
