import { PlusOutlined, UploadOutlined, SwapOutlined } from '@ant-design/icons';
import { Divider, Row, Button, Col, message, notification } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as XLSX from 'xlsx';

import ChangeCompanyModal from './components/ChangeCompanyModal';
import Filter from './components/Filter';
import OrderList from './components/List';
import ROUTER from '../../config/router';
import WithConfig from '../../hoc/WithConfig';
import WithLoading from '../../hoc/WithLoading';
import { actions as orderActions } from '../../redux/OrderRedux';
import { apiCall } from '../../utils/api';
import OrderImportModal from '../Routing/components/OrderImportModal';

// const SheetJSFT = [
//   'xlsx',
//   'xlsb',
//   'xlsm',
//   'xls',
//   'xml',
//   'csv',
//   'txt',
//   'ods',
//   'fods',
//   'uos',
//   'sylk',
//   'dif',
//   'dbf',
//   'prn',
//   'qpw',
//   '123',
//   'wb*',
//   'wq*',
//   'html',
//   'htm',
// ]
//   .map(function (x) {
//     return `.${x}`;
//   })
//   .join(',');

class Order extends Component {
  state = {
    dataFilter: {
      page: 1,
      page_size: 20,
      search: '',
      status: '',
      vehicle_type: '',
      delivery_date: '',
    },
    dataFromFile: [],
    orderList: [],
    loading: false,
    visible: false,
    disabledFindLocationButton: false,
    disabledgetOrderListButton: false,
    disabledgetDetailButton: false,
    mode: 'import',
    visibleOrderModal: false,
    selectedRows: [],
  };

  static getDerivedStateFromProps(props, state) {
    const { search, status, vehicle_type, delivery_date, page, page_size } = state.dataFilter;

    props.getOrdersIfNeed({
      page,
      page_size,
      search,
      status,
      vehicle_type,
      delivery_date,
    });

    return null;
  }

  componentDidMount() {
    const { userRole, history } = this.props;
    if (userRole === 'super-admin') history.push('/');
  }

  onPageChange = (page, page_size) => {
    const { dataFilter } = this.state;

    const { invalidateOrders } = this.props;
    dataFilter.page = page;
    dataFilter.page_size = page_size;

    this.setState({
      dataFilter,
    });
    invalidateOrders();
  };

  onSelectedRowKeys = (valueRows) => {
    this.setState({ selectedRows: valueRows });
  };

  showModal = () => this.setState({ visible: true, mode: 'import' });

  showModal2 = () => this.setState({ visible: true, mode: 'customerOrder' });

  showModal3 = () => this.setState({ visible: true, mode: 'agencyOrder' });

  onSearch = async () => {
    this.setState({ disabledFindLocationButton: true, loading: true });
    const orders =
      this.props.mode === 'customerOrder' ? this.state.dataFromFile : this.state.orderList;
    for (let i = 0; i < orders.length; i++) {
      const APIKEY = process.env.REACT_APP_TOM_TOM_API_KEY;
      const baseurl = `https://api.tomtom.com/search/2/search/${
        this.props.mode === 'customerOrder' ? orders[i]['Địa chỉ'] : orders[i].address
      }.json`;
      const searchURL = `${baseurl}?key=${APIKEY}&countrySet=VN`;
      const api = {
        endPoint: searchURL,
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
      };
      const { response } = await apiCall(api);
      const addresses = response?.data.results.map((v) => ({
        code: v.id,
        address: `${v.address.streetNumber ? `${v.address.streetNumber},` : ''} ${
          v.address.streetName ? `${v.address.streetName},` : ''
        }  ${v.address.municipalitySubdivision ? `${v.address.municipalitySubdivision},` : ''} ${
          v.address.localName || v.address.freeformAddress
        }`,
        lat: v.position.lat,
        lng: v.position.lon,
      }));

      if (addresses?.length) {
        orders[i].receivedAddress = addresses[0].address;
        orders[i].lat = addresses[0].lat;
        orders[i].lng = addresses[0].lng;
      }
    }
    if (this.props.mode === 'customerOrder') {
      this.setState({ dataFromFile: [...orders], loading: false });
    } else {
      this.setState({ orderList: [...orders], loading: false });
    }
  };

  handleOk = async () => {
    let temp = {};
    const arr = [];
    this.state.dataFromFile.map((order, index) => {
      if (order['Name'] && order['Phone'] && order['Address']) {
        temp = {};
        temp.delivery_date = order['Ngày giao']
          ? moment(order['Ngày giao']).format('YYYY-MM-DDTHH:00:00[Z]')
          : '';
        temp.customer_name = order['Name'];
        temp.customer_phone_number = order['Phone'];
        temp.location = order.location
          ? order.location
          : {
              address: order.Address ? order.Address : '',
              latitude: order['Vĩ độ'] ? Number(order['Vĩ độ']) : 0,
              longitude: order['Kinh độ'] ? Number(order['Kinh độ']) : 0,
            };
        temp.occupied_space = order['Thể tích (m3)'] ? order['Thể tích (m3)'] : 0;
        temp.demand = order['Khối lượng (kg)'] ? order['Khối lượng (kg)'] : 0;
        temp.delivery_at = {
          start_time: order['Thời gian giao từ'] ? order['Thời gian giao từ'] : '',
          to_time: order['Thời gian giao đến'] ? order['Thời gian giao đến'] : '',
        };
        temp.note = order['Ghi chú'] ? order['Ghi chú'] : '';
        // Default information
        temp.driver_code = '';
        temp.feature_codes = [];
        temp.money = 0;
        temp.vehicle_type = '';

        arr.push(temp);
        return temp;
      }
    });

    await this.props.insertManyOrder(
      { orders: arr },
      {
        onSuccess: (res) => {
          // console.log(res);
          if (res?.data?.success === true) {
            notification.open({
              message: `Thêm thành công ${res?.data?.data?.length} đơn hàng!`,
              type: 'success',
            });
            // history.push(ROUTER.DRIVER.INDEX);
          } else if (
            res?.data?.success === false &&
            res?.data?.message === 'must provide at least one element in input slice'
          ) {
            notification.open({
              message:
                'Thêm đơn giao hàng từ file thất bại! Xem lại thông tin đơn giao hàng trong file đã tồn tại hay chưa?',
              type: 'error',
            });
          }
        },
        onError: (err) => {
          const message = JSON.parse(err.response).message;
          notification.open({
            message: `Thêm đơn giao hàng từ file thất bại!`,
            description: `${message}`,
            type: 'error',
          });
        },
      },
    );

    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 2000);
  };

  handleExport = () => {
    const mode = this.state.mode;
    const curDate = moment(Date()).format('YYYYMMDD-HHmm');
    const data =
      mode === 'customerOrder'
        ? this.state.dataFromFile.map((order, index) => [
            index + 1,
            order['Đơn hàng'],
            order['Khách hàng'],
            order['Điện thoại'],
            order['Địa chỉ'],
            order['Trọng lượng'],
            order.receivedAddress,
            order.lng,
            order.lat,
          ])
        : this.state.orderList.map((order, index) => [
            index + 1,
            order['orderId'],
            order['customer'],
            order['phoneNumber'],
            order['address'],
            order['weight'],
            order.receivedAddress,
            order.lng,
            order.lat,
          ]);
    data.unshift([
      'STT',
      'Đơn Hàng',
      'Khách Hàng',
      'Số Điện Thoại',
      'Địa Chỉ',
      'Trọng Lượng',
      'Địa Chỉ Truy Xuất',
      'Kinh Độ',
      'Vĩ Độ',
    ]);
    const wb = XLSX.utils.book_new();
    const ws_name = 'SheetJS';

    /* make worksheet */
    const ws_data = data;
    const ws = XLSX.utils.aoa_to_sheet(ws_data);

    /* Add the worksheet to the workbook */
    XLSX.utils.book_append_sheet(wb, ws, ws_name);
    XLSX.writeFile(wb, `Truy_Xuat_Dia_Chi_Don_Hang_<${curDate}>.xlsx`);
  };

  handleExportOrderList = () => {
    const orderList = this.state.orderList;
    const curDate = moment(Date()).format('YYYYMMDD-HHmm');
    let data = [];
    let count = [];
    let order = {
      orderId: '',
      productCode: '',
      quantity: 0,
    };

    for (let i = 0; i < orderList.length; i++) {
      order.orderId = orderList[i].orderId;
      for (let j = 0; j < orderList[i].productList.length; j++) {
        // console.log(j);
        order.productCode = orderList[i].productList[j].productCode;
        order.quantity = orderList[i].productList[j].quantity;
        data.push(order);

        order = {
          orderId: '',
          productCode: '',
          quantity: 0,
        };
      }
    }
    count = data.map((order, index) => (order.orderId === '' ? '' : order.orderId));

    data = data.map((order, index) => [count, order.orderId, order.productCode, order.quantity]);
    data.unshift(['STT', 'Đơn Hàng', 'Mã Hàng', 'Số Lượng']);
    const wb = XLSX.utils.book_new();
    const ws_name = 'SheetJS';

    // Make worksheet
    const ws_data = data;
    const ws = XLSX.utils.aoa_to_sheet(ws_data);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, ws_name);
    XLSX.writeFile(wb, `Danh_Sach_Don_Hang_<${curDate}>.xlsx`);
  };

  getDetailExportSheet = () => {};

  getOrderList = () => {};

  handleCancel = () => this.setState({ dataFromFile: [], visible: false, mode: 'import' });

  handleSelectPosition = (order, address, latitude, longitude) => {
    const { dataFromFile } = this.state;
    const isExist = dataFromFile.findIndex((ord) => ord === order);
    if (isExist) {
      dataFromFile[isExist].Address = address;
      dataFromFile[isExist].location = {
        address,
        latitude,
        longitude,
      };
    }
    this.setState({ dataFromFile });
  };

  readExcelFile(file) {
    const promise = new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);

      reader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: 'buffer' });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      reader.onerror = (err) => reject(err);
    });

    promise
      .then((data) => {
        if (this.state.dataFromFile.length > 0) this.setState({ dataFromFile: [] });
        let dataFromFile = data;
        let temp = 0;
        for (let i = 0; i < data.length; i++) {
          dataFromFile[i].products = [];
          if (dataFromFile[i].STT) {
            temp = i;
            dataFromFile[i].products.push({
              productName: dataFromFile[i]['Tên sản phẩm'],
              quantity: dataFromFile[i]['Số Lượng'],
            });
          } else {
            dataFromFile[temp].products.push({
              productName: dataFromFile[i]['Tên sản phẩm'],
              quantity: dataFromFile[i]['Số Lượng'],
            });
          }
        }
        dataFromFile = dataFromFile.filter((record) => record.STT !== undefined);
        this.setState({ dataFromFile });
        message.success('Tải file thành công!');
      })
      .catch((error) => {
        this.setState({ dataFromFile: [] });
        message.error('Tải file thất bại!');
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }

  readSpecialExcelFile(file) {
    const promise = new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);

      reader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: 'buffer' });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      reader.onerror = (err) => reject(err);
    });

    promise
      .then((data) => {
        const orderList = [];
        let order = {
          serialNum: 0,
          orderId: '',
          customer: '',
          phoneNumber: '',
          address: '',
          weight: 0,
          lng: 0,
          lat: 0,
          productList: [],
        };
        // let count = 0;

        for (let i = 0; i < data.length; i++) {
          if (data[i][Object.keys(data[0])] === 'PHIẾU XUẤT KHO BÁN HÀNG') {
            // order.serialNum = orderList[i - 1]?.orderNum ? orderList[i - 1].orderNum : 0;
            // console.log(data[i + 2].__EMPTY_30); // Mã đơn hàng (`Số:`)
            order.orderId = data[i + 2].__EMPTY_30;
            order.customer = data[i + 5].__EMPTY_6 ? data[i + 5].__EMPTY_6 : data[i + 6].__EMPTY_6;
            order.address = data[i + 7].__EMPTY_2 ? data[i + 7].__EMPTY_2 : data[i + 8].__EMPTY_2;
            for (let j = i + 12; j < data.length; j++) {
              if (data[j].__EMPTY_1 && data[j].__EMPTY_17) {
                if (data[j].__EMPTY_1 === 'Mã hàng' && data[j].__EMPTY_17 === 'Số lượng') j++;
                // console.log(data[j].__EMPTY_1, data[j].__EMPTY_17);
                order.productList.push({
                  productCode: data[j].__EMPTY_1,
                  quantity: data[j].__EMPTY_17,
                });
              }
              if (data[j + 1][Object.keys(data[0])] === 'Cộng') break;
            }
            orderList.push(order);
            order = {
              serialNum: 0,
              orderId: '',
              customer: '',
              phoneNumber: '',
              address: '',
              weight: 0,
              lng: 0,
              lat: 0,
              productList: [],
            };
          }
        }
        this.setState({ orderList: [...orderList] });
        message.success('Tải file thành công!');
      })
      .catch((error) => {
        message.error('Tải file thất bại!');
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }

  onChangeFilter = (data) => {
    const { dataFilter } = this.state;
    this.setState({ dataFilter: { ...dataFilter, ...data } });
  };

  onImportSuccess = () => {
    this.props.invalidateOrders();
  };

  render() {
    // const { dataFromFile, orderList, visible, loading, mode } = this.state;
    const { visibleOrderImportModal } = this.state;
    const { visibleChangeModal } = this.state;
    // const { selectedRecordOrderCode } = this.state;
    const { selectedRows } = this.state;

    const { isFetching, meta, orders, history, company } = this.props;
    const canMoveOrders =
      company.currentCompany.type === 'multi' || company.currentCompany.parent_code;

    return (
      <>
        <Row>
          <Col span={24}>
            <div>
              <h2 style={{ float: 'left' }}>Đơn giao hàng</h2>
              <div style={{ float: 'right' }}>
                {canMoveOrders ? (
                  <Button
                    shape="round"
                    type="primary"
                    style={{ marginRight: 15 }}
                    onClick={() => this.setState({ visibleChangeModal: true })}
                    icon={<SwapOutlined />}>
                    Chuyển đơn
                  </Button>
                ) : null}
                <Button
                  style={{ marginRight: 15 }}
                  onClick={() => this.setState({ visibleOrderImportModal: true })}
                  icon={<UploadOutlined />}
                  type="default"
                  shape="round">
                  Upload file
                </Button>
                <Button
                  shape="round"
                  type="primary"
                  onClick={() => history.push(ROUTER.ORDER.ADD)}
                  icon={<PlusOutlined />}>
                  Thêm
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Divider style={{ marginTop: 0 }} />

        <div style={{ marginBottom: 20 }}>
          <Filter onSearch={this.onChangeFilter} />
        </div>
        {/* <Button
          type="primary"
          shape="round"
          icon={<UploadOutlined />}
          size="medium"
          onClick={this.showModal2}
          style={{ marginRight: '10px' }}>
          Nhập Thông Tin Đơn Khách Lẻ
        </Button>
        <Button
          type="primary"
          shape="round"
          icon={<UploadOutlined />}
          size="medium"
          onClick={this.showModal3}
          style={{ marginRight: '10px' }}>
          Nhập Thông Tin Đơn Khách Đại Lý
        </Button> */}
        <OrderList
          mode={this.state.mode}
          isFetching={isFetching}
          dataSource={orders}
          meta={meta}
          onChange={this.onPageChange}
          onShowSizeChange={this.onPageChange}
          showRowSelection={canMoveOrders}
          onSelectChange={this.onSelectedRowKeys}
        />
        <OrderImportModal
          isOpen={visibleOrderImportModal}
          onCancel={() => this.setState({ visibleOrderImportModal: false })}
          // onSuccess={this.filterOrder}
        />
        {canMoveOrders ? (
          <ChangeCompanyModal
            dataSelect={selectedRows}
            isOpen={visibleChangeModal}
            onCancel={() => this.setState({ visibleChangeModal: false })}
          />
        ) : null}
        {/* <Modal
          visible={visible}
          title="Nhập File Excel"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width={1500}
          footer={[
            <Button
              key="getTableOrders"
              type="primary"
              loading={loading}
              onClick={this.getOrderList}
              disabled={
                mode === 'import' ||
                mode === 'customerOrder' ||
                this.state.disabledgetOrderListButton
              }>
              Lấy Danh Sách Các Phiếu Xuất Kho
            </Button>,
            <Button
              key="getDetailExportSheet"
              type="primary"
              loading={loading}
              onClick={this.getDetailExportSheet}
              disabled={
                mode === 'import' || mode === 'customerOrder' || this.state.disabledgetDetailButton
              }>
              Lấy Thông Tin Các Phiếu Xuất Kho
            </Button>,
            <Button
              key="findLocations"
              type="primary"
              loading={loading}
              onClick={this.onSearch}
              disabled={mode === 'import' || this.state.disabledgetDetailButton}>
              Tìm Toạ Độ Đơn Hàng
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={() => {
                if (mode === 'import') this.handleOk();
                else if (mode === 'customerOrder') this.handleExport();
                else if (mode === 'agencyOrder') this.handleExport();
              }}>
              {mode === 'import' ? 'Lưu' : 'Xuất Dữ Liệu'}
            </Button>,
            <Button key="back" onClick={this.handleCancel}>
              Huỷ Bỏ
            </Button>,
          ]}>
          <input
            type="file"
            accept={SheetJSFT}
            label="Tải lên File"
            onChange={(e) => {
              const file = e.target.files[0];
              if (mode === 'import' || mode === 'customerOrder') this.readExcelFile(file);
              else if (mode === 'agencyOrder') this.readSpecialExcelFile(file);
            }}
          />
          {mode === 'import' && (
            <a
              href="https://docs.google.com/spreadsheets/d/10Otpz4WhCKgIGmc2G6HjYmNt8wRjKJsB/edit#gid=1684195527"
              target="_blank">
              Mẫu file Excel
            </a>
          )}
          <OrderList
            mode={this.state.mode}
            isFetching={isFetching}
            dataSource={mode !== 'agencyOrder' ? dataFromFile : orderList}
            meta={meta}
            onChange={this.onPageChange}
            onShowSizeChange={this.onPageChange}
            onSelectPosition={this.handleSelectPosition}
            isImportFromFile
          />
        </Modal> */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userRole: state.authReducer.authUser.role_code,
  company: state.companyReducer,
  orders: state.orderReducer.items,

  meta: state.orderReducer.meta,
  isFetching: state.orderReducer.isFetching,
});

const mapDispatchToProps = (dispatch) => ({
  getOrdersIfNeed: (filterOption) => dispatch(orderActions.getOrdersIfNeed(filterOption)),
  changePage: (page, page_size, total) => dispatch(orderActions.changePage(page, page_size, total)),
  invalidateOrders: () => dispatch(orderActions.invalidateOrders()),
  insertManyOrder: (payload, meta) => dispatch(orderActions.insertManyOrder(payload, meta)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WithConfig('order')(WithLoading(Order)));
