import { Col, Row, notification } from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import queryString from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import FeaturesForm from './components/FeaturesForm';
import CompanyForm from './components/Form';
import Card from '../../components/Card';
import ROUTER from '../../config/router';
import { actions as companyActions } from '../../redux/CompanyRedux';
import { isBelowBreakpoint } from '../../utils/window';

class EditCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentCompanyId: '',
      companyInfo: {},
    };
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    const locationData = queryString.parse(nextProps.location.search);
    if (locationData.fromCompanyId !== prevState.currentCompanyId) {
      nextProps.getCompanyById(locationData.fromCompanyId);
      return { currentCompanyId: locationData.fromCompanyId };
    }
    if (nextProps.currentCompanyData) {
      const currentCompanyData = nextProps.currentCompanyData;
      if (currentCompanyData.code && prevState.currentCompanyId) {
        return {
          companyInfo: currentCompanyData,
        };
      }
    }
    return null;
  };

  onSubmit = (values) => {
    const { history, currentCompanyData, companyCode } = this.props;
    const { address, description, email, type, parent_code, hotline, name, phone } = values;
    const companyInfoPayload = {
      address,
      description,
      email,
      type: companyCode ? 'single' : type || 'single',
      parent_code: companyCode ? companyCode : type === 'multi' ? '' : parent_code || '',
      hotline,
      name,
      phone,
    };

    this.props.updateCompany(currentCompanyData.code, companyInfoPayload, {
      onSuccess: () => {
        notification.open({
          message: 'Sửa thành công',
          type: 'success',
        });
        history.push(ROUTER.COMPANY.INDEX);
      },
      onError: () =>
        notification.open({
          message: `${'Sửa thất bại'}`,
          type: 'error',
        }),
    });
  };

  onUpdateFeatures = (values) => {
    const { currentCompanyData } = this.props;
    const { features } = values;

    this.props.updateCompanyFeatures(
      currentCompanyData.code,
      { features },
      {
        onSuccess: () => {
          notification.open({
            message: 'Sửa chức năng thành công',
            type: 'success',
          });
        },
        onError: () =>
          notification.open({
            message: `${'Sửa  chức năngthất bại'}`,
            type: 'error',
          }),
      },
    );
  };

  render() {
    const { companyCode } = this.props;
    const { companyInfo } = this.state;

    return (
      <div>
        <Row type="flex" justify="center">
          <Col span={24} lg={24}>
            <Card hoverable={false} bordered={!isBelowBreakpoint()}>
              <CompanyForm
                onSubmit={this.onSubmit}
                isEditMode
                companyInfo={companyInfo}
                companyCode={companyCode}
              />
            </Card>
          </Col>
          {!companyCode && companyInfo?.code ? (
            <Col span={24} lg={24}>
              <Card hoverable={false} bordered={!isBelowBreakpoint()}>
                <FeaturesForm onSubmit={this.onUpdateFeatures} features={companyInfo.features} />
              </Card>
            </Col>
          ) : null}
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ companyReducer, authReducer }) => ({
  currentCompanyData: companyReducer.currentCompany,
  companyCode: authReducer.authUser.company_code,
});

const mapDispatchToProps = (dispatch) => ({
  getCompanyById: (companyId) => dispatch(companyActions.getCompanyById(companyId)),
  updateCompany: (companyId, payload, meta) =>
    dispatch(companyActions.updateCompany(companyId, payload, meta)),
  updateCompanyFeatures: (companyId, payload, meta) =>
    dispatch(companyActions.updateCompanyFeatures(companyId, payload, meta)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditCompany));
