import { PlusOutlined } from '@ant-design/icons';
import { Button, Divider, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

// import Filter from './components/Filter';
import FormCategory from './components/Form';
import ListCategories from './components/List';
import { actions as categoryActions } from '../../redux/CategoryRedux';
import { actions as companyActions } from '../../redux/CompanyRedux';

const Categories = ({
  getAllCategories,
  dataCategories,
  categoriesTree,
  allCompanies,
  addCategory,
  updateCategory,
  changeStatusCategory,
}) => {
  // const [status, setStatus] = useState(null);
  // const [level, setLevel] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [dataCurCategory, setDataCurCategory] = useState();

  // useEffect(() => {
  //   getCategories({
  //     page,
  //     page_size: pageSize,
  //     level,
  //     status,
  //   });
  // }, [page, pageSize, level, status]);

  useEffect(() => {
    getAllCategories();
  }, []);

  // const onChangeStatus = (status) => {
  //   setStatus(status);
  // };

  // const onChangeLevel = (level) => {
  //   setLevel(level);
  // };

  const openModalForm = () => {
    setOpenModal(true);
  };

  const closeModalForm = () => {
    setOpenModal(false);
    setIsEdit(false);
  };

  const handleAddCategory = (value) => {
    addCategory(
      {
        code: value.code,
        name: value.name,
        parent_code: value.parent_code,
        level: value.level,
        description: value.description,
      },
      {
        onSuccess: () => {
          closeModalForm();
          notification.success({ message: 'Thêm nhóm hàng thành công' });
          getAllCategories();
        },
        onError: (mes) => {
          notification.error({ message: `Thêm nhóm hàng thất bại. ` });
        },
      },
    );
  };

  const handleEditCategory = (value) => {
    const payload = {
      code: value.code,
      level: value.level,
      name: value.name,
      parent_code: value.parent_code,
      description: value.description,
    };

    updateCategory(
      {
        code: value.code,
        payload,
      },
      {
        onSuccess: () => {
          closeModalForm();
          notification.success({ message: 'Sửa nhóm hàng thành công' });
          getAllCategories();
        },
        onError: (mes) => {
          notification.error({ message: `Sửa nhóm hàng thất bại. ` });
        },
      },
    );
  };

  const editCategory = (payload) => {
    setIsEdit(true);
    setDataCurCategory(payload);
    openModalForm();
  };

  const defaultValues = {
    code: '',
    name: '',
    parent_code: '',
  };

  return (
    <>
      <div>
        <h2 style={{ float: 'left' }}>Danh sách nhóm hàng</h2>
        <div style={{ float: 'right' }}>
          <Button shape="round" type="primary" onClick={openModalForm} icon={<PlusOutlined />}>
            Thêm
          </Button>
        </div>
      </div>
      <Divider style={{ marginTop: 0 }} />
      {/* <div style={{ marginBottom: '15px' }}>
        <Filter
          style={{ width: 'inherit', marginBottom: '15px' }}
          onChangeStatus={onChangeStatus}
          onChangeLevel={onChangeLevel}
        />
      </div> */}
      <ListCategories
        dataCategories={categoriesTree}
        allCompanies={allCompanies}
        editCategory={editCategory}
        changeStatusCategory={changeStatusCategory}
      />
      <FormCategory
        openModalForm={openModalForm}
        closeModalForm={closeModalForm}
        openModal={openModal}
        onSubmit={isEdit ? handleEditCategory : handleAddCategory}
        dataCurCategory={isEdit ? dataCurCategory : defaultValues}
        title={isEdit ? 'Sửa nhóm hàng' : 'Thêm nhóm hàng'}
        dataCategories={dataCategories}
      />
    </>
  );
};
const mapStateToProps = (state) => ({
  dataCategories: state?.categoryReducer?.allItems,
  categoriesTree: state?.categoryReducer?.treeData,
  meta: state?.categoryReducer?.meta,
  allCompanies: state?.companyReducer?.allItems,
});

const mapDispatchToProps = (dispatch) => ({
  getAllCategories: () => {
    dispatch(categoryActions.getAllCategories());
  },
  addCategory: (payload, meta) => dispatch(categoryActions.addCategory({ payload, meta })),
  updateCategory: (payload, meta) => dispatch(categoryActions.updateCategory({ payload, meta })),
  changeStatusCategory: (payload, meta) => dispatch(categoryActions.changeState({ payload, meta })),
  getAllCompanies: (filterOption) => dispatch(companyActions.getAllCompanies(filterOption)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
