import produce from 'immer';
import moment from 'moment';

import { API_URLS } from '../config/api';
import { apiCall } from '../utils/api';
import { isCallingApi, isSuccessfulApiCall, isFailedApiCall } from './actionDedicate';
import { PREFIX, typesWithPrefix } from './config';

const { API } = PREFIX;
const _types = typesWithPrefix(API);
const TYPE = {
  GET_APIS: _types('GET_APIS'),
  GET_ALL_APIS: _types('GET_ALL_APIS'),
  GET_APIS_CHANGE_PAGE: _types('GET_APIS_CHANGE_PAGE'),
  INVALIDATE_APIS: _types('INVALIDATE_APIS'),
  INSERT_API: _types('INSERT_API'),
  EDIT_API: _types('EDIT_API'),
  DELETE_API: _types('DELETE_API'),
};

// ACTION
export const actions = {
  //GET APIS
  getApisIfNeed: (filterOption) => (dispatch, getState) => {
    const { apiReducer } = getState();
    const isFetching = apiReducer.isFetching;
    const didInvalidate = apiReducer.didInvalidate;
    const query = apiReducer.query;
    const isQueryChanged = query.search !== filterOption.search;
    if ((!isFetching && didInvalidate) || (!isFetching && isQueryChanged)) {
      dispatch(actions.getApis(filterOption));
    }
  },

  getApis: (query) => async (dispatch) => {
    const params = {
      page: query.page,
      page_size: query.page_size,
      search: query.search,
    };
    const api = API_URLS.API.getApis(params);
    dispatch(actions.gettingApis());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(
        actions.getApisSuccess({
          items: response.data.data,
          meta: {
            page: response.data.page,
            page_size: response.data.page_size,
            total: response.data.total,
          },
          query: {
            search: params.search,
          },
        }),
      );
    } else {
      dispatch(actions.getApisFailure());
    }
  },

  gettingApis: () => ({
    type: TYPE.GET_APIS,
    meta: { prefix: [PREFIX.API, PREFIX.API_CALLING] },
  }),

  getApisSuccess: (payload) => ({
    type: TYPE.GET_APIS,
    payload,
    meta: { prefix: [PREFIX.API, PREFIX.API_CALLED_SUCCESS] },
  }),

  getApisFailure: () => ({
    type: TYPE.GET_APIS,
    meta: { prefix: [PREFIX.API, PREFIX.API_CALLED_FAILURE] },
  }),

  // CHANGE PAGE
  changePage: (page, page_size, total) => ({
    type: TYPE.GET_APIS_CHANGE_PAGE,
    payload: {
      page,
      page_size,
      total,
    },
    meta: {
      prefix: [PREFIX.API],
    },
  }),

  // INVALIDATE APIS
  invalidateApis: () => ({
    type: TYPE.INVALIDATE_APIS,
    meta: {
      prefix: [PREFIX.API, PREFIX.API_CALLED_SUCCESS],
    },
  }),

  //GET ALL ROLES
  getAllApis: () => async (dispatch) => {
    const api = API_URLS.API.getApis({ page: -1, page_size: -1 });
    dispatch(actions.gettingAllApis());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(
        actions.getAllApisSuccess({
          items: response.data.data,
        }),
      );
    } else {
      dispatch(actions.getAllApisFailure());
    }
  },

  gettingAllApis: () => ({
    type: TYPE.GET_ALL_APIS,
    meta: { prefix: [PREFIX.API, PREFIX.API_CALLING] },
  }),

  getAllApisSuccess: (payload) => ({
    type: TYPE.GET_ALL_APIS,
    payload,
    meta: { prefix: [PREFIX.API, PREFIX.API_CALLED_SUCCESS] },
  }),

  getAllApisFailure: () => ({
    type: TYPE.GET_ALL_APIS,
    meta: { prefix: [PREFIX.API, PREFIX.API_CALLED_FAILURE] },
  }),

  // INSERT API
  insertApi: (payload, meta) => async (dispatch, getState) => {
    const api = API_URLS.API.insertApi();
    dispatch(actions.insertingApi());
    const { response, error } = await apiCall({
      ...api,
      payload,
    });
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(actions.insertApiSuccess());
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
    } else {
      dispatch(actions.insertApiFailure());
    }
  },
  insertingApi: () => ({
    type: TYPE.INSERT_API,
    meta: { prefix: [PREFIX.API, PREFIX.API_CALLING] },
  }),

  insertApiSuccess: () => ({
    type: TYPE.INSERT_API,
    meta: { prefix: [PREFIX.API, PREFIX.API_CALLED_SUCCESS] },
  }),

  insertApiFailure: () => ({
    type: TYPE.INSERT_API,
    meta: { prefix: [PREFIX.API, PREFIX.API_CALLED_FAILURE] },
  }),

  // EDIT API
  editApi: (code, payload, meta) => async (dispatch, getState) => {
    const api = API_URLS.API.updateApi(code);
    dispatch(actions.editingApi());
    const { response, error } = await apiCall({
      ...api,
      payload,
    });
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(actions.editApiSuccess());
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
    } else {
      dispatch(actions.editApiFailure());
    }
  },
  editingApi: () => ({
    type: TYPE.EDIT_API,
    meta: { prefix: [PREFIX.API, PREFIX.API_CALLING] },
  }),

  editApiSuccess: () => ({
    type: TYPE.EDIT_API,
    meta: { prefix: [PREFIX.API, PREFIX.API_CALLED_SUCCESS] },
  }),

  editApiFailure: () => ({
    type: TYPE.EDIT_API,
    meta: { prefix: [PREFIX.API, PREFIX.API_CALLED_FAILURE] },
  }),

  // DELETE API
  deleteApi: (code, meta) => async (dispatch, getState) => {
    const api = API_URLS.API.deleteApi(code);
    dispatch(actions.deletingApi());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(actions.deleteApiSuccess());
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
    } else {
      dispatch(actions.deleteApiFailure());
    }
  },
  deletingApi: () => ({
    type: TYPE.DELETE_API,
    meta: { prefix: [PREFIX.API, PREFIX.API_CALLING] },
  }),

  deleteApiSuccess: () => ({
    type: TYPE.DELETE_API,
    meta: { prefix: [PREFIX.API, PREFIX.API_CALLED_SUCCESS] },
  }),

  deleteApiFailure: () => ({
    type: TYPE.DELETE_API,
    meta: { prefix: [PREFIX.API, PREFIX.API_CALLED_FAILURE] },
  }),
};

// REDUCER
const initialState = {
  allItems: [],
  items: [],
  query: {},
  meta: {
    page: 1,
    page_size: 10,
    total: 0,
  },
  isFetching: false,
  didInvalidate: true,
  lastUpdated: moment().unix(),
};

export const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case TYPE.GET_APIS:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          const { items, meta, query } = action.payload;
          draft.isFetching = false;
          draft.didInvalidate = false;
          draft.lastUpdated = moment().unix();
          draft.items = items;
          draft.meta = meta;
          draft.query = query;
        } else if (isFailedApiCall(action)) {
          draft.isFetching = false;
          draft.didInvalidate = false;
        }
        break;
      case TYPE.GET_APIS_CHANGE_PAGE:
        draft.meta = action.payload;
        break;
      case TYPE.INVALIDATE_APIS:
        draft.didInvalidate = true;
        break;
      case TYPE.GET_ALL_APIS:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          const { items } = action.payload;
          draft.isFetching = false;
          draft.didInvalidate = false;
          draft.allItems = items;
        } else if (isFailedApiCall(action)) {
          draft.isFetching = false;
          draft.didInvalidate = false;
        }
        break;
      default:
        return draft;
    }
  });
