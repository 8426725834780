import { PlusOutlined } from '@ant-design/icons';
import { Row, Button, Col, Table, notification } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import ExpiryForm from './Form';
import { actions as vehicleExpiryActions } from '../../../../../redux/VehicleExpiryRedux';
import { actions as vehiclePartActions } from '../../../../../redux/VehiclePartRedux';

const columns = (allPart, onEdit) => [
  {
    title: <b>Tên linh kiện</b>,
    dataIndex: 'part_code',
    render: (value, record) => {
      const filterList = allPart.filter((data) => data.code === record.part_code);
      // console.log(filterList);
      const edit = () => {
        onEdit(record.part_code, {
          expiry_km_from: record?.expiry_km_from,
          expiry_km_to: record?.expiry_km_to,
          expiry_time_from: record?.expiry_time_from,
          expiry_time_to: record?.expiry_time_to,
          import_date: record?.import_date,
          travel_distance: record?.travel_distance,
        });
      };
      return <a onClick={edit}>{filterList[0]?.name}</a>;
    },
  },
  {
    title: <b>Ngày nhập hàng</b>,
    dataIndex: 'import_date',
    render: (value) => <>{moment(value).format('DD/MM/YYYY')}</>,
  },
  {
    title: <b>Hạn sử dụng theo quãng đường</b>,
    dataIndex: 'expiryKm',
    render: (text, record) =>
      record.expiry_km_from === 0 && record.expiry_km_to === 0 ? (
        ''
      ) : record.expiry_km_from === 0 ? (
        <div>Dưới {record.expiry_km_to} km</div>
      ) : record.expiry_km_to === 0 ? (
        <div>Không ít hơn {record.expiry_km_from} km</div>
      ) : (
        <div>
          {record.expiry_km_from} - {record.expiry_km_to} km
        </div>
      ),
  },
  {
    title: <b>Hạn sử dụng theo thời gian</b>,
    dataIndex: 'expiryTime',
    render: (text, record) =>
      record.expiry_time_from === 0 && record.expiry_time_to === 0 ? (
        ''
      ) : record.expiry_time_from === 0 ? (
        <div>Dưới {record.expiry_time_to} tháng</div>
      ) : record.expiry_time_to === 0 ? (
        <div>Không ít hơn {record.expiry_time_from} tháng</div>
      ) : (
        <div>
          {record.expiry_time_from} - {record.expiry_time_to} tháng
        </div>
      ),
  },
  { title: <b>Quãng đường đã đi được</b>, dataIndex: 'travel_distance' },
];

class VehiclePage extends Component {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props) {
    super(props);
  }
  state = {
    test: [],
    visible: false,
    isEdit: false,
    dataFilter: {
      page: '',
      page_size: '',
      search: '',
    },
    // vehicleCode: '',
  };

  // static getDerivedStateFromProps(props, state) {
  //   if (props.vehicleId !== state.vehicleCode) {
  //     props.getVehicleExpiryById(props.vehicleId);
  //     return {
  //       vehicleCode: props.vehicleId,
  //     };
  //   }
  //   return null;
  // }

  componentDidMount = () => {
    this.props.getAllVehicleExpiry();
    this.props.getAllVehiclePart();
    this.props.getVehicleExpiryById(this.props.vehicleId);
  };

  toggleModalForm = () => {
    this.setState((prevState) => ({ visible: !prevState.visible }));
  };

  onAdd = () => {
    this.setState({
      isEdit: false,
      curVehiclePart: {},
    });
    this.toggleModalForm();
  };

  onEdit = (partCode, payload) => {
    this.setState({
      isEdit: true,
      curVehiclePart: { partCode, payload },
    });
    this.toggleModalForm();
  };

  handleOnAdd = (value) => {
    const { allExpiry, allPart, vehicleId, insertVehicleExpiry, updateVehicleExpiry } = this.props;

    const expiryList = allExpiry.filter((data) => data.vehicle_code === vehicleId);
    const mergeArray = value.map((data1) => ({
      ...data1,
      ...allPart.find((data2) => data2.id === data1.part_code),
    }));
    const filterPart = mergeArray.map(({ code, id, description, name, ...keepAttrs }) => keepAttrs);
    const filterPartConfig = [];
    filterPart.map((value) => {
      filterPartConfig.push({
        import_date: value.importDate,
        part_code: value.partCode,
        travel_distance: value.travelDistance,
      });
    });

    if (expiryList && expiryList.length) {
      updateVehicleExpiry(
        vehicleId,
        { vehicleExpiries: filterPartConfig.concat(expiryList[0].expiry_parts) },
        {
          onSuccess: () => {
            notification.open({
              message: 'Thêm thành công',
              type: 'success',
            });
            this.props.getVehicleExpiryById(this.props.vehicleId);
          },
          onError: () =>
            notification.open({
              message: `${'Thêm thất bại'}`,
              type: 'error',
            }),
        },
      );
    } else {
      insertVehicleExpiry(
        { expiry_parts: filterPartConfig, vehicle_code: vehicleId },
        {
          onSuccess: () => {
            notification.open({
              message: 'Thêm thành công',
              type: 'success',
            });
            this.props.getVehicleExpiryById(this.props.vehicleId);
          },
          onError: () =>
            notification.open({
              message: `${'Thêm thất bại'}`,
              type: 'error',
            }),
        },
      );
    }
  };

  handleOnEdit = (value) => {
    const { vehicleId, allExpiry, updateVehicleExpiry } = this.props;
    const {
      curVehiclePart: { partCode },
    } = this.state;
    const vehiclePart = allExpiry.filter((value) => value.vehicle_code === vehicleId)[0]
      .expiry_parts;
    const filterPart = vehiclePart.filter((value) => value.part_code !== partCode);

    const valueConfig = {};

    valueConfig.part_code = value.partCode;
    valueConfig.expiry_km_from = value.expiry_km_from;
    valueConfig.expiry_km_to = value.expiry_km_to;
    valueConfig.expiry_time_from = value.expiry_time_from;
    valueConfig.expiry_time_to = value.expiry_time_to;
    valueConfig.import_date = value.importDate;
    valueConfig.travel_distance = value.travelDistance;

    filterPart.unshift(valueConfig);

    updateVehicleExpiry(
      vehicleId,
      { vehicleExpiries: filterPart },
      {
        onSuccess: () => {
          notification.open({
            message: 'Sửa thành công',
            type: 'success',
          });
          this.props.getVehicleExpiryById(this.props.vehicleId);
        },
        onError: () =>
          notification.open({
            message: `${'Sửa thất bại'}`,
            type: 'error',
          }),
      },
    );
  };

  handleOnClose = () => {
    this.setState({
      visible: false,
    });
  };

  onChangeFilter = (data) => {
    this.setState({
      dataFilter: data,
    });
  };

  render() {
    const { allPart, isFetching, currentExpoiry } = this.props;
    const { visible, isEdit, curVehiclePart } = this.state;

    return (
      <Row gutter={15}>
        <Col span={24}>
          <Button
            style={{ float: 'right', marginBottom: '15px' }}
            shape="round"
            type="primary"
            onClick={this.onAdd}
            icon={<PlusOutlined />}>
            Thêm linh kiện
          </Button>
        </Col>
        <Col span={24}>
          <Table
            loading={isFetching}
            dataSource={currentExpoiry?.expiry_parts || []}
            columns={columns(allPart, this.onEdit)}
            pagination={{
              // page,
              current: 1,
              page_size: 10,
              showSizeChanger: true,
              showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total}`,
            }}
            size="small"
            scroll={{ x: 'max-content' }}
          />
        </Col>
        <Col span={24}>
          <ExpiryForm
            onAdd={this.handleOnAdd}
            onEdit={this.handleOnEdit}
            visible={visible}
            toggleForm={this.toggleModalForm}
            isEdit={isEdit}
            curVehiclePart={curVehiclePart}
          />
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  allExpiry: state.vehicleExpiryReducer.allItems,
  isFetching: state.vehicleExpiryReducer.isFetching,
  allPart: state.vehiclePartReducer.allItems,
  currentExpoiry: state?.vehicleExpiryReducer?.currentVehicleExpiry || {},
});

const mapDispatchToProps = (dispatch) => ({
  getAllVehicleExpiry: () => dispatch(vehicleExpiryActions.getAllVehicleExpiry()),
  getAllVehiclePart: () => dispatch(vehiclePartActions.getAllVehiclePart()),
  insertVehicleExpiry: (payload, meta) =>
    dispatch(vehicleExpiryActions.insertVehicleExpiry(payload, meta)),
  updateVehicleExpiry: (id, payload, meta) =>
    dispatch(vehicleExpiryActions.updateVehicleExpiry(id, payload, meta)),
  getVehicleExpiryById: (id, meta) => dispatch(vehicleExpiryActions.getVehicleExpiryById(id, meta)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VehiclePage);
