export default {
  HOME: '/',
  AUTH: {
    LOGIN: '/login',
    CHANGE_PASSWORD: '/change-password',
    REGISTER: '/dang-ky',
  },
  COMPANY: {
    INDEX: '/company',
    ADD_COMPANY: '/company/add',
    EDIT_COMPANY: '/company/edit',
  },
  VEHICLE: {
    INDEX: '/vehicle',
    ADD_VEHICLE: '/vehicle/add',
    DETAIL_VEHICLE: '/vehicle/detail/:vehicleInfoId',
    EDIT_VEHICLE: '/vehicle/edit/:vehicleInfoId',
  },
  DASHBOARD: {
    INDEX: '/dashboard',
    NODES: '/dashboard/nodes',
    ORDERS: '/',
    // ORDERS: '/dashboard/orders',
    REALDISTANCE: '/dashboard/real-distance',
    CONPARE_ESTIMATE_AND_REAL: '/dashboard/compare-estimate-and-real',
  },
  VEHICLE_PART: {
    INDEX: '/vehicle-part',
    ADD_VEHICLE_PART: '/vehicle-part/add',
    EDIT_VEHICLE_PART: '/vehicle-part/edit/:vehiclePartInfoId',
  },
  DRIVER: {
    INDEX: '/driver',
    ADD_DRIVER: '/driver/add',
    EDIT_DRIVER: '/driver/edit/:driverInfoId',
  },
  ORDER: {
    INDEX: '/order',
    ADD: '/order/add',
    EDIT: '/order/:code',
  },
  CUSTOMER: {
    INDEX: '/customer',
    ADD: '/customer/add',
    EDIT: '/customer/:code',
  },
  FEATURE: {
    INDEX: '/feature',
    ADD: '/feature/add',
    EDIT: '/feature/:code',
  },
  ACCOUNT: {
    INDEX: '/account',
    ADD: '/account/add',
  },
  API: {
    INDEX: '/api',
  },
  MANAGER: { INDEX: '/manager' },
  ROUTING: {
    INDEX: '/routing',
    CVRP: '/cvrp',
    SCHEDULE: '/schedule',
  },
  SAMPLE_ROUTE: {
    INDEX: '/sample-route',
    ADD: '/sample-route/add',
    EDIT: '/sample-route/:code',
  },
  CONFIGURATION: {
    INDEX: '/configuration',
    ADD: '/configuration/add',
    EDIT: '/configuration/:code',
    KEY: '/configuration/key',
  },
  SCHEDULE: {
    INDEX: '/schedule',
    DETAIL: '/schedule/:id',
    EDIT: '/schedule/:id/edit',
  },
  INVENTORY: {
    STORAGE: '/storages',
    CATEGORIES: '/categories',
    UNITS: '/units',
    PRODUCTS: '/products',
    WAREHOUSES: '/warehouses',
    ADD_DELIVERY_VOUCHER: '/delivery-voucher/add',
    DELIVERY_VOUCHER: '/delivery-voucher',
    ///
    RECEIVING_VOUCHER: '/receiving-voucher',
    ADD_RECEIVING_VOUCHER: '/receiving-voucher/add',
    ADD_TRANSFER_VOUCHER: '/transfer-voucher/add',
    TRANSFER_VOUCHER: '/transfer-voucher',
    ADD_CANCELLATION_VOUCHER: '/cancellation-voucher/add',
    CANCELLATION_VOUCHER: '/cancellation-voucher',
    //
    ADD_RECEIVING_OFFER: '/receiving-offer/add',
    RECEIVING_OFFER: '/receiving-offer',
    ADD_INVENTORY_RECORD: '/inventory-records-voucher/add',
    INVENTORY_RECORD: '/inventory-records-voucher',
  },

  NOT_FOUND: '/404',
  UNAUTHORIZED: '/401',
};
