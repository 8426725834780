/* eslint-disable import/no-unresolved */
import { Table } from 'antd';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import { Link } from 'react-router-dom';

import ROUTER from '../../../config/router';
import '../../../assets/css/style.css';

const columns = () => [
  {
    title: <b>Tên công ty</b>,
    dataIndex: 'name',
    key: 'name',
    render: (value, record) => (
      <>
        <Link
          to={`${ROUTER.COMPANY.EDIT_COMPANY}?fromCompanyId=${record.id}`}
          key={`${record.id}-company-name`}>
          {value}
        </Link>
      </>
    ),
  },
  {
    title: <b>Số điện thoại</b>,
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: <b>Email</b>,
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: <b>Quy mô</b>,
    dataIndex: 'type',
    key: 'type',
    render: (value) =>
      value === 'multi'
        ? 'Tổng công ty/Trụ sở'
        : value === 'single'
        ? 'Một công ty/Chi Nhánh'
        : '-',
  },
  {
    title: <b>Địa chỉ</b>,
    dataIndex: 'address',
    key: 'address',
  },
  // {
  //   title: <b>Mô tả</b>,
  //   dataIndex: 'description',
  //   key: 'description',
  // },
];

class CompanyList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page_size: 1,
    };
  }

  render() {
    const {
      dataSource,
      meta: { page, page_size, total },
      onPageChange,
      onEdit,
      companyCode,
    } = this.props;
    const companyColumns = columns(onEdit);
    if (companyCode) {
      // don't show the column "Quy mo"
      delete companyColumns[3];
    }

    return (
      <>
        <Table
          size="small"
          dataSource={dataSource}
          columns={companyColumns}
          rowKey="id"
          pagination={{
            // page,
            current: page,
            page_size,
            total,
            onChange: onPageChange,
            showSizeChanger: true,
            showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total} công ty`,
          }}
          scroll={{ x: 'max-content' }}
        />
      </>
    );
  }
}

export default CompanyList;
