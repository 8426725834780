import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  // QrcodeOutlined,
  TagOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { Button, Popconfirm, Table, Tooltip, notification } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';

import ProductColumn from '../../../components/VoucherComponents/ProductColumn';
// import { API_URLS } from '../../../config/api';
import { VOUCHER_STATUS } from '../../../constants/enum';
// import { apiCall } from '../../../utils/api';
// import { downloadFile } from '../../../utils/file';

const column = (
  getDeliveryVoucherByCode,
  handleAcceptVoucher,
  handleCancelVoucher,
  // handleExportQRCode,
  loadingQRCodeFile,
) => [
  {
    title: <b>Mã phiếu</b>,
    dataIndex: 'code',
    render: (value, record) => {
      if (record.note) {
        return (
          <Tooltip title={record.note}>
            <span>
              {value} <TagOutlined />
            </span>
          </Tooltip>
        );
      }

      return value;
    },
  },
  {
    title: <b>Kho</b>,
    dataIndex: 'storage_name',
  },
  {
    title: <b>Đơn giao hàng</b>,
    dataIndex: 'order_code',
    render: (value) => value || '-',
  },
  {
    title: <b>Ngày tạo</b>,
    dataIndex: 'created_at',
    render: (value) => moment(value).format('DD/MM/YYYY'),
  },
  {
    title: <b>Ngày xuất</b>,
    dataIndex: 'delivery_time',
    render: (value) => (value ? moment(value).format('DD/MM/YYYY') : '-'),
  },
  {
    title: <b>Khách hàng</b>,
    dataIndex: 'delivery_to',
    render: (value) => value?.name || '-',
  },
  {
    title: <b>Trạng thái</b>,
    dataIndex: 'state',
    render: (value) => VOUCHER_STATUS[value],
  },
  {
    title: <b>Sản phẩm</b>,
    dataIndex: 'product_items',
    render: (value) => <ProductColumn products={value} />,
  },
  {
    title: <b>Thao tác</b>,
    align: 'center',
    render: (value, record) => (
      <>
        <Popconfirm
          disabled={[
            'accept',
            'rejected',
            'completed',
            'cancelled',
            'draft',
            'voucher_created',
          ].includes(record.state)}
          title="Bạn chắc chắn muốn hoàn thành phiếu này?"
          onConfirm={(e) => handleAcceptVoucher(e, record)}>
          <Tooltip title="Hoàn thành phiếu">
            <Button
              type="primary"
              shape="circle"
              disabled={[
                'accept',
                'completed',
                'rejected',
                'cancelled',
                'draft',
                'voucher_created',
              ].includes(record.state)}>
              <CheckCircleOutlined />
            </Button>
          </Tooltip>
        </Popconfirm>
        <Popconfirm
          disabled={['accept', 'rejected', 'completed', 'cancelled', 'voucher_created'].includes(
            record.state,
          )}
          title="Bạn chắc chắn muốn huỷ phiếu này?"
          onConfirm={(e) => handleCancelVoucher(e, record)}>
          <Tooltip title="Huỷ phiếu">
            <Button
              type="danger"
              shape="circle"
              style={{ marginLeft: 10 }}
              disabled={[
                'accept',
                'rejected',
                'completed',
                'cancelled',
                'voucher_created',
              ].includes(record.state)}>
              <CloseCircleOutlined />
            </Button>
          </Tooltip>
        </Popconfirm>

        <Tooltip title="Chi tiết phiếu">
          <Button
            onClick={() => {
              getDeliveryVoucherByCode(record.code, record);
              // handleOpenModal();
            }}
            style={{ marginLeft: 10 }}
            icon={<UnorderedListOutlined />}
            shape="circle"
            type="primary"
          />
        </Tooltip>
        {/* <Tooltip title="Xuất QR Code">
          <Button
            onClick={() => {
              handleExportQRCode(record.code);
            }}
            style={{ marginLeft: 10 }}
            icon={<QrcodeOutlined />}
            shape="circle"
            type="primary"
            disabled={loadingQRCodeFile}
          />
        </Tooltip> */}
      </>
    ),
  },
];

const ListDeliveryVoucher = ({
  meta,
  isFetching,
  onPageChange,
  data,
  getDeliveryVoucherByCode,
  changeState,
  getDeliveryVoucher,
}) => {
  const [loadingQRCodeFile, setLoadingQRCodeFile] = useState(false);
  const handleAcceptVoucher = (e, record) => {
    e.preventDefault();

    changeState(
      {
        code: record.code,
        payload: {
          state: 'completed',
        },
      },
      {
        onSuccess: () => {
          notification.success({ message: 'Hoàn thành phiếu thành công' });

          getDeliveryVoucher({
            page: 1,
            page_size: 10,
          });
        },
        onError: () => {
          notification.error({ message: `Hoàn thành phiếu thất bại. ` });
        },
      },
    );
  };

  const handleCancelVoucher = (e, record) => {
    e.preventDefault();

    changeState(
      {
        code: record.code,
        payload: {
          state: 'cancelled',
        },
      },
      {
        onSuccess: () => {
          notification.success({ message: 'Hủy phiếu thành công' });
          getDeliveryVoucher({
            page: 1,
            page_size: 10,
          });
        },
        onError: () => {
          notification.error({ message: 'Hủy phiếu thất bại. ' });
        },
      },
    );
  };

  // const handleExportQRCode = async (code) => {
  //   const api = API_URLS.DELIVERY_VOUCHER.getDeliveryVoucherQRCode(code);

  //   setLoadingQRCodeFile(true);
  //   const { response, error } = await apiCall(api);
  //   if (!error && response.status === 200 && response.data?.url) {
  //     const linkFile = response.data.url;
  //     downloadFile(linkFile);
  //   } else {
  //     notification.error({ message: 'Có lỗi khi tải file. Hãy thử lại!' });
  //   }

  //   setLoadingQRCodeFile(false);
  // };

  return (
    <>
      <Table
        size="small"
        columns={column(
          getDeliveryVoucherByCode,
          handleAcceptVoucher,
          handleCancelVoucher,
          // handleExportQRCode,
          loadingQRCodeFile,
        )}
        dataSource={data}
        loading={isFetching}
        rowKey={(record) => record?.code}
        pagination={{
          current: meta?.page,
          pageSize: meta?.pageSize || 10,
          total: meta?.total,
          onChange: onPageChange,
          showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total}`,
        }}
      />
    </>
  );
};

export default ListDeliveryVoucher;
