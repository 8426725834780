import { Menu, Dropdown } from 'antd';
import React from 'react';

const UserDropdown = ({ onClick, children }) => (
  <Dropdown
    overlay={
      <Menu onClick={(event) => onClick(event)}>
        <Menu.Item key="change-pass">
          <span role="img" aria-label="Password">
            Đổi Mật Khẩu
          </span>
        </Menu.Item>
      </Menu>
    }>
    <span
      style={{
        width: '40px',
        display: 'inline-block',
        textAlign: 'center',
      }}>
      {children}
    </span>
  </Dropdown>
);

export default UserDropdown;
