import { PlusOutlined, CheckOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, Modal, Row, Table, notification } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { actions as companyActions } from '../../redux/CompanyRedux';
import { actions as unitActions } from '../../redux/UnitRedux';

const { addUnit, getUnits, updateUnit } = unitActions;
const { getAllCompanies } = companyActions;

const FormItem = Form.Item;
const { Item } = Form;

const column = (allCompanies, editUnit) => [
  {
    title: <b>Mã đơn vị</b>,
    dataIndex: 'code',
    render: (value, record) => {
      const edit = () => {
        editUnit(record.code, {
          code: record.code,
          name: record.name,
          description: record.description,
        });
      };
      return <a onClick={edit}>{value}</a>;
    },
  },
  {
    title: <b>Tên đơn vị</b>,
    dataIndex: 'name',
  },
  {
    title: <b>Công ty</b>,
    dataIndex: 'company_code',
    render: (value, record) => {
      const company = allCompanies.find((element) => element.id === value);
      return company?.name;
    },
  },
  {
    title: <b>Mô tả</b>,
    dataIndex: 'description',
  },
];

const Units = ({ getUnits, allCompanies, dataUnits, meta, addUnit, updateUnit }) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [dataCurUnits, setdataCurUnits] = useState({});

  useEffect(() => {
    getUnits({
      page,
      page_size: pageSize,
    });
  }, []);

  const onPageChange = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
  };

  const openModalForm = () => {
    setOpenModal(true);
  };

  const closeModalForm = () => {
    setOpenModal(false);
    setdataCurUnits({});
  };

  const editUnit = (code, payload) => {
    openModalForm();
    setdataCurUnits(payload);
    setIsEdit(true);
  };

  const handleAddUnit = (value) => {
    addUnit(
      {
        code: value.code,
        name: value?.name,
        description: value.description,
      },
      {
        onSuccess: () => {
          notification.success({ message: 'Thêm đơn vị thành công' });
          getUnits({
            page,
            page_size: pageSize,
          });
        },
        onError: (mes) => {
          notification.error({ message: `Thêm đơn vị thất bại. ` });
        },
      },
    );
    closeModalForm();
  };

  const handleEditUnit = (value) => {
    updateUnit(
      {
        code: value.code,
        payload: {
          code: value.code,
          name: value?.name,
          description: value.description,
        },
      },
      {
        onSuccess: () => {
          notification.success({ message: 'Sửa thành công' });
          getUnits({
            page,
            page_size: pageSize,
          });
        },
        onError: () => {
          notification.error({ message: 'Sửa thất bại' });
        },
      },
    );
    closeModalForm();
  };

  return (
    <>
      <div>
        <h2 style={{ float: 'left' }}>Danh sách đơn vị</h2>
        <div style={{ float: 'right' }}>
          <Button shape="round" type="primary" onClick={openModalForm} icon={<PlusOutlined />}>
            Thêm
          </Button>
        </div>
      </div>
      <Divider style={{ marginTop: 0 }} />
      <Table
        size="small"
        columns={column(allCompanies, editUnit)}
        dataSource={dataUnits}
        rowKey={(record) => record?.code}
        pagination={{
          current: meta?.page,
          pageSize: meta?.pageSize || 10,
          total: meta?.total,
          onChange: onPageChange,
          showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total}`,
        }}
      />

      <Modal
        title={isEdit ? 'Sửa đơn vị' : 'Thêm đơn vị'}
        visible={openModal}
        onOK={openModalForm}
        onCancel={closeModalForm}
        footer={<></>}
        destroyOnClose>
        <Form
          layout="vertical"
          onFinish={isEdit ? handleEditUnit : handleAddUnit}
          initialValues={{
            code: dataCurUnits?.code ? dataCurUnits?.code : '',
            name: dataCurUnits?.name ? dataCurUnits?.name : '',
            description: dataCurUnits?.description ? dataCurUnits?.description : '',
          }}>
          <Row gutter={10}>
            <Col span={24}>
              <FormItem label="Mã đơn vị" name="code">
                <Input placeholder="Nhập mã đơn vị..." />
              </FormItem>
              <FormItem
                label="Tên đơn vị"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Nhập tên đơn vị!',
                  },
                ]}>
                <Input placeholder="Nhập tên đơn vị..." />
              </FormItem>
              <FormItem label="Mô tả" name="description">
                <TextArea rows={4} placeholder="Mô tả..." />
              </FormItem>
            </Col>

            <Item style={{ float: 'right', marginTop: '10px' }}>
              <Button type="primary" htmlType="submit" icon={<CheckOutlined />} shape="round">
                Lưu
              </Button>
              &nbsp;
            </Item>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  allCompanies: state?.companyReducer?.allItems,
  dataUnits: state?.unitReducer?.data,
  meta: state?.unitReducer?.meta,
});

const mapDispatchToProps = (dispatch) => ({
  getUnits: (params) => {
    dispatch(getUnits(params));
  },
  addUnit: (payload, meta) => dispatch(addUnit({ payload, meta })),
  updateUnit: (payload, meta) => dispatch(updateUnit({ payload, meta })),
  getAllCompanies: (filterOption) => dispatch(getAllCompanies(filterOption)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Units);
