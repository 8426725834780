import { Row, Col, DatePicker, Divider } from 'antd';
import moment from 'moment';
import queryString from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Cvrp from './cvrp';
import ROUTER from '../../config/router';
import { actions as driverActions } from '../../redux/DriverRedux';
import { actions as orderActions } from '../../redux/OrderRedux';
import { actions as routingActions } from '../../redux/RoutingRedux';

class Schedule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allDrivers: [],
      delivery_date: undefined,
      // delivery_date: this.props.location?.search
      //   ? queryString.parse(this.props.location?.search)?.delivery_date
      //   : moment().add(1, 'days').toISOString(),
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { history, location } = props;
    const queryDeliveryDate = queryString.parse(location.search).delivery_date;
    if (!queryDeliveryDate) {
      const deliveryDate = moment().add(1, 'days').toISOString();
      history.push(`${ROUTER.ROUTING.SCHEDULE}?delivery_date=${deliveryDate}`);
      return null;
    }
    if (queryDeliveryDate !== state.delivery_date) {
      props.getOrdersIfNeed({
        page: -1,
        page_size: -1,
        delivery_date: queryDeliveryDate,
        status: 'Ordered',
      });

      const nextState = {
        delivery_date: queryDeliveryDate,
      };

      if (props.allDrivers && props.allDrivers !== state.allDrivers) {
        props.getRoutes(props.allDrivers, {
          page: -1,
          page_size: -1,
          delivery_date: queryDeliveryDate,
        });
        nextState.allDrivers = props.allDrivers;
      }

      return nextState;
    } else if (state.delivery_date && props.allDrivers && props.allDrivers !== state.allDrivers) {
      props.getRoutes(props.allDrivers, {
        page: -1,
        page_size: -1,
        delivery_date: state.delivery_date,
      });

      return {
        allDrivers: props.allDrivers,
      };
    }

    return null;
  }

  componentDidMount = () => {
    this.props.getAllDrivers();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.delivery_date !== prevState.delivery_date)
      prevProps.getRoutes(prevProps.allDrivers, {
        page: -1,
        page_size: -1,
        delivery_date: this.state.delivery_date,
      });
  };

  onUpdateDeliveryDate = (value) => {
    const { history } = this.props;
    const deliveryDate = moment(value).toISOString();
    history.push(`${ROUTER.ROUTING.SCHEDULE}?delivery_date=${deliveryDate}`);
    // this.setState({ delivery_date: deliveryDate });
  };

  render() {
    const { delivery_date } = this.state;
    return (
      <>
        <div style={{ marginTop: 2 }}>
          <h2 style={{ float: 'left' }}>Lịch giao vận</h2>
          <div style={{ float: 'right' }}>
            <DatePicker
              placeholder="Chọn ngày giao hàng"
              onChange={this.onUpdateDeliveryDate}
              defaultValue={moment(this.state.delivery_date)}
              format="DD/MM/YYYY"
            />
          </div>
        </div>
        <Divider style={{ marginTop: 0 }} />
        <Cvrp
          deliveryDate={delivery_date}
          getRoutes={() =>
            this.props.getRoutes(this.props.allDrivers, { page: -1, page_size: -1, delivery_date })
          }
          getNonScheduledOrders={() =>
            this.props.getOrdersIfNeed({
              page: -1,
              page_size: -1,
              delivery_date,
              status: 'Ordered',
            })
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  solution: state.routingReducer.solution,
  allDrivers: state.driverReducer.allItems,
});

const mapDispatchToProps = (dispatch) => ({
  sortDrivers: (updatedDrivers) => dispatch(routingActions.sortDrivers(updatedDrivers)),
  updateSolution: (updatedDrivers) => dispatch(routingActions.updateSolution(updatedDrivers)),
  getRoutes: (params, meta) => dispatch(routingActions.getRoutes(params, meta)),
  getAllDrivers: () => dispatch(driverActions.getAllDrivers()),
  getOrdersIfNeed: (params) => dispatch(orderActions.getOrdersIfNeed(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);
