import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { API_URLS } from '../config/api';
import { apiCall } from '../utils/api';
import { PREFIX, typesWithPrefix } from './config';

const _types = typesWithPrefix(PREFIX.VEHICLE_BILLING);

const TYPES = {
  GET_VIHICLE_BILLING: _types('GET_VIHICLE_BILLING'),
  INSERT_VIHICLE_BILLING: _types('INSERT_VIHICLE_BILLING'),
  UPDATE_VIHICLE_BILLING: _types('UPDATE_VIHICLE_BILLING'),
  DELETE_VIHICLE_BILLING: _types('DELETE_VIHICLE_BILLING'),
  GET_VIHICLE_BILLING_BY_CODE: _types('GET_VIHICLE_BILLING_BY_CODE'),
};

export const thunkActions = {
  getVehicleBilling: createAsyncThunk(TYPES.GET_VIHICLE_BILLING, async (params) => {
    const api = API_URLS.VEHICLE_BILLING.getVehicleBilling({
      page: params.page,
      page_size: params.page_size,
      billing_type: params.billing_type,
      search: params.search,
    });

    const { response } = await apiCall(api);
    const data = response.data || [];

    return data;
  }),

  getVehicleBillingByCode: createAsyncThunk(TYPES.GET_VIHICLE_BILLING_BY_CODE, async (code) => {
    const api = API_URLS.VEHICLE_BILLING.getVehicleBillingById(code);

    const { response } = await apiCall(api);
    const data = response.data || [];

    return data;
  }),
  insertVehicleBilling: createAsyncThunk(
    TYPES.INSERT_VIHICLE_BILLING,
    async ({ payload, meta }) => {
      const api = API_URLS.VEHICLE_BILLING.insertVehicleBilling(payload);

      const { response, error } = await apiCall(api);

      if (meta) {
        if (!error && response.status === 200) {
          meta.onSuccess();
        } else {
          meta.onError();
        }
      }

      return response.data;
    },
  ),

  updateVehicleBilling: createAsyncThunk(
    TYPES.UPDATE_VIHICLE_BILLING,
    async ({ payload, meta }) => {
      const api = API_URLS.VEHICLE_BILLING.updateVehicleBilling(payload.code, payload.payload);

      const { response, error } = await apiCall(api);

      if (meta) {
        if (!error && response.status === 200) {
          meta.onSuccess();
        } else {
          meta.onError();
        }
      }

      return response.data;
    },
  ),
};

const initialState = {
  listVehicleBilling: [],
  currentVehicleBilling: {},
  isFetching: false,
  meta: {
    page: -1,
    page_size: -1,
    total: 0,
  },
};

export const vehicleBillingSlice = createSlice({
  name: 'vehicleBillingReducer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      //get vehicle Billing
      .addCase(thunkActions.getVehicleBilling.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(thunkActions.getVehicleBilling.fulfilled, (state, { payload }) => {
        const { data, page, page_size, total } = payload;
        state.isFetching = false;
        state.listVehicleBilling = data;
        state.meta.page = page;
        state.meta.page_size = page_size;
        state.meta.total = total;
      })
      .addCase(thunkActions.getVehicleBilling.rejected, (state) => {
        state.isFetching = false;
      })

      // get veicle billing by code
      .addCase(thunkActions.getVehicleBillingByCode.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(thunkActions.getVehicleBillingByCode.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.currentVehicleBilling = payload.data;
      })
      .addCase(thunkActions.getVehicleBillingByCode.rejected, (state) => {
        state.isFetching = false;
      })

      // add vehicle billing
      .addCase(thunkActions.insertVehicleBilling.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(thunkActions.insertVehicleBilling.fulfilled, (state) => {
        state.isFetching = false;
      })
      .addCase(thunkActions.insertVehicleBilling.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(thunkActions.updateVehicleBilling.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(thunkActions.updateVehicleBilling.fulfilled, (state) => {
        state.isFetching = false;
      })
      .addCase(thunkActions.updateVehicleBilling.rejected, (state) => {
        state.isFetching = false;
      });
  },
});

export const actions = { ...vehicleBillingSlice.actions, ...thunkActions };
export const { reducer } = vehicleBillingSlice;
