import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ROUTER from '../../../config/router';
import AccountPage from '../../AccountManage';
import AddAccount from '../../AccountManage/add';
const Account = () => (
  <Switch>
    <Route exact path={ROUTER.ACCOUNT.INDEX} component={AccountPage} />
    <Route exact path={ROUTER.ACCOUNT.ADD} component={AddAccount} />
  </Switch>
);
export default Account;
