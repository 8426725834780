import { Input, DatePicker, Row, Col, Select } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';

import WithLocale from '../../../hoc/WithLocale';

const { Search } = Input;

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataFilter: {
        search: '',
        vehicle_type: '',
        delivery_date: '',
        status: '',
        page: 1,
      },
    };
  }

  onFilter = (value, type) => {
    const { dataFilter } = this.state;
    if (type === 'delivery_date') dataFilter[type] = moment(value).toISOString();
    else dataFilter[type] = value;
    this.setState({ dataFilter });
    this.props.onSearch(dataFilter);
  };

  render() {
    return (
      <Row gutter={20} style={{ marginBottom: '15px' }}>
        <Col span={12} lg={6}>
          <div style={{ marginBottom: '10px' }}>Mã đơn hoặc tên khách hàng:</div>
          <div style={{ display: 'flex' }}>
            <Search
              onSearch={(value) => this.onFilter(value, 'search')}
              style={{ width: 400, marginRight: '10px' }}
              // defaultValue={defaultValues.name}
              placeholder="Gõ để tìm kiếm"
              enterButton
              allowClear
            />
          </div>
        </Col>
        <Col span={12} lg={6}>
          <div style={{ marginBottom: '10px' }}>Ngày giao hàng:</div>
          <DatePicker
            onChange={(value) => this.onFilter(value, 'delivery_date')}
            style={{ width: '100%' }}
            placeholder="Chọn ngày giao hàng"
            format="DD/MM/YYYY"
            enterButton
          />
        </Col>
        <Col span={12} lg={6}>
          <div style={{ marginBottom: '10px' }}>Loại xe:</div>
          <Select
            onChange={(value) => this.onFilter(value, 'vehicle_type')}
            style={{ width: '100%' }}
            allowClear
            placeholder="Chọn loại xe">
            <Select.Option key="truck" value="truck">
              Xe tải
            </Select.Option>
            <Select.Option key="motor" value="motor">
              Xe gắn máy
            </Select.Option>
          </Select>
        </Col>
        <Col span={12} lg={6}>
          <div style={{ marginBottom: '10px' }}>Trạng thái:</div>
          <Select
            onChange={(value) => this.onFilter(value, 'status')}
            style={{ width: '100%' }}
            allowClear
            placeholder="Chọn trạng thái">
            <Select.Option key="Ordered" value="Ordered">
              Đặt hàng
            </Select.Option>
            <Select.Option key="Scheduled" value="Scheduled">
              Đã lên lịch
            </Select.Option>
            <Select.Option key="OnRoute" value="On Route">
              Đang giao
            </Select.Option>
            <Select.Option key="Completed" value="Completed">
              Hoàn thành
            </Select.Option>
            <Select.Option key="Failed" value="Failed">
              Thất bại
            </Select.Option>
          </Select>
        </Col>
      </Row>
    );
  }
}

export default WithLocale(Filter);
