/* eslint-disable import/no-unresolved */
import {
  RetweetOutlined,
  CheckCircleOutlined,
  EditOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import {
  Table,
  Switch,
  Select,
  Input,
  Popover,
  Row,
  Button,
  notification,
  Avatar,
  Col,
} from 'antd';
import React, { Component } from 'react';

// import CompanySearch from '../../../components/FormItems/CompanySearch';
// import WithLoading from '../../../hoc/WithLoading';
// import FormResetPassword from './FormResetPassword';
import './List.css';

const Option = Select.Option;

const messageNotification = (status) => ({
  onSuccess: () => {
    notification.open({
      message: 'Thành công',
      description: `${status} tài khoản thành công`,
      type: 'success',
    });
  },
  onError: (errorCode) =>
    notification.open({
      message: 'Lỗi',
      description: `${`${status} tài khoản thất bại`} - ${errorCode}`,
      type: 'error',
    }),
});

const columns = (
  code,
  isEditing,
  userRole,
  allowedRoles,
  allCompanies,
  handleChangeAvatar,
  handleChangeName,
  handleChangeRole,
  handleChangeEmail,
  handleChangePhoneNumber,
  handleCheck,
  handleEdit,
  togglePopoverResetPassword,
  handleSubmitResetPassword,
  invisiblePopover,
  activeDeactiveUser,
) => [
  {
    title: <b>Họ tên</b>,
    dataIndex: 'full_name',
    render: (value, record) =>
      isEditing && code === record.code ? (
        <Input defaultValue={value} style={{ maxWcodeth: '150px' }} onChange={handleChangeName} />
      ) : (
        value
      ),
  },
  {
    title: <b>Tài khoản</b>,
    dataIndex: 'username',
  },
  {
    title: <b>Vai trò</b>,
    dataIndex: 'role_code',
    render: (value, record) => {
      const role = allowedRoles.find((item) => item.code === value);
      if (isEditing && code === record.code) {
        return (
          <div>
            <Select defaultValue={value} onChange={handleChangeRole} style={{ minWidth: '100px' }}>
              {allowedRoles?.map((item) => (
                <Option value={item.code} key={item.code}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </div>
        );
      }

      return role?.name || value;
    },
  },
  {
    title: <b>Email</b>,
    dataIndex: 'email',
    render: (value, record) =>
      isEditing && code === record.code ? (
        <Input defaultValue={value} onChange={handleChangeEmail} />
      ) : (
        value
      ),
  },
  {
    title: <b>Số điện thoại</b>,
    dataIndex: 'phone_number',
    render: (value, record) =>
      isEditing && code === record.code ? (
        <Input defaultValue={value} onChange={handleChangePhoneNumber} />
      ) : (
        value
      ),
  },
  {
    title: <b>Công ty</b>,
    dataIndex: 'company_code',
    render: (value) => {
      const company = value ? allCompanies.find((item) => item.code === value) : null;

      return company?.name || value;
    },
  },
  {
    title: <b>Cập nhật</b>,
    align: 'center',
    render: (value, record) => (
      // const isActive = record.status === true;
      <>
        {isEditing && code === record.code ? (
          <Button onClick={(e) => handleCheck(e, record)} shape="circle" type="primary">
            <CheckCircleOutlined />
          </Button>
        ) : (
          <Button onClick={(e) => handleEdit(e, record)} type="success" shape="circle">
            <EditOutlined />
          </Button>
        )}
        <Popover
          placement="left"
          title="Đặt lại mật khẩu của người dùng này ?"
          content={
            <Row>
              <Col span={24}>
                {/* <FormResetPassword onSubmit={handleSubmitResetPassword} accountInfo={record} /> */}
                <Button
                  type="primary"
                  onClick={() => handleSubmitResetPassword(record.code)}
                  shape="round"
                  style={{ float: 'right' }}>
                  <CheckOutlined />
                  OK
                </Button>
              </Col>
            </Row>
          }
          trigger="click"
          {...(invisiblePopover ? { visible: false } : null)}>
          <Button
            style={{ marginLeft: '10px' }}
            type="default"
            shape="circle"
            title="Đổi mật khẩu"
            onClick={() => togglePopoverResetPassword()}>
            <RetweetOutlined />
          </Button>
        </Popover>

        <Switch
          style={{ marginLeft: '10px' }}
          defaultChecked={record.status}
          onChange={(value) =>
            !value
              ? activeDeactiveUser(record.code, messageNotification('Vô hiệu hóa'))
              : activeDeactiveUser(record.code, messageNotification('Kích hoạt'))
          }
        />
      </>
    ),
  },
];

class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      newUser: {
        code: '',
        role_code: '',
        full_name: '',
        company_code: '',
        email: '',
      },
      invisiblePopover: true,
    };
  }

  handleEdit = (e, record) => {
    e.preventDefault();
    this.setState(() => ({
      isEditing: true,
      newUser: {
        code: record.code,
        role_code: record.role_code,
        full_name: record.full_name,
        company_code: record.company_code,
        email: record.email,
      },
    }));
  };

  handleChangeAvatar = () => {};

  handleChangeRole = (value) => {
    const { newUser } = this.state;
    this.setState({
      newUser: {
        ...newUser,
        role_code: value,
      },
    });
  };

  handleChangeName = (e) => {
    const { newUser } = this.state;
    const { value } = e.target;
    this.setState({
      newUser: {
        ...newUser,
        full_name: value,
      },
    });
  };

  handleChangePhoneNumber = (e) => {
    const { newUser } = this.state;
    const { value } = e.target;
    this.setState({
      newUser: {
        ...newUser,
        phone_number: value,
      },
    });
  };

  handleChangeCompanyCode = () => {
    const { newUser } = this.state;
    this.setState({
      newUser: {
        ...newUser,
        company_code: value,
      },
    });
  };

  handleCheck = (e, oldRecord) => {
    e.preventDefault();
    this.props.onUpdateUser(oldRecord, this.state.newUser);
    this.setState(() => ({
      isEditing: false,
      newUser: {
        code: '',
        role_code: '',
        full_name: '',
        company_code: '',
        email: '',
        phone_number: '',
      },
    }));
  };

  togglePopoverResetPassword = () => {
    const { invisiblePopover } = this.state;
    this.setState({ invisiblePopover: !invisiblePopover });
  };

  handleSubmitResetPassword = (code) => {
    const { resetPassword } = this.props;
    resetPassword(code, {
      onSuccess: () => {
        this.setState({
          invisiblePopover: true,
        });
        notification.open({
          message: 'Thành công',
          description: `Cập nhật mật khẩu thành công`,
          type: 'success',
        });
      },
      onError: (errorCode) =>
        notification.open({
          message: 'Lỗi',
          description: `${'Cập nhật mật khẩu thất bại'} - ${errorCode}`,
          type: 'error',
        }),
    });
  };

  handleChangeEmail = (e) => {
    const { value } = e.target;
    const { newUser } = this.state;
    this.setState({
      newUser: {
        ...newUser,
        email: value,
      },
    });
  };
  render() {
    const {
      dataSource,
      meta: { page, page_size, total },
      onChange,
      onShowSizeChange,
      activeDeactiveUser,
      userRole,
      allCompanies,
      allowedRoles,
    } = this.props;
    const { code } = this.state.newUser;
    const { isEditing, invisiblePopover } = this.state;

    const userPageColumns = columns(
      code,
      isEditing,
      userRole,
      allowedRoles,
      allCompanies,
      this.handleChangeAvatar,
      this.handleChangeName,
      this.handleChangeRole,
      this.handleChangeEmail,
      this.handleChangePhoneNumber,
      this.handleCheck,
      this.handleEdit,
      this.togglePopoverResetPassword,
      this.handleSubmitResetPassword,
      invisiblePopover,
      activeDeactiveUser,
    );

    return (
      <Table
        size="small"
        dataSource={dataSource}
        columns={userPageColumns}
        rowKey="code"
        pagination={{
          current: page,
          page_size,
          total,
          showSizeChanger: true,
          onShowSizeChange,
          onChange,
          showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total} tài khoản`,
        }}
      />
    );
  }
}

export default UserList;
