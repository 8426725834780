import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { PREFIX, typesWithPrefix } from './config';
import { API_URLS } from '../config/api';
import { apiCallPromise } from '../utils/api';

const _types = typesWithPrefix(PREFIX.DELIVERY_VOUCHER);

const types = {
  GET_DELIVERY_VOUCHER: _types('GET_DELIVERY_VOUCHER'),
  ADD_DELIVERY_VOUCHER: _types('GET_DELIVERY_VOUCHER'),
  UPDATE_DELIVERY_VOUCHER: _types('GET_DELIVERY_VOUCHER'),
  GET_DELIVERY_VOUCHER_BY_CODE: _types('GET_DELIVERY_VOUCHER_BY_CODE'),
  CHANGE_STATE: _types('CHANGE_STATE'),
};

const initialState = {
  data: null,
  isFetching: false,
  didInvalidate: true,
  currentDeliveryVoucher: {},
  meta: {
    pageSize: 10,
    page: 1,
    total: 0,
  },
};

const thunkActions = {
  getDeliveryVoucher: createAsyncThunk(types.GET_DELIVERY_VOUCHER, async (query) => {
    const params = {
      page: query.page,
      page_size: query.page_size,
      state: query.state,
      product_codes: query.product_codes?.length ? query.product_codes.join(',') : undefined,
      storage_code: query.storage_code,
    };

    const api = API_URLS.DELIVERY_VOUCHER.getDeliveryVoucher(params);

    const { response, error } = await apiCallPromise(api);

    if (error) rejectWithValue(params);

    return response?.data;
  }),

  getDeliveryVoucherById: createAsyncThunk(types.GET_DELIVERY_VOUCHER_BY_CODE, async (code) => {
    const api = API_URLS.DELIVERY_VOUCHER.getDeliveryVoucherById(code);
    const { response, error } = await apiCallPromise(api);

    if (!error && response.status === 200 && response.data.success === true) {
      if (meta && meta.onSuccess) meta.onSuccess();
      return { data: response.data.data };
    } else {
      if (meta && meta.onError) meta.onError(error);
      rejectWithValue();
    }
  }),

  addDeliveryVoucher: createAsyncThunk(types.ADD_DELIVERY_VOUCHER, async ({ payload, meta }) => {
    const api = API_URLS.DELIVERY_VOUCHER.insertDeliveryVoucher(payload);
    const { response, error } = await apiCallPromise(api);

    if (!error && (response.status === 200 || response.status === 201)) {
      if (meta && meta.onSuccess) {
        meta.onSuccess();
        fulfillWithValue({ response });
      }
    } else {
      if (meta && meta.onError) meta.onError(error);
      rejectWithValue({ error });
    }
  }),

  updateDeliveryVoucher: createAsyncThunk(
    types.UPDATE_DELIVERY_VOUCHER,
    async ({ payload, meta }) => {
      const api = API_URLS.DELIVERY_VOUCHER.updateDeliveryVoucher(payload.code, payload.payload);

      const { response, error } = await apiCallPromise(api);

      if (meta) {
        if (!error && response.status === 200) {
          meta.onSuccess();
        } else {
          meta.onError();
        }
      }

      return response.data;
    },
  ),

  changeState: createAsyncThunk(types.CHANGE_STATE, async ({ payload, meta }) => {
    const api = API_URLS.DELIVERY_VOUCHER.changeStateDeliveryVoucher(payload.code, payload.payload);

    const { response, error } = await apiCallPromise(api);
    if (meta) {
      if (!error && response.status === 200) {
        meta.onSuccess();
      } else {
        meta.onError();
      }
    }

    return response.data;
  }),
};

const deliveryVoucherSlice = createSlice({
  name: 'deliveryVoucherSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(thunkActions.getDeliveryVoucher.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(thunkActions.getDeliveryVoucher.fulfilled, (state, { payload }) => {
        const { data, page, page_size, total } = payload;
        state.data = data;
        state.didInvalidate = true;
        state.isFetching = false;
        state.meta.page = page;
        state.meta.pageSize = page_size;
        state.meta.total = total;
      })
      .addCase(thunkActions.getDeliveryVoucher.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(thunkActions.getDeliveryVoucherById.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(thunkActions.getDeliveryVoucherById.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.currentDeliveryVoucher = payload;
      })
      .addCase(thunkActions.getDeliveryVoucherById.rejected, (state) => {
        state.isFetching = false;
      })
      .addMatcher(
        isAnyOf(
          thunkActions.addDeliveryVoucher.fulfilled,
          thunkActions.updateDeliveryVoucher.fulfilled,
          thunkActions.changeState.fulfilled,
        ),
        (state) => {
          state.isFetching = false;
          state.didInvalidate = true;
        },
      )
      .addMatcher(
        isAnyOf(
          thunkActions.addDeliveryVoucher.pending,
          thunkActions.updateDeliveryVoucher.pending,
          thunkActions.changeState.pending,
        ),
        (state) => {
          state.isFetching = true;
        },
      )
      .addMatcher(
        isAnyOf(
          thunkActions.addDeliveryVoucher.rejected,
          thunkActions.updateDeliveryVoucher.rejected,
          thunkActions.changeState.rejected,
        ),
        (state) => {
          state.isFetching = false;
          state.didInvalidate = true;
        },
      );
  },
});

export const actions = { ...deliveryVoucherSlice.actions, ...thunkActions };
export const { reducer } = deliveryVoucherSlice;
