import { CheckOutlined, ReloadOutlined } from '@ant-design/icons';
import { Form, Row, Col, Input, Button, Typography, TimePicker, Select } from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import TomtomSearch from '../../../components/FormItems/TomtomSearch';
import { actions as tomtomActions } from '../../../redux/TomtomRedux';

const { setMapAddress, setMapLatitude, setMapLongitude, clearStore } = tomtomActions;

const FormItem = Form.Item;
const { TextArea } = Input;
const { Option } = Select;
const { Title } = Typography;
const layout = { labelCol: { span: 24 } };

class CustomerForm extends Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();
    this.state = {
      formRef: this.formRef,
      currentCustomerId: props?.defaultValue?.id || '',
      listContact: props.defaultValue.contacts || [],
      listTimeWindows: props.defaultValue.time_windows || [],
      start_location: props?.defaultValue?.start_location || '',
      finish_location: props?.defaultValue?.finish_location || '',
      address: props?.defaultValue?.address || '',
      longitude: props?.defaultValue?.longitude || 0,
      longitudeF: props?.defaultValue?.longitude || 0,
      latitude: props?.defaultValue?.latitude || 0,
      latitudeF: props?.defaultValue?.latitude || 0,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { defaultValue, currentLatitude, currentLongitude } = nextProps;
    const { currentCustomerId, latitudeF, longitudeF } = prevState;
    const value = {};

    if (defaultValue?.code && defaultValue?.code !== currentCustomerId) {
      const contactArr = [];
      const timeArr = [];
      prevState.formRef?.current?.setFieldsValue(defaultValue);

      if (defaultValue.contacts?.length)
        defaultValue.contacts.forEach((contact) => contactArr.push(contact));

      if (defaultValue.time_windows?.length)
        defaultValue.time_windows.forEach((time) =>
          timeArr.push([
            moment({
              hour: Number(time.from_time?.slice(0, 2)),
              minute: Number(time.from_time?.slice(3)),
            }),
            moment({
              hour: Number(time.to_time?.slice(0, 2)),
              minute: Number(time.to_time?.slice(3)),
            }),
          ]),
        );

      prevState.formRef?.current?.setFieldsValue({ address: defaultValue?.address });
      nextProps.setTomtomSearchAddress(defaultValue?.address);
      nextProps.setTomtomSearchLatitude(defaultValue?.latitude);
      nextProps.setTomtomSearchLongitude(defaultValue?.longitude);

      value.currentCustomerId = defaultValue?.code;
      value.listContact = contactArr;
      value.listTimeWindows = timeArr;
      value.start_location = defaultValue?.start_location || '';
      value.finish_location = defaultValue?.finish_location || '';
    }
    if (currentLatitude !== latitudeF) {
      value.latitudeF = currentLatitude;
      value.latitude = currentLatitude;
      prevState?.formRef?.current?.setFieldsValue({
        latitude: currentLatitude,
      });
    }
    if (currentLongitude !== longitudeF) {
      value.longitudeF = currentLongitude;
      value.longitude = currentLongitude;
      prevState?.formRef?.current?.setFieldsValue({
        longitude: currentLongitude,
      });
    }

    return value;
  }

  componentDidMount() {
    const { defaultValue } = this.props;
    if (defaultValue.address && defaultValue.latitude && defaultValue.longitude) {
      this.props.setTomtomSearchAddress(defaultValue?.address);
      this.props.setTomtomSearchLatitude(defaultValue?.latitude);
      this.props.setTomtomSearchLongitude(defaultValue?.longitude);
    }
  }

  componentWillUnmount() {
    this.props.clearTomtomStore();
  }

  onReset = () => this.formRef.current.resetFields();

  onSelectAddress = (value) =>
    this.formRef?.current?.setFieldsValue({
      address: value.address,
      longitude: value.lng,
      latitude: value.lat,
    });

  onFinish = (value) => {
    this.props.onSubmit({ ...value, time_windows: this.state.listTimeWindows });
  };

  updateListContact = (index, value) => {
    const { listContact } = this.state;
    const temp = [...listContact];
    temp[index] = { ...temp[index], ...value };
    this.setState({ listContact: temp });
    this.formRef.current.setFieldsValue({ contacts: temp });
  };

  updateListTimeWindow = (index, value) => {
    const { listTimeWindows } = this.state;
    const temp = [...listTimeWindows];
    temp[index] = { ...temp[index], ...value };
    this.setState({ listTimeWindows: temp });
    this.formRef.current.setFieldsValue({ time_windows: temp });
  };

  handleChangePhoneNumber = (index, e) => {
    const { value } = e.target;
    const regex = /^-?\d*(\.\d*)?$/;

    if (regex.test(value) || value === '' || value === '-')
      this.updateListContact(index, { phone_number: value });
  };

  renderContactList = () => {
    const { defaultValue } = this.props;
    const { listContact } = this.state;
    const listInput = [];
    for (let i = 0; i < listContact?.length; i++) {
      listInput.push(
        <Row
          span={24}
          gutter={15}
          key={`${defaultValue.code}-listContact-${i}`}
          style={{ marginTop: -5, marginBottom: 15 }}>
          <Col span={12}>
            <Input
              value={listContact[i]?.name}
              onChange={(e) => this.updateListContact(i, { name: e.target.value })}
              placeholder="Nhập tên người nhận hàng"
              style={{ width: '100%' }}
            />
          </Col>
          <Col span={12}>
            <Input
              value={listContact[i]?.phone_number}
              onChange={(e) => this.handleChangePhoneNumber(i, e)}
              placeholder="Nhập số điện thoại nhận hàng"
              style={{ width: '100%' }}
            />
          </Col>
        </Row>,
      );
    }

    // new blank row for adding more
    listInput.push(
      <Row
        key={`${defaultValue.code}-listContact-${listContact.length}`}
        span={24}
        gutter={15}
        style={{ marginTop: -5, marginBottom: 15 }}>
        <Col span={12}>
          <Input
            onChange={(e) => this.updateListContact(listContact.length, { name: e.target.value })}
            placeholder="Nhập tên người nhận hàng"
            style={{ width: '100%' }}
          />
        </Col>
        <Col span={12}>
          <Input
            onChange={(e) => this.handleChangePhoneNumber(listContact.length, e)}
            placeholder="Nhập số điện thoại nhận hàng"
            style={{ width: '100%' }}
          />
        </Col>
      </Row>,
    );

    return listInput;
  };

  renderTimeWindowsList = () => {
    const { defaultValue } = this.props;
    const { listTimeWindows } = this.state;
    const listInput = [];
    for (let i = 0; i < listTimeWindows?.length; i++) {
      listInput.push(
        <Row
          span={24}
          gutter={15}
          key={`${defaultValue.code}-listTimeWindows-${i}`}
          style={{ marginTop: -5, marginBottom: 15 }}>
          <Col span={24}>
            <TimePicker.RangePicker
              format="HH:mm"
              value={listTimeWindows[i] ? listTimeWindows[i] : ''}
              placeholder={['Bắt đầu', 'Kết thúc']}
              style={{ width: '100%' }}
              onChange={(e) => this.updateListTimeWindow(i, e)}
            />
          </Col>
        </Row>,
      );
    }

    // new blank row for adding more
    listInput.push(
      <Row
        key={`${defaultValue.code}-listTimeWindows-${listTimeWindows.length}`}
        span={24}
        gutter={15}
        style={{ marginTop: -5, marginBottom: 15 }}>
        <Col span={24}>
          <TimePicker.RangePicker
            format="HH:mm"
            placeholder={['Bắt đầu', 'Kết thúc']}
            style={{ width: '100%' }}
            onChange={(e) => this.updateListTimeWindow(listTimeWindows.length, e)}
          />
        </Col>
      </Row>,
    );

    return listInput;
  };

  render() {
    const { isEditMode, defaultValue } = this.props;

    const FormTitle = isEditMode ? 'Sửa thông tin khách hàng' : 'Thêm khách hàng';

    return (
      <>
        <Title level={3} description="Thông báo">
          {FormTitle}
        </Title>
        <Form ref={this.formRef} initialValues={defaultValue} onFinish={this.onFinish} {...layout}>
          <Row gutter={10}>
            <Col span={12}>
              <Row gutter={10}>
                <Col span={24}>
                  <FormItem label="Mã khách hàng" name="code">
                    <Input disabled={isEditMode} placeholder="Nhập mã khách hàng..." />
                  </FormItem>
                </Col>
                <Col span={24} lg={16}>
                  <FormItem
                    label="Tên khách hàng"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: 'Nhập tên khách hàng!',
                      },
                    ]}>
                    <Input placeholder="Nhập tên khách hàng..." />
                  </FormItem>
                </Col>
                <Col span={24} lg={8}>
                  <FormItem label="Phân loại" name="type">
                    <Select style={{ width: '100%' }} placeholder="Phân loại">
                      <Option value="retail">Khách lẻ</Option>
                      <Option value="agency">Đại lý</Option>
                    </Select>
                  </FormItem>
                </Col>
                {/* <Col span={24}>
                <FormItem
                  label="Địa chỉ"
                  name="address"
                  rules={[
                    {
                      required: true,
                      message: 'Nhập địa chỉ!',
                    },
                  ]}>
                  <Input placeholder="Chọn địa chỉ..." style={{ width: '100%' }} />
                </FormItem>
              </Col>

              <Row span={24} gutter={15}>
                <Col span={12}>
                  <FormItem
                    label="Kinh độ"
                    name="longitude"
                    rules={[
                      {
                        required: true,
                        message: 'Nhập kinh độ!',
                      },
                    ]}>
                    <Input placeholder="Nhập kinh độ..." style={{ width: '100%' }} />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    label="Vĩ độ"
                    name="latitude"
                    rules={[
                      {
                        required: true,
                        message: 'Nhập vĩ độ!',
                      },
                    ]}>
                    <Input placeholder="Nhập vĩ độ..." style={{ width: '100%' }} />
                  </FormItem>
                </Col>
              </Row> */}

                <Col span={24}>
                  <Form.Item
                    label="Thông tin nhận hàng"
                    name="contacts"
                    initialValue={this.state.listContact}
                    style={{ display: 'flex' }}>
                    <Input hidden />
                  </Form.Item>
                  {this.renderContactList()}
                </Col>
              </Row>
              <Row span={24} gutter={15}>
                <Col span={24}>
                  <Form.Item
                    label="Địa chỉ"
                    name="address"
                    rules={[
                      {
                        required: true,
                        message: 'Nhập địa chỉ hoặc chọn vị trí trên bản đồ!',
                      },
                    ]}
                    initialValue={this.state.address}>
                    <Input style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </Row>
              <Row span={24} gutter={15}>
                <Col span={12}>
                  <Form.Item
                    label="Kinh độ"
                    name="longitude"
                    rules={[
                      {
                        required: true,
                        message: 'Chọn vị trí trên bản đồ',
                      },
                    ]}
                    initialValue={this.state.longitude}
                    style={{ display: 'flex' }}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Vĩ độ"
                    name="latitude"
                    rules={[
                      {
                        required: true,
                        message: 'Chọn vị trí trên bản đồ',
                      },
                    ]}
                    initialValue={this.state.latitude}
                    style={{ display: 'flex' }}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row span={24} gutter={15}>
                <Col span={12}>
                  <Form.Item
                    label="Thời gian nhận hàng"
                    name="time_windows"
                    initialValue={this.state.listTimeWindows}
                    style={{ display: 'flex' }}>
                    <Input hidden />
                  </Form.Item>
                </Col>
              </Row>

              {this.renderTimeWindowsList()}
            </Col>

            <Col span={12}>
              <FormItem name="address">
                <TomtomSearch
                  isEditMode={isEditMode}
                  onSelectAddress={this.onSelectAddress}
                  smallInput
                />
              </FormItem>
              {/* <FormItem>
                <Button
                  onClick={this.onSelectAddress}
                  type="primary"
                  shape="round"
                  icon={<CheckOutlined />}>
                  Chọn địa điểm
                </Button>
              </FormItem> */}
            </Col>

            <Col span={24}>
              <FormItem label="Ghi chú" name="note">
                <TextArea placeholder="Thêm ghi chú..." />
              </FormItem>
            </Col>
          </Row>

          <FormItem style={{ float: 'right', marginTop: '10px' }}>
            <Button
              type="primary"
              htmlType="submit"
              shape="round"
              icon={<CheckOutlined />}
              style={{ marginRight: 8 }}>
              Lưu
            </Button>
            <Button htmlType="reset" onClick={this.onReset} shape="round" icon={<ReloadOutlined />}>
              Nhập lại
            </Button>
          </FormItem>
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  currentAddress: state.tomtomReducer.address,
  currentLatitude: state.tomtomReducer.latitude,
  currentLongitude: state.tomtomReducer.longitude,
});

const mapDispatchToProps = (dispatch) => ({
  setTomtomSearchAddress: (payload) => dispatch(setMapAddress(payload)),
  setTomtomSearchLatitude: (payload) => dispatch(setMapLatitude(payload)),
  setTomtomSearchLongitude: (payload) => dispatch(setMapLongitude(payload)),
  clearTomtomStore: () => dispatch(clearStore()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerForm);
