/* eslint-disable import/no-unresolved */
import { Table } from 'antd';
import React, { Component } from 'react';
// import { Link } from 'react-router-dom';

import '../../../assets/css/style.css';

const columns = (onSetEditRole) => [
  {
    title: <b>Tên</b>,
    dataIndex: 'name',
    key: 'name',
    render: (_, record) => {
      const edit = () => {
        onSetEditRole({ ...record });
      };

      return <a onClick={edit}>{record.name}</a>;
    },
  },

  {
    title: <b>Mô tả</b>,
    dataIndex: 'description',
  },
];

class FeatureList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page_size: 1,
    };
  }

  render() {
    const {
      dataSource,
      onSetEditRole,
      meta: { page, page_size, total },
      onPageChange,
    } = this.props;
    return (
      <>
        <Table
          size="small"
          dataSource={dataSource}
          columns={columns(onSetEditRole)}
          rowKey="code"
          pagination={{
            // page,
            current: page,
            page_size,
            total,
            onChange: onPageChange,
            showSizeChanger: true,
            showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total} đặc tính`,
          }}
          scroll={{ x: 'max-content' }}
        />
      </>
    );
  }
}

export default FeatureList;
