import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ROUTER from '../../../config/router';
import AddCompany from '../../Company/add';
import EditCompany from '../../Company/edit';
import CompanyPage from '../../Company/index';

const Companies = () => (
  <Switch>
    <Route exact path={ROUTER.COMPANY.INDEX} component={CompanyPage} />
    <Route exact path={ROUTER.COMPANY.ADD_COMPANY} component={AddCompany} />
    <Route exact path={ROUTER.COMPANY.EDIT_COMPANY} component={EditCompany} />
  </Switch>
);
export default Companies;
