import lodash from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { actions as categoryActions } from '../../redux/CategoryRedux';
import SelectSearch from './Select';

const { getAllCategories } = categoryActions;

class CategorySearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prevSelectedValue: [],
      defaultValue: [],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.allCategory && nextProps.allCategory.length) {
      if (!lodash.isEqual(nextProps.selectedValue, prevState.prevSelectedValue)) {
        const nextState = { prevSelectedValue: nextProps.selectedValue };
        if (nextProps.selectedValue && nextProps.selectedValue.length) {
          const { selectedValue } = nextProps;
          if (typeof selectedValue === 'string') {
            nextState.defaultValue = CategorySearch.getProductByCode([selectedValue], nextProps);
          } else if (typeof selectedValue === 'object') {
            if (Array.isArray(selectedValue)) {
              if (typeof selectedValue[0] === 'string') {
                nextState.defaultValue = CategorySearch.getProductByCode(selectedValue, nextProps);
              } else if (typeof selectedValue[0] === 'object') {
                nextState.defaultValue = selectedValue;
              }
            } else {
              nextState.defaultValue = selectedValue;
            }
          }
        } else {
          nextState.defaultValue = [];
        }
        return nextState;
      }
    }
    return null;
  }

  componentDidMount() {
    this.props.getAllCategories();
  }

  searchProductByName = async (name) => {
    const params = {
      search: name,
      page: 1,
      page_size: 50,
    };
    const api = API_URLS.PRODUCT.getFilteredProducts(params);
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      if (response.data.data && response.data.data.length) {
        return response.data.data.map((product) => ({
          key: product.code,
          label: `${product.name}`,
          // defaultValue: response.data.data,
        }));
      }
    }

    return [];
  };

  static getProductByCode = (codes, props) => {
    if (codes && codes.length) {
      const { allCategory } = props;
      if (!allCategory.length) {
        return [];
      }
      const defaultValue = [];
      codes.forEach((code) => {
        const category_code = code.trim();
        if (!category_code) return;
        const foundCategory = allCategory.find((p) => p.code === category_code);
        const category = foundCategory
          ? {
              key: category_code,
              label: `${foundCategory.name}`,
            }
          : { key: category_code, label: `[Not found] ${category_code}` };
        defaultValue.push(category);
      });
      return defaultValue;
    }
    return [];
  };

  render() {
    const { allCategory, onChange } = this.props;
    const dataSource = allCategory;
    return (
      <SelectSearch
        {...this.props}
        onChange={onChange}
        selectedValue={this.state.defaultValue}
        dataSource={dataSource}
        placeholder={this.props.placeholder || 'Chọn nhóm hàng'}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  allCategory: state.categoryReducer.allItems,
});

const mapDispatchToProps = (dispatch) => ({
  getAllCategories: () => dispatch(getAllCategories()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CategorySearch);
