/* eslint-disable import/no-unresolved */
import { Button, Switch, Table, notification, Checkbox } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import ROUTER from '../../../config/router';

import '../../../assets/css/style.css';
import './style.css';

const rowSelection = (onSelectChange, onSelectRow, onSelectAllRow) => ({
  onChange: (selectedRowKeys, record) => onSelectChange(selectedRowKeys, record),
  onSelect: (record, selected) => onSelectRow(record, selected),
  getCheckboxProps: (record) => ({
    name: record.name,
  }),
  onSelectAll: (selected, selectedRows, changeRows) =>
    onSelectAllRow(selected, selectedRows, changeRows),
  columnWidth: 10,
});

const columns = (
  allVehicles,
  collapseTable,
  isImportFromFile,
  activeSecretCode,
  deactiveSecretCode,
  changeStateDriver,
  mode,
) => {
  const cols = [
    {
      title: <b>Mã lái xe</b>,
      dataIndex: 'code',
    },

    {
      title: <b>Tên lái xe</b>,
      dataIndex: 'name',
      key: 'name',
      render: isImportFromFile
        ? (value) => value
        : (value, record) => (
            <>
              <Link
                to={ROUTER.DRIVER.EDIT_DRIVER.replace(':driverInfoId', record.code)}
                key={`${record.code}-driver-name`}>
                {value}
              </Link>
            </>
          ),
    },

    {
      title: <b>Số điện thoại</b>,
      dataIndex: 'phone_number',
      width: 110,
      key: 'phone_number',
      className: collapseTable ? 'hide-col' : '',
    },
    {
      title: <b>Trạng thái giao vận</b>,
      dataIndex: 'status',
      width: 110,
    },
    {
      title: <b>Ngày sinh</b>,
      dataIndex: 'date_of_birth',
      key: 'date_of_birth',
      width: 120,
      render: (text) => (text ? <div>{moment(text).format('DD/MM/YYYY')}</div> : null),
      className: collapseTable ? 'hide-col' : '',
    },
    {
      title: <b>Phương tiện</b>,
      dataIndex: 'default_vehicle_code',
      width: 90,
      render: (value, record) => {
        const vehicle = record.default_vehicle_code
          ? allVehicles.find((item) => item.code === record.default_vehicle_code)
          : '';
        return vehicle?.label;
      },
    },
    {
      title: <b>Thời gian làm việc</b>,
      dataIndex: 'workTime',
      width: 150,
      render: (text, record) =>
        record.work_time_from && record.work_time_to ? (
          <div>
            {record.work_time_from} - {record.work_time_to}
          </div>
        ) : null,
    },
    {
      title: <b>Mã đăng nhập App</b>,
      dataIndex: 'secret_code',
      render: (value, record) => (
        <div>
          {value !== '' ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ marginRight: '15px' }}>{value}</div>
              <Button
                type="primary"
                danger
                shape="round"
                onClick={() => {
                  deactiveSecretCode(record.code, {
                    onSuccess: () => {
                      notification.open({
                        message: 'Hủy mã đăng nhập thành công',
                        type: 'success',
                      });
                    },
                    onError: (errorCode) =>
                      notification.open({
                        message: 'Hủy mã đăng nhập không thành công',
                        type: 'error',
                      }),
                  });
                }}>
                Hủy
              </Button>
            </div>
          ) : (
            <Button
              type="primary"
              shape="round"
              onClick={() => {
                activeSecretCode(record.code, {
                  onSuccess: () => {
                    notification.open({
                      message: 'Thêm mã đăng nhập thành công',
                      type: 'success',
                    });
                  },
                  onError: (errorCode) =>
                    notification.open({
                      message: 'Thêm mã đăng nhập không thành công',
                      type: 'error',
                    }),
                });
              }}>
              Tạo mã đăng nhập
            </Button>
          )}
        </div>
      ),
    },
    // {
    //   title: <b>Chuyển đơn</b>,
    //   render: () => (
    //     <Button   type="primary"
    //           shape="round"> Chuyển đơn</Button>
    //   ),
    // },
    {
      title: <b>Hoạt động</b>,
      dataIndex: 'active',
      render: (value, record) => (
        <Switch
          defaultChecked={value}
          onChange={(value) => {
            changeStateDriver(
              {
                active: value,
                driver_codes: [record.code],
              },
              {
                onSuccess: () => {
                  notification.open({
                    message: 'Thay đổi hoạt động thành công',
                    type: 'success',
                  });
                },
                onError: (errorCode) =>
                  notification.open({
                    message: 'Thay đổi hoạt động không thành công',
                    type: 'error',
                  }),
              },
            );
          }}
        />
      ),
    },
  ];
  if (mode === 'routing') {
    delete cols[0];
    delete cols[2];
    delete cols[4];
    delete cols[6];
  }

  return cols;
};

class DriverList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page_size: 1,
    };
  }

  render() {
    const {
      dataSource,
      meta,
      onPageChange,
      allVehicles,
      isImportFromFile,
      onSelectChange,
      showRowSelection,
      onSelectRow,
      collapseTable,
      onSelectAllRow,
      activeSecretCode,
      deactiveSecretCode,
      changeStateDriver,
      mode,
    } = this.props;

    const driverColumns = columns(
      allVehicles,
      collapseTable,
      isImportFromFile,
      activeSecretCode,
      deactiveSecretCode,
      changeStateDriver,
      mode,
    );

    return (
      <>
        <Table
          size="small"
          dataSource={dataSource}
          columns={driverColumns}
          rowKey={(record) =>
            isImportFromFile ? `${record?.['name'] + record?.['CCCD']}` : record?.code
          }
          pagination={
            mode === 'routing'
              ? false
              : {
                  current: meta?.page,
                  page_size: meta?.page_size || 5,
                  total: meta?.total,
                  onChange: onPageChange,
                  showSizeChanger: !showRowSelection,
                  locale: { items_per_page: '/ trang' },
                  showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total} lái xe`,
                }
          }
          rowSelection={
            showRowSelection ? rowSelection(onSelectChange, onSelectRow, onSelectAllRow) : ''
          }
          scroll={{ x: 'max-content', y: mode === 'routing' && 540 }}
          rowClassName={(record) => {
            if (isImportFromFile && (!record['name'] || !record['phone_number'])) return 'text-red';
          }}
        />
      </>
    );
  }
}

export default DriverList;
