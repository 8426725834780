import { DatePicker } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import DriverSearch from '../../../components/FormItems/DriverSearch';
import { actions as driverActions } from '../../../redux/DriverRedux';

const { RangePicker } = DatePicker;

const Filter = ({ onSearch, getAllDrivers }) => {
  const [fromDate, setFromDate] = useState(moment().subtract(1, 'M'));
  const [toDate, setToDate] = useState(moment());
  const [driverCodes, setDriverCodes] = useState();

  useEffect(() => {
    getAllDrivers();
  }, []);

  const handleDateTimeChange = (value) => {
    setFromDate(value[0]);
    setToDate(value[1]);
    onSearch({
      fromDate: value[0],
      toDate: value[1],
      driverCode: driverCodes,
    });
  };
  const handleChangeDriverCode = (value) => {
    setDriverCodes(value);
    onSearch({
      fromDate,
      toDate,
      driverCode: value,
    });
  };

  return (
    <div>
      <DriverSearch
        // selectedValue={driverCodes}
        onChange={handleChangeDriverCode}
        allowClear
        mode="multiple"
        style={{ width: '220px', maxWidth: '100%', marginRight: '10px' }}
      />
      <RangePicker
        style={{ width: '220px', maxWidth: '100%' }}
        defaultValue={[fromDate, toDate]}
        format="DD/MM/YYYY"
        onChange={handleDateTimeChange}
        ranges={{
          'Hôm nay': [moment(), moment()],
          'Hôm qua': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
          'Tuần này': [moment().startOf('week'), moment().endOf('week')],
          'Tuần trước': [
            moment().subtract(1, 'weeks').startOf('week'),
            moment().subtract(1, 'weeks').endOf('week'),
          ],
          'Tháng này': [moment().startOf('month'), moment().endOf('month')],
          'Tháng trước': [
            moment().subtract(1, 'months').startOf('month'),
            moment().subtract(1, 'months').endOf('month'),
          ],
        }}
        allowClear
        placeholder={['Từ ngày', 'Đến ngày']}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getAllDrivers: () => dispatch(driverActions.getAllDrivers()),
});

export default connect(null, mapDispatchToProps)(Filter);
