import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { PREFIX, typesWithPrefix } from './config';
import { API_URLS } from '../config/api';
import { apiCallPromise } from '../utils/api';

const _types = typesWithPrefix(PREFIX.PRODUCT);

const types = {
  GET_PRODUCTS: _types('GET_PRODUCTS'),
  ADD_PRODUCT: _types('ADD_PRODUCT'),
  UPDATE_PRODUCT: _types('UPDATE_PRODUCT'),
  GET_PRODUCT_BY_CODE: _types('GET_PRODUCT_BY_CODE'),
  UPDATE_PRODUCT_STATUS: _types('UPDATE_PRODUCT_STATUS'),
};

const initialState = {
  data: null,
  isFetching: false,
  didInvalidate: true,
  // currentData: {},
  meta: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
};

const thunkActions = {
  getProducts: createAsyncThunk(types.GET_PRODUCTS, async (query) => {
    const params = {
      page: query.page,
      page_size: query.page_size,
      status: query.status,
      search: query.search,
      category_codes: query.category_codes,
    };
    const api = API_URLS.PRODUCTS.getProducts(params);

    const { response } = await apiCallPromise(api);

    return response.data;
  }),

  addProduct: createAsyncThunk(types.ADD_PRODUCT, async ({ payload, meta }) => {
    const api = API_URLS.PRODUCTS.insertProduct(payload);
    const { response, error } = await apiCallPromise(api);

    if (!error && (response.status === 200 || response.status === 201)) {
      if (meta && meta.onSuccess) {
        meta.onSuccess();
        fulfillWithValue({ response });
      }
    } else {
      if (meta && meta.onError) meta.onError(error);
      rejectWithValue({ error });
    }
  }),

  getProductById: createAsyncThunk(types.GET_PRODUCT_BY_CODE, async (code, { rejectWithValue }) => {
    const api = API_URLS.PRODUCTS.getProductById(code);
    const { response, error } = await apiCallPromise(api);

    if (!error && response.status === 200 && response.data.success === true) {
      if (meta && meta.onSuccess) meta.onSuccess();
      return { data: response.data.data };
    } else {
      if (meta && meta.onError) meta.onError(error);
      rejectWithValue();
    }
  }),

  updateProduct: createAsyncThunk(types.UPDATE_PRODUCT, async ({ payload, meta }) => {
    const api = API_URLS.PRODUCTS.updateProduct(payload.code, payload.payload);

    const { response, error } = await apiCallPromise(api);

    if (meta) {
      if (!error && response.status === 200) {
        meta.onSuccess();
      } else {
        meta.onError();
      }
    }

    return response.data;
  }),

  changeState: createAsyncThunk(types.UPDATE_PRODUCT_STATUS, async ({ payload, meta }) => {
    const api = API_URLS.PRODUCTS.changeStatusProduct(payload.code, payload.payload);

    const { response, error } = await apiCallPromise(api);
    if (meta) {
      if (!error && response.status === 200) {
        meta.onSuccess();
      } else {
        meta.onError();
      }
    }

    return response.data;
  }),
};

const productSlice = createSlice({
  name: 'productSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(thunkActions.getProducts.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(thunkActions.getProducts.fulfilled, (state, { payload }) => {
        const { data, page, page_size, total } = payload;
        state.data = data;
        state.didInvalidate = true;
        state.isFetching = false;
        state.meta.page = page;
        state.meta.pageSize = page_size;
        state.meta.total = total;
      })
      .addCase(thunkActions.getProducts.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(thunkActions.getProductById.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(thunkActions.getProductById.fulfilled, (state, { payload }) => {
        state.isFetching = false;
      })
      .addCase(thunkActions.getProductById.rejected, (state) => {
        state.isFetching = false;
      })
      .addMatcher(
        isAnyOf(
          thunkActions.addProduct.fulfilled,
          thunkActions.updateProduct.fulfilled,
          thunkActions.changeState.fulfilled,
        ),
        (state) => {
          state.isFetching = false;
          state.didInvalidate = true;
        },
      )
      .addMatcher(
        isAnyOf(
          thunkActions.addProduct.pending,
          thunkActions.updateProduct.pending,
          thunkActions.changeState.pending,
        ),
        (state) => {
          state.isFetching = true;
        },
      )
      .addMatcher(
        isAnyOf(
          thunkActions.addProduct.rejected,
          thunkActions.updateProduct.rejected,
          thunkActions.changeState.rejected,
        ),
        (state) => {
          state.isFetching = false;
          state.didInvalidate = true;
        },
      );
  },
});

export const actions = { ...productSlice.actions, ...thunkActions };
export const { reducer } = productSlice;
