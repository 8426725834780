/* eslint-disable import/no-unresolved */
import { Table } from 'antd';
import React, { Component } from 'react';

import '../../../assets/css/style.css';

const columns = (onSetEditPart) => [
  {
    title: <b>Tên</b>,
    dataIndex: 'name',
    render: (value, record) => {
      const edit = () => {
        onSetEditPart(record.code, {
          name: record.name,
          description: record.description,
          expiry_km_from: record.expiry_km_from,
          expiry_km_to: record.expiry_km_to,
          expiry_time_from: record.expiry_time_from,
          expiry_time_to: record.expiry_time_to,
        });
      };

      return <a onClick={edit}>{record.name}</a>;
    },
  },
  {
    title: <b>Hạn sử dụng theo quãng đường</b>,
    dataIndex: 'expiryKm',
    render: (text, record) =>
      record.expiry_km_from === 0 && record.expiry_km_to === 0 ? (
        ''
      ) : record.expiry_km_from === 0 ? (
        <div>Dưới {record.expiry_km_to} km</div>
      ) : record.expiry_km_to === 0 ? (
        <div>Không ít hơn {record.expiry_km_from} km</div>
      ) : (
        <div>
          {record.expiry_km_from} - {record.expiry_km_to} km
        </div>
      ),
  },
  {
    title: <b>Hạn sử dụng theo thời gian</b>,
    dataIndex: 'expiryTime',
    render: (text, record) =>
      record.expiry_time_from === 0 && record.expiry_time_to === 0 ? (
        ''
      ) : record.expiry_time_from === 0 ? (
        <div>Dưới {record.expiry_time_to} tháng</div>
      ) : record.expiry_time_to === 0 ? (
        <div>Không ít hơn {record.expiry_time_from} tháng</div>
      ) : (
        <div>
          {record.expiry_time_from} - {record.expiry_time_to} tháng
        </div>
      ),
  },
  {
    title: <b>Ghi chú</b>,
    dataIndex: 'description',
    key: 'description',
  },
];

class VehiclePartList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page_size: 1,
    };
  }

  render() {
    const {
      dataSource,
      meta: { page, page_size, total },
      onPageChange,
      onSetEditPart,
    } = this.props;
    return (
      <>
        <Table
          size="small"
          dataSource={dataSource}
          columns={columns(onSetEditPart)}
          rowKey="id"
          pagination={{
            // page,
            current: page,
            page_size,
            total,
            onChange: onPageChange,
            showSizeChanger: true,
            showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total}`,
          }}
          scroll={{ x: 'max-content' }}
        />
      </>
    );
  }
}

export default VehiclePartList;
