import { CheckOutlined } from '@ant-design/icons';
import { Col, Input, Modal, Row, Form, Button } from 'antd';
import React from 'react';

const FormItem = Form.Item;
const { Item } = Form;

const FormStorage = ({
  openModalForm,
  openModal,
  onSubmit,
  closeModalForm,
  dataCurStorage,
  isEdit,
  dataStorage,
}) => {
  const onFinish = (value) => {
    onSubmit(value);
  };

  return (
    <Modal
      title="Sửa kho"
      visible={openModal}
      onOK={openModalForm}
      onCancel={closeModalForm}
      footer={<></>}
      destroyOnClose>
      <Form
        onFinish={onFinish}
        layout="vertical"
        initialValues={{
          code: dataCurStorage?.code ? dataCurStorage?.code : '',
          name: dataCurStorage?.name ? dataCurStorage?.name : '',
        }}>
        <Row gutter={10}>
          <Col span={24}>
            <FormItem label="Mã kho" name="code">
              <Input placeholder="Nhập mã kho..." disabled={isEdit} />
            </FormItem>
            <FormItem
              label="Tên kho"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Nhập tên kho!',
                },
              ]}>
              <Input placeholder="Nhập tên kho..." />
            </FormItem>
          </Col>

          <Item style={{ float: 'right', marginTop: '10px' }}>
            <Button type="primary" htmlType="submit" icon={<CheckOutlined />} shape="round">
              Lưu
            </Button>
            &nbsp;
          </Item>
        </Row>
      </Form>
    </Modal>
  );
};

export default FormStorage;
