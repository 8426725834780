import { Input, Select } from 'antd';
import React, { Component } from 'react';

const { Search } = Input;
const { Option } = Select;

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataFilter: {
        search: '',
        type: '',
      },
    };
  }

  search = (field) => {
    const { dataFilter } = this.state;
    dataFilter.search = field;
    this.setState({ dataFilter });
    this.props.onSearch(dataFilter);
  };

  type = (field) => {
    const { dataFilter } = this.state;
    dataFilter.type = field;
    this.setState({ dataFilter });
    this.props.onSearch(dataFilter);
  };

  render() {
    const { companyCode } = this.props;
    return (
      <div>
        <Search
          onSearch={this.search}
          style={{ width: '100%', maxWidth: 400, marginRight: 15 }}
          placeholder="Tìm theo tên công ty"
          enterButton
          allowClear
        />
        {!companyCode ? (
          <Select
            style={{ width: '100%', maxWidth: 300 }}
            placeholder="Quy mô"
            onChange={this.type}
            allowClear>
            <Option value="single">Một công ty/Chi Nhánh</Option>
            <Option value="multi">Tổng công ty/Trụ sở</Option>
          </Select>
        ) : null}
      </div>
    );
  }
}

export default Filter;
