import produce from 'immer';
import moment from 'moment';

import { API_URLS } from '../config/api';
import { apiCall } from '../utils/api';
import { isCallingApi, isSuccessfulApiCall, isFailedApiCall } from './actionDedicate';
import { PREFIX, typesWithPrefix } from './config';
const _types = typesWithPrefix(PREFIX.FEATURE);
const TYPE = {
  INVALIDATE_FEATURES: _types('INVALIDATE_FEATURES'),
  GET_FEATURES: _types('GET_FEATURES'),
  GET_ALL_FEATURES: _types('GET_ALL_FEATURES'),
  UPDATE_FEATURE: _types('UPDATE_FEATURE'),
  DELETE_FEATURE: _types('DELETE_FEATURE'),
  GET_FEATURE_BY_ID: _types('GET_FEATURE_BY_ID'),
  GET_FEATURES_CHANGE_PAGE: _types('GET_FEATURES_CHANGE_PAGE'),
};
export const actions = {
  //get list feature
  getFeaturesIfNeed: (filterOption) => (dispatch, getState) => {
    const state = getState();
    const isFetching = state.featureReducer.isFetching;
    const didInvalidate = state.featureReducer.didInvalidate;
    const query = state.featureReducer.query;
    const isQueryChanged = query.search !== filterOption.search;
    if ((!isFetching && didInvalidate) || (!isFetching && isQueryChanged)) {
      dispatch(actions.getFeatures(filterOption));
    }
  },

  getFeatures: (query) => async (dispatch) => {
    const params = {
      page: query.page,
      page_size: query.page_size,
      search: query.search,
    };
    const api = API_URLS.FEATURE.getFeatures(params);
    dispatch(actions.gettingFeatures());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(
        actions.getFeaturesSuccess({
          items: response.data.data,
          meta: {
            page: response.data.page,
            page_size: response.data.page_size,
            total: response.data.total,
          },
          query: {
            search: params.search,
          },
        }),
      );
    } else {
      dispatch(actions.getFeaturesFailure(params));
    }
  },
  gettingFeatures: () => ({
    type: TYPE.GET_FEATURES,
    meta: { prefix: [PREFIX.FEATURE, PREFIX.API_CALLING] },
  }),

  getFeaturesSuccess: (payload) => ({
    type: TYPE.GET_FEATURES,
    payload,
    meta: {
      prefix: [PREFIX.FEATURE, PREFIX.API_CALLED_SUCCESS],
    },
  }),

  getFeaturesFailure: (query) => ({
    type: TYPE.GET_FEATURES,
    query,
    meta: {
      prefix: [PREFIX.FEATURE, PREFIX.API_CALLED_FAILURE],
    },
  }),

  invalidateFeatures: () => ({
    type: TYPE.INVALIDATE_FEATURES,
    meta: {
      prefix: [PREFIX.FEATURE, PREFIX.API_CALLED_SUCCESS],
    },
  }),

  // get feature by id
  getFeatureById: (featureId, meta) => async (dispatch) => {
    dispatch(actions.gettingFeatureDetail());
    const api = API_URLS.FEATURE.getFeatureById(featureId);
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      // const data = response.data.data;
      dispatch(actions.getFeatureDetailSuccess(response.data.data));
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
    } else {
      dispatch(actions.getFeatureDetailFailure());
      if (meta && meta.onError) {
        meta.onError(error);
      }
    }
  },
  gettingFeatureDetail: () => ({
    type: TYPE.GET_FEATURE_BY_ID,
    meta: { prefix: [PREFIX.FEATURE, PREFIX.API_CALLING] },
  }),
  getFeatureDetailSuccess: (payload) => ({
    type: TYPE.GET_FEATURE_BY_ID,
    meta: { prefix: [PREFIX.FEATURE, PREFIX.API_CALLED_SUCCESS] },
    payload,
  }),
  getFeatureDetailFailure: () => ({
    type: TYPE.GET_FEATURE_BY_ID,
    meta: { prefix: [PREFIX.FEATURE, PREFIX.API_CALLED_FAILURE] },
  }),

  //GET ALL FEATURES
  getAllFeatures: () => async (dispatch) => {
    const params = {
      page: -1,
      page_size: -1,
    };
    const api = API_URLS.FEATURE.getFeatures(params);
    dispatch(actions.gettingAllFeatures());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(
        actions.getAllFeaturesSuccess({
          items: response.data.data,
        }),
      );
    } else {
      dispatch(actions.getAllFeaturesFailure(params));
    }
  },
  gettingAllFeatures: () => ({
    type: TYPE.GET_ALL_FEATURES,
    meta: { prefix: [PREFIX.FEATURE, PREFIX.API_CALLING] },
  }),

  getAllFeaturesSuccess: (payload) => ({
    type: TYPE.GET_ALL_FEATURES,
    payload,
    meta: {
      prefix: [PREFIX.FEATURE, PREFIX.API_CALLED_SUCCESS],
    },
  }),

  getAllFeaturesFailure: (query) => ({
    type: TYPE.GET_ALL_FEATURES,
    query,
    meta: {
      prefix: [PREFIX.FEATURE, PREFIX.API_CALLED_FAILURE],
    },
  }),

  // update feature
  updatingFeature: () => ({
    type: TYPE.UPDATE_FEATURE,
    meta: { prefix: [PREFIX.FEATURE, PREFIX.API_CALLING] },
  }),
  updateFeatureSuccess: () => ({
    type: TYPE.UPDATE_FEATURE,
    meta: {
      prefix: [PREFIX.FEATURE, PREFIX.API_CALLED_SUCCESS],
    },
  }),
  updateFeatureFailure: () => ({
    type: TYPE.UPDATE_FEATURE,
    meta: {
      prefix: [PREFIX.FEATURE, PREFIX.API_CALLED_FAILURE],
    },
  }),
  updateFeature: (featureId, payload, meta) => async (dispatch) => {
    const api = API_URLS.FEATURE.updateFeature(featureId, payload);
    dispatch(actions.updatingFeature());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(actions.updateFeatureSuccess(response.data));
      dispatch(actions.getFeatures({ page: 1, page_size: 10 }));
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
    } else {
      dispatch(actions.updateFeatureFailure(error));
      if (meta && meta.onError) {
        meta.onError(error);
      }
    }
  },

  // Delete feature
  deletingFeature: () => ({
    type: TYPE.DELETE_FEATURE,
    meta: { prefix: [PREFIX.FEATURE, PREFIX.API_CALLING] },
  }),

  deleteFeatureSuccess: () => ({
    type: TYPE.DELETE_FEATURE,
    meta: {
      prefix: [PREFIX.FEATURE, PREFIX.API_CALLED_SUCCESS],
    },
  }),

  deleteFeatureFailure: () => ({
    type: TYPE.DELETE_FEATURE,
    meta: {
      prefix: [PREFIX.FEATURE, PREFIX.API_CALLED_FAILURE],
    },
  }),

  deleteFeature: (featureId, meta) => async (dispatch) => {
    const api = API_URLS.FEATURE.deleteFeature(featureId);
    dispatch(actions.deletingFeature());
    const { response, error } = await apiCall({ ...api });
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(actions.deleteFeatureSuccess(response.data));
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
    } else {
      dispatch(actions.deleteFeatureFailure(error));
      if (meta && meta.onError) {
        meta.onError(error);
      }
    }
  },

  // insert feature
  insertFeature: (payload, meta) => async (dispatch) => {
    const api = API_URLS.FEATURE.insertFeature(payload);
    const params = {
      page: 1,
      page_size: 10,
      search: '',
    };
    const { response, error } = await apiCall(api);
    if (!error && (response.status === 200 || response.status === 201)) {
      if (meta && meta.onSuccess) {
        meta.onSuccess();
        dispatch(actions.getFeatures(params));
      }
    } else if (meta && meta.onError) {
      meta.onError(error);
    }
    return { response, error };
  },

  changePage: (page, page_size, total) => ({
    type: TYPE.GET_FEATURES_CHANGE_PAGE,
    payload: {
      page,
      page_size,
      total,
    },
    meta: {
      prefix: [PREFIX.FEATURE],
    },
  }),
};

const initialState = {
  isFetching: false,
  didInvalidate: true,
  lastUpdated: moment().unix(),
  currentFeature: {},
  allItems: [],
  items: [],
  query: {},
  meta: {
    total: 0,
    page: 1,
    page_size: 10,
  },
};

export const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case TYPE.GET_FEATURES:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          const { items, meta, query } = action.payload;
          draft.isFetching = false;
          draft.didInvalidate = false;
          draft.lastUpdated = moment.unix();
          draft.items = items;
          draft.meta = meta;
          draft.query = query;
        } else if (isFailedApiCall(action)) {
          draft.isFetching = false;
          draft.didInvalidate = false;
        }
        break;
      case TYPE.GET_FEATURE_BY_ID:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          draft.currentFeature = action.payload;
          draft.isFetching = false;
        } else if (isFailedApiCall(action)) {
          draft.isFetching = false;
          draft.didInvalidate = false;
        }
        break;
      case TYPE.INVALIDATE_FEATURES:
        draft.didInvalidate = true;
        break;
      case TYPE.GET_FEATURES_CHANGE_PAGE:
        draft.meta = {
          page: action.payload.page,
          page_size: action.payload.page_size,
          total: action.payload.total,
        };
        break;
      case TYPE.GET_ALL_FEATURES:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          const { items } = action.payload;
          draft.isFetching = false;
          draft.didInvalidate = false;
          draft.allItems = items;
        } else if (isFailedApiCall(action)) {
          draft.isFetching = false;
          draft.didInvalidate = false;
        }
        break;
      case TYPE.UPDATE_FEATURE:
      case TYPE.DELETE_FEATURE:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        }
        if (isSuccessfulApiCall(action)) {
          draft.isFetching = false;
          draft.didInvalidate = true;
          draft.lastUpdated = moment.unix();
        }
        if (isFailedApiCall(action)) {
          draft.isFetching = false;
          draft.didInvalidate = false;
        }
        break;
      default:
        return draft;
    }
  });
