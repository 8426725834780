import lodash from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import SelectSearch from './Select';
import { API_URLS } from '../../config/api';
import { apiCall } from '../../utils/api';

class CustomerSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prevSelectedValue: [],
      defaultValue: [],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.allCustomers && nextProps.allCustomers.length) {
      if (!lodash.isEqual(nextProps.selectedValue, prevState.prevSelectedValue)) {
        const nextState = { prevSelectedValue: nextProps.selectedValue };
        if (nextProps.selectedValue && nextProps.selectedValue.length) {
          const { selectedValue } = nextProps;
          if (typeof selectedValue === 'string')
            nextState.defaultValue = CustomerSearch.getCustomerByCode([selectedValue], nextProps);
          else if (typeof selectedValue === 'object') {
            if (Array.isArray(selectedValue)) {
              if (typeof selectedValue[0] === 'string')
                nextState.defaultValue = CustomerSearch.getCustomerByCode(selectedValue, nextProps);
              else if (typeof selectedValue[0] === 'object') nextState.defaultValue = selectedValue;
            } else nextState.defaultValue = selectedValue;
          }
        } else nextState.defaultValue = [];
        return nextState;
      }
    }

    return null;
  }

  searchCustomerByName = async (name) => {
    const params = {
      search: name,
      page: 1,
      page_size: 20,
    };
    const api = API_URLS.CUSTOMER.getCustomers(params);
    const { response, error } = await apiCall(api);
    const results = [];
    if (!error && response.status === 200) {
      if (response.data.data && response.data.data.length) {
        response.data.data.forEach((customer) => {
          results.push({
            key: customer.code,
            label: `${customer.code} - ${customer.name}`,
          });
        });
      }
    }

    return results;
  };

  static getCustomerByCode = (codes, props) => {
    if (codes && codes.length) {
      const { allCustomers } = props;

      if (!allCustomers.length) return [];

      const defaultValue = [];
      codes.forEach((code) => {
        const customerCode = code.trim();
        if (!customerCode) return;
        const foundCustomer = allCustomers.find((p) => p.code === customerCode);
        const customer = foundCustomer
          ? {
              key: customerCode,
              label: `${foundCustomer.name}`,
            }
          : {
              key: customerCode,
              label: <span style={{ color: 'red' }}>[Not found] {customerCode}</span>,
            };
        defaultValue.push(customer);
      });
      return defaultValue;
    }
    return [];
  };

  render() {
    const { allCustomers, onChange } = this.props;

    return (
      <SelectSearch
        {...this.props}
        onChange={onChange}
        // onSearch={this.searchCustomerByName}
        selectedValue={this.state.defaultValue}
        dataSource={allCustomers}
        placeholder={this.props.placeholder || 'Chọn khách hàng'}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  allCustomers: state.customerReducer.allItems,
});

// const mapDispatchToProps = (dispatch) => ({
// });

export default connect(mapStateToProps)(CustomerSearch);
