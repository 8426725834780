import { notification } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { actions as apiActions } from '../../../../redux/ApiRedux';
import ApiForm from './Form';
import List from './List';

class ApiTab extends Component {
  state = {
    visibleApiForm: false,
    dataFilter: {
      search: '',
    },
  };

  static getDerivedStateFromProps(props, state) {
    const page = props.meta.page;
    const page_size = props.meta.page_size;
    const { search } = state.dataFilter;
    props.getApisIfNeed({
      page,
      page_size,
      search,
    });
    return null;
  }

  onPageChange = (page, page_size) => {
    const { changePage, invalidateApis, meta } = this.props;
    changePage(page, page_size, meta.total);
    invalidateApis();
  };

  onChangeFilter = (data) => {
    this.setState({
      dataFilter: data,
    });
  };

  deleteApi = (code) => {
    this.props.deleteApi(code, {
      onSuccess: () => {
        notification.success({ message: 'Đã xóa API' });
        this.props.invalidateApis();
      },
      onError: (error) => notification.error({ message: `${error} - Xóa thất bại` }),
    });
  };

  toggleModalApi = () => {
    this.setState((prevState) => ({ visibleApiForm: !prevState.visibleApiForm }));
  };

  setAddApi = () => {
    this.setState({ editApi: {} }, () => this.toggleModalApi());
  };

  onCreateApi = (values) => {
    this.props.createApi(values, {
      onSuccess: () => {
        notification.success({ message: 'Tạo API mới thành công' });
        this.toggleModalApi();
        this.props.invalidateApis();
      },
      onError: (mes) => {
        notification.error({ message: `Tạo API thất bại. ${mes}` });
      },
    });
  };

  setEditApi = (values) => {
    this.setState({ editApi: { ...values } }, () => this.toggleModalApi());
  };

  editApi = (id, payload) => {
    this.props.editApi(
      id,
      payload,
      {
        onSuccess: () => {
          notification.success({ message: 'Đã cập nhật API' });
          this.toggleModalApi();
          this.props.invalidateApis();
        },
        onError: (error) => notification.error({ message: `${error} - Cập nhật thất bại` }),
      },
      this.state.page_sizeAPI,
    );
  };

  render() {
    const { apis, isFetching, meta } = this.props;
    const { visibleApiForm, editApi } = this.state;
    return (
      <>
        <List
          dataSource={apis}
          meta={meta}
          isFetching={isFetching}
          onChange={this.onPageChange}
          onShowSizeChange={this.onPageChange}
          onChangeFilter={this.onChangeFilter}
          onDeleteApi={this.deleteApi}
          onSetAddApi={this.setAddApi}
          onSetEditApi={this.setEditApi}
        />
        <ApiForm
          visibleApiForm={visibleApiForm}
          editApi={editApi}
          onToggleModalAPI={this.toggleModalApi}
          onCreateApi={this.onCreateApi}
          onEditApi={this.editApi}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  meta: state.apiReducer.meta,
  apis: state.apiReducer.items,
  isFetching: state.apiReducer.isFetching,
  didInvalidate: state.apiReducer.didInvalidate,
});

const mapDispatchToProps = (dispatch) => ({
  getApisIfNeed: (params) => dispatch(apiActions.getApisIfNeed(params)),
  changePage: (page, page_size, total) => dispatch(apiActions.changePage(page, page_size, total)),
  invalidateApis: () => dispatch(apiActions.invalidateApis()),
  createApi: (payload, meta) => dispatch(apiActions.insertApi(payload, meta)),
  editApi: (code, payload, meta) => dispatch(apiActions.editApi(code, payload, meta)),
  deleteApi: (code, meta) => dispatch(apiActions.deleteApi(code, meta)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApiTab);
