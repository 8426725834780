import { UploadOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Select } from 'antd';
import React, { Component } from 'react';

const { Search } = Input;
const { Option } = Select;

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataFilter: {
        search: '',
        gender: '',
        status: '',
        page: 1,
        // page_size: 10,
      },
    };
  }

  onSearch = (field) => {
    const { dataFilter } = this.state;
    dataFilter.search = field;
    this.setState({ dataFilter });
    this.props.onSearch(dataFilter);
  };

  onChangeGender = (field) => {
    const { dataFilter } = this.state;
    dataFilter.gender = field;
    this.setState({ dataFilter });
    this.props.onSearch(dataFilter);
  };

  onChangeStatus = (field) => {
    const { dataFilter } = this.state;
    dataFilter.status = field;
    this.setState({ dataFilter });
    this.props.onSearch(dataFilter);
  };

  render() {
    return (
      <Row gutter={15}>
        <Col span={6}>
          <div style={{ marginBottom: '10px' }}>Tìm kiếm</div>
          <Search
            onSearch={this.onSearch}
            style={{ width: '100%' }}
            placeholder="Tên lái xe"
            enterButton
            allowClear
          />
        </Col>
        <Col span={6}>
          <div style={{ marginBottom: '10px' }}>Trạng thái</div>
          <Select
            style={{ width: '100%' }}
            placeholder="Trạng thái"
            onChange={this.onChangeStatus}
            allowClear>
            <Option value="Ready">Sẵn sàng</Option>
            <Option value="Driving">Đang trên đường</Option>
            <Option value="Off">Nghỉ</Option>
          </Select>
        </Col>
        <Col span={6}>
          <div style={{ marginBottom: '10px' }}>Giới tính</div>
          <Select
            style={{ width: '100%' }}
            placeholder="Giới tính"
            onChange={this.onChangeGender}
            allowClear>
            <Option value="Male">Nam</Option>
            <Option value="Female">Nữ</Option>
          </Select>
        </Col>
        <Col>
          <Button
            type="primary"
            style={{ alignSelf: 'end', margin: '30px 0 0 12px' }}
            shape="round">
            Chuyển tài xế
          </Button>
        </Col>
        <Col>
          <Button
            style={{ alignSelf: 'end', margin: '30px 0 0 0px' }}
            type="primary"
            shape="round"
            icon={<UploadOutlined />}
            size="medium"
            onClick={this.props.onShowModal}>
            Upload File
          </Button>
        </Col>
      </Row>
    );
  }
}

export default Filter;
