/* eslint-disable react/no-unused-state */
/* eslint-disable prefer-destructuring */
// import { Input } from 'antd';
// import { Button } from 'antd';
import { Popover, TimePicker } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import Icon from 'react-fa';

import DriverSearch from '../../../../../components/FormItems/DriverSearch';
import common from '../../../../../config/common';
import { formatDuration } from '../../../../../utils/vrp';

// const TextArea = Input.TextArea;

class DriverItem extends Component {
  constructor() {
    super();
    this.state = {
      currentDriverCode: '',
      isChangedDriverCode: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { driver } = props;
    const { currentDriverCode, isChangedDriverCode } = state;

    if (driver?.code && driver.code !== currentDriverCode && !isChangedDriverCode)
      return { currentDriverCode: driver.code };

    return null;
  }

  handleChangeDriverCode = (driverCode) => {
    this.props.onChangeDriver(this.props.index, this.props.driver?.code, driverCode);
    this.setState({ currentDriverCode: driverCode, isChangedDriverCode: true });
  };

  render() {
    const { driver, route, index, vehicle_capacity } = this.props;

    return (
      <div
        style={{
          width: '100%',
          height: 70,
          backgroundColor: index % 2 === 0 ? '#f2f2f2' : null,
          padding: 5,
          fontSize: 18,
        }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* <Icon name="motorcycle" style={{ alignSelf: 'center', fontSize: 'medium' }} /> */}
          <DriverSearch
            style={{ width: '100%', flex: 1 }}
            onChange={(e) => this.handleChangeDriverCode(e)}
            selectedValue={driver?.code}
            allowClear
          />
        </div>
        {/* <Button
          onClick={() =>
            this.props.onChangeDriver(index, driver?.code, this.state.currentDriverCode)
          }
          disabled={this.state.currentDriverCode === driver?.code}
          style={{ marginLeft: 10 }}
          shape="round"
          type="primary">
          Đổi
        </Button> */}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* <div>
            <Icon name="phone" style={{ fontSize: 'small' }} />
            &nbsp;
            <small>{driver.phone_number}</small>
          </div> */}
          {/* <div>
            <Icon name="qrcode" />
            <small>
              &nbsp;
              {driver.code}
            </small>
          </div> */}
          <Popover
            content={
              <div style={{ width: '300px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <b>Số điện thoại:</b>
                  <p style={{ alignSelf: 'center' }}>{driver?.phone_number || '-'}</p>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <b>Thời gian bắt đầu:</b>
                  {route?.estimate_start_time || route?.start_time ? (
                    <p style={{ alignSelf: 'center' }}>
                      {moment(route?.estimate_start_time || route?.start_time).format('HH:mm')}
                    </p>
                  ) : this.props.onSelectStartTime ? (
                    <TimePicker
                      format="HH:mm"
                      size="small"
                      placeholder="Xuất phát"
                      onChange={this.props.onSelectStartTime}
                      allowClear={false}
                      style={{ alignSelf: 'flex-start' }}
                    />
                  ) : (
                    <p>-</p>
                  )}
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <b>Quãng đường:</b>
                  <p>
                    {`${
                      route?.total_distance > 0
                        ? Math.round((route?.total_distance / 1000) * 10) / 10
                        : 0
                    } km`}
                  </p>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <b>Thời gian di chuyển:</b>
                  <p>{formatDuration(route?.total_time)}</p>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <b>Khối lượng:</b>
                  <p
                    style={
                      Math.round(route?.weight_used * 10) / 10 > vehicle_capacity
                        ? { fontSize: '16px', fontWeight: 'bold', color: '#f00' }
                        : {}
                    }>
                    {route?.weight_used ? Math.round(route?.weight_used * 10) / 10 : 0} kg
                  </p>
                </div>
              </div>
            }
            title="Chi tiết tuyến đường"
            trigger="hover">
            <span>
              <Icon name="tag" style={{ fontSize: 'small' }} />
              &nbsp;
              <small style={{ alignSelf: 'center' }}>{common.routeStatus[route?.status]}</small>
            </span>
          </Popover>
        </div>
        {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Icon name="phone" />
            <small>
              &nbsp;
              {driver.phone_number}
            </small>
          </div>
          {route?.estimate_start_time ? (
            <small style={{ alignSelf: 'center' }}>
              {route?.estimate_start_time
                ? moment(route?.estimate_start_time).format('HH:mm')
                : null}
            </small>
          ) : (
            <TimePicker
              format="HH:mm"
              size="small"
              placeholder="Xuất phát"
              onChange={this.props.onSelectStartTime}
              allowClear={false}
              style={{ alignSelf: 'flex-start' }}
            />
          )}
        </div> */}
      </div>
    );
  }
}

export default DriverItem;
