/* eslint-disable react/no-array-index-key */
import update from 'immutability-helper';
import lodash from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import DragableItem from './dragableItem';
import { actions as driverActions } from '../../../../../redux/DriverRedux';
class DriverList extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.moveItem = lodash.debounce(this.moveItem, 10);
  }

  componentDidMount() {
    // use on DriverSearch
    this.props.getAllDrivers();
  }

  moveItem = (dragIndex, hoverIndex) => {
    const { drivers } = this.props;
    const dragDriver = drivers[dragIndex];
    const updatedDrivers = update(drivers, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragDriver],
      ],
    });
    this.props.onSortDrivers(updatedDrivers);
  };

  render() {
    const { allDrivers, routes, vehicle_capacity } = this.props;

    return (
      <div>
        {routes?.map((route, index) => {
          const driver = allDrivers.find((item) => route.driver_code === item.code);
          return (
            <div
              key={`${driver?.code}-${index}`}
              ref={(node) => {
                this.node = node;
              }}>
              <DragableItem
                vehicle_capacity={vehicle_capacity}
                onChangeDriver={this.props.onChangeDriver}
                onSelectStartTime={this.props.onSelectStartTime}
                driver={driver}
                route={route}
                node={this.node}
                index={index}
                moveItem={this.moveItem}
              />
            </div>
          );
        })}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAllDrivers: () => dispatch(driverActions.getAllDrivers()),
});

export default connect(null, mapDispatchToProps)(DriverList);
