import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ROUTER from '../../../config/router';
import ApiPage from '../../Api/index';
const Api = () => (
  <Switch>
    <Route exact path={ROUTER.MANAGER.INDEX} component={ApiPage} />
  </Switch>
);
export default Api;
