import produce from 'immer';
import moment from 'moment';

import { isCallingApi, isSuccessfulApiCall, isFailedApiCall } from './actionDedicate';
import { PREFIX, typesWithPrefix } from './config';
import { API_URLS } from '../config/api';
import { apiCall } from '../utils/api';

const _types = typesWithPrefix(PREFIX.ROUTING);
const types = {
  GET_CVRP_SOLUTION: _types('GET_CVRP_SOLUTION'),
  GET_ROUTES: _types('GET_ROUTES'),
  UPDATE_ROUTES: _types('UPDATE_ROUTES'),
  UPDATE_ROUTE_BY_CODE: _types('UPDATE_ROUTE_BY_CODE'),
  UPDATE_DRIVER_ROUTE: _types('UPDATE_DRIVER_ROUTE'),
  UPDATE_DRIVER_TEMP_ROUTE: _types('UPDATE_DRIVER_TEMP_ROUTE'),
  UPDATE_START_TIME_TEMP_ROUTE: _types('UPDATE_START_TIME_TEMP_ROUTE'),
  INSERT_ROUTE: _types('INSERT_ROUTE'),
  INSERT_ROUTES: _types('INSERT_ROUTES'),
  INSERT_ROUTE_FROM_SAMPLE_ROUTE: _types('INSERT_ROUTE_FROM_SAMPLE_ROUTE'),
  ADD_TEMP_ROUTE: _types('ADD_TEMP_ROUTE'),
  SEND_ROUTE_TO_MOBILE_APP: _types('SEND_ROUTE_TO_MOBILE_APP'),
  SORT_DRIVERS: _types('SORT_DRIVERS'),
  RECALC_SOLUTION_CVRP: _types('RECALC_SOLUTION_CVRP'),
  UPDATE_CVRP_SOLUTION: _types('UPDATE_CVRP_SOLUTION'),
  INVALIDATE_SOLUTION: _types('INVALIDATE_SOLUTION'),
  VALIDATE_SOLUTION: _types('VALIDATE_SOLUTION'),
  HIDE_ROUTE_FROM_MAP: _types('HIDE_ROUTE_FROM_MAP'),
  CLEAR_HIDDEN_ROUTES: _types('CLEAR_HIDDEN_ROUTES'),
  DELETE_ROUTE: _types('DELETE_ROUTE'),
};

export const actions = {
  // GET CVRP SOLUTION
  getCVRPSolution: (payload, meta) => async (dispatch) => {
    const api = API_URLS.ROUTING.getCVRPSolution(payload);
    dispatch(actions.gettingCVRPSolution());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      if (meta && meta.onSuccess) {
        dispatch(actions.getCVRPSolutionSuccess(response.data));
        meta.onSuccess();
      }
    } else if (meta && meta.onError) {
      meta.onError(error);
      dispatch(actions.getCVRPSolutionFailure());
    }
    return { response, error };
  },
  gettingCVRPSolution: () => ({
    type: types.GET_CVRP_SOLUTION,
    meta: { prefix: [PREFIX.ROUTING, PREFIX.API_CALLING] },
  }),

  getCVRPSolutionSuccess: (payload) => ({
    type: types.GET_CVRP_SOLUTION,
    payload,
    meta: { prefix: [PREFIX.ROUTING, PREFIX.API_CALLED_SUCCESS] },
  }),

  getCVRPSolutionFailure: () => ({
    type: types.GET_CVRP_SOLUTION,
    meta: { prefix: [PREFIX.ROUTING, PREFIX.API_CALLED_FAILURE] },
  }),

  // CREATE NEW ROUTE
  insertRoute: (payload, meta) => async (dispatch) => {
    const api = API_URLS.ROUTING.insertRoute(payload);
    dispatch(actions.insertingRoute());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      if (meta && meta.onSuccess) {
        dispatch(actions.insertRouteSuccess(response.data));
        meta.onSuccess();
      }
    } else if (meta && meta.onError) {
      meta.onError(error);
      dispatch(actions.insertRouteFailure());
    }
    return { response, error };
  },
  insertingRoute: () => ({
    type: types.INSERT_ROUTE,
    meta: { prefix: [PREFIX.ROUTING, PREFIX.API_CALLING] },
  }),
  insertRouteSuccess: (payload) => ({
    type: types.INSERT_ROUTE,
    payload,
    meta: { prefix: [PREFIX.ROUTING, PREFIX.API_CALLED_SUCCESS] },
  }),
  insertRouteFailure: () => ({
    type: types.INSERT_ROUTE,
    meta: { prefix: [PREFIX.ROUTING, PREFIX.API_CALLED_FAILURE] },
  }),

  // CREATE SOME ROUTES
  insertRoutes: (payload, meta) => async (dispatch) => {
    const api = API_URLS.ROUTING.insertRoutes(payload);
    dispatch(actions.insertingRoutes());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      if (meta && meta.onSuccess) {
        dispatch(actions.insertRoutesSuccess(response.data));
        meta.onSuccess();
      }
    } else if (meta && meta.onError) {
      meta.onError(error);
      dispatch(actions.insertRoutesFailure());
    }
    return { response, error };
  },
  insertingRoutes: () => ({
    type: types.INSERT_ROUTES,
    meta: { prefix: [PREFIX.ROUTING, PREFIX.API_CALLING] },
  }),
  insertRoutesSuccess: (payload) => ({
    type: types.INSERT_ROUTES,
    payload,
    meta: { prefix: [PREFIX.ROUTING, PREFIX.API_CALLED_SUCCESS] },
  }),
  insertRoutesFailure: () => ({
    type: types.INSERT_ROUTES,
    meta: { prefix: [PREFIX.ROUTING, PREFIX.API_CALLED_FAILURE] },
  }),

  // INSERT ROUTE FROM SAMPLE ROUTE
  insertRouteFromSampleRoute: (payload, meta) => async (dispatch) => {
    const api = API_URLS.ROUTING.insertRouteFromSampleRoute(payload);
    dispatch(actions.insertingRouteFromSampleRoute());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      if (meta && meta.onSuccess) {
        dispatch(actions.insertRouteFromSampleRouteSuccess(response.data));
        meta.onSuccess(response.data?.data);
      }
    } else if (meta && meta.onError) {
      meta.onError(error);
      dispatch(actions.insertRouteFromSampleRouteFailure());
    }
    return { response, error };
  },
  insertingRouteFromSampleRoute: () => ({
    type: types.INSERT_ROUTE_FROM_SAMPLE_ROUTE,
    meta: { prefix: [PREFIX.ROUTING, PREFIX.API_CALLING] },
  }),
  insertRouteFromSampleRouteSuccess: (payload) => ({
    type: types.INSERT_ROUTE_FROM_SAMPLE_ROUTE,
    payload,
    meta: { prefix: [PREFIX.ROUTING, PREFIX.API_CALLED_SUCCESS] },
  }),
  insertRouteFromSampleRouteFailure: () => ({
    type: types.INSERT_ROUTE_FROM_SAMPLE_ROUTE,
    meta: { prefix: [PREFIX.ROUTING, PREFIX.API_CALLED_FAILURE] },
  }),

  // Add temporary route on schedule page
  addTemporaryRoute: (index, deliveryDate) => (dispatch, getState) => {
    const { configurationReducer } = getState();
    const configValues = configurationReducer.item;
    const depot = {
      address: configValues.depot?.address || '',
      latitude: configValues.depot?.latitude || 0,
      longitude: configValues.depot?.longitude || 0,
    };
    const tempRoute = {
      code: `temp_route_${index}`,
      driver_code: '',
      total_distance: 0,
      total_time: 0,
      weight_used: 0,
      nodes: [],
      depot,
    };
    const tempRouteData = {
      code: `temp_route_${index}`,
      delivery_date: deliveryDate,
      driver_code: '',
      nodes: [],
      estimate_start_time: configValues.time_window?.from_time
        ? moment(configValues.time_window?.from_time, 'HH:mm').add(1, 'days').toISOString()
        : '',
      start_location: depot,
      finish_location: depot,
      total_distance: 0,
      total_time: 0,
      weight_used: 0,
      status: '',
      status_updated: { updated_at: '', updated_by: { user_code: '', username: '' } },
      show_on_driver_app: true,
      volume_used: 0,
      real_start_time: null,
      completed_time: null,
      created_at: '',
      updated_at: '',
      company_code: '',
    };
    const tempDriver = {
      code: '',
      id: '',
      name: '',
      gender: '',
      date_of_birth: null,
      identity_card: '',
      phone_number: '',
      email: '',
      note: '',
      images: null,
      default_vehicle_code: '',
      status: '',
      work_time_from: '',
      work_time_to: '',
      fixed_cost: 0,
      cost_per_km: 0,
      cost_per_hour: 0,
      cost_per_hour_overtime: 0,
      speed: 0,
      start_location: depot,
      finish_location: depot,
      active: true,
      company_code: '',
    };

    dispatch({
      type: types.ADD_TEMP_ROUTE,
      payload: { tempRoute, tempRouteData, tempDriver },
      meta: { prefix: [PREFIX.ROUTING, PREFIX.API_CALLED_SUCCESS] },
    });
  },

  // Send routes to driver's mobile app
  sendRouteToMobileApp: (payload, meta) => async (dispatch) => {
    const api = API_URLS.ROUTING.sendRouteToMobileApp(payload);
    dispatch(actions.sendingRouteToMobileApp());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      if (meta && meta.onSuccess) {
        dispatch(actions.sendRouteToMobileAppSuccess(response.data));
        meta.onSuccess(response.data?.data);
      }
    } else if (meta && meta.onError) {
      meta.onError(error);
      dispatch(actions.sendRouteToMobileAppFailure());
    }
    return { response, error };
  },
  sendingRouteToMobileApp: () => ({
    type: types.SEND_ROUTE_TO_MOBILE_APP,
    meta: { prefix: [PREFIX.ROUTING, PREFIX.API_CALLING] },
  }),
  sendRouteToMobileAppSuccess: (payload) => ({
    type: types.SEND_ROUTE_TO_MOBILE_APP,
    payload,
    meta: { prefix: [PREFIX.ROUTING, PREFIX.API_CALLED_SUCCESS] },
  }),
  sendRouteToMobileAppFailure: () => ({
    type: types.SEND_ROUTE_TO_MOBILE_APP,
    meta: { prefix: [PREFIX.ROUTING, PREFIX.API_CALLED_FAILURE] },
  }),

  // RECALCULATE SOLUTION
  calcSolution: (payload, meta) => async (dispatch) => {
    const api = API_URLS.ROUTING.calc();
    dispatch(actions.calculatingSolution());
    const { response, error } = await apiCall({
      ...api,
      payload,
    });
    if (!error && response.status === 200) {
      meta.onSuccess(response.data?.routes);
      // dispatch(actions.calcSolutionSuccess(response.data));
    } else {
      meta.onError();
      // dispatch(actions.calcSolutionFailure());
    }
  },
  calculatingSolution: () => ({
    type: types.RECALC_SOLUTION_CVRP,
    meta: { prefix: [PREFIX.ROUTING, PREFIX.API_CALLING] },
  }),
  calcSolutionSuccess: (payload) => ({
    type: types.RECALC_SOLUTION_CVRP,
    payload,
    meta: { prefix: [PREFIX.ROUTING, PREFIX.API_CALLED_SUCCESS] },
  }),
  calcSolutionFailure: () => ({
    type: types.RECALC_SOLUTION_CVRP,
    meta: { prefix: [PREFIX.ROUTING, PREFIX.API_CALLED_FAILURE] },
  }),

  // UPDATE SOLUTION
  updateSolution: (payload) => ({
    type: types.UPDATE_CVRP_SOLUTION,
    payload,
    meta: { prefix: [PREFIX.ROUTING] },
  }),

  sortDrivers: (drivers) => ({
    type: types.SORT_DRIVERS,
    payload: drivers,
    meta: { prefix: [PREFIX.ROUTING] },
  }),
  // DELETE ROUTE
  deleteRoute: (body, meta) => async (dispatch, getState) => {
    const api = API_URLS.ROUTING.deleteRoute(body);

    dispatch(actions.deletingRoute());
    const { response, error } = await apiCall(api);

    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(actions.deleteRouteSuccess());
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
    } else {
      dispatch(actions.deleteRouteFailure());
    }
  },
  deletingRoute: () => ({
    type: types.DELETE_ROUTE,
    meta: { prefix: [PREFIX.API, PREFIX.API_CALLING] },
  }),

  deleteRouteSuccess: () => ({
    type: types.DELETE_ROUTE,
    meta: { prefix: [PREFIX.API, PREFIX.API_CALLED_SUCCESS] },
  }),

  deleteRouteFailure: () => ({
    type: types.DELETE_ROUTE,
    meta: { prefix: [PREFIX.API, PREFIX.API_CALLED_FAILURE] },
  }),

  // INVALIDATE SOLUTION
  invalidateSolution: () => ({
    type: types.INVALIDATE_SOLUTION,
    meta: { prefix: [PREFIX.ROUTING] },
  }),
  validateSolution: () => ({
    type: types.VALIDATE_SOLUTION,
    meta: { prefix: [PREFIX.ROUTING] },
  }),

  // HIDE ROUTE FROM MAP
  toggleRoute: (payload) => ({
    type: types.HIDE_ROUTE_FROM_MAP,
    payload,
    meta: { prefix: [PREFIX.ROUTING] },
  }),

  // CLEAR HIDDEN ROUTES
  clearHiddenRoutes: (payload) => ({
    type: types.CLEAR_HIDDEN_ROUTES,
    payload,
    meta: { prefix: [PREFIX.ROUTING] },
  }),

  // GET ROUTES
  getRoutes: (allDrivers, params) => async (dispatch, getState) => {
    const { routingReducer } = getState();
    if (routingReducer.isFetching) {
      return;
    }

    const api = API_URLS.ROUTING.getRoutes(params);
    dispatch(actions.gettingRoutes());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      // const list = response.data?.data?.map((item) =>
      //   item.nodes.map((node) => ({ ...node, driver: item.driver_code })),
      // );
      // const drivers = list
      //   ?.map((item) => item.map((e) => ({ code: e.driver, id: e.driver })))
      //   ?.flat();

      // driver list
      const driverList = response.data?.data?.map((route) => ({
        code: route.driver_code,
        id: route.driver_code,
        ...allDrivers.find((driver) => route?.driver_code === driver.code),
      }));

      // route list
      const routes = response?.data?.data?.map((route, index) => ({
        ...route,
        depot: { location: route.start_location },
        nodes: route?.nodes?.map((node, i) => ({
          ...node,
          // duration: 0,
          location: { ...node?.customer?.location },
          index: i,
        })),
      }));
      // const nodes = response?.data?.data?.map((route) => route.nodes).flat();
      // console.log('🚀 -> file: RoutingRedux.js:247 -> getRoutes: -> nodes:', nodes);
      // const nodesList = nodes?.map((node, index) => ({
      //   distance: node?.distance,
      //   duration: node?.duration,
      //   demand: node?.demand,
      //   location: { ...node?.customer?.location },
      //   index: index,
      // }));
      // const routes = nodesList?.map((node, index) => ({
      //   nodes: [node],
      //   ...depotList[index],
      // }));
      const payload = { drivers: driverList, routes, data: response?.data?.data };
      dispatch(actions.getRoutesSuccess(payload));
    } else dispatch(actions.getRoutesFailure());

    return { response, error };
  },
  gettingRoutes: () => ({
    type: types.GET_ROUTES,
    meta: { prefix: [PREFIX.ROUTING, PREFIX.API_CALLING] },
  }),
  getRoutesSuccess: (payload) => ({
    type: types.GET_ROUTES,
    payload,
    meta: { prefix: [PREFIX.ROUTING, PREFIX.API_CALLED_SUCCESS] },
  }),
  getRoutesFailure: () => ({
    type: types.GET_ROUTES,
    meta: { prefix: [PREFIX.ROUTING, PREFIX.API_CALLED_FAILURE] },
  }),

  // UPDATE ROUTES
  updateRoutes: (payload, meta) => async (dispatch) => {
    const api = API_URLS.ROUTING.updateRoutes(payload);
    dispatch(actions.updatingRoutes());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(actions.updateRoutesSuccess(payload));
      // dispatch(actions.getRoutes());
      if (meta && meta.onSuccess) meta.onSuccess(response.data?.data);
    } else {
      dispatch(actions.updateRoutesFailure());
      if (meta && meta.onError) meta.onError();
    }
  },
  updatingRoutes: () => ({
    type: types.UPDATE_ROUTES,
    meta: { prefix: [PREFIX.ROUTING, PREFIX.API_CALLING] },
  }),
  updateRoutesSuccess: (payload) => ({
    type: types.UPDATE_ROUTES,
    payload,
    meta: { prefix: [PREFIX.ROUTING, PREFIX.API_CALLED_SUCCESS] },
  }),
  updateRoutesFailure: () => ({
    type: types.UPDATE_ROUTES,
    meta: { prefix: [PREFIX.ROUTING, PREFIX.API_CALLED_FAILURE] },
  }),

  // UPDATE 1 ROUTE BY IT'S CODE
  updateRouteByCode: (index, code, payload, meta) => async (dispatch, getState) => {
    const { routingReducer, driverReducer } = getState();
    const api = API_URLS.ROUTING.updateRouteByCode(code, payload);
    dispatch(actions.updatingRouteByCode());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(
        actions.updateRouteByCodeSuccess(index, routingReducer, driverReducer, response.data.data),
      );
      if (meta && meta.onSuccess) meta.onSuccess(response.data?.data);
    } else {
      dispatch(actions.updateRouteByCodeFailure());
      if (meta && meta.onError) meta.onError();
    }
  },
  updatingRouteByCode: () => ({
    type: types.UPDATE_ROUTE_BY_CODE,
    meta: { prefix: [PREFIX.ROUTING, PREFIX.API_CALLING] },
  }),
  updateRouteByCodeSuccess: (index, routingReducer, driverReducer, dataRes) => {
    const { allItems } = driverReducer;
    const { solution, data, drivers } = routingReducer;

    const newDriver = allItems.find((driver) => driver?.code === dataRes?.driver_code);

    const payload = {
      routes: solution.routes.map((route) =>
        route?.code === dataRes?.code
          ? {
              code: dataRes?.code,
              nodes: dataRes?.nodes,
              depot: { location: newDriver?.start_location },
            }
          : route,
      ),
      data: data.map((route) => (route?.code === dataRes?.code ? dataRes : route)),
      drivers: drivers.map((driver, i) => (i === index ? newDriver : driver)),
    };
    return {
      type: types.UPDATE_ROUTE_BY_CODE,
      payload,
      meta: { prefix: [PREFIX.ROUTING, PREFIX.API_CALLED_SUCCESS] },
    };
  },
  updateRouteByCodeFailure: () => ({
    type: types.UPDATE_ROUTE_BY_CODE,
    meta: { prefix: [PREFIX.ROUTING, PREFIX.API_CALLED_FAILURE] },
  }),

  // UPDATE DRIVER OF 1 ROUTE
  updateDriverRoute: (index, code, payload, meta) => async (dispatch, getState) => {
    const { routingReducer, driverReducer } = getState();
    const api = API_URLS.ROUTING.updateDriverRoute(code, payload);
    dispatch(actions.updatingDriverRoute());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(
        actions.updateDriverRouteSuccess(index, routingReducer, driverReducer, response.data.data),
      );
      if (meta && meta.onSuccess) meta.onSuccess(response.data?.data);
    } else {
      dispatch(actions.updateDriverRouteFailure());
      if (meta && meta.onError) meta.onError();
    }
  },
  updatingDriverRoute: () => ({
    type: types.UPDATE_DRIVER_ROUTE,
    meta: { prefix: [PREFIX.ROUTING, PREFIX.API_CALLING] },
  }),
  updateDriverRouteSuccess: (index, routingReducer, driverReducer, dataRes) => {
    const { allItems } = driverReducer;
    const { solution, data, drivers } = routingReducer;

    const newDriver = allItems.find((driver) => driver?.code === dataRes?.driver_code);

    const payload = {
      routes: solution.routes,
      data: data.map((route) => (route?.code === dataRes?.code ? dataRes : route)),
      drivers: drivers.map((driver, i) => (i === index ? newDriver : driver)),
    };
    return {
      type: types.UPDATE_DRIVER_ROUTE,
      payload,
      meta: { prefix: [PREFIX.ROUTING, PREFIX.API_CALLED_SUCCESS] },
    };
  },
  updateDriverRouteFailure: () => ({
    type: types.UPDATE_DRIVER_ROUTE,
    meta: { prefix: [PREFIX.ROUTING, PREFIX.API_CALLED_FAILURE] },
  }),

  updateStartTimeOfTempRoute: (startTime) => (dispatch, getState) => {
    const {
      routingReducer: { data },
    } = getState();
    const tempRouteData = { ...data[0] };
    tempRouteData.estimate_start_time = startTime;

    dispatch({
      type: types.UPDATE_START_TIME_TEMP_ROUTE,
      payload: { tempRouteData },
      meta: { prefix: [PREFIX.ROUTING, PREFIX.API_CALLED_SUCCESS] },
    });
  },

  updateDriverOfTempRoute: (tempDriver, tempRouteCode, meta) => (dispatch, getState) => {
    const {
      routingReducer: {
        solution: { routes },
      },
    } = getState();
    const tempRouteIndex = routes.findIndex((item) => item.code === tempRouteCode);
    if (tempRouteIndex >= 0) {
      dispatch({
        type: types.UPDATE_DRIVER_TEMP_ROUTE,
        payload: { tempRouteIndex, tempDriver },
        meta: {
          prefix: [PREFIX.ROUTING, PREFIX.API_CALLED_SUCCESS],
          onSuccess: meta?.onSuccess,
          onError: meta?.onError,
        },
      });
    }
  },
};

const initialState = {
  isFetching: false,
  didInvalidate: true,
  solution: {},
  data: [],
  drivers: [],
  routes: [],
  hiddenRoutesIndex: [0, 1, 2],
};

export const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.SORT_DRIVERS:
        draft.drivers = action.payload;
        break;
      case types.GET_CVRP_SOLUTION:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          draft.isFetching = false;
          draft.didInvalidate = false;
          draft.solution = action.payload;
          draft.routes = action.payload.routes.reverse();
        } else if (isFailedApiCall(action)) {
          draft.isFetching = false;
          draft.didInvalidate = false;
        }
        break;
      case types.UPDATE_CVRP_SOLUTION:
        draft.solution = action.payload ? action.payload : draft.solution;
        break;
      case types.INVALIDATE_SOLUTION:
        draft.didInvalidate = true;
        break;
      case types.VALIDATE_SOLUTION:
        draft.didInvalidate = false;
        break;
      // case types.HIDE_ROUTE_FROM_MAP:
      //   const index = draft.hiddenRoutesIndex.indexOf(action.payload);
      //   if (index > -1) {
      //     draft.hiddenRoutesIndex.splice(index, 1);
      //   } else draft.hiddenRoutesIndex.push(action.payload);
      //   break;
      // case types.CLEAR_HIDDEN_ROUTES:
      //   draft.hiddenRoutesIndex = [];
      //   break;
      case types.HIDE_ROUTE_FROM_MAP:
        // Kiểm tra xem index của tuyến đường có trong mảng hiddenRoutesIndex hay không
        const index = draft.hiddenRoutesIndex.indexOf(action.payload);
        if (index > -1) {
          // Nếu có, xóa index đó ra khỏi mảng để hiển thị tuyến đường
          draft.hiddenRoutesIndex.splice(index, 1);
        } else {
          // Nếu không, thêm index vào mảng để ẩn tuyến đường
          draft.hiddenRoutesIndex.push(action.payload);
        }
        break;
      case types.CLEAR_HIDDEN_ROUTES:
        // Đặt mảng hiddenRoutesIndex thành trống để hiển thị tất cả các tuyến đường
        draft.hiddenRoutesIndex = [];
        break;
      case types.GET_ROUTES:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          draft.isFetching = false;
          draft.solution.routes = action.payload?.routes || [];
          draft.data = action.payload?.data || [];
          draft.drivers = action.payload?.drivers || [];
        } else if (isFailedApiCall(action)) {
          draft.isFetching = false;
        }
        break;
      case types.UPDATE_ROUTE_BY_CODE:
        draft.solution.routes = action.payload?.routes;
        draft.data = action.payload?.data;
        draft.drivers = action.payload?.drivers;
        break;
      case types.UPDATE_DRIVER_ROUTE:
        draft.solution.routes = action.payload?.routes;
        draft.data = action.payload?.data;
        draft.drivers = action.payload?.drivers;
        break;
      case types.ADD_TEMP_ROUTE:
        draft.solution.routes = [action.payload?.tempRoute, ...draft.solution.routes];
        draft.data = [action.payload?.tempRouteData, ...draft.data];
        draft.drivers = [action.payload?.tempDriver, ...draft.drivers];
        break;
      case types.UPDATE_START_TIME_TEMP_ROUTE:
        draft.data[0] = action.payload?.tempRouteData;
        break;
      case types.UPDATE_DRIVER_TEMP_ROUTE:
        const { tempRouteIndex, tempDriver } = action.payload;
        if (tempRouteIndex >= 0) {
          draft.solution.routes[tempRouteIndex].driver_code = tempDriver.code;
          draft.solution.routes[tempRouteIndex].depot.location = tempDriver.start_location;
          draft.data[tempRouteIndex].driver_code = tempDriver.code;
          draft.data[tempRouteIndex].start_location = tempDriver.start_location;
          draft.data[tempRouteIndex].finish_location = tempDriver.finish_location;
          draft.drivers[tempRouteIndex] = tempDriver;
          action.meta.onSuccess();
        }
        break;
      default:
        return draft;
    }
  });
