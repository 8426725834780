import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ROUTER from '../../../config/router';
import VehiclePartPage from '../../VehiclePart/index';

const VehicleParts = () => (
  <Switch>
    <Route exact path={ROUTER.VEHICLE_PART.INDEX} component={VehiclePartPage} />
    {/* <Route exact path={ROUTER.VEHICLE.ADD_VEHICLE} component={AddVehicle} />
    <Route exact path={ROUTER.VEHICLE.EDIT_VEHICLE} component={EditVehicle} /> */}
  </Switch>
);
export default VehicleParts;
