import { PlusOutlined } from '@ant-design/icons';
import { Divider, Row, Button, Col } from 'antd';
// import queryString from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Filter from './components/Filter';
import CompanyList from './components/List';
import ROUTER from '../../config/router';
import WithConfig from '../../hoc/WithConfig';
import WithLoading from '../../hoc/WithLoading';
import { actions as companyActions } from '../../redux/CompanyRedux';

const { getCompaniesIfNeed, updateCompany, insertCompany, changePage, invalidateCompanies } =
  companyActions;

class CompanyPage extends Component {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props) {
    super(props);
  }
  state = {
    visible: false,
    dataFilter: {
      page: '',
      page_size: '',
      search: '',
      type: '',
    },
  };

  static getDerivedStateFromProps(props, state) {
    const page = props.meta.page;
    const page_size = props.meta.page_size;
    const { search, type } = state.dataFilter;
    props.getCompaniesIfNeed({
      page,
      page_size,
      search,
      type,
    });
    return null;
  }

  componentDidMount() {
    const { userRole } = this.props;
    if (userRole !== 'super-admin' && userRole !== 'admin') {
      this.props.history.push('/');
    }
  }

  onPageChange = (page, page_size) => {
    const { changePage, invalidateCompanies, total } = this.props;
    changePage(page, page_size, total);
    invalidateCompanies();
  };
  // onShowSizeChange = (page, page_size) => this.props.getUsers({ page, page_size });

  // handleFilter = () => {
  //   // this.showModal();
  //   const { dataFilter } = this.state;
  //   dataFilter.search = 'an';
  //   this.setState({ dataFilter });
  // };

  handleOnAdd = () => {
    const { history } = this.props;
    history.push(ROUTER.COMPANY.ADD_COMPANY);
  };

  onChangeFilter = (data) => {
    this.setState({
      dataFilter: data,
    });
  };

  // handleOk = () => {
  //   this.setState({
  //     visible: false,
  //   });
  // };

  // handleCancel = () => {
  //   this.setState({
  //     visible: false,
  //   });
  // };

  // search = (field) => {
  //   const { DataFilter } = this.state;
  //   DataFilter.search = field;
  //   this.setState({
  //     DataFilter,
  //   });
  //   this.props.getUsers(DataFilter);
  // };

  // handleUpdateUser = (oldUser, newUser) => {
  //   const { full_name, email, station_code, role, id } = newUser;
  //   // eslint-disable-next-line no-useless-escape
  //   const re = /^[a-z][a-z0-9]*([_\.][a-z0-9]+)*@[a-z0-9]+(\.[a-z0-9]+)+$/;
  //   if (full_name === '') {
  //     notification.open({
  //       type: 'error',
  //       message: `Tên không được để trống`,
  //     });
  //   } else if (email && !re.test(email)) {
  //     notification.open({
  //       type: 'error',
  //       message: `Email không đúng định dạng`,
  //     });
  //   } else {
  //     this.props.updateUser(
  //       id,
  //       {
  //         full_name,
  //         role,
  //         email,
  //         station_code,
  //       },
  //       {
  //         onSuccess: () => {
  //           notification.open({
  //             type: 'success',
  //             message: 'Cập nhật người dùng thành công',
  //           });
  //         },
  //         onError: (errorCode) =>
  //           notification.open({
  //             type: 'error',
  //             message: `Cập nhật người dùng thất bại ${errorCode}`,
  //           }),
  //       },
  //     );
  //   }
  // };
  // showModal = () => {
  //   this.setState({
  //     visible: true,
  //   });
  // };

  // handlerDataFromUserForm = (payload) => {
  //   delete payload.confirmPassword;
  //   this.props.insertUser(payload, {
  //     onSuccess: () => {
  //       notification.success({ message: 'Thêm tài khoản thành công' });
  //       this.setState({
  //         editCustomerModalVisible: false,
  //       });
  //       this.props.getUsers({ page: 1, page_size: 10, search: '' });
  //     },
  //     onError: (errorCode) => {
  //       notification.error({ message: `Thêm tài khoản thất bại. ${errorCode}` });
  //     },
  //   });
  // };

  render() {
    const { isFetching, meta, companies, companyCode } = this.props;
    return (
      <>
        <div>
          <h2 style={{ float: 'left' }}>Danh sách công ty</h2>
          <div style={{ float: 'right' }}>
            <Button shape="round" type="primary" onClick={this.handleOnAdd} icon={<PlusOutlined />}>
              Thêm
            </Button>
          </div>
        </div>
        <Divider style={{ maringTop: 0 }} />

        <div style={{ marginBottom: '15px' }}>
          <Filter onSearch={this.onChangeFilter} companyCode={companyCode} />
        </div>
        <CompanyList
          companyCode={companyCode}
          isFetching={isFetching}
          dataSource={companies}
          meta={meta}
          onPageChange={this.onPageChange}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { companyReducer, authReducer } = state;
  return {
    companies: companyReducer.items,
    meta: companyReducer.meta,
    total: companyReducer.meta.total,
    isFetching: companyReducer.isFetching,
    userRole: authReducer.authUser.role_code,
    companyCode: authReducer.authUser.company_code,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCompaniesIfNeed: (filterOption) => dispatch(getCompaniesIfNeed(filterOption)),
  updateCompany: (companyId, payload, meta) => dispatch(updateCompany(companyId, payload, meta)),
  insertCompany: (payload, meta) => dispatch(insertCompany(payload, meta)),
  invalidateCompanies: () => dispatch(invalidateCompanies()),
  changePage: (page, page_size, total) => dispatch(changePage(page, page_size, total)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WithConfig('company')(WithLoading(CompanyPage)));
