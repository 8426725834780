import React, { Fragment } from 'react';

import Depot from './depot';
import Node from './node';
import NodeConnector from './nodeConnector';

function Route({
  route,
  onChangeNodePosition,
  onSwapNodes,
  onShowNodeInfo,
  routeIndex,
  mainColor,
}) {
  return (
    <>
      <Depot />
      <NodeConnector
        nodeIndex={-1}
        routeIndex={routeIndex}
        onChangeNodePosition={onChangeNodePosition}
        mainColor={mainColor}
        time={route?.nodes?.[0]?.durationFromPrevNode}
      />
      {route?.nodes?.map((node, nodeIndex) => (
        <Fragment key={`${node?.customer?.code}-${node?.index}`}>
          <Node
            onSwapNodes={onSwapNodes}
            nodeIndex={node.index}
            onShowNodeInfo={onShowNodeInfo}
            node={node}
            routeIndex={routeIndex}
            mainColor={mainColor}
          />
          <NodeConnector
            nodeIndex={node.index}
            routeIndex={routeIndex}
            onChangeNodePosition={onChangeNodePosition}
            mainColor={mainColor}
            time={route.nodes[node.index]?.durationFromPrevNode}
          />
        </Fragment>
      ))}
      <Depot />
    </>
  );
}

export default Route;
