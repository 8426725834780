export const columnChartOptions = {
  series: [
    {
      name: 'Net Profit',
      data: [4080115.554458587, 312312, 32],
    },
    {
      name: 'Revenue',
      data: [16578, 4343, 12312],
    },
  ],
  chart: {
    type: 'bar',
    height: 350,
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '40%',
      endingShape: 'rounded',
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 1,
    colors: ['transparent'],
  },
  xaxis: {
    categories: [],
  },
  //   yaxis: {
  //     title: {
  //       text: '$ (thousands)',
  //     },
  //   },
  fill: {
    opacity: 1,
  },
  tooltip: {
    y: {
      formatter(val) {
        return ` ${val} `;
      },
    },
  },
};
