import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';

import { reducer as accountReducer } from './AccountRedux';
import { reducer as alertReducer } from './AlertRedux';
import { reducer as apiReducer } from './ApiRedux';
import { reducer as appReducer } from './AppRedux';
import { reducer as authReducer } from './AuthRedux';
import { reducer as cancelVoucherReducer } from './CancelVoucherRedux';
import { reducer as categoriesReducer } from './Categoriesredux';
import { reducer as categoryReducer } from './CategoryRedux';
import { reducer as companyReducer } from './CompanyRedux';
import { reducer as configurationReducer } from './ConfigurationRedux';
import { reducer as customerReducer } from './CustomerRedux';
import { reducer as dashboardReducer } from './DashboardRedux';
import { reducer as deliveryVoucherReducer } from './DeliveryVoucherRedux';
import { reducer as driverReducer } from './DriverRedux';
import { reducer as featureReducer } from './FeatureRedux';
import { reducer as InventoryRecordVoucherReducer } from './InventoryRecordVoucherRedux';
import { reducer as orderReducer } from './OrderRedux';
import { reducer as receivingVoucherReducer } from './ReceivingVoucherRedux';
import { reducer as roleReducer } from './RoleRedux';
import { reducer as routingReducer } from './RoutingRedux';
import { reducer as sampleRouteReducer } from './SampleRouteRedux';
import { reducer as secretKeyReducer } from './SecretKeyRedux';
import { reducer as storageReducer } from './StorageRedux';
import { reducer as tomtomReducer } from './TomtomRedux';
import { reducer as unitReducer } from './UnitRedux';
import { reducer as vehicleBillingReducer } from './VehicleBillingRedux';
import { reducer as vehicleExpiryReducer } from './VehicleExpiryRedux';
import { reducer as vehiclePartReducer } from './VehiclePartRedux';
import { reducer as vehicleReducer } from './VehicleRedux';
import { reducer as wareHousesReducer } from './WareHousesRedux';
import { reducer as productReducer } from './productRedux';

const logger = createLogger({
  collapsed: true,
  titleFormatter: (action) =>
    // `[${action.meta && action.meta.prefix && action.meta.prefix.join('] [')}] ${action.type}`,
    action.type,
});

export default configureStore({
  reducer: {
    accountReducer,
    alertReducer,
    apiReducer,
    appReducer,
    authReducer,
    companyReducer,
    configurationReducer,
    customerReducer,
    driverReducer,
    featureReducer,
    orderReducer,
    roleReducer,
    routingReducer,
    sampleRouteReducer,
    tomtomReducer,
    vehicleExpiryReducer,
    vehiclePartReducer,
    vehicleReducer,
    vehicleBillingReducer,
    dashboardReducer,
    storageReducer,
    categoryReducer,
    unitReducer,
    productReducer,
    deliveryVoucherReducer,
    wareHousesReducer,
    receivingVoucherReducer,
    cancelVoucherReducer,
    InventoryRecordVoucherReducer,
    categoriesReducer,
    secretKeyReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(logger),
});
