import { notification } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { actions as apiActions } from '../../../../redux/ApiRedux';
import { actions as roleActions } from '../../../../redux/RoleRedux';
import RoleForm from './Form';
import List from './List';
import ListAPI from './ListAPI';

class RoleTab extends Component {
  state = {
    visibleRole: false,
    visibleListApi: false,
    curRoleId: '',
    dataFilter: {
      search: '',
    },
  };

  static getDerivedStateFromProps(props, state) {
    const page = props.meta.page;
    const page_size = props.meta.page_size;
    const { search } = state.dataFilter;
    props.getRolesIfNeed({
      page,
      page_size,
      search,
    });
    return null;
  }

  componentDidMount() {
    this.props.getAllApis();
  }

  onPageChange = (page, page_size) => {
    const { changePage, invalidateRoles, meta } = this.props;
    changePage(page, page_size, meta.total);
    invalidateRoles();
  };

  onChangeFilter = (data) => {
    this.setState({
      dataFilter: data,
    });
  };

  setAddRole = () => {
    this.setState({ curEditRole: {} }, () => this.toggleModalRole());
  };

  setEditRole = (id, payload) => {
    this.setState({ curEditRole: { id, payload } }, () => this.toggleModalRole());
  };

  toggleModalRole = () => {
    this.setState((prevState) => ({ visibleRole: !prevState.visibleRole }));
  };

  activeDeactiveRole = (id) => {
    this.props.activeDeactiveRole(id, {
      onSuccess: () => {
        notification.success({ message: 'Trạng thái đã được cập nhật' });
      },
      onError: (error) => notification.error({ message: `${error} - Cập nhật thất bại` }),
    });
  };

  onCreateRole = (values) => {
    this.props.createRole(values, {
      onSuccess: () => {
        notification.success({ message: 'Tạo vai trò mới thành công' });
        this.toggleModalRole();
        this.props.invalidateRoles();
      },
      onError: (mes) => {
        notification.error({ message: `Tạo vai trò thất bại. ${mes}` });
      },
    });
  };

  onEditRole = (id, payload) => {
    this.props.editRole(id, payload, {
      onSuccess: () => {
        notification.success({ message: 'Đã cập nhật vai trò' });
        this.toggleModalRole();
        this.props.invalidateRoles();
      },
      onError: (error) => notification.error({ message: `${error} - Cập nhật thất bại` }),
    });
  };

  getApiOfRole = (payload) => {
    this.props.getApiOfRole(payload.id);
    this.setState({ curRoleId: { payload } }, () => this.toggleModalList());
  };

  toggleModalList = () => {
    this.setState((prevState) => ({ visibleListApi: !prevState.visibleListApi }));
  };

  deleteApiOfRole = (id, apiCode) => {
    const payload = { apis: apiCode };
    this.props.deleteApiOfRole(id, payload, {
      onSuccess: () => {
        notification.success({ message: 'Đã xóa api' });
      },
      onError: (error) => notification.error({ message: `${error} - Xóa thất bại` }),
    });
  };

  uploadApiFromExcel = (payload) => {
    const idrole = this.state.curRoleId;
    this.props.addApiOfRoleFromExcel(payload, {
      onSuccess: () => {
        notification.success({ message: 'Thêm mới api thành công' });
        this.props.getAPIsofRole(idrole.payload.id);
      },
      onError: (error) => notification.error({ message: `Thêm thất bại - ${error}` }),
    });
  };

  deleteListApiOfRole = (id, payload) => {
    this.props.deleteApiOfRole(id, payload, {
      onSuccess: () => {
        notification.success({ message: 'Đã xóa api' });
      },
      onError: (error) => notification.error({ message: `${error} - Xóa thất bại` }),
    });
  };

  createApiOfRole = (id, payload) => {
    this.props.createApiOfRole(id, payload, {
      onSuccess: () => {
        notification.success({ message: 'Thêm mới api thành công' });
      },
      onError: (error) => notification.error({ message: `${error} - Thêm thất bại` }),
    });
  };

  render() {
    const { allApis, isFetching, meta, roles, listApiOfRole } = this.props;
    const { curRoleId, visibleRole, visibleListApi, curEditRole } = this.state;
    return (
      <>
        <List
          dataSource={roles}
          meta={meta}
          isFetching={isFetching}
          onChange={this.onPageChange}
          onShowSizeChange={this.onPageChange}
          onSetAddRole={this.setAddRole}
          onSetEditRole={this.setEditRole}
          onActiveDeactiveRole={this.activeDeactiveRole}
          onGetIdListAPI={this.getApiOfRole}
          onChangeFilter={this.onChangeFilter}
        />
        <RoleForm
          visibleRole={visibleRole}
          editRole={curEditRole}
          onToggleModalRole={this.toggleModalRole}
          onCreateRole={this.onCreateRole}
          onEditRole={this.onEditRole}
        />
        <ListAPI
          isFetching={isFetching}
          visibleListApi={visibleListApi}
          allApis={allApis}
          listApiOfRole={listApiOfRole}
          roleId={curRoleId}
          onToggleModalList={this.toggleModalList}
          onDeleteApiOfRole={this.deleteApiOfRole}
          getApiOfRole={this.getApiOfRole}
          uploadApiFromExcel={this.uploadApiFromExcel}
          onDeleteListApiOfRole={this.deleteListApiOfRole}
          onCreateApiOfRole={this.createApiOfRole}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  meta: state.roleReducer.meta,
  roles: state.roleReducer.items,
  isFetching: state.roleReducer.isFetching,
  didInvalidate: state.roleReducer.didInvalidate,
  listApiOfRole: state.roleReducer.listApiOfRole,
  allApis: state.apiReducer.allItems,
});

const mapDispatchToProps = (dispatch) => ({
  getRolesIfNeed: (params) => dispatch(roleActions.getRolesIfNeed(params)),
  changePage: (page, page_size, total) => dispatch(roleActions.changePage(page, page_size, total)),
  invalidateRoles: () => dispatch(roleActions.invalidateRoles()),
  activeDeactiveRole: (id, meta) => dispatch(roleActions.activeDeactiveRole(id, meta)),
  createRole: (payload, meta) => dispatch(roleActions.insertRole(payload, meta)),
  editRole: (id, payload, meta) => dispatch(roleActions.editRole(id, payload, meta)),
  getApiOfRole: (id) => dispatch(roleActions.getApiOfRole(id)),
  deleteApiOfRole: (id, payload, meta) => dispatch(deleteApiOfRole(id, payload, meta)),
  getAllApis: (params) => dispatch(apiActions.getAllApis(params)),
  insertApiOfRoleFromExcel: (payload, meta) =>
    dispatch(roleActions.insertApiOfRoleFromExcel(payload, meta)),
  createApiOfRole: (payload, meta) => dispatch(roleActions.insertApiOfRole(payload, meta)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoleTab);
