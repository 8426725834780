import { PlusOutlined } from '@ant-design/icons';
import { Divider, Row, Button, Modal, Col, notification } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Filter from './components/Filter';
import UserList from './components/List';
import UserForm from './components/UserForm';
import WithConfig from '../../hoc/WithConfig';
import WithLoading from '../../hoc/WithLoading';
import { actions as accountActions } from '../../redux/AccountRedux';
import { actions as companyActions } from '../../redux/CompanyRedux';
import { actions as roleActions } from '../../redux/RoleRedux';
// eslint-disable-next-line import/no-unresolved

class UserAccount extends Component {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props) {
    super(props);
  }
  state = {
    visible: false,
    dataFilter: {
      page: '',
      page_size: '',
      search: '',
      accountType: '',
      company_code: '',
    },
  };

  static getDerivedStateFromProps(props, state) {
    const page = props.meta.page;
    const page_size = props.meta.page_size;
    const { search, accountType, company_code } = state.dataFilter;
    props.getUsersIfNeed({
      page,
      page_size,
      search,
      accountType,
      company_code,
    });
    return null;
  }

  componentDidMount() {
    this.props.getAllCompanies();
    this.props.getAllRoles();
  }

  onPageChange = (page, page_size) => {
    const { changePage, invalidateUsers, meta } = this.props;
    changePage(page, page_size, meta.total);
    invalidateUsers();
  };

  handleOnAdd = () => {
    // this.props.history.push(ROUTER.ACCOUNT.ADD);
    this.showModal();
  };

  handleOk = () => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleUpdateUser = (oldUser, newUser) => {
    const { full_name, email, role_code, code, phone_number } = newUser;
    // eslint-disable-next-line no-useless-escape
    const emailRe = /^[a-z][a-z0-9]*([_\.][a-z0-9]+)*@[a-z0-9]+(\.[a-z0-9]+)+$/;
    const phoneRe = /^(0)[1-9]([0-9]{8}[0-9]?)\b/;
    if (full_name === '') {
      notification.open({
        type: 'error',
        message: `Tên không được để trống`,
      });
    } else if (email && !emailRe.test(email)) {
      notification.open({
        type: 'error',
        message: `Email không đúng định dạng`,
      });
    } else if (phone_number && !phoneRe.test(phone_number)) {
      notification.open({
        type: 'error',
        message: `Số điện thoại không đúng định dạng`,
      });
    } else {
      this.props.updateUser(
        code,
        {
          full_name,
          role_code,
          email,
          phone_number,
        },
        {
          onSuccess: () => {
            notification.open({
              type: 'success',
              message: 'Cập nhật người dùng thành công',
            });
          },
          onError: (errorCode) =>
            notification.open({
              type: 'error',
              message: `Cập nhật người dùng thất bại ${errorCode}`,
            }),
        },
      );
    }
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handlerDataFromUserForm = (payload) => {
    delete payload.confirmPassword;
    this.props.insertUser(payload, {
      onSuccess: () => {
        notification.success({ message: 'Thêm tài khoản thành công' });
        this.props.invalidateUsers();
        this.setState({
          visible: false,
        });
      },
      onError: (errorCode) => {
        notification.error({ message: `Thêm tài khoản thất bại. ${errorCode}` });
      },
    });
  };

  onChangeFilter = (data) => {
    this.setState({
      dataFilter: data,
    });
  };

  render() {
    const {
      activeDeactiveUser,
      resetPassword,
      allRoles,
      isFetching,
      meta,
      users,
      userRole,
      userCompanyCode,
      currentCompany,
      allCompanies,
    } = this.props;

    const allowedRoles =
      allRoles && allRoles.length
        ? userRole === 'super-admin'
          ? allRoles.filter((item) => item.status)
          : allRoles.filter(
              (item) => item.status && item.code !== 'super-admin' && item.code !== 'admin',
            )
        : [];
    const companies = currentCompany?.code ? [currentCompany, ...allCompanies] : allCompanies;
    return (
      <>
        <Row>
          <Col span={24}>
            <div>
              <h2 style={{ float: 'left' }}>Danh sách tài khoản</h2>
              <div style={{ float: 'right' }}>
                <Button
                  shape="round"
                  type="primary"
                  onClick={this.handleOnAdd}
                  icon={<PlusOutlined />}>
                  Thêm
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Divider style={{ marginTop: 0 }} />

        <div style={{ marginBottom: 10 }}>
          <Filter onSearch={this.onChangeFilter} />
        </div>
        <UserList
          isFetching={isFetching}
          dataSource={users}
          meta={meta}
          onChange={this.onPageChange}
          onShowSizeChange={this.onPageChange}
          activeDeactiveUser={activeDeactiveUser}
          onUpdateUser={this.handleUpdateUser}
          resetPassword={resetPassword}
          allowedRoles={allowedRoles}
          userRole={userRole}
          allCompanies={companies}
        />
        <Modal
          title="Tạo tài khoản mới"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={<></>}>
          <div>
            <UserForm
              allowedRoles={allowedRoles}
              emitData={this.handlerDataFromUserForm}
              company_code={userCompanyCode}
            />
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.accountReducer.items,
  meta: state.accountReducer.meta,
  isFetching: state.accountReducer.isFetching,
  userRole: state.authReducer.authUser.role_code,
  userCompanyCode: state.authReducer.authUser.company_code,
  currentCompany: state.companyReducer.currentCompany,
  allCompanies: state.companyReducer.allItems,
  allRoles: state.roleReducer.allItems,
});

const mapDispatchToProps = (dispatch) => ({
  getUsersIfNeed: (filterOption) => dispatch(accountActions.getUsersIfNeed(filterOption)),
  updateUser: (code, payload, meta) => dispatch(accountActions.updateUser(code, payload, meta)),
  activeDeactiveUser: (code, meta) => dispatch(accountActions.activeDeactiveUser(code, meta)),
  resetPassword: (code, meta) => dispatch(accountActions.resetPassword(code, meta)),
  insertUser: (payload, meta) => dispatch(accountActions.insertUser(payload, meta)),
  changePage: (page, page_size, total) =>
    dispatch(accountActions.changePage(page, page_size, total)),
  invalidateUsers: () => dispatch(accountActions.invalidateUsers()),
  getAllCompanies: (filterOption) => dispatch(companyActions.getAllCompanies(filterOption)),
  getAllRoles: () => dispatch(roleActions.getAllRoles()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WithConfig('user')(WithLoading(UserAccount)));
