import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ROUTER from '../../../config/router';
import AddVehicle from '../../Vehicle/add';
import DetailVehicle from '../../Vehicle/detail';
import EditVehicle from '../../Vehicle/edit';
import VehiclePage from '../../Vehicle/index';

const Vehicles = () => (
  <Switch>
    <Route exact path={ROUTER.VEHICLE.INDEX} component={VehiclePage} />
    <Route exact path={ROUTER.VEHICLE.ADD_VEHICLE} component={AddVehicle} />
    <Route exact path={ROUTER.VEHICLE.DETAIL_VEHICLE} component={DetailVehicle} />
    <Route exact path={ROUTER.VEHICLE.EDIT_VEHICLE} component={EditVehicle} />
  </Switch>
);
export default Vehicles;
