import { PlusOutlined } from '@ant-design/icons';
import { Button, Divider } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import ModalDeliveryVoucher from './EditForm/Modal';
import Filter from './components/Filter';
import ListDeliveryVoucher from './components/List';
import ROUTER from '../../config/router';
import { actions as deliveryVoucherActions } from '../../redux/DeliveryVoucherRedux';
import { actions as wareHouseActions } from '../../redux/WareHousesRedux';

const DeliveryVoucher = ({
  getDeliveryVoucher,
  deliveryVoucherData,
  meta,
  isFetching,
  history,
  getInvetory,
  wareHouseData,
  changeState,
}) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [status, setStatus] = useState();
  const [productCodes, setProductCodes] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [curDeliveryVoucher, setcurDeliveryVoucher] = useState({});

  useEffect(() => {
    getDeliveryVoucher({
      page,
      page_size: pageSize,
      state: status,
      product_codes: productCodes,
    });
  }, [page, pageSize, status, productCodes]);

  useEffect(() => {
    getInvetory({
      page: -1,
      page_size: -1,
    });
  }, []);

  const handleChangeStatus = (value) => {
    setStatus(value);
  };

  const handleChangeProductCodes = (value) => {
    setProductCodes(value);
  };

  const onPageChange = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
  };

  const handleOpenModal = () => {
    setOpenModal(!openModal);
  };

  const handleAdd = () => {
    history.push(ROUTER.INVENTORY.ADD_DELIVERY_VOUCHER);
  };

  const getDeliveryVoucherByCode = (deliveryCode, payload) => {
    handleOpenModal();
    setcurDeliveryVoucher(payload);
  };

  return (
    <>
      <div>
        <h2 style={{ float: 'left' }}>Danh sách phiếu xuất</h2>
        <div style={{ float: 'right' }}>
          <Button shape="round" type="primary" onClick={handleAdd} icon={<PlusOutlined />}>
            Thêm
          </Button>
        </div>
      </div>
      <Divider style={{ marginTop: 0 }} />
      <div style={{ marginBottom: '15px' }}>
        <Filter
          onChangeStatus={handleChangeStatus}
          onChangeProductCodes={handleChangeProductCodes}
        />
      </div>
      <ListDeliveryVoucher
        data={deliveryVoucherData}
        meta={meta}
        isFetching={isFetching}
        onPageChange={onPageChange}
        getDeliveryVoucherByCode={getDeliveryVoucherByCode}
        changeState={changeState}
        getDeliveryVoucher={getDeliveryVoucher}
      />

      <ModalDeliveryVoucher
        item={curDeliveryVoucher}
        toggleModalVoucher={handleOpenModal}
        wareHouseData={wareHouseData}
        visibleModal={openModal}
        getDeliveryVoucher={getDeliveryVoucher}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  deliveryVoucherData: state?.deliveryVoucherReducer?.data,
  meta: state?.deliveryVoucherReducer?.meta,
  isFetching: state?.deliveryVoucherReducer?.isFetching,
  wareHouseData: state?.wareHousesReducer?.data,
});

const mapDispatchToProps = (dispatch) => ({
  getDeliveryVoucher: (params) => dispatch(deliveryVoucherActions.getDeliveryVoucher(params)),
  getInvetory: (params) => dispatch(wareHouseActions.getInvetory(params)),
  changeState: (payload, meta) => dispatch(deliveryVoucherActions.changeState({ payload, meta })),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryVoucher);
