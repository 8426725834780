import { Button, Col, DatePicker, Divider, Modal, Row, notification } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import EditForm from './EditForm';
import CustomerSearch from '../../../components/FormItems/CustomerSearch';
import { VOUCHER_STATUS } from '../../../constants/enum';
import { actions as customerActions } from '../../../redux/CustomerRedux';
import { actions as deliveryVoucherActions } from '../../../redux/DeliveryVoucherRedux';

const { updateDeliveryVoucher } = deliveryVoucherActions;

const ModalDeliveryVoucher = ({
  visibleModal,
  toggleModalVoucher,
  item,
  wareHouseData,
  updateDeliveryVoucher,
  getDeliveryVoucher,
  getAllCustomers,
}) => {
  const [note, setNote] = useState(item?.note);
  const [deliveryTime, setDeliveryTime] = useState(
    item?.delivery_time ? moment(item.delivery_time).toISOString() : '',
  );
  const [deliveryTo, setDeliveryTo] = useState(item?.delivery_to);
  const [submitData, setSubmitData] = useState();

  useEffect(() => {
    getAllCustomers();
  }, []);

  useEffect(() => {
    setDeliveryTime(item?.delivery_time ? moment(item.delivery_time).toISOString() : '');
    setDeliveryTo(item?.delivery_to);
    setNote(item?.note);
  }, [item]);

  const isCompleted = item?.state === 'completed' || item?.state === 'cancelled';

  const onChangeNote = (value) => {
    setNote(value.target.value);
  };

  const onChangeDeliveryTime = (value) => {
    setDeliveryTime(moment(value).toISOString());
  };
  const getData = (product) => {
    const curData = [];
    if (product) {
      product?.map((value, index) => {
        curData.push({
          category_code: value?.category_code,
          category_name: value?.category_name,
          expiry_date: value?.expiry_date,
          lot_image_url: '',
          lot_number: value?.lot_number,
          manufacturing_date: value?.manufacturing_date,
          product_code: value?.product_code,
          product_name: value?.product_name,
          product_secondary_code: value?.product_secondary_code,
          quantity: value?.quantity,
          input_price: value?.input_price,
          unit: {
            factor: value?.unit?.factor,
            primary_unit: value?.unit?.primary_unit,
            secondary_unit: value?.unit?.secondary_unit,
          },
        });
      });
    } else {
      item?.product_items?.map((value, index) => {
        curData.push({
          category_code: value?.category?.code,
          category_name: value?.category?.name,
          expiry_date: value?.expiry_date,
          lot_image_url: '',
          lot_number: value?.lot_number,
          manufacturing_date: value?.manufacturing_date,
          product_code: value?.code,
          product_name: value?.name,
          product_secondary_code: value?.secondary_code,
          quantity: value?.quantity,
          input_price: value?.input_price,
          unit: {
            factor: value?.unit?.factor,
            primary_unit: value?.unit?.primary_unit,
            secondary_unit: value?.unit?.secondary_unit,
          },
        });
      });
    }
    setSubmitData(curData);
  };

  const handleSubmit = () => {
    const payload = {
      note,
      product_items: submitData ? submitData : item.product_items,
      delivery_time: deliveryTime,
      storage_code: item?.storage_code,
      storage_level: item?.storage_level,
      storage_name: item?.storage_name,
      delivery_to: deliveryTo
        ? {
            code: deliveryTo?.code || deliveryTo?.value || '',
            name: deliveryTo?.name || deliveryTo?.label || '',
            type: 'Customer',
          }
        : item?.delivery_to,
    };

    updateDeliveryVoucher(
      {
        code: item.code,
        payload,
      },
      {
        onSuccess: () => {
          notification.success({ message: 'Sửa phiếu xuất thành công' });
          getDeliveryVoucher({
            page: 1,
            page_size: 10,
          });
          getProducts({
            page: 1,
            page_size: 10,
          });
        },
        onError: (mes) => {
          notification.error({ message: `Sửa phiếu xuất thất bại. ` });
        },
      },
    );
    toggleModalVoucher();
  };

  return (
    <Modal
      title={
        <Row gutter={15}>
          <Col span={24}>
            <h3 style={{ float: 'left' }}>Thông tin phiếu xuất</h3>
          </Col>
        </Row>
      }
      visible={visibleModal}
      onCancel={toggleModalVoucher}
      destroyOnClose
      width={1200}
      footer={<></>}>
      <Row gutter={15} style={{ marginBottom: '10px' }}>
        <Col span={24} lg={12}>
          <Row>
            <Col span={12} lg={6}>
              <b>Mã: </b>
            </Col>
            <Col span={12} lg={18}>
              <>{item?.code}</>
            </Col>
          </Row>
        </Col>
        <Col span={24} lg={12}>
          <Row>
            <Col span={12} lg={6}>
              <b>Trạng thái: </b>
            </Col>
            <Col span={12} lg={18}>
              <>{VOUCHER_STATUS[item?.state]}</>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={15} style={{ marginBottom: '10px' }}>
        <Col span={24} lg={12}>
          <Row>
            <Col span={12} lg={6}>
              <b>Ngày tạo: </b>
            </Col>
            <Col span={12} lg={18}>
              <>{moment(item?.created_at).format('DD-MM-YYYY HH:mm:ss')}</>
            </Col>
          </Row>
        </Col>
        <Col span={24} lg={12}>
          <Row>
            <Col span={12} lg={6}>
              <b>Ngày xuất kho: </b>
            </Col>
            <Col span={12} lg={18}>
              {isCompleted ? (
                <p>
                  {item?.delivery_time
                    ? moment(item?.delivery_time).format('DD/MM/YYYY HH:mm')
                    : '-'}
                </p>
              ) : (
                <DatePicker
                  style={{ width: '100%' }}
                  onChange={onChangeDeliveryTime}
                  placeholder="Chọn ngày xuất kho"
                  format="DD-MM-YYYY"
                  allowClear={false}
                  defaultValue={item?.delivery_time ? moment(item?.delivery_time) : ''}
                />
              )}
            </Col>
          </Row>
        </Col>
        <Col span={24} lg={12}>
          <Row>
            <Col span={12} lg={6}>
              <b>Khách hàng: </b>
            </Col>
            <Col span={12} lg={18}>
              {isCompleted ? (
                <p>{deliveryTo?.name}</p>
              ) : (
                <CustomerSearch
                  onChange={setDeliveryTo}
                  mode="object"
                  selectedValue={deliveryTo?.code}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider />
      <EditForm
        onChangeNote={onChangeNote}
        onChangeReceivingTime={onChangeDeliveryTime}
        // receivingTime={item?.receiving_time}
        products={item?.product_items}
        sendData={getData}
        state={item?.state}
        note={item?.note || note}
        voucherCode={item?.code}
        warehouseProducts={wareHouseData}
      />
      <Row>
        <Col span={8} lg={6}>
          <b>Ghi chú: </b>
        </Col>
        <Col span={16} lg={18}>
          {isCompleted ? (
            <p>{note}</p>
          ) : (
            <TextArea rows={2} placeholder="Ghi chú" value={note} onChange={onChangeNote} />
          )}
        </Col>
      </Row>
      <Button
        type="primary"
        shape="round"
        style={{ width: '20%', marginLeft: '40%', marginTop: '15px' }}
        onClick={handleSubmit}
        disabled={isCompleted}>
        Lưu phiếu xuất
      </Button>
    </Modal>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getAllCustomers: () => dispatch(customerActions.getAllCustomers()),
  updateDeliveryVoucher: (payload, meta) => dispatch(updateDeliveryVoucher({ payload, meta })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalDeliveryVoucher);
