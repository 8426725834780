import * as tt from '@tomtom-international/web-sdk-maps';
import * as ttapi from '@tomtom-international/web-sdk-services';
import { Promise as bluebirdPromise } from 'bluebird';
import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import '@tomtom-international/web-sdk-maps/dist/maps.css';
import './Tomtom.css';
import { connect } from 'react-redux';

import { COLORS } from '../../../config/colors';
import { actions as routingActions } from '../../../redux/RoutingRedux';

const convertToPoints = (location) => ({
  point: {
    latitude: location?.latitude,
    longitude: location?.longitude,
  },
});

function TomtomCVRP(props) {
  const { solution, didInvalidate, validateSolution, hiddenRoutesIndex } = props;
  // const history = useHistory();
  const mapElement = useRef();
  const prevCounts = useRef();
  const [map, setMap] = useState({});
  const [longitude] = useState(105.85387423256537);
  const [latitude] = useState(21.027961928464592);
  const [curOrderMarkers, setCurOrderMarkers] = useState([]);
  const [changedRouteCount, setChangedRouteCount] = useState(0);
  const [countRoutes, setCountRoutes] = useState(0);
  const routes = props.routes
    ? props.routes
    : solution?.routes?.map((item) => ({ nodes: item.nodes })) || [];

  if (!routes?.length) {
    const elements = document.getElementsByClassName('marker');
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }
  }

  const drawRoute = (geoJson, map, color, index) => {
    if (hiddenRoutesIndex.includes(index)) {
      map.addLayer({
        id: `route-${index}-${changedRouteCount}`,
        type: 'line',
        source: {
          type: 'geojson',
          data: geoJson,
        },
        paint: {
          'line-color': color,
          'line-width': 3,
        },
      });
    }
  };

  // Khởi tạo map
  useEffect(() => {
    const initMap = tt.map({
      key: process.env.REACT_APP_TOM_TOM_API_KEY,
      container: mapElement.current,
      center: [longitude, latitude],
      zoom: 12,
      language: 'VN',
    });
    setMap(initMap);
    return () => initMap.remove();
  }, []);

  useEffect(() => {
    if (map && routes) {
      if (routes.length === 0) {
        for (let i = countRoutes; i > 0; i--) {
          map.removeLayer(`route-${i - 1}-${changedRouteCount - 1}`);
        }
      }
      if (countRoutes !== prevCounts.current || countRoutes === 0) {
        for (let i = prevCounts.current; i > 0; i--) {
          map.removeLayer(`route-${i - 1}-${changedRouteCount - 1}`);
        }
      }
    }
  }, [routes]);

  useEffect(() => {
    prevCounts.current = countRoutes;
  }, [countRoutes]);

  //Create driver marker
  const addDriverMarker = () => {
    if (routes?.length) {
      routes?.map((route) => {
        const { depot } = route;
        if (!depot?.location?.longitude || !depot?.location?.latitude) {
          return;
        }

        const markerElement = document.createElement('div');
        markerElement.className = 'marker';

        const markerContentElement = document.createElement('div');
        markerContentElement.className = 'marker-content';
        markerContentElement.style.backgroundColor = '#363636';
        markerElement.appendChild(markerContentElement);

        const iconElement = document.createElement('div');
        iconElement.className = 'marker-icon';
        iconElement.style.backgroundImage = `url(https://i.imgur.com/oz3r3Wq.png)`;
        markerContentElement.appendChild(iconElement);

        const popup = new tt.Popup({ offset: 30 }).setText(depot?.location?.address);

        const marker = new tt.Marker({
          draggable: false,
          element: markerElement,
          anchor: 'bottom',
        })
          .setLngLat([depot.location.longitude, depot.location.latitude])
          .setPopup(popup)
          .addTo(map);
        map.jumpTo({ center: [depot.location.longitude, depot.location.latitude] });
      });
    }
  };

  //Create order markers
  const removeAllOrderMarkers = () => {
    curOrderMarkers.map((item) => item.remove());
    setCurOrderMarkers([]);
  };

  const addOrderMarker = (code, location, color, tempOrderMarkers) => {
    const markerElement = document.createElement('div');
    markerElement.className = 'marker';

    const markerContentElement = document.createElement('div');
    markerContentElement.className = 'marker-content';
    markerContentElement.style.backgroundColor = color;
    markerElement.appendChild(markerContentElement);

    const markerTextElement = document.createElement('p');
    const text = document.createTextNode(code);
    markerTextElement.className = code.length > 3 ? 'marker-text-many' : 'marker-text';
    markerTextElement.appendChild(text);
    markerElement.appendChild(markerTextElement);

    const popup = new tt.Popup({ offset: 30 }).setText(location?.address);

    const marker = new tt.Marker({
      draggable: false,
      element: markerElement,
      anchor: 'bottom',
    })
      .setLngLat([location?.longitude, location?.latitude])
      .setPopup(popup)
      .addTo(map);
    tempOrderMarkers.push(marker);
  };

  const addRouteNodesMarkers = (nodes, color, tempOrderMarkers) => {
    nodes?.map((item) =>
      addOrderMarker(
        item.shipping_code || item?.customer?.code || `${item.index + 1}`,
        item.location,
        color,
        tempOrderMarkers,
      ),
    );
  };

  const addOrdersMarkers = () => {
    if (routes?.length) {
      removeAllOrderMarkers();
      const tempOrderMarkers = [];
      routes.map((route, index) => {
        if (hiddenRoutesIndex.includes(index))
          addRouteNodesMarkers(route.nodes, COLORS[index], tempOrderMarkers);
      });
      setCurOrderMarkers(tempOrderMarkers);
    }
  };

  //Draw routes
  const sortRouteDestinations = (locations) => {
    const { depot } = routes[0];

    const pointsForDestinations = locations.map((destination) =>
      convertToPoints(destination.location),
    );
    const callParameters = {
      key: process.env.REACT_APP_TOM_TOM_API_KEY,
      destinations: pointsForDestinations,
      origins: [convertToPoints(depot.location)],
    };

    return new Promise((resolve, reject) => {
      ttapi.services.matrixRouting(callParameters).then((matrixAPIResults) => {
        const results = matrixAPIResults.matrix[0];
        const resultsArray = results.map((result, index) => ({
          location: locations[index]?.location,
          // drivingtime: result.response.routeSummary.travelTimeInSeconds,
          index,
        }));
        // resultsArray.sort((a, b) => a.drivingtime - b.drivingtime);
        resultsArray.sort((a, b) => a.index - b.index);
        const sortedLocations = resultsArray.map((result) => result.location);
        resolve(sortedLocations);
      });
    });
  };

  const reCalculateRoute = (nodes, color, index) => {
    const { depot } = routes[index];
    return sortRouteDestinations(nodes)
      .then((sorted) => {
        sorted.unshift(depot.location);
        sorted.push(depot.location);
        return ttapi.services
          .calculateRoute({
            key: process.env.REACT_APP_TOM_TOM_API_KEY,
            locations: sorted,
          })
          .then((routeData) => {
            const geoJson = routeData.toGeoJson();
            drawRoute(geoJson, map, color, index);
          });
      })
      .catch(() => {})
      .then(() => bluebirdPromise.delay(100));
  };

  const calculateRoutes = async () => {
    if (routes?.length) {
      setCountRoutes(routes.length);
      return bluebirdPromise
        .map(
          routes,
          (route, index) => {
            if (route.nodes?.length) {
              return reCalculateRoute(route.nodes, COLORS[index], index);
            }
            return bluebirdPromise.resolve();
          },
          { concurrency: 1 },
        )
        .then(() => setChangedRouteCount(changedRouteCount + 1));
    }
  };

  useEffect(() => {
    if (!map || !map?.options || !routes?.length || routes?.[0]?.depot?.location === undefined)
      return;

    addDriverMarker();
    addOrdersMarkers();

    if (changedRouteCount !== 0)
      routes.map((route, index) => map.removeLayer(`route-${index}-${changedRouteCount - 1}`));

    calculateRoutes();
    validateSolution();
  }, [map, longitude, latitude, routes, didInvalidate]);

  return map ? <div ref={mapElement} className="tomtom-cvrp-map" /> : null;
}

const mapStateToProps = (state) => ({
  solution: state.routingReducer.solution,
  didInvalidate: state.routingReducer.didInvalidate,
  hiddenRoutesIndex: state.routingReducer.hiddenRoutesIndex,
});

const mapDispatchToProps = (dispatch) => ({
  validateSolution: () => dispatch(routingActions.validateSolution()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TomtomCVRP);
