import { Col, DatePicker, Row, Select, notification } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import ProductForm from './AddForm/ProductForm';
import CustomerSearch from '../../components/FormItems/CustomerSearch';
import ROUTER from '../../config/router';
import { actions as customerActions } from '../../redux/CustomerRedux';
import { actions as deliveryVoucherActions } from '../../redux/DeliveryVoucherRedux';
import { actions as StorageActions } from '../../redux/StorageRedux';
import { actions as wareHouseActions } from '../../redux/WareHousesRedux';
import { actions as ProductActions } from '../../redux/productRedux';

const { getStorage, getStorageById } = StorageActions;
const { getProducts } = ProductActions;
const { getInvetory } = wareHouseActions;
const { addDeliveryVoucher, getDeliveryVoucher } = deliveryVoucherActions;

const AddDeliveryVoucher = ({
  getStorage,
  storageData,
  getInvetory,
  wareHouseData,
  addDeliveryVoucher,
  getDeliveryVoucher,
  getAllCustomers,
  history,
}) => {
  const [storageCode, setStorageCode] = useState();
  const [note, setNote] = useState('');
  const [deliveryTime, setDeliveryTime] = useState(moment().toISOString());
  const [deliveryTo, setDeliveryTo] = useState();

  useEffect(() => {
    getStorage({
      page: -1,
      page_size: -1,
    });

    getInvetory({
      page: -1,
      page_size: -1,
    });

    getAllCustomers();
  }, []);

  useEffect(() => {
    if (storageData?.length && !storageCode) {
      setStorageCode(storageData[0].code);
    }
  }, [storageData]);

  const handleChangeStorageCode = (value) => {
    setStorageCode(value);
  };

  const handleChangeNote = (value) => {
    setNote(value.target.value);
  };

  const handleChangeDeliveryTime = (value) => {
    setDeliveryTime(moment(value).toISOString());
  };

  const handleSubmit = (value) => {
    if (!storageCode) {
      notification.error({ message: 'Hãy chọn kho!' });
      return;
    }
    if (!value?.length) {
      notification.error({ message: 'Hãy chọn sản phẩm!' });
      return;
    }
    const curStorage = storageData?.length
      ? storageData.find((item) => item.code === storageCode)
      : null;
    const curData = [];
    value.map((value) => {
      curData.push({
        category_code: '',
        category_name: '',
        expiry_date: value?.selectedLotNumber?.expiry_date,
        input_price: 0,
        lot_image_url: '',
        lot_number: value?.selectedLotNumber?.lot_number,
        manufacturing_date: value?.selectedLotNumber?.manufacturing_date,
        product_code: value?.code,
        product_name: value?.name,
        product_secondary_code: value?.secondary_code,
        quantity: value?.quantity,
        unit: value?.unit,
      });
    });
    const payload = {
      delivery_time: deliveryTime,
      note,
      product_items: curData,
      storage_code: storageCode,
      storage_level: curStorage?.level,
      storage_name: curStorage?.name,
      delivery_to: deliveryTo
        ? {
            code: deliveryTo?.code || deliveryTo?.value || '',
            name: deliveryTo?.name || deliveryTo?.label || '',
            type: 'Customer',
          }
        : undefined,
    };

    addDeliveryVoucher(payload, {
      onSuccess: () => {
        notification.success({ message: 'Thêm phiếu xuất thành công' });
        history.push(ROUTER.INVENTORY.DELIVERY_VOUCHER);
      },
      onError: () => {
        notification.error({ message: `Thêm phiếu xuất thất bại. ` });
      },
    });
  };

  return (
    <>
      <h2>Tạo phiếu xuất mới</h2>
      <Row gutter={15}>
        <Col span={8} style={{ marginTop: 10, marginBottom: 20 }}>
          <h3 style={{ marginBottom: 10 }}>Chọn kho</h3>
          <Select
            placeholder="Chọn kho"
            value={storageCode}
            onChange={handleChangeStorageCode}
            style={{ width: '99%', float: 'right' }}>
            {storageData?.map((storage) => (
              <Select.Option key={storage?.code} value={storage?.code}>
                {storage?.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={8} style={{ marginTop: 10, marginBottom: 20 }}>
          <h3 style={{ marginBottom: 10 }}>Thời gian xuất kho</h3>
          <DatePicker
            style={{ width: '100%' }}
            onChange={handleChangeDeliveryTime}
            defaultValue={moment()}
            placeholder="Chọn ngày xuất"
            format="DD-MM-YYYY"
            allowClear={false}
          />
        </Col>
        <Col span={8} style={{ marginTop: 10, marginBottom: 20 }}>
          <h3 style={{ marginBottom: 10 }}>Khách hàng</h3>
          <CustomerSearch onChange={setDeliveryTo} mode="object" />
        </Col>
      </Row>
      <Row gutter={15} />
      <ProductForm
        products={wareHouseData}
        storages={storageData}
        handleSubmit={handleSubmit}
        onChangeNote={handleChangeNote}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  storageData: state?.storageReducer?.Storage?.data,
  isFetching: state?.productReducer?.isFetching,
  wareHouseData: state?.wareHousesReducer?.data,
});

const mapDispatchToProps = (dispatch) => ({
  getStorage: (params) => dispatch(getStorage(params)),
  getProducts: (params) => dispatch(getProducts(params)),
  getStorageById: (id) => dispatch(getStorageById(id)),
  getInvetory: (params) => dispatch(getInvetory(params)),
  addDeliveryVoucher: (payload, meta) => dispatch(addDeliveryVoucher({ payload, meta })),
  getDeliveryVoucher: (params) => dispatch(getDeliveryVoucher(params)),
  getAllCustomers: () => dispatch(customerActions.getAllCustomers()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddDeliveryVoucher);
