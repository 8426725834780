import { CheckOutlined } from '@ant-design/icons';
import { Row, Button, Col, Form, Modal, InputNumber, DatePicker, notification } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import './ExpiryForm.css';

import VehiclePartSearch from '../../../../../components/FormItems/VehiclePartSearch';

class ExpiryForm extends Component {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }
  state = {
    formRef: this.formRef,
    listPart: [],
    expiryKm: false,
    expiryTime: false,
    partCode: false,
    importDate: false,
  };

  onValidateDistance = (values) => {
    if (values?.expiry_km_to && values.expiry_km_from >= values.expiry_km_to) {
      this.setState({
        expiryKm: true,
      });
      return false;
    }
    if (values?.expiry_time_to && values.expiry_time_from >= values.expiry_time_to) {
      this.setState({
        expiryTime: true,
        check: true,
      });
      return false;
    } else {
      return true;
    }
  };

  onvalidateModal = () => {
    const { listPart } = this.state;
    const uniqueCode = new Set(listPart.map((value) => value.partCode));
    const partCodeFilter = listPart.map((e) => e.partCode).filter((value) => value);
    const importDateFilter = listPart.map((e) => e.importDate).filter((value) => value);
    if (listPart && listPart.length > 0) {
      if (uniqueCode.size < listPart.length) {
        notification.open({
          message: 'Trùng tên linh kiện',
          type: 'error',
        });
      } else if (partCodeFilter.length < importDateFilter.length) {
        notification.open({
          message: 'Thiếu tên linh kiện',
          type: 'error',
        });
        return false;
      } else if (partCodeFilter.length > importDateFilter.length) {
        notification.open({
          message: 'Thiếu ngày nhập hàng',
          type: 'error',
        });
        return false;
      } else return true;
    }
    return false;
  };

  renderListProduct = () => {
    const { listPart } = this.state;
    const listInput = [];
    for (let i = 0; i <= listPart.length - 1; i += 1) {
      listInput.push(
        <Row gutter={15}>
          <Col span={8}>
            <Form.Item name="partCode">
              <VehiclePartSearch
                allowClear
                onChange={(value) => this.updateListProduct(i + 1, { partCode: value })}
              />
            </Form.Item>
          </Col>

          <Col span={7}>
            <DatePicker
              onChange={(value) => this.updateListProduct(i + 1, { importDate: value })}
              style={{ width: '100%' }}
              format="DD/MM/YYYY"
            />
          </Col>

          <Col span={9}>
            <InputNumber
              placeholder="Nhập quãng đường"
              onChange={(value) => this.updateListProduct(i + 1, { travelDistance: value })}
              min={0}
              style={{ width: '100%' }}
            />
          </Col>
        </Row>,
      );
    }
    return listInput;
  };

  onFinish = () => {
    if (this.onvalidateModal()) {
      this.props.onAdd(this.state.listPart);
      this.toggleModal();
    }
  };

  onUpdateModal = (value) => {
    if (this.onValidateDistance(value)) {
      //update
      const values = {
        ...value,
        importDate: moment(value.importDate).toISOString(),
      };
      this.props.onEdit(values);
      this.toggleModal();
    }
  };

  toggleModal = () => {
    this.setState({
      listPart: [],
      expiryKm: false,
      expiryTime: false,
      partCode: false,
      importDate: false,
    });
    this.props.toggleForm();
  };

  updateListProduct = (index, value) => {
    const { listPart } = this.state;
    const temp = [...listPart];
    temp[index] = { ...temp[index], ...value };
    temp[index].travelDistance = temp[index].travelDistance || 0;
    this.setState({ listPart: temp });
  };

  render() {
    const { visible, isEdit, curVehiclePart } = this.props;
    const formItemLayout = {
      labelCol: {
        span: 24,
      },
    };
    return (
      <Modal
        title={isEdit ? 'Sửa linh kiện' : 'Thêm linh kiện'}
        visible={visible}
        onCancel={this.toggleModal}
        destroyOnClose
        footer={<></>}>
        <Form
          ref={this.formRef}
          {...formItemLayout}
          onFinish={isEdit ? this.onUpdateModal : this.onFinish}
          initialValues={{
            partCode: isEdit && curVehiclePart.partCode ? curVehiclePart.partCode : '',
            importDate:
              isEdit && curVehiclePart.partCode ? moment(curVehiclePart.payload.importDate) : '',
            travelDistance:
              isEdit && curVehiclePart.partCode ? curVehiclePart.payload.travelDistance : '',
            expiry_km_from:
              isEdit && curVehiclePart.partCode ? curVehiclePart.payload.expiry_km_from : 0,
            expiry_km_to:
              isEdit && curVehiclePart.partCode ? curVehiclePart.payload.expiry_km_to : 0,
            expiry_time_from:
              isEdit && curVehiclePart.partCode ? curVehiclePart.payload.expiry_time_from : 0,
            expiry_time_to:
              isEdit && curVehiclePart.partCode ? curVehiclePart.payload.expiry_time_to : 0,
          }}>
          <Row gutter={15}>
            <Col span={8}>
              <Form.Item required name="partCode" label="Tên linh kiện">
                <VehiclePartSearch
                  selectedValue={curVehiclePart?.partCode}
                  onChange={(value) => this.updateListProduct(0, { partCode: value })}
                />
              </Form.Item>
            </Col>

            <Col span={7}>
              <Form.Item required name="importDate" label="Ngày nhập hàng">
                <DatePicker
                  onChange={(value) => this.updateListProduct(0, { importDate: value })}
                  style={{ width: '100%' }}
                  format="DD/MM/YYYY"
                />
              </Form.Item>
            </Col>

            <Col span={9}>
              <Form.Item name="travelDistance" label="Quãng đường đã đi">
                <InputNumber
                  placeholder="Nhập quãng đường"
                  onChange={(value) => this.updateListProduct(0, { travelDistance: value })}
                  style={{ width: '100%' }}
                  min={0}
                  value={0}
                />
              </Form.Item>
            </Col>
          </Row>

          {!isEdit ? this.renderListProduct() : null}

          {isEdit && curVehiclePart.partCode ? (
            <>
              <Row className="flex-container">
                <Col span={24} className="padding-div">
                  Hạn sử dụng theo quãng đường
                </Col>
                <Col span={6} style={{ height: 40 }}>
                  <Form.Item name="expiry_km_from">
                    <InputNumber
                      min={0}
                      onChange={this.onChange}
                      style={{ borderColor: this.state.expiryKm ? 'red' : '' }}
                    />
                  </Form.Item>
                </Col>
                <Col span={1}>-</Col>
                <Col span={6} style={{ height: 40 }}>
                  <Form.Item name="expiry_km_to">
                    <InputNumber
                      min={0}
                      onChange={this.onChange}
                      style={{ borderColor: this.state.expiryKm ? 'red' : '' }}
                    />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <div>km</div>
                </Col>
                {this.state.expiryKm ? (
                  <Col span={24} id="warning-text">
                    Hạn sử dụng bắt đầu phải nhỏ hơn hạn sử dụng kết thúc !!!
                  </Col>
                ) : (
                  ''
                )}
              </Row>

              <Row className="flex-container">
                <Col span={24} className="padding-div">
                  Hạn sử dụng theo thời gian
                </Col>
                <Col span={6} style={{ height: 40 }}>
                  <Form.Item name="expiry_time_from">
                    <InputNumber
                      min={0}
                      onChange={this.onChange}
                      style={{ borderColor: this.state.expiryTime ? 'red' : '' }}
                    />
                  </Form.Item>
                </Col>
                <Col span={1}>-</Col>
                <Col span={6} style={{ height: 40 }}>
                  <Form.Item name="expiry_time_to">
                    <InputNumber
                      min={0}
                      onChange={this.onChange}
                      style={{ borderColor: this.state.expiryTime ? 'red' : '' }}
                    />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <div>tháng</div>
                </Col>
                {this.state.expiryTime ? (
                  <Col span={24} id="warning-text">
                    Hạn sử dụng bắt đầu phải nhỏ hơn hạn sử dụng kết thúc !!!
                  </Col>
                ) : (
                  ''
                )}
              </Row>
            </>
          ) : null}

          <Button
            type="primary"
            htmlType="submit"
            shape="round"
            style={{
              width: '20%',
              marginLeft: '180px',
              marginTop: '10px',
            }}>
            <CheckOutlined />
            Lưu
          </Button>
        </Form>
      </Modal>
    );
  }
}

export default ExpiryForm;
