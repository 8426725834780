import { Col, Divider, Row, notification, Typography } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import OrderForm from './components/Form';
import Card from '../../components/Card';
import ROUTER from '../../config/router';
import { actions as orderActions } from '../../redux/OrderRedux';
import { isBelowBreakpoint } from '../../utils/window';

class AddVehicle extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSubmit = (values) => {
    const { history } = this.props;
    const orderPayload = {
      customer: values.customer,
      delivery_date: values.delivery_date,
      demand: values.demand,
      driver_code: '',
      feature_codes: values.feature_codes || [],
      money: 0,
      note: values.note,
      occupied_space: values.occupied_space,
      vehicle_type: values.vehicle_type || '',
    };

    this.props.insertOrder(orderPayload, {
      onSuccess: (newOrder) => {
        notification.open({
          message: 'Thêm đơn giao hàng thành công!',
          type: 'success',
        });
        if (newOrder?.code) {
          history.push(ROUTER.ORDER.EDIT.replace(':code', newOrder.code));
        } else {
          history.push(ROUTER.ORDER.INDEX);
        }
      },
      onError: () =>
        notification.open({
          message: `${'Thêm đơn giao hàng thất bại!'}`,
          type: 'error',
        }),
    });
  };

  render() {
    const { configValues } = this.props;
    const defaultValue = {
      timeWindow:
        configValues.workTimeFrom && configValues.workTimeTo
          ? [moment(configValues.workTimeFrom, 'HH:mm'), moment(configValues.workTimeTo, 'HH:mm')]
          : ['', ''],
      occupied_space: configValues.vehicle_volume,
      demand: configValues.vehicle_capacity,
    };
    return (
      <div>
        <Row type="flex" justify="center">
          <Col span={24} lg={24}>
            <Card hoverable={false} bordered={!isBelowBreakpoint()}>
              <Typography.Title level={3}>Thêm đơn giao hàng</Typography.Title>
              <Divider />
              <OrderForm defaultValue={defaultValue} onSubmit={this.onSubmit} isEditMode={false} />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  configValues: state.configurationReducer.item,
});

const mapDispatchToProps = (dispatch) => ({
  insertOrder: (payload, meta) => dispatch(orderActions.insertOrder(payload, meta)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddVehicle));
