/* eslint-disable prettier/prettier */
import {
  AppstoreOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  ApiOutlined,
  ShopOutlined,
  SettingOutlined,
  CarOutlined,
  ShoppingCartOutlined,
  UsergroupDeleteOutlined,
  ShareAltOutlined,
  ApartmentOutlined,
  UnorderedListOutlined,
  ImportOutlined,
  ExportOutlined,
  WarningOutlined,
  // SnippetsOutlined,
  TableOutlined,
  GoldOutlined,
  // DeploymentUnitOutlined,
  KeyOutlined,
} from '@ant-design/icons';

import ROUTER from './router';

export default () => ({
  // SUPER ADMIN
  'super-admin': [
    {
      key: 'company',
      to: ROUTER.COMPANY.INDEX,
      icon: ShopOutlined,
      content: 'Công ty',
    },
    {
      key: 'account',
      to: ROUTER.ACCOUNT.INDEX,
      icon: UsergroupAddOutlined,
      content: 'Người dùng',
    },
    {
      key: 'vehicle-part',
      to: ROUTER.VEHICLE_PART.INDEX,
      icon: ApartmentOutlined,
      content: 'Linh kiện',
    },
    {
      key: 'api',
      to: ROUTER.MANAGER.INDEX,
      icon: ApiOutlined,
      content: 'Phân quyền',
    },
  ],

  // COMPANY'S ADMIN
  admin: [
    {
      key: 'dashboard',
      to: ROUTER.DASHBOARD.ORDERS,
      icon: UsergroupAddOutlined,
      content: 'Dashboard',
      children: [
        {
          key: 'orders',
          to: ROUTER.DASHBOARD.ORDERS,
          icon: '',
          content: 'Thống kê đơn hàng',
        },
        {
          key: 'driving-time',
          to: ROUTER.DASHBOARD.INDEX,
          icon: '',
          content: 'Thống kê thời gian lái xe',
        },
        {
          key: 'nodes',
          to: ROUTER.DASHBOARD.NODES,
          icon: '',
          content: 'Thống kê điểm giao',
        },
        {
          key: 'real-distance',
          to: ROUTER.DASHBOARD.REALDISTANCE,
          icon: '',
          content: 'Thống kê quãng đường',
        },
        {
          key: 'compare-estimate-and-real',
          to: ROUTER.DASHBOARD.CONPARE_ESTIMATE_AND_REAL,
          icon: '',
          content: 'So sánh giữa dự kiến & thực tế',
        },
      ],
    },
    {
      key: 'company',
      to: ROUTER.COMPANY.INDEX,
      icon: ShopOutlined,
      content: 'Chi nhánh',
    },
    {
      key: 'routing-p',
      to: ROUTER.SCHEDULE.INDEX,
      icon: ShareAltOutlined,
      content: 'Giao vận',
      children: [
        {
          key: 'schedule',
          to: ROUTER.SCHEDULE.INDEX,
          icon: '',
          content: 'Lịch giao vận',
        },
        {
          key: 'routing',
          to: ROUTER.ROUTING.INDEX,
          icon: '',
          content: 'Lập lịch',
        },
        {
          key: 'sample-route',
          to: ROUTER.SAMPLE_ROUTE.INDEX,
          icon: '',
          content: 'Lịch mẫu',
        },
      ],
    },
    {
      key: 'driver',
      to: ROUTER.DRIVER.INDEX,
      icon: UserOutlined,
      content: 'Lái xe',
    },
    {
      key: 'order',
      to: ROUTER.ORDER.INDEX,
      icon: ShoppingCartOutlined,
      content: 'Đơn giao hàng',
    },
    {
      key: 'customer',
      to: ROUTER.CUSTOMER.INDEX,
      icon: UsergroupDeleteOutlined,
      content: 'Khách hàng',
    },
    {
      key: 'vehicle',
      to: ROUTER.VEHICLE.INDEX,
      icon: CarOutlined,
      content: 'Phương tiện',
    },
    {
      key: 'feature',
      to: ROUTER.FEATURE.INDEX,
      icon: AppstoreOutlined,
      content: 'Đặc tính',
    },
    {
      key: 'warehouses',
      // to: ROUTER.INVENTORY.WAREHOUSE,
      icon: ShopOutlined,
      content: 'Kho',
      children: [
        {
          key: 'storage',
          to: ROUTER.INVENTORY.STORAGE,
          icon: UnorderedListOutlined,
          content: 'Danh sách kho',
        },
        {
          key: 'ware-house',
          to: ROUTER.INVENTORY.WAREHOUSES,
          icon: ShopOutlined,
          content: 'Tồn kho',
        },
        {
          key: 'category',
          to: ROUTER.INVENTORY.CATEGORIES,
          icon: GoldOutlined,
          content: 'Nhóm hàng',
        },
        {
          key: 'product',
          to: ROUTER.INVENTORY.PRODUCTS,
          icon: AppstoreOutlined,
          content: 'Sản phẩm',
        },
        // {
        //   key: 'unit',
        //   to: ROUTER.INVENTORY.UNITS,
        //   icon: DeploymentUnitOutlined,
        //   content: 'Đơn vị',
        // },
        {
          key: 'receiving-voucher',
          to: ROUTER.INVENTORY.RECEIVING_VOUCHER,
          icon: ImportOutlined,
          content: 'Phiếu nhập',
        },
        {
          key: 'delivery-voucher',
          to: ROUTER.INVENTORY.DELIVERY_VOUCHER,
          icon: ExportOutlined,
          content: 'Phiếu xuất',
        },
        // {
        //   key: 'transfer-voucher',
        //   to: ROUTER.INVENTORY.TRANSFER_VOUCHER,
        //   icon: DeliveredProcedureOutlined,
        //   content: 'Chuyển',
        // },
        {
          key: 'cancellation-voucher',
          to: ROUTER.INVENTORY.CANCELLATION_VOUCHER,
          icon: WarningOutlined,
          content: 'Phiếu huỷ',
        },
        // {
        //   key: 'receiving-offer',
        //   to: ROUTER.INVENTORY.RECEIVING_OFFER,
        //   icon: SnippetsOutlined,
        //   content: 'Phiếu yêu cầu',
        // },
        {
          key: 'temp',
          to: ROUTER.INVENTORY.INVENTORY_RECORD,
          icon: TableOutlined,
          content: 'Phiếu kiểm kê',
        },
      ],
    },
    {
      key: 'account',
      to: ROUTER.ACCOUNT.INDEX,
      icon: UsergroupAddOutlined,
      content: 'Người dùng',
    },
    {
      key: 'configuration',
      to: ROUTER.CONFIGURATION.INDEX,
      icon: SettingOutlined,
      content: 'Cấu hình',
      children: [
        {
          key: 'configuration',
          to: ROUTER.CONFIGURATION.INDEX,
          icon: SettingOutlined,
          content: 'Cấu hình',
        },
        {
          key: 'secret-key',
          to: ROUTER.CONFIGURATION.KEY,
          icon: KeyOutlined,
          content: 'Mã',
        },
      ],
    },
  ],
  manager: [
    {
      key: 'dashboard',
      to: ROUTER.DASHBOARD.ORDERS,
      icon: UsergroupAddOutlined,
      content: 'Dashboard',
      children: [
        {
          key: 'orders',
          to: ROUTER.DASHBOARD.ORDERS,
          icon: '',
          content: 'Thống kê đơn hàng',
        },
        {
          key: 'driving-time',
          to: ROUTER.DASHBOARD.INDEX,
          icon: '',
          content: 'Thống kê thời gian lái xe',
        },
        {
          key: 'nodes',
          to: ROUTER.DASHBOARD.NODES,
          icon: '',
          content: 'Thống kê điểm giao',
        },
        {
          key: 'real-distance',
          to: ROUTER.DASHBOARD.REALDISTANCE,
          icon: '',
          content: 'Thống kê quãng đường',
        },
        {
          key: 'compare-estimate-and-real',
          to: ROUTER.DASHBOARD.CONPARE_ESTIMATE_AND_REAL,
          icon: '',
          content: 'So sánh giữa dự kiến & thực tế',
        },
      ],
    },
    {
      key: 'routing-p',
      to: ROUTER.SCHEDULE.INDEX,
      icon: ShareAltOutlined,
      content: 'Giao vận',
      children: [
        {
          key: 'schedule',
          to: ROUTER.SCHEDULE.INDEX,
          icon: '',
          content: 'Lịch giao vận',
        },
        {
          key: 'routing',
          to: ROUTER.ROUTING.INDEX,
          icon: '',
          content: 'Lập lịch',
        },
        {
          key: 'sample-route',
          to: ROUTER.SAMPLE_ROUTE.INDEX,
          icon: '',
          content: 'Lịch mẫu',
        },
      ],
    },
    {
      key: 'driver',
      to: ROUTER.DRIVER.INDEX,
      icon: UserOutlined,
      content: 'Lái xe',
    },
    {
      key: 'order',
      to: ROUTER.ORDER.INDEX,
      icon: ShoppingCartOutlined,
      content: 'Đơn giao hàng',
    },
    {
      key: 'customer',
      to: ROUTER.CUSTOMER.INDEX,
      icon: UsergroupDeleteOutlined,
      content: 'Khách hàng',
    },
    {
      key: 'vehicle',
      to: ROUTER.VEHICLE.INDEX,
      icon: CarOutlined,
      content: 'Phương tiện',
    },
    {
      key: 'feature',
      to: ROUTER.FEATURE.INDEX,
      icon: AppstoreOutlined,
      content: 'Đặc tính',
    },
    {
      key: 'warehouses',
      // to: ROUTER.INVENTORY.WAREHOUSE,
      icon: ShopOutlined,
      content: 'Kho',
      children: [
        {
          key: 'storage',
          to: ROUTER.INVENTORY.STORAGE,
          icon: UnorderedListOutlined,
          content: 'Danh sách kho',
        },
        {
          key: 'ware-house',
          to: ROUTER.INVENTORY.WAREHOUSES,
          icon: ShopOutlined,
          content: 'Tồn kho',
        },
        {
          key: 'category',
          to: ROUTER.INVENTORY.CATEGORIES,
          icon: GoldOutlined,
          content: 'Nhóm hàng',
        },
        {
          key: 'product',
          to: ROUTER.INVENTORY.PRODUCTS,
          icon: AppstoreOutlined,
          content: 'Sản phẩm',
        },
        // {
        //   key: 'unit',
        //   to: ROUTER.INVENTORY.UNITS,
        //   icon: DeploymentUnitOutlined,
        //   content: 'Đơn vị',
        // },
        {
          key: 'receiving-voucher',
          to: ROUTER.INVENTORY.RECEIVING_VOUCHER,
          icon: ImportOutlined,
          content: 'Phiếu nhập',
        },
        {
          key: 'delivery-voucher',
          to: ROUTER.INVENTORY.DELIVERY_VOUCHER,
          icon: ExportOutlined,
          content: 'Phiếu xuất',
        },
        // {
        //   key: 'transfer-voucher',
        //   to: ROUTER.INVENTORY.TRANSFER_VOUCHER,
        //   icon: DeliveredProcedureOutlined,
        //   content: 'Chuyển',
        // },
        {
          key: 'cancellation-voucher',
          to: ROUTER.INVENTORY.CANCELLATION_VOUCHER,
          icon: WarningOutlined,
          content: 'Phiếu huỷ',
        },
        // {
        //   key: 'receiving-offer',
        //   to: ROUTER.INVENTORY.RECEIVING_OFFER,
        //   icon: SnippetsOutlined,
        //   content: 'Phiếu yêu cầu',
        // },
        {
          key: 'temp',
          to: ROUTER.INVENTORY.INVENTORY_RECORD,
          icon: TableOutlined,
          content: 'Phiếu kiểm kê',
        },
      ],
    },
    {
      key: 'account',
      to: ROUTER.ACCOUNT.INDEX,
      icon: UsergroupAddOutlined,
      content: 'Người dùng',
    },
  ],
});
