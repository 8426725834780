import { CheckOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Switch,
  Table,
  notification,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import VNDInput from '../../../../../components/FormItems/VNDInput';
import { actions as companyActions } from '../../../../../redux/CompanyRedux';
import { actions as vehicleBillingActions } from '../../../../../redux/VehicleBillingRedux';
import { numberToVnd } from '../../../../../utils/numberFormatter';

const { Option } = Select;
const { Search } = Input;

const columns = (editVehicleBilling, allCompanies) => [
  {
    title: <b>Tên </b>,
    dataIndex: 'name',
    render: (value, record) => {
      // getVihicleBillingByCode(record.code);
      const edit = () => {
        editVehicleBilling(record.code, {
          code: record?.code,
          name: record?.name || '',
          billing_type: record?.billing_type || '',
          cost: record?.cost || 0,
          description: record?.description || '',
        });
      };
      return <a onClick={edit}>{value}</a>;
    },
  },
  {
    title: <b>Ảnh hóa đơn</b>,
    dataIndex: 'billing_image',
  },
  {
    title: <b>Loại thanh toán</b>,
    dataIndex: 'billing_type',
    render: (value) => {
      if (value === 'Maintain') return <div>Bảo dưỡng</div>;
      if (value === 'Fuel') return <div>Nhiên liệu</div>;
    },
  },
  {
    title: <b>Công ty</b>,
    dataIndex: 'company_code',
    render: (value) => {
      const filterConpany = allCompanies?.filter((data) => data.code === value);

      return <div>{filterConpany[0]?.name}</div>;
    },
  },
  {
    title: <b>Miêu tả</b>,
    dataIndex: 'description',
  },
  {
    title: <b>Giá tiền</b>,
    dataIndex: 'cost',
    render: (value) => numberToVnd(value),
  },
  {
    title: <b>Thanh toán</b>,
    dataIndex: 'is_paid',
    render: (value) => <Switch value={value} />,
  },
];

const VehicleBilling = ({
  getVehicleBilling,
  isFetching,
  listVehicleBilling,
  vehicleId,
  insertBilling,
  getVihicleBillingByCode,
  // currentVehicleBilling,
  editBilling,
  getAllCompanies,
  allCompanies,
}) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [billingImage, setBillingImage] = useState('');
  const [billingType, setBillingType] = useState('');
  const [cost, setCost] = useState(0);
  const [description, setDescription] = useState('');
  const [name, setName] = useState('');

  const [dataEdit, setDataEdit] = useState({});
  const [isEdit, setIsEdit] = useState(false);

  const [billingTypeSearch, setBillingTypeSearch] = useState('');
  const [search, setSearch] = useState('');

  useEffect(() => {
    getVehicleBilling({
      page,
      page_size: pageSize,
      search,
      billing_type: billingTypeSearch,
    });
  }, [page, pageSize, search, billingTypeSearch]);

  useEffect(() => {
    setBillingImage(dataEdit.billing_image);
    setBillingType(dataEdit.billing_type);
    setName(dataEdit.name);
    setDescription(dataEdit.description);
    setCost(dataEdit.cost);
  }, [dataEdit]);

  useEffect(() => {
    getAllCompanies();
  }, []);

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setBillingImage('');
    setBillingType('');
    setCost(0);
    setDescription(0);
    setName('');
    setDataEdit({});
    setIsEdit(false);
  };

  const handleChangePage = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
  };

  const handleChangeBillingType = (value) => {
    setBillingType(value);
  };

  const handleChangeName = (value) => {
    setName(value.target.value);
  };

  const handleChangeCost = (value) => {
    setCost(value);
  };

  const handleChangeDescription = (value) => {
    setDescription(value.target.value);
  };

  const handleChangeNameSearch = (value) => {
    setSearch(value);
  };

  const handleChangeBillingTypeSearch = (value) => {
    setBillingTypeSearch(value);
  };

  const payload = {
    billing_image: '',
    billing_type: billingType,
    cost,
    description,
    name,
    vehicle_code: vehicleId,
  };

  const insertVehicleBilling = () => {
    if (payload) {
      insertBilling(payload, {
        onSuccess: () => {
          notification.success({ message: 'Thêm thành công' });
          getVehicleBilling({
            page,
            page_size: pageSize,
          });
        },
        onError: () => {
          notification.error({ message: 'Thêm thất bại' });
        },
      });
    } else {
      notification.error({ message: 'Không có dữ liệu' });
    }

    handleCloseModal();
  };

  const editVehicleBilling = (code, payload) => {
    getVihicleBillingByCode(code);
    setIsEdit(true);
    setDataEdit(payload);
    handleOpenModal();
  };

  const handleEditVehicleBilling = () => {
    if (payload) {
      editBilling(
        {
          code: dataEdit.code,
          payload,
        },
        {
          onSuccess: () => {
            notification.success({ message: 'Sửa thành công' });
            getVehicleBilling({
              page,
              page_size: pageSize,
            });
          },
          onError: () => {
            notification.error({ message: 'Sửa thất bại' });
          },
        },
      );
    } else {
      notification.error({ message: 'Không có dữ liệu' });
    }
    handleCloseModal();
  };

  return (
    <>
      <Row gutter={15}>
        <Col span={12}>
          <Row gutter={15}>
            <Col span={12}>
              <Search
                // value={search}
                placeholder="Tìm kiếm theo mã hoặc tên"
                onSearch={handleChangeNameSearch}
                enterButton
                allowClear
              />
            </Col>
            <Col span={12}>
              <Select
                style={{ width: '100%' }}
                onChange={handleChangeBillingTypeSearch}
                value={billingTypeSearch}
                placeholder="Chọn trạng thái"
                allowClear>
                <Option value="Maintain">Bảo dưỡng</Option>
                <Option value="Fuel">Đổ nhiên liệu</Option>
              </Select>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Button
            style={{ float: 'right', marginBottom: '15px' }}
            shape="round"
            type="primary"
            onClick={handleOpenModal}
            icon={<PlusOutlined />}>
            Thêm chi phí sửa chữa
          </Button>
        </Col>
        <Col span={24}>
          <Table
            loading={isFetching}
            dataSource={listVehicleBilling ? listVehicleBilling : []}
            columns={columns(editVehicleBilling, allCompanies)}
            pagination={{
              current: page,
              page_size: pageSize,
              showSizeChanger: true,
              onShowSizeChange: handleChangePage,
              showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total}`,
            }}
            size="small"
            scroll={{ x: 'max-content' }}
          />
        </Col>
        <Col span={24}>
          <Modal
            title="Thêm hóa đơn thanh toán"
            // onOk={handleCloseModal}
            onCancel={handleCloseModal}
            visible={openModal}
            open={openModal}
            footer={<></>}
            destroyOnClose>
            <Form
              layout="vertical"
              onFinish={isEdit ? handleEditVehicleBilling : insertVehicleBilling}
              initialValues={{
                name: dataEdit.code ? dataEdit.name : '',
                billingType: dataEdit.code ? dataEdit.billing_type : '',
                cost: dataEdit.code ? dataEdit.cost : 0,
                description: dataEdit.code ? dataEdit.description : '',
              }}>
              <Row gutter={15}>
                <Col span={12}>
                  <Form.Item
                    rules={[{ required: true, message: `Hãy nhập tên hóa đơn` }]}
                    name="name"
                    label="Tên chi phiếu">
                    <Input
                      value={name}
                      style={{ width: '100%' }}
                      placeholder="Nhập tên chi phiếu ..."
                      onChange={handleChangeName}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="billingType"
                    label="Loại trạng thái sửa chữa"
                    rules={[{ required: true, message: `Hãy nhập trạng thái hóa đơn` }]}>
                    <Select
                      style={{ width: '100%' }}
                      onChange={handleChangeBillingType}
                      placeholder="Chọn trạng thái">
                      <Option value="Maintain">Bảo dưỡng</Option>
                      <Option value="Fuel">Đổ nhiên liệu</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="cost" label="Giá tiền">
                    <VNDInput value={cost} style={{ width: '230px' }} onChange={handleChangeCost} />
                  </Form.Item>
                </Col>
                {/* <Col span={12}>
                  <Form.Item required name="maintain_type" label="Loại trạng thái sửa chữa">
                    <ImageUploadFormItem
                      width="200px"
                      height="200px"
                      outputWidth={200}
                      outputHeight={200}
                      // onChange={(value) => this.coverImageUploadFormItemOnChange(value)}
                    />
                  </Form.Item>
                </Col> */}
                <Col span={24}>
                  <Form.Item name="description" label="Loại trạng thái sửa chữa">
                    <TextArea
                      rows={4}
                      style={{ width: '100%' }}
                      value={description}
                      onChange={handleChangeDescription}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Button
                type="primary"
                htmlType="submit"
                shape="round"
                style={{
                  width: '20%',
                  marginLeft: '180px',
                  marginTop: '10px',
                }}>
                <CheckOutlined />
                Lưu
              </Button>
            </Form>
          </Modal>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  listVehicleBilling: state?.vehicleBillingReducer?.listVehicleBilling || [],
  isFetching: state?.vehicleBillingReducer?.isFetching,
  meta: state?.vehicleBillingReducer?.meta,
  currentVehicleBilling: state?.vehicleBillingReducer?.currentVehicleBilling,
  allCompanies: state?.companyReducer?.allItems,
});

const mapDispatchToProps = (dispatch) => ({
  getVehicleBilling: (params) => dispatch(vehicleBillingActions.getVehicleBilling(params)),
  insertBilling: (payload, meta) =>
    dispatch(vehicleBillingActions.insertVehicleBilling({ payload, meta })),
  getVihicleBillingByCode: (code) => dispatch(vehicleBillingActions.getVehicleBillingByCode(code)),
  editBilling: (payload, meta) =>
    dispatch(vehicleBillingActions.updateVehicleBilling({ payload, meta })),
  getAllCompanies: () => dispatch(companyActions.getAllCompanies()),
});

export default connect(mapStateToProps, mapDispatchToProps)(VehicleBilling);
