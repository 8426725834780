import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
// import produce from 'immer';

import { actions as accountActions } from './AccountRedux';
import { actions as roleActions } from './RoleRedux';
// import { isCallingApi, isSuccessfulApiCall, isFailedApiCall } from './actionDedicate';
import { PREFIX, typesWithPrefix } from './config';
import { API_URLS } from '../config/api';
import { apiCallPromise } from '../utils/api';

const _types = typesWithPrefix(PREFIX.AUTH);
const types = {
  LOG_IN: _types('LOG_IN'),
  LOG_IN_TOKEN: _types('LOG_IN_TOKEN'),
  LOG_OUT: _types('LOG_OUT'),
  CHANGE_PASSWORD: _types('CHANGE_PASSWORD'),
};

const authActions = {
  login: (username, password, remember) => async (dispatch) => {
    const api = API_URLS.ACCOUNT.login({
      UserName: username,
      Password: password,
    });
    dispatch(authActions.loggingIn());
    const { response, error } = await apiCallPromise(api);
    if (!error && response.status === 200 && response.data.success) {
      dispatch(authActions.logInSuccess(response.data, remember));
    } else {
      dispatch(authActions.logInFailure(error));
    }
  },

  loginWithToken: () => async (dispatch) => {
    const api = API_URLS.ACCOUNT.loginWithToken();
    dispatch(authActions.loggingInWithToken());
    const { response, error } = await apiCallPromise(api);
    if (!error && response.status === 200 && response.data.success) {
      dispatch(authActions.logInSuccessWithToken(response.data));
    } else {
      dispatch(authActions.logInFailureWithToken(error));
    }
  },
  loginWithTokenIfNeed: () => (dispatch, getState) => {
    const { authReducer } = getState();
    const isFetching = authReducer.isFetching;
    const isAuthenticated = authReducer.isAuthenticated;
    const jwt = localStorage.getItem('jwt');
    if (!isFetching && !isAuthenticated && jwt) {
      dispatch(authActions.loginWithToken());
    }
  },

  changePassword: (payload, meta) => async (dispatch) => {
    const api = API_URLS.ACCOUNT.changePassword();
    dispatch(authActions.changingPassword());
    const { response, error } = await apiCallPromise({ ...api, payload });
    if (!error && response.status === 200 && response.data) {
      dispatch(authActions.changePasswordSuccess(response.data));
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
    } else {
      dispatch(authActions.changePasswordFailure());
      if (meta && meta.onError) {
        meta.onError(error);
      }
    }
  },

  logOut: () => async (dispatch) => {
    dispatch(accountActions.invalidateUsers());
    dispatch(roleActions.invalidateRoles());
    dispatch({
      type: types.LOG_OUT,
      meta: { prefix: [PREFIX.AUTH] },
    });
  },

  loggingIn: () => ({
    type: types.LOG_IN,
    meta: { prefix: [PREFIX.AUTH, PREFIX.API_CALLING] },
  }),
  logInSuccess: (payload, remember) => ({
    type: types.LOG_IN,
    payload,
    meta: { prefix: [PREFIX.AUTH, PREFIX.API_CALLED_SUCCESS], remember },
  }),
  logInFailure: (payload) => ({
    type: types.LOG_IN,
    payload,
    meta: { prefix: [PREFIX.AUTH, PREFIX.API_CALLED_FAILURE] },
  }),
  loggingInWithToken: () => ({
    type: types.LOG_IN_TOKEN,
    meta: { prefix: [PREFIX.AUTH, PREFIX.API_CALLING] },
  }),
  logInSuccessWithToken: (payload, location) => ({
    type: types.LOG_IN_TOKEN,
    payload: { ...payload, location },
    meta: { prefix: [PREFIX.AUTH, PREFIX.API_CALLED_SUCCESS] },
  }),
  logInFailureWithToken: (payload) => ({
    type: types.LOG_IN_TOKEN,
    payload,
    meta: { prefix: [PREFIX.AUTH, PREFIX.API_CALLED_FAILURE] },
  }),
  changingPassword: () => ({
    type: types.CHANGE_PASSWORD,
    meta: { prefix: [PREFIX.AUTH, PREFIX.API_CALLING] },
  }),
  changePasswordSuccess: (payload) => ({
    type: types.CHANGE_PASSWORD,
    payload: { ...payload },
    meta: { prefix: [PREFIX.AUTH, PREFIX.API_CALLED_SUCCESS] },
  }),
  changePasswordFailure: () => ({
    type: types.CHANGE_PASSWORD,
    meta: { prefix: [PREFIX.AUTH, PREFIX.API_CALLED_FAILURE] },
  }),
};

const thunkActions = {
  login: createAsyncThunk(
    types.LOG_IN,
    async ({ username, password, remember }, { rejectWithValue }) => {
      const api = API_URLS.ACCOUNT.login({
        UserName: username,
        Password: password,
      });
      const { response, error } = await apiCallPromise(api);
      if (error) rejectWithValue({ error });

      return { ...response.data, remember };
    },
  ),

  loginWithToken: createAsyncThunk(types.LOG_IN_TOKEN, async (_, { rejectWithValue }) => {
    const api = API_URLS.ACCOUNT.loginWithToken();
    const { response, error } = await apiCallPromise(api);
    if (error) rejectWithValue({ error });

    return { ...response.data };
  }),
  loginWithTokenIfNeed: () => (dispatch, getState) => {
    const { authReducer } = getState();
    const isFetching = authReducer.isFetching;
    const isAuthenticated = authReducer.isAuthenticated;
    const jwt = localStorage.getItem('jwt');
    if (!isFetching && !isAuthenticated && jwt) dispatch(thunkActions.loginWithToken());
  },

  logOut: createAsyncThunk(types.LOG_OUT, async (_, { dispatch }) => {
    dispatch(accountActions.invalidateUsers());
    dispatch(roleActions.invalidateRoles());
  }),

  changePassword: createAsyncThunk(types.CHANGE_PASSWORD, async (_, { dispatch }) => {
    const api = API_URLS.ACCOUNT.changePassword();
    dispatch({
      type: types.CHANGE_PASSWORD,
      meta: { prefix: [PREFIX.AUTH, PREFIX.API_CALLING] },
    });
    const { response, error } = await apiCallPromise({ ...api, payload });
    if (!error && response.status === 200 && response.data) {
      dispatch({
        type: types.CHANGE_PASSWORD,
        payload: { ...response.data },
        meta: { prefix: [PREFIX.AUTH, PREFIX.API_CALLED_SUCCESS] },
      });
      if (meta && meta.onSuccess) meta.onSuccess();
    } else {
      dispatch({
        type: types.CHANGE_PASSWORD,
        meta: { prefix: [PREFIX.AUTH, PREFIX.API_CALLED_FAILURE] },
      });
      if (meta && meta.onError) meta.onError(error);
    }
  }),
};

const initialState = {
  isFetching: false,
  didInvalidate: true,
  isAuthenticated: false,
  error: false,
  authUser: {},
  forwardLocation: {},
  token: '',
  role: 'super-admin',
};

const authSlice = createSlice({
  name: 'authReducer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(thunkActions.logOut.fulfilled, () => {
        localStorage.clear();
        window.location.reload();

        return initialState;
      })
      .addMatcher(
        isAnyOf(thunkActions.login.pending, thunkActions.loginWithToken.pending),
        (state) => {
          state.isFetching = true;
          state.isAuthenticated = false;
          state.error = false;
        },
      )
      .addMatcher(
        isAnyOf(thunkActions.login.fulfilled, thunkActions.loginWithToken.fulfilled),
        (state, { payload }) => {
          localStorage.setItem('jwt', payload?.token);
          state.isAuthenticated = true;
          state.isFetching = false;
          state.authUser = payload?.user ? payload?.user : payload?.data ? payload?.data : '';
          state.error = false;
          state.token = payload?.token;
        },
      )
      .addMatcher(
        isAnyOf(thunkActions.login.rejected, thunkActions.loginWithToken.rejected),
        (state) => {
          state.isAuthenticated = false;
          state.isFetching = false;
          state.error = true;
        },
      );
  },
});

export const actions = { ...authSlice.actions, ...thunkActions };
export const { reducer } = authSlice;

// export const reducer = (state = initialState, action) =>
//   produce(state, (draft) => {
//     switch (action.type) {
//       case types.LOG_IN:
//         if (isCallingApi(action)) {
//           draft.isFetching = true;
//           draft.isAuthenticated = false;
//           draft.error = false;
//         } else if (isSuccessfulApiCall(action)) {
//           const { token, user } = action.payload;
//           localStorage.setItem('jwt', token);
//           draft.isAuthenticated = true;
//           draft.isFetching = false;
//           draft.authUser = user ? user : '';
//           // draff.role = { Code: user.roleCode };
//           draft.error = false;
//           draft.token = token;
//           break;
//         } else if (isFailedApiCall(action)) {
//           draft.isAuthenticated = false;
//           draft.isFetching = false;
//           draft.error = true;
//         }
//         break;
//       case types.LOG_IN_TOKEN:
//         if (isCallingApi(action)) {
//           draft.isFetching = true;
//           draft.isAuthenticated = false;
//           draft.error = false;
//         } else if (isSuccessfulApiCall(action)) {
//           const { token, user } = action.payload;
//           localStorage.setItem('jwt', token);
//           draft.isAuthenticated = true;
//           draft.isFetching = false;
//           draft.authUser = user ? user : data;
//           // draff.role = { Code: user.roleCode };
//           draft.error = false;
//           draft.token = token;
//         } else if (isFailedApiCall(action)) {
//           draft.isAuthenticated = false;
//           draft.isFetching = false;
//           draft.error = true;
//         }
//         break;
//       case types.LOG_OUT:
//         localStorage.clear();
//         return initialState;
//       default:
//     }
//   });
