import { CheckOutlined, ReloadOutlined } from '@ant-design/icons';
import {
  Form,
  Row,
  Col,
  Input,
  Button,
  Typography,
  Select,
  InputNumber,
  DatePicker,
  TimePicker,
  Checkbox,
} from 'antd';
import 'antd/dist/antd.css';
// import lodash from 'lodash';
// import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import VehicleSearch from '../../../components/FormItems/VehicleSearch';
import { actions as tomtomActions } from '../../../redux/TomtomRedux';
import LocationForm from './LocationForm';

const { TextArea } = Input;
const FormItem = Form.Item;
const { Title } = Typography;
const { Option } = Select;
const layout = {
  labelCol: {
    span: 24,
  },
};
class DriverForm extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      formRef: this.formRef,
      currentDriverCode: props?.defaultValue?.code || '',
      visibleStartLocation: false,
      visibleEndLocation: false,
      start_location: props?.defaultValue?.start_location || '',
      finish_location: props?.defaultValue?.finish_location || '',
      isCopyLocation: true,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { defaultValue } = nextProps;
    const { currentDriverCode } = prevState;
    if (defaultValue?.code && defaultValue?.code !== currentDriverCode) {
      prevState.formRef.current.setFieldsValue(defaultValue);
      return {
        currentDriverCode: defaultValue?.code,
        start_location: defaultValue?.start_location || '',
        finish_location: defaultValue?.finish_location || '',
      };
    }
    return null;
  }

  componentWillUnmount() {
    this.props.clearTomtomStore();
  }

  onReset = () => {
    this.formRef.current.resetFields();
  };

  onFinish = (value) => {
    const { start_location, finish_location } = this.state;
    value = {
      ...value,
      start_location,
      finish_location,
      isCopyLocation: this.state.isCopyLocation,
    };
    this.props.onSubmit(value);
  };

  toggleModalStartLocation = () => {
    const { setTomtomSearchAddress, setTomtomSearchLatitude, setTomtomSearchLongitude } =
      this.props;
    const { start_location } = this.state;
    setTomtomSearchAddress(start_location.address);
    setTomtomSearchLatitude(start_location.latitude);
    setTomtomSearchLongitude(start_location.longitude);
    this.setState((prevState) => ({
      visibleStartLocation: !prevState.visibleStartLocation,
    }));
  };

  toggleModalEndLocation = () => {
    const { setTomtomSearchAddress, setTomtomSearchLatitude, setTomtomSearchLongitude } =
      this.props;
    const { finish_location } = this.state;
    setTomtomSearchAddress(finish_location.address);
    setTomtomSearchLatitude(finish_location.latitude);
    setTomtomSearchLongitude(finish_location.longitude);
    this.setState((prevState) => ({
      visibleEndLocation: !prevState.visibleEndLocation,
    }));
  };

  onSubmitStartLocation = (value) => {
    const { address, lat, lng } = value;
    this.setState({
      start_location: {
        address,
        latitude: lat,
        longitude: lng,
      },
    });
    this.state.formRef.current.setFieldsValue({
      start_locationAddress: value.address,
      start_latitude: value.lat,
      start_longitude: value.lng,
    });
    if (this.state.isCopyLocation) {
      this.state.formRef.current.setFieldsValue({
        finish_locationAddress: value.address,
        finish_latitude: value.lat,
        finish_longitude: value.lng,
      });
    }
  };

  validatetLongitude = (value, callback) => {
    if (value < 0 && value > 180) {
      callback('Kinh độ nằm trong khoảng 0* - 180*');
    } else {
      callback();
    }
  };

  validatetLatitude = (value, callback) => {
    if (value < 0 && value > 90) {
      callback('Kinh độ nằm trong khoảng 0* - 90*');
    } else {
      callback();
    }
  };

  onSubmitEndLocation = (value) => {
    const { address, lat, lng } = value;
    this.setState({
      finish_location: {
        address,
        latitude: lat,
        longitude: lng,
      },
    });
    this.state.formRef.current.setFieldsValue({
      finish_locationAddress: value.address,
      finish_latitude: value.lat,
      finish_longitude: value.lng,
    });
  };

  handleChangeEndPosition = () => {
    const { start_location, isCopyLocation } = this.state;
    this.state.formRef.current.setFieldsValue({
      finish_locationAddress: start_location.address,
      finish_latitude: start_location.latitude,
      finish_longitude: start_location.longitude,
    });
    this.setState(() => ({
      isCopyLocation: !isCopyLocation,
    }));
  };

  render() {
    const { isEditMode, defaultValue } = this.props;
    const { visibleStartLocation, visibleEndLocation } = this.state;
    const FormTitle = isEditMode ? 'Sửa thông tin lái xe' : 'Thêm lái xe';

    return (
      <div>
        <Title level={3} description="Thông báo">
          {FormTitle}
        </Title>
        <Form ref={this.formRef} initialValues={defaultValue} onFinish={this.onFinish} {...layout}>
          <Row gutter={15}>
            <Col span={6}>
              <FormItem
                label="Mã lái xe"
                name="code"
                // rules={[
                //   {
                //     required: true,
                //     message: 'Nhập mã lái xe!',
                //   },
                // ]}
              >
                <Input placeholder="Nhập mã lái xe ..." />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                label="Tên lái xe"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Nhập tên lái xe!',
                  },
                ]}>
                <Input placeholder="Nhập tên lái xe ..." />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                label="Số điện thoại"
                name="phone_number"
                rules={[
                  {
                    required: true,
                    message: 'Nhập số điện thoại!',
                  },
                ]}>
                <Input placeholder="Nhập số điện thoại..." style={{ width: '100%' }} />
              </FormItem>
            </Col>
            {/* <Col lg={12}>add image here</Col> */}
            <Col span={6}>
              <FormItem
                label="Giới tính"
                name="gender"
                rules={[
                  {
                    required: true,
                    message: 'Chọn giới tính!',
                  },
                ]}>
                <Select placeholder="Chọn giới tính...">
                  <Option value="Male">Nam</Option>
                  <Option value="Female">Nữ</Option>
                </Select>
              </FormItem>
            </Col>
          </Row>

          <Row gutter={15}>
            <Col span={8}>
              <FormItem label="Ngày sinh" name="date_of_birth">
                <DatePicker
                  format="DD/MM/YYYY"
                  placeholder="Chọn ngày sinh..."
                  style={{ width: '100%' }}
                />
              </FormItem>
            </Col>

            <Col span={8}>
              <FormItem label="Số CCCD" name="identity_card">
                <Input placeholder="Nhập số CCCD..." style={{ width: '100%' }} />
              </FormItem>
            </Col>

            <Col span={8}>
              <FormItem label="Email" name="email">
                <Input placeholder="Nhập email..." style={{ width: '100%' }} />
              </FormItem>
            </Col>
          </Row>

          <Row gutter={15}>
            <Col span={8}>
              <FormItem
                label="Thời gian làm việc"
                name="workTime"
                // rules={[
                //   {
                //     required: true,
                //     message: 'Chọn thời gian làm việc!',
                //   },
                // ]}
              >
                <TimePicker.RangePicker
                  format="HH:mm"
                  placeholder={['Bắt đầu', 'Kết thúc']}
                  style={{ width: '100%' }}
                />
              </FormItem>
            </Col>

            <Col span={8}>
              <FormItem
                label="Phương tiện"
                name="default_vehicle_code"
                // rules={[
                //   {
                //     required: true,
                //     message: 'Chọn phương tiện!',
                //   },
                // ]}
              >
                <VehicleSearch
                  selectedValue={defaultValue?.default_vehicle_code}
                  allowClear={false}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="Trạng thái"
                name="status"
                // rules={[
                //   {
                //     required: true,
                //     message: 'Chọn trạng thái!',
                //   },
                // ]}
              >
                <Select placeholder="Chọn trạng thái...">
                  <Option value="Ready">Sẵn sàng</Option>
                  <Option value="Driving">Đang trên đường</Option>
                  <Option value="Off">Nghỉ</Option>
                </Select>
              </FormItem>
            </Col>
          </Row>

          <Row
            gutter={15}
            style={{
              position: 'relative',
              border: '1px solid silver',
              padding: '10px',
              borderRadius: '10px',
              margin: '20px 0',
            }}>
            <div
              style={{
                margin: '10px 0',
                position: 'absolute',
                left: '60px',
                backgroundColor: 'white',
                zIndex: '100',
                padding: '0 5px',
                top: '-20px',
              }}>
              Địa điểm bắt đầu
            </div>
            <Col span={8}>
              <FormItem
                label="Địa điểm"
                name="start_locationAddress"
                rules={[
                  {
                    required: true,
                    message: 'Chọn hoặc nhập địa điểm',
                  },
                ]}>
                <Input placeholder="Nhập địa điểm..." />
              </FormItem>
              <Button
                onClick={this.toggleModalStartLocation}
                type="primary"
                style={{
                  position: 'absolute',
                  top: '40px',
                  right: '8px',
                }}>
                Chọn
              </Button>
            </Col>
            <Col span={8}>
              <FormItem
                label="Vĩ tuyến"
                name="start_latitude"
                rules={[
                  {
                    required: true,
                    message: 'Chọn hoặc nhập vĩ tuyến',
                  },
                  //   { validator: this.validatetLatitude },
                ]}>
                <Input placeholder="Nhập vĩ tuyến ..." />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="Kinh tuyến"
                name="start_longitude"
                rules={[
                  {
                    required: true,
                    message: 'Chọn hoặc nhập kinh tuyến',
                  },
                  //   { validator: this.validatetLongitude },
                ]}>
                <Input placeholder="Nhập kinh tuyến..." />
              </FormItem>
            </Col>
          </Row>

          <Row
            gutter={15}
            style={{
              position: 'relative',
              border: '1px solid silver',
              padding: '10px',
              borderRadius: '10px',
              margin: '20px 0',
            }}>
            <div
              style={{
                margin: '10px 0',
                position: 'absolute',
                left: '60px',
                backgroundColor: 'white',
                zIndex: '100',
                padding: '0 5px',
                top: '-20px',
              }}>
              Địa điểm kết thúc
            </div>
            <Col span={24}>
              <Checkbox
                onChange={() => {
                  this.handleChangeEndPosition();
                }}
                checked={this.state.isCopyLocation}>
                Trùng điểm bắt đầu
              </Checkbox>
            </Col>
            <Col span={8}>
              <FormItem
                label="Địa điểm"
                name={
                  this.state.isCopyLocation ? 'start_locationAddress' : 'finish_locationAddress'
                }
                rules={[
                  {
                    required: true,
                    message: 'Chọn hoặc nhập địa điểm',
                  },
                ]}>
                <Input placeholder="Nhập địa điểm..." disabled={this.state.isCopyLocation} />
              </FormItem>
              <Button
                onClick={this.toggleModalEndLocation}
                type="primary"
                disabled={this.state.isCopyLocation}
                style={{
                  position: 'absolute',
                  top: '40px',
                  right: '8px',
                }}>
                Chọn
              </Button>
            </Col>
            <Col span={8}>
              <FormItem
                label="vĩ tuyến"
                name={this.state.isCopyLocation ? 'start_latitude' : 'finish_latitude'}
                rules={[
                  {
                    required: true,
                    message: 'Chọn hoặc nhập vĩ  tuyến',
                  },
                  //   { validator: this.validatetLatitude },
                ]}>
                <Input placeholder="Nhập địa điểm..." disabled={this.state.isCopyLocation} />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="Kinh tuyến"
                name={this.state.isCopyLocation ? 'start_longitude' : 'finish_longitude'}
                rules={[
                  {
                    required: true,
                    message: 'Chọn hoặc nhập kinh tuyến',
                  },
                  //   { validator: this.validatetLongitude },
                ]}>
                <Input placeholder="Nhập địa điểm..." disabled={this.state.isCopyLocation} />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={15}>
            <Col span={8}>
              <FormItem label="Tốc độ" name="speed" initialValue={1}>
                <InputNumber min={0} style={{ width: '100%' }} />
              </FormItem>
            </Col>
          </Row>

          {/* <Row gutter={15}>
            <Col span={8}>
              <FormItem label="Lương theo giờ" name="cost_per_hour" initialValue={0}>
                <InputNumber min={0} style={{ width: '100%' }} />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="Lương theo quãng đường (km)" name="cost_per_km" initialValue={0}>
                <InputNumber min={0} style={{ width: '100%' }} />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="Lương thưởng ngoài giờ"
                name="cost_per_hour_overtime"
                initialValue={0}>
                <InputNumber min={0} style={{ width: '100%' }} />
              </FormItem>
            </Col>
          </Row> */}

          <Col span={24}>
            <FormItem label="Ghi chú" name="note">
              <TextArea placeholder="Thêm ghi chú..." />
            </FormItem>
          </Col>

          <LocationForm
            onSubmitLocation={this.onSubmitStartLocation}
            visibleLocation={visibleStartLocation}
            location={defaultValue?.start_location}
            onToggleModalLocation={this.toggleModalStartLocation}
            isEditMode
          />

          <LocationForm
            onSubmitLocation={this.onSubmitEndLocation}
            visibleLocation={visibleEndLocation}
            location={defaultValue?.finish_location}
            onToggleModalLocation={this.toggleModalEndLocation}
            isEditMode
          />

          <FormItem style={{ float: 'right', marginTop: '10px' }}>
            <Button
              type="primary"
              htmlType="submit"
              shape="round"
              icon={<CheckOutlined />}
              style={{ marginRight: 8 }}>
              Lưu
            </Button>
            <Button htmlType="reset" onClick={this.onReset} shape="round" icon={<ReloadOutlined />}>
              Nhập lại
            </Button>
          </FormItem>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setTomtomSearchAddress: (payload) => dispatch(tomtomActions.setMapAddress(payload)),
  setTomtomSearchLatitude: (payload) => dispatch(tomtomActions.setMapLatitude(payload)),
  setTomtomSearchLongitude: (payload) => dispatch(tomtomActions.setMapLongitude(payload)),
  clearTomtomStore: () => dispatch(tomtomActions.clearStore()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DriverForm);
