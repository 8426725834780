import { Button, Col, Form, Input, Row, notification } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { API_URLS } from '../../config/api';
import { actions as authActions } from '../../redux/AuthRedux';
import { actions as secretKeyActions } from '../../redux/SecretKeyRedux';
import { apiCallPromise } from '../../utils/api';

const { login } = authActions;
const { getSecretKeyByCompany, createSecretKey } = secretKeyActions;
const SECRET_KEY = '**************';
const SecretKey = ({
  userName,
  company,
  getSecretKeyByCompany,
  dataKey,
  login,
  createSecretKey,
}) => {
  const [SecretKey, setSecretKey] = useState(SECRET_KEY);
  const [isLogin, setIsLogin] = useState(false);
  const [openModal, setopenModal] = useState(false);
  const [isKey, setIsKey] = useState(false);

  useEffect(() => {
    getSecretKeyByCompany(company);
  }, [company]);

  useEffect(() => {
    if (isLogin) {
      setSecretKey(dataKey);
      navigator.clipboard.writeText(dataKey);
    } else {
      setSecretKey(SECRET_KEY);
    }
  }, [isLogin, dataKey]);

  const handleCopy = () => {
    if (SecretKey !== SECRET_KEY) {
      notification.success({ message: 'Sao chép mã' });
      navigator.clipboard.writeText(SecretKey);
    }
  };

  const handleOpenModal = () => {
    setopenModal(!openModal);
  };

  const handleGetKey = () => {
    createSecretKey({
      company_code: company,
    });
  };

  const onFinish = async (value) => {
    const api = API_URLS.ACCOUNT.login({
      UserName: userName,
      Password: value.password,
    });
    const { response, error } = await apiCallPromise(api);

    if (!error && (response.status === 200 || response.status === 201)) {
      notification.success({ message: 'Sinh mã thành công' });
      setIsLogin(true);
      if (isKey) {
        handleGetKey();
      }
      handleOpenModal();
      setIsKey(false);

      setTimeout(() => {
        setSecretKey(SECRET_KEY);
        setIsLogin(false);
      }, 5 * 1000 * 60);
    }
  };

  const handleCopyCompanyCode = () => {
    navigator.clipboard.writeText(company);
  };
  return (
    <>
      <Row>
        <Col span={24}>
          <Row>
            <h2 style={{ float: 'left', width: '80%' }}>SECRET KEY</h2>
          </Row>
        </Col>
      </Row>
      <Button
        type="primary"
        shape="round"
        style={{ marginBottom: '20px' }}
        onClick={() => {
          handleOpenModal();
          setIsKey(true);
        }}>
        {!dataKey ? 'Sinh mã' : 'Sinh lại mã'}
      </Button>

      <Row>
        <Col span={12}>
          <Input value={SecretKey} disabled={!isLogin} />
        </Col>
        <Col span={12}>
          <Button
            onClick={() => {
              if (!isLogin) {
                handleOpenModal();
              }
              handleCopy();
            }}>
            Sao chép mã
          </Button>
        </Col>
      </Row>
      <Row style={{ marginTop: '20px' }}>
        <Col span={24}>
          <h2 style={{ float: 'left', width: '80%' }}>Mã công ty </h2>
        </Col>
        <Col>
          <Input value={company} style={{ width: '220px' }} />
        </Col>
        <Col>
          <Button onClick={handleCopyCompanyCode}>Sao chép mã công ty</Button>
        </Col>
      </Row>
      <Modal
        title="Xác nhận tài khoản"
        visible={openModal}
        onOk={handleOpenModal}
        onCancel={handleOpenModal}
        footer={<></>}>
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          //   onFinishFailed={onFinishFailed}
          autoComplete="off">
          <Form.Item label="Tài khoản" name="username">
            <strong>{userName}</strong>
          </Form.Item>

          <Form.Item
            label="Mật khẩu"
            name="password"
            rules={[
              {
                required: true,
                message: 'Nhập mật khẩu!',
              },
            ]}>
            <Input.Password />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  userName: state?.authReducer?.authUser?.username,
  company: state?.authReducer.authUser.company_code,
  dataKey: state?.secretKeyReducer?.data,
});

const mapDispatchToProps = (dispatch) => ({
  login: (username, password, remember) => dispatch(login({ username, password, remember })),
  getSecretKeyByCompany: (code) => dispatch(getSecretKeyByCompany(code)),
  createSecretKey: (payload, meta) => dispatch(createSecretKey({ payload, meta })),
});

export default connect(mapStateToProps, mapDispatchToProps)(SecretKey);
