import { CheckOutlined } from '@ant-design/icons';
import { Form, Input, Modal, notification, Button, Row, Col } from 'antd';
import React from 'react';

// const layout = {
//   labelCol: { span: 24 },
// };
class RoleForm extends React.Component {
  state = {};

  onFinish = (values) => {
    const { code, name, description } = values;
    this.props.onCreateRole({ code, name, description });
  };

  onUpdateRole = (values) => {
    const { code, name, description } = values;
    this.props.onEditRole(this.props.editRole.id, { code, name, description });
  };

  onFinishFailed = () => {
    notification.warning({ message: 'Không được để trống !!!' });
  };

  render() {
    const { visibleRole, onToggleModalRole, editRole } = this.props;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
      },
    };
    return (
      <Modal
        title={editRole && editRole.id ? 'Cập nhập vai trò' : 'Thêm vai trò mới'}
        visible={visibleRole}
        onOk={editRole && editRole.id ? this.onUpdateRole : this.onFinish}
        onCancel={onToggleModalRole}
        destroyOnClose
        footer={<></>}>
        <Row>
          <Col span={22} offset={1}>
            <Form
              {...formItemLayout}
              onFinish={editRole && editRole.id ? this.onUpdateRole : this.onFinish}
              onFinishFailed={this.onFinishFailed}
              initialValues={{
                code: editRole && editRole.id ? editRole.payload.code : '',
                name: editRole && editRole.id ? editRole.payload.name : '',
                // method: editRole && editRole.id ? editRole.method : '',
                description: editRole && editRole.id ? editRole.payload.description : '',
              }}>
              <Form.Item
                name="code"
                label="Mã"
                rules={[{ required: true, message: 'Nhập mã vai trò' }]}>
                <Input placeholder="mã" />
              </Form.Item>

              <Form.Item
                name="name"
                label="Tên"
                rules={[{ required: true, message: 'Nhập tên vai trò' }]}>
                <Input placeholder="Nhập tên vai trò" />
              </Form.Item>
              <Form.Item name="description" label="Ghi chú">
                <Input placeholder="Nhập ghi chú" />
              </Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                shape="round"
                style={{
                  width: '20%',
                  marginLeft: '180px',
                  marginTop: '10px',
                }}>
                <CheckOutlined />
                Lưu
              </Button>
            </Form>
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default RoleForm;
