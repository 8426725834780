import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Input, Select, Button, Table, notification } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';

import { isBelowBreakpoint } from '../../../utils/window';

const { TextArea } = Input;
const { Option } = Select;

const columns = (
  onChangeLotNumber,
  onChangeQuantity,
  onRemoveItem,
  onAddItem,
  products,
  focusRowIndex,
  storageCode,
  checkQuantity,
) => [
  {
    title: () => (
      <Select
        placeholder="Chọn mặt hàng"
        style={{ width: '250px' }}
        showSearch
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onSelect={(values) => onAddItem(values)}
        showAction={['click', 'focus']}>
        {products !== null &&
          products?.length !== undefined &&
          products?.map((item, index) => (
            <Option key={item.product_code} value={item.product_code}>
              {item.product_name}
            </Option>
          ))}
      </Select>
    ),
    width: '25%',
    key: 'name',
    dataIndex: 'name',
    render: (value, record) => {
      const obj = {
        children: value,
        props: {
          rowSpan: record.rowSpan !== undefined ? record.rowSpan : 1,
        },
      };

      return obj;
    },
  },
  {
    title: <b>Ngày sản xuất / hết hạn</b>,
    key: 'lot_number',
    width: '20%',
    render: (value, record, index) =>
      record.summary_lot_numbers?.length && record.summary_lot_numbers?.length > 1 ? (
        <>
          <Select
            placeholder="Chọn ngày"
            style={{ width: '250px' }}
            showSearch
            filterOption={false}
            disabled={record.selectedLotNumber?.quantity === 0}
            defaultValue={`${moment(record.summary_lot_numbers[0].manufacturing_date).format(
              'DD-MM-YYYY',
            )} /
              ${moment(record.summary_lot_numbers[0].expiry_date).format('DD-MM-YYYY')}`}
            onChange={(values) => onChangeLotNumber(values, record.code, record.indexKey)}
            showAction={['click', 'focus']}>
            {record.summary_lot_numbers !== null &&
              record.summary_lot_numbers?.length !== undefined &&
              record.summary_lot_numbers.map((item, index) => (
                <Option key={item.lot_number} value={JSON.stringify(item)}>
                  {`${moment(item.manufacturing_date).format('DD-MM-YYYY')} /
              ${moment(item.expiry_date).format('DD-MM-YYYY')}`}
                </Option>
              ))}
          </Select>
          {record.plusIcon === false ? null : (
            <Button
              tabIndex="-1"
              size="small"
              type="primary"
              shape="circle"
              icon={<PlusOutlined />}
              style={{ marginLeft: 5 }}
              onClick={() => onAddItem(record.code, true)}
            />
          )}
        </>
      ) : record.summary_lot_numbers?.length === 1 ? (
        record.summary_lot_numbers[0].expiry_date &&
        record.summary_lot_numbers[0].manufacturing_date ? (
          <p>
            {`${moment(record.summary_lot_numbers[0].manufacturing_date).format('DD-MM-YYYY')} /
              ${moment(record.summary_lot_numbers[0].expiry_date).format('DD-MM-YYYY')}`}
          </p>
        ) : (
          'N/A'
        )
      ) : (
        'N/A'
      ),
  },
  {
    title: <b>Số lượng</b>,
    key: 'quantity',
    width: '15%',
    render: (value, record, index) => (
      <Input
        defaultValue={record.selectedLotNumber?.quantity > 0 ? 1 : 0}
        autoFocus={index === focusRowIndex}
        type="number"
        min={record.selectedLotNumber?.quantity > 0 ? 1 : 0}
        max={record.selectedLotNumber?.quantity}
        key="quantity"
        value={record.quantity}
        disabled={record.selectedLotNumber?.quantity === 0}
        addonAfter={<div>{record.unit.secondary_unit}</div>}
        onChange={(e) =>
          checkQuantity(e.target.value, record.expirationCode, record.selectedLotNumber?.quantity)
        }
      />
    ),
  },
  {
    title: <b>Còn trong kho</b>,
    key: '',
    width: '15%',
    align: 'center',
    render: (value, record) => record.selectedLotNumber?.quantity || 0,
  },
  {
    title: <b>Thao tác</b>,
    width: '10%',
    align: 'center',
    render: (value, record, index) => (
      <Button
        tabIndex="-1"
        shape="circle"
        type="danger"
        onClick={() => onRemoveItem(record.code, index)}
        icon={<DeleteOutlined />}
      />
    ),
  },
];
class ItemList extends Component {
  constructor(props) {
    super(props);
    this.textSelect = React.createRef();
    this.state = {
      focusRowIndex: 0,
      listProducts: [],
      products: [],
      originProducts: [],
      stationCode: '',
      storageCode: '',
      validateQuantity: true,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { products, stationCode, storageCode } = nextProps;
    if (stationCode !== prevState.stationCode || storageCode !== prevState.storageCode)
      return { listProducts: [], stationCode, storageCode };

    if (nextProps.products !== prevState.originProducts)
      return {
        products,
        originProducts: products,
      };

    return null;
  }

  setRowSpan = (listProducts, productCode) => {
    const duplicatedProductsIndex = [];
    listProducts.forEach((item, index) => {
      if (item.code === productCode) {
        duplicatedProductsIndex.push(index);
      }
    });
    if (duplicatedProductsIndex.length === 1) {
      listProducts[duplicatedProductsIndex[0]].rowSpan = 1;
      listProducts[duplicatedProductsIndex[0]].plusIcon = true;
    } else if (duplicatedProductsIndex.length > 1) {
      duplicatedProductsIndex.forEach((pIndex) => {
        listProducts[pIndex].rowSpan = 0;
        listProducts[pIndex].plusIcon = false;
      });
      listProducts[duplicatedProductsIndex[0]].rowSpan = duplicatedProductsIndex.length;
      listProducts[duplicatedProductsIndex[duplicatedProductsIndex.length - 1]].plusIcon = true;

      return duplicatedProductsIndex[duplicatedProductsIndex.length - 1];
    }

    return 0;
  };

  onGetUniqueKey = (listProducts, productCode) => {
    const duplicatedProductsIndexItem = [];
    listProducts.forEach((item, index) => {
      if (item.code === productCode) duplicatedProductsIndexItem.push(index);
    });
    if (duplicatedProductsIndexItem.length > 0)
      return `${productCode}-${duplicatedProductsIndexItem.length}`;
    else return `${productCode}-0`;
  };

  onGetNewIndex = (listProducts, productCode) => {
    let maxIndex = -1;
    listProducts.forEach((item, index) => {
      if (item.code === productCode) {
        if (item.indexKey !== undefined && item.indexKey > maxIndex) {
          maxIndex = item.indexKey;
        }
      }
    });

    return maxIndex + 1;
  };

  onRemoveItem = (code, productIndex) => {
    const { listProducts } = this.state;
    const removeProducts = listProducts.filter(
      (item, index) => item.code !== code || index !== productIndex,
    );
    this.setRowSpan(removeProducts, code);
    this.setState({ listProducts: removeProducts });
  };

  checkQuantity = (value, id, max) => {
    if (Number(value) > 0) this.onChangeQuantity(value, id);
    if (Number(value) > max) {
      notification.error({ message: `Vượt quá số lượng cho phép ` });
      this.onChangeQuantity(max, id);
    } else this.onChangeQuantity(0, id);
  };

  onAddItem = (productCode, grouped = false) => {
    const { listProducts, originProducts } = this.state;
    let isExist = -1;
    if (listProducts.length) {
      const index = listProducts
        .slice() // to preserve the original array
        .reverse()
        .findIndex((item) => item.code === productCode);
      // isExist is the last index of item that has the productCode
      isExist = index >= 0 ? listProducts.length - 1 - index : index;
    }
    const product = originProducts.find((item) => item.product_code === productCode);
    if (product) {
      let newListProducts = listProducts;
      let focusRowIndex = listProducts.length;
      const newIndex = this.onGetNewIndex(listProducts, productCode);
      const newProduct = {
        code: product.product_code,
        secondary_code: product.product_secondary_code,
        indexKey: newIndex,
        expirationCode: `${productCode}-${newIndex}`,
        name: product.product_name,
        unit: product.unit,
        summary_lot_numbers: product.summary_lot_numbers,
        selectedLotNumber: product.summary_lot_numbers[0],
        // total_quantity: product.total_quantity,
        quantity: product.summary_lot_numbers[0]?.quantity > 0 ? 1 : 0,
        input_price: 0,
      };
      if (isExist < 0) {
        newListProducts = newListProducts.concat(newProduct);
        focusRowIndex = newListProducts.length - 1;
      } else {
        if (grouped) {
          newListProducts.splice(isExist + 1, 0, newProduct);
          focusRowIndex = this.setRowSpan(newListProducts, productCode);
        } else notification.error({ message: 'Sản phẩm đã có trong danh sách!' });
      }

      this.setState({ listProducts: newListProducts, focusRowIndex });
    }
  };

  onChangeQuantity = (value, code) => {
    if (value <= 0) return;

    const { listProducts } = this.state;
    const newProducts = listProducts.map((item, index) => {
      if (item.expirationCode === code)
        return {
          ...item,
          quantity: Number(value),
          totalPrice: item?.price?.price * Number(value),
        };

      return item;
    });

    this.setState({ listProducts: newProducts });
  };

  onChangeLotNumber = (values, code, key) => {
    const { listProducts } = this.state;
    const newExpirationProducts = listProducts.map((item) => {
      if (item.code === code && item.indexKey === key) item.selectedLotNumber = JSON.parse(values);

      return item;
    });
    this.setState({ listProducts: newExpirationProducts });
  };

  combineData = () => {
    const { listProducts } = this.state;
    const combinedList = [];
    listProducts.forEach((product) => {
      const existProduct = combinedList.find(
        (item) => item.selectedLotNumber.lot_number === product.selectedLotNumber.lot_number,
      );
      if (existProduct) existProduct.quantity += product.quantity;
      else combinedList.push(product);
    });

    return combinedList;
  };

  onFinish = () => {
    const combinedData = this.combineData();
    this.props.handleSubmit(combinedData);
    this.setState({ listProducts: combinedData });
  };

  render() {
    const { storageCode, products, note } = this.props;
    const { listProducts, focusRowIndex } = this.state;
    return isBelowBreakpoint() ? (
      <Table
        key={`${listProducts.length}`}
        size="small"
        scroll={{ x: 'max-content', y: 400 }}
        dataSource={listProducts}
        columns={columns(
          this.onChangeLotNumber,
          this.onChangeQuantity,
          this.onRemoveItem,
          this.onAddItem,
          products,
          focusRowIndex,
          storageCode,
          this.checkQuantity,
        )}
        rowKey={(record) => record.expirationCode}
        rowClassName={(r, idx) => (idx % 2 ? 'whitesmoke' : '')}
        pagination={false}
      />
    ) : (
      <div>
        <Table
          key={`${listProducts.length}`}
          size="small"
          scroll={{ x: 'max-content', y: 400 }}
          dataSource={listProducts}
          columns={columns(
            this.onChangeLotNumber,
            this.onChangeQuantity,
            this.onRemoveItem,
            this.onAddItem,
            products,
            focusRowIndex,
            storageCode,
            this.checkQuantity,
          )}
          rowKey={(record) => record.expirationCode}
          rowClassName={(r, idx) => (idx % 2 ? 'whitesmoke' : '')}
          pagination={false}
        />
        <TextArea
          style={{ marginTop: 20 }}
          rows={2}
          placeholder="Ghi chú"
          value={note}
          onChange={(e) => this.props.onChangeNote(e)}
        />
        <Button
          type="primary"
          shape="round"
          style={{ width: '30%', marginLeft: '35%', marginTop: '30px' }}
          onClick={this.onFinish}
          disabled={!listProducts?.length}>
          Tạo phiếu xuất
        </Button>
      </div>
    );
  }
}

export default ItemList;
