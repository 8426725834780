import { Col, Row, notification } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Card from '../../components/Card';
import ROUTER from '../../config/router';
import { actions as vehicleActions } from '../../redux/VehicleRedux';
import { isBelowBreakpoint } from '../../utils/window';
import VehicleForm from './components/Form';

class AddVehicle extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSubmit = (values) => {
    const { history } = this.props;
    const {
      label,
      description,
      license_plate_number,
      vehicle_type,
      vehicle_volume,
      vehicle_capacity,
      defaultFeatureCode,
    } = values;
    const vehicleInfoPayload = {
      label,
      description,
      license_plate_number,
      vehicle_type,
      vehicle_volume,
      vehicle_capacity,
      feature_codes: defaultFeatureCode,
      images: [],
    };
    this.props.insertVehicle(vehicleInfoPayload, {
      onSuccess: () => {
        notification.open({
          message: 'Thêm thành công',
          type: 'success',
        });
        history.push(ROUTER.VEHICLE.INDEX);
      },
      onError: () =>
        notification.open({
          message: `${'Thêm thất bại'}`,
          type: 'error',
        }),
    });
  };

  render() {
    return (
      <div>
        <Row type="flex" justify="center">
          <Col span={24} lg={24}>
            <Card hoverable={false} bordered={!isBelowBreakpoint()}>
              <VehicleForm onSubmit={this.onSubmit} isEditMode={false} />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ companyReducer }) => ({
  companies: companyReducer.items,
});

const mapDispatchToProps = (dispatch) => {
  const { insertVehicle } = vehicleActions;
  return {
    insertVehicle: (payload, meta) => dispatch(insertVehicle(payload, meta)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddVehicle));
