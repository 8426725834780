import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { API_URLS } from '../config/api';
import { apiCallPromise } from '../utils/api';
import { PREFIX, typesWithPrefix } from './config';

const _types = typesWithPrefix(PREFIX.SECRET_KEY);

const types = {
  CREATE_SECRET_KEY: _types('CREATE_SECRET_KEY'),
  GET_SECRET_KEY_BY_COMPANY: _types('GET_SECRET_KEY_BY_COMPANY'),
};

const initialState = {
  data: null,
  isFetching: false,
  didInvalidate: true,
};

const thunkActions = {
  createSecretKey: createAsyncThunk(
    types.CREATE_SECRET_KEY,
    async ({ payload, meta }, { dispatch }) => {
      const api = API_URLS.SECRET_KEY.createSecretKey(payload);

      const { response, error } = await apiCallPromise(api);
      if (!error && (response.status === 200 || response.status === 201)) {
        dispatch(thunkActions.getSecretKeyByCompany(payload.company_code));
        if (meta && meta.onSuccess) {
          meta.onSuccess();
          fulfillWithValue({ response });
        }
      } else {
        if (meta && meta.onError) meta.onError(error);
        rejectWithValue({ error });
      }
    },
  ),

  getSecretKeyByCompany: createAsyncThunk(types.GET_SECRET_KEY_BY_COMPANY, async (code) => {
    const api = API_URLS.SECRET_KEY.getSecretKeyByCompany(code);
    const { response } = await apiCallPromise(api);

    // if (!error && response.status === 200 && response.data.success === true) {
    //   if (meta && meta.onSuccess) meta.onSuccess();
    //   return { data: response.data.data };
    // } else {
    //   if (meta && meta.onError) meta.onError(error);
    //   rejectWithValue();
    // }
    return response.data;
  }),
};

const secretKeySlice = createSlice({
  name: 'secretKeySlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(thunkActions.getSecretKeyByCompany.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(thunkActions.getSecretKeyByCompany.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.data = data;
        state.didInvalidate = true;
        state.isFetching = false;
      })
      .addCase(thunkActions.getSecretKeyByCompany.rejected, (state) => {
        state.isFetching = false;
      })
      .addCase(thunkActions.createSecretKey.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(thunkActions.createSecretKey.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.didInvalidate = true;
      })
      .addCase(thunkActions.createSecretKey.rejected, (state) => {
        state.didInvalidate = true;
        state.isFetching = false;
      });
  },
});

export const actions = { ...secretKeySlice.actions, ...thunkActions };
export const { reducer } = secretKeySlice;
