import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import { Input, Select, Button, Table } from 'antd';
import { cloneDeep } from 'lodash';
import React, { useState, useEffect } from 'react';

// import { isBelowBreakpoint } from '../../../utils/window';
// import { changeAlias } from '../../../../util/formatText';

const { Option } = Select;

const columns = (onChangeQuantity, onRemoveItem, onAddItem, products) => [
  {
    title: () =>
      products?.length ? (
        <Select
          placeholder="Chọn mặt hàng"
          style={{ width: '250px' }}
          showSearch
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onSelect={(values) => onAddItem(values)}
          showAction={['click', 'focus']}>
          {products.map((item, index) => (
            <Option key={item.code} value={item.code}>
              {item.secondary_code ? `${item.secondary_code} - ${item.name}` : item.name}
            </Option>
          ))}
        </Select>
      ) : (
        <b>Sản phẩm</b>
      ),
    width: '30%',
    key: 'name',
    dataIndex: ['product', 'name'],
    render: (value, record) => {
      const obj = {
        children: (
          <>
            {value}
            <br />
            <small>{record.product.code}</small>
          </>
        ),
        props: {
          rowSpan: record.rowSpan !== undefined ? record.rowSpan : 1,
        },
      };

      return obj;
    },
  },
  // {
  //   title: <b>Giá nhập</b>,
  //   key: 'input_price',
  //   width: '10%',
  //   render: (value, record, index) => (
  //     <Input
  //       defaultValue={1}
  //       type="number"
  //       key="input_price"
  //       value={record.input_price}
  //       addonAfter={<div>đ</div>}
  //       onChange={(e) => onChangePrice(e.target.value, record.code, index)}
  //     />
  //   ),
  // },
  {
    title: <b>Số lượng</b>,
    key: 'quantity',
    dataIndex: 'quantity',
    width: '10%',
    render: (value, record, index) => (
      <Input
        defaultValue={1}
        type="number"
        min={1}
        max={1000}
        key="quantity"
        value={value}
        addonAfter={<div>{record.product?.unit?.secondary_unit}</div>}
        onChange={(e) => onChangeQuantity(e.target.value, record.product.code, index)}
      />
    ),
  },
  {
    title: <b>Xoá</b>,
    width: '10%',
    align: 'center',
    render: (_, record, index) => (
      <Button
        shape="circle"
        type="danger"
        onClick={() => onRemoveItem(record.code, index)}
        icon={<DeleteOutlined />}
      />
    ),
  },
];

const ProductForm = (props) => {
  const { products, orderItems } = props;
  const [listProducts, setListProducts] = useState([]);

  useEffect(() => {
    setListProducts(orderItems?.length ? cloneDeep(orderItems) : []);
  }, [orderItems]);

  const onAddItem = (productCode) => {
    const index = listProducts.findIndex((item) => item.product.code === productCode);

    if (index >= 0) {
      listProducts[index].quantity += 1;
    } else {
      const productItem = products.find((item) => item.code === productCode);
      listProducts.push({
        product: {
          category: productItem.category,
          category_code: productItem.category_code,
          code: productItem.code,
          description: productItem.description,
          feature: productItem.feature,
          name: productItem.name,
          secondary_code: productItem.secondary_code,
          unit: productItem.unit,
        },
        quantity: 1,
      });
    }

    setListProducts([...listProducts]);
  };

  const onRemoveItem = (code) => {
    const removedProducts = listProducts.filter((item) => item.product.code !== code);

    setListProducts(removedProducts);
  };

  const onChangeQuantity = (value, code, productIndex) => {
    const quantity = value >= 0 ? value : 0;

    if (listProducts[productIndex] && listProducts[productIndex].product.code === code) {
      listProducts[productIndex].quantity = Number.parseInt(quantity, 10);

      setListProducts([...listProducts]);
    }
  };

  const onSubmit = () => {
    props.onSubmit(listProducts);
  };

  return (
    <>
      <Table
        size="small"
        scroll={{ x: 'max-content', y: 400 }}
        dataSource={listProducts}
        columns={columns(onChangeQuantity, onRemoveItem, onAddItem, products)}
        rowKey={(record, index) => `${record.code}-${index}`}
        rowClassName={(r, idx) => (idx % 2 ? 'whitesmoke' : '')}
        pagination={false}
      />

      {/* <Divider /> */}
      <div style={{ float: 'right', marginTop: '20px' }}>
        <Button
          type="primary"
          onClick={onSubmit}
          shape="round"
          icon={<CheckOutlined />}
          disabled={!orderItems?.length && !listProducts.length}>
          Lưu
        </Button>
      </div>
    </>
  );
};

export default ProductForm;
