/* eslint-disable import/no-unresolved */
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Switch, Table, notification } from 'antd';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import { Link } from 'react-router-dom';

import ROUTER from '../../../config/router';

import '../../../assets/css/style.css';
import './style.css';

const rowSelection = (onSelectChange, onSelectRow, onSelectAllRow) => ({
  onChange: (selectedRowKeys, record) => onSelectChange(selectedRowKeys, record),
  onSelect: (record, selected) => onSelectRow(record, selected),
  getCheckboxProps: (record) => ({
    name: record.name,
  }),
  onSelectAll: (selected, selectedRows, changeRows) =>
    onSelectAllRow(selected, selectedRows, changeRows),
  columnWidth: 10,
});

const columns = (collapseTable, isImportFromFile, changeStateCustomer) => [
  {
    title: <b>Mã khách hàng</b>,
    dataIndex: 'code',
  },

  {
    title: <b>Tên khách hàng</b>,
    dataIndex: 'name',
    key: 'name',
    render: isImportFromFile
      ? (value) => value
      : (value, record) => (
          <Link
            to={ROUTER.CUSTOMER.EDIT.replace(':code', record.code)}
            key={`${record.code}-customer-name`}>
            {value}
          </Link>
        ),
  },
  {
    title: <b>Phân loại</b>,
    key: 'type',
    dataIndex: 'type',
    render: (value) => (value === 'agency' ? 'Đại lý' : value === 'retail' ? 'Khách lẻ' : '-'),
  },
  {
    title: <b>Địa chỉ</b>,
    key: 'address',
    dataIndex: ['location', 'address'],
  },
  {
    title: <b>Toạ độ</b>,
    dataIndex: ['location', 'latitude'],
    align: 'center',
    render: (value, record) =>
      record?.location?.latitude && record?.location?.longitude ? (
        <CheckCircleOutlined style={{ fontSize: '18px', color: 'green' }} />
      ) : (
        <CloseCircleOutlined style={{ fontSize: '18px', color: 'red' }} />
      ),
  },
  {
    title: <b>Trạng thái</b>,
    dataIndex: 'active',
    render: (value, record) => (
      <Switch
        defaultChecked={value}
        onChange={(value) => {
          const payload = {
            active: value,
            customer_codes: [record.code],
          };
          changeStateCustomer(payload, {
            onSuccess: () => {
              notification.open({
                message: 'Thay đổi hoạt động thành công',
                type: 'success',
              });
            },
            onError: (errorCode) =>
              notification.open({
                message: 'Thay đổi hoạt động không thành công',
                type: 'error',
              }),
          });
        }}
      />
    ),
  },
];

const expandcolumns = [
  {
    title: <b>Tên người nhận</b>,
    dataIndex: 'name',
    key: 'name',
    width: '250px',
    span: 2,
  },
  {
    title: <b>Số điện thoại</b>,
    dataIndex: 'phone_number',
    key: 'phone_number',
    width: '250px',
    span: 2,
  },
];

class CustomerList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageSize: 1,
    };
  }

  render() {
    const {
      dataSource,
      meta,
      onPageChange,
      isImportFromFile,
      onSelectChange,
      showRowSelection,
      onSelectRow,
      collapseTable,
      onSelectAllRow,
      changeStateCustomer,
    } = this.props;
    const customerColumns = columns(collapseTable, isImportFromFile, changeStateCustomer);

    const expandedRowRender = (record) => (
      <div className="expand-detail-contacts">
        <Table
          size="small"
          dataSource={record.contacts}
          columns={expandcolumns}
          pagination={false}
          rowKey="phone_number"
          style={{ marginLeft: '30px', maxWidth: '650px' }}
        />
      </div>
    );

    return (
      <>
        <Table
          size="small"
          dataSource={dataSource}
          columns={customerColumns}
          rowKey={(record) =>
            isImportFromFile ? `${record?.name + record?.location?.address}` : record?.code
          }
          expandedRowRender={(record) => expandedRowRender(record)}
          pagination={{
            // page,
            current: meta?.page,
            pageSize: meta?.pageSize || 5,
            total: meta?.total,
            onChange: onPageChange,
            showSizeChanger: !showRowSelection,
            locale: { items_per_page: '/ trang' },
            showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total}`,
          }}
          rowSelection={
            showRowSelection ? rowSelection(onSelectChange, onSelectRow, onSelectAllRow) : ''
          }
          scroll={{ x: 'max-content' }}
          // rowClassName={(record) => {
          //   if (
          //     isImportFromFile &&
          //     (record['Tên'] === undefined || record['Số điện thoại'] === undefined)
          //   )
          //     return 'text-red';
          // }}
        />
      </>
    );
  }
}

export default CustomerList;
