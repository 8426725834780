import { Col, Row, notification } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import CompanyForm from './components/Form';
import Card from '../../components/Card';
import ROUTER from '../../config/router';
import { actions as companyActions } from '../../redux/CompanyRedux';
import { isBelowBreakpoint } from '../../utils/window';

class AddCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSubmit = (values) => {
    const { history, companyCode } = this.props;
    const { address, description, email, type, parent_code, hotline, name, phone } = values;
    const companyInfoPayload = {
      address,
      description,
      email,
      type: companyCode ? 'single' : type || 'single',
      parent_code: companyCode ? companyCode : type === 'multi' ? '' : parent_code || '',
      hotline,
      name,
      phone,
    };
    this.props.insertCompany(companyInfoPayload, {
      onSuccess: () => {
        notification.open({
          message: 'Thêm thành công',
          type: 'success',
        });
        history.push(ROUTER.COMPANY.INDEX);
      },
      onError: () =>
        notification.open({
          message: `${'Thêm thất bại'}`,
          type: 'error',
        }),
    });
  };

  render() {
    return (
      <div>
        <Row type="flex" justify="center">
          <Col span={24} lg={24}>
            <Card hoverable={false} bordered={!isBelowBreakpoint()}>
              <CompanyForm
                onSubmit={this.onSubmit}
                companyCode={this.props.companyCode}
                isEditMode={false}
              />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ companyReducer, authReducer }) => ({
  companies: companyReducer.items,
  companyCode: authReducer.authUser.company_code,
});

const mapDispatchToProps = (dispatch) => {
  const { insertCompany } = companyActions;
  return {
    insertCompany: (payload, meta) => dispatch(insertCompany(payload, meta)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddCompany));
