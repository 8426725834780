import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Input, Select, Button, DatePicker, Table } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import VNDInput from '../../../components/FormItems/VNDInput';
import { ISODateTimeParam } from '../../../utils/dateTime';

// import { isBelowBreakpoint } from '../../../utils/window';
// import { changeAlias } from '../../../../util/formatText';
// import { isBelowBreakpoint } from '../../../../util/windows';

// const formatter = new Intl.NumberFormat('vi-VN', {
//   style: 'currency',
//   currency: 'VND',
//   minimumFractionDigits: 0,
// });
const { Option } = Select;

const columns = (
  onChangeQuantity,
  checkQuantity,
  onRemoveItem,
  onAddItem,
  products,
  onChangeManufacturingDate,
  onChangeExpiryDate,
  focusRowIndex,
  storageCode,
  warehouseProducts,
  onChangePrice,
) => [
  {
    title: () => (
      // <ProductSearch onChange={(value) => onAddItem(value)} style={{ width: '250px' }} />
      <Select
        placeholder="Chọn mặt hàng"
        style={{ width: '250px' }}
        showSearch
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onSelect={(values) => onAddItem(values)}
        showAction={['click', 'focus']}>
        {products?.map((item, index) => (
          <Option key={item.code} value={item.code}>
            {item.secondary_code ? `${item.secondary_code} - ${item.name}` : item.name}
          </Option>
        ))}
      </Select>
    ),
    width: '300px',
    key: 'name',
    dataIndex: 'name',
    render: (value, record) => {
      const obj = {
        children: (
          <>
            {value}
            <br />
            <small>{record.code}</small>
          </>
        ),
        props: {
          rowSpan: record.rowSpan !== undefined ? record.rowSpan : 1,
        },
      };

      return obj;
    },
  },
  {
    title: <b>Số lượng</b>,
    key: 'quantity',
    width: '120px',
    render: (value, record, index) => (
      <Input
        defaultValue={1}
        autoFocus={index === focusRowIndex}
        type="number"
        min={1}
        max={1000}
        key="quantity"
        value={record.quantity}
        addonAfter={<div>{record.unit?.secondary_unit}</div>}
        onChange={(e) => onChangeQuantity(e.target.value, record.code, index)}
        onBlur={(e) => checkQuantity(e, record.code)}
      />
    ),
  },
  {
    title: <b>Giá nhập</b>,
    key: 'input_price',
    width: '130px',
    render: (_, record, index) => (
      <VNDInput
        value={record.input_price || 0}
        onChange={(val) => onChangePrice(val, record.code, index)}
      />
    ),
  },
  // {
  //   title: <b>Còn trong kho</b>,
  //   key: '',
  //   width: '80px',
  //   align: 'center',
  //   // render: (value, record) => {
  //   //   const foundProduct = warehouseProducts.find(
  //   //     (product) => product.product_code === record.code && product.storage_code === storageCode,
  //   //   );
  //   //   if (isObject(foundProduct)) return foundProduct.total_quantity;

  //   //   return 0;
  //   // },
  // },
  {
    title: <b>Ngày sản xuất</b>,
    key: 'manufacturing_date',
    width: '150px',
    render: (value, record, index) => (
      <>
        <DatePicker
          defaultValue={record.manufacturing_date ? moment(record.manufacturing_date) : moment()}
          allowClear={false}
          style={{ width: '80%' }}
          format="DD-MM-YYYY"
          placeholder="Chọn ngày"
          onChange={(value) => onChangeManufacturingDate(value, record.code, index)}
          // disabledDate={(current) => current && current < Date.now() - 86400000}
        />
        {record.plusIcon === false ? null : (
          <Button
            size="small"
            type="primary"
            shape="circle"
            icon={<PlusOutlined />}
            style={{ marginLeft: 5 }}
            onClick={() => onAddItem(record.code)}
          />
        )}
      </>
    ),
  },
  {
    title: <b>Ngày hết hạn</b>,
    key: 'expiry_date',
    width: '150px',
    render: (value, record, index) => (
      <>
        <DatePicker
          defaultValue={record.expiry_date ? moment(record.expiry_date) : ''}
          allowClear={false}
          style={{ width: '80%' }}
          format="DD-MM-YYYY"
          placeholder="Chọn ngày"
          onChange={(value) => onChangeExpiryDate(value, record.code, index)}
          disabledDate={(current) => current && current < Date.now() - 86400000}
        />
        {record.plusIcon === false ? null : (
          <Button
            size="small"
            type="primary"
            shape="circle"
            icon={<PlusOutlined />}
            style={{ marginLeft: 5 }}
            onClick={() => onAddItem(record.code)}
          />
        )}
      </>
    ),
  },

  {
    title: <b>Xoá</b>,
    width: '60px',
    align: 'center',
    render: (value, record, index) => (
      <Button
        shape="circle"
        type="danger"
        onClick={() => onRemoveItem(record.code, index)}
        icon={<DeleteOutlined />}
      />
    ),
  },
];
class ProductForm extends Component {
  constructor(props) {
    super(props);
    this.textSelect = React.createRef();
    this.state = {
      listProducts: [],
      products: [],
      originProducts: [],
      stationCode: '',
      storageCode: '',
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { products, stationCode, storageCode } = nextProps;
    if (stationCode !== prevState.stationCode || storageCode !== prevState.storageCode)
      return { listProducts: [], stationCode, storageCode };

    if (products !== prevState.originProducts) return { products, originProducts: products };

    if (nextProps.searchProduct) {
      const { productItems, stationCodeSumary, products } = nextProps;
      if (!prevState.listProducts.length && productItems && productItems.length) {
        const fullInfoProductItems = [];
        productItems.forEach((item) => {
          products.find((itemProduct) => {
            if (item.code === itemProduct.code) {
              fullInfoProductItems.push({
                quantity: item.quantity,
                code: item.code,
                price: itemProduct.price,
                name: itemProduct.name,
                unit: itemProduct.unit,
              });
            }
          });
        });
        nextProps.emptyReceivingVoucher([], '');
        nextProps.sendData(fullInfoProductItems);
        return {
          listProducts: fullInfoProductItems,
          stationCode: stationCodeSumary,
        };
      }
    } else if (nextProps.stationCode !== prevState.stationCode) {
      return {
        listProducts: [],
        stationCode: nextProps.stationCode,
      };
    }
    return null;
  }

  setRowSpan = (listProducts, productCode) => {
    const duplicatedProductsIndex = [];
    listProducts.forEach((item, index) => {
      if (item.code === productCode) {
        duplicatedProductsIndex.push(index);
      }
    });
    if (duplicatedProductsIndex.length === 1) {
      listProducts[duplicatedProductsIndex[0]].rowSpan = 1;
      listProducts[duplicatedProductsIndex[0]].plusIcon = true;
    } else if (duplicatedProductsIndex.length > 1) {
      duplicatedProductsIndex.forEach((pIndex) => {
        listProducts[pIndex].rowSpan = 0;
        listProducts[pIndex].plusIcon = false;
      });
      listProducts[duplicatedProductsIndex[0]].rowSpan = duplicatedProductsIndex.length;
      listProducts[duplicatedProductsIndex[duplicatedProductsIndex.length - 1]].plusIcon = true;

      return duplicatedProductsIndex[duplicatedProductsIndex.length - 1];
    }

    return 0;
  };

  onRemoveItem = (code, productIndex) => {
    const { listProducts } = this.state;
    const removeProducts = listProducts.filter(
      (item, index) => item.code !== code || index !== productIndex,
    );
    this.setRowSpan(removeProducts, code);
    this.setState({ listProducts: removeProducts }, () => this.combineData(removeProducts));
  };

  checkQuantity = ({ target: { value } }, id) => {
    if (Number(value) > 0) this.onChangeQuantity(value, id);
    else this.onChangeQuantity(0, id);
  };

  onAddItem = (productCode, grouped = false) => {
    const { listProducts, originProducts } = this.state;
    let isExist = -1;
    if (listProducts.length) {
      const index = listProducts
        .slice() // to preserve the original array
        .reverse()
        .findIndex((item) => item.code === productCode);
      // isExist is the last index of item that has the productCode
      isExist = index >= 0 ? listProducts.length - 1 - index : index;
    }
    let product = originProducts?.find((item) => item.code === productCode);
    if (product) {
      if (product.expiration) {
        const expiryDate = new Date();
        if (product.expiration.day)
          expiryDate.setDate(expiryDate.getDate() + product.expiration.day);
        if (product.expiration.month)
          expiryDate.setMonth(expiryDate.getMonth() + product.expiration.month);
        if (product.expiration.year)
          expiryDate.setFullYear(expiryDate.getFullYear() + product.expiration.year);

        product = {
          ...product,
          manufacturing_date: ISODateTimeParam(new Date()),
          expiry_date: ISODateTimeParam(expiryDate),
        };
      }
      let newListProducts = listProducts;
      let focusRowIndex = listProducts.length;
      const newProduct = { ...product, quantity: 1, input_price: 0 };
      if (isExist < 0) {
        newListProducts = newListProducts.concat(newProduct);
        focusRowIndex = newListProducts.length - 1;
      } else {
        // if (grouped) {
        newListProducts.splice(isExist + 1, 0, newProduct);
        focusRowIndex = this.setRowSpan(newListProducts, productCode);
        // } else notification.error({ message: 'Sản phẩm đã có trong danh sách!' });
      }

      this.setState({ listProducts: newListProducts, focusRowIndex }, () => this.combineData());
    }
  };

  onChangeQuantity = (value, code, productIndex) => {
    if (value <= 0) return;

    const { listProducts } = this.state;
    const newProducts = listProducts.map((item, index) => {
      if (item.code === code && index === productIndex)
        return {
          ...item,
          quantity: Number(value),
          //   totalPrice: item?.price?.price * Number(value),
        };

      return item;
    });
    this.setState({ listProducts: newProducts }, () => this.combineData());
  };

  onChangePrice = (value, code, productIndex) => {
    if (value <= 0) return;

    const { listProducts } = this.state;
    const newProducts = listProducts.map((item, index) => {
      if (item.code === code && index === productIndex)
        return {
          ...item,
          input_price: Number(value),
        };

      return item;
    });
    this.setState({ listProducts: newProducts }, () => this.combineData());
  };

  onChangeManufacturingDate = (value, code, productIndex) => {
    const { listProducts } = this.state;
    const newManufacturingDateProducts = listProducts.map((item, index) => {
      if (item.code === code && index === productIndex) {
        return {
          ...item,
          manufacturing_date: ISODateTimeParam(value),
        };
      }
      return item;
    });

    this.setState({ listProducts: newManufacturingDateProducts }, () => this.combineData());
  };

  onChangeExpiryDate = (value, code, productIndex) => {
    const { listProducts } = this.state;
    const newExpiryDateProducts = listProducts.map((item, index) => {
      if (item.code === code && index === productIndex)
        return { ...item, expiry_date: ISODateTimeParam(value) };

      return item;
    });

    this.setState({ listProducts: newExpiryDateProducts }, () => this.combineData());
  };

  combineData = () => this.props.sendData(this.state.listProducts);

  render() {
    const { products, storageCode, warehouseProducts } = this.props;
    const { listProducts, focusRowIndex } = this.state;

    return (
      <Table
        key={`${listProducts?.length}`}
        size="small"
        scroll={{ x: 'max-content', y: 400 }}
        dataSource={listProducts}
        columns={columns(
          this.onChangeQuantity,
          this.checkQuantity,
          this.onRemoveItem,
          this.onAddItem,
          products,
          this.onChangeManufacturingDate,
          this.onChangeExpiryDate,
          focusRowIndex,
          storageCode,
          warehouseProducts,
          this.onChangePrice,
        )}
        rowKey={(record, index) => `${record.code}-${index}`}
        rowClassName={(r, idx) => (idx % 2 ? 'whitesmoke' : '')}
        pagination={false}
      />
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  emptyReceivingVoucher: (productItem, stationSumary) =>
    dispatch(emptyReceivingVoucher(productItem, stationSumary)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductForm));
