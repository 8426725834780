import { PlusOutlined } from '@ant-design/icons';
import { Divider, Button, Modal, message, notification } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as XLSX from 'xlsx';

import Filter from './components/Filter';
import DriverList from './components/List';
import ROUTER from '../../config/router';
import WithConfig from '../../hoc/WithConfig';
import WithLoading from '../../hoc/WithLoading';
import { actions as driverActions } from '../../redux/DriverRedux';
import { actions as vehicleActions } from '../../redux/VehicleRedux';

const {
  getDriversIfNeed,
  changePage,
  invalidateDrivers,
  activeSecretDriveCode,
  deactiveSecretCode,
  changeActiveDriver,
} = driverActions;
const { getAllVehicles } = vehicleActions;

const SheetJSFT = [
  'xlsx',
  'xlsb',
  'xlsm',
  'xls',
  'xml',
  'csv',
  'txt',
  'ods',
  'fods',
  'uos',
  'sylk',
  'dif',
  'dbf',
  'prn',
  'qpw',
  '123',
  'wb*',
  'wq*',
  'html',
  'htm',
]
  .map(function (x) {
    return `.${x}`;
  })
  .join(',');
class DriverPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataFilter: {
        page: 1,
        page_size: 10,
        search: '',
        gender: '',
        status: '',
      },
      dataFromFile: [],
      loading: false,
      visible: false,
    };
  }

  componentDidMount() {
    this.props.getAllVehicles({ page: 1 });
  }

  static getDerivedStateFromProps(props, state) {
    const { search, gender, status, page, page_size } = state.dataFilter;

    props.getDriversIfNeed({
      page,
      page_size,
      search,
      gender,
      status,
    });

    return null;
  }

  onPageChange = (page, page_size) => {
    const { dataFilter } = this.state;
    const { invalidateDrivers } = this.props;

    dataFilter.page = page;
    dataFilter.page_size = page_size;
    this.setState({
      dataFilter,
    });
    invalidateDrivers();
  };

  showModal = () => this.setState({ visible: true });

  handleOk = () => {
    this.setState({ loading: true });

    this.props.insertManyDriver(
      { drivers: this.state.dataFromFile },
      {
        onSuccess: (res) => {
          if (res?.data?.success === true)
            notification.success({
              message: `Thêm thành công ${res?.data?.data?.length} lái xe!`,
            });
          else if (
            res?.data?.success === false &&
            res?.data?.message === 'must provide at least one element in input slice'
          )
            notification.error({
              message:
                'Thêm lái xe từ file thất bại! Xem lại thông tin lái xe trong file đã tồn tại hay chưa?',
            });
        },
        onError: (err) => {
          const message = JSON.parse(err.response).message;
          notification.error({
            message: 'Thêm lái xe từ file thất bại!',
            description: message,
          });
        },
      },
    );

    this.setState({ loading: false, visible: false });
  };

  handleCancel = () => this.setState({ dataFromFile: [], visible: false });

  handleOnAdd = () => {
    const { history } = this.props;
    history.push(ROUTER.DRIVER.ADD_DRIVER);
  };

  onChangeFilter = (data) => {
    const { dataFilter } = this.state;
    this.setState({ dataFilter: { ...dataFilter, ...data } });
  };

  readExcelFile(file) {
    const promise = new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);

      reader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: 'buffer' });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      reader.onerror = (err) => reject(err);
    });

    promise
      .then((data) => {
        const recordFromFile = [];
        if (data.length) {
          data.map((record) => {
            // Nếu record là thông tin 1 lái xe
            if (record['Họ và tên']) {
              const newDriver = {
                name: record['Họ và tên'] || '',
                gender: record['Giới tính'] || '',
                date_of_birth: record['Ngày sinh'] || '',
                identity_card: record['CCCD'] || '',
                phone_number: record['Số điện thoại'] || '',
                email: record['Email'] || '',
                note: record['Ghi chú'] || '',
                images: record['Hình ảnh'] || [],
                default_vehicle_code: record['Mã phương tiện mặc định'] || '',
                status: record['Trạng thái'] || '',
                work_time_from: record['Thời gian làm việc từ'] || '',
                work_time_to: record['Thời gian làm việc đến'] || '',
                fixed_cost: record['Lương cứng'] || 0,
                cost_per_km: record['Lương theo quãng đường'] || 0,
                cost_per_hour: record['Lương theo giờ'] || 0,
                cost_per_hour_overtime: record['Lương thưởng ngoài giờ'] || 0,
                speed: record['Tốc độ'] || 0,
                start_location: {
                  address: record['Địa điểm bắt đầu'] || '',
                  latitude: 0,
                  longitude: 0,
                },
                finish_location: {
                  address: record['Địa điểm kết thúc'] || '',
                  latitude: 0,
                  longitude: 0,
                },
              };

              recordFromFile.push(newDriver);
            }
          });
        }
        if (this.state.dataFromFile.length > 0) this.setState({ dataFromFile: [] });
        this.setState({ dataFromFile: recordFromFile });
        message.success('Tải file thành công!');
      })
      .catch((error) => {
        this.setState({ dataFromFile: [] });
        // eslint-disable-next-line no-console
        console.log(error);
        message.error('Tải file thất bại!');
      });
  }

  render() {
    const { dataFromFile, visible, loading } = this.state;
    const {
      isFetching,
      meta,
      drivers,
      allVehicles,
      activeSecretDriveCode,
      deactiveSecretCode,
      changeState,
    } = this.props;

    return (
      <>
        <div>
          <h2 style={{ float: 'left' }}>Danh sách lái xe</h2>
          <div style={{ float: 'right' }}>
            <Button shape="round" type="primary" onClick={this.handleOnAdd} icon={<PlusOutlined />}>
              Thêm
            </Button>
          </div>
        </div>
        <Divider style={{ marginTop: 0 }} />

        <div style={{ marginBottom: '15px' }}>
          <Filter
            style={{ width: 'inherit', marginBottom: '15px' }}
            onSearch={this.onChangeFilter}
            onShowModal={this.showModal}
          />
        </div>

        <DriverList
          allVehicles={allVehicles}
          isFetching={isFetching}
          dataSource={drivers}
          meta={meta}
          onPageChange={this.onPageChange}
          activeSecretCode={activeSecretDriveCode}
          deactiveSecretCode={deactiveSecretCode}
          changeStateDriver={changeState}
        />

        <Modal
          visible={visible}
          title="Nhập File Excel"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width={1500}
          footer={[
            <Button
              key="submit"
              shape="round"
              type="primary"
              loading={loading}
              onClick={this.handleOk}>
              Lưu
            </Button>,
            <Button key="back" shape="round" onClick={this.handleCancel}>
              Huỷ Bỏ
            </Button>,
          ]}>
          <input
            type="file"
            accept={SheetJSFT}
            label="Tải lên File"
            onChange={(e) => {
              const file = e.target.files[0];
              this.readExcelFile(file);
            }}
          />
          <a
            // eslint-disable-next-line max-len
            href="https://docs.google.com/spreadsheets/d/1Xu7Kgiigc2DWbz5j39JrYyuGLYKJJOVk/edit?usp=sharing&ouid=105820780785344894245&rtpof=true&sd=true"
            target="_blank">
            Mẫu file Excel
          </a>
          <DriverList
            isFetching={isFetching}
            dataSource={dataFromFile}
            meta={meta}
            onChange={this.onPageChange}
            onPageChange={this.onPageChange}
            isImportFromFile
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { driverReducer, vehicleReducer } = state;
  return {
    drivers: driverReducer.items,
    meta: driverReducer.meta,
    total: driverReducer.meta.total,
    isFetching: driverReducer.isFetching,
    allVehicles: vehicleReducer.allItems,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllVehicles: () => dispatch(getAllVehicles()),
  getDriversIfNeed: (filterOption) => dispatch(getDriversIfNeed(filterOption)),
  changePage: (page, page_size, total) => dispatch(changePage(page, page_size, total)),
  invalidateDrivers: () => dispatch(invalidateDrivers()),
  insertManyDriver: (payload, meta) => dispatch(driverActions.insertManyDriver(payload, meta)),
  activeSecretDriveCode: (code, meta) => dispatch(activeSecretDriveCode(code, meta)),
  deactiveSecretCode: (code, meta) => dispatch(deactiveSecretCode(code, meta)),
  changeState: (payload, meta) => dispatch(changeActiveDriver(payload, meta)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WithConfig('driver')(WithLoading(DriverPage)));
