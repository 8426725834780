import { DeleteOutlined, QrcodeOutlined } from '@ant-design/icons';
import { Input, Button, Select, Table, notification, Tooltip } from 'antd';
import { isArray } from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';

import { API_URLS } from '../../../config/api';
import { apiCall } from '../../../utils/api';
import { downloadFile } from '../../../utils/file';
import { isBelowBreakpoint } from '../../../utils/window';

const { Option } = Select;

const handleExportItemQRCode = async (code, product_code, lot_number) => {
  const api = API_URLS.DELIVERY_VOUCHER.getDeliveryVoucherItemQRCode(
    code,
    product_code,
    lot_number,
  );

  const { response, error } = await apiCall(api);
  if (!error && response.status === 200 && response.data?.url) {
    const linkFile = response.data.url;
    downloadFile(linkFile);
  } else {
    notification.error({ message: 'Có lỗi khi tải file. Hãy thử lại!' });
  }
};

const columns = (
  onChangeQuantity,
  onRemoveItem,
  onChangeLotNumber,
  onAddItem,
  warehouseProducts,
  state,
  isVoucherHasOrderAttached,
  calcMaxAmount,
  onExportItemQRCode,
) => [
  {
    title: () => <b>Mặt hàng</b>,
    width: '20%',
    key: 'product_name',
    dataIndex: 'product_name',
    render: (value, record) => {
      const obj = {
        children: value,
        props: {
          rowSpan: record.rowSpan !== undefined ? record.rowSpan : 1,
        },
      };
      return obj;
    },
  },
  // {
  //   title: <b>Mã phụ</b>,
  //   key: 'product_secondary_code',
  //   dataIndex: 'product_secondary_code',
  //   width: '10%',
  //   align: 'center',
  // },
  {
    title: <b>Ngày sản xuất / hết hạn</b>,
    key: 'lot_number',
    width: '30%',
    render: (value, record, index) => {
      const whProduct = warehouseProducts?.length
        ? warehouseProducts.find((item) => item.product_code === record.product_code)
        : false;
      return state === 'processing' &&
        whProduct &&
        whProduct.summary_lot_numbers?.length &&
        whProduct.summary_lot_numbers?.length > 1 ? (
        <>
          <Select
            placeholder="Chọn ngày"
            style={{ width: '250px' }}
            showSearch
            filterOption={false}
            disabled={record.quantity === 0}
            defaultValue={`${moment(record.manufacturing_date).format('DD-MM-YYYY')} /
              ${moment(record.expiry_date).format('DD-MM-YYYY')}`}
            onSelect={(values) => onChangeLotNumber(values, record.product_code, index)}
            showAction={['click', 'focus']}>
            {whProduct.summary_lot_numbers !== null &&
              whProduct.summary_lot_numbers?.length !== undefined &&
              whProduct.summary_lot_numbers.map((item) => (
                <Option key={item.lot_number} value={JSON.stringify(item)}>
                  {`${moment(item.manufacturing_date).format('DD-MM-YYYY')} /
              ${moment(item.expiry_date).format('DD-MM-YYYY')}`}
                </Option>
              ))}
          </Select>
          {/* {record.plusIcon === false ? null : (
            <Button
              tabIndex="-1"
              size="small"
              type="primary"
              shape="circle"
              icon={<PlusOutlined />}
              style={{ marginLeft: 5 }}
              onClick={() => onAddItem(record.product_code)}
            />
          )} */}
        </>
      ) : (
        `${moment(record.manufacturing_date).format('DD-MM-YYYY')} /
              ${moment(record.expiry_date).format('DD-MM-YYYY')}`
      );
    },
  },
  {
    title: <b>Số lượng</b>,
    key: 'quantity',
    width: '15%',
    render: (value, record, index) =>
      state === 'processing' ? (
        <Input
          defaultValue={record.quantity}
          autoFocus
          type="number"
          min={1}
          max={calcMaxAmount(warehouseProducts, record)}
          key="quantity"
          value={record.quantity}
          onChange={(e) => onChangeQuantity(e.target.value, record.product_code, index)}
          addonAfter={record.unit?.secondary_unit}
        />
      ) : (
        <div>{record.quantity}</div>
      ),
  },
  {
    title: <b>Còn trong kho</b>,
    key: '',
    width: '15%',
    align: 'center',
    render: (value, record) => calcMaxAmount(warehouseProducts, record),
  },
  {
    title: <b>Thao tác</b>,
    width: '10%',
    align: 'center',
    render: (value, record, index) => (
      <>
        <Tooltip title="Xuất QR Code">
          <Button
            onClick={() => {
              onExportItemQRCode(record.product_code, record.lot_number);
            }}
            style={{ marginRight: 10 }}
            icon={<QrcodeOutlined />}
            shape="circle"
            type="primary"
          />
        </Tooltip>
        <Button
          key="delete"
          shape="circle"
          type="danger"
          onClick={() => onRemoveItem(record.product_code, index)}
          disabled={
            state === 'cancelled' ||
            state === 'completed' ||
            (isVoucherHasOrderAttached && record.rowSpan === 1)
          }
          icon={<DeleteOutlined />}
        />
      </>
    ),
  },
];
class ItemList extends Component {
  constructor(props) {
    super(props);
    this.textSelect = React.createRef();
    this.state = {
      listProducts: isArray(props.products) ? this.setAllRowSpan([...props?.products]) : [],
      products: [],
      originProducts: [],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.products !== prevState.originProducts) {
      const { products } = nextProps;
      return {
        products,
        originProducts: products,
      };
    }
    return null;
  }

  calcMaxAmount = (warehouseProducts, record) => {
    const whProduct = warehouseProducts?.length
      ? warehouseProducts.find((item) => item.product_code === record.product_code)
      : false;
    if (whProduct?.summary_lot_numbers?.length) {
      const lot = record.selectedLotNumber?.lot_number
        ? whProduct?.summary_lot_numbers.find(
            (item) => item?.lot_number === record.selectedLotNumber?.lot_number,
          )
        : whProduct?.summary_lot_numbers.find((item) => item?.lot_number === record?.lot_number);
      return lot?.quantity;
    }

    return 0;
  };

  setAllRowSpan = (listProducts) => {
    const processedProductCodes = [];
    listProducts.forEach((item) => {
      if (!processedProductCodes.includes(item.product_code)) {
        processedProductCodes.push(item.product_code);
        this.setRowSpan(listProducts, item.product_code);
      }
    });
    return listProducts;
  };

  setRowSpan = (listProducts, productCode) => {
    const duplicatedProductsIndex = [];
    let totalQuantity = 0;
    listProducts.forEach((item, index) => {
      if (item.product_code === productCode) {
        duplicatedProductsIndex.push(index);
        totalQuantity += item.quantity || 0;
      }
    });
    if (duplicatedProductsIndex.length === 1) {
      listProducts[duplicatedProductsIndex[0]] = {
        ...listProducts[duplicatedProductsIndex[0]],
        totalQuantity: listProducts[duplicatedProductsIndex[0]].totalQuantity || totalQuantity,
        rowSpan: 1,
        plusIcon: true,
      };
    } else if (duplicatedProductsIndex.length > 1) {
      duplicatedProductsIndex.forEach((pIndex) => {
        listProducts[pIndex] = {
          ...listProducts[pIndex],
          totalQuantity: listProducts[pIndex].totalQuantity || totalQuantity,
          rowSpan: 0,
          plusIcon: false,
        };
      });

      listProducts[duplicatedProductsIndex[0]] = Object.assign(
        {},
        listProducts[duplicatedProductsIndex[0]],
        {
          rowSpan: duplicatedProductsIndex.length,
        },
      );
      listProducts[duplicatedProductsIndex[duplicatedProductsIndex.length - 1]] = Object.assign(
        {},
        listProducts[duplicatedProductsIndex[duplicatedProductsIndex.length - 1]],
        {
          plusIcon: true,
        },
      );

      return duplicatedProductsIndex[duplicatedProductsIndex.length - 1];
    }

    return 0;
  };

  onRemoveItem = (productCode, productIndex) => {
    const { listProducts } = this.state;
    const removeProducts = listProducts.filter(
      (item, index) => item.product_code !== productCode || index !== productIndex,
    );
    this.setRowSpan(removeProducts, productCode);
    this.setState({ listProducts: removeProducts }, () => this.combineData());
  };

  onAddItem = (productCode) => {
    const { listProducts } = this.state;
    const { warehouseProducts } = this.props;
    let isExist = -1;
    if (listProducts.length) {
      const index = listProducts
        .slice() // to preserve the original array
        .reverse()
        .findIndex((item) => item.product_code === productCode);
      // isExist is the last index of item that has the productCode
      isExist = index >= 0 ? listProducts.length - 1 - index : index;
    }
    const product = warehouseProducts.find((item) => item.product_code === productCode);
    if (product && isExist >= 0) {
      const newListProducts = listProducts;
      let focusRowIndex = listProducts.length;
      const newProduct = {
        product_code: product.product_code,
        quantity: 1,
        totalQuantity: newListProducts[isExist].totalQuantity || 0,
        order_codes: newListProducts[isExist].order_codes || null,
        name: product.product_name,
        unit: {
          name: product.unit,
        },
        price: product.price,
        category: {
          name: product.category_name,
          code: product.category_code,
        },
        supplier: {
          name: product.supplier_name,
          code: product.supplier_code,
        },
      };

      newListProducts.splice(isExist + 1, 0, newProduct);
      focusRowIndex = this.setRowSpan(newListProducts, productCode);

      this.setState({ listProducts: newListProducts, focusRowIndex }, () => this.combineData());
    }
  };

  onGetNewIndex = (listProducts, productCode) => {
    let maxIndex = -1;
    listProducts.forEach((item) => {
      if (item.code === productCode) {
        if (item.indexKey !== undefined && item.indexKey > maxIndex) {
          maxIndex = item.indexKey;
        }
      }
    });

    return maxIndex + 1;
  };

  onChangeQuantity = (value, code, productIndex) => {
    if (Number(value) <= 0) return;

    const { listProducts } = this.state;
    const newProducts = listProducts.map((item, index) => {
      if (item.product_code === code && index === productIndex)
        return {
          ...item,
          quantity: Number(value),
          totalPrice: item?.price?.price * Number(value),
        };

      return item;
    });
    this.setState({ listProducts: newProducts }, () => this.combineData());
  };

  onChangeLotNumber = (value, code, key) => {
    const { listProducts } = this.state;
    const newExpirationProducts = listProducts.map((item, index) => {
      if (key === index && item.product_code === code) item.selectedLotNumber = JSON.parse(value);

      return item;
    });
    this.setState({ listProducts: newExpirationProducts }, () => this.combineData());
  };

  combineData = () => this.props.sendData(this.state.listProducts);

  onExportItemQRCode = (product_code, lot_number) => {
    handleExportItemQRCode(this.props.voucherCode, product_code, lot_number);
  };

  render() {
    const { listProducts } = this.state;
    const { warehouseProducts, state, isVoucherHasOrderAttached } = this.props;

    return isBelowBreakpoint() ? (
      <>
        <Table
          key={`${listProducts?.length}`}
          size="small"
          scroll={{ x: 'max-content', y: 400 }}
          dataSource={listProducts}
          columns={columns(
            this.onChangeQuantity,
            this.onRemoveItem,
            this.onChangeLotNumber,
            this.onAddItem,
            warehouseProducts,
            state,
            isVoucherHasOrderAttached,
            this.calcMaxAmount,
            this.onExportItemQRCode,
          )}
          style={{ marginBottom: 10 }}
          rowKey={(record, index) => `${record.product_code}-${index}`}
          rowClassName={(r, idx) => (idx % 2 ? 'whitesmoke' : '')}
          pagination={false}
        />
      </>
    ) : (
      <>
        <Table
          key={`${listProducts?.length}`}
          size="small"
          scroll={{ x: 'max-content', y: 400 }}
          dataSource={listProducts}
          columns={columns(
            this.onChangeQuantity,
            this.onRemoveItem,
            this.onChangeLotNumber,
            this.onAddItem,
            warehouseProducts,
            state,
            isVoucherHasOrderAttached,
            this.calcMaxAmount,
            this.onExportItemQRCode,
          )}
          style={{ marginBottom: 10 }}
          rowKey={(record, index) => `${record.product_code}-${index}`}
          rowClassName={(r, idx) => (idx % 2 ? 'whitesmoke' : '')}
          pagination={false}
        />
      </>
    );
  }
}

export default ItemList;
