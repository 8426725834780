import produce from 'immer';
import moment from 'moment';

import { isCallingApi, isSuccessfulApiCall, isFailedApiCall } from './actionDedicate';
import { PREFIX, typesWithPrefix } from './config';
import { API_URLS } from '../config/api';
import { apiCall } from '../utils/api';

const _types = typesWithPrefix(PREFIX.ACCOUNT);
const TYPE = {
  GET_USERS: _types('GET_USERS'),
  GET_USERS_CHANGE_PAGE: _types('GET_USERS_CHANGE_PAGE'),
  INVALIDATE_USERS: _types('INVALIDATE_USERS'),
  UPDATE_USER: _types('UPDATE_USER'),
  DELETE_USER: _types('DELETE_USER'),
  ACTIVATE_DEACTIVATE_USER: _types('ACTIVATE_DEACTIVATE_USER'),
};
export const actions = {
  //get list user
  getUsersIfNeed: (filterOption) => (dispatch, getState) => {
    const state = getState();
    const isFetching = state.accountReducer.isFetching;
    const didInvalidate = state.accountReducer.didInvalidate;
    const query = state.accountReducer.query;
    const isQueryChanged =
      query.accountType !== filterOption.accountType ||
      query.search !== filterOption.search ||
      query.company_code !== filterOption.company_code;
    if ((!isFetching && didInvalidate) || (!isFetching && isQueryChanged)) {
      dispatch(actions.getUsers(filterOption));
    }
  },

  getUsers: (query) => async (dispatch) => {
    const params = {
      page: query.page,
      page_size: query.page_size,
      search: query.search,
      accountType: query.accountType,
      company_code: query.company_code,
    };
    dispatch(actions.gettingUsers());
    const api = API_URLS.USER.getUsers(params);
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(
        actions.getUsersSuccess({
          items: response.data.data,
          meta: {
            page: response.data.page,
            page_size: response.data.page_size,
            total: response.data.total,
          },
          query: {
            search: params.search,
            company_code: params.company_code,
            accountType: params.accountType,
          },
        }),
      );
    } else {
      dispatch(actions.getUsersFailure(params));
    }
  },
  gettingUsers: () => ({
    type: TYPE.GET_USERS,
    meta: { prefix: [PREFIX.ACCOUNT, PREFIX.API_CALLING] },
  }),

  getUsersSuccess: (payload) => ({
    type: TYPE.GET_USERS,
    payload,
    meta: {
      prefix: [PREFIX.ACCOUNT, PREFIX.API_CALLED_SUCCESS],
    },
  }),

  getUsersFailure: (query) => ({
    type: TYPE.GET_USERS,
    query,
    meta: {
      prefix: [PREFIX.ACCOUNT, PREFIX.API_CALLED_FAILURE],
    },
  }),

  // change page
  changePage: (page, page_size, total) => ({
    type: TYPE.GET_USERS_CHANGE_PAGE,
    payload: {
      page,
      page_size,
      total,
    },
    meta: {
      prefix: [PREFIX.ACCOUNT],
    },
  }),

  // invalidate user
  invalidateUsers: () => ({
    type: TYPE.INVALIDATE_USERS,
    meta: {
      prefix: [PREFIX.ACCOUNT, PREFIX.API_CALLED_SUCCESS],
    },
  }),

  // update user
  updateUser: (userCode, payload, meta) => async (dispatch, getState) => {
    // const state = getState();
    // const pagination = state.accountReducer.meta;
    const api = API_URLS.USER.updateUser(userCode, payload);
    dispatch(actions.updatingUser());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(actions.updateUserSuccess(response.data));
      // dispatch(actions.getUsers({ page: pagination.page, page_size: pagination.page_size }));
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
    } else {
      dispatch(actions.updateUserFailure(error));
      if (meta && meta.onError) {
        meta.onError(error);
      }
    }
  },
  updatingUser: () => ({
    type: TYPE.UPDATE_USER,
    meta: { prefix: [PREFIX.ACCOUNT, PREFIX.API_CALLING] },
  }),
  updateUserSuccess: () => ({
    type: TYPE.UPDATE_USER,
    meta: {
      prefix: [PREFIX.ACCOUNT, PREFIX.API_CALLED_SUCCESS],
    },
  }),
  updateUserFailure: () => ({
    type: TYPE.UPDATE_USER,
    meta: {
      prefix: [PREFIX.ACCOUNT, PREFIX.API_CALLED_FAILURE],
    },
  }),

  // Active- Deactive user
  activeDeactiveUser: (userCode, meta) => async (dispatch) => {
    const api = API_URLS.USER.activeDeactiveUser(userCode);
    dispatch(actions.activeDeactivingUser());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(actions.activeDeactiveUserSuccess(response.data));
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
    } else {
      dispatch(actions.activeDeactiveUserFailure(error));
      if (meta && meta.onError) {
        meta.onError(error);
      }
    }
  },
  activeDeactivingUser: () => ({
    type: TYPE.ACTIVATE_DEACTIVATE_USER,
    meta: { prefix: [PREFIX.ACCOUNT, PREFIX.API_CALLING] },
  }),

  activeDeactiveUserSuccess: () => ({
    type: TYPE.ACTIVATE_DEACTIVATE_USER,
    meta: {
      prefix: [PREFIX.ACCOUNT, PREFIX.API_CALLED_SUCCESS],
    },
  }),

  activeDeactiveUserFailure: () => ({
    type: TYPE.ACTIVATE_DEACTIVATE_USER,
    meta: {
      prefix: [PREFIX.ACCOUNT, PREFIX.API_CALLED_FAILURE],
    },
  }),

  // Delete
  deleteUser: (userId, meta) => async (dispatch) => {
    const api = API_URLS.USER.deleteUser(userId);
    dispatch(actions.deletingUser());
    const { response, error } = await apiCall({ ...api });
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(actions.deleteUserSuccess(response.data));
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
    } else {
      dispatch(actions.deleteUserFailure(error));
      if (meta && meta.onError) {
        meta.onError(error);
      }
    }
  },
  deletingUser: () => ({
    type: TYPE.DELETE_USER,
    meta: { prefix: [PREFIX.USER, PREFIX.API_CALLING] },
  }),

  deleteUserSuccess: () => ({
    type: TYPE.DELETE_USER,
    meta: {
      prefix: [PREFIX.USER, PREFIX.API_CALLED_SUCCESS],
    },
  }),

  deleteUserFailure: () => ({
    type: TYPE.DELETE_USER,
    meta: {
      prefix: [PREFIX.USER, PREFIX.API_CALLED_FAILURE],
    },
  }),

  // insertUser
  insertUser: (payload, meta) => async (dispatch) => {
    const api = API_URLS.USER.insertUser(payload);
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      if (meta && meta.onSuccess) {
        // dispatch(actions.getUsers({ page: 1, page_size: 10 }));
        meta.onSuccess();
      }
    } else if (meta && meta.onError) {
      meta.onError(error);
    }
    return { response, error };
  },

  // reset password user
  resetPassword: (accountId, meta) => async () => {
    const api = API_URLS.USER.resetPassword(accountId);
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
    } else if (meta && meta.onError) {
      meta.onError(error);
    }
    return { response, error };
  },
};

const initialState = {
  isFetching: false,
  didInvalidate: true,
  lastUpdated: moment().unix(),
  items: [],
  query: {},
  meta: {
    page: 1,
    page_size: 10,
    total: 0,
  },
};

export const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case TYPE.GET_USERS:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          const { items, meta, query } = action.payload;
          draft.isFetching = false;
          draft.didInvalidate = false;
          draft.lastUpdated = moment.unix();
          draft.items = items;
          draft.meta = meta;
          draft.query = query;
        } else if (isFailedApiCall(action)) {
          draft.query = action.query;
          draft.isFetching = false;
          draft.didInvalidate = false;
        }
        break;
      case TYPE.GET_USERS_CHANGE_PAGE:
        draft.meta = action.payload;
        break;
      case TYPE.INVALIDATE_USERS:
        draft.didInvalidate = true;
        break;
      case TYPE.UPDATE_USER:
      case TYPE.ACTIVATE_DEACTIVATE_USER:
      case TYPE.DELETE_USER:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        }
        if (isSuccessfulApiCall(action)) {
          draft.isFetching = false;
          draft.didInvalidate = true;
          draft.lastUpdated = moment.unix();
        }
        if (isFailedApiCall(action)) {
          draft.isFetching = false;
          draft.didInvalidate = false;
        }
        break;
      default:
        return draft;
    }
  });
