import { Card, Col, Row, Typography } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { connect } from 'react-redux';

import Filter from './filter';
import common from '../../../config/common';
import { actions as dashboardActions } from '../../../redux/DashboardRedux';
import { ISODateParam } from '../../../utils/dateTime';

const { getStatisticOrders } = dashboardActions;
const { Text } = Typography;

const PieChart = {
  options: {
    chart: {
      width: 380,
      type: 'pie',
    },
    labels: [],
    responsive: [
      {
        breakpoint: 300,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  },
};

const OrdersDashboard = ({ isFetching, dataOrders, getStatisticOrders }) => {
  const [dateFrom, setDateFrom] = useState(moment().subtract(1, 'M'));
  const [dateTo, setDateTo] = useState(moment().format('YYYY-MM-DD'));

  useEffect(() => {
    const momentFrom = moment.isMoment(dateFrom) ? dateFrom : moment(dateFrom.replace('%2B', '+'));
    const momentTo = moment.isMoment(dateTo) ? dateTo : moment(dateTo.replace('%2B', '+'));
    getStatisticOrders({
      page: -1,
      page_size: -1,
      time_start: ISODateParam(momentFrom),
      time_end: ISODateParam(momentTo),
    });
  }, [dateFrom, dateTo]);

  const dataName = [];
  const dataNumberOfOrders = [];

  dataOrders?.forEach((element) => {
    dataName.push(element.status);
    dataNumberOfOrders.push(element.number_of_order);
  });

  const handleSearchData = (value) => {
    setDateFrom(value.fromDate);
    setDateTo(value.toDate);
  };

  return (
    <Card
      title={
        <>
          <Text>Thống kê số lượng đơn theo trạng thái đơn hàng</Text>
        </>
      }
      bordered
      loading={isFetching}
      extra={<Filter onSearch={handleSearchData} />}>
      <Row gutter={16}>
        <Col span={24} lg={24}>
          {dataOrders ? (
            <Chart
              options={{
                ...PieChart,
                // xaxis: {
                //   ...PieChart.xaxis,
                //   // labels: { show: true },
                //   categories: dataName,
                // },
                // title: { text: this.props.title },
                labels: dataName,
                legend: {
                  show: true,
                  formatter: (value) =>
                    common.orderStatus[value] ? common.orderStatus[value] : value,
                },
              }}
              series={dataNumberOfOrders}
              type="pie"
              height="auto"
              width="40%"
            />
          ) : (
            <div>Không có dữ liệu</div>
          )}
        </Col>
      </Row>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  isFetching: state.dashboardReducer?.orders?.sale?.isFetching,
  dataOrders: state.dashboardReducer?.orders?.sale?.data,
});

const mapDispatchToProps = (dispatch) => ({
  getStatisticOrders: (query) => {
    dispatch(getStatisticOrders(query));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersDashboard);
