import { LoginOutlined } from '@ant-design/icons';
import { Row, Col, Form, Input, Button, notification } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Card from '../../../components/Card';
import ROUTER from '../../../config/router';
import { actions as authActions } from '../../../redux/AuthRedux';

class ChangePassPage extends Component {
  formRef = React.createRef();
  componentDidMount() {
    this.formRef.current.setFieldsValue({
      OldPasswd: '',
      NewPasswd: '',
    });
  }
  // handleSubmit = e => {
  //   e.preventDefault();
  //   this.props.form.validateFields((error, values) => {
  //     if (!error) {
  //       this.props.changePassword(
  //         {
  //           ConfirmPasswd: values.ConfirmPasswd,
  //           NewPasswd: values.NewPasswd,
  //           OldPasswd: values.OldPasswd,
  //         },
  //         {
  //           onSuccess: () => {
  //             message.success('Update password succeeded');
  //             this.props.history.push(ROUTER.HOME);
  //           },
  //           onError: error => {
  //             message(`Update password failed - ${error}`;
  //           },
  //         },
  //       );
  //     }
  //   });
  // };
  onFinish = (values) => {
    const hasError = values.NewPasswd !== values.ConfirmPasswd;
    if (values.NewPasswd !== values.ConfirmPasswd) {
      this.formRef.current.setFields([
        {
          name: 'ConfirmPasswd',
          // // required: true,
          // value: valuePhone,
          errors: hasError ? ['Phải giống mật khẩu mới!'] : null,
        },
      ]);
    } else {
      this.props.changePassword(
        {
          // ConfirmPasswd: values.ConfirmPasswd,
          new_password: values.NewPasswd,
          old_password: values.OldPasswd,
        },
        {
          onSuccess: () => {
            notification.success({ message: 'Đổi mật khẩu thành công' });
            this.props.history.push(ROUTER.HOME);
          },
          onError: (error) => notification.error({ message: `${error} - Đổi mật khẩu thất bại` }),
        },
      );
    }
  };
  render() {
    return (
      <Row>
        <Col sm={{ span: 16, offset: 4 }} lg={{ span: 16, offset: 4 }}>
          <Card hoverable={false} hasShadow>
            <Form
              // onSubmit={this.handleSubmit} //remove antd v4
              ref={this.formRef}
              onFinish={this.onFinish}>
              <Form.Item
                label="Mật khẩu cũ"
                name="OldPasswd"
                rules={[
                  {
                    required: true,
                    message: `${'Nhập mật khẩu cũ'}!`,
                  },
                ]}>
                <Input type="password" placeholder={`${'Nhập mật khẩu'} ...`} />
              </Form.Item>
              <br />
              <Form.Item
                label="Mật khẩu mới"
                name="NewPasswd"
                rules={[
                  {
                    required: true,
                    message: `${'Nhập mật khẩu mới'}!`,
                  },
                ]}>
                <Input type="password" placeholder={`${'Nhập mật khẩu'} ...`} />
              </Form.Item>
              <br />
              <Form.Item
                label="Xác nhận mật khẩu"
                name="ConfirmPasswd"
                rules={[
                  {
                    required: true,
                    message: `${'Xác nhận mật khẩu mới'}!`,
                  },
                ]}>
                <Input type="password" placeholder={`${'Nhập mật khẩu'} ...`} />
              </Form.Item>
              <br />
              <Form.Item>
                <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                  <LoginOutlined />
                  Xác nhận
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  changePassword: (payload, meta) => dispatch(authActions.changePassword(payload, meta)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassPage);
