import lodash from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import SelectSearch from './Select';
import { actions as companyActions } from '../../redux/CompanyRedux';

const { getAllCompanies } = companyActions;

class CompanySearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prevSelectedValue: [],
      defaultValue: [],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.allCompanies && nextProps.allCompanies.length) {
      if (!lodash.isEqual(nextProps.selectedValue, prevState.prevSelectedValue)) {
        const nextState = { prevSelectedValue: nextProps.selectedValue };
        if (nextProps.selectedValue && nextProps.selectedValue.length) {
          const { selectedValue } = nextProps;
          if (typeof selectedValue === 'string') {
            nextState.defaultValue = CompanySearch.getCompanyByCode([selectedValue], nextProps);
          } else if (typeof selectedValue === 'object') {
            if (Array.isArray(selectedValue)) {
              if (typeof selectedValue[0] === 'string') {
                nextState.defaultValue = CompanySearch.getCompanyByCode(selectedValue, nextProps);
              } else if (typeof selectedValue[0] === 'object') {
                nextState.defaultValue = selectedValue;
              }
            } else {
              nextState.defaultValue = selectedValue;
            }
          }
        } else {
          nextState.defaultValue = [];
        }
        return nextState;
      }
    }
    return null;
  }

  componentDidMount() {
    this.props.getAllCompanies();
  }

  // searchProductByName = async (name) => {
  //   const params = {
  //     search: name,
  //     page: 1,
  //     page_size: 50,
  //   };
  // const api = API_URLS.PRODUCT.getFilteredProducts(params);
  //   const { response, error } = await apiCall(api);
  //   if (!error && response.status === 200) {
  //     if (response.data.data && response.data.data.length) {
  //       return response.data.data.map((product) => ({
  //         key: product.code,
  //         label: `${product.name}`,
  //         // defaultValue: response.data.data,
  //       }));
  //     }
  //   }

  //   return [];
  // };

  static getCompanyByCode = (codes, props) => {
    if (codes && codes.length) {
      const { allCompanies } = props;
      if (!allCompanies.length) {
        return [];
      }
      const defaultValue = [];
      codes.forEach((code) => {
        const company_code = code.trim();
        if (!company_code) return;
        const foundCompany = allCompanies.find((p) => p.code === company_code);
        const company = foundCompany
          ? {
              key: company_code,
              label: `${foundCompany.name}`,
            }
          : { key: company_code, label: `[Not found] ${company_code}` };
        defaultValue.push(company);
      });
      return defaultValue;
    }
    return [];
  };

  render() {
    const { allCompanies, type, onChange } = this.props;
    const dataSource = type ? allCompanies.filter((com) => com.type === type) : allCompanies;
    return (
      <SelectSearch
        {...this.props}
        onChange={onChange}
        selectedValue={this.state.defaultValue}
        dataSource={dataSource}
        placeholder={this.props.placeholder || 'Chọn công ty'}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  allCompanies: state.companyReducer.allItems,
});

const mapDispatchToProps = (dispatch) => ({
  getAllCompanies: () => dispatch(getAllCompanies()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanySearch);
