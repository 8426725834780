import produce from 'immer';
import moment from 'moment';

import { isCallingApi, isSuccessfulApiCall, isFailedApiCall } from './actionDedicate';
import { PREFIX, typesWithPrefix } from './config';
import { API_URLS } from '../config/api';
import { apiCall } from '../utils/api';
const _types = typesWithPrefix(PREFIX.DRIVER);
const TYPE = {
  INVALIDATE_DRIVERS: _types('INVALIDATE_DRIVERS'),
  GET_DRIVERS: _types('GET_DRIVERS'),
  GET_ALL_DRIVERS: _types('GET_ALL_DRIVERS'),
  UPDATE_DRIVER: _types('UPDATE_DRIVER'),
  DELETE_DRIVER: _types('DELETE_DRIVER'),
  GET_DRIVER_BY_ID: _types('GET_DRIVER_BY_ID'),
  GET_DRIVERS_CHANGE_PAGE: _types('GET_DRIVERS_CHANGE_PAGE'),
  ACTIVE_DRIVER_CODE: _types('ACTIVE_DRIVER_CODE'),
  DEACTIVE_DRIVER_CODE: _types('DEACTIVE_DRIVER_CODE'),
  CHANGE_ACTIVE_DRIVER: _types('CHANGE_ACTIVE_DRIVER'),
};

export const actions = {
  //get list driver
  getDriversIfNeed: (filterOption) => (dispatch, getState) => {
    const state = getState();
    const isFetching = state.driverReducer.isFetching;
    const didInvalidate = state.driverReducer.didInvalidate;
    const query = state.driverReducer.query;
    const isQueryChanged =
      query.search !== filterOption.search ||
      query.gender !== filterOption.gender ||
      query.status !== filterOption.status;
    if ((!isFetching && didInvalidate) || (!isFetching && isQueryChanged)) {
      dispatch(actions.getDrivers(filterOption));
    }
  },

  getDrivers: (query) => async (dispatch) => {
    const params = {
      page: query.page,
      page_size: query.page_size,
      search: query.search,
      gender: query.gender,
      status: query.status,
    };
    const api = API_URLS.DRIVER.getDriversMobileApp(params);
    dispatch(actions.gettingDrivers());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(
        actions.getDriversSuccess({
          items: response.data.data,
          meta: {
            page: response.data.page,
            page_size: response.data.page_size,
            total: response.data.total,
          },
          query: {
            search: params.search,
            gender: params.gender,
            status: params.status,
          },
        }),
      );
    } else {
      dispatch(actions.getDriversFailure(params));
    }
  },
  gettingDrivers: () => ({
    type: TYPE.GET_DRIVERS,
    meta: { prefix: [PREFIX.DRIVER, PREFIX.API_CALLING] },
  }),

  getDriversSuccess: (payload) => ({
    type: TYPE.GET_DRIVERS,
    payload,
    meta: {
      prefix: [PREFIX.DRIVER, PREFIX.API_CALLED_SUCCESS],
    },
  }),

  getDriversFailure: (query) => ({
    type: TYPE.GET_DRIVERS,
    query,
    meta: {
      prefix: [PREFIX.DRIVER, PREFIX.API_CALLED_FAILURE],
    },
  }),

  invalidateDrivers: () => ({
    type: TYPE.INVALIDATE_DRIVERS,
    meta: {
      prefix: [PREFIX.DRIVER, PREFIX.API_CALLED_SUCCESS],
    },
  }),

  // get driver by id
  getDriverById: (driverId, meta) => async (dispatch) => {
    dispatch(actions.gettingDriverDetail());
    const api = API_URLS.DRIVER.getDriverById(driverId);
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      // const data = response.data.data;
      dispatch(actions.getDriverDetailSuccess(response.data.data));
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
    } else {
      dispatch(actions.getDriverDetailFailure());
      if (meta && meta.onError) {
        meta.onError(error);
      }
    }
  },
  gettingDriverDetail: () => ({
    type: TYPE.GET_DRIVER_BY_ID,
    meta: { prefix: [PREFIX.DRIVER, PREFIX.API_CALLING] },
  }),
  getDriverDetailSuccess: (payload) => ({
    type: TYPE.GET_DRIVER_BY_ID,
    meta: { prefix: [PREFIX.DRIVER, PREFIX.API_CALLED_SUCCESS] },
    payload,
  }),
  getDriverDetailFailure: () => ({
    type: TYPE.GET_DRIVER_BY_ID,
    meta: { prefix: [PREFIX.DRIVER, PREFIX.API_CALLED_FAILURE] },
  }),

  //GET ALL DRIVERS
  getAllDrivers: () => async (dispatch, getState) => {
    const state = getState();
    const isFetching = state.driverReducer.isFetchingAllDriver;
    if (isFetching) {
      return;
    }

    const params = {
      page: -1,
      page_size: -1,
    };
    const api = API_URLS.DRIVER.getDrivers(params);
    dispatch(actions.gettingAllDrivers());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(
        actions.getAllDriversSuccess({
          items: response.data.data,
        }),
      );
    } else {
      dispatch(actions.getAllDriversFailure(params));
    }
  },
  gettingAllDrivers: () => ({
    type: TYPE.GET_ALL_DRIVERS,
    meta: { prefix: [PREFIX.DRIVER, PREFIX.API_CALLING] },
  }),

  getAllDriversSuccess: (payload) => ({
    type: TYPE.GET_ALL_DRIVERS,
    payload,
    meta: {
      prefix: [PREFIX.DRIVER, PREFIX.API_CALLED_SUCCESS],
    },
  }),

  getAllDriversFailure: (query) => ({
    type: TYPE.GET_ALL_DRIVERS,
    query,
    meta: {
      prefix: [PREFIX.DRIVER, PREFIX.API_CALLED_FAILURE],
    },
  }),

  // update driver
  updatingDriver: () => ({
    type: TYPE.UPDATE_DRIVER,
    meta: { prefix: [PREFIX.DRIVER, PREFIX.API_CALLING] },
  }),
  updateDriverSuccess: () => ({
    type: TYPE.UPDATE_DRIVER,
    meta: {
      prefix: [PREFIX.DRIVER, PREFIX.API_CALLED_SUCCESS],
    },
  }),
  updateDriverFailure: () => ({
    type: TYPE.UPDATE_DRIVER,
    meta: {
      prefix: [PREFIX.DRIVER, PREFIX.API_CALLED_FAILURE],
    },
  }),
  updateDriver: (driverId, payload, meta) => async (dispatch) => {
    const api = API_URLS.DRIVER.updateDriver(driverId, payload);
    dispatch(actions.updatingDriver());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(actions.updateDriverSuccess(response.data));
      dispatch(actions.getDrivers({ page: 1, page_size: 10 }));
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
    } else {
      dispatch(actions.updateDriverFailure(error));
      if (meta && meta.onError) {
        meta.onError(error);
      }
    }
  },

  // Delete driver
  deletingDriver: () => ({
    type: TYPE.DELETE_DRIVER,
    meta: { prefix: [PREFIX.DRIVER, PREFIX.API_CALLING] },
  }),

  deleteDriverSuccess: () => ({
    type: TYPE.DELETE_DRIVER,
    meta: {
      prefix: [PREFIX.DRIVER, PREFIX.API_CALLED_SUCCESS],
    },
  }),

  deleteDriverFailure: () => ({
    type: TYPE.DELETE_DRIVER,
    meta: {
      prefix: [PREFIX.DRIVER, PREFIX.API_CALLED_FAILURE],
    },
  }),

  deleteDriver: (driverId, meta) => async (dispatch) => {
    const api = API_URLS.DRIVER.deleteDriver(driverId);
    dispatch(actions.deletingDriver());
    const { response, error } = await apiCall({ ...api });
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(actions.deleteDriverSuccess(response.data));
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
    } else {
      dispatch(actions.deleteDriverFailure(error));
      if (meta && meta.onError) {
        meta.onError(error);
      }
    }
  },

  // insert driver
  insertDriver: (payload, meta) => async (dispatch) => {
    const api = API_URLS.DRIVER.insertDriver(payload);
    const params = {
      page: 1,
      page_size: 10,
      search: '',
    };
    const { response, error } = await apiCall(api).catch((result) => result);
    if (!error && (response.status === 200 || response.status === 201)) {
      if (meta && meta.onSuccess) {
        meta.onSuccess();
        dispatch(actions.getDrivers(params));
      }
    } else if (meta && meta.onError) {
      meta.onError(error);
    }
    return { response, error };
  },

  insertManyDriver: (payload, meta) => async (dispatch) => {
    const api = API_URLS.DRIVER.insertManyDriver(payload);
    const params = { page: 1, pageSize: 10, search: '' };
    const { response, error } = await apiCall(api);
    if (!error && (response.status === 200 || response.status === 201)) {
      if (meta && meta.onSuccess) {
        meta.onSuccess(response);
        dispatch(actions.getDrivers(params));
      }
    } else if (meta && meta.onError) meta.onError(error);

    return { response, error };
  },

  changePage: (page, page_size, total) => ({
    type: TYPE.GET_DRIVERS_CHANGE_PAGE,
    payload: {
      page,
      page_size,
      total,
    },
    meta: {
      prefix: [PREFIX.DRIVER],
    },
  }),

  // active deactive secretcode
  activeSecretDriveCode: (driverCode, meta) => async (dispatch) => {
    const api = API_URLS.DRIVER.activeDriverMobileApp(driverCode);
    dispatch(actions.activingDriveCode());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(actions.activeDriveCodeSuccess(response.data));
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
    } else {
      dispatch(actions.activeDriveCodeFailure(error));
      if (meta && meta.onError) {
        meta.onError(error);
      }
    }
  },
  activingDriveCode: () => ({
    type: TYPE.ACTIVE_DRIVER_CODE,
    meta: { prefix: [PREFIX.ACCOUNT, PREFIX.API_CALLING] },
  }),

  activeDriveCodeSuccess: () => ({
    type: TYPE.ACTIVE_DRIVER_CODE,
    meta: {
      prefix: [PREFIX.ACCOUNT, PREFIX.API_CALLED_SUCCESS],
    },
  }),

  activeDriveCodeFailure: () => ({
    type: TYPE.ACTIVE_DRIVER_CODE,
    meta: {
      prefix: [PREFIX.ACCOUNT, PREFIX.API_CALLED_FAILURE],
    },
  }),

  deactiveSecretCode: (userCode, meta) => async (dispatch) => {
    const api = API_URLS.DRIVER.deactiveDriverMobileApp(userCode);
    dispatch(actions.deactivingDriveCode());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(actions.deactiveDriveCodeSuccess(response.data));
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
    } else {
      dispatch(actions.deactiveDriveCodeFailure(error));
      if (meta && meta.onError) {
        meta.onError(error);
      }
    }
  },
  deactivingDriveCode: () => ({
    type: TYPE.DEACTIVE_DRIVER_CODE,
    meta: { prefix: [PREFIX.ACCOUNT, PREFIX.API_CALLING] },
  }),

  deactiveDriveCodeSuccess: () => ({
    type: TYPE.DEACTIVE_DRIVER_CODE,
    meta: {
      prefix: [PREFIX.ACCOUNT, PREFIX.API_CALLED_SUCCESS],
    },
  }),

  deactiveDriveCodeFailure: () => ({
    type: TYPE.DEACTIVE_DRIVER_CODE,
    meta: {
      prefix: [PREFIX.ACCOUNT, PREFIX.API_CALLED_FAILURE],
    },
  }),

  changeActiveDriver: (payload, meta) => async (dispatch) => {
    const api = API_URLS.DRIVER.changeStateDriver(payload);
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200) {
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
    } else {
      if (meta && meta.onError) {
        meta.onError(error);
      }
    }
  },

  // changingDriverActive: () => ({
  //   type: TYPE.CHANGE_ACTIVE_DRIVER,
  //   meta: { prefix: [PREFIX.ACCOUNT, PREFIX.API_CALLING] },
  // }),

  // changeDriverActiveSuccess: () => ({
  //   type: TYPE.CHANGE_ACTIVE_DRIVER,
  //   meta: {
  //     prefix: [PREFIX.ACCOUNT, PREFIX.API_CALLED_SUCCESS],
  //   },
  // }),

  // changeDriverActiveFailure: () => ({
  //   type: TYPE.CHANGE_ACTIVE_DRIVER,
  //   meta: {
  //     prefix: [PREFIX.ACCOUNT, PREFIX.API_CALLED_FAILURE],
  //   },
  // }),
};

const initialState = {
  isFetching: false,
  isFetchingAllDriver: false,
  didInvalidate: true,
  lastUpdated: moment().unix(),
  currentDriver: {},
  allItems: [],
  items: [],
  query: {},
  meta: {
    total: 0,
    page: 1,
    page_size: 10,
  },
};

export const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case TYPE.GET_DRIVERS:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          const { items, meta, query } = action.payload;
          draft.isFetching = false;
          draft.didInvalidate = false;
          draft.lastUpdated = moment.unix();
          draft.items = items;
          draft.meta = meta;
          draft.query = query;
        } else if (isFailedApiCall(action)) {
          draft.isFetching = false;
          draft.didInvalidate = false;
        }
        break;
      case TYPE.GET_DRIVER_BY_ID:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          draft.currentDriver = action.payload;
          draft.isFetching = false;
        } else if (isFailedApiCall(action)) {
          draft.isFetching = false;
          draft.didInvalidate = false;
        }
        break;
      case TYPE.INVALIDATE_DRIVERS:
        draft.allItems = [];
        draft.didInvalidate = true;
        break;
      case TYPE.GET_DRIVERS_CHANGE_PAGE:
        draft.meta = {
          page: action.payload.page,
          page_size: action.payload.page_size,
          total: action.payload.total,
        };
        break;
      case TYPE.GET_ALL_DRIVERS:
        if (isCallingApi(action)) {
          draft.isFetchingAllDriver = true;
        } else if (isSuccessfulApiCall(action)) {
          const { items } = action.payload;
          draft.isFetchingAllDriver = false;
          draft.didInvalidate = false;
          draft.allItems = items;
        } else if (isFailedApiCall(action)) {
          draft.isFetchingAllDriver = false;
          draft.didInvalidate = false;
        }
        break;
      case TYPE.UPDATE_DRIVER:
      case TYPE.DELETE_DRIVER:
      case TYPE.ACTIVE_DRIVER_CODE:
      case TYPE.DEACTIVE_DRIVER_CODE:
      case TYPE.CHANGE_ACTIVE_DRIVER:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        }
        if (isSuccessfulApiCall(action)) {
          draft.allItems = []; // so that we'll call it again
          draft.isFetching = false;
          draft.didInvalidate = true;
          draft.lastUpdated = moment.unix();
        }
        if (isFailedApiCall(action)) {
          draft.isFetching = false;
          draft.didInvalidate = false;
        }
        break;

      default:
        return draft;
    }
  });
