import { Input, Row, Col } from 'antd';
import React, { Component } from 'react';

import WithLocale from '../../../../hoc/WithLocale';

const { Search } = Input;

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataFilter: {
        search: '',
      },
    };
  }

  onSearch = (value) => {
    const { dataFilter } = this.state;
    dataFilter.search = value;
    this.setState({
      dataFilter,
    });
    this.props.onSearch(dataFilter);
  };

  render() {
    return (
      <Row gutter={16}>
        <Col span={6} lg={6} style={{ paddingLeft: 0 }}>
          <Search
            onSearch={this.onSearch}
            style={{ width: 300 }}
            placeholder="Nhập tên hoặc mã để tìm kiếm"
            enterButton
          />
        </Col>
      </Row>
    );
  }
}

export default WithLocale(Filter);
