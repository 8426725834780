import { Table, Tooltip } from 'antd';
import moment from 'moment';
import React from 'react';

const column = [
  {
    title: <b>Kho</b>,
    dataIndex: 'storage_name',
    key: 'storage_name',
    className: 'hidden-md',
    width: '15%',
  },
  {
    title: <b>Tên sản phẩm</b>,
    dataIndex: 'product_name',
    key: 'product_name',
    width: '20%',
  },
  // {
  //   title: <b>Mã phụ</b>,
  //   dataIndex: 'product_secondary_code',
  //   key: 'product_secondary_code',
  //   width: '10%',
  // },
  {
    title: (
      <Tooltip placement="top" title="Số lượng còn lại">
        <b>Tồn kho</b>
      </Tooltip>
    ),
    dataIndex: 'total_quantity',
    key: 'total_quantity',
    align: 'right',
    // width: '10%',
  },
  {
    title: (
      <Tooltip placement="top" title="Số lượng chưa giao">
        <b>Chưa giao</b>
      </Tooltip>
    ),
    dataIndex: 'total_ordered',
    key: 'total_ordered',
    align: 'right',
    render: (value) => value || 0,
  },
  {
    title: (
      <Tooltip placement="top" title="Số lượng đang giao">
        <b>Đang giao</b>
      </Tooltip>
    ),
    dataIndex: 'total_shipping',
    key: 'total_shipping',
    align: 'right',
    render: (value) => value || 0,
  },
  {
    title: (
      <Tooltip placement="top" title="Số lượng đã giao thành công">
        <b>Đã giao</b>
      </Tooltip>
    ),
    dataIndex: 'total_shipped',
    key: 'total_shipped',
    align: 'right',
    render: (value) => value || 0,
  },
  {
    title: (
      <Tooltip placement="top" title="Số lượng thực tế còn lại trong kho">
        <b>Thực còn</b>
      </Tooltip>
    ),
    dataIndex: 'total_ordered',
    key: 'total_ordered',
    align: 'right',
    render: (value, record) =>
      record.total_quantity - (record.total_shipping || 0) - (record.total_shipped || 0),
  },
  {
    title: (
      <Tooltip placement="top" title="Số lượng chờ trả lại từ khách hàng">
        <b>Chờ trả lại</b>
      </Tooltip>
    ),
    dataIndex: 'total_returned',
    key: 'total_returned',
    align: 'right',
    render: (value) => value || 0,
  },
];

const expandcolumns = (unit) => [
  {
    title: <b>Ngày sản xuất</b>,
    dataIndex: 'manufacturing_date',
    key: 'manufacturing_date',
    width: '250px',
    span: 2,
    render: (value, record) => (value ? moment(value).format('DD/MM/YYYY') : 'N/A'),
  },
  {
    title: <b>Ngày hết hạn</b>,
    dataIndex: 'expiry_date',
    key: 'expiry_date',
    width: '250px',
    span: 2,
    render: (value, record) => (value ? moment(value).format('DD/MM/YYYY') : 'N/A'),
  },
  {
    title: <b>Tồn kho</b>,
    dataIndex: 'quantity',
    key: 'quantity',
    align: 'right',
    width: '150px',
    span: 2,
  },
  // {
  //   title: <b>Giá</b>,
  //   dataIndex: 'price',
  //   key: 'price',
  //   className: 'hidden-md',
  //   align: 'right',
  //   width: '150px',
  //   span: 5,
  //   render: (value) =>
  //     `${numberToVnd(value)}${
  //       unit ? `/${typeof unit === 'object' ? unit.primary : unit}` : ''
  //     }`,
  // },
];

const WareHouseList = ({ data, meta, onPageChange, isFetching }) => {
  const expandedRowRender = (record) => (
    <div className="expand-detail-summary-barcode">
      <Table
        size="middle"
        dataSource={record?.summary_lot_numbers}
        columns={expandcolumns(record.unit)}
        pagination={false}
        rowKey="lot_number"
        style={{ marginLeft: '30px', maxWidth: '650px' }}
      />
    </div>
  );
  return (
    <>
      <Table
        loading={isFetching}
        columns={column}
        dataSource={data}
        rowKey={(record) => record?.product_code}
        expandedRowRender={(record) => expandedRowRender(record)}
        pagination={{
          current: meta?.page,
          pageSize: meta?.pageSize || 10,
          total: meta?.total,
          onChange: onPageChange,
          showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total}`,
        }}
      />
    </>
  );
};

export default WareHouseList;
