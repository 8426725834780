import { CheckOutlined } from '@ant-design/icons';
import { Form, Input, Modal, notification, Button, Row, Col } from 'antd';
import React from 'react';
class FeatureForm extends React.Component {
  state = {};

  onFinish = (values) => {
    const { name, description } = values;
    this.props.onCreateFeature({ name, description });
  };

  onUpdateFeature = (values) => {
    const { name, description } = values;
    this.props.onEditFeature(this.props.editFeature.code, { name, description });
  };

  onFinishFailed = () => {
    notification.warning({ message: 'Không được để trống !!!' });
  };

  render() {
    const { visibleFeature, onToggleModalFeature, editFeature } = this.props;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
      },
    };

    return (
      <Modal
        title={editFeature?.code ? 'Cập nhập đặc tính' : 'Thêm đặc tính mới'}
        visible={visibleFeature}
        onOk={editFeature?.code ? this.onUpdateFeature : this.onFinish}
        onCancel={onToggleModalFeature}
        destroyOnClose
        footer={<></>}>
        <Row>
          <Col span={22} offset={1}>
            <Form
              {...formItemLayout}
              onFinish={editFeature && editFeature.code ? this.onUpdateFeature : this.onFinish}
              onFinishFailed={this.onFinishFailed}
              initialValues={{
                name: editFeature && editFeature.code ? editFeature.name : '',
                description: editFeature && editFeature.code ? editFeature.description : '',
              }}>
              <Form.Item
                name="name"
                label="Tên"
                rules={[{ required: true, message: 'Nhập đặc tính' }]}>
                <Input placeholder="Nhập đặc tính" />
              </Form.Item>
              <Form.Item name="description" label="Mô tả">
                <Input placeholder="Nhập mô tả" />
              </Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                shape="round"
                style={{
                  width: '20%',
                  marginLeft: '180px',
                  marginTop: '10px',
                }}>
                <CheckOutlined />
                Lưu
              </Button>
            </Form>
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default FeatureForm;
