import { Button, Col, DatePicker, Row, Select, notification } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import ProductForm from './AddForm/ProductForm';
import ROUTER from '../../config/router';
import { actions as InventoryVoucherActions } from '../../redux/InventoryRecordVoucherRedux';
import { actions as StorageActions } from '../../redux/StorageRedux';
import { actions as wareHouseActions } from '../../redux/WareHousesRedux';

const { getStorage, getStorageById } = StorageActions;
const { getInvetory } = wareHouseActions;
const { addInventoryRecordVoucher } = InventoryVoucherActions;

const AddInventoryVoucher = ({
  getStorage,
  storageData,
  getInvetory,
  wareHouseData,
  addInventoryRecordVoucher,
  history,
}) => {
  const [invetoryTime, setInvetoryTime] = useState(moment().toISOString());
  const [storageCode, setStorageCode] = useState();
  const [note, setNote] = useState('');
  const [submitData, setSubmitData] = useState();

  useEffect(() => {
    getStorage({
      page: -1,
      page_size: -1,
    });

    getInvetory({
      page: -1,
      page_size: -1,
    });
  }, []);

  useEffect(() => {
    if (storageData?.length && !storageCode) {
      setStorageCode(storageData[0].code);
    }
  }, [storageData]);

  const handleChangeStorageCode = (value) => {
    setStorageCode(value);
  };

  const handleChangeInventoryTime = (value) => {
    setInvetoryTime(moment(value).toISOString());
  };

  const handleChangeNote = (value) => {
    setNote(value.target.value);
  };

  const getData = (product) => {
    const curData = [];

    product?.map((value, index) => {
      curData.push({
        product_code: value?.product_code,
        product_name: value?.product_name,
        product_secondary_code: value?.product_secondary_code,
        lot_details: value?.summary_lot_numbers?.map((itemlv2) => ({
          expiry_date: itemlv2.expiry_date || null,
          lot_number: itemlv2.lot_number || 0,
          manufacturing_date: itemlv2.manufacturing_date || null,
          real_quantity: itemlv2?.real_quantity,
        })),
        unit: {
          factor: value?.unit?.factor,
          primary_unit: value?.unit?.primary_unit,
          secondary_unit: value?.unit?.secondary_unit,
        },
      });
    });

    setSubmitData(curData);
  };

  const handleSubmit = () => {
    if (!storageCode) {
      notification.error({ message: 'Hãy chọn kho!' });
      return;
    }
    if (!submitData?.length) {
      notification.error({ message: 'Hãy chọn sản phẩm!' });
      return;
    }
    const curStorage = storageData?.length
      ? storageData.find((item) => item.code === storageCode)
      : null;
    const payload = {
      inventory_records: submitData,
      note,
      record_time: invetoryTime,
      storage_code: storageCode,
      storage_level: curStorage?.level,
      storage_name: curStorage?.name,
    };
    addInventoryRecordVoucher(payload, {
      onSuccess: () => {
        notification.success({ message: 'Thêm phiếu kiểm kê thành công!' });
        history.push(ROUTER.INVENTORY.INVENTORY_RECORD);
      },
      onError: (mes) => {
        notification.error({ message: 'Thêm phiếu kiểm kê thất bại!' });
      },
    });
  };

  return (
    <>
      <h2>Tạo phiếu Kiểm kê mới</h2>
      <Row gutter={15}>
        <Col span={8} style={{ marginTop: 10, marginBottom: 20 }}>
          <h3 style={{ marginBottom: 10 }}>Chọn kho</h3>
          <Select
            placeholder="Chọn kho"
            value={storageCode}
            onChange={handleChangeStorageCode}
            style={{ width: '99%', float: 'right' }}>
            {storageData?.map((storage) => (
              <Select.Option key={storage?.code} value={storage?.code}>
                {storage?.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={8} style={{ marginTop: 10, marginBottom: 20 }}>
          <h3 style={{ marginBottom: 10 }}>Ngày kiểm kê</h3>
          <DatePicker
            style={{ width: '100%' }}
            onChange={handleChangeInventoryTime}
            placeholder="Chọn ngày kiểm kê"
            defaultValue={moment()}
            format="DD-MM-YYYY"
            allowClear={false}
          />
        </Col>
      </Row>
      <ProductForm wareHouseProducts={wareHouseData} sendData={getData} />
      <Row gutter={15}>
        <Col span={24} style={{ marginTop: 10 }}>
          <TextArea
            placeholder="Ghi chú"
            onChange={handleChangeNote}
            // value={this.state.note}
          />
        </Col>
      </Row>
      <Row gutter={15}>
        <Col lg={8} />
        <Col lg={16}>
          <Button
            type="primary"
            shape="round"
            style={{ width: '30%', marginLeft: '10%', marginTop: '30px' }}
            onClick={handleSubmit}
            disabled={!storageCode || !submitData?.length}>
            Tạo phiếu kiểm kê
          </Button>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  storageData: state?.storageReducer?.Storage?.data,
  //   isFetching: state?.productReducer?.isFetching,
  wareHouseData: state?.wareHousesReducer?.data,
});

const mapDispatchToProps = (dispatch) => ({
  getStorage: (params) => dispatch(getStorage(params)),
  getStorageById: (id) => dispatch(getStorageById(id)),
  addInventoryRecordVoucher: (payload, meta) =>
    dispatch(addInventoryRecordVoucher({ payload, meta })),
  getInvetory: (params) => dispatch(getInvetory(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddInventoryVoucher);
