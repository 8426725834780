import * as tt from '@tomtom-international/web-sdk-maps';
import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import '@tomtom-international/web-sdk-maps/dist/maps.css';
import './Tomtom.css';

function App(props) {
  const { onAddDriverMakers, onAddOrderMakers, depot } = props;
  const mapElement = useRef();
  const [map, setMap] = useState({});
  const [longitude, setLongitude] = useState(105.85387423256537);
  const [latitude, setLatitude] = useState(21.027961928464592);
  const [curOrderMarkers, setCurOrderMarkers] = useState([]);
  const [curDriverMarker, setCurDriverMarker] = useState([]);

  useEffect(() => {
    const initMap = tt.map({
      key: process.env.REACT_APP_TOM_TOM_API_KEY,
      container: mapElement.current,
      center: [longitude, latitude],
      zoom: 12,
      language: 'vn',
    });
    setMap(initMap);
    return () => initMap.remove();
  }, []);

  useEffect(() => {
    if (!map || !map.options) return;

    //For create many driver markers
    // const addDriverMarkers = () => {
    //   if (onAddDriverMakers.length) {
    //     const length = onAddDriverMakers.length;
    // setZoom(10);
    //     if (onAddDriverMakers.length !== driverQuantity) {
    //       setDriverQuantity(length);
    //       setLongitude(onAddDriverMakers[length - 1].lng);
    //       setLatitude(onAddDriverMakers[length - 1].lat);
    //     }
    //     onAddDriverMakers.map((item) => {
    //       const markerElement = document.createElement('div');
    //       markerElement.className = 'marker';

    //       const markerContentElement = document.createElement('div');
    //       markerContentElement.className = 'marker-content';
    //       markerContentElement.style.backgroundColor = '#363636';
    //       markerElement.appendChild(markerContentElement);

    //       //Number marker
    //       // const markerTextElement = document.createElement('p');
    //       // const text = document.createTextNode('0');
    //       // markerTextElement.className = 'marker-text';
    //       // markerTextElement.appendChild(text);
    //       // markerElement.appendChild(markerTextElement);

    //       const iconElement = document.createElement('div');
    //       iconElement.className = 'marker-icon';
    //       iconElement.style.backgroundImage = `url(https://i.imgur.com/oz3r3Wq.png)`;
    //       markerContentElement.appendChild(iconElement);

    //       const marker = new tt.Marker({
    //         draggable: false,
    //         element: markerElement,
    //         anchor: 'bottom',
    //       })
    //         .setLngLat([item.lng, item.lat])
    //         .addTo(map);
    //     });
    //   }
    // };

    //Create driver marker
    const removeAllDriverMarkers = () => {
      curDriverMarker.map((item) => item.remove());
    };
    const addDriverMarkers = () => {
      const tempDriverMarkers = [];

      const markerElement = document.createElement('div');
      markerElement.className = 'marker';

      const markerContentElement = document.createElement('div');
      markerContentElement.className = 'marker-content';
      markerContentElement.style.backgroundColor = '#363636';
      markerElement.appendChild(markerContentElement);
      if (onAddDriverMakers.length === 1) {
        const iconElement = document.createElement('div');
        iconElement.className = 'marker-icon';
        iconElement.style.backgroundImage = `url(https://i.imgur.com/oz3r3Wq.png)`;
        markerContentElement.appendChild(iconElement);
      } else {
        const markerTextElement = document.createElement('p');
        const text = document.createTextNode(onAddDriverMakers.length);
        markerTextElement.className =
          onAddDriverMakers.length > 9 ? 'marker-text-many' : 'marker-text';
        markerTextElement.appendChild(text);
        markerElement.appendChild(markerTextElement);
      }
      if (onAddDriverMakers.length) {
        const marker = new tt.Marker({
          draggable: false,
          element: markerElement,
          anchor: 'bottom',
        })
          .setLngLat([depot?.longitude || longitude, depot?.latitude || latitude])
          .addTo(map);
        tempDriverMarkers.push(marker);
        setCurDriverMarker(tempDriverMarkers);
        map.jumpTo({ center: [depot?.longitude || null, depot?.latitude || null] });
      } else {
        map.jumpTo({ center: [longitude, latitude] });
      }
    };
    removeAllDriverMarkers();
    addDriverMarkers();

    //Create order markers
    const removeAllOrderMarkers = () => {
      curOrderMarkers.map((item) => item.remove());
    };

    const addOrderMarkers = () => {
      const tempOrderMarkers = [];
      onAddOrderMakers.map((item) => {
        const marker = new tt.Marker({
          draggable: false,
          anchor: 'bottom',
        })
          .setLngLat([item?.lng, item?.lat])
          .addTo(map);
        tempOrderMarkers.push(marker);
        setCurOrderMarkers(tempOrderMarkers);
        map.jumpTo({ center: [item?.lng, item?.lat] });
      });
    };
    removeAllOrderMarkers();
    addOrderMarkers();
  }, [map, longitude, latitude, onAddDriverMakers.length, onAddOrderMakers.length]);

  return (
    <>
      {map && (
        <div className="app">
          <div ref={mapElement} className="map" />
        </div>
      )}
    </>
  );
}

export default App;
