export const PREFIX = {
  AUTH: 'AUTH',
  APP: 'APP',
  ACCOUNT: 'ACCOUNT',
  API: 'API',
  API_CALLING: 'API_CALLING',
  API_CALLED_SUCCESS: 'API_CALLED_SUCCESS',
  API_CALLED_FAILURE: 'API_CALLED_FAILURE',
  ROLE: 'ROLE',
  NOTIFICATION: 'NOTIFICATION',
  PROMOTION: 'PROMOTION',
  COMPANY: 'COMPANY',
  VEHICLE: 'VEHICLE',
  VEHICLE_PART: 'VEHICLE_PART',
  VEHICLE_EXPIRY: 'VEHICLE_EXPIRY',
  VEHICLE_BILLING: 'VEHICLE_BILLING',
  DRIVER: 'DRIVER',
  CUSTOMER: 'CUSTOMER',
  ORDER: 'ORDER',
  FEATURE: 'FEATURE',
  CONFIGURATION: 'CONFIGURATION',
  TOMTOM: 'TOMTOM',
  ROUTING: 'ROUTING',
  SAMPLE_ROUTE: 'SAMPLE_ROUTE',
  DASHBOARD: 'DASHBOARD',
  STORAGE: 'STORAGE',
  CATEGORY: 'CATEGORY',
  UNIT: 'UNIT',
  PRODUCT: 'PRODUCT',
  CANCEL_VOUCHER: 'CANCEL_VOUCHER',
  DELIVERY_VOUCHER: 'DELIVERY_VOUCHER',
  INVENTORY: 'INVENTORY',
  RECEIVING_VOUCHER: 'RECEIVING_VOUCHER',
  CATEGORIES: 'CATEGORIES',
  SECRET_KEY: 'SECRET_KEY',
};

export const typesWithPrefix = (prefix) => (key) => `${prefix}_${key}`;
