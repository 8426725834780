import { Card, Col, Row, Typography } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { connect } from 'react-redux';

import { barChartOption } from './barChartOptions';
import Filter from './filter';
import { actions as dashboardActions } from '../../../redux/DashboardRedux';
import { ISODateParam } from '../../../utils/dateTime';

const { Text } = Typography;
const { getStatisticDrivingTime } = dashboardActions;

const DrivingTimeDashboard = ({ getStatisticDrivingTime, isFetching, dataDrivingTime }) => {
  // const [driverCodes, setDriverCodes] = useState([]);
  const [dateFrom, setDateFrom] = useState(moment().subtract(1, 'M'));
  const [dateTo, setDateTo] = useState(moment());
  const [driverCodes, setDriverCodes] = useState();

  useEffect(() => {
    const momentFrom = moment.isMoment(dateFrom) ? dateFrom : moment(dateFrom);
    const momentTo = moment.isMoment(dateTo) ? dateTo : moment(dateTo);
    getStatisticDrivingTime({
      page: -1,
      page_size: -1,
      time_start: ISODateParam(momentFrom),
      time_end: ISODateParam(momentTo),
      driver_codes: driverCodes,
    });
  }, [dateFrom, dateTo, driverCodes]);

  // const [page, setPage] = useState(1);

  const dataName = [];
  const dataTotalTime = [];

  dataDrivingTime?.forEach((element) => {
    dataName.push(element.driver_name);
    dataTotalTime.push(element.total_time);
  });

  const handleSearchData = (value) => {
    setDateFrom(value.fromDate);
    setDateTo(value.toDate);
    setDriverCodes(value?.driverCode?.join());
  };

  return (
    <Card
      title={
        <>
          <Text>Thống kê thời gian di chuyển từng lái xe</Text>
        </>
      }
      bordered
      loading={isFetching}
      extra={<Filter onSearch={handleSearchData} />}>
      <Row gutter={16}>
        <Col span={24} lg={24}>
          {dataDrivingTime ? (
            <Chart
              options={{
                ...barChartOption,
                stacked: false,
                xaxis: {
                  ...barChartOption.xaxis,
                  categories: dataName,
                  labels: {
                    formatter: (val) => `${Math.round(val / 3600)} giờ`,
                  },
                },
                // title: { text: 'thời gian' },
              }}
              series={[{ data: dataTotalTime, name: 'Thời gian' }]}
              type="bar"
              height="auto"
              width="100%"
            />
          ) : (
            <div>Không có dữ liệu</div>
          )}
        </Col>
      </Row>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  isFetching: state.dashboardReducer?.drvingTime?.sale?.isFetching,
  dataDrivingTime: state.dashboardReducer?.drvingTime?.sale?.data,
});

const mapDispatchToProps = (dispatch) => ({
  getStatisticDrivingTime: (query) => {
    dispatch(getStatisticDrivingTime(query));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DrivingTimeDashboard);
