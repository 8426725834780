import { DeleteOutlined } from '@ant-design/icons';
import { Input, Button, Table } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';

import { ISODateTimeParam } from '../../../utils/dateTime';
import { isBelowBreakpoint } from '../../../utils/window';

// const formatter = new Intl.NumberFormat('vi-VN', {
//   style: 'currency',
//   currency: 'VND',
//   minimumFractionDigits: 0,
// });

const columns = (
  onChangeQuantity,
  onRemoveItem,
  onChangeExpiration,
  onAddItem,
  state,
  ware,
  storageCode,
  listOfProduct,
) => [
  {
    title: () => <b>Mặt hàng</b>,
    key: 'product_name',
    dataIndex: 'product_name',
    width: '30%',
  },
  // {
  //   title: () => <b>Mã phụ</b>,
  //   key: 'product_secondary_code',
  //   dataIndex: 'product_secondary_code',
  //   width: '10%',
  // },
  {
    title: <b>Tồn kho</b>,
    key: 'product_code',
    dataIndex: 'product_code',
    width: '15%',
    render: (value, record) => {
      const a = ware?.find((values) => values.product_code === value);
      return <div>{a?.total_quantity - (a?.total_shipping || 0) - (a?.total_shipped || 0)}</div>;
    },
  },
  {
    title: <b>Ngày tạo </b>,
    dataIndex: 'created_at',
    key: 'created_at',
    className: 'hidden-md',
    width: '15%',
    render: (value) => moment(value).format('DD-MM-YYYY'),
  },
  {
    title: <b>Ngày sửa </b>,
    dataIndex: 'updated_at',
    key: 'updated_at',
    className: 'hidden-md',
    width: '15%',
    render: (value) => moment(value).format('DD-MM-YYYY'),
  },
  {
    title: <b>Xoá</b>,
    align: 'center',
    render: (value, record, index) => (
      <Button
        key="delete"
        shape="circle"
        type="danger"
        onClick={() => onRemoveItem(record.product_code, index)}
        disabled={state === 'cancelled' || state === 'completed'}
        icon={<DeleteOutlined />}
      />
    ),
  },
];

const childrenColumns = (
  focusRowIndex,
  onChangeQuantity,
  ware,
  storageCode,
  onChangeQuantityNumber,
) => [
  {
    title: <b>Số lô</b>,
    key: 'lot_number',
    dataIndex: 'lot_number',
  },

  {
    title: <b>Ngày sản xuất</b>,
    key: 'manufacturing_date',
    dataIndex: 'manufacturing_date',
    render: (value) => (value ? moment(value).format('DD-MM-YYYY') : 'N/A'),
  },
  {
    title: <b>Hạn sử dụng</b>,
    key: 'expiry_date',
    dataIndex: 'expiry_date',
    render: (value) => (value ? moment(value).format('DD-MM-YYYY') : 'N/A'),
  },
  {
    title: (
      <b>
        Tồn kho <br />
        <small>(Chưa tính đang giao)</small>
      </b>
    ),
    key: 'product_code',
    dataIndex: 'product_code',
    width: '15%',
    render: (value, record) => {
      const warehouseRecord = ware.find((values) => values.product_code === value);
      if (warehouseRecord && warehouseRecord.summary_lot_numbers?.length) {
        const lotItem = warehouseRecord.summary_lot_numbers.find(
          (item) => item.lot_number === record.lot_number,
        );
        if (lotItem) {
          return <div>{lotItem.quantity}</div>;
        }
      }
      return '-';
    },
  },
  {
    title: <b>Số lượng kiểm kê</b>,
    // key: 'summary_lot_numbers',\
    dataIndex: 'real_quantity',
    render: (value, record, index) => (
      <Input
        // defaultValue={value}
        autoFocus={index === focusRowIndex}
        type="number"
        min={0}
        // max={1000}
        key="real_quantity"
        value={value}
        // addonAfter={<div style={{ width: '20px' }}>{record.unit?.primary}</div>}
        onChange={(e) => {
          onChangeQuantity(e.target.value, record.code, record.lot_number, record.product_code);
          // onChangeQuantityNumber(e.target.value, record.code);
        }}
      />
    ),
  },
];

class ItemList extends Component {
  constructor(props) {
    super(props);
    this.textSelect = React.createRef();
    this.state = {
      listProducts: props.products ? this.setAllRowSpan([...props.products]) : [],
      products: [],
      originProducts: [],
      setAllRowSpan: this.setAllRowSpan,
      combineData: this.combineData,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { products, isChangeData, changeData } = props;
    const { originProducts, setAllRowSpan, combineData } = state;

    if (isChangeData && products.length) {
      changeData();
      combineData(setAllRowSpan([...products]) || []);
      return { listProducts: setAllRowSpan([...products]) || [] };
    }

    if (products !== originProducts) return { products, originProducts: products };

    return null;
  }

  setAllRowSpan = (listProducts) => {
    const processedProductCodes = [];
    listProducts.forEach((item) => {
      if (!processedProductCodes.includes(item.product_code)) {
        processedProductCodes.push(item.product_code);
        this.setRowSpan(listProducts, item.product_code);
      }
    });
    return listProducts;
  };

  setRowSpan = (listProducts, productCode) => {
    const duplicatedProductsIndex = [];
    listProducts.forEach((item, index) => {
      if (item.product_code === productCode) {
        duplicatedProductsIndex.push(index);
      }
    });
    if (duplicatedProductsIndex.length === 1) {
      listProducts[duplicatedProductsIndex[0]] = Object.assign(
        {},
        listProducts[duplicatedProductsIndex[0]],
        { rowSpan: 1, plusIcon: true },
      );
    } else if (duplicatedProductsIndex.length > 1) {
      duplicatedProductsIndex.forEach((pIndex) => {
        listProducts[pIndex] = Object.assign({}, listProducts[pIndex], {
          rowSpan: 0,
          plusIcon: false,
        });
      });

      listProducts[duplicatedProductsIndex[0]] = Object.assign(
        {},
        listProducts[duplicatedProductsIndex[0]],
        {
          rowSpan: duplicatedProductsIndex.length,
        },
      );
      listProducts[duplicatedProductsIndex[duplicatedProductsIndex.length - 1]] = Object.assign(
        {},
        listProducts[duplicatedProductsIndex[duplicatedProductsIndex.length - 1]],
        {
          plusIcon: true,
        },
      );

      return duplicatedProductsIndex[duplicatedProductsIndex.length - 1];
    }

    return 0;
  };

  onRemoveItem = (code, productIndex) => {
    const { listProducts } = this.state;
    const removeProducts = listProducts.filter(
      (item, index) => item.product_code !== code || index !== productIndex,
    );
    this.setRowSpan(removeProducts, code);
    this.setState({ listProducts: removeProducts }, () =>
      this.combineData(this.state.listProducts),
    );
  };

  onAddItem = (productCode) => {
    const { listProducts, originProducts } = this.state;
    let isExist = -1;
    if (listProducts.length) {
      const index = listProducts
        .slice() // to preserve the original array
        .reverse()
        .findIndex((item) => item.product_code === productCode);
      // isExist is the last index of item that has the productCode
      isExist = index >= 0 ? listProducts.length - 1 - index : index;
    }
    const product = originProducts?.find((item) => item.product_code === productCode);
    if (product) {
      const newListProducts = listProducts;
      let focusRowIndex = listProducts.length;
      const newProduct = {
        product_code: product.product_code,
        name: product.product_name,
        unit: {
          name: product.unit,
        },
        price: product.price,
        category: {
          name: product.category_name,
          code: product.category_code,
        },
        supplier: {
          name: product.supplier_name,
          code: product.supplier_code,
        },
        quantity: 1,
      };

      newListProducts.splice(isExist + 1, 0, newProduct);
      focusRowIndex = this.setRowSpan(newListProducts, productCode);

      this.setState({ listProducts: newListProducts, focusRowIndex }, () =>
        this.combineData(this.state.listProducts),
      );
    }
  };

  onChangeQuantity = (value, code, lot_number, product_code) => {
    if (Number(value) < 0) return;

    const { listProducts } = this.state;
    const currentProduct = listProducts?.find((product) => product.product_code === product_code);
    const currentLotNumber = {
      ...currentProduct?.lot_details?.find((item) => item.lot_number === lot_number),
      real_quantity: Number(value),
    };
    currentProduct.lot_details = currentProduct.lot_details.map((item) =>
      item.lot_number === currentLotNumber.lot_number ? currentLotNumber : item,
    );

    this.setState({ listProducts }, () => this.combineData(this.state.listProducts));
  };

  onChangeExpiration = (value, code, productIndex) => {
    const { listProducts } = this.state;
    const newExpirationProducts = listProducts.map((item, index) => {
      if (item.product_code === code && index === productIndex) {
        return {
          ...item,
          expiry_date: ISODateTimeParam(value),
        };
      }
      return item;
    });
    this.setState({ listProducts: newExpirationProducts }, () =>
      this.combineData(this.state.listProducts),
    );
  };

  combineData = (data) => this.props.sendData(data);

  expandedRowRender = (voucherItem) => {
    const dataIndex = [];
    const { focusRowIndex } = this.state;
    const { lot_details } = voucherItem;
    const { ware, storageCode, onChangeQuantityNumber } = this.props;
    Object.keys(lot_details).map((key) => {
      dataIndex.push({
        code: key,
        expiry_date: lot_details[key].expiry_date,
        lot_number: lot_details[key].lot_number,
        manufacturing_date: lot_details[key].manufacturing_date,
        product_code: voucherItem.product_code,
        real_quantity: lot_details[key].real_quantity,
      });
    });
    // console.log('🚀 -> file: EditForm.js:291 -> ItemList -> Object.keys -> dataIndex:', dataIndex);

    return (
      <Table
        rowKey="code"
        columns={childrenColumns(
          focusRowIndex,
          this.onChangeQuantity,
          ware,
          storageCode,
          onChangeQuantityNumber,
        )}
        dataSource={dataIndex}
      />
    );
  };

  render() {
    const { listProducts } = this.state;
    const { state, ware, storageCode, listOfProduct } = this.props;

    return isBelowBreakpoint() ? (
      <>
        <Table
          key={`${listProducts?.length}`}
          size="small"
          scroll={{ x: 'max-content', y: 400 }}
          dataSource={listProducts}
          columns={columns(
            this.onChangeQuantity,
            this.onRemoveItem,
            this.onChangeExpiration,
            this.onAddItem,
            state,
            ware,
            storageCode,
          )}
          style={{ marginBottom: 10 }}
          rowKey={(record) => record.product_code}
          rowClassName={(r, idx) => (idx % 2 ? 'whitesmoke' : '')}
          pagination={false}
          expandable={{ expandedRowRender: this.expandedRowRender }}
        />
      </>
    ) : (
      <>
        <Table
          key={`${listProducts?.length}`}
          size="small"
          scroll={{ x: 'max-content', y: 400 }}
          dataSource={listProducts}
          columns={columns(
            this.onChangeQuantity,
            this.onRemoveItem,
            this.onChangeExpiration,
            this.onAddItem,
            state,
            ware,
            storageCode,
            listOfProduct,
          )}
          style={{ marginBottom: 10 }}
          rowKey={(record) => record.product_code}
          rowClassName={(r, idx) => (idx % 2 ? 'whitesmoke' : '')}
          pagination={false}
          expandable={{ expandedRowRender: this.expandedRowRender }}
        />
      </>
    );
  }
}

export default ItemList;
