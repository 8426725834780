/* eslint-disable react/no-access-state-in-setstate */
// import { EyeInvisibleOutlined } from '@ant-design/icons';
import { Row, Button, Col, notification, Checkbox } from 'antd';
import lodash from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import { connect } from 'react-redux';
import SplitPane from 'react-split-pane';

import DriverList from './components/RoutingTable/DriverList';
import RouteList from './components/RoutingTable/RouteList';
import TomtomCVRP from './components/TomtomCVRP';
import UnscheduledOrders from './components/UnScheduledOrders';
import WithLoading from '../../hoc/WithLoading';
import { actions as orderActions } from '../../redux/OrderRedux';
import { actions as routingActions } from '../../redux/RoutingRedux';

class CVRP extends Component {
  constructor(props) {
    super(props);

    this.state = {
      vehicle_capacity: 100,
      startHour: 6,
      finishHour: 12,
      startMin: 0,
      finishMin: 0,
      prevDrivers: [],
      // Select routes
      selectedRoutes: [],
      // Change node of route
      isChangedNodePosition: false,
      //* Mảng routes (clone từ redux)
      routes: props?.solution?.routes?.length ? props.solution.routes : [],
      //* Mảng orders chưa được lập lịch (clone từ redux)
      orders: props.nonScheduledOrders.length ? props.nonScheduledOrders : [],
      changedRoutes: [], //* Index của các route đã chỉnh sửa
      // Add route
      isAddedRoute: false,
      newRouteIndex: 0,
      showAllRoutes: false,
    };

    this.onSwapNodes = lodash.debounce(this.onSwapNodes, 10);
  }

  static getDerivedStateFromProps(props, state) {
    const { solution, nonScheduledOrders } = props;
    const { routes, orders, isChangedNodePosition } = state;

    if (
      solution?.routes && // to make sure solution has routes and not a blank object
      solution.routes?.length !== routes.length &&
      // solution.routes?.some((r) => r?.depot?.location?.address) &&
      !isChangedNodePosition
    ) {
      return { routes: [...solution?.routes] };
    }

    if (
      ((nonScheduledOrders?.length && !orders.length) ||
        nonScheduledOrders.length !== orders.length) &&
      !isChangedNodePosition
    ) {
      return { orders: nonScheduledOrders };
    }

    return null;
  }

  timelineData = () => {
    const { driversList } = this.props;
    const { prevDrivers, startHour, finishHour, startMin, finishMin } = this.state;
    if (driversList && driversList !== prevDrivers) {
      if (driversList.length > 0) {
        const startTime = driversList.map((e) => e.work_time_from).sort();
        const finishTime = driversList.map((e) => e.work_time_to).sort();
        this.setState({
          startHour: parseInt(startTime[0]?.substring(0, 2), 10),
          startMin: parseInt(startTime[0]?.substring(3, 5), 10),
          finishHour: parseInt(finishTime[finishTime.length - 1]?.substring(0, 2), 10),
          finishMin: parseInt(finishTime[finishTime.length - 1]?.substring(3, 5), 10),
        });
      } else {
        this.setState({
          startHour: 6,
          finishHour: 12,
          startMin: 0,
          finishMin: 0,
        });
      }
      this.setState({
        prevDrivers: driversList,
      });
    }
    return [
      [
        { type: 'string', id: 'Room' },
        { type: 'string', id: 'Name' },
        { type: 'date', id: 'Start' },
        { type: 'date', id: 'End' },
      ],
      [
        '',
        '',
        new Date(0, 0, 0, startHour, startMin, 0),
        new Date(0, 0, 0, finishHour, finishMin, 0),
      ],
      // ['', '', new Date(0, 0, 0, 6, 0, 0), new Date(0, 0, 0, 12, 0, 0)],
      // ['', '', new Date(0, 0, 0, 2, 0, 0), new Date(0, 0, 0, 3, 0, 0)],
      // ['', '', new Date(0, 0, 0, 3, 0, 0), new Date(0, 0, 0, 4, 0, 0)],
      // ['', '', new Date(0, 0, 0, 4, 0, 0), new Date(0, 0, 0, 5, 0, 0)],
    ];
  };

  componentWillUnmount() {
    this.props.clearHiddenRoutes();
  }

  onSortDrivers = (updatedDrivers) => this.props.sortDrivers(updatedDrivers);

  calcAndUpdateSolution = (updateRoutes) => {
    const payloadCalc = {
      routes: updateRoutes.map((route) => ({
        depot: {
          location: route.depot.location, // dành cho 1 depot, có thể phát triển dành cho nhiều depot
          nb_vehicles: route.depot.nb_vehicles,
          vehicle_capacity: route.depot.vehicle_capacity,
        },
        driver_code: route.driver_code,
        nodes: route.nodes.map((itemNode) => ({
          // load: itemNode.weight,
          load: 0,
          location: {
            latitude: itemNode.location.latitude,
            longitude: itemNode.location.longitude,
            address: itemNode.location.address,
          },
        })),
      })),
    };

    this.props.calcSolution(payloadCalc, {
      onSuccess: (dataCalc) => {
        notification.success({
          message: 'Tính toán khoảng cách, thời gian, của các tuyến thành công!',
        });
        const routes = updateRoutes.map((item, index) =>
          // const route = data.routes.find((e) => e.driverId === item.driverId);
          ({
            ...item,
            // weight_used: route ? route.demand : 0,
            // distance: route ? route.distance : 0,
            // total_time: route ? route.duration : 0,
            weight_used: dataCalc[index]?.demand || 0,
            total_distance: dataCalc[index]?.distance || 0,
            total_time: dataCalc[index]?.duration || 0,
          }),
        );
        this.props.updateSolution({ routes });
        this.props.invalidateSolution();
      },
      onError: () => {
        notification.error({
          message: 'Tính toán khoảng cách, thời gian, của các tuyến không thành công!',
        });
      },
    });
  };

  reCalculateAndUpdateRoutes = (changingRoutes, updateRouteIndices) => {
    if (!changingRoutes.length) {
      return;
    }

    // console.log('reCalculateAndUpdateRoutes', changingRoutes, updateRouteIndices);
    this.setState({ isCalculatingCVRP: true });
    //* Payload tính lại khoảng cách, thời gian
    const payload = {
      routes: changingRoutes.map((route) => ({
        depot: {
          location: route?.depot?.location || {
            address: '',
            latitude: 0,
            longitude: 0,
          },
          nb_vehicles: 1,
          vehicle_capacity: 100,
        },
        driver_code: route?.driver_code,
        nodes: route?.nodes?.length
          ? route?.nodes.map((node) => ({
              load: 0,
              location: node?.location || {
                address: '',
                latitude: 0,
                longitude: 0,
              },
            }))
          : [],
        start_time: route.start_time,
      })),
    };

    //* Tính lại khoảng cách, thời gian
    this.props.calcSolution(payload, {
      onSuccess: (dataCalc) => {
        const { routes, changedRoutes } = this.state;
        if (!dataCalc?.length) {
          notification.error({
            message: 'Có lỗi khi tính toán lại tuyến đường, hãy thử lại!',
          });
          return;
        }
        notification.success({ message: 'Đã tính toán lại tuyến đường.' });
        //* Update state tuyến đường (routes)
        routes[updateRouteIndices[0]] = {
          ...changingRoutes[0],
          total_distance: dataCalc[0]?.distance,
          total_time: dataCalc[0]?.duration,
          weight_used: dataCalc[0]?.demand,
          nodes: dataCalc[0]?.nodes?.map((n, i) => ({
            ...changingRoutes[0].nodes[i],
            ...n,
            order_code: changingRoutes[0].nodes[i]?.order_code,
            // shipping_code: changingRoutes[0].nodes[i]?.shipping_code,
          })),
        };
        const newChangedRoutes = changedRoutes.includes(updateRouteIndices[0])
          ? [...changedRoutes]
          : [...changedRoutes, updateRouteIndices[0]];
        if (updateRouteIndices[1] !== undefined) {
          const tIdx = dataCalc.length - 1;
          routes[updateRouteIndices[1]] = {
            ...changingRoutes[1],
            total_distance: dataCalc[tIdx]?.distance,
            total_time: dataCalc[tIdx]?.duration,
            weight_used: dataCalc[tIdx]?.demand,
            nodes: dataCalc[tIdx]?.nodes?.map((n, i) => ({
              ...changingRoutes[1].nodes[i],
              ...n,
              order_code: changingRoutes[1].nodes[i]?.order_code,
              // shipping_code: changingRoutes[1].nodes[i]?.shipping_code,
            })),
          };
          if (!newChangedRoutes.includes(updateRouteIndices[1])) {
            newChangedRoutes.push(updateRouteIndices[1]);
          }
        }
        newChangedRoutes.sort();
        this.setState({
          routes: [...routes],
          changedRoutes: newChangedRoutes,
          isChangedNodePosition: true,
          isCalculatingCVRP: false,
        });
      },
      onError: () => {
        this.setState({ isCalculatingCVRP: false });
        notification.error({
          message: 'Có lỗi khi tính toán tuyến đường, hãy thử lại!',
        });
      },
    });
  };

  //* Chuyển vị trí node -> tính toán lại khoảng cách & thời gian của tuyến đường
  handleChangeNodePosition = (node, source, target) => {
    // console.log('handleChangeNodePosition', node, source, target);
    const { routes, orders } = this.state;

    const changingRoutes = [];
    const updateRouteIndices = [];

    const sourceRoute =
      source && (source?.routeIndex || source?.routeIndex === 0)
        ? lodash.cloneDeep(routes[source.routeIndex])
        : null;
    const targetRoute =
      target && (target?.routeIndex || target?.routeIndex === 0)
        ? lodash.cloneDeep(routes[target.routeIndex])
        : null;

    //* Trường hợp bỏ đơn hàng khỏi route
    if (!targetRoute) {
      //* Sửa lại mảng đơn hàng chưa được lập lịch
      const newOrders = [...orders];
      // const order = data[source.routeIndex].nodes[source.nodeIndex - 1];
      newOrders.unshift(node);

      this.setState({ orders: newOrders, isChangedNodePosition: true });

      sourceRoute.nodes.splice(source.nodeIndex, 1);
      changingRoutes.push(sourceRoute);
      updateRouteIndices.push(source.routeIndex);
    }
    //* Nếu tuyến chưa chọn driver (có thể là tempRoute hoặc route từ redux)
    // else if (!routes[target.routeIndex]?.driver_code) {
    //   notification.warning({ message: 'Chưa chọn lái xe cho tuyến đích!' });
    // }
    else {
      //* Trường hợp thay đổi vị trí của node trong cùng 1 route
      if (source.routeIndex === target.routeIndex) {
        const newNodes = [];
        if (target.nodeIndex === -1) {
          newNodes.push(node);
        }
        targetRoute.nodes?.forEach((n, index) => {
          if (index !== source.nodeIndex) {
            if (index === target.nodeIndex && target.nodeIndex < source.routeIndex) {
              newNodes.push(node);
            }
            newNodes.push(n);
            if (index === target.nodeIndex && target.nodeIndex > source.routeIndex) {
              newNodes.push(node);
            }
          }
        });
        targetRoute.nodes = newNodes;
        changingRoutes.push(targetRoute);
        updateRouteIndices.push(target.routeIndex);
      } else {
        //* Trường hợp thay đổi node từ route này sang route khác
        //* Nếu vị trí node ở đầu route
        if (target.nodeIndex === -1) {
          targetRoute.nodes = [{ ...node, index: undefined }, ...targetRoute.nodes];
        } else {
          targetRoute.nodes.splice(target.nodeIndex + 1, 0, { ...node, index: undefined });
        }

        //* Trường hợp thêm đơn hàng vào route
        if (typeof source.routeIndex !== 'number') {
          //* Sửa lại mảng đơn hàng chưa được lập lịch
          const newOrders = orders.toSpliced(node.index, 1);
          this.setState({ orders: newOrders, isChangedNodePosition: true });
        } else {
          //* Chuyển node từ route nguồn sang route đích
          sourceRoute.nodes.splice(source.nodeIndex, 1);
          changingRoutes.push(sourceRoute);
          updateRouteIndices.push(source.routeIndex);
        }
        changingRoutes.push(targetRoute);
        updateRouteIndices.push(target.routeIndex);
      }
    }

    return this.reCalculateAndUpdateRoutes(changingRoutes, updateRouteIndices);
  };

  //* Lưu thay đổi cho các tuyến đã chỉnh sửa
  handleSaveRoutes = () => {
    const { changedRoutes, routes } = this.state;
    const { data } = this.props;
    const newRoutes = [];
    const newDataRoutes = [];
    const updatedDataRoutes = [];
    const updatedRoutes = [];
    //* Thông tin trên redux của các tuyến đã chỉnh sửa thông tin
    changedRoutes.forEach((changedIdx) => {
      if (data[changedIdx] && routes[changedIdx]) {
        if (routes[changedIdx].code.startsWith('temp_route')) {
          newDataRoutes.push(data[changedIdx]);
          newRoutes.push(routes[changedIdx]);
        } else if (routes[changedIdx]?.nodes?.length) {
          updatedDataRoutes.push(data[changedIdx]);
          updatedRoutes.push(routes[changedIdx]);
        }
      }
    });

    if (newRoutes?.length) {
      this.addNewRoutes(newRoutes, newDataRoutes);
    }

    if (updatedRoutes?.length) {
      //* Lấy ra mảng các nodes của các tuyến đã chỉnh sửa thông tin
      const dataNodes = updatedDataRoutes
        .map((route, index) =>
          route.nodes.map((node, i) => ({ ...node, routeIndex: index, nodeIndex: i })),
        )
        .flat();

      //* Payload cập nhật thông tin các tuyến
      const payloadUpdateRoutes = {
        new_routes: updatedDataRoutes.map((route, i) => ({
          delivery_date: route.delivery_date,
          driver_code: route.driver_code,
          estimate_start_time: route?.estimate_start_time || null,
          finish_location: route.finish_location || { address: '', latitude: 0, longitude: 0 },
          nodes: updatedRoutes?.[i].nodes.map((node) => {
            const foundNode = dataNodes.find((n) => n?.order_code === node.order_code);
            return {
              comment: foundNode ? foundNode?.comment : '',
              completed_image: foundNode ? foundNode?.completed_image : '',
              customer: foundNode ? foundNode?.customer : node?.customer,
              demand: foundNode?.demand || 0,
              distance: node?.distance,
              estimated_arrival_time: node?.estimated_arrival_time,
              estimated_departure_time: node?.estimated_departure_time,
              occupied_space: foundNode?.occupied_space || 0,
              order_code: foundNode ? foundNode?.order_code : node?.order_code,
              rate: foundNode?.rate || 0,
              real_arrived_time: foundNode?.real_arrived_time || null,
              real_departure_time: foundNode?.real_departure_time || null,
              status: foundNode ? foundNode?.status : 'Scheduled',
              status_updated: foundNode?.status_updated || {
                updated_at: null,
                updated_by: { user_code: '', username: '' },
              },
            };
          }),
          start_location: route.start_location || { address: '', latitude: 0, longitude: 0 },
          total_distance: updatedRoutes?.[i]?.distance || 0,
          total_time: updatedRoutes?.[i]?.duration || 0,
        })),
        old_route_codes: updatedDataRoutes.map((route) => route.code),
      };

      this.props.updateRoutes(payloadUpdateRoutes, {
        onSuccess: () => {
          this.props.getNonScheduledOrders();
          this.props.getRoutes();
          notification.success({ message: 'Cập nhật tuyến đường thành công.' });
          this.setState({ routes: [], orders: [], isChangedNodePosition: false });
        },
        onError: () => notification.error({ message: 'Cập nhật tuyến đường thất bại.' }),
      });
    }
  };

  onRemoveNode = (source) =>
    this.handleChangeNodePosition(source.node, {
      nodeIndex: source.nodeIndex,
      routeIndex: source.routeIndex,
    });

  //* Đổi vị trí giữa 2 node với nhau
  onSwapNodes = (source, target) => {
    if (target.routeIndex !== -1 && source.routeIndex !== -1) {
      const { routes } = this.props.solution;
      const resultRoutes = [];
      routes.forEach((route) => {
        const resultNodes = [];
        route.nodes.forEach((node) => {
          if (node.customerCode === source.node.customerCode) {
            const tempNode = { ...target.node, index: source.node.index };
            resultNodes.push(tempNode);
          } else if (node.customerCode === target.node.customerCode) {
            const tempNode = { ...source.node, index: target.node.index };
            resultNodes.push(tempNode);
          } else {
            resultNodes.push(node);
          }
        });
        resultRoutes.push({ ...route, nodes: resultNodes });
      });

      this.calcAndUpdateSolution(resultRoutes);
    }
  };

  addNewRoutes = (newRoutes, newDataRoutes) => {
    const { insertRoutes } = this.props;
    const routesPayload = newRoutes.map((item, index) => {
      const startTime =
        newDataRoutes[index]?.delivery_date && newDataRoutes[index]?.estimate_start_time
          ? moment(newDataRoutes[index].delivery_date)
              .set('hour', moment(newDataRoutes[index].estimate_start_time).get('hour'))
              .set('minute', moment(newDataRoutes[index].estimate_start_time).get('minute'))
              .toISOString()
          : '';

      return {
        delivery_date: newDataRoutes[index]?.delivery_date || '',
        estimate_start_time: startTime,
        driver_code: item.driver_code,
        start_location: item.depot.location,
        finish_location: item.depot.location,
        total_distance: item.total_distance,
        total_time: item.total_time,
        // weight_used: item.weight_used,
        nodes: item.nodes.map(({ index, location, duration, ...keepAttrs }) => ({
          ...keepAttrs,
          shipping_code:
            keepAttrs.shipping_code || keepAttrs.customer?.code || keepAttrs.order_code,
          status: 'Scheduled',
        })),
      };
    });

    insertRoutes(
      { routes: routesPayload },
      {
        onSuccess: () => {
          this.props.getNonScheduledOrders();
          this.props.getRoutes();
          this.setState({ routes: [], orders: [], isChangedNodePosition: false });
          notification.open({
            message: 'Thêm tuyến đường thành công.',
            type: 'success',
          });
        },
        onError: () => {
          notification.error({
            message: 'Thêm tuyến đường thất bại!',
          });
        },
      },
    );
  };

  //* Chỉnh sửa tuyến đường gửi cho lái xe
  handleChangeRouteSendToMobileApp = (routeCode) =>
    //* Nếu route có node: có thể gửi, không có node: không gửi
    this.state.routes.find((route) => route.code === routeCode)?.nodes?.length &&
    this.setState({
      selectedRoutes: !this.state.selectedRoutes.find((code) => code === routeCode)
        ? [...this.state.selectedRoutes, routeCode]
        : this.state.selectedRoutes.filter((code) => code !== routeCode),
    });

  //* Gửi tuyến đường cho lái xe
  handleSendRouteToMobileApp = () => {
    const { sendRouteToMobileApp } = this.props;
    const { selectedRoutes } = this.state;

    sendRouteToMobileApp(
      { route_codes: selectedRoutes.filter((routeCode) => routeCode && routeCode), show: true },
      {
        onSuccess: () =>
          notification.success({ message: 'Gửi tuyến đường cho lái xe thành công.' }),
        onError: () => notification.error({ message: 'Gửi tuyến đường cho lái xe thất bại!' }),
      },
    );
  };

  //* Đổi lái xe cho mỗi tuyến
  handleChangeDriver = (index, oldDriverCode, newDriverCode) => {
    const { dataRoute, allDrivers, updateDriverRoute, updateDriverOfTempRoute } = this.props;
    //* Nếu route được chỉnh sửa là route mới được thêm vào
    if (dataRoute[index]?.code?.startsWith('temp_route')) {
      const newDriver = allDrivers.find((driver) => driver.code === newDriverCode);
      updateDriverOfTempRoute(newDriver, dataRoute[index].code, {
        onSuccess: () => {
          notification.success({ message: 'Đổi lái xe thành công.' });
          this.setState({ routes: [] });
        },
        onError: () => notification.error({ message: 'Đổi lái xe thất bại!' }),
      });
    } else {
      const payload = { driver_code: newDriverCode };
      updateDriverRoute(index, dataRoute[index]?.code, payload, {
        onSuccess: () => notification.success({ message: 'Đổi lái xe thành công.' }),
        onError: () => notification.error({ message: 'Đổi lái xe thất bại!' }),
      });
    }
  };

  //* Thay đổi giờ xuất phát của tuyến
  handleSelectStartTime = (time) => {
    const { deliveryDate, updateStartTimeOfTempRoute } = this.props;
    const newStartTime = moment(deliveryDate)
      .set('hour', moment(time).get('hour'))
      .set('minute', moment(time).get('minute'))
      .toISOString();
    updateStartTimeOfTempRoute(newStartTime);
  };

  //* Thêm tuyến đường mới (fake data tuyến mới trên redux store)
  handleAddRoute = () => {
    const { newRouteIndex } = this.state;
    const { deliveryDate } = this.props;
    this.props.addTemporaryRoute(newRouteIndex, deliveryDate);
    this.setState({ newRouteIndex: newRouteIndex + 1 });
    // this.setState({ routes: [], isAddedRoute: true });
  };

  render() {
    const { allDrivers, solution, deleteRoute } = this.props;
    const { selectedRoutes, routes, orders, isChangedNodePosition } = this.state;

    const onDelete = (code) => {
      const body = {
        codes: [code],
      };

      deleteRoute(body, {
        onSuccess: () => {
          notification.success({ message: 'Xóa thành công' });
          this.props.getRoutes();
          invalidateApis();
        },

        onError: (error) => notification.error({ message: `${error} - Xóa thất bại` }),
      });
    };

    return (
      <DndProvider backend={Backend}>
        <SplitPane split="vertical" className="routing-split-pane">
          <div style={{ height: '100%', maxHeight: 800, overflow: 'scroll' }}>
            {routes?.length && orders ? (
              <UnscheduledOrders orders={orders} onDropNode={this.onRemoveNode} />
            ) : null}
            <Row gutter={15}>
              <Col span={8} lg={6}>
                <Button
                  type="ghost"
                  shape="round"
                  onClick={this.handleAddRoute}
                  style={{ margin: '15px 0' }}>
                  Thêm tuyến
                </Button>
              </Col>
              <Col span={16} lg={18}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingRight: 10,
                    margin: '15px 0',
                  }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                      checked={
                        selectedRoutes?.length &&
                        selectedRoutes?.length === solution?.routes?.length
                      }
                      onClick={(e) =>
                        this.setState({
                          selectedRoutes: e.target.checked
                            ? solution?.routes?.map((route) => route.nodes.length && route.code)
                            : [],
                        })
                      }
                    />
                    <Button
                      type="primary"
                      shape="round"
                      onClick={this.handleSendRouteToMobileApp}
                      disabled={!selectedRoutes.length}
                      style={{ margin: '0 0 0 15px' }}>
                      Gửi tuyến cho lái xe
                    </Button>
                  </div>
                  <div
                    style={
                      {
                        // width: '90%',
                        // height: '75px',
                        // marginLeft: '90px',
                      }
                    }>
                    {/* <Chart
                    chartType="Timeline"
                    data={this.timelineData()}
                    width="100%"
                    height="100px"
                    options={timelineOptions}
                  /> */}
                    <Button
                      style={{ alignSelf: 'center' }}
                      disabled={!isChangedNodePosition}
                      onClick={this.handleSaveRoutes}
                      shape="round"
                      type="primary">
                      Lưu thay đổi
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ width: 230 }}>
                <DriverList
                  allDrivers={allDrivers}
                  routes={routes}
                  onChangeDriver={this.handleChangeDriver}
                  onSortDrivers={this.onSortDrivers}
                  onSelectStartTime={this.handleSelectStartTime}
                  vehicle_capacity={this.state.vehicle_capacity}
                />
              </div>
              <div style={{ marginLeft: 15, width: 'calc(100% - 230px)', height: '100%' }}>
                <RouteList
                  selectedRoutes={selectedRoutes}
                  handleChangeRouteSendToMobileApp={this.handleChangeRouteSendToMobileApp}
                  onChangeNodePosition={this.handleChangeNodePosition}
                  onSwapNodes={this.onSwapNodes}
                  onDelete={onDelete}
                  routes={routes}
                  vehicle_capacity={this.state.vehicle_capacity}

                  // onShowNodeInfo={onShowNodeInfo}
                />
              </div>
            </div>
          </div>
          <div style={{ height: '100%', maxHeight: 800 }}>
            <TomtomCVRP routes={routes} />
          </div>
        </SplitPane>
      </DndProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  driversList: state.routingReducer.drivers,
  allDrivers: state.driverReducer.allItems,
  solution: state.routingReducer.solution,
  data: state.routingReducer.data,
  dataRoute: state.routingReducer.data,
  isFetching: state.routingReducer.isFetching,
  nonScheduledOrders: state.orderReducer.items,
  hiddenRoutesIndex: state.routingReducer.hiddenRoutesIndex,
});

const mapDispatchToProps = (dispatch) => ({
  sortDrivers: (updatedDrivers) => dispatch(routingActions.sortDrivers(updatedDrivers)),
  calcSolution: (updatedDrivers, meta) =>
    dispatch(routingActions.calcSolution(updatedDrivers, meta)),
  updateSolution: (updatedDrivers) => dispatch(routingActions.updateSolution(updatedDrivers)),
  invalidateSolution: () => dispatch(routingActions.invalidateSolution()),
  clearHiddenRoutes: () => dispatch(routingActions.clearHiddenRoutes()),
  insertRoutes: (payload, meta) => dispatch(routingActions.insertRoutes(payload, meta)),
  updateRoutes: (payload, meta) => dispatch(routingActions.updateRoutes(payload, meta)),
  addTemporaryRoute: (index, deliveryDate) =>
    dispatch(routingActions.addTemporaryRoute(index, deliveryDate)),
  updateDriverOfTempRoute: (newDriver, tempRouteCode, meta) =>
    dispatch(routingActions.updateDriverOfTempRoute(newDriver, tempRouteCode, meta)),
  updateStartTimeOfTempRoute: (startTime) =>
    dispatch(routingActions.updateStartTimeOfTempRoute(startTime)),
  updateRouteByCode: (index, code, payload, meta) =>
    dispatch(routingActions.updateRouteByCode(index, code, payload, meta)),
  updateDriverRoute: (index, code, payload, meta) =>
    dispatch(routingActions.updateDriverRoute(index, code, payload, meta)),
  sendRouteToMobileApp: (payload, meta) =>
    dispatch(routingActions.sendRouteToMobileApp(payload, meta)),
  updateOrderStatus: (index) => dispatch(orderActions.updateOrderStatus(index)),
  deleteRoute: (body, meta) => dispatch(routingActions.deleteRoute(body, meta)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithLoading(CVRP));
