import { Divider } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Filter from './components/Filter';
import WareHouseList from './components/List';
import { actions as wareHouseActions } from '../../redux/WareHousesRedux';

const { getInvetory } = wareHouseActions;

const WareHouse = ({ getInvetory, dataWareHouse, meta, isFetching }) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [storageCodes, setStorageCodes] = useState();
  const [productCodes, setProductCodes] = useState();
  const [categoryCodes, setCategoryCodes] = useState();
  const [expirationFrom, setExpirationFrom] = useState();
  const [expirationTo, setexpirationTo] = useState();
  const [quantityFrom, setQuantityFrom] = useState(null);
  const [quantityTo, setQuantityTo] = useState(null);
  const [search, setSearch] = useState(null);

  useEffect(() => {
    getInvetory({
      page,
      page_size: pageSize,
      storage_codes: storageCodes,
      product_codes: productCodes,
      category_codes: categoryCodes,
      expiration_from: expirationFrom ? moment(expirationFrom).toISOString() : expirationFrom,
      expiration_to: expirationTo ? moment(expirationTo).toISOString() : expirationTo,
      quantity_from: quantityFrom,
      quantity_to: quantityTo,
      search,
    });
  }, [
    page,
    pageSize,
    storageCodes,
    productCodes,
    categoryCodes,
    expirationFrom,
    expirationTo,
    quantityTo,
    quantityFrom,
    search,
  ]);

  const handleChangeSearch = (value) => {
    setSearch(value);
  };

  const handleChangeStorageCodes = (value) => {
    setStorageCodes(value);
  };

  const handleChangeProductCodes = (value) => {
    setProductCodes(value.join(','));
  };

  const handleChangeCategoryCode = (value) => {
    setCategoryCodes(value?.join(','));
  };

  const onChangePage = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
  };

  const handleChangeExpiration = (value) => {
    if (value !== null) {
      setExpirationFrom(value[0]);
      setexpirationTo(value[1]);
    } else {
      setExpirationFrom(null);
      setexpirationTo(null);
    }
  };

  const handleChangeQuantity = (value) => {
    if (value !== null) {
      setQuantityFrom(value[0]);
      setQuantityTo(value[1]);
    } else {
      setQuantityFrom(null);
      setQuantityTo(null);
    }
  };

  return (
    <>
      <div>
        <h2>Tồn kho</h2>
      </div>
      <Divider style={{ marginTop: 0 }} />

      <div style={{ marginBottom: '15px' }}>
        <Filter
          changeCategoryCodes={handleChangeCategoryCode}
          changeproductCodes={handleChangeProductCodes}
          changeStorageCodes={handleChangeStorageCodes}
          handleChangeSearch={handleChangeSearch}
          handleChangeQuantity={handleChangeQuantity}
          handleChangeExpiration={handleChangeExpiration}
        />
      </div>
      <WareHouseList
        data={dataWareHouse}
        onChangePage={onChangePage}
        meta={meta}
        isFetching={isFetching}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  dataWareHouse: state?.wareHousesReducer?.data,
  meta: state?.wareHousesReducer?.meta,
  isFetching: state?.wareHousesReducer?.isFetching,
});

const mapDispatchToProps = (dispatch) => ({
  getInvetory: (params) => dispatch(getInvetory(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WareHouse);
