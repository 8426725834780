export default {
  // common actions
  Add: 'Thêm',
  Save: 'Lưu',
  Clear: 'Clear',
  Search: 'Tìm kiếm',
  Filter: 'Lọc',
  Reset: 'Bỏ lọc',
  All: 'Tất cả',
  'Input text search': 'Gõ để tìm kiếm',

  // common words
  'Xuctienviet ©2020': 'Xuctienviet ©2020',
  Permission: 'Quyền',
  Role: 'Vai trò',
  Language: 'Ngôn ngữ',
  Name: 'Tên',
  Hello: 'Xin chào',
  Detail: 'Chi tiết',
  Image: 'Ảnh',
  Images: 'Danh sách ảnh',
  'No images': 'Không có ảnh',
  Code: 'Mã',
  Status: 'Trạng thái',
  State: 'Trạng thái',
  of: 'trong',
  Activated: 'Đang hoạt động',
  Deactivated: 'Đã dừng',
  'Created at': 'Ngày tạo',
  CreatedAt: 'Ngày tạo',
  'Updated at': 'Ngày sửa',
  UpdatedAt: 'Ngày sửa',
  Address: 'Địa chỉ',

  // objects
  Supplier: 'Nhà cung cấp',
  Suppliers: 'Nhà cung cấp',
  'Suppliers List': 'Danh sách nhà cung cấp',
  Category: 'Nhóm hàng',
  Categories: 'Nhóm hàng',
  Product: 'Mặt hàng',
  Products: 'Mặt hàng',
  ProductName: 'Tên sản phẩm',
  'Products List': 'Danh sách mặt hàng',
  Invoice: 'Đơn hàng',
  Invoices: 'Đơn hàng',
  Order: 'Đơn hàng',
  Orders: 'Đơn hàng',
  Chart: 'Biểu đồ',
  Promotion: 'Khuyến mại',
  Station: 'Trạm',
  'Accept list product offer': 'Duyệt danh sách sản phẩm đề xuất',
  Avatar: 'Ảnh',
  'Send list product offer': 'Gửi danh sách sản phẩm đề xuất',
  'Product Trade Offer': 'Sản phẩm đề xuất',
  'Account Manage': 'Quản trị người dùng',
  Api: 'Api',
  // product-supplier-category
  Price: 'Giá',
  InputPrice: 'Giá nhập',
  'Input Price': 'Giá nhập',
  'Listed Price': 'Giá niêm yết',
  'Selling Price': 'Giá bán',
  SaleOff: 'Giảm giá',
  'SaleOff Amount': 'Giảm giá',
  Quantity: 'Số lượng',
  Weight: 'Khối lượng',
  'Total Weight': 'Tổng khối lượng',
  Criteria: 'Chứng nhận chất lượng',

  // order
  OrderNumber: 'Mã đơn',
  'Order Number': 'Mã đơn',
  DeliveryDate: 'Ngày giao hàng',
  'Delivery Date': 'Ngày giao hàng',
  'Payment Amount': 'Thành tiền',
  'Total Cost': 'Tổng tiền',
  'Order detail': 'Chi tiết đơn hàng',
  pending: 'Chờ xử lý',
  received: 'Đặt hàng',
  processing: 'Đóng gói',
  shipping: 'Vận chuyển',
  shipped: 'Tới POS',
  completed: 'Hoàn thành',
  returned: 'Đã trả lại',
  cancelled: 'Đã hủy',

  // User form
  'Remember me': 'Ghi nhớ',
  'Log In': 'Đăng Nhập',
  Register: 'Đăng ký',
  LogOut: 'Đăng xuất',
  Username: 'Tài khoản',
  Password: 'Mật khẩu',
  'Enter your username': 'Nhập tài khoản',
  'Enter your password': 'Nhập mật khẩu',
  'Enter your new password': 'Nhập mật khẩu mới',
  'Enter your confirm password': 'Nhập xác nhận mật khẩu',
  'Forgot password': 'Quên mật khẩu',
  Loading: 'Đang tải',
  'Please input your Username': 'Hãy nhập tài khoản',
  'Please input your Password': 'Hãy nhập mật khẩu',
  'These credential do not match our records': 'Sai tài khoản hoặc mật khẩu',
  'Enter your email': 'Nhập email',
  Edit: 'Cập nhật',
  'New Account': 'Tạo tài khoản mới',
  Email: 'Email',
  'Confirm Password': 'Xác nhận mật khẩu',
  'Select user role': 'Chọn role',

  // Product forms
  'Product Name': 'Tên mặt hàng',
  'Input product name': 'Nhập tên mặt hàng',
  'Pick Product': 'Chọn mặt hàng',

  // Error
  'Can not find order information': 'Không tìm thấy đơn hàng',
  'Can not find supplier information': 'Không tìm thấy thông tin nhà cung cấp',
  'IdentityCard exists!': 'Chứng minh thư đã tồn tại!',
  'PhoneNumber exists!': 'Số điện thoại đã tồn tại!',
  'email already exists': 'Email đã tồn tại',
};
