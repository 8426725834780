import { CheckOutlined } from '@ant-design/icons';
import { Form, Row, Col, Button, Typography, Checkbox } from 'antd';
import React from 'react';

const FormItem = Form.Item;
const { Title } = Typography;

const FeaturesForm = (props) => {
  const features = props.features || ['Transportation'];

  const onFinish = (values) => {
    props.onSubmit(values);
  };

  return (
    <div>
      <Title level={3}>Chức năng</Title>
      <Form onFinish={onFinish} layout="vertical">
        <Row gutter={15}>
          <Col span={24}>
            <FormItem label="" name="features" initialValue={features} valuePropName="value">
              <Checkbox.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={12}>
                    <Checkbox value="Transportation">Giao vận</Checkbox>
                  </Col>
                  <Col span={12}>
                    <Checkbox value="Inventory">Kho</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </FormItem>
          </Col>
        </Row>

        <FormItem style={{ float: 'right', marginTop: '10px' }}>
          <Button
            type="primary"
            htmlType="submit"
            shape="round"
            icon={<CheckOutlined />}
            style={{ marginRight: 8 }}>
            Lưu
          </Button>
        </FormItem>
      </Form>
    </div>
  );
};

export default FeaturesForm;
