import { Col, Row, notification } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import SampleRouteForm from './components/Form';
import Card from '../../components/Card';
import ROUTER from '../../config/router';
import { actions as customerActions } from '../../redux/CustomerRedux';
import { isBelowBreakpoint } from '../../utils/window';

class AddSampleRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSubmit = (values) => {
    const { insertCustomer, history, currentAddress, currentLatitude, currentLongitude } =
      this.props;

    const customerInfoPayload = {
      ...values,
      address: undefined,
      location: { address: currentAddress, latitude: currentLatitude, longitude: currentLongitude },
      time_windows: values.time_windows.map((time) => ({
        from_time: `${time[0].hour() > 9 ? time[0].hour() : `0${time[0].hour()}`}:${
          time[0].minute() > 9 ? time[0].minute() : `0${time[0].minute()}`
        }`,
        to_time: `${time[1].hour() > 9 ? time[1].hour() : `0${time[1].hour()}`}:${
          time[1].minute() > 9 ? time[1].minute() : `0${time[1].minute()}`
        }`,
      })),
    };

    insertCustomer(customerInfoPayload, {
      onSuccess: () => {
        notification.open({
          message: 'Thêm khách hàng thành công.',
          type: 'success',
        });
        history.push(ROUTER.CUSTOMER.INDEX);
      },
      onError: (err) => {
        const message = JSON.parse(err.response).message;
        notification.open({
          message: 'Thêm khách hàng thất bại!',
          description: `${message}`,
          type: 'error',
        });
      },
    });
  };

  render() {
    const { speed, depot, work_time_from, work_time_to } = this.props.configValues;
    const defaultValue = {
      speed,
      workTime:
        work_time_from && work_time_to
          ? [moment(work_time_from, 'HH:mm'), moment(work_time_to, 'HH:mm')]
          : ['', ''],
      start_location: depot,
      start_locationAddress: depot?.address,
      finish_location: depot,
      finish_locationAddress: depot?.address,
    };

    return (
      <div>
        <Row type="flex" justify="center">
          <Col span={24} lg={24}>
            <Card hoverable={false} bordered={!isBelowBreakpoint()}>
              <SampleRouteForm
                defaultValue={defaultValue}
                onSubmit={this.onSubmit}
                isEditMode={false}
              />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  configValues: state.configurationReducer.item,
  currentAddress: state.tomtomReducer.address,
  currentLatitude: state.tomtomReducer.latitude,
  currentLongitude: state.tomtomReducer.longitude,
});

const mapDispatchToProps = (dispatch) => ({
  insertCustomer: (payload, meta) => dispatch(customerActions.insertCustomer({ payload, meta })),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddSampleRoute));
