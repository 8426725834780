import { CheckOutlined, ReloadOutlined } from '@ant-design/icons';
import { Form, Row, Col, Input, Button, Typography, Select, InputNumber } from 'antd';
import React, { Component } from 'react';

import FeatureSearch from '../../../components/FormItems/FeatureSearch';

const { TextArea } = Input;
const FormItem = Form.Item;
const { Title } = Typography;
const { Option } = Select;
const layout = {
  labelCol: {
    span: 24,
  },
};
class VehicleForm extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      formRef: this.formRef,
      currentVehicleId: props?.defaultValue?.id || '',
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { defaultValue } = nextProps;
    const { currentVehicleId } = prevState;
    if (defaultValue?.id && defaultValue?.id !== currentVehicleId) {
      prevState.formRef.current.setFieldsValue(defaultValue);
      return {
        currentVehicleId: defaultValue?.id,
      };
    }
    return null;
  }

  onReset = () => {
    this.setState(() => ({
      optionFields: ['vehicle'],
    }));
    this.formRef.current.resetFields();
  };

  onFinish = (value) => {
    this.props.onSubmit(value);
  };

  render() {
    const { isEditMode, defaultValue } = this.props;
    const FormTitle = isEditMode ? 'Sửa thông tin phương tiện' : 'Thêm phương tiện';
    return (
      <div>
        <Title level={3} description="Thông báo">
          {FormTitle}
        </Title>
        <Form ref={this.formRef} initialValues={defaultValue} onFinish={this.onFinish} {...layout}>
          <Row gutter={15}>
            <Col span={12}>
              <FormItem
                label="Tên xe"
                name="label"
                rules={[
                  {
                    required: true,
                    message: 'Nhập tên xe của bạn!',
                  },
                ]}>
                <Input placeholder="Nhập tên xe ..." />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Loại phương tiện"
                name="vehicle_type"
                rules={[
                  {
                    required: true,
                    message: 'Chọn loại phương tiện!',
                  },
                ]}>
                <Select placeholder="Chọn loại phương tiện...">
                  <Option value="truck">Xe tải</Option>
                  <Option value="motor">Xe gắn máy</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Biển số xe"
                name="license_plate_number"
                rules={[
                  {
                    required: true,
                    message: 'Nhập biển số xe của bạn!',
                  },
                ]}>
                <Input placeholder="Nhập biển số xe..." />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Mức tiêu thụ nhiên liệu (lít/km)"
                name="fuel_per_km"
                initialValue={0}>
                <InputNumber style={{ width: '100%' }} min={0} />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Tải trọng (kg)" name="vehicle_capacity" initialValue={0}>
                <InputNumber style={{ width: '100%' }} min={0} />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Thể tích (m3)" name="vehicle_volume" initialValue={0}>
                <InputNumber style={{ width: '100%' }} min={0} />
              </FormItem>
            </Col>
            <Col span={24} lg={24}>
              <FormItem label="Đặc tính" name="defaultFeatureCode">
                <FeatureSearch
                  selectedValue={defaultValue?.defaultFeatureCode}
                  allowClear={false}
                  mode="multiple"
                />
              </FormItem>
            </Col>
            <Col span={24} lg={24}>
              <FormItem label="Mô tả" name="description">
                <TextArea placeholder="Nhập mô tả..." />
              </FormItem>
            </Col>
          </Row>

          <FormItem style={{ float: 'right', marginTop: '10px' }}>
            <Button
              type="primary"
              htmlType="submit"
              shape="round"
              icon={<CheckOutlined />}
              style={{ marginRight: 8 }}>
              Lưu
            </Button>
            <Button htmlType="reset" onClick={this.onReset} shape="round" icon={<ReloadOutlined />}>
              Nhập lại
            </Button>
          </FormItem>
        </Form>
      </div>
    );
  }
}

export default VehicleForm;
