import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ROUTER from '../../../config/router';
import RoutingPage from '../../Routing';
import Schedule from '../../Routing/schedule';
// import CVRP from '../../Routing/cvrp';

const Routing = () => (
  <Switch>
    <Route exact path={ROUTER.ROUTING.INDEX} component={RoutingPage} />
    {/* <Route exact path={ROUTER.ROUTING.CVRP} component={CVRP} /> */}
    <Route exact path={ROUTER.ROUTING.SCHEDULE} component={Schedule} />
  </Switch>
);
export default Routing;
