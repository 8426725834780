export const COLORS = [
  '#9ACD32',
  '#CD5C5C',
  '#DB7093',
  '#0000FF',
  '#BC8F8F',
  '#6A5ACD',
  '#32CD32',
  '#FFB6C1',
  '#00FFFF',
  '#FF1493',
  '#DAA520',
  '#20B2AA',
  '#BDB76B',
  '#BA55D3',
  '#7FFF00',
  '#87CEFA',
  '#FF6347',
  '#808000',
  '#1E90FF',
  '#FFA07A',
  '#FF0000',
  '#9966CC',
  '#FFA500',
  '#8B4513',
  '#00FF00',
  '#FFFF00',
  '#FF00FF',
  '#660066',
  '#006600',
  '#666666',
  '#993333',
  '#993366',
  '#6600CC',
  '#666633',
  '#669999',
  '#003366',
  '#9DE908',
  '#F59B61',
  '#F24072',
  '#C00757',
  '#ACA216',
  '#66CDA0',
  '#F29874',
  '#F0544D',
  '#C46CEF',
  '#8AC39E',
  '#8AD429',
  '#05762E',
  '#28C294',
  '#883F12',
];

export const DONE_COLOR = '#2F4F4F';
export const DISABLED_COLOR = '#D3D3D3';
