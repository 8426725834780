import { Tooltip } from 'antd';
import React, { Component } from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import { compose } from 'redux';

import ItemTypes from '../constants';

const dragSource = {
  beginDrag(props) {
    return {
      node: props.node,
      nodeIndex: props.nodeIndex,
      routeIndex: props.routeIndex,
    };
  },
};

const dropTarget = {
  drop(props, monitor) {
    if (
      monitor.getItem().routeIndex === props.routeIndex &&
      monitor.getItem().nodeIndex === props.nodeIndex
    ) {
      return;
    }

    if (props.onSwapNodes) {
      props.onSwapNodes(monitor.getItem(), props);
    }
  },
};

function collectDrop(connect) {
  return { connectDropTarget: connect.dropTarget() };
}

function collectDrag(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

class Node extends Component {
  render() {
    const { isDragging, connectDragSource, connectDropTarget, node, mainColor } = this.props;
    const opacity = isDragging ? 0.5 : 1;

    return (
      connectDragSource &&
      connectDropTarget &&
      connectDragSource(
        connectDropTarget(
          <div
            style={{
              height: 34,
              width: 'auto',
              minWidth: 20,
              opacity,
              position: 'relative',
              backgroundColor: '#ffffff',
              color: mainColor,
              border: `solid ${mainColor} 2px`,
              borderRadius: 4,
              padding: '2px 3px',
              cursor: 'pointer',
              display: 'inline-block',
              textAlign: 'center',
              alignItems: 'center',
            }}
            onClick={() => console.log('Not completed')}>
            <Tooltip title={node?.customer?.name} color={mainColor} trigger="click">
              {`${
                node?.shipping_code ||
                (node?.customer?.code ? node.customer.code.slice(0, 5) : '') ||
                (node?.order_code ? node.order_code : '')
              }`}
            </Tooltip>
          </div>,
        ),
      )
    );
  }
}

const dragDrop = compose(
  DropTarget(ItemTypes.NODE, dropTarget, collectDrop),
  DragSource(ItemTypes.NODE, dragSource, collectDrag),
);

export default dragDrop(Node);
