import { CloseOutlined, EditOutlined, CheckOutlined } from '@ant-design/icons';
import { Select, Button, Row, Col } from 'antd';
import lodash from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { actions as tomtomActions } from '../../../redux/TomtomRedux';
import { apiCall } from '../../../utils/api';

const { Option } = Select;

class SelectFormItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedAddress: props.selectingAddress || undefined,
      options: [],
      isChangingAddress: false,
      init: false,
    };

    this.onSearch = lodash.debounce(this.onSearch, 800);
  }

  componentDidMount() {
    if (this.props?.selectingAddress !== '') this.onSearch(this.props?.selectingAddress);
  }

  componentDidUpdate() {
    // console.log('state: ', this.state.selectedAddress);
    // console.log('props: ', this.props?.selectingAddress);
    if (this.state.selectedAddress !== this.props?.selectingAddress)
      this.onSearch(this.props?.selectingAddress);
  }

  // componentWillUnmount() {
  //   console.log(this.state);
  // }

  onSearch = async (value) => {
    if (value) {
      const APIKEY = process.env.REACT_APP_TOM_TOM_API_KEY;
      const baseurl = `https://api.tomtom.com/search/2/search/${value}.json`;
      const searchURL = `${baseurl}?key=${APIKEY}&countrySet=VN`;
      const api = {
        endPoint: searchURL,
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
      };
      const { response } = await apiCall(api);
      const addresses = response?.data.results?.map((v) => ({
        code: v.id,
        address: `${v.address.streetNumber ? `${v.address.streetNumber},` : ''} ${
          v.address.streetName ? `${v.address.streetName},` : ''
        }  ${v.address.municipalitySubdivision ? `${v.address.municipalitySubdivision},` : ''} ${
          v.address.localName || v.address.freeformAddress
        }`,
        lat: v.position.lat,
        lng: v.position.lon,
      }));
      // if (addresses?.length)
      this.setState({ options: addresses });
    }
    this.setState({ selectedAddress: this.props?.selectingAddress });
  };

  handleChangeAddForm = (value) => {
    const { setTomtomSearchAddress, setTomtomSearchLatitude, setTomtomSearchLongitude } =
      this.props;
    const locationInfo = this.state.options.find((item) => item.code === value.value);
    setTomtomSearchAddress(locationInfo.address);
    setTomtomSearchLatitude(locationInfo.lat);
    setTomtomSearchLongitude(locationInfo.lng);
    this.props.onChange(locationInfo);
  };

  handleChangeEditForm = (value) => {
    this.setState({ curAddressCode: value.value });
  };

  saveAddress = () => {
    const { setTomtomSearchAddress, setTomtomSearchLatitude, setTomtomSearchLongitude } =
      this.props;
    const locationInfo = this.state.options.find((item) => item.code === this.state.curAddressCode);
    this.setState({ isChangingAddress: false });
    setTomtomSearchAddress(locationInfo.address);
    setTomtomSearchLatitude(locationInfo.lat);
    setTomtomSearchLongitude(locationInfo.lng);

    // this.setState({ selectedAddress: locationInfo.address, isChangingAddress: false });
    this.props.onChange(locationInfo);
  };

  render() {
    const { options, isChangingAddress } = this.state;
    const { style, smallInput, selectedAddress, isEditMode } = this.props;
    return (
      <>
        {selectedAddress && isEditMode ? (
          <Row style={{ marginBottom: 5 }}>
            <Col span={22} style={{ display: isChangingAddress ? 'none' : '', fontSize: 'large' }}>
              {selectedAddress}
            </Col>
            <Col
              style={{
                display: isChangingAddress ? '' : 'none',
                width: smallInput ? '85%' : '94%',
              }}>
              <Select
                showSearch
                labelInValue
                style={style ? Object?.assign({ width: '100%' }, style) : { width: '100%' }}
                placeholder="Nhập địa chỉ"
                filterOption={false}
                onSearch={this.onSearch}
                onChange={this.handleChangeEditForm}
                // filterOption={(input, option) =>
                //   (option?.address ?? '').toLowerCase().includes(input.toLowerCase())
                // }
              >
                {options?.map((d) => (
                  <Option key={d.code} value={d.code}>
                    {d.address}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col span={2} style={{ display: isChangingAddress ? 'none' : '' }}>
              <Button
                style={{ padding: '3px', float: 'right' }}
                type="primary"
                icon={<EditOutlined />}
                title="Click để sửa"
                onClick={() => this.setState({ isChangingAddress: true })}
              />
            </Col>
            <Col
              style={{
                display: isChangingAddress ? '' : 'none',
                width: smallInput ? '15%' : '6%',
              }}>
              <Button
                style={{ padding: '3px', marginLeft: '3px', float: 'right', width: '45%' }}
                type="primary"
                icon={<CheckOutlined />}
                title="Click để lưu"
                disabled={!this.state.curAddressCode}
                onClick={this.saveAddress}
              />
              <Button
                style={{ padding: '3px', float: 'right', width: '45%' }}
                type="danger"
                icon={<CloseOutlined />}
                title="Click để quay lai"
                onClick={() => this.setState({ isChangingAddress: false })}
              />
            </Col>
          </Row>
        ) : (
          <Select
            showSearch
            labelInValue
            style={style ? Object.assign({ width: '100%' }, style) : { width: '100%' }}
            placeholder="Nhập địa chỉ"
            filterOption={false}
            onSearch={this.onSearch}
            onChange={this.handleChangeAddForm}>
            {options?.map((d) => (
              <Option key={d.code} value={d.code}>
                {d.address}
              </Option>
            ))}
          </Select>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedAddress: state.tomtomReducer.address,
});

const mapDispatchToProps = (dispatch) => ({
  setTomtomSearchAddress: (payload) => dispatch(tomtomActions.setMapAddress(payload)),
  setTomtomSearchLatitude: (payload) => dispatch(tomtomActions.setMapLatitude(payload)),
  setTomtomSearchLongitude: (payload) => dispatch(tomtomActions.setMapLongitude(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectFormItem);
