import { Button, Col, DatePicker, Divider, Row, Select, notification } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import ProductForm from './AddForm/ProductForm';
import ROUTER from '../../config/router';
import { actions as receivingVoucherActions } from '../../redux/ReceivingVoucherRedux';
import { actions as storageActions } from '../../redux/StorageRedux';
import { actions as productActions } from '../../redux/productRedux';

const { getProducts } = productActions;
const { getStorage, getStorageById } = storageActions;
const { addReceivingVoucher } = receivingVoucherActions;

const AddReceivingVoucher = ({
  getProducts,
  dataProducts,
  isFetching,
  getStorage,
  storageData,
  addReceivingVoucher,
  history,
}) => {
  const [storageCode, setStorageCode] = useState();
  const [receivingTime, setReceivingTime] = useState(moment().toISOString());
  const [submitData, setSubmitData] = useState([]);
  const [note, setNote] = useState('');

  useEffect(() => {
    getProducts({ page: -1, page_size: -1 });
    getStorage({ page: -1, page_size: -1 });
  }, []);

  useEffect(() => {
    if (storageData?.length && !storageCode) {
      setStorageCode(storageData[0].code);
    }
  }, [storageData]);

  const handleChangeNote = (value) => {
    setNote(value.target.value);
  };

  const handleChangeReceivingtime = (value) => {
    setReceivingTime(moment(value).toISOString());
  };

  const handleChangeStorageCode = (value) => {
    setStorageCode(value);
  };

  const getData = (product) => {
    const curData = [];
    product?.map((value, index) => {
      curData.push({
        category_code: value?.category?.code,
        category_name: value?.category?.name,
        expiry_date: value?.expiry_date,
        lot_image_url: '',
        lot_number: value?.lot_number,
        manufacturing_date: value?.manufacturing_date,
        product_code: value?.code,
        product_name: value?.name,
        product_secondary_code: value?.secondary_code,
        input_price: value?.input_price,
        quantity: value?.quantity,
        unit: {
          factor: value?.unit?.factor,
          primary_unit: value?.unit?.primary_unit,
          secondary_unit: value?.unit?.secondary_unit,
        },
      });
    });
    setSubmitData(curData);
  };

  const handleSubmit = () => {
    if (!storageCode) {
      notification.error({ message: 'Hãy chọn kho!' });
      return;
    }
    if (!submitData?.length) {
      notification.error({ message: 'Hãy chọn sản phẩm!' });
      return;
    }
    const curStorage = storageData?.length
      ? storageData.find((item) => item.code === storageCode)
      : null;
    const payload = {
      storage_code: storageCode,
      storage_level: curStorage?.level,
      storage_name: curStorage?.name,
      receiving_time: receivingTime,
      product_items: submitData,
      note,
    };
    addReceivingVoucher(payload, {
      onSuccess: () => {
        notification.success({ message: 'Thêm phiếu nhập thành công!' });
        history.push(ROUTER.INVENTORY.RECEIVING_VOUCHER);
      },
      onError: () => {
        notification.error({ message: 'Thêm phiếu nhập thất bại!' });
      },
    });
  };

  return (
    <>
      <h2>Tạo phiếu nhập mới</h2>
      <Row gutter={15}>
        <Col span={8} style={{ marginTop: 10, marginBottom: 20 }}>
          <h3 style={{ marginBottom: 10 }}>Chọn kho</h3>
          <Select
            placeholder="Chọn kho"
            value={storageCode}
            onChange={handleChangeStorageCode}
            style={{ width: '99%', float: 'right' }}>
            {storageData?.map((storage) => (
              <Select.Option key={storage?.code} value={storage?.code}>
                {storage?.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={8} style={{ marginTop: 10 }}>
          <h3 style={{ marginBottom: 10 }}>Thời gian nhập kho</h3>
          <DatePicker
            style={{ width: '100%' }}
            onChange={handleChangeReceivingtime}
            defaultValue={moment()}
            placeholder="Chọn ngày nhập"
            format="DD-MM-YYYY"
            allowClear={false}
          />
        </Col>
      </Row>

      <Row gutter={15}>
        <Col span={24} style={{ marginTop: 10 }}>
          <ProductForm
            products={dataProducts}
            disabled={isFetching}
            sendData={getData}
            handleChangeStorageCode={handleChangeStorageCode}
            storageData={storageData}
          />
        </Col>
      </Row>
      <Row gutter={15}>
        <Col span={24} style={{ marginTop: 10 }}>
          <TextArea
            placeholder="Ghi chú"
            onChange={handleChangeNote}
            // value={this.state.note}
          />
        </Col>
      </Row>
      <Divider />
      <Row gutter={15}>
        <Col lg={8} />
        <Col lg={16}>
          <Button
            type="primary"
            shape="round"
            style={{ width: '30%', marginLeft: '10%' }}
            onClick={handleSubmit}
            disabled={!storageCode || !submitData?.length}>
            Tạo phiếu nhập
          </Button>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  dataProducts: state?.productReducer?.data,
  isFetching: state?.productReducer?.isFetching,
  storageData: state?.storageReducer?.Storage?.data,
});

const mapDispatchToProps = (dispatch) => ({
  getProducts: (params) => dispatch(getProducts(params)),
  getStorage: (params) => dispatch(getStorage(params)),
  addReceivingVoucher: (payload, meta) => dispatch(addReceivingVoucher({ payload, meta })),
  getStorageById: (id) => dispatch(getStorageById(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddReceivingVoucher);
