export default {
  // common actions
  Add: 'Add',
  Save: 'Save',
  Clear: 'Clear',
  Search: 'Search',
  Filter: 'Filter',
  Reset: 'Reset',
  All: 'All',
  'Input text search': 'Input text search',

  // common words
  'Xuctienviet ©2020': 'Xuctienviet ©2020',
  Permission: 'Permission',
  Language: 'Language',
  Loading: 'Loading',
  Role: 'Role',
  Hello: 'Hello',
  Detail: 'Detail',
  Name: 'Name',
  Image: 'Image',
  Code: 'Code',
  Status: 'Status',
  State: 'Status',
  of: 'of',
  Activated: 'Activated',
  Deactivated: 'Deactivated',
  'Created at': 'Created at',
  CreatedAt: 'CreatedAt',
  'Updated at': 'Updated at',
  UpdatedAt: 'UpdatedAt',
  Address: 'Address',

  // objects
  Supplier: 'Supplier',
  Suppliers: 'Suppliers',
  'Suppliers List': 'Suppliers List',
  Category: 'Category',
  Categories: 'Categories',
  Product: 'Product',
  Products: 'Products',
  'Products List': 'Products List',
  Invoice: 'Invoice',
  Invoices: 'Invoices',
  Order: 'Order',
  Orders: 'Orders',
  Chart: 'Chart',
  Promotion: 'Promotion',
  Station: 'Station',
  'Account Manage': 'Account Manage',
  Api: 'Api',
  // product-supplier-category
  Price: 'Price',
  InputPrice: 'InputPrice',
  'Input Price': 'Input Price',
  'Listed Price': 'Listed Price',
  'Selling Price': 'Selling Price',
  SaleOff: 'SaleOff',
  'SaleOff Amount': 'SaleOff Amount',
  Quantity: 'Quantity',
  Weight: 'Weight',
  'Total Weight': 'Total Weight',
  Criteria: 'Criteria',

  // order
  OrderNumber: 'Order Number',
  'Order Number': 'Order Number',
  DeliveryDate: 'Delivery Date',
  'Delivery Date': 'Delivery Date',
  'Total Cost': 'Total Cost',
  'Order detail': 'Order detail',
  'Payment Amount': 'Payment Amount',
  pending: 'pending',
  received: 'received',
  processing: 'processing',
  shipping: 'shipping',
  shipped: 'shipped',
  completed: 'completed',
  returned: 'returned',
  cancelled: 'cancelled',

  // User form
  'Remember me': 'Remember me',
  'Log In': 'Log In',
  Register: 'Register',
  LogOut: 'LogOut',
  Username: 'Username',
  Password: 'Password',
  'Enter your username': 'Enter your username',
  'Enter your password': 'Enter your password',
  'Enter your new password': 'Enter your new password',
  'Enter your confirm password': 'Enter your confirm password',
  'Forgot password': 'Forgot password',
  'Please input your Username': 'Please input your Username',
  'Please input your Password': 'Please input your Password',
  'These credential do not match our records': 'These credential do not match our records',
  'Enter your email': 'Enter your email',
  Edit: 'Edit',
  'New Account': 'New Account',
  Email: 'Email',
  'Confirm Password': 'Confirm Password',
  'Select user role': 'Select user role',
  // Product form
  'Product Name': 'Product Name',
  'Input product name': 'Input product name',
  'Pick Product': 'Pick Product',

  // Error
  'Can not find order information': 'Can not find order information',
  'Can not find supplier information': 'Can not find supplier information',
};
