import { Button, notification, DatePicker, message, Modal, Checkbox } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as XLSX from 'xlsx';

import { actions as orderActions } from '../../../redux/OrderRedux';
import OrderList from '../../Order/components/List';

const SheetJSFT = [
  'xlsx',
  'xlsb',
  'xlsm',
  'xls',
  'xml',
  'csv',
  'txt',
  'ods',
  newFunction(),
  'uos',
  'sylk',
  'dif',
  'dbf',
  'prn',
  'qpw',
  '123',
  'wb*',
  'wq*',
  'html',
  'htm',
]
  .map((x) => `.${x}`)
  .join(',');

const OrderImportModal = (props) => {
  const { isOpen, onCancel } = props;
  const [selectedDeliveryDate, setSelectedDeliveryDate] = useState(moment().add(1, 'days'));
  const [mergeOrder, setMergeOrder] = useState(true);
  const [dataFromFile, setDataFromFile] = useState([]);
  const [didAddOrders, setDidAddOrders] = useState(false);

  const readExcelFile = (file) => {
    const promise = new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);

      reader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: 'buffer' });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      reader.onerror = (err) => reject(err);
    });

    promise
      .then((data) => {
        const recordFromFile = [];
        if (data.length) {
          data.map((record, i) => {
            if (record['Mã khách hàng']) {
              const newOrder = {
                code: `${record['STT']}-${i}`,
                customer: {
                  code: record['Mã khách hàng'] ? record['Mã khách hàng'].trim() : '',
                  name: record['Tên khách hàng'] ? record['Tên khách hàng'].trim() : '',
                  phone_number: record['SĐT'] ? record['SĐT'].trim() : '',
                  note: record['Ghi chú'] ? record['Ghi chú'].trim() : '',
                  location: { address: record['Địa chỉ'] ? record['Địa chỉ'].trim() : '' },
                },
                delivery_date: record['Ngày giao hàng']
                  ? moment(record['Ngày giao hàng'], 'DD/MM/YYYY').toISOString()
                  : '',
                demand: record['Trọng lượng (Kg)']
                  ? Number.parseFloat(record['Trọng lượng (Kg)'])
                  : 0,
                driver_code: record['Mã lái xe'] ? record['Mã lái xe'].trim() : '',
                feature_codes: [],
                shipping_code: record['Mã khách hàng'] ? record['Mã khách hàng'].trim() : '',
                money: 0,
                note: '',
                occupied_space: record['Thể tích (m3)']
                  ? Number.parseFloat(record['Thể tích (m3)'])
                  : 0,
                vehicle_type: record['Loại xe'] ? record['Loại xe'].trim() : '',
              };
              recordFromFile.push(newOrder);
            }
          });
        }
        if (dataFromFile.length > 0) setDataFromFile([]);
        setDataFromFile([...recordFromFile]);
        setDidAddOrders(true);
        message.success('Tải file thành công!');
      })
      .catch(() => {
        setDataFromFile([]);
        message.error('Tải file thất bại!');
      });
  };

  // Add many orders from excel file
  const handleAddOrders = () => {
    const defaultDeliveryDate = selectedDeliveryDate.toISOString();
    const payload = dataFromFile.map((order) => ({
      ...order,
      code: undefined,
      delivery_date: order?.delivery_date ? order?.delivery_date : defaultDeliveryDate,
      customer: { code: order?.customer?.code },
    }));

    props.insertManyOrder(
      {
        merge_order: !!mergeOrder,
        orders: payload,
      },
      {
        onSuccess: () => {
          notification.success({ message: 'Thêm đơn giao hàng thành công.' });
          setDidAddOrders(false);
          onCancel();
          if (props.onSuccess) {
            props.onSuccess(defaultDeliveryDate);
          }
        },
        onError: () => notification.error({ message: 'Thêm đơn giao hàng thất bại!' }),
      },
    );
  };

  return (
    <Modal
      style={{ minWidth: 1200 }}
      title={
        <>
          <span>Nhập đơn giao hàng từ file:</span>
          <input
            style={{ marginLeft: 16 }}
            type="file"
            accept={SheetJSFT}
            label="Tải lên File"
            onChange={(e) => {
              const file = e.target.files[0];
              readExcelFile(file);
            }}
          />
          <span style={{ marginLeft: 16 }}>Ngày giao hàng:</span>
          <DatePicker
            style={{ width: 170, marginLeft: 15 }}
            onChange={(e) => setSelectedDeliveryDate(e)}
            value={selectedDeliveryDate}
            format="DD/MM/YYYY"
          />
          <span style={{ marginLeft: 16 }}>Gộp đơn của cùng KH:</span>
          <Checkbox
            style={{ marginLeft: 15 }}
            onChange={(e) => {
              setMergeOrder(e.target.checked);
            }}
            checked={mergeOrder}
          />
        </>
      }
      open={isOpen}
      onCancel={onCancel}
      footer={
        <>
          <Button type="primary" shape="round" disabled={!didAddOrders} onClick={handleAddOrders}>
            Nhập
          </Button>
          <Button type="ghost" shape="round" onClick={onCancel}>
            Huỷ
          </Button>
        </>
      }>
      <OrderList
        // isFetching={isFetchingOrder}
        dataSource={dataFromFile}
        collapseTable
        mode="routing"
      />
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  insertManyOrder: (payload, meta) => dispatch(orderActions.insertManyOrder(payload, meta)),
});

export default connect(null, mapDispatchToProps)(OrderImportModal);

function newFunction() {
  return 'fods';
}
