import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { PREFIX, typesWithPrefix } from './config';
import { API_URLS } from '../config/api';
import { apiCallPromise } from '../utils/api';

const _types = typesWithPrefix(PREFIX.DASHBOARD);

const types = {
  STATISTIC_DRIVING_TIME: _types('STATISTIC_DRIVING_TIME'),
  STATISTIC_NODES: _types('STATISTIC_NODES'),
  STATISTIC_ORDERS: _types('STATISTIC_ORDERS'),
  STATISTIC_REAL_DISTANCE: _types('STATISTIC_REAL_DISTANCE'),
  CONPARE_ESTIMATE_AND_REAL: _types('CONPARE_ESTIMATE_AND_REAL'),
};

const initialState = {
  drvingTime: {
    sale: { isFetching: false, didInvalidate: true, lastUpdated: 0, data: null },
    meta: {
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      query: {},
    },
  },
  nodes: {
    sale: { isFetching: false, didInvalidate: true, lastUpdated: 0, data: null },
    meta: {
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      query: {},
    },
  },
  orders: {
    sale: { isFetching: false, didInvalidate: true, lastUpdated: 0, data: null },
    meta: {
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      query: {},
    },
  },
  realDistance: {
    sale: { isFetching: false, didInvalidate: true, lastUpdated: 0, data: null },
    meta: {
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      query: {},
    },
  },
  Compare: {
    sale: { isFetching: false, didInvalidate: true, lastUpdated: 0, data: null },
    meta: {
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      query: {},
    },
  },
};

const thunkActions = {
  getStatisticDrivingTime: createAsyncThunk(types.STATISTIC_DRIVING_TIME, async (query) => {
    const params = {
      page: query.page,
      page_size: query.page_size,
      driver_codes: query.driver_codes,
      time_start: query.time_start,
      time_end: query.time_end,
    };

    const api = API_URLS.DASHBOARD.drivingTime(params);
    const { response } = await apiCallPromise(api);

    return response.data;
  }),

  getStatisticNodes: createAsyncThunk(types.STATISTIC_NODES, async (query) => {
    const params = {
      page: query.page,
      page_size: query.page_size,
      driver_codes: query.driver_codes,
      time_start: query.time_start,
      time_end: query.time_end,
    };
    const api = API_URLS.DASHBOARD.getStatisticNodes(params);
    const { response } = await apiCallPromise(api);

    return response.data;
  }),

  getStatisticOrders: createAsyncThunk(types.STATISTIC_ORDERS, async (query) => {
    const params = {
      page: query.page,
      page_size: query.page_size,
      driver_codes: query.driver_codes,
      time_start: query.time_start,
      time_end: query.time_end,
    };
    const api = API_URLS.DASHBOARD.getStatisticOrders(params);
    const { response } = await apiCallPromise(api);

    return response.data;
  }),

  getStatisticRealDistance: createAsyncThunk(types.STATISTIC_REAL_DISTANCE, async (query) => {
    const params = {
      page: query.page,
      page_size: query.page_size,
      driver_codes: query.driver_codes,
      time_start: query.time_start,
      time_end: query.time_end,
    };
    const api = API_URLS.DASHBOARD.getStatisticRealDistance(params);
    const { response } = await apiCallPromise(api);

    return response.data;
  }),

  getCompareEstimateAndReal: createAsyncThunk(types.CONPARE_ESTIMATE_AND_REAL, async (query) => {
    const params = {
      page: query.page,
      page_size: query.page_size,
      driver_codes: query.driver_codes,
      route_codes: query.route_codes,
      time_start: query.time_start,
      time_end: query.time_end,
    };
    const api = API_URLS.DASHBOARD.compareEstimateAndReal(params);
    const { response } = await apiCallPromise(api);

    return response.data;
  }),
};

const dashboardSlice = createSlice({
  name: 'dashboardReducer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(thunkActions.getStatisticDrivingTime.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(thunkActions.getStatisticDrivingTime.fulfilled, (state, { payload }) => {
        const { data, page, page_size, total } = payload;
        state.drvingTime.sale.data = data;
        state.drvingTime.sale.didInvalidate = true;
        state.drvingTime.sale.isFetching = false;
        state.drvingTime.meta.pagination.page = page;
        state.drvingTime.meta.pagination.pageSize = page_size;
        state.drvingTime.meta.pagination.total = total;
      })
      .addCase(thunkActions.getStatisticDrivingTime.rejected, (state) => {
        state.drvingTime.sale.isFetching = false;
      })
      .addCase(thunkActions.getStatisticNodes.pending, (state) => {
        state.drvingTime.sale.isFetching = true;
      })
      .addCase(thunkActions.getStatisticNodes.fulfilled, (state, { payload }) => {
        const { data, page, page_size, total } = payload;

        state.nodes.sale.data = data;
        state.nodes.sale.didInvalidate = true;
        state.nodes.sale.isFetching = false;
        state.nodes.meta.pagination.page = page;
        state.nodes.meta.pagination.pageSize = page_size;
        state.nodes.meta.pagination.total = total;
      })
      .addCase(thunkActions.getStatisticNodes.rejected, (state) => {
        state.nodes.sale.isFetching = false;
      })
      .addCase(thunkActions.getStatisticOrders.pending, (state) => {
        state.nodes.sale.isFetching = true;
      })
      .addCase(thunkActions.getStatisticOrders.fulfilled, (state, { payload }) => {
        const { data, page, page_size, total } = payload;

        state.orders.sale.data = data;
        state.orders.sale.didInvalidate = true;
        state.orders.sale.isFetching = false;
        state.orders.meta.pagination.page = page;
        state.orders.meta.pagination.pageSize = page_size;
        state.orders.meta.pagination.total = total;
      })
      .addCase(thunkActions.getStatisticOrders.rejected, (state) => {
        state.orders.sale.isFetching = false;
      })
      .addCase(thunkActions.getStatisticRealDistance.pending, (state) => {
        state.realDistance.sale.isFetching = true;
      })
      .addCase(thunkActions.getStatisticRealDistance.fulfilled, (state, { payload }) => {
        const { data, page, page_size, total } = payload;

        state.realDistance.sale.data = data;
        state.realDistance.sale.didInvalidate = true;
        state.realDistance.sale.isFetching = false;
        state.realDistance.meta.pagination.page = page;
        state.realDistance.meta.pagination.pageSize = page_size;
        state.realDistance.meta.pagination.total = total;
      })
      .addCase(thunkActions.getStatisticRealDistance.rejected, (state) => {
        state.realDistance.sale.isFetching = false;
      })
      .addCase(thunkActions.getCompareEstimateAndReal.pending, (state) => {
        state.Compare.sale.isFetching = true;
      })
      .addCase(thunkActions.getCompareEstimateAndReal.fulfilled, (state, { payload }) => {
        const { data, page, page_size, total } = payload;

        state.Compare.sale.data = data;
        state.Compare.sale.didInvalidate = true;
        state.Compare.sale.isFetching = false;
        state.Compare.meta.pagination.page = page;
        state.Compare.meta.pagination.pageSize = page_size;
        state.Compare.meta.pagination.total = total;
      })
      .addCase(thunkActions.getCompareEstimateAndReal.rejected, (state) => {
        state.Compare.sale.isFetching = false;
      });
  },
});

export const actions = { ...dashboardSlice.actions, ...thunkActions };
export const { reducer } = dashboardSlice;
