import produce from 'immer';

import { PREFIX, typesWithPrefix } from './config';

const _types = typesWithPrefix(PREFIX.TOMTOM);
const TYPE = {
  SET_LATITUDE: _types('SET_LATITUDE'),
  SET_LONGITUDE: _types('SET_LONGITUDE'),
  SET_ADDRESS: _types('SET_ADDRESS'),
  CLEAR_STORE: _types('CLEAR_STORE'),
};

export const actions = {
  setMapLatitude: (payload) => ({
    type: TYPE.SET_LATITUDE,
    payload,
    meta: {
      prefix: [PREFIX.TOMTOM],
    },
  }),

  setMapLongitude: (payload) => ({
    type: TYPE.SET_LONGITUDE,
    payload,
    meta: {
      prefix: [PREFIX.TOMTOM],
    },
  }),

  setMapAddress: (payload) => ({
    type: TYPE.SET_ADDRESS,
    payload,
    meta: {
      prefix: [PREFIX.TOMTOM],
    },
  }),

  clearStore: () => ({
    type: TYPE.CLEAR_STORE,
    meta: {
      prefix: [PREFIX.TOMTOM],
    },
  }),
};

const initialState = {
  isFetching: false,
  didInvalidate: true,
  latitude: '',
  longitude: '',
  address: '',
};

export const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case TYPE.SET_LATITUDE:
        draft.latitude = action.payload;
        break;
      case TYPE.SET_LONGITUDE:
        draft.longitude = action.payload;
        break;
      case TYPE.SET_ADDRESS:
        draft.address = action.payload;
        break;
      case TYPE.CLEAR_STORE:
        return initialState;
      default:
        return draft;
    }
  });
