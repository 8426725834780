import { Col, Row, Tabs } from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Card from '../../components/Card';
import { actions as vehicleActions } from '../../redux/VehicleRedux';
import { isBelowBreakpoint } from '../../utils/window';
import DetailList from './components/DetailList';
import VehicleBilling from './components/Tab/VehicleBilling';
import PartList from './components/Tab/VehicleExpirys/index';
import VehicleHistory from './components/Tab/VehicleExpirysHistory';

const { TabPane } = Tabs;

class DetailVehicle extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    const {
      getVehicleById,
      match: {
        params: { vehicleInfoId },
      },
    } = this.props;
    getVehicleById(vehicleInfoId);
  };

  render() {
    const {
      curentVehicleData,
      match: {
        params: { vehicleInfoId },
      },
    } = this.props;
    const defaultValue = {
      id: curentVehicleData.id,
      label: curentVehicleData.label,
      vehicle_type: curentVehicleData.vehicle_type,
      license_plate_number: curentVehicleData.license_plate_number,
      description: curentVehicleData.description,
      vehicle_capacity: curentVehicleData.vehicle_capacity,
      vehicle_volume: curentVehicleData.vehicle_volume,
      fuel_per_km: curentVehicleData.fuel_per_km,
      defaultFeatureCode: curentVehicleData.feature_codes,
    };
    return (
      <div>
        <Row type="flex" justify="center">
          <Col span={24} lg={24}>
            <Card hoverable={false} bordered={!isBelowBreakpoint()}>
              <DetailList defaultValue={defaultValue} />
              <Tabs defaultActiveKey="vehicle-part">
                <TabPane tab="Linh kiện xe" key="vehicle-part">
                  {/* <Button
                    shape="round"
                    type="primary"
                    onClick={this.handleOnAdd}
                    icon={<PlusOutlined />}>
                    Thêm linh kiện
                  </Button> */}
                  <PartList vehicleId={vehicleInfoId} />
                </TabPane>
                <TabPane tab="Lịch sử bảo dưỡng" key="vehicle-history">
                  <VehicleHistory vehicleId={vehicleInfoId} />
                </TabPane>
                <TabPane tab="Chi phí bảo dưỡng" key="vehicle-billing">
                  <VehicleBilling vehicleId={vehicleInfoId} />
                </TabPane>
              </Tabs>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ vehicleReducer }) => ({
  curentVehicleData: vehicleReducer.currentVehicle,
});

const mapDispatchToProps = (dispatch) => {
  const { getVehicleById } = vehicleActions;
  return {
    getVehicleById: (vehicleId) => dispatch(getVehicleById(vehicleId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DetailVehicle));
