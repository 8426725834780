import { CheckOutlined, RedoOutlined } from '@ant-design/icons';
import { Row, Col, Button, Form, Input, notification } from 'antd';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Card from '../../../../components/Card';
import { API_URLS } from '../../../../config/api';
import ROUTER from '../../../../config/router';
import { apiCall } from '../../../../utils/api';

class RegisterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  onFinish = async (value) => {
    const { address, customer_name, description, email, hotline, name, phone } = value;
    const { history } = this.props;
    this.setState({
      isLoading: true,
    });
    const api = API_URLS.COMPANY.registerCompany({
      address,
      customer_name,
      description,
      email,
      hotline,
      name,
      phone,
    });
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      this.setState({
        isLoading: false,
      });
      notification.success({ message: 'Đăng ký thành công' });
      history.push(ROUTER.AUTH.LOGIN);
    } else {
      notification.error({ message: `Thêm tài khoản thất bại. ${response.data.message}` });
      this.setState({
        isLoading: false,
      });
    }
  };

  validatePhoneNumber = (rule, value, callback) => {
    const re = /^(0)[1-9]([0-9]{8}[0-9]?)\b/;
    if (value && !re.test(value)) {
      callback('Số điện thoại không hợp lệ');
    } else {
      callback();
    }
  };

  render() {
    const formItemLayout = {
      labelCol: {
        span: 24,
      },
    };
    return (
      <Row>
        <Col sm={{ span: 16, offset: 4 }} lg={{ span: 8, offset: 8 }}>
          <div style={{ marginTop: 100 }}>
            <Card hoverable={false} clickable={false} hasShadow>
              <h1 style={{ textAlign: 'center' }}>Đăng ký</h1>
              <Form onFinish={this.onFinish} {...formItemLayout}>
                <Form.Item
                  name="customer_name"
                  label="Tên công ty"
                  rules={[{ required: true, message: 'Nhập tên công ty!' }]}>
                  <Input placeholder="Nhập tên công ty" />
                </Form.Item>

                <Row gutter={15}>
                  <Col span={12}>
                    <Form.Item
                      name="email"
                      label="Email"
                      rules={[
                        { required: true, message: 'Nhập email!' },
                        { type: 'email', message: 'Email không hợp lệ!' },
                      ]}>
                      <Input placeholder="Nhập email" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="hotline"
                      label="SĐT công ty"
                      rules={[
                        { required: true, message: 'Nhập số điện thoại công ty!' },
                        { validator: this.validatePhoneNumber },
                      ]}>
                      <Input placeholder="Nhập số điện thoại công ty" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={15}>
                  <Col span={12}>
                    <Form.Item name="name" label="Tên liên hệ">
                      <Input placeholder="Nhập địa chỉ" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="phone"
                      label="SĐT liên hệ"
                      rules={[{ validator: this.validatePhoneNumber }]}>
                      <Input placeholder="Nhập số điện thoại liên hệ" />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item name="address" label="Địa chỉ">
                  <Input placeholder="Nhập địa chỉ" />
                </Form.Item>

                <Form.Item name="description" label="Ghi chú">
                  <Input placeholder="Nhập ghi chú" />
                </Form.Item>

                <Row gutter={15}>
                  <Col span={12}>
                    <Link to="/">
                      <Button style={{ float: 'right' }} shape="round">
                        <RedoOutlined />
                        Quay lại
                      </Button>
                    </Link>
                  </Col>
                  <Col span={12}>
                    <Button type="primary" htmlType="submit" shape="round">
                      <CheckOutlined />
                      Đăng ký
                    </Button>
                  </Col>
                </Row>
              </Form>
              <div>
                <span style={{ float: 'left' }} />
              </div>
            </Card>
          </div>
        </Col>
        <Button />
      </Row>
    );
  }
}

export default RegisterForm;
