import { Col, Row, notification } from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Card from '../../components/Card';
import ROUTER from '../../config/router';
import { actions as vehicleActions } from '../../redux/VehicleRedux';
import { isBelowBreakpoint } from '../../utils/window';
import VehicleForm from './components/Form';

class EditVehicle extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    const {
      getVehicleById,
      match: {
        params: { vehicleInfoId },
      },
    } = this.props;
    getVehicleById(vehicleInfoId);
  };

  onSubmit = (values) => {
    const {
      updateVehicle,
      getVehicleById,
      history,
      match: {
        params: { vehicleInfoId },
      },
    } = this.props;
    const {
      label,
      description,
      fuel_per_km,
      license_plate_number,
      vehicle_type,
      vehicle_volume,
      vehicle_capacity,
      defaultFeatureCode,
    } = values;
    const vehicleInfoPayload = {
      label,
      description,
      fuel_per_km,
      license_plate_number,
      vehicle_type,
      vehicle_volume,
      vehicle_capacity,
      feature_codes: defaultFeatureCode,
    };
    updateVehicle(vehicleInfoId, vehicleInfoPayload, {
      onSuccess: () => {
        notification.open({
          message: 'Sửa thành công',
          type: 'success',
        });
        getVehicleById(vehicleInfoId);
        history.push(ROUTER.VEHICLE.INDEX);
      },
      onError: () =>
        notification.open({
          message: `${'Sửa thất bại'}`,
          type: 'error',
        }),
    });
  };

  render() {
    const { curentVehicleData } = this.props;
    const defaultValue = {
      id: curentVehicleData.id,
      label: curentVehicleData.label,
      vehicle_type: curentVehicleData.vehicle_type,
      license_plate_number: curentVehicleData.license_plate_number,
      description: curentVehicleData.description,
      vehicle_capacity: curentVehicleData.vehicle_capacity,
      vehicle_volume: curentVehicleData.vehicle_volume,
      fuel_per_km: curentVehicleData.fuel_per_km,
      defaultFeatureCode: curentVehicleData.feature_codes,
    };
    return (
      <div>
        <Row type="flex" justify="center">
          <Col span={24} lg={24}>
            <Card hoverable={false} bordered={!isBelowBreakpoint()}>
              <VehicleForm defaultValue={defaultValue} onSubmit={this.onSubmit} isEditMode />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ vehicleReducer }) => ({
  curentVehicleData: vehicleReducer.currentVehicle,
});

const mapDispatchToProps = (dispatch) => {
  const { getVehicleById, updateVehicle } = vehicleActions;
  return {
    getVehicleById: (vehicleId) => dispatch(getVehicleById(vehicleId)),
    updateVehicle: (vehicleId, payload, meta) => dispatch(updateVehicle(vehicleId, payload, meta)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditVehicle));
