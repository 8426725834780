import produce from 'immer';
import moment from 'moment';

import { isCallingApi, isSuccessfulApiCall, isFailedApiCall } from './actionDedicate';
import { PREFIX, typesWithPrefix } from './config';
import { API_URLS } from '../config/api';
import { apiCall } from '../utils/api';
const _types = typesWithPrefix(PREFIX.ORDER);

const types = {
  GET_ORDERS: _types('GET_ORDERS'),
  GET_COMPANYS: _types('GET_COMPANYS'),
  GET_ALL_ORDERS: _types('GET_ALL_ORDERS'),
  GET_ALL_ROUTING_ORDERS: _types('GET_ALL_ROUTING_ORDERS'),
  GET_ORDERS_CHANGE_PAGE: _types('GET_ORDERS_CHANGE_PAGE'),
  INVALIDATE_ORDERS: _types('INVALIDATE_ORDERS'),
  GET_ORDER_BY_CODE: _types('GET_ORDER_BY_CODE'),
  UPDATE_ORDER: _types('UPDATE_ORDER'),
  INSERT_ORDER: _types('INSERT_ORDER'),
  UPDATE_ORDER_STATUS: _types('UPDATE_ORDER_STATUS'),
};

export const actions = {
  //GET LIST ORDER
  getOrdersIfNeed: (filterOption) => (dispatch, getState) => {
    const state = getState();
    const isFetching = state.orderReducer.isFetching;
    const didInvalidate = state.orderReducer.didInvalidate;
    const { query, meta } = state.orderReducer;
    const isQueryChanged =
      query.status !== filterOption.status ||
      query.search !== filterOption.search ||
      query.vehicle_type !== filterOption.vehicle_type ||
      query.delivery_date !== filterOption.delivery_date ||
      meta.page !== filterOption.page ||
      meta.page_size !== filterOption.page_size;

    if ((!isFetching && didInvalidate) || (!isFetching && isQueryChanged))
      dispatch(actions.getOrders(filterOption));
  },

  getOrders: (query) => async (dispatch) => {
    const params = {
      page: query.page,
      page_size: query.page_size,
      search: query.search,
      status: query.status,
      vehicle_type: query.vehicle_type,
      delivery_date: query.delivery_date,
    };

    dispatch(actions.gettingOrders());
    const api = API_URLS.ORDER.getOrders(params);
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(
        actions.getOrdersSuccess({
          items: response.data.data,
          meta: {
            page: response.data.page,
            page_size: response.data.page_size,
            total: response.data.total,
          },
          query: {
            search: params.search,
            status: params.status,
            vehicle_type: params.vehicle_type,
            delivery_date: params.delivery_date,
          },
        }),
      );
    } else {
      dispatch(actions.getOrdersFailure(params));
    }
  },
  gettingOrders: () => ({
    type: types.GET_ORDERS,
    meta: { prefix: [PREFIX.ORDER, PREFIX.API_CALLING] },
  }),

  getOrdersSuccess: (payload) => ({
    type: types.GET_ORDERS,
    payload,
    meta: {
      prefix: [PREFIX.ORDER, PREFIX.API_CALLED_SUCCESS],
    },
  }),

  getOrdersFailure: (query) => ({
    type: types.GET_ORDERS,
    query,
    meta: {
      prefix: [PREFIX.ORDER, PREFIX.API_CALLED_FAILURE],
    },
  }),

  // CHANGE PAGE
  changePage: (page, page_size, total) => ({
    type: types.GET_ORDERS_CHANGE_PAGE,
    payload: {
      page,
      page_size,
      total,
    },
    meta: {
      prefix: [PREFIX.ORDER],
    },
  }),

  // INVALIDATE ROLES
  invalidateOrders: () => ({
    type: types.INVALIDATE_ORDERS,
    meta: {
      prefix: [PREFIX.ORDER, PREFIX.API_CALLED_SUCCESS],
    },
  }),

  // GET ORDER BY CODE
  getOrderByCode: (code) => async (dispatch) => {
    dispatch(actions.gettingOrderByCode());
    const api = API_URLS.ORDER.getOrderByCode(code);
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(actions.getOrderByCodeSuccess(response.data.data));
    } else {
      dispatch(actions.getOrderByCodeFailure());
    }
  },
  gettingOrderByCode: () => ({
    type: types.GET_ORDER_BY_CODE,
    meta: { prefix: [PREFIX.ORDER, PREFIX.API_CALLING] },
  }),
  getOrderByCodeSuccess: (payload) => ({
    type: types.GET_ORDER_BY_CODE,
    meta: { prefix: [PREFIX.ORDER, PREFIX.API_CALLED_SUCCESS] },
    payload,
  }),
  getOrderByCodeFailure: () => ({
    type: types.GET_ORDER_BY_CODE,
    meta: { prefix: [PREFIX.ORDER, PREFIX.API_CALLED_FAILURE] },
  }),

  //GET ALL DRIVERS
  getAllOrders: () => async (dispatch) => {
    const api = API_URLS.ORDER.getOrders({ page: -1, page_size: -1 });
    dispatch(actions.gettingAllOrders());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(
        actions.getAllOrdersSuccess({
          items: response.data.data,
        }),
      );
    } else {
      dispatch(actions.getAllOrdersFailure(params));
    }
  },
  gettingAllOrders: () => ({
    type: types.GET_ALL_ORDERS,
    meta: { prefix: [PREFIX.ORDER, PREFIX.API_CALLING] },
  }),

  getAllOrdersSuccess: (payload) => ({
    type: types.GET_ALL_ORDERS,
    payload,
    meta: {
      prefix: [PREFIX.ORDER, PREFIX.API_CALLED_SUCCESS],
    },
  }),

  getAllOrdersFailure: (query) => ({
    type: types.GET_ALL_ORDERS,
    query,
    meta: {
      prefix: [PREFIX.ORDER, PREFIX.API_CALLED_FAILURE],
    },
  }),

  // GET ALL ORDERS FOR ROUTING
  getAllRoutingOrders: (delivery_date) => async (dispatch) => {
    const api = API_URLS.ORDER.getOrders({ page: -1, page_size: -1, delivery_date });
    dispatch(actions.gettingAllRoutingOrders());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(
        actions.getAllRoutingOrdersSuccess({
          items: response.data.data,
          query: {
            delivery_date,
          },
        }),
      );
    } else {
      dispatch(actions.getAllRoutingOrdersFailure(params));
    }
  },
  gettingAllRoutingOrders: () => ({
    type: types.GET_ALL_ROUTING_ORDERS,
    meta: { prefix: [PREFIX.ORDER, PREFIX.API_CALLING] },
  }),

  getAllRoutingOrdersSuccess: (payload) => ({
    type: types.GET_ALL_ROUTING_ORDERS,
    payload,
    meta: {
      prefix: [PREFIX.ORDER, PREFIX.API_CALLED_SUCCESS],
    },
  }),

  getAllRoutingOrdersFailure: (query) => ({
    type: types.GET_ALL_ROUTING_ORDERS,
    query,
    meta: {
      prefix: [PREFIX.ORDER, PREFIX.API_CALLED_FAILURE],
    },
  }),

  //INSERT ORDER
  insertOrder: (payload, meta) => async (dispatch) => {
    const api = API_URLS.ORDER.insertOrder();
    dispatch(actions.insertingOrder());
    const { response, error } = await apiCall({
      ...api,
      payload,
    });
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(actions.insertOrderSuccess());
      // dispatch(actions.getOrders({ page: 1, page_size: 10 }));
      if (meta && meta.onSuccess) {
        meta.onSuccess(response.data.data);
      }
    } else {
      dispatch(actions.insertOrderFailure());
      if (meta && meta.onError) {
        meta.onError(error);
      }
    }
  },
  insertingOrder: () => ({
    type: types.INSERT_ORDER,
    meta: { prefix: [PREFIX.ORDER, PREFIX.API_CALLING] },
  }),

  insertOrderSuccess: () => ({
    type: types.INSERT_ORDER,
    meta: { prefix: [PREFIX.ORDER, PREFIX.API_CALLED_SUCCESS] },
  }),

  insertOrderFailure: () => ({
    type: types.INSERT_ORDER,
    meta: { prefix: [PREFIX.ORDER, PREFIX.API_CALLED_FAILURE] },
  }),

  insertManyOrder: (payload, meta) => async (dispatch) => {
    const api = API_URLS.ORDER.insertManyOrder(payload);
    const params = {
      page: 1,
      pageSize: 10,
    };
    const { response, error } = await apiCall(api);
    if (!error && (response.status === 200 || response.status === 201)) {
      if (meta && meta.onSuccess) {
        meta.onSuccess(response);
        dispatch(actions.getOrders(params));
      }
    } else if (meta && meta.onError) {
      meta.onError(error);
    }
    return { response, error };
  },

  //UPDATE ORDER
  updateOrder: (code, payload, meta) => async (dispatch, getState) => {
    const api = API_URLS.ORDER.updateOrder(code, payload);
    dispatch(actions.updatingOrder());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(actions.updateOrderSuccess(response.data.data));
      if (meta && meta.onSuccess) meta.onSuccess();
    } else {
      dispatch(actions.updateOrderFailure(error));
      if (meta && meta.onError) meta.onError(error);
    }
  },
  updatingOrder: () => ({
    type: types.UPDATE_ORDER,
    meta: { prefix: [PREFIX.ORDER, PREFIX.API_CALLING] },
  }),
  updateOrderSuccess: (payload) => ({
    type: types.UPDATE_ORDER,
    meta: {
      prefix: [PREFIX.ORDER, PREFIX.API_CALLED_SUCCESS],
    },
    payload,
  }),
  updateOrderFailure: () => ({
    type: types.UPDATE_ORDER,
    meta: {
      prefix: [PREFIX.ORDER, PREFIX.API_CALLED_FAILURE],
    },
  }),

  //CHANGE LIST ORDER WHEN ADDING ORDER TO ROUTE (status: 'Ordered' -> 'Scheduled')
  updateOrderStatus: (index) => ({
    index,
    type: types.UPDATE_ORDER_STATUS,
    meta: { prefix: [PREFIX.ORDER, PREFIX.API_CALLED_SUCCESS] },
  }),
};

const initialState = {
  isFetching: false,
  didInvalidate: true,
  lastUpdated: moment().unix(),
  currentOrder: {},
  allItems: [],
  items: [],
  query: {},
  meta: {
    page: 1,
    page_size: 20,
    total: 0,
  },
  allRoutingOrders: {
    items: [],
    query: {},
  },
};

export const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.GET_ORDERS:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          const { items, meta, query } = action.payload;
          draft.isFetching = false;
          draft.didInvalidate = false;
          draft.lastUpdated = moment.unix();
          draft.items = items;
          draft.meta = meta;
          draft.query = query;
        } else if (isFailedApiCall(action)) {
          draft.isFetching = false;
          draft.didInvalidate = false;
        }
        break;
      case types.GET_ORDERS_CHANGE_PAGE:
        draft.meta = action.payload;
        break;
      case types.INVALIDATE_ORDERS:
        draft.didInvalidate = true;
        break;
      case types.GET_ALL_ORDERS:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          const { items } = action.payload;
          draft.isFetching = false;
          draft.didInvalidate = false;
          draft.allItems = items;
        } else if (isFailedApiCall(action)) {
          draft.isFetching = false;
          draft.didInvalidate = false;
        }
        break;
      case types.GET_ALL_ROUTING_ORDERS:
        if (isCallingApi(action)) {
          draft.allRoutingOrders.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          const { items, query } = action.payload;
          draft.allRoutingOrders.isFetching = false;
          draft.allRoutingOrders.items = items;
          draft.allRoutingOrders.query = query;
        } else if (isFailedApiCall(action)) {
          draft.allRoutingOrders.isFetching = false;
        }
        break;
      case types.GET_ORDER_BY_CODE:
      case types.UPDATE_ORDER:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          draft.currentOrder = action.payload;
          draft.isFetching = false;
        } else if (isFailedApiCall(action)) {
          draft.isFetching = false;
        }
        break;
      case types.UPDATE_ORDER_STATUS:
        draft.items.splice(action.index, 1);
        break;
      default:
        return draft;
    }
  });
