import { Col, Input, Row } from 'antd';
import React, { Component } from 'react';

const { Search } = Input;

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = { dataFilter: { search: '' } };
  }

  onSearch = (field) => {
    const { dataFilter } = this.state;
    dataFilter.search = field;
    this.setState({ dataFilter });
    this.props.onSearch(dataFilter);
  };

  render() {
    return (
      <Row gutter={15}>
        <Col span={12}>
          <Search
            onSearch={this.onSearch}
            style={{ width: '100%' }}
            placeholder="Tên lịch mẫu"
            enterButton
            allowClear
          />
        </Col>
      </Row>
    );
  }
}

export default Filter;
