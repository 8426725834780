import { Row, Col, Button } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Login from './wrapper';
import { actions as authActions } from '../../../redux/AuthRedux';

class LoginContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromProps(nextProps) {
    const { history, forwardLocation, isAuthenticated, hasConfiguration } = nextProps;
    if (isAuthenticated) {
      // if (!hasConfiguration) {
      //   history.push('/configuration');
      // } else {
      if (forwardLocation && forwardLocation.pathname) {
        history.push(forwardLocation.pathname);
      } else {
        history.push('/');
      }
      // }
    }

    return null;
  }

  doLogin = (values) => {
    const { username, password, remember } = values;
    this.props.login(username, password, remember);
  };

  render() {
    const { isFetching, error } = this.props;
    return (
      <Row>
        <Col sm={{ span: 16, offset: 4 }} lg={{ span: 6, offset: 9 }}>
          <div style={{ marginTop: 100 }}>
            <Login
              error={error}
              isFetching={isFetching}
              doLogin={(values) => this.doLogin(values)}
            />
          </div>
        </Col>
        <Button />
      </Row>
    );
  }
}

const mapStateToProps = ({ authReducer, configurationReducer }) => ({
  isAuthenticated: authReducer.isAuthenticated,
  authUser: authReducer.authUser,
  isFetching: authReducer.isFetching,
  forwardLocation: authReducer.forwardLocation,
  error: authReducer.error,
  hasConfiguration:
    Object.keys(configurationReducer.item).length || configurationReducer.didInvalidate,
});

const mapDispatchToProps = (dispatch) => ({
  login: (username, password, remember) =>
    dispatch(authActions.login({ username, password, remember })),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginContainer));
