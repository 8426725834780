import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ROUTER from '../../../config/router';
import AddDriver from '../../Driver/add';
import EditDriver from '../../Driver/edit';
import DriverPage from '../../Driver/index';

const Driver = () => (
  <Switch>
    <Route exact path={ROUTER.DRIVER.INDEX} component={DriverPage} />
    <Route exact path={ROUTER.DRIVER.ADD_DRIVER} component={AddDriver} />
    <Route exact path={ROUTER.DRIVER.EDIT_DRIVER} component={EditDriver} />
  </Switch>
);
export default Driver;
