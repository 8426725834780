import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ROUTER from '../../../config/router';
// import AddCompany from '../../Company/add';
// import EditCompany from '../../Company/edit';
import ConfigurationPage from '../../Configuration/index';
import SecretKey from '../../SecretKey';

const Configuration = () => (
  <Switch>
    <Route exact path={ROUTER.CONFIGURATION.INDEX} component={ConfigurationPage} />
    <Route exact path={ROUTER.CONFIGURATION.KEY} component={SecretKey} />
    {/* <Route exact path={ROUTER.COMPANY.ADD_COMPANY} component={AddCompany} />
    <Route exact path={ROUTER.COMPANY.EDIT_COMPANY} component={EditCompany} /> */}
  </Switch>
);
export default Configuration;
