import { Button, Col, DatePicker, Divider, Modal, Row, notification } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import EditForm from './EditForm';
import { VOUCHER_STATUS } from '../../../constants/enum';
import { actions as inventoryVoucherActions } from '../../../redux/InventoryRecordVoucherRedux';

const { updateInventoryRecordVoucher } = inventoryVoucherActions;

const ModalInventoryVoucher = ({
  visibleModal,
  toggleModalVoucher,
  item,
  wareHouseData,
  updateInventoryRecordVoucher,
  getInventoryRecordVoucher,
}) => {
  const [note, setNote] = useState(item?.note);
  const [invetoryTime, setInvetoryTime] = useState(
    item?.record_time ? moment(item?.record_time).toISOString() : '',
  );
  const [submitData, setSubmitData] = useState({});

  useEffect(() => {
    setInvetoryTime(item?.record_time ? moment(item?.record_time).toISOString() : '');
    setNote(item?.note);
  }, []);

  const isCompleted = item?.state === 'completed' || item?.state === 'cancelled';

  const onChangeNote = (value) => {
    setNote(value.target.value);
  };

  const onChangeInventoryTime = (value) => {
    setInvetoryTime(moment(value).toISOString());
  };

  const getData = (product) => {
    const curData = [];
    if (product) {
      product?.map((value, index) => {
        curData.push({
          product_code: value?.product_code,
          product_name: value?.product_name,
          product_secondary_code: value?.product_secondary_code,
          lot_details: value?.lot_details?.map((itemlv2) => ({
            expiry_date: itemlv2.expiry_date || null,
            lot_number: itemlv2.lot_number || 0,
            manufacturing_date: itemlv2.manufacturing_date || null,
            real_quantity: itemlv2?.real_quantity,
          })),
          unit: {
            factor: value?.unit?.factor,
            primary_unit: value?.unit?.primary_unit,
            secondary_unit: value?.unit?.secondary_unit,
          },
        });
      });
    } else {
      item?.inventory_records?.map((value, index) => {
        curData.push({
          product_code: value?.product_code,
          product_name: value?.product_name,
          product_secondary_code: value?.product_secondary_code,
          lot_details: value?.summary_lot_numbers?.map((itemlv2) => ({
            expiry_date: itemlv2.expiry_date || null,
            lot_number: itemlv2.lot_number || 0,
            manufacturing_date: itemlv2.manufacturing_date || null,
            real_quantity: itemlv2?.real_quantity,
          })),
          unit: {
            factor: value?.unit?.factor,
            primary_unit: value?.unit?.primary_unit,
            secondary_unit: value?.unit?.secondary_unit,
          },
        });
      });
    }

    setSubmitData(curData);
  };

  const handleSubmit = () => {
    const payload = {
      note,
      inventory_records: submitData.length > 0 ? submitData : item?.inventory_records,
      record_time: invetoryTime,
      storage_code: item?.storage_code,
      storage_level: item?.storage_level,
      storage_name: item?.storage_name,
    };

    updateInventoryRecordVoucher(
      {
        code: item.code,
        payload,
      },
      {
        onSuccess: () => {
          notification.success({ message: 'Sửa phiếu kiểm kê thành công' });
          getInventoryRecordVoucher({
            page: 1,
            page_size: 10,
          });
        },
        onError: (mes) => {
          notification.error({ message: `Sửa phiếu kiểm kê thất bại. ` });
        },
      },
    );
    toggleModalVoucher();
  };

  return (
    <>
      <Modal
        title={
          <Row gutter={15}>
            <Col span={24}>
              <h3 style={{ float: 'left' }}>Thông tin phiếu kiểm kê</h3>
            </Col>
          </Row>
        }
        visible={visibleModal}
        onCancel={toggleModalVoucher}
        destroyOnClose
        width={1200}
        footer={<></>}>
        <Row gutter={15} style={{ marginBottom: '10px' }}>
          <Col span={24} lg={12}>
            <Row>
              <Col span={12} lg={6}>
                <b>Mã: </b>
              </Col>
              <Col span={12} lg={18}>
                <>{item?.code}</>
              </Col>
            </Row>
          </Col>
          <Col span={24} lg={12}>
            <Row>
              <Col span={12} lg={6}>
                <b>Trạng thái: </b>
              </Col>
              <Col span={12} lg={18}>
                <>{VOUCHER_STATUS[item?.state]}</>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={15} style={{ marginBottom: '10px' }}>
          <Col span={24} lg={12}>
            <Row>
              <Col span={12} lg={6}>
                <b>Ngày tạo: </b>
              </Col>
              <Col span={12} lg={18}>
                <>{moment(item?.created_at).format('DD-MM-YYYY HH:mm:ss')}</>
              </Col>
            </Row>
          </Col>
          <Col span={24} lg={12}>
            <Row>
              <Col span={12} lg={6}>
                <b>Ngày kiểm kê: </b>
              </Col>
              <Col span={12} lg={18}>
                {isCompleted ? (
                  <p>
                    {item?.record_time ? moment(item?.record_time).format('DD/MM/YYYY HH:mm') : '-'}
                  </p>
                ) : (
                  <DatePicker
                    style={{ width: '100%' }}
                    onChange={onChangeInventoryTime}
                    placeholder="Chọn ngày xuất kho"
                    format="DD-MM-YYYY"
                    allowClear={false}
                    defaultValue={item?.record_time ? moment(item?.record_time) : ''}
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider />

        <EditForm products={item?.inventory_records} sendData={getData} ware={wareHouseData} />
        <Row>
          <Col span={8} lg={6}>
            <b>Ghi chú: </b>
          </Col>
          <Col span={16} lg={18}>
            {isCompleted ? (
              <p>{note}</p>
            ) : (
              <TextArea rows={2} placeholder="Ghi chú" value={note} onChange={onChangeNote} />
            )}
          </Col>
        </Row>
        <Button
          type="primary"
          shape="round"
          style={{ width: '20%', marginLeft: '40%', marginTop: '15px' }}
          onClick={handleSubmit}
          disabled={isCompleted}>
          Lưu phiếu xuất
        </Button>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  updateInventoryRecordVoucher: (payload, meta) =>
    dispatch(updateInventoryRecordVoucher({ payload, meta })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalInventoryVoucher);
