import { Button, Modal, Table } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import TomtomSearch from '../../../components/FormItems/TomtomSearch';
import common from '../../../config/common';
import ROUTER from '../../../config/router';
import { actions as tomtomActions } from '../../../redux/TomtomRedux';

import './style.css';

const rowSelection = (onSelectChange, onSelectRow, onSelectAllRow, getCheckboxProps) => ({
  onChange: onSelectChange,
  onSelect: onSelectChange || onSelectRow,
  getCheckboxProps: getCheckboxProps
    ? getCheckboxProps
    : (record) => ({
        // disabled:
        //   moment().format('YYYY-MM-DD HH:mm:ss') >
        //   moment(record.delivery_date).format('YYYY-MM-DD HH:mm:ss'),
        code: record.code,
        name: record.name,
      }),
  onSelectAll: onSelectAllRow || onSelectChange,
  columnWidth: 10,
});

const columns = (collapseTable, isImportFromFile, showModal) => [
  {
    title: <b>Mã đơn</b>,
    dataIndex: 'code',
    key: 'code',
    render: (value, record) =>
      moment().format('YYYY-MM-DD HH:mm:ss') >
      moment(record.delivery_date).utc().format('YYYY-MM-DD HH:mm:ss') ? (
        //value
        <Link to={ROUTER.ORDER.EDIT.replace(':code', `${value}`)}>{value}</Link>
      ) : (
        <Link to={ROUTER.ORDER.EDIT.replace(':code', `${value}`)}>{value}</Link>
      ),
    className: collapseTable ? 'hide-col' : '',
  },
  {
    title: <b>Mã KH</b>,
    dataIndex: 'shipping_code',
    render: (value, record) => <b>{value || record.customer.code || ''}</b>,
  },
  {
    title: <b>Khách hàng</b>,
    dataIndex: isImportFromFile ? 'Name' : ['customer', 'name'],
  },
  {
    title: <b>Số điện thoại</b>,
    dataIndex: isImportFromFile ? 'Phone' : ['customer', 'contacts'],
    width: 150,
    className: collapseTable ? 'hide-col' : '',
    render: (value, record) => record?.customer?.contacts?.[0]?.phone_number,
  },
  {
    title: <b>Địa chỉ</b>,
    dataIndex: isImportFromFile ? 'Address' : ['customer', 'location', 'address'],
    ellipsis: true,
  },

  {
    title: <b>Trạng thái</b>,
    dataIndex: 'status',
    render: (value) => common.orderStatus[value] || value || '-',
  },
  {
    title: <b>Ngày đặt hàng</b>,
    dataIndex: 'created_at',
    render: (value) => (value ? <div>{moment(value).format('DD/MM/YYYY HH:mm')}</div> : ''),
    className: collapseTable ? 'hide-col' : '',
  },
  {
    title: <b>Ngày giao hàng</b>,
    width: 130,
    dataIndex: isImportFromFile ? 'Ngày giao' : 'delivery_date',
    render: (value) => (value ? <div>{moment(value).utc().format('DD/MM/YYYY')}</div> : ''),
  },
  {
    title: <b>Sản phẩm</b>,
    width: 120,
    className: collapseTable ? 'hide-col' : '',
    dataIndex: 'order_items',
    render: (orderItems) => {
      if (!orderItems?.length) {
        return '-';
      }

      const sumProducts = orderItems.reduce(
        (accumulator, currentItem) => accumulator + (currentItem.quantity || 0),
        0,
      );

      return `${sumProducts} sản phẩm`;
    },
  },
  {
    title: (
      <b>
        Thể tích (m<sup>3</sup>)
      </b>
    ),
    width: 120,
    dataIndex: isImportFromFile ? 'Thể tích (m2)' : 'occupied_space',
    className: collapseTable ? 'hide-col' : '',
  },
  {
    title: <b>Khối lượng (kg)</b>,
    width: 130,
    dataIndex: isImportFromFile ? 'Khối lượng (kg)' : 'demand',
    className: collapseTable ? 'hide-col' : '',
  },
  isImportFromFile
    ? {
        title: 'Tuỳ Chọn',
        dataIndex: '',
        render: (text, record) => {
          if (record.Address && (record['Kinh độ'] === undefined || record['Vĩ độ'] === undefined))
            if (!record?.location) {
              return (
                <Button size="small" onClick={() => showModal(record)}>
                  Tìm toạ độ
                </Button>
              );
            }
        },
      }
    : {},
];

const routingColumns = () => [
  {
    title: <b>Mã KH</b>,
    dataIndex: 'shipping_code',
    render: (value, record) => <b>{value || record.customer.code || ''}</b>,
  },
  {
    title: <b>Khách hàng</b>,
    dataIndex: ['customer', 'name'],
  },
  {
    title: <b>Số điện thoại</b>,
    dataIndex: ['customer', 'contacts'],
    width: 150,
    // className: collapseTable ? 'hide-col' : '',
    render: (value, record) => record?.customer?.contacts?.[0]?.phone_number,
  },
  {
    title: <b>Ngày giao hàng</b>,
    width: 130,
    dataIndex: 'delivery_date',
    render: (value) => (value ? <div>{moment(value).format('DD/MM/YYYY')}</div> : ''),
  },
  {
    title: <b>Địa chỉ</b>,
    dataIndex: ['customer', 'location', 'address'],
    ellipsis: true,
  },
];

const customerColumns = (collapseTable, isImportFromFile, showModal) => [
  {
    title: <b>STT</b>,
    dataIndex: 'STT',
    className: collapseTable ? 'hide-col' : '',
  },
  {
    title: <b>Đơn giao hàng</b>,
    dataIndex: isImportFromFile && 'Đơn hàng',
  },
  {
    title: <b>Khách Hàng</b>,
    dataIndex: isImportFromFile ? 'Khách hàng' : 'customer_name',
  },
  {
    title: <b>SĐT</b>,
    dataIndex: isImportFromFile && 'Điện thoại',
  },
  {
    title: <b>Địa Chỉ</b>,
    dataIndex: isImportFromFile && 'Địa chỉ',
  },
  {
    title: <b>Địa Chỉ Truy Xuất</b>,
    dataIndex: isImportFromFile && 'receivedAddress',
  },
  {
    title: <b>Trọng Lượng</b>,
    dataIndex: '',
  },
  {
    title: <b>Kinh Độ</b>,
    dataIndex: isImportFromFile && 'lng',
  },
  {
    title: <b>Vĩ Độ</b>,
    dataIndex: isImportFromFile && 'lat',
  },
];

const agencyColumns = (collapseTable, isImportFromFile, showModal) => [
  {
    title: <b>STT</b>,
    dataIndex: 'STT',
    className: collapseTable ? 'hide-col' : '',
  },
  {
    title: <b>Đơn giao hàng</b>,
    dataIndex: isImportFromFile && 'orderId',
  },
  {
    title: <b>Khách Hàng</b>,
    dataIndex: 'customer',
  },
  {
    title: <b>SĐT</b>,
    dataIndex: isImportFromFile && 'phoneNumber',
  },
  {
    title: <b>Địa Chỉ</b>,
    dataIndex: isImportFromFile && 'address',
  },
  {
    title: <b>Địa Chỉ Truy Xuất</b>,
    dataIndex: isImportFromFile && 'receivedAddress',
  },
  {
    title: <b>Trọng Lượng</b>,
    dataIndex: 'weight',
  },
  {
    title: <b>Kinh Độ</b>,
    dataIndex: isImportFromFile && 'lng',
  },
  {
    title: <b>Vĩ Độ</b>,
    dataIndex: isImportFromFile && 'lat',
  },
];

class OrderList extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      formRef: this.formRef,
      loading: false,
      // isFullOfInfo: true,
      record: {},
      visibleTomTom: false,
      addressDetecting: '',
      visibleStartLocation: false,
      start_location: props?.defaultValue?.start_location || '',
      // selectedRowKeys: [],
      // selectedRows:[],
    };
  }

  componentDidMount() {
    const {
      defaultValue,
      setTomtomSearchAddress,
      setTomtomSearchLatitude,
      setTomtomSearchLongitude,
    } = this.props;
    if (defaultValue) {
      setTomtomSearchAddress(defaultValue.address);
      setTomtomSearchLatitude(defaultValue.latitude);
      setTomtomSearchLongitude(defaultValue.longitude);
    }
  }

  showModal = (record) => {
    this.setState({ visibleTomTom: true, addressDetecting: record.Address, record });
    this.props.setTomtomSearchAddress(record.Address);
  };

  handleOk = async () => {
    // const { tomtomReducer } = this.props;
    // console.log(tomtomReducer);
    this.props.onSelectPosition(
      this.state.record,
      this.props.selectedAddress,
      this.props.curLat,
      this.props.curLng,
    );
    this.formRef?.current?.setFieldsValue({ address: '' });
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visibleTomTom: false, addressDetecting: '' });
    }, 300);
  };

  handleCancel = () => {
    this.formRef?.current?.setFieldsValue({ address: '' });
    this.props.clearTomtomStore();
    this.setState({ visibleTomTom: false, addressDetecting: '' });
  };

  onSelectAddress = (value) => this.formRef?.current?.setFieldsValue({ address: value });

  // onSubmitStartLocation = (value) => {
  //   const { address, lat, lng } = value;
  //   this.setState({
  //     start_location: {
  //       address,
  //       latitude: lat,
  //       longitude: lng,
  //     },
  //   });
  //   this.state.formRef.current.setFieldsValue({
  //     start_locationAddress: value.address,
  //   });
  // };

  // showModalLocation = (address) => {
  //   const { setTomtomSearchAddress, setTomtomSearchLatitude, setTomtomSearchLongitude } =
  //     this.props;
  //   const { start_location, addressDetecting } = this.state;
  //   setTomtomSearchAddress(address);
  //   setTomtomSearchLatitude(start_location.latitude);
  //   setTomtomSearchLongitude(start_location.longitude);
  //   this.setState((prevState) => ({
  //     ...prevState,
  //     addressDetecting,
  //     visibleStartLocation: !prevState.visibleStartLocation,
  //   }));
  // };

  render() {
    const { loading, addressDetecting, visibleTomTom } = this.state;
    const {
      meta,
      onChange,
      dataSource,
      onShowSizeChange,
      onSelectChange,
      showRowSelection,
      onSelectRow,
      collapseTable,
      onSelectAllRow,
      isImportFromFile,
      mode,
    } = this.props;

    let orderPageColumns;

    if (mode === 'import')
      orderPageColumns = columns(collapseTable, isImportFromFile, this.showModal);
    else if (mode === 'routing') orderPageColumns = routingColumns();
    else if (mode === 'customerOrder')
      orderPageColumns = customerColumns(collapseTable, isImportFromFile, this.showModal);
    else if (mode === 'agencyOrder')
      orderPageColumns = agencyColumns(collapseTable, isImportFromFile, this.showModal);
    else orderPageColumns = columns();

    return (
      <>
        <Table
          showModal
          size="small"
          dataSource={dataSource}
          columns={orderPageColumns}
          rowKey={(record) => {
            if (isImportFromFile) {
              if (mode === 'import')
                return `${record?.Phone + record?.Name + record?.Address + record?.STT}`;
              else if (mode === 'customerOrder') return `${record['Đơn hàng']}`;
              else if (mode === 'agencyOrder') return `${record.orderId}`;
            } else return record.code;
          }}
          rowSelection={
            showRowSelection ? rowSelection(onSelectChange, onSelectRow, onSelectAllRow) : ''
          }
          pagination={
            mode === 'routing'
              ? false
              : {
                  current: meta?.page,
                  pageSize: meta?.page_size || 12,
                  total: meta?.total,
                  showSizeChanger: !showRowSelection,
                  onShowSizeChange,
                  onChange,
                  locale: { items_per_page: '/ trang' },
                  pageSizeOptions: [20, 40, 60, 100],
                  showTotal: (total, range) => `${range[0]}-${range[1]} trong ${total} đơn hàng`,
                }
          }
          scroll={{ x: 'max-content', y: mode === 'routing' && 500 }}
          rowClassName={(record) => {
            if (
              isImportFromFile &&
              (record.Name === undefined ||
                record.Address === undefined ||
                record.Phone === undefined) &&
              (record['Khách hàng'] === undefined ||
                record['Địa chỉ'] === undefined ||
                record['Điện thoại'] === undefined) &&
              mode === 'import'
            )
              return 'text-red';
          }}
        />

        <Modal
          open={visibleTomTom}
          title="Toạ độ"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width="70%"
          footer={[
            <Button key="submit" type="primary" loading={loading} onClick={this.handleOk}>
              Lưu
            </Button>,
            <Button key="back" onClick={this.handleCancel}>
              Huỷ Bỏ
            </Button>,
          ]}>
          {/* {console.log(this.props)} */}
          <TomtomSearch
            isEditMode={false}
            onSelectAddress={this.onSelectAddress}
            selectingAddress={addressDetecting}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  tomtomReducer: state.tomtomReducer,
  selectedAddress: state.tomtomReducer.address,
  curLat: state.tomtomReducer.latitude,
  curLng: state.tomtomReducer.longitude,
});

const mapDispatchToProps = (dispatch) => ({
  setTomtomSearchAddress: (payload) => dispatch(tomtomActions.setMapAddress(payload)),
  setTomtomSearchLatitude: (payload) => dispatch(tomtomActions.setMapLatitude(payload)),
  setTomtomSearchLongitude: (payload) => dispatch(tomtomActions.setMapLongitude(payload)),
  clearTomtomStore: () => dispatch(tomtomActions.clearStore()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderList);
