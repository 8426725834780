import { DeleteOutlined } from '@ant-design/icons';
import { Input, Button, DatePicker, Table } from 'antd';
import { isArray, isObject } from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';

import VNDInput from '../../../components/FormItems/VNDInput';
import { ISODateTimeParam } from '../../../utils/dateTime';
import { numberToVnd } from '../../../utils/numberFormatter';
import { isBelowBreakpoint } from '../../../utils/window';

const columns = (
  onChangeQuantity,
  onRemoveItem,
  onChangeExpiration,
  onChangeManufacturing,
  onAddItem,
  state,
  listWarehouseProducts,
  calcMaxAmount,
  onChangePrice,
) => [
  {
    title: () => <b>Mặt hàng</b>,
    width: '15%',
    key: 'product_name',
    dataIndex: 'product_name',
    render: (value, record) => {
      const obj = {
        children: value,
        props: {
          rowSpan: record.rowSpan !== undefined ? record.rowSpan : 1,
        },
      };

      return obj;
    },
  },
  // {
  //   title: <b>Mã phụ</b>,
  //   width: '10%',
  //   key: 'product_secondary_code',
  //   dataIndex: 'product_secondary_code',
  // },
  {
    title: <b>Số lượng</b>,
    key: 'quantity',
    width: '10%',
    align: 'right',
    render: (value, record, index) =>
      state === 'processing' ? (
        <Input
          defaultValue={record.quantity}
          autoFocus
          type="number"
          min={1}
          max={1000}
          key="quantity"
          value={record.quantity}
          addonAfter={<div>{record.unit.secondary_unit}</div>}
          onChange={(e) => onChangeQuantity(e.target.value, record.product_code, index)}
        />
      ) : (
        <div>{record.quantity}</div>
      ),
  },
  {
    title: <b>Giá nhập</b>,
    key: 'input_price',
    width: '15%',
    align: 'right',
    render: (_, record, index) =>
      state === 'processing' ? (
        <VNDInput
          value={record.input_price || 0}
          onChange={(val) => onChangePrice(val, record.code, index)}
        />
      ) : (
        <div>{numberToVnd(record.input_price)}</div>
      ),
  },
  //   {
  //     title: <b>Còn trong kho</b>,
  //     key: '',
  //     width: '8%',
  //     align: 'center',
  //     // render: (value, record) => calcMaxAmount(listWarehouseProducts, record),
  //   },
  {
    title: <b>Ngày sản xuất</b>,
    key: 'manufacturing_date',
    width: '15%',
    align: 'center',
    render: (value, record, index) => (
      <>
        {state === 'processing' ? (
          <DatePicker
            defaultValue={record.manufacturing_date ? moment(record.manufacturing_date) : ''}
            allowClear={false}
            style={{ width: '80%' }}
            format="DD-MM-YYYY"
            placeholder="Chọn ngày"
            onChange={(value) => onChangeManufacturing(value, record.product_code, index)}
            // disabledDate={(current) => current && current < Date.now() - 86400000}
          />
        ) : (
          <div>
            {record.manufacturing_date
              ? moment.utc(record.manufacturing_date).local().format('DD-MM-YYYY')
              : undefined}
          </div>
        )}
        {/* {record.plusIcon === false || state === 'completed' || state === 'cancelled' ? null : (
          <Button
            size="small"
            type="primary"
            shape="circle"
            icon={<PlusOutlined />}
            style={{ marginLeft: 5 }}
            disabled={state === 'cancelled' || state === 'completed'}
            onClick={() => onAddItem(record.product_code)}
          />
        )} */}
      </>
    ),
  },
  {
    title: <b>Ngày hết hạn</b>,
    key: 'expiry_date',
    width: '15%',
    align: 'center',
    render: (value, record, index) => (
      <>
        {state === 'processing' ? (
          <DatePicker
            defaultValue={record.expiry_date ? moment(record.expiry_date) : ''}
            allowClear={false}
            style={{ width: '100%' }}
            format="DD-MM-YYYY"
            placeholder="Chọn ngày"
            onChange={(value) => onChangeExpiration(value, record.product_code, index)}
            disabledDate={(current) => current && current < Date.now()}
          />
        ) : (
          <div>
            {record.expiry_date
              ? moment.utc(record.expiry_date).local().format('DD-MM-YYYY')
              : undefined}
          </div>
        )}
        {/* {record.plusIcon === false || state === 'completed' || state === 'cancelled' ? null : (
          <Button
            size="small"
            type="primary"
            shape="circle"
            icon={<PlusOutlined />}
            style={{ marginLeft: 5 }}
            disabled={state === 'cancelled' || state === 'completed'}
            onClick={() => onAddItem(record.product_code)}
          />
        )} */}
      </>
    ),
  },
  {
    title: <b>Xoá</b>,
    width: '10%',
    align: 'center',
    render: (value, record, index) => (
      <Button
        key="delete"
        shape="circle"
        type="danger"
        onClick={() => onRemoveItem(record.product_code, index)}
        disabled={state === 'cancelled' || state === 'completed'}
        icon={<DeleteOutlined />}
      />
    ),
  },
];
class ItemList extends Component {
  constructor(props) {
    super(props);
    this.textSelect = React.createRef();
    this.state = {
      listProducts: isArray(props.products) ? this.setAllRowSpan([...props?.products]) : [],
      products: [],
      originProducts: [],
    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.products !== prevState.originProducts) {
      const { products } = nextProps;
      return {
        products,
        originProducts: products,
      };
    }
    return null;
  }

  calcMaxAmount = (warehouseProducts, record) => {
    const whProduct = warehouseProducts?.length
      ? warehouseProducts.find((item) => item.product_code === record.product_code)
      : false;
    if (isObject(whProduct)) return whProduct?.total_quantity;

    return 0;
  };

  setAllRowSpan = (listProducts) => {
    const processedProductCodes = [];
    listProducts.forEach((item) => {
      if (!processedProductCodes.includes(item.product_code)) {
        processedProductCodes.push(item.product_code);
        this.setRowSpan(listProducts, item.product_code);
      }
    });
    return listProducts;
  };

  setRowSpan = (listProducts, productCode) => {
    const duplicatedProductsIndex = [];
    listProducts.forEach((item, index) => {
      if (item.product_code === productCode) {
        duplicatedProductsIndex.push(index);
      }
    });
    if (duplicatedProductsIndex.length === 1) {
      listProducts[duplicatedProductsIndex[0]] = Object.assign(
        {},
        listProducts[duplicatedProductsIndex[0]],
        { rowSpan: 1, plusIcon: true },
      );
    } else if (duplicatedProductsIndex.length > 1) {
      duplicatedProductsIndex.forEach((pIndex) => {
        listProducts[pIndex] = Object.assign({}, listProducts[pIndex], {
          rowSpan: 0,
          plusIcon: false,
        });
      });

      listProducts[duplicatedProductsIndex[0]] = Object.assign(
        {},
        listProducts[duplicatedProductsIndex[0]],
        {
          rowSpan: duplicatedProductsIndex.length,
        },
      );
      listProducts[duplicatedProductsIndex[duplicatedProductsIndex.length - 1]] = Object.assign(
        {},
        listProducts[duplicatedProductsIndex[duplicatedProductsIndex.length - 1]],
        {
          plusIcon: true,
        },
      );

      return duplicatedProductsIndex[duplicatedProductsIndex.length - 1];
    }

    return 0;
  };

  onRemoveItem = (code, productIndex) => {
    const { listProducts } = this.state;
    const removeProducts = listProducts.filter(
      (item, index) => item.product_code !== code || index !== productIndex,
    );
    this.setRowSpan(removeProducts, code);
    this.setState({ listProducts: removeProducts }, () => this.combineData());
  };

  onAddItem = (productCode) => {
    const { listProducts, originProducts } = this.state;
    let isExist = -1;
    if (listProducts.length) {
      const index = listProducts
        .slice() // to preserve the original array
        .reverse()
        .findIndex((item) => item.product_code === productCode);
      // isExist is the last index of item that has the productCode
      isExist = index >= 0 ? listProducts.length - 1 - index : index;
    }
    const product = originProducts.find((item) => item.code === productCode);
    if (product) {
      const newListProducts = listProducts;
      let focusRowIndex = listProducts.length;
      const newProduct = {
        product_code: product.product_code,
        name: product.product_name,
        unit: {
          name: product.unit,
        },
        price: product.price,
        category: {
          name: product.category_name,
          code: product.category_code,
        },
        supplier: {
          name: product.supplier_name,
          code: product.supplier_code,
        },
        quantity: 1,
      };

      newListProducts.splice(isExist + 1, 0, newProduct);
      focusRowIndex = this.setRowSpan(newListProducts, productCode);

      this.setState({ listProducts: newListProducts, focusRowIndex }, () => this.combineData());
    }
  };

  onChangeQuantity = (value, code, productIndex) => {
    if (Number(value) <= 0) return;

    const { listProducts } = this.state;
    const newProducts = listProducts.map((item, index) => {
      if (item.product_code === code && index === productIndex) {
        return {
          ...item,
          quantity: Number(value),
          //   totalPrice: item?.price?.price * Number(value),
        };
      }
      return item;
    });
    this.setState({ listProducts: newProducts }, () => this.combineData());
  };

  onChangePrice = (value, code, productIndex) => {
    if (Number(value) <= 0) return;

    const { listProducts } = this.state;
    const newProducts = listProducts.map((item, index) => {
      if (item.product_code === code && index === productIndex) {
        return {
          ...item,
          input_price: Number(value),
        };
      }
      return item;
    });
    this.setState({ listProducts: newProducts }, () => this.combineData());
  };

  onChangeExpiration = (value, code, productIndex) => {
    const { listProducts } = this.state;
    const newExpirationProducts = listProducts.map((item, index) => {
      if (item.product_code === code && index === productIndex)
        return { ...item, expiry_date: ISODateTimeParam(value) };

      return item;
    });
    this.setState({ listProducts: newExpirationProducts }, () => this.combineData());
  };

  onChangeManufacturing = (value, code, productIndex) => {
    const { listProducts } = this.state;
    const newManufacturingProducts = listProducts.map((item, index) => {
      if (item.product_code === code && index === productIndex)
        return { ...item, manufacturing_date: ISODateTimeParam(value) };

      return item;
    });
    this.setState({ listProducts: newManufacturingProducts }, () => this.combineData());
  };

  combineData = () => this.props.sendData(this.state.listProducts);

  render() {
    const { listProducts } = this.state;
    const { state, listWarehouseProducts } = this.props;

    return isBelowBreakpoint() ? (
      <>
        <Table
          key={`${listProducts?.length}`}
          size="small"
          scroll={{ x: 'max-content', y: 400 }}
          dataSource={listProducts}
          columns={columns(
            this.onChangeQuantity,
            this.onRemoveItem,
            this.onChangeExpiration,
            this.onChangeManufacturing,
            this.onAddItem,
            state,
            listWarehouseProducts,
            this.calcMaxAmount,
            this.onChangePrice,
          )}
          style={{ marginBottom: 10 }}
          //   rowKey={(record) => record.product_code}
          rowKey={(record, index) => `${record.product_code}-${index}`}
          rowClassName={(r, idx) => (idx % 2 ? 'whitesmoke' : '')}
          pagination={false}
        />
      </>
    ) : (
      <>
        <Table
          key={`${listProducts?.length}`}
          size="small"
          scroll={{ x: 'max-content', y: 400 }}
          dataSource={listProducts}
          columns={columns(
            this.onChangeQuantity,
            this.onRemoveItem,
            this.onChangeExpiration,
            this.onChangeManufacturing,
            this.onAddItem,
            state,
            listWarehouseProducts,
            this.calcMaxAmount,
            this.onChangePrice,
          )}
          style={{ marginBottom: 10 }}
          //   rowKey={(record) => record.product_code}
          rowKey={(record, index) => `${record.product_code}-${index}`}
          rowClassName={(r, idx) => (idx % 2 ? 'whitesmoke' : '')}
          pagination={false}
        />
      </>
    );
  }
}

export default ItemList;
