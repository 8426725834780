import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ROUTER from '../../../config/router';
import OrderPage from '../../Order';
import AddOrder from '../../Order/add';
import EditOrder from '../../Order/edit';

const Order = () => (
  <Switch>
    <Route exact path={ROUTER.ORDER.INDEX} component={OrderPage} />
    <Route exact path={ROUTER.ORDER.ADD} component={AddOrder} />
    <Route exact path={ROUTER.ORDER.EDIT} component={EditOrder} />
  </Switch>
);
export default Order;
