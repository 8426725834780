export default {
  dateTimeFormat: 'DD-MM-YYYY HH:mm:ss',
  invoiceStatus: {
    0: 'pending',
    1: 'received',
    2: 'processing',
    3: 'shipping',
    4: 'shipped',
    5: 'completed',
    6: 'returned',
    7: 'cancelled',
  },
  productTemplate: `<h3>Thành Phần:</h3>
  <p></p>
  <h3>Quy cách đóng gói:</h3>
  <p></p>
  <h3>Công dụng:</h3>
  <p></p>
  <h3>Hướng dẫn sử dụng:</h3>
  <p></p>
  <h3>Khuyến cáo của nhà sản xuất:</h3>
  <p></p>`,
  routeStatus: {
    Scheduled: 'Đã lập lịch',
    DriverConfirming: 'Chờ LX xác nhận',
    DriverConfirmed: 'LX xác nhận',
    DriverRejected: 'LX từ chối',
    DriverReady: 'LX sẵn sàng',
    OnRoute: 'Đang trên đường',
    OnService: 'Đã tới điểm giao hàng',
    GoDepot: 'LX đang về kho',
    Completed: 'Hoàn thành',
  },
  orderStatus: {
    Ordered: 'Đặt hàng',
    Scheduled: 'Đã lập lịch',
    OnRoute: 'Đang giao',
    'On Route': 'Đang giao',
    Completed: 'Hoàn thành',
    Failed: 'Thất bại',
  },
};
