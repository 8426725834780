import { PlusOutlined } from '@ant-design/icons';
import { Button, Divider, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Filter from './components/Filter';
import FormProduct from './components/Form';
import ListProducts from './components/List';
import { actions as categoryActions } from '../../redux/CategoryRedux';
// import { actions as unitActions } from '../../redux/UnitRedux';
import { actions as productActions } from '../../redux/productRedux';

const { getProducts, getProductById, addProduct, updateProduct, changeState } = productActions;
const { getCategoryById } = categoryActions;
// const { getUnitById } = unitActions;

const ProductPage = ({
  getProducts,
  dataProducts,
  meta,
  changeState,
  addProduct,
  updateProduct,
  getProductById,
  getCategoryById,
  curCategory,
  // getUnitById,
  isLoading,
  // curUnit,
}) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [categoryCodes, setCategoryCodes] = useState([]);
  const [status, setStatus] = useState();
  const [search, setSearch] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  //   const [curUnit, setCurUnit] = useState({});
  const [curProduct, setCurProduct] = useState({});
  const [categoryId, setCategoryId] = useState(undefined);
  // const [unitId, setUnitId] = useState(undefined);

  useEffect(() => {
    getProducts({
      page,
      page_size: pageSize,
      search,
      status,
      category_codes: categoryCodes,
    });
  }, [page, pageSize, search, status, categoryCodes]);

  useEffect(() => {
    getCategoryById(categoryId);
  }, [categoryId]);

  // useEffect(() => {
  //   getUnitById(unitId);
  // }, [unitId]);

  const onChangePage = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
  };

  const openModalForm = () => {
    setOpenModal(true);
  };

  const closeModalForm = () => {
    setOpenModal(false);
    setIsEdit(false);
    setCurProduct({});
    setCategoryId(undefined);
  };

  const handleChangeCategoryCodes = (value) => {
    setCategoryCodes(value.join());
  };

  // const handleChangeUnit = (value) => {
  //   setUnitId(value);
  // };

  const handleChangeCategoryCodeForm = (value) => {
    setCategoryId(value);
  };

  const handleChangeSearch = (value) => {
    setSearch(value);
  };

  const handleChangeStatus = (value) => {
    setStatus(value);
  };

  const editProduct = (code, payload) => {
    openModalForm();
    setIsEdit(true);
    setCurProduct(payload);
    setCategoryId(payload?.category_code);
  };

  const handleAddProduct = (value) => {
    const payload = {
      //   category: curCategory,
      category: {
        code: curCategory.code,
        description: curCategory.description,
        level: curCategory.level,
        name: curCategory.name,
        parent_code: curCategory.parent_code,
      },
      category_code: curCategory?.code ? curCategory?.code : '',
      code: value?.code,
      description: value?.description,
      feature: value?.feature ? value?.feature : [],
      name: value?.name,
      // don't need secondary_code. only use for Ubofood data
      // secondary_code: value?.secondary_code,
      img_url: value?.img_url || '',
      unit: {
        // only use secondary_unit, keeping primary_unit and factor to match Ubofood data
        factor: 1,
        primary_unit: value.secondary_unit || '',
        secondary_unit: value.secondary_unit || '',
      },
    };

    addProduct(payload, {
      onSuccess: () => {
        notification.success({ message: 'Thêm sản phẩm thành công' });
        getProducts({
          page: 1,
          page_size: 10,
        });
      },
      onError: (mes) => {
        notification.error({ message: `Thêm sản phẩm thất bại.` });
      },
    });
    closeModalForm();
  };

  const handleEditProduct = (value) => {
    const payload = {
      //   category: curCategory?.code ? curCategory : null,
      category: {
        code: curCategory.code,
        description: curCategory.description,
        level: curCategory.level,
        name: curCategory.name,
        parent_code: curCategory.parent_code,
      },
      category_code: curCategory?.code ? curCategory?.code : '',
      code: value?.code,
      description: value?.description,
      feature: value?.feature ? value?.feature : [],
      name: value?.name,
      secondary_code: value?.secondary_code || curProduct?.secondary_code,
      img_url: value?.img_url || '',
      unit: {
        // only use secondary_unit, keeping primary_unit and factor to match Ubofood data
        factor: curProduct?.unit?.factor !== undefined ? curProduct.unit.factor : 1,
        primary_unit:
          curProduct?.unit?.primary_unit ||
          value.secondary_unit ||
          curProduct?.unit?.secondary_unit,
        secondary_unit: value.secondary_unit || curProduct?.unit?.secondary_unit,
      },
    };
    updateProduct(
      {
        code: curProduct.code,
        payload,
      },
      {
        onSuccess: () => {
          notification.success({ message: 'Thêm sản phẩm thành công' });
          getProducts({
            page: 1,
            page_size: 10,
          });
        },
        onError: (mes) => {
          notification.error({ message: `Thêm sản phẩm thất bại. ` });
        },
      },
    );
    closeModalForm();
  };

  return (
    <>
      <div>
        <h2 style={{ float: 'left' }}>Danh sách sản phẩm</h2>
        <div style={{ float: 'right' }}>
          <Button shape="round" type="primary" onClick={openModalForm} icon={<PlusOutlined />}>
            Thêm
          </Button>
        </div>
      </div>
      <Divider style={{ marginTop: 0 }} />
      <div style={{ marginBottom: '15px' }}>
        <Filter
          style={{ width: 'inherit', marginBottom: '15px' }}
          onChangeStatus={handleChangeStatus}
          onChangeSearch={handleChangeSearch}
          onChangeCategoryCodes={handleChangeCategoryCodes}
        />
      </div>
      <ListProducts
        dataProducts={dataProducts}
        meta={meta}
        onChangePage={onChangePage}
        editProduct={editProduct}
        changeStatusProduct={changeState}
        isLoading={isLoading}
      />
      <FormProduct
        openModal={openModal}
        openModalForm={openModalForm}
        closeModalForm={closeModalForm}
        isEdit={isEdit}
        handleSubmit={isEdit ? handleEditProduct : handleAddProduct}
        handleChangeCategoryCodeForm={handleChangeCategoryCodeForm}
        // handleChangeUnit={handleChangeUnit}
        curProduct={curProduct}
        // curUnit={curUnit}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  dataProducts: state?.productReducer?.data,
  meta: state?.productReducer?.meta,
  curCategory: state?.categoryReducer?.currentData,
  isLoading: state?.productReducer?.isFetching,
  // curUnit: state?.unitReducer?.currentData,
});

const mapDispatchToProps = (dispatch) => ({
  getProducts: (params) => dispatch(getProducts(params)),
  changeState: (payload, meta) => dispatch(changeState({ payload, meta })),
  addProduct: (payload, meta) => dispatch(addProduct({ payload, meta })),
  updateProduct: (payload, meta) => dispatch(updateProduct({ payload, meta })),
  getProductById: (code) => dispatch(getProductById(code)),
  getCategoryById: (code) => dispatch(getCategoryById(code)),
  // getUnitById: (code) => dispatch(getUnitById(code)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductPage);
