import produce from 'immer';
import moment from 'moment';

import { API_URLS } from '../config/api';
import { apiCall } from '../utils/api';
// import { findIndex } from '../utils/findIndex';
import { isCallingApi, isFailedApiCall, isSuccessfulApiCall } from './actionDedicate';
import { PREFIX, typesWithPrefix } from './config';

const { ROLE } = PREFIX;
const _types = typesWithPrefix(ROLE);
const TYPE = {
  GET_ROLES: _types('GET_ROLES'),
  GET_ROLES_CHANGE_PAGE: _types('GET_ROLES_CHANGE_PAGE'),
  INVALIDATE_ROLES: _types('INVALIDATE_ROLES'),
  GET_ALL_ROLES: _types('GET_ALL_ROLES'),
  ACTIVE_DEACTIVE_ROLE: _types('ACTIVE_DEACTIVE_ROLE'),
  GET_API_OF_ROLE: _types('GET_API_OF_ROLE'),
  INSERT_ROLE: _types('INSERT_ROLE'),
  EDIT_ROLE: _types('EDIT_ROLE'),
  DELETE_API_OF_ROLE: _types('DELETE_API_OF_ROLE'),
  INSERT_ROLE_API: _types('INSERT_ROLE_API'),
};

// ACTION
export const actions = {
  //GET ROLES
  getRolesIfNeed: (filterOption) => (dispatch, getState) => {
    const { roleReducer } = getState();
    const isFetching = roleReducer.isFetching;
    const didInvalidate = roleReducer.didInvalidate;
    const query = roleReducer.query;
    const isQueryChanged = query.search !== filterOption.search;
    if ((!isFetching && didInvalidate) || (!isFetching && isQueryChanged)) {
      dispatch(actions.getRoles(filterOption));
    }
  },

  getRoles: (query) => async (dispatch) => {
    const params = {
      page: query.page,
      page_size: query.page_size,
      search: query.search,
    };
    const api = API_URLS.ROLE.getRoles(params);
    dispatch(actions.gettingRoles());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(
        actions.getRolesSuccess({
          items: response.data.data,
          meta: {
            page: response.data.page,
            page_size: response.data.page_size,
            total: response.data.total,
          },
          query: {
            search: params.search,
          },
        }),
      );
    } else {
      dispatch(actions.getRolesFailure());
    }
  },

  gettingRoles: () => ({
    type: TYPE.GET_ROLES,
    meta: { prefix: [PREFIX.ROLE, PREFIX.API_CALLING] },
  }),

  getRolesSuccess: (payload) => ({
    type: TYPE.GET_ROLES,
    payload,
    meta: { prefix: [PREFIX.ROLE, PREFIX.API_CALLED_SUCCESS] },
  }),

  getRolesFailure: () => ({
    type: TYPE.GET_ROLES,
    meta: { prefix: [PREFIX.ROLE, PREFIX.API_CALLED_FAILURE] },
  }),

  // CHANGE PAGE
  changePage: (page, page_size, total) => ({
    type: TYPE.GET_ROLES_CHANGE_PAGE,
    payload: {
      page,
      page_size,
      total,
    },
    meta: {
      prefix: [PREFIX.ROLE],
    },
  }),

  // INVALIDATE ROLES
  invalidateRoles: () => ({
    type: TYPE.INVALIDATE_ROLES,
    meta: {
      prefix: [PREFIX.ROLE, PREFIX.API_CALLED_SUCCESS],
    },
  }),

  //GET ALL ROLES
  getAllRoles: () => async (dispatch) => {
    const api = API_URLS.ROLE.getRoles({ page: -1, page_size: -1 });
    dispatch(actions.gettingAllRoles());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(
        actions.getAllRolesSuccess({
          items: response.data.data,
        }),
      );
    } else {
      dispatch(actions.getAllRolesFailure());
    }
  },

  gettingAllRoles: () => ({
    type: TYPE.GET_ALL_ROLES,
    meta: { prefix: [PREFIX.ROLE, PREFIX.API_CALLING] },
  }),

  getAllRolesSuccess: (payload) => ({
    type: TYPE.GET_ALL_ROLES,
    payload,
    meta: { prefix: [PREFIX.ROLE, PREFIX.API_CALLED_SUCCESS] },
  }),

  getAllRolesFailure: () => ({
    type: TYPE.GET_ALL_ROLES,
    meta: { prefix: [PREFIX.ROLE, PREFIX.API_CALLED_FAILURE] },
  }),

  //ACTIVE DEACTIVE ROLE
  activeDeactiveRole: (code, meta) => async (dispatch) => {
    const api = API_URLS.ROLE.activeDeactiveRole(code);
    dispatch(actions.activeDeactivingRole());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data && response.data.success === true) {
      if (meta && meta.onSuccess) {
        dispatch(actions.activeDeactiveRoleSuccess());
        meta.onSuccess();
      }
    } else {
      dispatch(actions.activeDeactiveRoleFailure());
      if (meta && meta.onError) {
        meta.onError();
      }
    }
  },

  activeDeactivingRole: () => ({
    type: TYPE.ACTIVE_DEACTIVE_ROLE,
    meta: { prefix: [PREFIX.ROLE, PREFIX.API_CALLING] },
  }),

  activeDeactiveRoleSuccess: () => ({
    type: TYPE.ACTIVE_DEACTIVE_ROLE,
    meta: { prefix: [PREFIX.ROLE, PREFIX.API_CALLED_SUCCESS] },
  }),

  activeDeactiveRoleFailure: () => ({
    type: TYPE.ACTIVE_DEACTIVE_ROLE,
    meta: { prefix: [PREFIX.ROLE, PREFIX.API_CALLED_FAILURE] },
  }),

  //INSERT ROLE
  insertRole: (payload, meta) => async (dispatch, getState) => {
    const api = API_URLS.ROLE.insertRole();
    dispatch(actions.insertingRole());
    const { response, error } = await apiCall({
      ...api,
      payload,
    });
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(actions.insertRoleSuccess());
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
    } else {
      dispatch(actions.insertRoleFailure());
    }
  },
  insertingRole: () => ({
    type: TYPE.INSERT_ROLE,
    meta: { prefix: [PREFIX.ROLE, PREFIX.API_CALLING] },
  }),

  insertRoleSuccess: () => ({
    type: TYPE.INSERT_ROLE,
    meta: { prefix: [PREFIX.ROLE, PREFIX.API_CALLED_SUCCESS] },
  }),

  insertRoleFailure: () => ({
    type: TYPE.INSERT_ROLE,
    meta: { prefix: [PREFIX.ROLE, PREFIX.API_CALLED_FAILURE] },
  }),

  //EDIT ROLE
  editRole: (id, payload, meta) => async (dispatch, getState) => {
    const api = API_URLS.ROLE.editRole(id);
    dispatch(actions.editingRole());
    const { response, error } = await apiCall({
      ...api,
      payload,
    });
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(actions.editRoleSuccess());
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
    } else {
      dispatch(actions.editRoleFailure());
    }
  },
  editingRole: () => ({
    type: TYPE.EDIT_ROLE,
    meta: { prefix: [PREFIX.ROLE, PREFIX.API_CALLING] },
  }),

  editRoleSuccess: () => ({
    type: TYPE.EDIT_ROLE,
    meta: { prefix: [PREFIX.ROLE, PREFIX.API_CALLED_SUCCESS] },
  }),

  editRoleFailure: () => ({
    type: TYPE.EDIT_ROLE,
    meta: { prefix: [PREFIX.ROLE, PREFIX.API_CALLED_FAILURE] },
  }),

  //GET ROLE'S APIS
  getApiOfRole: (id) => async (dispatch) => {
    const api = API_URLS.ROLE.getRole(id);
    dispatch(actions.gettingApiOfRole());
    const { response, error } = await apiCall(api);
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(actions.getApiOfRoleSuccess(response.data.data.apis));
    } else {
      dispatch(actions.getApiOfRoleFailure());
    }
  },

  gettingApiOfRole: () => ({
    type: TYPE.GET_API_OF_ROLE,
    meta: { prefix: [PREFIX.ROLE, PREFIX.API_CALLING] },
  }),

  getApiOfRoleSuccess: (payload) => ({
    type: TYPE.GET_API_OF_ROLE,
    payload,
    meta: { prefix: [PREFIX.ROLE, PREFIX.API_CALLED_SUCCESS] },
  }),

  getApiOfRoleFailure: () => ({
    type: TYPE.GET_API_OF_ROLE,
    meta: { prefix: [PREFIX.ROLE, PREFIX.API_CALLED_FAILURE] },
  }),

  //DELETE ROLE'S API
  deleteApiOfRole: (id, payload, meta) => async (dispatch) => {
    const api = API_URLS.ROLE.deleteApiOfRole(id, payload);
    dispatch(actions.deletingApiOfRole());
    const { response, error } = await apiCall({ ...api });
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(actions.deleteApiOfRoleSuccess());
      dispatch(actions.getApiOfRole(id));
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
    } else {
      dispatch(actions.deleteApiOfRoleFailure());
      if (meta && meta.onSuccess) {
        meta.onError();
      }
    }
  },

  deletingApiOfRole: () => ({
    type: TYPE.DELETE_API_OF_ROLE,
    meta: { prefix: [PREFIX.ROLE, PREFIX.API_CALLING] },
  }),

  deleteApiOfRoleSuccess: () => ({
    type: TYPE.DELETE_API_OF_ROLE,
    meta: { prefix: [PREFIX.ROLE, PREFIX.API_CALLED_SUCCESS] },
  }),

  deleteApiOfRoleFailure: () => ({
    type: TYPE.DELETE_API_OF_ROLE,
    meta: { prefix: [PREFIX.ROLE, PREFIX.API_CALLED_FAILURE] },
  }),

  //INSERT ROLE'S API FROM EXCEL
  insertApiOfRoleFromExcel: (payload, meta) => async (dispatch) => {
    const api = API_URLS.ROLE.insertApiOfRoleFromExcel();
    const param = { roles: payload };
    dispatch(actions.insertingApiOfRole());
    const { response, error } = await apiCall({ ...api, payload: param });
    if (!error && response.status === 200 && response.data.success === true) {
      dispatch(actions.insertApiOfRoleSuccess());
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
    } else {
      dispatch(actions.insertApiOfRoleFailure());
      if (meta && meta.onError) {
        meta.onError();
      }
    }
  },

  insertingApiOfRole: () => ({
    type: TYPE.INSERT_ROLE_API,
    meta: { prefix: [PREFIX.ROLE, PREFIX.API_CALLING] },
  }),

  insertApiOfRoleSuccess: () => ({
    type: TYPE.INSERT_ROLE_API,
    meta: { prefix: [PREFIX.ROLE, PREFIX.API_CALLED_SUCCESS] },
  }),

  insertApiOfRoleFailure: () => ({
    type: TYPE.INSERT_ROLE_API,
    meta: { prefix: [PREFIX.ROLE, PREFIX.API_CALLED_FAILURE] },
  }),

  //INSERT ROLE'S API
  insertApiOfRole: (id, payload, meta) => async (dispatch) => {
    const param = { apis: payload };
    const api = API_URLS.ROLE.insertApiOfRole(id, param);
    dispatch(actions.insertingApiOfRole());
    const { response, error } = await apiCall({ ...api });
    if (!error && response.status === 200) {
      dispatch(actions.insertApiOfRoleSuccess());
      dispatch(actions.getApiOfRole(id));
      if (meta && meta.onSuccess) {
        meta.onSuccess();
      }
    } else {
      dispatch(actions.insertApiOfRoleFailure());
      if (meta && meta.onSuccess) {
        meta.onError();
      }
    }
  },
};

// REDUCER
const listState = {
  lastUpdated: moment().unix(),
  allItems: [],
  items: [],
  meta: {
    page: 1,
    page_size: 10,
    total: 0,
  },
  query: {},
  isFetching: false,
  didInvalidate: true,
  listApiOfRole: [],
};

export const reducer = (state = listState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case TYPE.GET_ROLES:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          const { items, meta, query } = action.payload;
          draft.isFetching = false;
          draft.didInvalidate = false;
          draft.lastUpdated = moment().unix();
          draft.items = items;
          draft.meta = meta;
          draft.query = query;
        } else if (isFailedApiCall(action)) {
          draft.isFetching = false;
          draft.didInvalidate = false;
        }
        break;
      case TYPE.GET_ROLES_CHANGE_PAGE:
        draft.meta = action.payload;
        break;
      case TYPE.INVALIDATE_ROLES:
        draft.didInvalidate = true;
        break;
      case TYPE.GET_ALL_ROLES:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          const { items } = action.payload;
          draft.isFetching = false;
          draft.didInvalidate = false;
          draft.allItems = items;
        } else if (isFailedApiCall(action)) {
          draft.isFetching = false;
          draft.didInvalidate = false;
        }
        break;
      case TYPE.GET_API_OF_ROLE:
        if (isCallingApi(action)) {
          draft.isFetching = true;
        } else if (isSuccessfulApiCall(action)) {
          draft.isFetching = false;
          draft.didInvalidate = false;
          draft.listApiOfRole = action.payload;
        } else if (isFailedApiCall(action)) {
          draft.isFetching = false;
        }
        break;
      default:
        return draft;
    }
  });
