import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Divider, Row, Button, Modal, message, notification, Col, Form, Input, Table } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as XLSX from 'xlsx';

import Filter from './components/Filter';
import SampleRouteList from './components/List';
import CustomerSearch from '../../components/FormItems/CustomerSearch';
import DriverSearch from '../../components/FormItems/DriverSearch';
import WithConfig from '../../hoc/WithConfig';
import WithLoading from '../../hoc/WithLoading';
import { actions as customerActions } from '../../redux/CustomerRedux';
import { actions as driverActions } from '../../redux/DriverRedux';
import { actions as sampleRouteActions } from '../../redux/SampleRouteRedux';

const {
  getSampleRoutesIfNeed,
  insertSampleRoute,
  updateSampleRoute,
  changePage,
  invalidateSampleRoutes,
} = sampleRouteActions;
const layout = { labelCol: { span: 24 } };

const SheetJSFT = [
  'xlsx',
  'xlsb',
  'xlsm',
  'xls',
  'xml',
  'csv',
  'txt',
  'ods',
  newFunction(),
  'uos',
  'sylk',
  'dif',
  'dbf',
  'prn',
  'qpw',
  '123',
  'wb*',
  'wq*',
  'html',
  'htm',
]
  .map(function (x) {
    return `.${x}`;
  })
  .join(',');

const columns = [
  {
    title: <b>Lái xe</b>,
    dataIndex: 'driver_name',
    key: 'driver_name',
    width: '250px',
    span: 2,
  },
  {
    title: <b>Số điện thoại</b>,
    dataIndex: 'driver_code',
    key: 'driver_code',
    width: '250px',
    span: 2,
  },
  {
    title: <b>Mã khách hàng</b>,
    dataIndex: 'customer_codes',
    key: 'customer_codes',
    width: '250px',
    span: 2,
    render: (values) => values.join(', '),
  },
];

class SampleRoute extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();

    this.state = {
      formRef: this.formRef,
      dataFilter: {
        page: '',
        pageSize: '',
        search: '',
        companyCode: '',
      },
      // Modal sample route from excel file
      dataFromFile: [],
      sampleRouteName: '',
      loading: false,
      visible: false,
      // Modal sample route information
      listSampleRoutes: [],
      currentSampleRoute: null,
      visibleModalInfo: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { meta } = props;
    const { dataFilter } = state;

    props.getSampleRoutesIfNeed({
      page: meta.page,
      pageSize: meta.pageSize,
      search: dataFilter.search,
      companyCode: dataFilter.companyCode,
    });

    return null;
  }

  componentDidMount() {
    this.props.getAllDrivers();
    this.props.getAllCustomers();
  }

  onPageChange = (page, pageSize) => {
    const { changePage, invalidateSampleRoutes, total } = this.props;
    changePage(page, pageSize, total);
    invalidateSampleRoutes();
  };

  showModal = () => this.setState({ visible: true });

  handleOk = () => {
    if (!this.state.sampleRouteName)
      return notification.error({ message: 'Hãy nhập tên lịch mẫu!' });

    this.setState({ loading: true });

    this.props.insertSampleRoute(
      {
        name: this.state.sampleRouteName,
        sample_routes: this.state.dataFromFile.map((route) => ({
          driver_code: route.driver_code,
          customer_codes: route.customer_codes,
        })),
      },
      {
        onSuccess: (res) => {
          if (res?.data?.success === true)
            notification.success({
              message: `Thêm thành công ${res?.data?.data?.sample_routes?.length} lịch mẫu!`,
            });
          else if (
            res?.data?.success === false &&
            res?.data?.message === 'must provide at least one element in input slice'
          )
            notification.error({
              message: `Thêm lịch mẫu từ file thất bại! Xem lại thông tin lịch mẫu trong file đã tồn tại hay chưa?`,
            });
        },
        onError: (err) => {
          const message = JSON.parse(err.response).message;
          notification.error({
            message: `Thêm lịch mẫu từ file thất bại!`,
            description: `${message}`,
          });
        },
      },
    );

    this.setState({ loading: false, visible: false });
  };

  handleCancel = () => this.setState({ dataFromFile: [], visible: false });

  // Thêm/sửa 1 lịch mẫu
  handleConfirmInfo = () => {
    const { sampleRouteName, currentSampleRoute, listSampleRoutes } = this.state;
    if (!sampleRouteName) {
      return notification.error({ message: 'Hãy nhập tên lịch mẫu!' });
    }

    this.setState({ loading: true });

    if (!currentSampleRoute?.code) {
      this.props.insertSampleRoute(
        {
          name: sampleRouteName,
          sample_routes: listSampleRoutes,
        },
        {
          onSuccess: () => {
            notification.success({
              message: 'Thêm lịch mẫu thành công!',
            });
            this.handleCancelInfo();
          },
          onError: (err) => {
            const message = JSON.parse(err.response).message;
            notification.error({
              message: 'Có lỗi khi thêm lịch mẫu. Hãy thử lại!',
              description: `${message}`,
            });
          },
        },
      );
    } else {
      this.props.updateSampleRoute(
        currentSampleRoute.code,
        {
          name: sampleRouteName,
          sample_routes: listSampleRoutes,
        },
        {
          onSuccess: () => {
            notification.success({
              message: 'Cập nhật lịch mẫu thành công!',
            });
            this.handleCancelInfo();
          },
          onError: (err) => {
            const message = JSON.parse(err.response).message;
            notification.error({
              message: 'Có lỗi khi sửa lịch mẫu. Hãy thử lại!',
              description: `${message}`,
            });
          },
        },
      );
    }
  };

  handleCancelInfo = () => {
    this.setState({
      visibleModalInfo: false,
      currentSampleRoute: null,
      listSampleRoutes: [],
    });
  };

  handleOnAdd = () => this.setState({ currentSampleRoute: {}, visibleModalInfo: true });

  handleSelectRoute = (route) =>
    this.setState({
      currentSampleRoute: route,
      visibleModalInfo: true,
      sampleRouteName: route?.name || '',
      listSampleRoutes: route?.sample_routes || [],
    });

  onChangeFilter = (data) => this.setState({ dataFilter: data });

  readExcelFile(file) {
    const promise = new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);

      reader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: 'buffer' });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      reader.onerror = (err) => reject(err);
    });

    promise
      .then((data) => {
        const recordFromFile = [];
        if (data.length) {
          data.map((record, i) => {
            // Nếu record là thông tin 1 lịch mẫu
            if (record['Mã lái xe']) {
              const newSampleRoute = {
                driver_code: record['Mã lái xe'],
                driver_name: record['Tên lái xe'],
                customer_codes: Object.keys(record).map(
                  (key) => key.startsWith('Mã KH') && record[key].toString(),
                ),
              };

              newSampleRoute.customer_codes = newSampleRoute.customer_codes.filter(
                (code) => code && code,
              );

              recordFromFile.push(newSampleRoute);
            }
          });
        }
        if (this.state.dataFromFile.length > 0) this.setState({ dataFromFile: [] });
        this.setState({ dataFromFile: [...recordFromFile] });
        message.success('Tải file thành công!');
      })
      .catch((error) => {
        this.setState({ dataFromFile: [] });
        // eslint-disable-next-line no-console
        console.log(error);
        message.error('Tải file thất bại!');
      });
  }

  updateListSampleRoutes = (index, value) => {
    const { listSampleRoutes } = this.state;
    const temp = [...listSampleRoutes];
    if (value.customer_codes)
      temp[index] = {
        ...temp[index],
        customer_codes: listSampleRoutes[index].customer_codes
          ? [...new Set([...temp[index].customer_codes, ...value.customer_codes])]
          : value.customer_codes,
      };
    else temp[index] = { ...temp[index], ...value };
    this.setState({ listSampleRoutes: temp });
    this.formRef.current.setFieldsValue({ listSampleRoutes: temp });
  };

  renderSampleRouteList = () => {
    const { listSampleRoutes, currentSampleRoute } = this.state;
    const listInput = [];
    for (let i = 0; i < listSampleRoutes?.length; i++) {
      listInput.push(
        <Row
          span={24}
          gutter={15}
          key={`${currentSampleRoute.code}-listSampleRoutes-${i}`}
          style={{ marginTop: 15 }}>
          <Col span={2} style={{ alignSelf: 'flex-start' }}>
            <b>Tuyến {i + 1}:</b>
          </Col>
          <Col span={11}>
            <DriverSearch
              selectedValue={listSampleRoutes[i]?.driver_code}
              onChange={(e) => this.updateListSampleRoutes(i, { driver_code: e })}
              style={{ width: '100%' }}
            />
          </Col>
          <Col span={11}>
            <CustomerSearch
              mode="multiple"
              selectedValue={listSampleRoutes[i]?.customer_codes}
              onChange={(e) => this.updateListSampleRoutes(i, { customer_codes: e })}
              style={{ width: '100%' }}
            />
          </Col>
        </Row>,
      );
    }

    // new blank row for adding more
    listInput.push(
      <Row
        key={`${currentSampleRoute.code}-listSampleRoutes-${listSampleRoutes.length}`}
        span={24}
        gutter={15}
        style={{ marginTop: 15 }}>
        <Col span={2} />
        <Col span={11}>
          <DriverSearch
            onChange={(e) =>
              this.updateListSampleRoutes(listSampleRoutes.length, { driver_code: e })
            }
            style={{ width: '100%' }}
          />
        </Col>
        <Col span={11}>
          <CustomerSearch
            mode="multiple"
            onChange={(e) =>
              this.updateListSampleRoutes(listSampleRoutes.length, { customer_codes: e })
            }
            style={{ width: '100%' }}
          />
        </Col>
      </Row>,
    );

    return listInput;
  };

  render() {
    const {
      dataFromFile,
      visible,
      visibleModalInfo,
      loading,
      sampleRouteName,
      currentSampleRoute,
    } = this.state;
    const { isFetching, meta, sampleRoutes, allVehicles } = this.props;

    return (
      <>
        <div>
          <h2 style={{ float: 'left' }}>Danh sách lịch mẫu</h2>
          <div style={{ float: 'right', width: '235px' }}>
            <Button
              style={{ marginRight: '12px' }}
              type="default"
              shape="round"
              icon={<UploadOutlined />}
              size="medium"
              onClick={this.showModal}>
              Upload File
            </Button>
            <Button shape="round" type="primary" onClick={this.handleOnAdd} icon={<PlusOutlined />}>
              Thêm
            </Button>
          </div>
        </div>
        <Divider style={{ marginTop: 0 }} />
        <div style={{ marginBottom: '15px' }}>
          <Filter style={{ width: 'inherit' }} onSearch={this.onChangeFilter} />
        </div>
        <SampleRouteList
          onSelectRoute={this.handleSelectRoute}
          allVehicles={allVehicles}
          isFetching={isFetching}
          dataSource={sampleRoutes}
          meta={meta}
          onPageChange={this.onPageChange}
        />

        {/* Modal thêm nhiều sample route */}
        <Modal
          visible={visible}
          title="Nhập lịch mẫu từ file excel"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width={1500}
          footer={[
            <Button
              key="submit"
              shape="round"
              type="primary"
              loading={loading}
              onClick={this.handleOk}>
              Lưu
            </Button>,
            <Button key="back" shape="round" onClick={this.handleCancel}>
              Huỷ Bỏ
            </Button>,
          ]}>
          <input
            type="file"
            accept={SheetJSFT}
            label="Tải lên File"
            onChange={(e) => {
              const file = e.target.files[0];
              this.readExcelFile(file);
            }}
          />
          <a
            href="https://docs.google.com/spreadsheets/d/10QGaahWlitoBc7Lz-cjlU6HFP0y-fca1/edit#gid=2053929411"
            target="_blank">
            Mẫu file Excel
          </a>
          <div style={{ margin: '15px 0' }}>
            <span>Tên lịch mẫu: </span>
            <Input
              style={{ width: 250 }}
              value={sampleRouteName}
              onChange={(e) => this.setState({ sampleRouteName: e.target.value })}
              placeholder="Nhập tên lịch mẫu"
            />
          </div>
          <Table
            dataSource={dataFromFile}
            columns={columns}
            pagination={false}
            rowKey="driver_code"
          />
        </Modal>

        {/* Modal thêm/sửa thông tin 1 sample route */}
        {currentSampleRoute ? (
          <Modal
            visible={visibleModalInfo}
            title="Thông tin lịch mẫu"
            width={1000}
            onCancel={this.handleCancelInfo}
            footer={[
              <Button key="submit" shape="round" type="primary" onClick={this.handleConfirmInfo}>
                Lưu
              </Button>,
              <Button key="back" onClick={this.handleCancelInfo} shape="round">
                Huỷ Bỏ
              </Button>,
            ]}>
            <>
              <Row type="flex" justify="center">
                <Col span={24} lg={24}>
                  <Form ref={this.formRef} initialValues={currentSampleRoute} {...layout}>
                    <Row gutter={10}>
                      <Col span={12}>
                        <FormItem label="Mã lịch mẫu:" name="code">
                          <Input disabled />
                        </FormItem>
                      </Col>
                      <Col span={12}>
                        <FormItem
                          label="Tên lịch mẫu:"
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: 'Nhập tên lịch mẫu!',
                            },
                          ]}>
                          <Input
                            placeholder="Nhập tên lịch mẫu..."
                            value={sampleRouteName}
                            onChange={(e) => this.setState({ sampleRouteName: e.target.value })}
                          />
                        </FormItem>
                      </Col>
                    </Row>
                    {this.renderSampleRouteList()}
                  </Form>
                </Col>
              </Row>
            </>
          </Modal>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { sampleRouteReducer } = state;
  return {
    sampleRoutes: sampleRouteReducer.items,
    meta: sampleRouteReducer.meta,
    total: sampleRouteReducer.meta.total,
    isFetching: sampleRouteReducer.isFetching,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getSampleRoutesIfNeed: (filterOption) => dispatch(getSampleRoutesIfNeed(filterOption)),
  insertSampleRoute: (payload, meta) => dispatch(insertSampleRoute({ payload, meta })),
  updateSampleRoute: (code, payload, meta) => dispatch(updateSampleRoute({ code, payload, meta })),
  changePage: (page, pageSize, total) => dispatch(changePage(page, pageSize, total)),
  invalidateSampleRoutes: () => dispatch(invalidateSampleRoutes()),
  getAllCustomers: () => dispatch(customerActions.getAllCustomers()),
  getAllDrivers: () => dispatch(driverActions.getAllDrivers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WithConfig('sampleRoute')(WithLoading(SampleRoute)));

function newFunction() {
  return 'fods';
}
