import { Col, Input, Row, Select } from 'antd';
import React, { Component } from 'react';

const { Search } = Input;
const { Option } = Select;

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataFilter: {
        search: '',
        vehicle_type: '',
      },
    };
  }

  onSearch = (field) => {
    const { dataFilter } = this.state;
    dataFilter.search = field;
    this.setState({ dataFilter });
    this.props.onSearch(dataFilter);
  };

  onChangeVehicleType = (field) => {
    const { dataFilter } = this.state;
    dataFilter.vehicle_type = field;
    this.setState({ dataFilter });
    this.props.onSearch(dataFilter);
  };

  render() {
    return (
      <Row gutter={15}>
        <Col span={12}>
          <div style={{ marginBottom: '10px' }}>Tìm kiếm</div>
          <Search
            onSearch={this.onSearch}
            style={{ width: '100%' }}
            placeholder="Tên hoặc biển số xe"
            enterButton
            allowClear
          />
        </Col>
        <Col span={12}>
          <div style={{ marginBottom: '10px' }}>Loại xe</div>
          <Select
            style={{ width: '100%' }}
            placeholder="Loại xe"
            onChange={this.onChangeVehicleType}
            allowClear>
            <Option value="truck">Xe tải</Option>
            <Option value="motor">Xe gắn máy</Option>
          </Select>
        </Col>
      </Row>
    );
  }
}

export default Filter;
