import moment from 'moment';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import 'moment/locale/vi';
import ROUTER from './config/router';
import AppLayout from './containers/AppLayout';
import LoginPage from './containers/Auth/LoginPage/index';
import RegisterPage from './containers/Auth/components/RegisterForm';

import './App.css';

moment.locale('vi');

function App() {
  return (
    <div>
      <Switch>
        <Route exact path={ROUTER.AUTH.LOGIN} component={LoginPage} />
        <Route exact path={ROUTER.AUTH.REGISTER} component={RegisterPage} />
        <Route path={ROUTER.HOME} component={AppLayout} />
      </Switch>
    </div>
  );
}

// eslint-disable-next-line no-undef
export default process.env.NODE_ENV === 'development'
  ? require('react-hot-loader/root').hot(App)
  : App;
